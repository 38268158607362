import React from 'react'
import PaginationPerPage from '../../../../app/components/pagination/PaginationPerPage'
import PortletBody from '../../../../app/components/portlet/PortletBody'
import StyledButton from '../../../../app/components/styledComponents/StyledButton'
import StyledFilterTabs from '../../../../app/components/styledComponents/StyledFilterTabs'
import StyledIconInput from '../../../../app/components/styledComponents/StyledIconInput'
import AddIcon from '../../../../app/components/svgIcons/AddIcon'
import FilterIcon from '../../../../app/components/svgIcons/FilterIcon'
import NewDownloadIcon from '../../../../app/components/svgIcons/NewDownloadIcon'
import SearchIcon from '../../../../app/components/svgIcons/SearchIcon'
import SortIcon from '../../../../app/components/svgIcons/SortIcon'
import BundleTile from '../Bundles/BundleTile'

export const BundleTab = ({activeTab}) => {
  return (
    <PortletBody className='mt-3'>
        <div className="d-flex justify-content-between align-items-center mb-4 mt-2">
            <StyledIconInput
              icon={<SearchIcon />}
              className="mw-50"
            //   onChange={handleSearch}
              placeholder="Search company by Name, Location or Status "
            />
            <StyledButton
              className="me-3 py-2"
              icon={<AddIcon />}
            //   onClick={ToggleFilterbar}
            >
              Add New
            </StyledButton>
            <StyledButton
              className="me-3 py-2"
              variant="iconBackground"
              icon={<FilterIcon />}
            //   onClick={ToggleFilterbar}
            >
              Filter
            </StyledButton>
            <StyledFilterTabs>
              <div className="btn-group" role="group">
                <button
                  className="btn"
                  id="sort"
                //   onClick={() => {
                //     setSortFilter(!sortFilter);
                //     setSelectedColumns(false);
                //   }}
                >
                  <SortIcon size={20} />
                </button>

                <button
                  className="btn"
                  // onClick={() => {
                  //   setSelectedColumns(!selectedColumns);
                  //   setSortFilter(false);
                  // }}
                >
                  <NewDownloadIcon size={20} />
                </button>
              </div>
            </StyledFilterTabs>
          </div>
        <BundleTile activeTab={activeTab}/>
        <PaginationPerPage
            perPage={ 0}
            pageNo={ 0}
            // onPerPageChange={handlePerPageChange}
            totalCount={ 0 }
            // onPageChange={handlePageChange}
          />
    </PortletBody>
  )
}
