import React from 'react';

const GlobeIcon = ({ size=16, ...props }) => (
<svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5.10004 13.94C5.08004 13.94 5.05337 13.9533 5.03337 13.9533C3.74004 13.3133 2.68671 12.2533 2.04004 10.96C2.04004 10.94 2.05337 10.9133 2.05337 10.8933C2.86671 11.1333 3.70671 11.3133 4.54004 11.4533C4.68671 12.2933 4.86004 13.1266 5.10004 13.94Z" fill="#767476"/>
<path d="M13.9599 10.9666C13.2999 12.2933 12.1999 13.3666 10.8599 14.0133C11.1132 13.1666 11.3265 12.3133 11.4665 11.4533C12.3065 11.3133 13.1332 11.1333 13.9465 10.8933C13.9399 10.92 13.9599 10.9466 13.9599 10.9666Z" fill="#767476"/>
<path d="M14.0132 5.13991C13.1732 4.88657 12.3265 4.67991 11.4665 4.53324C11.3265 3.67324 11.1199 2.81991 10.8599 1.98657C12.2399 2.64657 13.3532 3.75991 14.0132 5.13991Z" fill="#767476"/>
<path d="M5.10015 2.06004C4.86015 2.87337 4.68682 3.70004 4.54682 4.54004C3.68682 4.67337 2.83348 4.88671 1.98682 5.14004C2.63348 3.80004 3.70682 2.70004 5.03348 2.04004C5.05348 2.04004 5.08015 2.06004 5.10015 2.06004Z" fill="#767476"/>
<path d="M10.3267 4.39325C8.78001 4.21992 7.22001 4.21992 5.67334 4.39325C5.84001 3.47992 6.05334 2.56659 6.35334 1.68659C6.36667 1.63325 6.36001 1.59325 6.36667 1.53992C6.89334 1.41325 7.43334 1.33325 8.00001 1.33325C8.56001 1.33325 9.10667 1.41325 9.62667 1.53992C9.63334 1.59325 9.63334 1.63325 9.64667 1.68659C9.94667 2.57325 10.16 3.47992 10.3267 4.39325Z" fill="#767476"/>
<path d="M4.3935 10.3267C3.4735 10.16 2.56683 9.94667 1.68683 9.64667C1.6335 9.63334 1.5935 9.64001 1.54016 9.63334C1.4135 9.10667 1.3335 8.56667 1.3335 8.00001C1.3335 7.44001 1.4135 6.89334 1.54016 6.37334C1.5935 6.36667 1.6335 6.36667 1.68683 6.35334C2.5735 6.06001 3.4735 5.84001 4.3935 5.67334C4.22683 7.22001 4.22683 8.78001 4.3935 10.3267Z" fill="#767476"/>
<path d="M14.6664 8.00001C14.6664 8.56667 14.5864 9.10667 14.4598 9.63334C14.4064 9.64001 14.3664 9.63334 14.3131 9.64667C13.4264 9.94001 12.5198 10.16 11.6064 10.3267C11.7798 8.78001 11.7798 7.22001 11.6064 5.67334C12.5198 5.84001 13.4331 6.05334 14.3131 6.35334C14.3664 6.36667 14.4064 6.37334 14.4598 6.37334C14.5864 6.90001 14.6664 7.44001 14.6664 8.00001Z" fill="#767476"/>
<path d="M10.3267 11.6067C10.16 12.5267 9.94667 13.4334 9.64667 14.3134C9.63334 14.3667 9.63334 14.4067 9.62667 14.46C9.10667 14.5867 8.56001 14.6667 8.00001 14.6667C7.43334 14.6667 6.89334 14.5867 6.36667 14.46C6.36001 14.4067 6.36667 14.3667 6.35334 14.3134C6.06001 13.4267 5.84001 12.5267 5.67334 11.6067C6.44667 11.6934 7.22001 11.7534 8.00001 11.7534C8.78001 11.7534 9.56001 11.6934 10.3267 11.6067Z" fill="#767476"/>
<path d="M10.509 10.5088C8.84165 10.7192 7.15867 10.7192 5.49128 10.5088C5.2809 8.84141 5.2809 7.15843 5.49128 5.49103C7.15867 5.28066 8.84165 5.28066 10.509 5.49103C10.7194 7.15843 10.7194 8.84141 10.509 10.5088Z" fill="#767476"/>
</svg>
);
export default GlobeIcon;
