import React from 'react';

const FirstJobPubIcon    = ({ size = 18, color = "#000000", ...props }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.13208" width="24" height="24" rx="12" fill="#F5F5F5"/>
<path d="M15.352 7.205C14.6987 6.84333 13.8645 6.93667 13.0128 7.4675L11.3095 8.535C11.1928 8.605 11.0587 8.64584 10.9245 8.64584H10.382H10.0903C8.67867 8.64584 7.90283 9.42167 7.90283 10.8333V13.1667C7.90283 14.5783 8.67867 15.3542 10.0903 15.3542H10.382H10.9245C11.0587 15.3542 11.1928 15.395 11.3095 15.465L13.0128 16.5325C13.5262 16.8533 14.0278 17.0108 14.4945 17.0108C14.7978 17.0108 15.0895 16.9408 15.352 16.795C15.9995 16.4333 16.3612 15.6808 16.3612 14.6775V9.3225C16.3612 8.31917 15.9995 7.56667 15.352 7.205Z" fill="#767476"/>
</svg>

    

  );
};

export default FirstJobPubIcon;
