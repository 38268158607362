import { Formik, Form } from 'formik';
import React from 'react';
import { Modal } from 'react-bootstrap';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { Wrap } from '../../../assets/styledComponents/Global';
import PortletBody from '../../../app/components/portlet/PortletBody';
import StyledButton from '../../../app/components/styledComponents/StyledButton';
import styled from 'styled-components';
import theme from '../../../utils/themes';
import FormikSelect from '../../../app/components/formikComponents/FormikSelect';
import { JOB_COPY_WRITING } from '../../../utils/Statuses';
import { useChangeStatusMutation } from '../jobsListingApiSlice';
import { getStatusFriendly } from '../../../utils/helper';


const ChangeStatusModal = ({
    onHide,

    refetch,
    copywritingStatus

}) => {


    const [changeStatus, { isLoading: loading }] = useChangeStatusMutation();
    const handleSubmit = (values) => {
        let sendData = {
            jobId: copywritingStatus?.jobId,
            companyId: copywritingStatus?.companyId,
            copywriteStatus: values.status
        }
        changeStatus(sendData).unwrap().then(data => { toast.success(data.message || 'status changed successfully'); refetch(); onHide(); })
    };

    const statusSchema = Yup.object().shape({
        status: Yup.string().required('Required').nullable(),
    });

    const arr = Object?.entries(JOB_COPY_WRITING)?.map(([label, value]) => ({ label, value }));
    return (
        <>
            <Modal show={copywritingStatus?.changeStatus} centered onHide={onHide} size={'lg'}>
                <Wrap className="bg-white rounded">
                    <Formik
                        enableReinitialize={true}
                        initialValues={{
                            status: copywritingStatus?.status
                        }}
                        validationSchema={statusSchema}
                        onSubmit={handleSubmit}
                    >
                        {({ handleSubmit, values }) => (
                            <Form onSubmit={handleSubmit}>
                                <Modal.Header
                                    closeButton
                                    className="border-bottom-0 pb-2 align-items-baseline"
                                >
                                    <Modal.Title
                                        as="h4"
                                        className="flex-grow-1 px-0 pb-2 cursor-pointer flex-center"
                                    >
                                        Change Status
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body className="d-flex flex-column bg-body rounded">
                                    <PortletBody className=" mx-3">

                                        <div className="mt-4 pb-2">
                                            <label htmlFor="stageType" className="form-label">
                                                Choose status
                                            </label>
                                            <FormikSelect
                                                name="status"
                                                id="status"
                                                options={arr}
                                                value={values?.status && <StyledType className='d-flex py-1 align-items-center'>
                                                    <span className='text-primary'>
                                                        {getStatusFriendly(arr?.find(i => i.value === values?.status)?.label, JOB_COPY_WRITING)}
                                                    </span>
                                                </StyledType>}
                                                placeholder="Choose status"
                                            />
                                        </div>
                                    </PortletBody>
                                    <Wrap className="d-flex justify-content-between align-items-center pt-4 px-0">
                                        <div />
                                        <Wrap className="d-flex align-items-center ms-auto gap-4">
                                            <div
                                                className="text-secondary fw-bold mx-2 cursor-pointer"
                                                onClick={() => onHide()}
                                                disabled={loading}
                                            >
                                                Cancel
                                            </div>
                                            <StyledButton type={'submit'} disabled={loading} loading={loading} >Submit</StyledButton>
                                        </Wrap>
                                    </Wrap>
                                </Modal.Body>
                            </Form>
                        )}
                    </Formik>
                </Wrap>
            </Modal>
        </>
    );
};
const StyledType = styled.div`
  background-color: ${theme.primaryLight};
  width: fit-content;
  padding-left: 10px; 
  padding-right: 10px; 
  border-radius: 8px;
`

export default ChangeStatusModal;
