import React from 'react';

const ContractSigIcon = ({ size = 18, color = "#000000", ...props }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="24" height="24" rx="12" fill="#F5F5F5"/>
    <path d="M13.3708 6.16666H10.6292C10.0225 6.16666 9.52667 6.65666 9.52667 7.26332V7.81166C9.52667 8.41832 10.0167 8.90832 10.6233 8.90832H13.3708C13.9775 8.90832 14.4675 8.41832 14.4675 7.81166V7.26332C14.4733 6.65666 13.9775 6.16666 13.3708 6.16666Z" fill="#767476"/>
    <path d="M15.0567 7.81165C15.0567 8.73915 14.2983 9.49749 13.3708 9.49749H10.6292C9.70166 9.49749 8.94333 8.73915 8.94333 7.81165C8.94333 7.48499 8.59333 7.28082 8.30166 7.43249C7.47916 7.86999 6.91916 8.73915 6.91916 9.73665V15.2258C6.91916 16.6608 8.09166 17.8333 9.52666 17.8333H14.4733C15.9083 17.8333 17.0808 16.6608 17.0808 15.2258V9.73665C17.0808 8.73915 16.5208 7.86999 15.6983 7.43249C15.4067 7.28082 15.0567 7.48499 15.0567 7.81165ZM12.2217 14.8875H9.66666C9.42749 14.8875 9.22916 14.6892 9.22916 14.45C9.22916 14.2108 9.42749 14.0125 9.66666 14.0125H12.2217C12.4608 14.0125 12.6592 14.2108 12.6592 14.45C12.6592 14.6892 12.4608 14.8875 12.2217 14.8875ZM13.75 12.5542H9.66666C9.42749 12.5542 9.22916 12.3558 9.22916 12.1167C9.22916 11.8775 9.42749 11.6792 9.66666 11.6792H13.75C13.9892 11.6792 14.1875 11.8775 14.1875 12.1167C14.1875 12.3558 13.9892 12.5542 13.75 12.5542Z" fill="#767476"/>
    </svg>
    
    

  );
};

export default ContractSigIcon;
