import React from 'react';
import { get } from 'lodash';
import moment from 'moment';
import styled from 'styled-components';

const TableBody = ({ data, columns/*, ref*/ }) => {
  const renderCell = (item, column) => {
    if (column.content) return column.content(item);
    if (column.path === 'createdAt' || column.path === 'updatedAt') {
      return formatDate(get(item, column.path));
    }
    return get(item, column.path) ?? '-';
  };

  const createKey = (item) => {
    return (parseInt(item?.id) || 1) + Math.random() + 'td';
  };
  const formatDate = (date) => moment(new Date(date)).format('Do MMM YYYY');
  return (
    <StyledTBody>
      {data?.map((item, tbIndex) => (
        <tr key={`tbody-${Math.random()}-${tbIndex}`} >
          {columns.map((column) => (
            <td key={createKey(item, column)} className={column.alignBody}>
              {renderCell(item, column)}
            </td>
          ))}
        </tr>
      ))}
    </StyledTBody>
  );
};

const StyledTBody = styled.tbody`
  tr:last-child > td {
    border-bottom: 0;
  }
`;

export default TableBody;
