import React from 'react';
import Table from '../../../app/components/table/Table';


const CompanyListingTable = ({ companies, sortColumn, onSort, tableRef, filterTable }) => {
  return (
    <Table
      data={companies}
      columns={filterTable}
      sortColumn={sortColumn}
      onSort={onSort}
      tableRef={tableRef}
    />
  );
};


export default CompanyListingTable;
