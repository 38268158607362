
import React from 'react';
import { Modal } from 'react-bootstrap';
import PortletBody from '../../../app/components/portlet/PortletBody';
import StyledButton from '../../../app/components/styledComponents/StyledButton';
import { Wrap } from '../../../assets/styledComponents/Global';
import styled from 'styled-components';

const DataExportModal = ({ show, onHide, title,desc, downloadCsv,tab }) => {
   

    return (
        <Modal show={show} centered onHide={onHide} size={'lg'}>
           
                        <Modal.Header
                            // title={title}
                            closeButton
                            className="border-bottom-0 pb-0 align-items-baseline"
                        >
                            <Modal.Title
                                as="h3"
                                className="flex-grow-1 p-2 cursor-pointer flex-center"
                            >
                                {show && title}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="d-flex  flex-column bg-body rounded">
                            <PortletBody className="p-3 w-100 bg-body">
                               <StyledSpan>{desc}</StyledSpan>
                         </PortletBody>
                         <Wrap className="d-flex ms-auto">
            <StyledButton
              className=" mt-4 text-primary fw-bold"
              type="submit"
              variant={'text'}
              onClick={onHide}
            >
              Cancel
            </StyledButton>
            <StyledButton className="mt-4 rounded-3" type="submit" onClick={()=>downloadCsv(tab)}>
              Continue
            </StyledButton>
          </Wrap>
                        </Modal.Body>
            
        </Modal>
    );
};

const StyledSpan=styled.div`
color: var(--monochrome-1, #0A0509);
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 24px; 
`;
export default DataExportModal;
