import React from 'react';
// import Skeleton, { Table } from '@nejcm/react-skeleton';

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const TableSkeleton = ({ cols }) => {
  return (
    <>
      <div className="border border-muted rouned p-1">
        <table className="table">
          <thead>
            <tr>
              {cols.map((col, ind) => (
                <th className="text-center" key={`header-${ind}`}>
                  {col}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {Array.from(new Array(4)).map((ind, tbIndex) => (
              <tr key={`body-row-${tbIndex}`}>
                {cols.map((col, colIndex) => (
                  <td key={`col-${colIndex}-row-${tbIndex}`}>
                    <Skeleton height={30} className="me-2 mt-2" />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default TableSkeleton;
