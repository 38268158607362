import styled from 'styled-components';
import { FormLabel } from 'react-bootstrap/esm';

const StyledTag = styled(FormLabel)`
  ${({ variant, theme, _children,pad,textColor }) => {
    const basicProperties = `
    display: flex;
  align-items: center;
  color: ${textColor ? textColor : theme.statusTextColor};
  margin-top:10px;
  border: 1px;
  font-size:12px;
  border-radius: 4px;
  padding:${pad ? pad : '2px'};
    `;
    switch (variant) {
      case 'onboard':
        return `
        ${basicProperties}
  background: ${theme.skygreen};
        width:auto
        `;
        case 'onboard-pending':
          return `
          ${basicProperties}
    background: ${theme.lightYellow};
          width:auto
          `;
      case 'sent':
        return `
        ${basicProperties}
  background: ${theme.statusTagSent};
        width:60px
        `;
      case 'compeleted':
        return `
        ${basicProperties}
  background: ${theme.statusTagCompleted} ;
        `;
      case 'overdue':
        return `
        ${basicProperties}
  background: ${theme.statusTagOverDue};
  width:90px
        `;
      case 'noteSent':
        return `
        ${basicProperties}
  background: ${theme.noteTags};
        `;
      case 'new':
        return `
          ${basicProperties}
  background: ${theme.primaryLight};
  color:${theme.primary};
  width:auto
          `;
      default:
        return `
        ${basicProperties}
        `;
    }
  }}
`;
export default StyledTag;
