import React from 'react';
import { Route, Routes } from 'react-router-dom';
import LicenseDetails from './components/LicenseDetails';
import SwitchLicense from './components/SwitchLicense';
import AddLicense from './creation/AddLicense';
import LicenseListingContainer from './listing/LicenseListingContainer';

const LicensesRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<LicenseListingContainer />} />
      <Route path="/add-licence" element={<AddLicense/>} />
      <Route path="/:id/add-licence" element={<AddLicense/>} />
      <Route path="/switch-licence" element={<SwitchLicense/>} />
      <Route path="company/:id/licences/current" element={<LicenseDetails />} />

    </Routes>
  );
};

export default LicensesRoutes;
