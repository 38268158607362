import { apiSlice } from '../../app/services/apiSlice';

const PRODUCT_URL = 'backoffice/products';

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllProducts: builder.query({
      query: (params) => ({
        url: `${PRODUCT_URL}`,
        params,
      }),
    }),
    addNewProduct: builder.mutation({
      query: (data) => ({
        url: `${PRODUCT_URL}`,
        method: 'POST',
        body: {
          ...data,
        },
      }),
    }),

    removeProduct: builder.mutation({
      query: (data) => ({
        url: `${PRODUCT_URL}/${data}`,
        method: 'DELETE',
      }),
    }),
    getSingleProductById: builder.mutation({
      query: (id) => ({
        url: `${PRODUCT_URL}/${id}`,
        method: 'GET',
      }),
    }),
    getJobBoards: builder.mutation({
      query: (params) => ({
        url: `${PRODUCT_URL}/jobboards`,
        params: { ...params, sort: 'name', sortDir: 'ASC' }
      }),
      transformResponse: (responseData) => {
        responseData.options = responseData?.jobBoards?.map((option) => (
          {
            label: option.name,
            value: option.id,
          }));
        return responseData || {};
      },
    }),
    updateProductInfo: builder.mutation({
      query: (data) => ({
        url: `${PRODUCT_URL}`,
        method: 'PUT',
        body: {
          ...data
        },
      }),
    }),

  }),

});

export const {
  useGetAllProductsQuery,
  useAddNewProductMutation,
  useRemoveProductMutation,
  useGetSingleProductByIdMutation,
  useUpdateProductInfoMutation,
  useGetJobBoardsMutation
} = extendedApiSlice;
