import React from 'react';

const RadioMicrophoneIcon = ({ size = 24, ...props }) => (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M12 19C15.31 19 18 16.31 18 13V8C18 4.69 15.31 2 12 2C8.69 2 6 4.69 6 8V13C6 16.31 8.69 19 12 19Z" stroke="#585558" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M3 11V13C3 17.97 7.03 22 12 22C16.97 22 21 17.97 21 13V11" stroke="#585558" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M9.10938 7.48005C10.8894 6.83005 12.8294 6.83005 14.6094 7.48005" stroke="#585558" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M10.0293 10.48C11.2293 10.15 12.4993 10.15 13.6993 10.48" stroke="#585558" strokeLinecap="round" strokeLinejoin="round" />
    </svg>

);

export default RadioMicrophoneIcon;
