import React from 'react'

const CampaignIcon = ({ size = 24 }, props) => {
    return (
        <svg width={size} height={size} {...props} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.5 10.8221V14.8221C5.5 16.8221 6.5 17.8221 8.5 17.8221H9.93C10.3 17.8221 10.67 17.9321 10.99 18.1221L13.91 19.9521C16.43 21.5321 18.5 20.3821 18.5 17.4121V8.23205C18.5 5.25205 16.43 4.11205 13.91 5.69205L10.99 7.52205C10.67 7.71205 10.3 7.82205 9.93 7.82205H8.5C6.5 7.82205 5.5 8.82205 5.5 10.8221Z" stroke="currentColor" strokeWidth="2" />
        </svg>

    )
}

export default CampaignIcon