import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import PaginationPerPage from '../../../app/components/pagination/PaginationPerPage';
import Portlet from '../../../app/components/portlet/Portlet';
import PortletBody from '../../../app/components/portlet/PortletBody';
import StyledButton from '../../../app/components/styledComponents/StyledButton';
import StyledIconInput from '../../../app/components/styledComponents/StyledIconInput';
import EditIcon from '../../../app/components/svgIcons/EditIcon';
import FilterIcon from '../../../app/components/svgIcons/FilterIcon';
import SearchIcon from '../../../app/components/svgIcons/SearchIcon';
import SortIcon from '../../../app/components/svgIcons/SortIcon';
import TrashIcon from '../../../app/components/svgIcons/TrashIcon';
import { StyledFilterBar } from '../../../app/components/styledComponents/StyledFilterBar';
import StyledRadioButton from '../../../app/components/radio/StyledRadioButton';
import CrossIcon from '../../../app/components/svgIcons/CrossIcon';
import { isEqual } from 'lodash';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import LicenseListingTable from './LicenseListingTable';
import {
  useDeleteLicenseMutation,
  useGetAllLicensesQuery,
} from '../licenseApiSlice';
import { toast } from 'react-toastify';
import StyledToolTip from '../../../app/components/styledComponents/StyledToolTip';

const LicenseListingContainer = () => {
  const ref = useRef();
  const [sortFilter, setSortFilter] = useState(false);
  const [isOpen, setIsopen] = useState(false);
  const [filters, setFilters] = useState('');
  const [timer, setSearchTimer] = useState(null);

  const initialFilters = {
    perPage: 5,
    page: 1,
    sortedColumn: { path: 'createdAt', order: 'DESC' },
    query: '',
  };
  const [listing, setListing] = useState({ ...initialFilters });
  const {
    data: licences,
    refetch,
    isFetching,
  } = useGetAllLicensesQuery({
    perPage: listing.perPage,
    page: listing.page,
    query: listing.query && listing.query,
    sort: listing.sortedColumn.path,
    sortDir: listing.sortedColumn.order,
    ...filters,
  });

  const [delLicense] = useDeleteLicenseMutation();

  const handleSubmit = async (values) => {
    setFilters({ ...values });
    setIsopen(false);
  };

  let ToggleFilterbar = (item) => {
    setIsopen(item === 'filter' ? !isOpen : false);
    setSortFilter(item === 'sort' ? !sortFilter : false);
  };

  const handleSortBy = (item) => {
    const prevFilters = { ...listing };
    prevFilters.sortedColumn = item;
    setListing(prevFilters);
  };

  const sortFilters = [
    {
      id: 1,
      label: 'None',
      value: 'None',
      sortvalue: { path: 'name', order: 'ASC' },
    },
    {
      id: 2,
      label: 'Applied - Latest to Oldest',
      value: 'applied-latest-to-oldest',
      sortvalue: { path: 'createdAt', order: 'DESC' },
    },
    {
      id: 3,
      label: 'Applied - Oldest to Latest',
      value: 'applied-oldest-to-latest',
      sortvalue: { path: 'createdAt', order: 'ASC' },
    },
    {
      id: 4,
      label: 'Last Activity',
      value: 'last_activity',
      sortvalue: { path: 'updatedAt', order: 'DESC' },
    },
  ];

  useEffect(() => {
    const concernedElement = document.querySelector('.click-text');
    document.addEventListener('mousedown', (event) => {
      if (!concernedElement.contains(event.target)) {
        setSortFilter(false);
      }
    });
  }, [sortFilter]);

  const handleSearch = (evt) => {
    clearTimeout(timer);
    const query = evt.target.value;
    // setSearch(query);
    const newTimer = setTimeout(() => {
      setListing({ ...listing, page: 1, query: query });
    }, 2000);
    setSearchTimer(newTimer);
  };

  const handleSort = (sort) => {
    setListing((prevState) => ({
      ...prevState,
      sortedColumn: { ...sort },
      page: 1,
    }));
  };

  const handlePerPageChange = (perPage) => {
    setListing((prevState) => ({
      ...prevState,
      perPage: perPage.target.innerText,
      page: 1,
    }));
  };

  const handlePageChange = ({ selected }) => {
    setListing((prevState) => ({
      ...prevState,
      page: selected + 1,
    }));
  };

  // const onCheck = (item) => {
  // if (item.length === candidates.length) {
  //   setAllCandSelect(true);
  // }
  // let newArr = [];
  // if (item.length > 0) {
  //   setAllCandSelect(true);
  //   tempEmail.forEach((tmp) => {
  //     tmp.checked = !tmp.checked;
  //   });
  // } else {
  //   let newItemIndex = candRes?.data.findIndex((t) => t.id === item.id);
  //   tempEmail[newItemIndex].checked = !tempEmail[newItemIndex].checked;
  // }
  // tempEmail.map((item) => item.checked && newArr.push(item.id));
  // setCandidates(tempEmail);
  // setSelectedCands(newArr);
  // if (every(candidates, ['checked', true])) {
  //   setAllCandSelect(true);
  // }
  // };

  useEffect(() => {
    refetch();
  }, [licences]);

  const deleteLicense = (license) => {
    const id = license?.id;
    delLicense(id)
      .unwrap()
      .then((data) => {
        toast.success('Licence deleted successfully');
        refetch();
      });
  };

  const column = [
    {
      id: 1,
      key: 'name',
      isChecked: true,
      path: 'name',
      label: 'Name',
      alignBody: 'text-left align-middle ps-4 py-3',
      alignHeader: 'text-left align-middle cursor-pointer ps-4 py-3',
      content: (licence) => (
        <Link
          // to={`${licence?.id}`}
          className="text-decoration-none"
          to={''}
        >
          <h5 className="m-0 cut-text"> {licence?.name || '-'}</h5>
          <span className="small cut-text">{licence?.id || '-'}</span>
        </Link>
      ),
    },
    {
      id: 2,
      key: 3,
      isChecked: true,
      path: 'duration',
      label: 'Duration',
      alignBody: 'text-center align-middle ps-4 py-3',
      alignHeader: 'text-center align-middle cursor-pointer ps-4 py-3',
    },
    {
      id: 3,
      key: 3,
      isChecked: true,
      path: 'maxBasicAccounts',
      label: 'Basic Account',
      alignBody: 'text-center align-middle ps-4 py-3',
      alignHeader: 'text-center align-middle cursor-pointer ps-4 py-3',
    },
    {
      id: 4,
      key: 4,
      isChecked: true,
      path: 'maxAccounts',
      label: 'Standard Account',
      alignBody: 'text-center align-middle ps-4 py-3',
      alignHeader: 'text-center align-middle cursor-pointer ps-4 py-3',
    },

    {
      id: 5,
      key: 5,
      isChecked: true,
      label: 'Licence Type',
      alignBody: 'text-center align-middle ps-4 py-3',
      alignHeader: 'text-center align-middle cursor-pointer ps-4 py-3',
      content: (licence) => (
        <>
          <label className="ms-2">{licence?.licenceType?.title}</label>
        </>
      ),
    },
    {
      id: 6,
      key: 6,
      isChecked: true,
      label: 'Actions',
      alignBody: 'text-center align-middle ps-4 py-3',
      alignHeader: 'text-center align-middle cursor-pointer ps-4 py-3',
      content: (licence) => (
        <div className="d-flex align-items-center justify-content-center gap-2">
          <Link
            variant="text"
            className="text-decoration-none"
            to={`${licence?.id}/add-licence`}
          >
            <StyledToolTip title="Edit licence"  className="position-fixed" childrenClass={'ms-0'}>
              <EditIcon />
            </StyledToolTip>
          </Link>
          <StyledToolTip title="Delete licence" className="position-fixed" childrenClass={'ms-0'}>
            <div
              className="text-primary cursor-pointer d-flex"
              onClick={() => deleteLicense(licence)}
            >
              <TrashIcon />
            </div>
          </StyledToolTip>
        </div>
      ),
    },
  ];

  return (
    <div>
      <Portlet>
        <div className="portlet__body-bg">
          <PortletBody className="mb-3 rounded-0 rounded-top">
            <div className="d-flex justify-content-between align-items-center">
              <h3>Licences</h3>
              <StyledButton
                className="btn btn-primary"
                as={Link}
                to={'/licences/add-licence'}
              >
                Add Licence
              </StyledButton>
            </div>
          </PortletBody>

          <PortletBody className="mx-3">
            <>
              <div className="flex-center mb-3">
                <div className="flex-grow-1">
                  <StyledIconInput
                    icon={<SearchIcon />}
                    className="mw-50"
                    $maxWidth="60%"
                    $marginLeft="3px"
                    onChange={handleSearch}
                    placeholder="Search by licence name"
                    loading={isFetching}
                  />
                </div>
                <div className="d-flex gap-2">
                  <StyledButton
                    className="px-2 py-1 feature-pending"
                    variant="icon"
                    onClick={() => ToggleFilterbar('filter')}
                    icon={<FilterIcon />}
                  >
                    Filter
                  </StyledButton>
                  <StyledButton
                    className="px-2 py-1 d-flex align-items-center"
                    variant="icon"
                    icon={<SortIcon size="20" className="me-0" />}
                    id="sort"
                    onClick={() => ToggleFilterbar('sort')}
                  >
                    Sort
                  </StyledButton>
                </div>
              </div>
              <div className="m-0 position-relative d-flex rounded flex-column click-text">
                {sortFilter && (
                  <StyledFilterBar
                    variant={'sort'}
                    className="bg-white p-3 align-items-center justify-content-center"
                  >
                    <div className="d-flex justify-content-between align-items-center mb-3">
                      <h5 className="mb-0">Sort By</h5>
                    </div>
                    {sortFilters.map((item, index) => {
                      return (
                        <div
                          className="d-flex align-items-center py-2 px-2 "
                          key={`sort-${item.value}-index`}
                        >
                          <StyledRadioButton
                            value={
                              listing.sortedColumn.path ===
                              item.sortvalue.path &&
                              listing.sortedColumn.order ===
                              item.sortvalue.order
                            }
                            label={item.label}
                            className="fw-bold"
                            onChange={() => handleSortBy(item.sortvalue)}
                          />
                        </div>
                      );
                    })}
                  </StyledFilterBar>
                )}
              </div>

              <div className="position-relative bg-transparent click-filters">
                {isOpen && (
                  <StyledFilterBar variant="filter" className="my-1">
                    <div className="d-flex justify-content-end">
                      <div
                        className="cursor-pointer mt-3 me-3"
                        onClick={ToggleFilterbar}
                      >
                        <CrossIcon />
                      </div>
                    </div>

                    <div className="sd-header">
                      <h4 className="mb-0">Filters</h4>
                      {!isEqual(filters, initialFilters) && (
                        <h6
                          className="mb-0 cursor-pointer"
                          onClick={() => {
                            setFilters('');
                            setIsopen(false);
                          }}
                        >
                          Clear filters
                        </h6>
                      )}
                    </div>
                    <div className="sd-body">
                      <Formik
                        initialValues={{
                          ...filters,
                        }}
                        validationSchema={Yup.object().shape({
                          appDate: Yup.string(),
                        })}
                        onSubmit={handleSubmit}
                        innerRef={ref}
                      >
                        {({ handleSubmit, values, submitForm, errors }) => (
                          <Form onSubmit={handleSubmit}>
                            {/* <UsersFilterForm submitForm={submitForm} /> */}
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </StyledFilterBar>
                )}
              </div>

              <LicenseListingTable
                license={licences?.licences ? licences?.licences : []}
                sortColumn={listing?.sortedColumn}
                onSort={handleSort}
                filterTable={column}
              />
              <PaginationPerPage
                perPage={licences?.perPage ?? 5}
                pageNo={licences?.page ?? 1}
                onPerPageChange={handlePerPageChange}
                totalCount={licences?.total ?? 0}
                onPageChange={handlePageChange}
              />
            </>
          </PortletBody>
        </div>
      </Portlet>
      {/* <AddUserModal show={addUser} onHide={() => setAddUser(false)} /> */}
    </div>
  );
};

export default LicenseListingContainer;
