/* eslint-disable no-unused-vars */
import React from 'react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import PaginationPerPage from '../../../app/components/pagination/PaginationPerPage';
import Portlet from '../../../app/components/portlet/Portlet';
import PortletBody from '../../../app/components/portlet/PortletBody';
import StyledButton from '../../../app/components/styledComponents/StyledButton';
import StyledIconInput from '../../../app/components/styledComponents/StyledIconInput';
import EditIcon from '../../../app/components/svgIcons/EditIcon';
import FilterIcon from '../../../app/components/svgIcons/FilterIcon';
import SearchIcon from '../../../app/components/svgIcons/SearchIcon';
import SortIcon from '../../../app/components/svgIcons/SortIcon';
import TrashIcon from '../../../app/components/svgIcons/TrashIcon';
import { StyledFilterBar } from '../../../app/components/styledComponents/StyledFilterBar';
import StyledRadioButton from '../../../app/components/radio/StyledRadioButton';
import { useEffect, useRef } from 'react';
import CrossIcon from '../../../app/components/svgIcons/CrossIcon';
import { isEqual } from 'lodash';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import SettingsListingTable from './SettingsListingTable';
import AddCompanySettingsModal from '../components/AddCompanySettingsModal';
import TableSkeleton from '../../../assets/skeletons/tableSkeleton';
import { useGetAllCompanySettingsQuery, useRemoveCompanySettingMutation } from '../../company/companyApiSlice';
import { toast } from 'react-toastify';

const SettingsListingContainer = () => {
  const ref = useRef();
  const { compId } = useParams();
  const [sortFilter, setSortFilter] = useState(false);
  const [isOpen, setIsopen] = useState(false);
  const [editSettings, setEditSettings] = useState(false);
  const [addSettings, setAddSettings] = useState(false);
  const [editSettingData, setEditSettingData] = useState();
  const [deleteSetting] = useRemoveCompanySettingMutation();
  const initialFilters = {
    perPage: 5,
    page: 1,
    sortedColumn: { path: 'createdAt', order: 'DESC' },
    query: '',
  };

  const handleSubmit = async (values) => {
    setFilters({ ...values });
    setIsopen(false);
  };

  let ToggleFilterbar = (item) => {
    setIsopen(item === 'filter' ? !isOpen : false);
    setSortFilter(item === 'sort' ? !sortFilter : false);
  };
  const [filters, setFilters] = useState('');

  const handleSortBy = (item) => {
    const prevFilters = { ...filters };
    prevFilters.sort = item.value;
    prevFilters.sortDir = item.dir;
    setFilters({ ...prevFilters });
  };
  const sortFilters = [
    {
      id: 1,
      label: 'Added on (Latest First)',
      value: 'applied-latest-to-oldest',
      dir: 'DESC',
    },
    {
      id: 2,
      label: 'Applied on (Oldest First)',
      value: 'applied-oldest-to-latest',
      dir: 'ASC',
    },
    {
      id: 3,
      label: 'Ratings (Highest First)',
      value: 'SettingsRating',
      dir: 'DESC',
    },
    {
      id: 4,
      label: 'Lastest First (Lowest First)',
      value: 'last_activity',
      dir: 'ASC',
    },
    {
      id: 5,
      label: 'Location (Nearest First)',
      value: 'nearest',
      dir: 'ASC',
    },
  ];

  useEffect(() => {
    const concernedElement = document.querySelector('.click-text');
    document.addEventListener('mousedown', (event) => {
      if (!concernedElement?.contains(event?.target)) {
        setSortFilter(false);
      }
    });
  }, [sortFilter]);
  const [listing, setListing] = useState({ ...initialFilters });

  const { data: settingsRes, refetch, isFetching, error } = useGetAllCompanySettingsQuery({
    params: {
      perPage: listing.perPage,
      page: listing.page,
      query: listing.query && listing.query,
      sort: listing.sortedColumn.path,
      sortDir: listing.sortedColumn.order,
      ...filters,
    },
    id: compId
  });

  const [timer, setSearchTimer] = useState(null);
  const handleSearch = (evt) => {
    clearTimeout(timer);
    const query = evt.target.value;
    const newTimer = setTimeout(() => {
      setListing({ ...listing, page: 1, query: query });
    }, 2000);
    setSearchTimer(newTimer);
  };
  const handleSort = (sort) => {
    setListing((prevState) => ({
      ...prevState,
      sortedColumn: { ...sort },
      page: 1,
    }));
  };
  const handlePerPageChange = (perPage) => {
    setListing((prevState) => ({
      ...prevState,
      perPage: perPage.target.innerText,
      page: 1,
    }));
  };

  const handlePageChange = ({ selected }) => {
    setListing((prevState) => ({
      ...prevState,
      page: selected + 1,
    }));
  };

  const deleteCompanySetting = (id) => {
    deleteSetting({ settingId: id, compId: compId }).unwrap().then((res) => {
      toast.success(res?.message || "Setting deleted successfully")
      refetch();
    })
  }
  const [column, setColumn] = useState([
    // {
    //   id: 1,
    //   key: 'id',
    //   label: (
    //     <>
    //       <div className="d-flex">
    //         {/* <StyledCheckbox
    //           disabled={true}
    //           size={16}
    //             value={allCandSelect}
    //             onChange={() => onCheck(candRes)}
    //         /> */}

    //         <span className="mx-2">Id</span>
    //       </div>
    //     </>
    //   ),
    //   isChecked: true,
    //   alignBody: 'text-left align-middle',
    //   alignHeader: 'text-left align-middle cursor-pointer p-3',
    //   content: (data) => (
    //     <>
    //       <div className="d-flex p-2">
    //         {/* <StyledCheckbox
    //           size={15}
    //           disabled={true}
    //             value={candidateData?.checked}
    //             onChange={() => onCheck(candidateData)}
    //         /> */}
    //         <span className="small">
    //           {data?.id || '-'}
    //         </span>
    //       </div>
    //     </>
    //   ),
    // },
    {
      id: 2,
      key: 'accessKey',
      path: 'accessKey',
      label: 'Access Key',
      isChecked: true,
      alignBody: 'ps-4 py-3 align-middle',
      alignHeader: 'ps-4 py-3 align-middle cursor-pointer',
      content: (data) => (
        <>
          <h5 className="m-0 cut-text">
            {data?.accessKey || '-'}
          </h5>
          <span className="small cut-text">
            {data?.id || '-'}
          </span>
        </>
      ),
    },
    {
      id: 3,
      key: 'defaultValue',
      isChecked: true,
      label: 'Default Value',
      alignBody: 'align-middle ps-4 py-3',
      alignHeader: 'align-middle cursor-pointer ps-4 py-3',
      content: (data) => (
        <span className="m-0">
          {data?.defaultValue || '-'}
        </span>
      ),
    },
    {
      id: 4,
      key: 'newValue',
      isChecked: true,
      label: 'New Value',
      alignBody: 'align-middle ps-4 py-3',
      alignHeader: 'align-middle cursor-pointer ps-4 py-3',
      content: (data) => (
        <span className="m-0">
          {data?.companySettingValue || '-'}
        </span>
      ),
    },
    {
      id: 5,
      key: 'description',
      isChecked: true,
      label: 'Description',
      alignBody: 'align-middle ps-4 py-3 w-25 mx-50',
      alignHeader: 'align-middle cursor-pointer ps-4 py-3',
      content: (data) => (
        <span className="m-0">
          {data?.description || '-'}
        </span>
      ),
    },

    {
      id: 6,
      key: 'actions',
      isChecked: true,
      label: 'Actions',
      alignBody: 'text-center align-middle text-nowrap ps-4 py-3',
      alignHeader: 'text-center align-middle cursor-pointer ps-4 py-3',
      content: (data) => (
        <div className="d-flex align-items-center justify-content-center">
          <div className="text-primary pe-2 cursor-pointer"
            onClick={() => {
              setEditSettings(true)
              setEditSettingData(data);
            }}
          >
            <EditIcon />
          </div>
          <div className="text-primary cursor-pointer" onClick={() => deleteCompanySetting(data?.id)}>
            <TrashIcon />
          </div>
        </div>
      ),
    },
  ]);

  return (
    <div>
      <Portlet>
        <div className="portlet__body-bg">
          <PortletBody>
            <>
              <div className="flex-center mb-3">
                <div className="flex-grow-1">
                  <StyledIconInput
                    icon={<SearchIcon />}
                    className="mw-50"
                    $maxWidth="60%"
                    $marginLeft="3px"
                    onChange={handleSearch}
                    loading={isFetching}
                    placeholder="Search"
                  />
                </div>
                <div className="d-flex gap-2">
                  <StyledButton onClick={() => setAddSettings(!addSettings)}>
                    Add Settings
                  </StyledButton>
                  <StyledButton
                    className="px-2 py-1 feature-pending"
                    variant="icon"
                    onClick={() => ToggleFilterbar('filter')}
                    icon={<FilterIcon />}
                  >
                    Filter
                  </StyledButton>
                  <StyledButton
                    className="px-2 py-1 d-flex align-items-center feature-pending"
                    variant="icon"
                    icon={<SortIcon size="20" className="me-0" />}
                    id="sort"
                    onClick={() => ToggleFilterbar('sort')}
                  >
                    Sort
                  </StyledButton>
                </div>
              </div>

              {(error || settingsRes?.settings?.length === 0) ? <div className='d-flex justify-content-center align-items-center'>No Company Settings Found ...</div> :
                <>
                  {settingsRes?.settings?.length > 0 ? (
                    <SettingsListingTable
                      settings={settingsRes?.settings || []}
                      sortColumn={listing.sortedColumn}
                      onSort={handleSort}
                      filterTable={column}
                    />
                  ) : (
                    <TableSkeleton
                      cols={[
                        'Id',
                        'Access Key',
                        'Default Value',
                        'New Value',
                        'Description',
                        'Actions',
                      ]}
                      count={6}
                    />
                  )}
                  <PaginationPerPage
                    perPage={settingsRes?.perPage || 5}
                    pageNo={settingsRes?.page || 1}
                    onPerPageChange={handlePerPageChange}
                    totalCount={settingsRes?.total || 0}
                    onPageChange={handlePageChange}
                  />
                </>
              }
            </>
          </PortletBody>
        </div>
        {/* <div className="m-0 position-relative d-flex rounded flex-column click-text">
                {sortFilter && (
                  <StyledFilterBar
                    variant={'sort'}
                    className="bg-white p-3 align-items-center justify-content-center"
                  >
                    <div className="d-flex justify-content-between align-items-center mb-3">
                      <h5 className="mb-0">Sort By</h5>
                    </div>
                    {sortFilters.map((item, index) => {
                      return (
                        <div
                          className="d-flex align-items-center py-2 px-2 "
                          key={`sort-${item.value}-index`}
                        >
                          <StyledRadioButton
                            value={filters.sort === item.value}
                            label={item.label}
                            className="fw-bold"
                            onChange={() => handleSortBy(item)}
                          />
                        </div>
                      );
                    })}
                  </StyledFilterBar>
                )}
              </div>

              <div className="portlet__body-bg position-relative bg-transparent click-filters">
                {isOpen && (
                  <StyledFilterBar variant="filter" className="my-1">
                    <div className="d-flex justify-content-end">
                      <div
                        className="cursor-pointer mt-3 me-3"
                        onClick={ToggleFilterbar}
                      >
                        <CrossIcon />
                      </div>
                    </div>

                    <div className="sd-header">
                      <h4 className="mb-0">Filters</h4>
                      {!isEqual(filters, initialFilters) && (
                        <h6
                          className="mb-0 cursor-pointer"
                          onClick={() => {
                            setFilters('');
                            setIsopen(false);
                          }}
                        >
                          Clear filters
                        </h6>
                      )}
                    </div>
                    <div className="sd-body">
                      <Formik
                        initialValues={{
                          ...filters,
                        }}
                        validationSchema={Yup.object().shape({
                          appDate: Yup.string(),
                        })}
                        onSubmit={handleSubmit}
                        innerRef={ref}
                      >
                        {({ handleSubmit, values, submitForm, errors }) => (
                          <Form onSubmit={handleSubmit}>
                            {/* <SettingssFilterForm submitForm={submitForm} /> 
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </StyledFilterBar>
                )}
              </div> */}
      </Portlet>
      <AddCompanySettingsModal
        title={editSettings ? 'Edit Company Setting' : 'Add Company Setting'}
        show={editSettings ? editSettings : addSettings}
        onHide={() => {
          setAddSettings(false)
          setEditSettings(false);
        }}
        editSettingData={editSettingData}
        editSettings={editSettings}
        compId={compId}
        refetch={refetch}
      />
    </div>
  );
};

export default SettingsListingContainer;
