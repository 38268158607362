import DropDownIcon from '../svgIcons/DropDownIcon';
import ReactPaginate from 'react-paginate';
import DropdownItem from 'react-bootstrap/DropdownItem';
import CustomDropdown from '../dropdown/CustomDropDown';
import './_pagination.scss';

const PaginationPerPage = ({
  perPage,
  onPerPageChange,
  pageNo,
  onPageChange,
  totalCount,
}) => {
  const totalPageCount = totalCount / perPage;
  const pageSizes = [5, 10, 20, 50];
  return (
    <div className="flex-center justify-content-end text-gray">
      <div className="flex-center me-lg-3">
        <h6 className="m-0 me-4">
          Showing {pageNo * perPage - perPage + 1} -{' '}
          {pageNo * perPage > totalCount ? totalCount : pageNo * perPage} of
          <span className="ms-1 me-2">{totalCount}</span>
        </h6>
        <CustomDropdown
          dropdownToggle={
            <span className="flex-center bg-white p-0">
              <span className="me-1 me-lg-2">{perPage}</span>
              <DropDownIcon rotate="down" size={'8px'} />
            </span>
          }
          toggleClassName="bg-body p-0"
          dropdownMenu={
            <>
              {pageSizes.map((p) => (
                <DropdownItem key={p} onClick={(p) => onPerPageChange(p)}>
                  {p}
                </DropdownItem>
              ))}
            </>
          }
        />
      </div>

      <ReactPaginate
        pageCount={isNaN(totalPageCount) ? 0 : Math.ceil(totalPageCount)}
        onPageChange={onPageChange}
        forcePage={pageNo - 1}
        marginPagesDisplayed={1}
        containerClassName="pagination__container"
        pageLinkClassName="pagination__page"
        previousClassName="pagination__page-prev-next"
        nextClassName="pagination__page-prev-next"
        previousLabel={<DropDownIcon rotate="left" size={'8px'} />}
        nextLabel={<DropDownIcon rotate="right" size={'8px'} />}
        activeLinkClassName="pagination__page-active"
      />
    </div>
  );
};

export default PaginationPerPage;
