import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import CompanyListingContainer from './listing/CompanyListingContainer';
import CompanyEditContainer from './edit/companyEditContainer';
import JobEditContainer from './job/JobEditContainer';
import SwitchLicense from '../Licenses/components/SwitchLicense';

const JobRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<CompanyListingContainer />} />
      <Route path=":compId" element={<CompanyEditContainer />} />
      <Route path=":compId/:jobId" element={<JobEditContainer />} />
      <Route path="licence/:compId/switch-licence" element={<SwitchLicense/>} />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  )
};

export default JobRoutes;
