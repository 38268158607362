import React from 'react';

const ChangeStatusIcon = ({ size = 18 }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.6665 3.43752V5.90419C14.6665 7.40419 14.0665 8.00419 12.5665 8.00419H10.7665C9.2665 8.00419 8.6665 7.40419 8.6665 5.90419V3.43752C8.6665 1.93752 9.2665 1.33752 10.7665 1.33752H12.5665C14.0665 1.33752 14.6665 1.93752 14.6665 3.43752Z" stroke="#767476" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M7.3335 10.1042V12.5708C7.3335 14.0708 6.7335 14.6708 5.2335 14.6708H3.4335C1.9335 14.6708 1.3335 14.0708 1.3335 12.5708V10.1042C1.3335 8.60415 1.9335 8.00415 3.4335 8.00415H5.2335C6.7335 8.00415 7.3335 8.60415 7.3335 10.1042Z" stroke="#767476" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M14.6667 10.0042C14.6667 12.5842 12.58 14.6708 10 14.6708L10.7 13.5042" stroke="#767476" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M1.3335 6.00419C1.3335 3.42419 3.42016 1.33752 6.00016 1.33752L5.30016 2.50419" stroke="#767476" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

  );
};

export default ChangeStatusIcon;
