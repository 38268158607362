import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import CompanyInfoEditForm from '../creation/components/CompanyInfoEditForm';
import { findPhoneNumbersInText } from 'libphonenumber-js';

const CompanyEditForm = ({
  updateCompanyGenerelInfo,
  handleFileSelect,
  fileref,
  removeProfileImage,
  CompanyAvatar,
  compyInfo,
  refetch,
  loading,
  editable,
  setEditable,
}) => {
  const countryType = [
    {
      label: 'Standard',
      value: 'Standard',
    },
    {
      label: 'Partnership',
      value: 'Partnership',
    },
    {
      label: 'Agency',
      value: 'Agency',
    },
  ];

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    companyType: Yup.string().required('Required'),
    telephoneCode: Yup.string().required('Required'),
    telephone: Yup.string().when('telephoneCode', (telephoneCode, schema) =>
      schema
        .required('Required')
        .test(
          'is-valid-phone',
          'Invalid Number',
          (value) =>
            findPhoneNumbersInText(`${telephoneCode}${value}`)?.length > 0 &&
            !value?.includes('+'),
        ),
    ),
    address: Yup.string().required('Required'),
    buildingNo: Yup.string().required('Required'),
    postcode: Yup.string().required('Required'),
    county: Yup.object().required('Required'),
  });
  const handleSubmit = (values) => {
    values = {
      ...values,
      companyInformation: {
        benefits: values?.benefits || '',
        aboutCompany: values?.aboutCompany || '',
        dei: values?.dei || '',
      },
      telephone:
        values?.telephoneCode +
        (values?.telephone[0] === '0'
          ? values?.telephone.substring(1)
          : values?.telephone),
    };
    values.telephoneCode = undefined;
    updateCompanyGenerelInfo(values);
  };
  const [telephoneNumber, setTelephoneNumber] = useState([]);
  useEffect(() => {
    compyInfo?.telephone &&
      setTelephoneNumber(findPhoneNumbersInText(compyInfo?.telephone));
  }, [compyInfo]);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        companyEmail: compyInfo?.companyEmail || '',
        name: compyInfo?.name || '',
        companyType: 'Standard',
        telephone:
          telephoneNumber[0]?.number?.nationalNumber ?? compyInfo?.telephone,
        telephoneCode: telephoneNumber[0]?.number?.countryCallingCode
          ? `+${telephoneNumber[0]?.number?.countryCallingCode}`
          : '+44',
        address: compyInfo?.address || '',
        buildingNo: compyInfo?.buildingNo || '',
        postcode: compyInfo?.postcode || '',
        county:
          { label: compyInfo?.county, value: compyInfo?.county } || undefined,
        description: compyInfo?.description || '',
        benefits: compyInfo?.companyInformation?.benefits || '',
        aboutCompany: compyInfo?.companyInformation?.aboutCompany || '',
        dei: compyInfo?.companyInformation?.dei || '',
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <CompanyInfoEditForm
            isEdit={true}
            handleSubmit={handleSubmit}
            handleFileSelect={handleFileSelect}
            countryType={countryType}
            fileref={fileref}
            removeProfileImage={removeProfileImage}
            CompanyAvatar={CompanyAvatar}
            compyInfo={compyInfo}
            refetch={refetch}
            loading={loading}
            setEditable={setEditable}
            editable={editable}
          />
        </Form>
      )}
    </Formik>
  );
};

export default CompanyEditForm;
