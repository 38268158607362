import React from 'react';

const BagIcon = ({ size = 15,color="#4A154B", ...props }) => (
    <svg width={size} height={size} viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M6.6666 18.8333H13.3333C16.6833 18.8333 17.2833 17.4917 17.4583 15.8583L18.0833 9.19167C18.3083 7.15833 17.7249 5.5 14.1666 5.5H5.83327C2.27494 5.5 1.6916 7.15833 1.9166 9.19167L2.5416 15.8583C2.7166 17.4917 3.3166 18.8333 6.6666 18.8333Z" stroke={color} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M6.66675 5.49996V4.83329C6.66675 3.35829 6.66675 2.16663 9.33341 2.16663H10.6667C13.3334 2.16663 13.3334 3.35829 13.3334 4.83329V5.49996" stroke={color} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M11.6666 11.3333V12.1667C11.6666 12.175 11.6666 12.175 11.6666 12.1833C11.6666 13.0917 11.6583 13.8333 9.99992 13.8333C8.34992 13.8333 8.33325 13.1 8.33325 12.1917V11.3333C8.33325 10.5 8.33325 10.5 9.16659 10.5H10.8333C11.6666 10.5 11.6666 10.5 11.6666 11.3333Z" stroke={color} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M18.0417 9.66663C16.1167 11.0666 13.9167 11.9 11.6667 12.1833" stroke={color} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M2.18335 9.89172C4.05835 11.1751 6.17502 11.9501 8.33335 12.1917" stroke={color} strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
    
);

export default BagIcon;
