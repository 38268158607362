import React from 'react';

import LicensesIcon from '../../components/svgIcons/LicensesIcon';
// import DashboardIcon from '../../components/svgIcons/DashboardIcon';
import CompaniesIcon from '../../components/svgIcons/CompaniesIcon';
import ProductsIcon from '../../components/svgIcons/ProductsIcon';
import OpenSidebarIcon from '../../components/svgIcons/OpenSidebarIcon';
import SettingsIcon from '../../components/svgIcons/SettingsIcon';
import OccyLogoTransparent from '../../components/svgIcons/OccyLogoTransparent.png';

import SideBarIcon from '../../components/svgIcons/SidebarIcon';
import './_sidebar.scss';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { occyLs } from '../../../utils/localStorage';
import { useDispatch } from 'react-redux';
import { sideMenuState } from '../../../features/auth/authSlice';
import CampaignIcon from '../../components/svgIcons/CampaignIcon';
import PenWriteIcon from '../../components/svgIcons/PenWriteIcon';
import OnBoardingIcon from '../../components/svgIcons/OnBoardingIcon';
import AnalyticsIcon from '../../components/svgIcons/AnalyticsIcon';

const SideBar = ({ openSideMenu, setOpenSideMenu }) => {
  const sidebarVisible = !!occyLs.getObject('APP_SIDEBAR');
  const dispatch = useDispatch();
  const MenuItems = [
    // {
    //   name: 'Dashboard',
    //   icon: <DashboardIcon />,
    //   to: 'dashboard',
    // },
    {
      name: 'Companies',
      icon: <CompaniesIcon />,
      to: 'companies',
    },
    // {
    //   name: 'Job Posts',
    //   icon: <JobsIcon />,
    //   to: 'job-posts',
    // },
    // {
    //   name: 'Job Listing',
    //   icon: <JobsIcon />,
    //   to: 'job-listing',
    // },
    {
      name: 'Copywriting',
      icon: <PenWriteIcon />,
      to: 'copywriting',
    },
    {
      name: 'Products',
      icon: <ProductsIcon />,
      to: 'products',
    },
    {
      name: 'Licences',
      icon: <LicensesIcon />,
      to: 'licences',
    },
    {
      name: 'Campaigns',
      icon: <CampaignIcon />,
      to: 'campaigns',
    },
    {
      name: 'Settings',
      icon: <SettingsIcon />,
      to: 'settings',
    },
    {
      name: 'Analytics',
      icon: <AnalyticsIcon/>,
      to: 'analytics',
    },
    {
      name: 'OnBoarding',
      icon: <OnBoardingIcon/>,
      to: 'onboarding',
    },
    // {
    //   name: 'Product Adoption',
    //   icon: <ProductAdoptionIcon/>,
    //   to: 'product/adoption',
    // },
  ];
  return (
    <>
      <div
        className={` ${sidebarVisible ? 'openSidebar' : 'closeSidebar'
          } vh-100 bg-primary p-0 position-fixed`}
      >
        <div
          className={`${sidebarVisible
            ? 'd-flex justify-content-between align-items-center px-3 py-4'
            : 'justify-content-center d-flex pt-4 pb-4'
            }`}
        >
          {sidebarVisible ? 
          // <OccyLogoWhite />
          <img
          src={OccyLogoTransparent}
          alt="img"
          height={40}
          width={150}
          />
           : null}
          <div
            className="menuButton p-1 rounded-circle cursor-pointer"
            onClick={() => {
              dispatch(sideMenuState(!sidebarVisible));
              setOpenSideMenu(!openSideMenu);
            }}
          >
            {sidebarVisible ? <OpenSidebarIcon /> : <SideBarIcon />}
          </div>
        </div>
        <hr className={sidebarVisible ? 'mx-3 pb-1 ' : 'mx-2 pb-1'} />
        <StyledSideMenu>
          {MenuItems.map((item, index) => {
            return (
              <NavLink
                key={index}
                to={item?.to}
                className={({ isActive }) =>
                  `${isActive ? 'text-white' : 'tab-link'} ${sidebarVisible
                    ? 'd-flex align-items-center px-3 ms-2'
                    : 'justify-content-center d-flex py-3 '
                  } py-4 text-decoration-none`
                }
              >
                <div>{item.icon}</div>
                {sidebarVisible ? (
                  <p className="ms-3 mt-1 MenuText mb-0">{item.name}</p>
                ) : null}
              </NavLink>
            );
          })}
        </StyledSideMenu>
      </div>
    </>
  );
};
const StyledSideMenu = styled.div`
  .tab-link {
    color: ${({ theme }) => theme.primaryLight2};
  }
  max-height: 80%;
  overflow: auto;
  position: relative;
  ::-webkit-scrollbar-thumb, ::-webkit-scrollbar-track, ::-webkit-scrollbar-thumb:hover,  ::-webkit-scrollbar {
    background: #4a154b; 
`;
export default SideBar;
