import React from 'react'
import { Col, Row } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import styled from 'styled-components';
import PortletBody from '../../app/components/portlet/PortletBody';
import { Wrap } from '../styledComponents/Global';

const CompanyProfileSkeleton = () => {
    return (
        <>
            <PortletBody className="w-100 pt-2 py-0">
                <Row className="py-4">
                    <Col md={4}>
                        <StyledDiv
                            className="position-relative "
                        >
                            <Skeleton height={350} width={500} className="me-2 mt-2" />

                        </StyledDiv>
                    </Col>
                    <Col md={8} className="ps-5">
                        <Row className="py-0 mb-3">
                            <div className="col-12 col-md-12">
                                <div className="d-flex justify-content-between pl-3">
                                    <Skeleton height={20} width={250} className="me-2 mt-2" />
                                    <Skeleton height={20} width={40} className="me-2 mt-2" />
                                </div>
                            </div>
                        </Row>
                        {Array.from(new Array(4)).map((i, ind) => (
                            <Row className={`py-0 ${ind !== 0 && "mt-2"}`} key={`sekeltincards-${ind}`}>
                                <div className="col-12 col-md-6">
                                    <div className="mb-2">
                                        <Skeleton height={25} width={350} className="me-2 mt-2" />
                                        <Skeleton height={25} width={350} className="me-2 mt-2" />
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="mb-2">
                                        <Skeleton height={25} width={350} className="me-2 mt-2" />
                                        <Skeleton height={25} width={350} className="me-2 mt-2" />
                                    </div>
                                </div>
                            </Row>
                        ))}
                    </Col>
                </Row>
                <Row></Row>
            </PortletBody>
            <PortletBody className="w-100 mt-3">
                <Wrap className="">
                    <Row className="">
                        <div className="col-12 col-md-12">
                            <div className="d-flex justify-content-between">
                                <Skeleton height={20} width={200} className="me-2 mt-2" />
                                <Skeleton height={20} width={40} className="me-2 mt-2" />
                            </div>
                        </div>
                    </Row>
                    <Skeleton height={50} width={1500} className="me-2 mt-2" />
                </Wrap>
            </PortletBody>
        </>
    )
}

const StyledDiv = styled.div`
  height: 380px;
`;

export default CompanyProfileSkeleton