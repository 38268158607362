import React from 'react';
import { useState } from 'react';
import Portlet from '../../../../app/components/portlet/Portlet';
import PortletHeader from '../../../../app/components/portlet/PortletHeader';
import { productDetailstabs } from '../../assets/utills';
import { BundleTab } from './BundleTab';
import { ChannelTab } from './ChannelTab';
import ProductDetailsTile from './ProductDetailsTile';

const ProductDetailsContainer = () => {
  const [tabs, setTabs] = useState(productDetailstabs);
  const activeTab = tabs.find((t) => t.active).id;
  const onChangeTabs = (tab) => {
    setTabs((prevState) =>
      prevState.map((p) => {
        return p.id === tab
          ? {
              ...p,
              active: true,
            }
          : {
              ...p,
              active: false,
            };
      }),
    );
  };
  return (
    <Portlet className="m-4  bg-transparent">
      <PortletHeader
        containerClassName="px-4 pt-3 bg-white rounded-0 rounded-top"
        tabs={tabs}
        onChangeTabs={onChangeTabs}
        title={'SRO Premium'}
      />

      {activeTab === 1 && <ProductDetailsTile /> }
      {activeTab === 2 && <BundleTab activeTab={activeTab}/> }
      {activeTab === 3 && <ChannelTab activeTab={activeTab}/> }

    </Portlet>
  );
};

export default ProductDetailsContainer;
