import React from 'react';
import { Container, Row } from 'react-bootstrap';
import PortletBody from '../../../../app/components/portlet/PortletBody';
import EditIcon from '../../../../app/components/svgIcons/EditIcon';
import { StyledLabel, Wrap } from '../../../../assets/styledComponents/Global';

const ProductDetailsTile = () => {
  return (
    <Wrap className="col-12 justify-content-center align-items-center d-flex">
      <PortletBody className="col-6 mt-3 p-4">
        <Wrap className="d-flex justify-content-between align-items-center">
          <StyledLabel size="16px">Basic Information</StyledLabel>
          <EditIcon />
        </Wrap>

        <Container fluid>
          <Row>
            <Wrap className="col-2 py-2 px-0 mt-3">
              <StyledLabel className='text-lightBlack'>Title</StyledLabel>
            </Wrap>
            <Wrap className="col-5 py-2 mt-3 ms-5">
              <StyledLabel size="14px" weight="400">
              SRO Premium
              </StyledLabel>
            </Wrap>
            <Wrap className="col-12 border-top" />

            <Wrap className="col-2 py-2 px-0">
              <StyledLabel className='text-lightBlack'>Quantity</StyledLabel>
            </Wrap>
            <Wrap className="col-5 py-2 ms-5">
              <StyledLabel size="14px" weight="400">
                4
              </StyledLabel>
            </Wrap>
            <Wrap className="col-12 border-top" />

            <Wrap className="col-2 py-2 px-0">
              <StyledLabel className='text-lightBlack'>Access Key</StyledLabel>
            </Wrap>
            <Wrap className="col-5 py-2 ms-5">
              <StyledLabel size="14px" weight="400">
              asw32
              </StyledLabel>
            </Wrap>
            <Wrap className="col-12 border-top " />

            <Wrap className="col-2 py-2 px-0">
              <StyledLabel className='text-lightBlack'>Price (in Pounds)</StyledLabel>
            </Wrap>
            <Wrap className="col-5 py-2 ms-5">
              <StyledLabel size="14px" weight="400">
              50
              </StyledLabel>
            </Wrap>
            <Wrap className="col-12 border-top" />

            <Wrap className="col-2 py-2 px-0">
              <StyledLabel className='text-lightBlack'>Product Group</StyledLabel>
            </Wrap>
            <Wrap className="col-5 py-2 ms-5">
              <StyledLabel size="14px" weight="400">
              500
              </StyledLabel>
            </Wrap>
            <Wrap className="col-12 border-top" />

            <Wrap className="col-2 py-2 px-0">
              <StyledLabel className='text-lightBlack'>Description</StyledLabel>
            </Wrap>
            <Wrap className="col-5 py-2 ms-5">
              <StyledLabel size="14px" weight="400">
              Premium SRO Details
              </StyledLabel>
            </Wrap>
          </Row>
        </Container>
      </PortletBody>
    </Wrap>
  );
};

export default ProductDetailsTile;
