import styled from 'styled-components';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

const StyledButton = ({ loading, children, icon, ...props }) => (
  <ButtonStyled {...props} _children={children}>
    <>
      <span
        className={`spinner spinner-border spinner-border-sm me-2 ${
          !loading ? 'd-none' : 'd-inline-block'
        }`}
        style={{
          transition: 'all 0.3s linear',
        }}
      />
      {icon && !loading && (
        <span
          style={{
            display: 'flex',
            alignItems: 'center',
            marginRight: children ? 10 : 0,
          }}
        >
          {icon}
        </span>
      )}
      <span
        style={{
          transition: 'margin-left 0.3s ease-in',
        }}
      >
        {children}
      </span>
    </>
  </ButtonStyled>
);

const ButtonStyled = styled(Button)`
  ${({ variant, height, theme, _children }) => {
    const basicProperties = `
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    ${!_children ? 'padding: 0.57rem;' : ''}
    `;
    switch (variant) {
      case 'white':
        return `
        ${basicProperties}
        color: ${theme.primary};
        border: 1px solid ${theme.primary};
        &:hover{
          background: ${theme.primary};
          color: ${theme.white};
          border: 1px solid ${theme.primary};
          box-shadow: none;
          
        }
        `;
      case 'light':
        return `
        ${basicProperties}
        color: ${theme.primary};
        background: ${theme.primaryLight};
        border:2px solid ${theme.inputBorder};
        &:hover, &:active, &:focus {
          color: ${theme.primary};
          border: 2px solid ${theme.primary};
          box-shadow: none;
          svg {
           fill: ${theme.white}!important;
          }
        }
        `;
        case 'added':
          return `
            ${basicProperties}
            color: ${theme.finch};
            background: ${theme.berylGreen};
            padding-right: 33px;
            padding-left: 32px;
            &:hover{
              color: ${theme.finch};
            background: ${theme.berylGreen};
  
              box-shadow: none;
              svg {
               fill: ${theme.berylGreen}!important;
              }
            }
            `;
      case 'text':
        return `
        ${basicProperties}
        color: ${theme.inputBody};
        background: transparent;
        &:hover, &:focus, &:active {
         background: transparent;
          color: ${theme.primary};
          box-shadow: none;
          border-color: transparent!important;
        }
        `;
      case 'icon':
        return `
          ${basicProperties}
          color: ${theme.inputColor};
          background: transparent;
          border: 1px solid ${theme.inputBorder};
          &:hover, &:active, &:focus {
          border: 1px solid ${theme.inputBorder};
          background: transparent;
            color: ${theme.primary};
            box-shadow: none;
          }
          `;
      case 'iconBackground':
        return `
              ${basicProperties}
              color: ${theme.inputColor};
              background: ${theme.white};
              border: 1px solid ${theme.inputBorder};
              height: ${height ? height + 'px' : 'auto'};
              width: ${height ? height + 'px' : ''};
              padding:13px;
              &:hover, &:active, &:focus {
                background: ${theme.primary};
                color: ${theme.white};
                box-shadow: none;
              }
              `;
      default:
        return `
        ${basicProperties}
        `;
    }
  }}
`;

ButtonStyled.propTypes = {
  loading: PropTypes.bool,
  children: PropTypes.element.isRequired,
  icon: PropTypes.element,
};
export default StyledButton;
