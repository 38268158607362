import React, { useState } from 'react';
import { Container, Row } from 'react-bootstrap';
import { CSVLink } from 'react-csv';
import { Link } from 'react-router-dom';
import PortletBody from '../../../../../app/components/portlet/PortletBody';
import StyledButton from '../../../../../app/components/styledComponents/StyledButton';
import StyledFilterTabs from '../../../../../app/components/styledComponents/StyledFilterTabs';
import StyledIconInput from '../../../../../app/components/styledComponents/StyledIconInput';
import AddIcon from '../../../../../app/components/svgIcons/AddIcon';
import FilterIcon from '../../../../../app/components/svgIcons/FilterIcon';
import NewDownloadIcon from '../../../../../app/components/svgIcons/NewDownloadIcon';
import SearchIcon from '../../../../../app/components/svgIcons/SearchIcon';
import SortIcon from '../../../../../app/components/svgIcons/SortIcon';
import BundleTile from '../../Bundles/BundleTile';

const BundlesView = ({ productDetails }) => {
  const [addNewBundle, setAddNewBundle] = useState(false);
  const [bundleIndex, setBundleIndex] = useState();
  const getCsvData = () => {
    return productDetails?.product.productPackages.map((jr) => ({
      id: jr.id,
      productId: jr.productId,
      title: jr.title,
      quantity: jr.quantity,
      bundlePrice: jr.bundlePrice,
    }));
  };
  return (
    <>
      <div className='d-flex bg-body justify-content-center align-item-center w-100'>
        <PortletBody className="mt-4 py-4 w-100" >
          <div className="d-flex justify-content-between align-items-center mb-4 mt-2">
            <StyledIconInput
              icon={<SearchIcon />}
              className="mw-50 feature-pending"
              // onChange={handleSearch}
              placeholder="Search product by Name, Type or Access Key "
            />
            <StyledButton
              className="btn btn-primary me-3"
              icon={<AddIcon />}
            >
              <Link
                variant="text"
                className="text-decoration-none px-2 text-white"
                to={`/products/${productDetails?.product.id}/bundles`}
              >
                Add New
              </Link>
            </StyledButton>
            <StyledButton
              className="me-3 py-2 feature-pending-new"
              variant="iconBackground"
              icon={<FilterIcon />}
            // onClick={ToggleFilterbar}
            >
              Filter
            </StyledButton>
            <StyledFilterTabs>
              <div className="btn-group" role="group">
                <button
                  className="btn feature-pending-new"
                  id="sort"
                >
                  <SortIcon size={20} />
                </button>
                <button
                  className="btn"
                >
                  {productDetails?.product.productPackages && (
                    <CSVLink
                      data={getCsvData()}
                    >
                      <NewDownloadIcon size={20} />
                    </CSVLink>
                  )}
                </button>
              </div>
            </StyledFilterTabs>
          </div>
          <Container fluid>
            <Row>
              {productDetails?.product?.productPackages.map((bundle) => (
                <BundleTile key={'bundleTiles' + bundle.id} prodId={productDetails?.product.id} bundles={bundle} setAddNewBundle={setAddNewBundle} setBundleIndex={setBundleIndex} activeTab={'1'} viewOnly={'true'}
                // deletebundle={deletebundle}
                addNewBundle={addNewBundle}
                bundleIndex={bundleIndex}
                />
              ))}
            </Row>
          </Container>
        </PortletBody>
      </div>

    </>
  );
};

export default BundlesView;
