import React from 'react';
import Table from '../../../app/components/table/Table';

const CompanyProductListingTable = ({
  companyProducts,
  sortColumn,
  onSort,
  filterTable,
  key
}) => {
  return (
    <>
      <Table
        data={companyProducts}
        columns={filterTable}
        sortColumn={sortColumn}
        onSort={onSort}
      />
    </>
  );
};

export default CompanyProductListingTable;
