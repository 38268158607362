import React, { Fragment, useEffect, useState } from 'react';
import StyledColoredTag from '../../../app/components/styledComponents/StyledColoredTag';
import Portlet from '../../../app/components/portlet/Portlet';
import PortletHeader from '../../../app/components/portlet/PortletHeader';
import StyledButton from '../../../app/components/styledComponents/StyledButton';
import PortletBody from '../../../app/components/portlet/PortletBody';
import { StyledSpan, Wrap } from '../../../assets/styledComponents/Global';
import { StyledLabel } from '../../Campaigns/CampaignDetails';
import styled from 'styled-components';
import StyledIconBoxColored from '../../../app/components/styledComponents/StyledIconBoxColored';
import StyledTextContainer from '../../../app/components/styledComponents/StyledTextContainer';
import FormikTextEditor from '../../../app/components/formikComponents/FormikTextEditor';
import FormikStyledTextArea from '../../../app/components/formikComponents/FormikTextArea';
import theme from '../../../utils/themes';
import RadioMicrophoneIcon from '../../../app/components/svgIcons/RadioMicrophoneIcon';
import CopyClipboardIcon from '../../../app/components/svgIcons/CopyClipboardIcon';
import TrophyIcon from '../../../app/components/svgIcons/TrophyIcon';
import PenNibStarIcon from '../../../app/components/svgIcons/PenNibStarIcon';
import { copyText, renderHtml } from '../../../utils/helper';
import { copyWriterVersionTab } from '../assets/utils';
import TickCircleIcon from '../../../app/components/svgIcons/TickCircleIcon';
import CommentDesignIcon from '../../../app/components/svgIcons/CommentDesignIcon';
import StyledToolTip from '../../../app/components/styledComponents/StyledToolTip';
import StyledCharacterCount from '../../../app/components/styledComponents/StyledCharacterCount';

const CopyWritingEditDetails = ({ copywriteDetails, reviewLoading, updateLoading, values, setValues }) => {

  const useTabState = (initialDetails) => {
    const [activeTab, setActiveTab] = useState(1);
    const [tabs, setTabs] = useState(copyWriterVersionTab);

    const getProductDetails = () => {
      if (initialDetails?.copyWriteReqDetails.length === 1) {
        setTabs((prevState) => prevState.filter((p) => p.id === 1));
      } else if (initialDetails?.copyWriteReqDetails.length === 2) {
        setTabs((prevState) => prevState.filter((p) => p.id !== 3));
      };
    };

    const onChangeTabs = (tab) => {
      setActiveTab(tab);
      setTabs((prevState) =>
        prevState.map((p) => {
          return p.id === tab
            ? {
              ...p,
              active: true,
            }
            : {
              ...p,
              active: false,
            };
        }),
      );
    };

    useEffect(() => {
      getProductDetails();
    }, [initialDetails]);

    return { activeTab, tabs, onChangeTabs };
  };
  const copyWriteReqDetails = copywriteDetails?.copyWriteReqDetails;
  const copyWriteReqDetailsLength = copyWriteReqDetails?.length;

  const { activeTab: activeTab1, tabs: tabs1, onChangeTabs: onChangeTabs1 } = useTabState(copywriteDetails);
  const { activeTab: activeTab2, tabs: tabs2, onChangeTabs: onChangeTabs2 } = useTabState(copywriteDetails);
  const { activeTab: activeTab3, tabs: tabs3, onChangeTabs: onChangeTabs3 } = useTabState(copywriteDetails);

  const removeTags = (str) => {
    if (str === null || str === '') {
      return false;
    }
    str = str.toString();
    return str.replace(/<[^>]*>/g, '');
  };


  const getTotalCharacterCount = (values) => {
    const elevatorPitchCount = values.elevatorPitch.length || 0;
    const jobDescriptionCount = values.jobDescription.length || 0;
    const jobRequirementCount = values.jobRequirement.length || 0;

    return elevatorPitchCount + jobDescriptionCount + jobRequirementCount;
  };
  return (
    <>
      <Portlet>
        <PortletHeader
          title={<div className='d-flex'>
            <span className='pe-3'>{copyWriteReqDetails[0]?.job?.title}</span>
            {<StyledColoredTag color={theme.blue} backgroundColor={theme.lightSkyBlue}>
              {copyWriteReqDetails[0]?.job?.company?.name}
            </StyledColoredTag>
            }
          </div>
          }
          subTitle={<StyledLabel size='16px' weight='600' color={theme.borderColor}>
            Improve copy and add suggestions for the job template
          </StyledLabel>}
          actions={
            !['IN_REVIEW', 'COMPLETED'].includes(copyWriteReqDetails?.[0]?.job?.copywriteStatus) && (
              <div className='d-flex '>

                <StyledButton
                  variant="white"
                  className="ms-auto mt-3 mb-5"
                  type="submit"
                  onClick={() => setValues({ ...values, buttonType: 'Draft' })}
                  loading={updateLoading}
                  disabled={updateLoading}
                >
                  Save changes
                </StyledButton>


                <StyledButton
                  className="btn btn-primary mx-2 mt-3 mb-5"
                  type="submit"
                  onClick={() => setValues({ ...values, buttonType: 'Save' })}
                  loading={reviewLoading}
                  disabled={reviewLoading}
                >
                  {`${copyWriteReqDetailsLength > 2 ? 'Send final copy' : copyWriteReqDetailsLength > 1 && copyWriteReqDetails[copyWriteReqDetailsLength - 1]?.job.copywriteStatus !== 'IN_PROGRESS' ? 'Send final copy' : 'Send for review'}`}
                </StyledButton>
              </div>
            )
          }
        />
      </Portlet>

      <Portlet className="bg-body m-3 ">
        <PortletBody className="rounded p-5">
          <StyledWrap className={`d-flex align-items-center justify-content-start bg-white pb-4 gap-3`}>
            <StyledIconBoxColored className={'rounded'} color={theme.WhiteLinen} icon={<RadioMicrophoneIcon />} />
            <Wrap className='d-flex flex-column'>
              <StyledSpan size='16px' className='text fw-semibold'>Elevator Pitch</StyledSpan>
              <StyledLabel size='12px' weight='500' className='text' color={theme.borderColor}>A brief and persuasive job description to attract potential candidates.</StyledLabel>
            </Wrap>
          </StyledWrap>
          <div className='row'>
            <div className={`${!['IN_REVIEW', 'COMPLETED'].includes(copyWriteReqDetails?.[0]?.job?.copywriteStatus) ? 'col-5' : 'col-12'}`}>
              <div className='d-flex justify-content-between'>
                <span>
                  {copyWriteReqDetailsLength > 1 ? <PortletHeader
                    containerClassName="p-0 mt-2"
                    headerClassName="py-0 ps-0  mx-2"
                    tabs={tabs1}
                    onChangeTabs={onChangeTabs1}
                  /> :
                    <StyledLabel size='14px' weight='600' className='pb-3'>Original Copy</StyledLabel>
                  }
                </span>
                {!['IN_REVIEW', 'COMPLETED'].includes(copyWriteReqDetails?.[0]?.job?.copywriteStatus) ?
                  <StyledCopy relative={copyWriteReqDetailsLength > 1}>
                    <StyledButton
                      icon={<>
                        <StyledToolTip title="Copy Text" childrenClass="ms-0 cursor-pointer">
                          <CopyClipboardIcon />
                        </StyledToolTip>
                      </>}
                      variant="text"
                      onClick={() => {
                        let text = '';
                        if (activeTab1 === 1) {
                          text = removeTags(copyWriteReqDetails[0]?.elevatorPitch);
                        } else if (activeTab1 === 2) {
                          text = removeTags(copyWriteReqDetails[1]?.elevatorPitch);
                        } else {
                          text = removeTags(copyWriteReqDetails[2]?.elevatorPitch);
                        }
                        copyText(text);
                      }}
                    />  </StyledCopy> :
                  copyWriteReqDetails[copyWriteReqDetailsLength - 1]?.job.copywriteStatus === 'COMPLETED' &&
                  <StyledColoredTag
                    className="rounded-pill mx-0"
                    backgroundColor="rgba(224, 242, 241, 0.35)"
                    $height='36px'
                  >
                    <TickCircleIcon color={theme.IconGreen} className="me-1" />{' '}
                    <StyledLabel color={theme.green}>
                      {copyWriteReqDetails[copyWriteReqDetailsLength - 1]?.finalVersionTracking?.elevatorPitch === 1 && "Original Version Picked"}
                      {copyWriteReqDetails[copyWriteReqDetailsLength - 1]?.finalVersionTracking?.elevatorPitch === 2 && "Version 1 Picked"}
                      {copyWriteReqDetails[copyWriteReqDetailsLength - 1]?.finalVersionTracking?.elevatorPitch === 3 && "Version 2 Picked"}
                    </StyledLabel>

                  </StyledColoredTag>
                }
              </div>
              {copyWriteReqDetails && copyWriteReqDetails.map((data, index) => (
                <Fragment key={`req-details-${index}`}>
                  {activeTab1 === index + 1 && (
                    <StyledTextContainer scrollable="auto" className='p-4 rounded rounded-4' >
                      {renderHtml(data.elevatorPitch)}
                    </StyledTextContainer>
                  )}
                </Fragment>
              ))}
            </div>
            {!['IN_REVIEW', 'COMPLETED'].includes(copyWriteReqDetails?.[0]?.job?.copywriteStatus) && (
              <StyledContainer className={`col-7 ${copyWriteReqDetailsLength > 1 ? 'multi-version' : ''}`} >
                {copyWriteReqDetailsLength === 1 && copyWriteReqDetails[0]?.job?.copywriteStatus !== "IN_PROGRESS" ? (
                  <StyledLabel size='14px' weight='600' className='pb-3'>
                    Edit Copy
                  </StyledLabel>
                ) : (
                  <StyledLabel size='14px' weight='600' className='pb-2'>
                    {copyWriteReqDetailsLength === 2 && copyWriteReqDetails[0]?.job?.copywriteStatus === "IN_PROGRESS" && copyWriteReqDetails[1]?.job?.copywriteStatus === "IN_PROGRESS" ? 'Copy Version 1' : 'Copy Version 2'}
                  </StyledLabel>
                )}
                <div className="">
                  <FormikTextEditor
                    className=""
                    name="elevatorPitch"
                    borderRadiusEditor={'0 0 8px 8px'}
                    id="elevatorPitch"
                    maxLength={5000}
                    totalCount={getTotalCharacterCount(values)}
                    placeholder="Enter elevator details"
                  />
                </div>
              </StyledContainer>
            )}
          </div>
          {copyWriteReqDetails[copyWriteReqDetailsLength - 1]?.clientComments?.elevatorPitch &&
            <div className='my-4'>
              <StyledTextContainer
                className='p-4 rounded'
                color={theme.skyBlueMatchColor}
                toggle={true}
                toggleScroll={true}
                headerIcon={<CommentDesignIcon />}
                header={`Comment from ${copyWriteReqDetails[copyWriteReqDetailsLength - 1]?.job?.company?.name}`}
              >
                {renderHtml(copyWriteReqDetails[copyWriteReqDetailsLength - 1]?.clientComments.elevatorPitch)}
              </StyledTextContainer>
            </div>
          }

        </PortletBody>
      </Portlet>

      <Portlet className="bg-body m-3 ">
        <PortletBody className="rounded p-5">
          <StyledWrap className={`d-flex align-items-center justify-content-start bg-white pb-4 gap-3`}>
            <StyledIconBoxColored className={'rounded'} color={theme.LavenderColor} icon={<TrophyIcon />} />
            <Wrap className='d-flex flex-column'>
              <StyledSpan size='16px' className='text fw-semibold'>Job Description</StyledSpan>
              <StyledLabel size='12px' weight='500' className='text' color={theme.borderColor}>Write job specifics that attracts potential candidates</StyledLabel>
            </Wrap>
          </StyledWrap>
          <div className='row'>
            <div className={`${!['IN_REVIEW', 'COMPLETED'].includes(copyWriteReqDetails?.[0]?.job?.copywriteStatus) && !['IN_REVIEW', 'COMPLETED'].includes(copyWriteReqDetails?.[0]?.job?.copywriteStatus) ? 'col-5' : 'col-12'}`}>
              <div className='d-flex justify-content-between'>
                <span>
                  {copyWriteReqDetailsLength > 1 ? <PortletHeader
                    containerClassName="p-0 mt-2"
                    headerClassName="py-0 ps-0 mx-2"
                    tabs={tabs2}
                    onChangeTabs={onChangeTabs2}
                  /> :
                    <StyledLabel size='14px' weight='600' className='pb-3'>Original Copy</StyledLabel>
                  }
                </span>
                {!['IN_REVIEW', 'COMPLETED'].includes(copyWriteReqDetails?.[0]?.job?.copywriteStatus) ?
                  <StyledCopy relative={copyWriteReqDetailsLength > 1}>
                    <StyledButton
                      icon={
                        <>
                          <StyledToolTip title="Copy Text" childrenClass="ms-0 cursor-pointer">
                            <CopyClipboardIcon />
                          </StyledToolTip>
                        </>
                      }
                      variant="text"
                      onClick={() => {
                        let text = '';
                        if (activeTab2 === 1) {
                          text = removeTags(copyWriteReqDetails[0]?.jobDescription);
                        } else if (activeTab2 === 2) {
                          text = removeTags(copyWriteReqDetails[1]?.jobDescription);
                        } else {
                          text = removeTags(copyWriteReqDetails[2]?.jobDescription);
                        }
                        copyText(text);
                      }}
                    /></StyledCopy>
                  :
                  copyWriteReqDetails[copyWriteReqDetailsLength - 1]?.job.copywriteStatus === 'COMPLETED' &&
                  <StyledColoredTag
                    className="rounded-pill mx-0"
                    backgroundColor="rgba(224, 242, 241, 0.35)"
                    $height='36px'
                  >
                    <TickCircleIcon color={theme.IconGreen} className="me-1" />{' '}
                    <StyledLabel color={theme.green}>
                      {copyWriteReqDetails[copyWriteReqDetailsLength - 1]?.finalVersionTracking?.jobDescription === 1 && "Original Version Picked"}
                      {copyWriteReqDetails[copyWriteReqDetailsLength - 1]?.finalVersionTracking?.jobDescription === 2 && "Version 1 Picked"}
                      {copyWriteReqDetails[copyWriteReqDetailsLength - 1]?.finalVersionTracking?.jobDescription === 3 && "Version 2 Picked"}
                    </StyledLabel>

                  </StyledColoredTag>
                }
              </div>
              {copyWriteReqDetails && copyWriteReqDetails.map((data, index) => (
                <Fragment  key={`resdetails2-${index}`}>
                  {activeTab2 === index + 1 && (
                    <StyledTextContainer scrollable="auto" className='p-4 rounded rounded-4'>
                      {renderHtml(data.jobDescription)}
                    </StyledTextContainer>
                  )}
                </Fragment>
              ))}
            </div>
            {!['IN_REVIEW', 'COMPLETED'].includes(copyWriteReqDetails?.[0]?.job?.copywriteStatus) && (
              <StyledContainer className={`col-7 ${copyWriteReqDetailsLength > 1 ? 'multi-version' : ''}`}>
                {copyWriteReqDetailsLength === 1 && copyWriteReqDetails[0]?.job?.copywriteStatus !== "IN_PROGRESS" ? (
                  <StyledLabel size='14px' weight='600' className='pb-3'>
                    Edit Copy
                  </StyledLabel>
                ) : (
                  <StyledLabel size='14px' weight='600' className='pb-2'>
                    {copyWriteReqDetailsLength === 2 && copyWriteReqDetails[0]?.job?.copywriteStatus === "IN_PROGRESS" && copyWriteReqDetails[1]?.job?.copywriteStatus === "IN_PROGRESS" ? 'Copy Version 1' : 'Copy Version 2'}
                  </StyledLabel>
                )}
                <div className="">
                  <FormikTextEditor
                    className=""
                    name="jobDescription"
                    borderRadiusEditor={'0 0 8px 8px'}
                    id="jobDescription"
                    maxLength={5000}
                    totalCount={getTotalCharacterCount(values)}
                    placeholder="Enter jobDescription details"
                  />
                </div>
              </StyledContainer>
            )}
          </div>
          {copyWriteReqDetails[copyWriteReqDetailsLength - 1]?.clientComments?.jobDescription &&
            <div className='my-4'>
              <StyledTextContainer
                className='p-4 rounded'
                color={theme.skyBlueMatchColor}
                toggle={true}
                toggleScroll={true}
                headerIcon={<CommentDesignIcon />}
                header={`Comment from ${copyWriteReqDetails[copyWriteReqDetailsLength - 1]?.job?.company?.name}`}
              >
                {renderHtml(copyWriteReqDetails[copyWriteReqDetailsLength - 1]?.clientComments.jobDescription)}
              </StyledTextContainer>
            </div>
          }

        </PortletBody>
      </Portlet>

      <Portlet className="bg-body m-3 ">
        <PortletBody className="rounded p-5">
          <StyledWrap className={`d-flex align-items-center justify-content-start bg-white pb-4 gap-3`}>
            <StyledIconBoxColored className={'rounded'} color={theme.NCSColor} icon={<PenNibStarIcon />} />
            <Wrap className='d-flex flex-column'>
              <StyledSpan size='16px' className='text fw-semibold'>Requirements</StyledSpan>
              <StyledLabel size='12px' weight='500' className='text' color={theme.borderColor}>Highlight the critical competencies and expertise necessary for this role</StyledLabel>
            </Wrap>
          </StyledWrap>
          <div className='row'>
            <div className={`${!['IN_REVIEW', 'COMPLETED'].includes(copyWriteReqDetails?.[0]?.job?.copywriteStatus) && !['IN_REVIEW', 'COMPLETED'].includes(copyWriteReqDetails?.[0]?.job?.copywriteStatus) ? 'col-5' : 'col-12'}`}>
              <div className='d-flex justify-content-between'>
                <span>
                  {copyWriteReqDetailsLength > 1 ? <PortletHeader
                    containerClassName="p-0 mt-2"
                    headerClassName="py-0 ps-0  mx-2"
                    tabs={tabs3}
                    onChangeTabs={onChangeTabs3}
                  /> :
                    <StyledLabel size='14px' weight='600' className='pb-3'>Original Copy</StyledLabel>
                  }
                </span>
                {!['IN_REVIEW', 'COMPLETED'].includes(copyWriteReqDetails?.[0]?.job?.copywriteStatus) ?
                  <StyledCopy relative={copyWriteReqDetailsLength > 1}>
                    <StyledButton
                      icon={<>
                        <StyledToolTip title="Copy Text" childrenClass="ms-0 cursor-pointer">
                          <CopyClipboardIcon />
                        </StyledToolTip>
                      </>}
                      variant="text"
                      onClick={() => {
                        let text = '';
                        if (activeTab3 === 1) {
                          text = removeTags(copyWriteReqDetails[0]?.jobRequirement);
                        } else if (activeTab3 === 2) {
                          text = removeTags(copyWriteReqDetails[1]?.jobRequirement);
                        } else {
                          text = removeTags(copyWriteReqDetails[2]?.jobRequirement);
                        }
                        copyText(text);
                      }}
                    /></StyledCopy> :
                  copyWriteReqDetails[copyWriteReqDetailsLength - 1]?.job.copywriteStatus === 'COMPLETED' &&
                  <StyledColoredTag
                    className="rounded-pill mx-0"
                    backgroundColor="rgba(224, 242, 241, 0.35)"
                    $height='36px'
                  >
                    <TickCircleIcon color={theme.IconGreen} className="me-1" />{' '}
                    <StyledLabel color={theme.green}>
                      {copyWriteReqDetails[copyWriteReqDetailsLength - 1]?.finalVersionTracking?.jobRequirement === 1 && "Original Version Picked"}
                      {copyWriteReqDetails[copyWriteReqDetailsLength - 1]?.finalVersionTracking?.jobRequirement === 2 && "Version 1 Picked"}
                      {copyWriteReqDetails[copyWriteReqDetailsLength - 1]?.finalVersionTracking?.jobRequirement === 3 && "Version 2 Picked"}
                    </StyledLabel>

                  </StyledColoredTag>
                }
              </div>
              {copyWriteReqDetails && copyWriteReqDetails.map((data, index) => (
                <Fragment  key={`resdetails3-${index}`}>
                  {activeTab3 === index + 1 && (
                    <StyledTextContainer scrollable="auto" className='p-4 rounded rounded-4' >
                      {renderHtml(data.jobRequirement)}
                    </StyledTextContainer>
                  )}
                </Fragment>
              ))}
            </div>
            {!['IN_REVIEW', 'COMPLETED'].includes(copyWriteReqDetails?.[0]?.job?.copywriteStatus) && (
              <StyledContainer className={`col-7 ${copyWriteReqDetailsLength > 1 ? 'multi-version' : ''}`}>
                {copyWriteReqDetailsLength === 1 && copyWriteReqDetails[0]?.job?.copywriteStatus !== "IN_PROGRESS" ? (
                  <StyledLabel size='14px' weight='600' className='pb-3'>
                    Edit Copy
                  </StyledLabel>
                ) : (
                  <StyledLabel size='14px' weight='600' className='pb-2'>
                    {copyWriteReqDetailsLength === 2 && copyWriteReqDetails[0]?.job?.copywriteStatus === "IN_PROGRESS" && copyWriteReqDetails[1]?.job?.copywriteStatus === "IN_PROGRESS" ? 'Copy Version 1' : 'Copy Version 2'}
                  </StyledLabel>
                )}
                <div className="">
                  <FormikTextEditor
                    className=""
                    name="jobRequirement"
                    borderRadiusEditor={'0 0 8px 8px'}
                    id="jobRequirement"
                    placeholder="Enter jobRequirement details"
                    totalCount={getTotalCharacterCount(values)}
                    maxLength={5000}
                  />
                </div>
              </StyledContainer>
            )}
            <StyledCharacterCount values={values} />
          </div>
          {copyWriteReqDetails[copyWriteReqDetailsLength - 1]?.clientComments?.jobRequirement &&
            <div className='my-4'>
              <StyledTextContainer
                className='p-4 rounded'
                color={theme.skyBlueMatchColor}
                toggle={true}
                toggleScroll={true}
                headerIcon={<CommentDesignIcon />}
                header={`Comment from ${copyWriteReqDetails[copyWriteReqDetailsLength - 1]?.job?.company?.name}`}
              >
                {renderHtml(copyWriteReqDetails[copyWriteReqDetailsLength - 1]?.clientComments?.jobRequirement)}
              </StyledTextContainer>
            </div>
          }

        </PortletBody>
      </Portlet>

      {!['IN_REVIEW', 'COMPLETED'].includes(copyWriteReqDetails?.[0]?.job?.copywriteStatus) && (
        <Portlet className="bg-body m-3">
          <PortletBody className="rounded p-5">
            <Wrap className='d-flex flex-column'>
              <StyledSpan size='16px' className='text fw-semibold my-1'>Suggestions</StyledSpan>
              <StyledLabel size='12px' weight='500' className='text my-1' color={theme.borderColor}>Recommend improvements on the overall job advert description</StyledLabel>
            </Wrap>
            <div className="col-12 col-md-12">
              <div className="my-3">
                <FormikStyledTextArea
                  label="suggesttion"
                  id="suggestions"
                  name="suggestions"
                  rows="6"
                  placeholder="Write your suggestions"
                  className="rounded"
                />
              </div>
            </div>
          </PortletBody>
        </Portlet>

      )}

    </>
  );
};
const StyledWrap = styled.div`
margin : 1px 1px 0px 0px;
`;
const StyledContainer = styled.div`
margin-top:0;
&.multi-version {
  margin-top: 20px;
}
`;
const StyledCopy = styled.div`
  position: ${props => props.relative ? 'relative' : 'relative'};
  bottom: ${props => props.relative ? 'auto' : '9px'};
  top: ${props => props.relative ? '9px' : 'auto'}
`;
export default CopyWritingEditDetails;