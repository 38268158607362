import React from 'react';

const PortletBody = ({ withBg, className = '', style, children, ...props }) => (
  <div
    className={`${className} portlet__body${withBg ? '-bg' : ''}`}
    style={style}
    {...props}
  >
    {children}
  </div>
);

export default PortletBody;
