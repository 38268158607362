import React, { useState } from 'react';
import { copyText } from '../../../utils/helper';
import CopyIcon from '../svgIcons/CopyIcon';
import StyledToolTip from './StyledToolTip';

const StyledCopy = ({ text }) => {
  const [linkCopied, setLinkCopied] = useState(false);

  return (
    <StyledToolTip
      title={linkCopied ? 'Copied' : 'Copy to clipboard'}
      className="mt-2"
      placement="top"
    >
      <span
        onClick={() => {
          copyText(text);
          setLinkCopied(true);
        }}
      >
        <CopyIcon className="cursor-pointer" />
      </span>
    </StyledToolTip>
  );
};

export default StyledCopy;
