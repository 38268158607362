import React, { lazy, Suspense } from 'react';
import { useState } from 'react';
import Portlet from '../../../app/components/portlet/Portlet';
import PortletHeader from '../../../app/components/portlet/PortletHeader';
import { getActiveTabId } from '../../../utils/helper';
import PortletBody from '../../../app/components/portlet/PortletBody';
import { useEffect } from 'react';
import { useRef } from 'react';
import Chart from 'chart.js/auto';
import {
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
} from 'chart.js';
import AnalyticSkeleton from '../../../assets/skeletons/analyticsSkeleton';
import { FiltersTiles } from './filterForms/FilterTiles';
import styled from 'styled-components';
import CrossIcon from '../../../app/components/svgIcons/CrossIcon';
import { isEmpty, isEqual } from 'lodash';
import { Form, Formik } from 'formik';
import DropDownIcon from '../../../app/components/svgIcons/DropDownIcon';
import CompaniesFilterForm from './filterForms/CompaniesFilterForm';
import JobFilterForm from './filterForms/JobFilterForm';
import HiringPlanFilterForm from './filterForms/HiringPlanFilterForm';
// import CustomersFilterForm from './filterForms/CustomersFilterForm';
import { StyledLabel } from '../../../assets/styledComponents/Global';
import theme from '../../../utils/themes';
import {
  useCandidatepoolQuery,
  useThirdPartyMetricsQuery,
} from '../analyticsApiSlice';
import moment from 'moment';
import { occyLs } from '../../../utils/localStorage';
// import { useGetAllCompaniesListMutation } from '../../company/companyApiSlice';
import {
  getFiltersParamsValues,
  campaignTabfiltersKeys,
  candidateTabfiltersKeys,
  jobTabfiltersKeys,
  getParamsbytab,
} from '../assets/analyticsUtil';
import DataExportModal from './DataExportModal';
import StyledSwitch from '../../../app/components/styledComponents/StyledSwitch';

// import ApplicationsFilterForm from './filterForms/ApplicationsFilterForm';
const OnboardingOverView = lazy(() => import('./Onboarding'));
const FeatureAdoption = lazy(() => import('./FeatureAdoption'));
const ThirdParty = lazy(() => import('./ThirdParty'));
const Copywriting = lazy(() => import('./Copywriting'));
const KeyPerformanceMetrics = lazy(() => import('./KeyPerformanceMetrics'));
const AnalyticTopFilter = lazy(() =>
  import('../components/TopFilter/AnalyticTopFilter'),
);

const ProductAdoptionDashboard = () => {
  Chart.register(
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController,
  );
  const [dataExport, setDataExport] = useState(false);
  const analyticsTabs = [

    {
      id: 2,
      title: 'Key Metrics',
      active: true,
    },
    {
      id: 3,
      title: 'Onboarding',
      active: false,
    },
    {
      id: 4,
      title: 'Third Party Integrations',
      active: false,
    },
    {
      id: 5,
      title: 'Feature Adoption',
      active: false,
    },
    // {
    //   id: 6,
    //   title: 'Copywriting',
    //   active: false,
    // },
  ];
  const [refresh, setRefresh] = useState(false);
  const [tabs, setTabs] = useState(analyticsTabs);
  const activeTab = getActiveTabId(tabs);
  // const [getAllCompanies] = useGetAllCompaniesListMutation();
  const onChangeTabs = (tab) => {
    refetch();
    setTabs((prevState) =>
      prevState?.map((p) => {
        return p.id === tab
          ? {
              ...p,
              active: true,
            }
          : {
              ...p,
              active: false,
            };
      }),
    );
    // setListing({ ...listing, page: 1, query: '' });
  };
  const [filters, setFilters] = useState('');
  const [show, isShow] = useState(false);
  const menuRef = useRef();
  const filterRef = useRef(null);
  const [isOpen, setIsopen] = useState(false);
  const [params, setParams] = useState({});
  const [daterange, setDateRange] = useState({
    fromDate: moment().subtract(12, 'months').add(1, 'days').set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    }),
    toDate: moment().set({
      hour: 23,
      minute: 59,
      second: 59,
      millisecond: 0,
    }),
  });
  // const [appliedValues, setApplyedValues] = useState({ selectedCompany: '' });
  // const { user } = useAuth();

  // let companyIdPayload = {
  //   companyIds: filters?.companyIds?.map((item) => item?.value),
  // };

  useEffect(() => {
    if (filters?.companyIds?.length === 0) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        jobIds: '',
        industryIds: '',
        companySize:'',
        departmentIds: '',
        hiringManagerIds: '',
        approverIds: '',
        campaignName: '',
        campaignJobName: '',
        channelIds: '',
      }));
    }
  }, [filters.companyIds]);

  useEffect(() => {
    let handler = (e) => {
      if (!menuRef?.current?.contains(e.target)) {
        isShow(false);
      }
    };

    document.addEventListener('mousedown', handler);

    return document.addEventListener('mousedown', handler);
  }, [show]);

  let toggleFilterbar = (item) => {
    setIsopen(item === 'filter' ? !isOpen : false);
  };

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setIsopen(false);
        }
      }

      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }

  useOutsideAlerter(isOpen && filterRef);

  const handleSubmit = (values) => {
    // setApplyedValues({ ...values });
    setFilters((prevFilters) => ({
      ...prevFilters,
    }));
    setIsopen(false);
  };

  // const debouncedHandlers = {};

  const onhandleChange = (name, value) => {
    // // Create or reuse a debounced handler for the input field
    //  if (!debouncedHandlers[name]) {
    //   debouncedHandlers[name] = debounce((val) => {
    //     setFilters((prev)=>({ ...prev, [name]: val }));
    //   }, 2000);
    // }

    // // Call the debounced handler for the specific input field
    // debouncedHandlers[name](value);
    setFilters((prev) => ({ ...prev, [name]: value }));
  };

  const [selectedFilter, setSelectedFilter] = useState();
  const [isSelectFilter, setIsSelectFilter] = useState(false);

  const components = {
    Companies: (setFieldValue, onhandleChange) => (
      <CompaniesFilterForm
        setFieldValue={setFieldValue}
        onhandleChange={onhandleChange}
      />
    ),
    Job: (setFieldValue, onhandleChange, values, setFilters) => (
      <JobFilterForm
        setFieldValue={setFieldValue}
        onhandleChange={onhandleChange}
        values={values}
        setFilters={setFilters}
      />
    ),
    'Industry': (setFieldValue, onhandleChange, values) => (
      <HiringPlanFilterForm
        setFieldValue={setFieldValue}
        onhandleChange={onhandleChange}
        values={values}
      />
    ),
    // Customer: (setFieldValue) => <CustomersFilterForm setFieldValue={setFieldValue} />,
  };

  const selectFilter = (name, isSelect) => {
    setSelectedFilter(name);
    setIsSelectFilter(isSelect);
  };

  // const allFilters = [
  //   {
  //     name: 'Companies',
  //     id: 1,
  //     data: {},
  //     allowed: [4, 5, 6],
  //     allfilters: ['companyIds'],
  //   },
  //   // {
  //   //   name: 'Customer',
  //   //   id: 2,
  //   //   data: { status: '', role: '', department: '', accountType: '' },
  //   // },
  //   {
  //     name: 'Job',
  //     id: 3,
  //     data: {
  //       category: '',
  //       jobType: '',
  //       jobDepartment: '',
  //       companySize:'',
  //       level: '',
  //       employmentType: '',
  //       postCategory: '',
  //     },
  //     allowed: [4, 5, 6],
  //     allfilters: [
  //       'jobIds',
  //       'industryIds',
  //       'companySize',
  //       'departmentIds',
  //       'jobLevel',
  //       'employmentType',
  //       'copywriteStatus',
  //     ],
  //   },
  //   {
  //     name: 'Hiring Plan',
  //     id: 4,
  //     data: {
  //       job: '',
  //       location: '',
  //       hiringPlanJobType: '',
  //       remote: '',
  //       startDate: '',
  //       endDate: '',
  //       budgeted: '',
  //       salary: '',
  //       hiringManager: [],
  //       recruiters: [],
  //       interviewers: [],
  //     },
  //     allowed: [4, 5, 6],
  //     allfilters: [
  //       'jobType',
  //       'remoteStatus',
  //       'companySize',
  //       'budgeted',
  //       'isCompetitive',
  //       'salaryPeriod',
  //       'salaryCurrency',
  //       'hiringManagerIds',
  //       'approverIds',
  //       'address',
  //       'postcode',
  //       'town',
  //       'country',
  //     ],
  //   },
  //   {
  //     name: 'Campaigns',
  //     id: 5,
  //     data: {},
  //     allfilters: [
  //       'campaignName',
  //       'campaignJobName',
  //       'campaignReferenceId',
  //       'campaignStartDate',
  //       'campaignEndDate',
  //       'campaignDuration',
  //       'campaignRepeatAfter',
  //       'campaignStatus',
  //       'channelIds',
  //     ],
  //     allowed: [5, 6],
  //   },
  //   {
  //     name: 'Applications',
  //     id: 6,
  //     data: {},
  //     allowed: [6],
  //     allfilters: [
  //       'rating',
  //       'candPostcode',
  //       'candTown',
  //       'interviewScheduled',
  //       'interviewStatus',
  //       'candStageGroup',
  //       'rejected',
  //       'rejectionReasons',
  //     ],
  //   },
  // ];

  // function getFilterTextColor(filter) {
  //   const isMatch = Object.keys(filter.data).some(
  //     (key) => appliedValues[key] === filter.data[key],
  //   );
  //   return isMatch ? theme?.primary : theme?.headingColor;
  // }
  const [liveData, setLiveData] = useState(false);
  const handleSwitch = (item) => {
    setLiveData(!liveData);
  };
  const { data: kpiData, refetch } = useThirdPartyMetricsQuery(
    {
      ...(!liveData ? daterange : { isLiveData: 1 }),
      ...(activeTab !== 3 && activeTab !== 4 && params),
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const { data: kpiPreviousData } = useThirdPartyMetricsQuery(
    {
      fromDate: moment(daterange?.fromDate)
        .subtract(daterange?.toDate?.diff(daterange?.fromDate, 'days'), 'days')
        .add(1, 'days'),
      toDate: moment(daterange?.toDate).subtract(
        daterange?.toDate?.diff(daterange?.fromDate, 'days'),
        'days',
      ),
      ...(activeTab !== 3 && activeTab !== 4 && params),
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const { data: candidatePool } = useCandidatepoolQuery(
    { ...(!liveData ? daterange : { isLiveData: 1 }), ...params },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const clearField = (key) => {
    setFilters((prev) => ({ ...prev, [key]: null }));
  };
  const resetData = () => {
    setRefresh(!refresh);
    setFilters({});
    setDateRange({
      fromDate: moment().subtract(12, 'months').add(1, 'days').set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      }),
      toDate: moment().set({
        hour: 23,
        minute: 59,
        second: 59,
        millisecond: 0,
      }),
    });
  };

  // const filteredProperties = (filter, checkproperty) => {
  //   const anyPropertyMatches = some(
  //     checkproperty,
  //     (property) => has(filter, property) && !isEmpty(filter[property]),
  //   );
  //   return anyPropertyMatches;
  // };

  const localStorageData = occyLs.getObject('token');
  const downloadCsv = (tab) => {
    setDataExport(false);
    let Overviewurl = `/backoffice/reports/download?a-token=${localStorageData}`;
    let applicantUrl = `${
      filters && activeTab > 3 ? '&' : '?'
    }a-token=${localStorageData}`;
    let url = new URL(
      '/backoffice/reports/download/applicant-data',
      process.env.REACT_APP_API_URL,
    );
    if (filters && activeTab > 3) {
      const paramsKeysValues = getParamsbytab(
        activeTab === 4
          ? jobTabfiltersKeys
          : activeTab === 5
          ? campaignTabfiltersKeys
          : candidateTabfiltersKeys,
        filters,
      );
      for (const key in paramsKeysValues) {
        if (paramsKeysValues.hasOwnProperty(key)) {
          url.searchParams.append(key, paramsKeysValues[key]);
        }
      }
    }

    const finalUrl = url.toString();
    switch (tab) {
      case 2:
        return window.open(
          process.env.REACT_APP_API_URL + Overviewurl,
          '_blank',
        );
      case 1:
        return window.open(finalUrl + applicantUrl, '_blank');
      case 3:
        return window.open(finalUrl + applicantUrl, '_blank');
      case 4:
        return window.open(finalUrl + applicantUrl, '_blank');
      case 5:
        return window.open(finalUrl + applicantUrl, '_blank');
      case 6:
        return window.open(finalUrl + applicantUrl, '_blank');
      default:
        return 0;
    }
  };

  useEffect(() => {
    if (filters) {
      const valuesByFilter = {};
      jobTabfiltersKeys.forEach((key) => {
        if (key in filters) {
          const value = getFiltersParamsValues(filters, key);
          if (
            !isEmpty(value) ||
            (['isCompetitive', 'budgeted'].includes(key) &&
              (value === 0 || value === 1))
          ) {
            valuesByFilter[key] = value;
          }
        }
      });
      setParams({ ...valuesByFilter });
    } else setParams({});
  }, [filters]);

  return (
    <>
      <div>
        <Suspense fallback={<AnalyticSkeleton />}>
          <Portlet>
            <PortletHeader
              tabs={tabs}
              title="Product Adoption"
              onChangeTabs={onChangeTabs}
              containerClassName="pt-2"
              actions={
                <div className="d-flex w-100 justify-content-center align-items-center">
                  <AnalyticTopFilter
                    toggleFilterbar={toggleFilterbar}
                    filters={filters}
                    setDateRange={setDateRange}
                    daterange={daterange}
                    resetData={resetData}
                    liveData={liveData}
                    tab={activeTab}
                    setDataExport={setDataExport}
                  />

                  <div
                    className={`d-flex justify-content-center align-content-center  pt- d-none`}
                  >
                    <StyledLabel
                      className="pe-2"
                      color={'#0A0509'}
                      weight={700}
                      size="10px"
                    >
                      Live Data
                    </StyledLabel>
                    <StyledSwitch
                      isToggle={liveData}
                      onToggle={() => {
                        handleSwitch({});
                      }}
                      width={'17px'}
                      height={'17px'}
                      lblheight={'16px'}
                      lblWidth={'29px'}
                      left={'0.3px'}
                      bottom={'0.3px'}
                      top={'0px'}
                    />
                  </div>
                </div>
              }
            />
            <div className="portlet__body-bg">
            {activeTab === 2 && (
                  <div>
                    <KeyPerformanceMetrics
                      kpiData={kpiData }
                      params={{ companyIds: filters?.companyIds }}
                      kpiPreviousData={kpiPreviousData}
                      daterange={daterange}
                      liveData={liveData}
                    />
                  </div>
                )}
                {activeTab !== 2 && 
                 <PortletBody className={`border`}>
                {activeTab !== 1 &&
                activeTab !== 2 &&
                
                activeTab !== 7 ? (
                  <FiltersTiles filters={filters} clearField={clearField} />
                ) : null}
                
                {activeTab === 3 && (
                  <div>
                    <OnboardingOverView
                      kpiData={kpiData}
                      kpiPreviousData={kpiPreviousData}
                      daterange={daterange}
                      globalFilters={filters}
                      refresh={refresh}
                      setRefresh={setRefresh}
                      liveData={liveData}
                    />
                  </div>
                )}
                {activeTab === 4 && (
                  <div>
                    <ThirdParty
                      kpiData={kpiData}
                      kpiPreviousData={kpiPreviousData}
                      daterange={daterange}
                      refresh={refresh}
                      setRefresh={setRefresh}
                      globalFilters={filters}
                      activeTab={activeTab}
                      liveData={liveData}
                    />
                  </div>
                )}
                {activeTab === 5 && (
                  <div>
                    <FeatureAdoption
                      kpiData={kpiData}
                      kpiPreviousData={kpiPreviousData}
                      daterange={daterange}
                      globalFilters={filters}
                      activeTab={activeTab}
                      refresh={refresh}
                      liveData={liveData}
                      setRefresh={setRefresh}
                    />
                  </div>
                )}
                {activeTab === 6 && (
                  <div>
                    <Copywriting
                      kpiData={{ ...kpiData, candidatePool }}
                      kpiPreviousData={kpiPreviousData}
                      daterange={daterange}
                      globalFilters={filters}
                      refresh={refresh}
                      liveData={liveData}
                      setRefresh={setRefresh}
                    />
                  </div>
                )}

              </PortletBody>}
            </div>
          </Portlet>
        </Suspense>
        <div ref={filterRef} className="bg-transparent mt-2">
          {isOpen && (
            <StyledFilterBar width="400px" variant="filter">
              <div className="d-flex justify-content-end">
                <div
                  className="cursor-pointer mt-3 me-3"
                  onClick={() => setIsopen(false)}
                >
                  <CrossIcon />
                </div>
              </div>

              <div className="sd-header">
                <div className="d-flex align-items-center gap-3">
                  {isSelectFilter && (
                    <DropDownIcon
                      className="cursor-pointer"
                      onClick={() => selectFilter('', false)}
                      rotate="left"
                    />
                  )}
                  <StyledLabel
                    weight={600}
                    size={'20px'}
                    color={theme?.primaryDark}
                    className="mb-0"
                  >
                    {selectedFilter ? selectedFilter : 'Filters'}{' '}
                  </StyledLabel>
                </div>
                {!isEqual(filters, '') && !selectedFilter && (
                  <h6
                    className="mb-0 cursor-pointer"
                    onClick={() => {
                      // setApplyedValues({});
                      setFilters('');
                      setIsopen(false);
                    }}
                  >
                    Clear All
                  </h6>
                )}
              </div>
              <div className="sd-body">
                <Formik
                  initialValues={{
                    ...filters,
                  }}
                  enableReinitialize
                  onSubmit={handleSubmit}
                >
                  {({ handleSubmit, setFieldValue, values, resetForm }) => (
                    <Form onSubmit={handleSubmit}>
                      {/* {!isSelectFilter &&
                        allFilters?.map((fill) =>
                          fill?.allowed?.includes(activeTab) ? (
                            <div className="col-12" key={fill?.id}>
                              <div
                                className="d-flex justify-content-between align-items-center cursor-pointer"
                                onClick={() => selectFilter(fill?.name, true)}
                              >
                                <StyledLabel
                                  weight={
                                    filteredProperties(
                                      filters,
                                      fill?.allfilters,
                                    )
                                      ? 700
                                      : 600
                                  }
                                  size={'14px'}
                                  color={
                                    filteredProperties(
                                      filters,
                                      fill?.allfilters,
                                    )
                                      ? theme?.primary
                                      : theme?.headingColor
                                  }
                                  className="cursor-pointer"
                                >
                                  {fill?.name}
                                </StyledLabel>
                                <DropDownIcon
                                  rotate="right"
                                  color={
                                    filteredProperties(
                                      filters,
                                      fill?.allfilters,
                                    )
                                      ? theme?.primary
                                      : 'currentColor'
                                  }
                                />
                              </div>
                              <hr />
                            </div>
                          ) : null,
                        )} */}
                      {
                        
                        components["Companies"](
                          setFieldValue,
                          onhandleChange,
                          values,
                          resetForm,
                        )}
                         {
                        components["Job"](
                          setFieldValue,
                          onhandleChange,
                          values,
                          resetForm,
                        )}
                    </Form>
                  )}
                </Formik>
              </div>
            </StyledFilterBar>
          )}
        </div>
      </div>
      <DataExportModal
        show={dataExport}
        onHide={() => setDataExport(false)}
        title={'Data Export'}
        desc={'The data will be exported with current filters applied'}
        downloadCsv={(tab) => downloadCsv(tab)}
        tab={activeTab}
      />
    </>
  );
};
export default ProductAdoptionDashboard;

const StyledFilterBar = styled.div`
  width: ${(props) => props?.width ?? '229px'};
  // height: ${(props) => (props?.variant ? '91vh !important' : 'none')};
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
  background-color: #fff;
  position: ${(props) => (props?.variant ? 'fixed' : 'absolute')};
  margin-top: ${(props) => (!props?.variant ? '35px' : 'none')};
  top: ${(props) => (props.variant ? '90px' : 'none')};
  right: 0;
  bottom: ${(props) => (props?.variant ? '0' : 'none')};
  z-index: 2;
  border-radius: ${(props) => (props?.variant ? '8px 0 0 0' : '14px')};
  transition: 0.5s;
  padding-bottom: 78px;

  .sd-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    color: ${({ theme }) => theme.textColor};
  }

  .sd-body {
    padding: 20px;
    height: 100%;
    width: 100%;
    position: relative;
    overflow: auto;
  }

  .sd-body ul {
    display: inline-block;
    width: 100%;
    margin-bottom: 0;
    padding: 0;
  }

  .sd-body ul li {
    list-style: none;
    margin-bottom: 8px;
  }

  .sd-body ul li:last-child {
    margin-bottom: 100px;
  }
`;
