import { Form, Formik } from 'formik';
import React, { useEffect } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import {
  channelFormValues,
  channelFormValuesSchema,
} from '../../assets/utills';
import ChannelForm from './ChannelForm';
const ChannelContainer = () => {
  const { handleSubmit, channelInformation,recordsLoading, updateLoading } = useOutletContext();

  const { productId } = useParams();
  const navigate = useNavigate();
  let data =
    channelInformation?.jobboardProducts?.length === 0 ||
    !channelInformation?.jobboardProducts
      ? channelFormValues
      : channelInformation;
  useEffect(() => {
    if (!channelInformation && !productId) navigate('/products');
  }, [channelInformation, productId]);

  return (
    <Formik
      initialValues={{ ...data }}
      enableReinitialize
      validationSchema={channelFormValuesSchema}
      onSubmit={(values) => handleSubmit(values)}
    >
      {({ handleSubmit, values, setValues, setFieldValue }) => (
        <Form onSubmit={handleSubmit}>
          <ChannelForm
            values={values}
            setValues={setValues}
            setFieldValue={setFieldValue}
            recordsLoading={recordsLoading}
            updateLoading={updateLoading}
          />
        </Form>
      )}
    </Formik>
  );
};

export default ChannelContainer;
