import React from 'react';
const SubsiIconFill = ({ size = 16, color = '#4A154B', ...props }) => (
  <svg width={size} height={size} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x="0.5" y="0.5" width="31" height="31" rx="3.5" fill="white" />
    <path d="M14.3333 22.6667C14.3333 23.5871 15.0795 24.3333 16 24.3333C16.9204 24.3333 17.6666 23.5871 17.6666 22.6667C17.6666 21.7462 16.9204 21 16 21C15.0795 21 14.3333 21.7462 14.3333 22.6667Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M21 22.6667C21 23.5871 21.7462 24.3333 22.6667 24.3333C23.5872 24.3333 24.3334 23.5871 24.3334 22.6667C24.3334 21.7462 23.5872 21 22.6667 21C21.7462 21 21 21.7462 21 22.6667Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M7.66667 22.6667C7.66667 23.5871 8.41286 24.3333 9.33333 24.3333C10.2538 24.3333 11 23.5871 11 22.6667C11 21.7462 10.2538 21 9.33333 21C8.41286 21 7.66667 21.7462 7.66667 22.6667Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M14.3333 9.33366C14.3333 10.2541 15.0795 11.0003 16 11.0003C16.9204 11.0003 17.6666 10.2541 17.6666 9.33366C17.6666 8.41318 16.9204 7.66699 16 7.66699C15.0795 7.66699 14.3333 8.41318 14.3333 9.33366Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M16 11L16 21" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M22.6666 21.0003L22.6666 17.667C22.6666 16.0003 21.8333 15.167 20.1666 15.167L11.8333 15.167C10.1666 15.167 9.33329 16.0003 9.33329 17.667L9.33329 21.0003" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <rect x="0.5" y="0.5" width="31" height="31" rx="3.5" stroke={color} />
  </svg>


);

export default SubsiIconFill;
