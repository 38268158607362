import { Modal } from 'react-bootstrap';
import React from 'react'
import PortletBody from '../../../app/components/portlet/PortletBody';
import StyledButton from '../../../app/components/styledComponents/StyledButton';

const DeleteJobModal = ({ show, onHide, deleteJob, loading }) => {
    return (
        <Modal show={show} centered onHide={onHide} size={'lg'}>
            <Modal.Header
                title="Delete Job"
                closeButton
                className="border-bottom-0 fs-5 pb-0 align-items-center mb-3 ps-2"
            >
                <Modal.Title
                    as="h4"
                    className="flex-grow-1 p-2 fs-2 cursor-pointer flex-center"
                >
                    Delete Job
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="d-flex  flex-column bg-body rounded">
                <PortletBody className="p-0 w-100">
                    <PortletBody className={'p-0 w-100'}>
                        <div className="p-1 p-lg-2">
                            <div className="p-2 fs-5">
                                Are you sure you want to delete?
                            </div>
                        </div>
                    </PortletBody>
                </PortletBody>
                <div className="d-flex justify-content-end mt-3">
                    <div className="d-flex gap-3">
                        <StyledButton
                            variant={'transparent'}
                            className="mb-2 border-0 fw-bold"
                            onClick={onHide}
                            disabled={loading}
                        >
                            Cancel
                        </StyledButton>
                        <StyledButton className="mb-2 bg-danger border-0"
                            disabled={loading}
                            loading={loading}
                            onClick={deleteJob}>
                            Delete
                        </StyledButton>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default DeleteJobModal