import React from 'react'

const SuspendIcon = ({ size = 17 }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_5892_157333)">
        <path d="M8.99992 14.6761C12.6818 14.6761 15.6666 11.6913 15.6666 8.00944C15.6666 4.32754 12.6818 1.34277 8.99992 1.34277C5.31802 1.34277 2.33325 4.32754 2.33325 8.00944C2.33325 11.6913 5.31802 14.6761 8.99992 14.6761Z" stroke="#4A154B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M4.28662 3.29688L13.7133 12.7235" stroke="#4A154B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_5892_157333">
          <rect width="16" height="16" fill="white" transform="translate(1 0.00976562)" />
        </clipPath>
      </defs>
    </svg>

  )
}

export default SuspendIcon