import React, { useState } from 'react';
import LicenseDetails from './components/LicenseDetails';
import LicenseSideBar from './components/LicenseSideBar';
import styled from 'styled-components';

const License = () => {
  const [tab, setTab] = useState(1);
  const [isSidebar, setSidebar] = useState(false)
  const handleSwitchTab = (link) => {
    setTab(link);
  };

  return (
    <>
      <div className={`d-flex`}>
        {isSidebar && <LicenseSideBar onTabChange={handleSwitchTab} />}
        {tab === 1 ? (
          <StyledDiv className=" mt-2  mx-2 me-auto ">
            <div className="portlet__body-bg">
              <LicenseDetails activeTab={1} setSidebar={setSidebar}/>
              {/* <AddOns /> */}
            </div>
          </StyledDiv>
        ) : (
          <StyledDiv className=" mt-2  ms-2 me-auto ">
            <div className="portlet__body-bg">
              <LicenseDetails activeTab={2} setSidebar={setSidebar}/>
              {/* <AddOns /> */}
            </div>{' '}
          </StyledDiv>
        )}
      </div>
    </>
  );
};
const StyledDiv = styled.div`
 min-width:50%;
`;

export default License;
