import React from 'react';

const OnlineIcon = ({ size = 9, color , ...props }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 7 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="3.64258" cy="3.5" r="3" fill='currentColor' />
  </svg>
);

export default OnlineIcon;
