import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import Portlet from '../../../app/components/portlet/Portlet';
import StyledButton from '../../../app/components/styledComponents/StyledButton';
import * as Yup from 'yup';
import PortletBody from '../../../app/components/portlet/PortletBody';
import { Col, Row } from 'react-bootstrap';
import FormikSelect from '../../../app/components/formikComponents/FormikSelect';
import { DurationFriendly } from '../../../utils/helper';
import FormikDatePicker from '../../../app/components/formikComponents/FormikDatePicker';
import { useAddSaveLicenceMutation, useGetLicencesMutation } from '../../company/companyApiSlice';
import FormikAsyncSelect from '../../../app/components/formikComponents/FormikAsyncSelect';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import moment from 'moment';
const SwitchLicense = () => {
  const [getLicences] = useGetLicencesMutation();
  const [addSaveLicence, { isLoading }] = useAddSaveLicenceMutation()
  const [licenceDetails, setLicenceDetails] = useState()
  const navigate = useNavigate();
  const [cost, setCost] = useState()
  const [duration, setDuration] = useState()
  const { compId } = useParams();
  // const features = [
  //   { id: uuidv4(), name: 'Video Profiling', value: 'enable', price: 'Paid' },
  //   {
  //     id: uuidv4(),
  //     name: 'Behavioural Profiling',
  //     value: 'disable',
  //     price: 'Free',
  //   },
  //   { id: uuidv4(), name: 'Media Library', value: 'enable', price: 'Paid' },
  //   {
  //     id: uuidv4(),
  //     name: 'Hosted Application Forms',
  //     value: 'enable',
  //     price: 'Free',
  //   },
  //   { id: uuidv4(), name: 'Agency Advert', value: 'disable', price: 'Paid' },
  //   { id: uuidv4(), name: 'Email Templates', value: 'enable', price: 'Paid' },
  //   { id: uuidv4(), name: 'Application Tags', value: 'disable', price: 'Free' },
  //   { id: uuidv4(), name: 'Application Tags', value: 'disable', price: 'Free' },
  //   {
  //     id: uuidv4(),
  //     name: 'Job Approval Process',
  //     value: 'enable',
  //     price: 'Paid',
  //   },
  // ];

  const switchLicenseSchema = Yup.object().shape({
    license: Yup.object().required('Required'),
    startDate: Yup.date().required('Required'),
    endDate: Yup.date().required('Required').when('startDate', (startDate, schema) => {
      return startDate
        ? schema.min(
          moment(startDate).add(1, 'days'),
          'End date must be after the start date',
        )
        : schema;
    }),
    paymentPlan: Yup.object().required('Required'),
  });

  const initValues = {
    license: undefined,
    paymentPlan: undefined,
    startDate: undefined,
    endDate: undefined,
  };



  const handleSubmit = (values) => {
    let date = new Date(values?.startDate)
    let endDate = new Date(values?.endDate)
    const data = {
      compId,
      body: { licenceId: values?.license?.value?.id, paymentPlanId: values?.paymentPlan?.id, startDate: new Date(date.getTime() + Math.abs(date.getTimezoneOffset() * 60000))
      ,endDate: new Date(endDate.getTime() + Math.abs(endDate.getTimezoneOffset() * 60000)) }
    }
    addSaveLicence(data).unwrap().then(res => {
      toast.success(res?.message)
      navigate(-1)
    })
  };
  return (
    <div>
      <Formik
        initialValues={{ ...initValues }}
        validationSchema={switchLicenseSchema}
        enableReinitialize
        onSubmit={handleSubmit}
      >
        {({ handleSubmit, setFieldValue }) => (
          <Form onSubmit={handleSubmit}>
            <Portlet className="mx-3 my-4 p-2">
              <div className="d-flex justify-content-between align-items-center">
                <h3>Switch Licence</h3>
                <StyledButton className="p-2" loading={isLoading} type='submit'>Save Changes</StyledButton>
              </div>
            </Portlet>

            <div className="mx-2 mb-5">
              <PortletBody className="mx-4 mb-3">
                <h6 className="fw-bold fs-5">Select Licence</h6>
                <Row className="mt-4">
                  <div className="col-12 col-md-6">
                    <div className="mb-3">
                      <label htmlFor="license" className="form-label">
                        Licence
                      </label>
                      <FormikAsyncSelect
                        apiCall={getLicences}
                        name={`license`}
                        id={`license`}
                        placeholder="Licences"
                        onChange={(value) => { setLicenceDetails(value?.value); setFieldValue("paymentPlan", ""); setDuration(""); setCost(""); setFieldValue("license", { label: value?.label, value: value?.value }); }}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="mb-3">
                      <label htmlFor="paymentPlan" className="form-label">
                        Payment Plan
                      </label>
                      <FormikSelect
                        name="paymentPlan"
                        id="paymentPlan"
                        placeholder="Select Payment Plan"
                        options={licenceDetails?.licencePaymentPlans?.map(plan => ({ label: plan?.duration + ' days', value: plan }))}
                        onChange={(value) => { setDuration(value?.value?.duration); setCost(value?.value?.fee); setFieldValue("paymentPlan", value?.value || value?.label); }}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="mb-3">
                      <label htmlFor="startDate" className="form-label">
                        Start Date
                      </label>
                      <FormikDatePicker minDate={new Date()} name="startDate" />
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="mb-3">
                      <label htmlFor="endDate" className="form-label">
                        End Date
                      </label>
                      <FormikDatePicker minDate={new Date()} name="endDate" />
                    </div>
                  </div>
                </Row>
              </PortletBody>
              <PortletBody className="mx-4 mb-3">
                <h6 className="fw-bold fs-5">Licence Details</h6>
                <Row className="mt-4">
                  <Col lg={2}>
                    <span className="fw-bold">Cost</span>
                  </Col>
                  <Col lg={10}>
                    <span>{cost ? ("£" + cost) : "-"} {duration ? DurationFriendly(duration) : "-"}</span>{' '}
                  </Col>
                </Row>
                <hr />
                <Row className="mt-4">
                  <Col lg={2}>
                    <span className="fw-bold">Standard Accounts</span>
                  </Col>
                  <Col lg={10}>
                    <span>{licenceDetails?.maxAccounts || "-"}</span>{' '}
                  </Col>
                </Row>
                <hr />
                <Row className="mt-4">
                  <Col lg={2}>
                    <span className="fw-bold">Guest Users</span>
                  </Col>
                  <Col lg={10}>
                    <span>{licenceDetails?.maxBasicAccounts || "-"}</span>{' '}
                  </Col>
                </Row>
                <hr />
                <Row className="mt-4">
                  <Col lg={2}>
                    <span className="fw-bold">Type</span>
                  </Col>
                  <Col lg={10}>
                    <span>{licenceDetails?.licenceType?.title || "-"}</span>{' '}
                  </Col>
                </Row>
                <hr />
                <Row className="mt-4">
                  <Col lg={2}>
                    <span className="fw-bold">Duration</span>
                  </Col>
                  <Col lg={10}>
                    <span>{duration ? DurationFriendly(duration, true) : "-"}</span>{' '}
                  </Col>
                </Row>
              </PortletBody>
              {licenceDetails?.licenceProducts?.length > 0 &&
                <PortletBody className="mx-4 mb-3">
                  <div className='mb-1'>
                    <h6 className="mt-4 mb-3 fw-bold">Product Allowances</h6>
                    {
                      licenceDetails?.licenceProducts?.map((item, index) => (
                        <>
                          {index !== 0 && <hr />}
                          <Row key={index} className="mt-2 ">
                            <Col lg={4}>
                              <span className="fw-bold">{item?.product?.title}</span>
                            </Col>
                            <Col lg={4}>
                              <span>{item?.quantity}{item?.duration ? DurationFriendly(item?.duration) : ''}</span>{' '}
                            </Col>
                            <Col lg={4}></Col>
                          </Row>
                        </>
                      ))
                    }
                  </div>

                </PortletBody>}

              {/* <PortletBody className="mx-4 mb-3">
                <h6 className="fw-bold fs-5">Features</h6>

                <Row className="mt-4">
                  <Col lg={3}>
                    <span className="fw-bold">Feature Name</span>
                  </Col>
                  <Col lg={2}>
                    <span className="fw-bold">Pricing</span>{' '}
                  </Col>

                  <Col lg={2}>
                    <span className="fw-bold">Enabled</span>
                  </Col>
                  <Col lg={5}>
                    <span className="fw-bold">Disabled</span>{' '}
                  </Col>
                </Row>
                {features?.map((feat, ind) => (
                  <Row className="mt-4" key={'feat' + ind + feat.id}>
                    <Col lg={3}>
                      <span>{feat.name}</span>
                    </Col>
                    <Col lg={2}>
                      <div className="d-flex">
                        <StyledColoredTag className="ms-0" variant="orange">
                          {feat.price}
                        </StyledColoredTag>
                      </div>
                    </Col>
                    <Col lg={2} className="d-flex ">
                      <span>
                        {feat?.value === 'enable' ? (
                          <EnableIcon className="ms-3" />
                        ) : (
                          <DisableIcon className="ms-3" />
                        )}
                      </span>
                    </Col>
                    <Col lg={5}>
                      <span>
                        {feat?.value === 'enable' ? (
                          <EnableIcon className="ms-3" />
                        ) : (
                          <DisableIcon className="ms-3" />
                        )}
                      </span>{' '}
                    </Col>
                  </Row>
                ))}
              </PortletBody> */}
              <div className="float-end mt-2 mb-5 me-4">
                <StyledButton loading={isLoading} type="submit"> Save Changes</StyledButton>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default SwitchLicense;
