import { Form, Formik } from 'formik';
import React from 'react';
import { Container, Modal, Row } from 'react-bootstrap';
import FormikInputField from '../../../../app/components/formikComponents/FormikInputField';
import PortletBody from '../../../../app/components/portlet/PortletBody';
import StyledButton from '../../../../app/components/styledComponents/StyledButton';
import { Wrap } from '../../../../assets/styledComponents/Global';
import { bundleFormSchema, BundleFormValues } from '../../assets/utills';

const NewBundleModal = ({
  show,
  onHide,
  handleSubmit,
  bundleIndex,
  loading,
}) => {
  return (
    <>
      <Modal
        show={show}
        centered
        onHide={() => {
          !loading && onHide();
        }}
        size={'lg'}
      >
        <Modal.Header closeButton>
          <Modal.Title
            as="h2"
            className="flex-grow-1 p-2 cursor-pointer flex-center"
          >
            {`${bundleIndex ? 'Edit Bundle' : 'Add New Bundle'}`}
          </Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={{ ...BundleFormValues, ...bundleIndex }}
          enableReinitialize
          validationSchema={bundleFormSchema}
          onSubmit={handleSubmit}
        >
          {({ handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              <Modal.Body className="d-flex  flex-column bg-body rounded px-4">
                <PortletBody>
                  <Container fluid className="my-2">
                    <Row>
                      <div className="col-12">
                        <div className="mb-3">
                          <label htmlFor="title" className="form-label">
                            Title
                          </label>
                          <FormikInputField
                            name="title"
                            id="title"
                            placeholder="Enter title"
                          />
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="mb-3">
                          <label htmlFor="bundlePrice" className="form-label">
                            Price
                          </label>
                          <FormikInputField
                            type="number"
                            name="bundlePrice"
                            id="bundlePrice"
                            placeholder="Enter price"
                          />
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="mb-3">
                          <label htmlFor="quantity" className="form-label">
                            Quantity
                          </label>
                          <FormikInputField
                            type="number"
                            name="quantity"
                            id="quantity"
                            placeholder="Enter quantity"
                          />
                        </div>
                      </div>

                      <div className="col-12">
                        <label
                          htmlFor="description"
                          className="form-label mb-0"
                        >
                          Description
                        </label>

                        <div className="mt-1">
                          <FormikInputField
                            name="description"
                            id="description"
                            placeholder="Enter description"
                            as="textarea"
                            rows="4"
                          />
                        </div>
                      </div>
                    </Row>
                  </Container>
                </PortletBody>
                <Wrap className="d-flex justify-content-end gap-2 mt-4">
                  <StyledButton
                    variant={'text'}
                    onClick={onHide}
                    disabled={loading}
                  >
                    Cancel
                  </StyledButton>
                  <StyledButton
                    type="submit"
                    disabled={loading}
                    loading={loading}
                  >
                    {`${bundleIndex ? 'Update' : 'Save'}`}
                  </StyledButton>
                </Wrap>
              </Modal.Body>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default NewBundleModal;
