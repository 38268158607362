import { capitalize, isEmpty } from 'lodash';
import React from 'react';
import { Helmet } from 'react-helmet';
import {
  useParams,
} from 'react-router-dom';
import {
  basicInformationValues,
} from '../../Products/assets/utills';
import { StyledLabel } from '../CampaignDetails';
import Portlet from '../../../app/components/portlet/Portlet';
import theme from '../../../utils/themes';
import StyledButton from '../../../app/components/styledComponents/StyledButton';
import styled from 'styled-components';
import BagIcon from '../../../app/components/svgIcons/Bagicon';
import { StyledSpan, Wrap } from '../../../assets/styledComponents/Global';
import StyledIconBoxColored from '../../../app/components/styledComponents/StyledIconBoxColored';
import ReedEditForm from './ReedEditForm';
import MonsterEditForm from './MonsterEditForm';
import CVLibraryEditForm from './CVLibraryEditForm';
import TotalJobsEditForm from './TotalJobsEditForm';
import SalaryDetailIcon from '../../../app/components/svgIcons/SalaryDetailIcon';
import LocationUnfillIcon from '../../../app/components/svgIcons/LocationUnfillIcon';
import RadioMicrophoneIcon from '../../../app/components/svgIcons/RadioMicrophoneIcon';
import { renderHtml } from '../../../utils/helper';
import { STATUSES } from '../../../utils/Statuses';
import moment from 'moment';
import NhsJobsEditForm from './NhsJobsEditForm';

const AdvertPostingDetails = ({
  totalJobKey,
  showMonsterEditFormKey,
  cvlibraryKey,
  reedKey,
  nhsJobKey,
  values,
  postingLoading,
  setClickNext,
  isBroadBeanProduct,
  setActiveTab,
  setFieldValue
}) => {
  const { productId } = useParams();

  // const  setProductResponse = useState();
  const initialData = productId ? '' : basicInformationValues;


  const getStatusName = (statusId) => {
    const statusKeys = Object.keys(STATUSES);
    for (const key of statusKeys) {
      if (STATUSES[key] === statusId) {
        return key;
      }
    }
    return '-';
  };
  const formatDate = (inputDate) => {
    if (!inputDate || !moment(inputDate).isValid()) {
      return '-';
    }

    const date = moment(inputDate);
    const formattedDate = moment(date).isValid() ? date.format('Do MMM YYYY'):'-';
    return formattedDate;
  };
  const capitalizeText = (text) => {
    if (typeof text === 'string') {
      return text?.split('_')?.map((word) => word?.charAt(0)?.toUpperCase() + word?.slice(1)?.toLowerCase())?.join(' ');
    } else {
      return '';
    }
  }


  const formatSalary = (salary) => {
    if (salary === null || salary === undefined) {
      return '-';
    }
    const formattedSalary = `£${salary.toFixed(2)}`;
    return formattedSalary;
  };

  return (
    <>
      <Helmet>
        <title>{capitalize(initialData?.title) + ' - occy ADMIN'}</title>
      </Helmet>
      <div className="px-5">
        <StyledWrap className={`d-flex align-items-center justify-content-start gap-3`}>
          <StyledIconBoxColored className={'rounded'} color={theme.WhiteLinen} icon={<BagIcon size={24} color={theme.textColor} />} />
          <Wrap className='d-flex flex-column'>
            <StyledSpan size='16px' className='text fw-semibold'>Job Details</StyledSpan>
          </Wrap>
        </StyledWrap>
        <div className="row">
          <div className="col-sm-6 col-md-3">
            <StyledLabel
              size="14px"
              color={theme.headingColor}
              className="mt-4 mb-1"
            >
              Job Name
            </StyledLabel>
            <div>
              <StyledLabel
                size="14px"
                weight="500"
                className="m-0 text-muted"
              >
                {capitalizeText(values?.campaignAdvert?.title) || '-'}
              </StyledLabel>
            </div>
          </div>
          <div className="col-sm-6 col-md-3">
            <StyledLabel
              size="14px"
              color={theme.headingColor}
              className="mt-4 mb-1"
            >
              Department
            </StyledLabel>
            <div>
              <StyledLabel
                size="14px"
                weight="500"
                className="m-0 text-muted"
              >
                {capitalizeText(values?.campaignAdvert?.department?.title) || '-'}
              </StyledLabel>
            </div>
          </div>
          <div className="col-sm-6 col-md-3">
            <StyledLabel
              size="14px"
              color={theme.headingColor}
              className="mt-4 mb-1"
            >
              Post Category
            </StyledLabel>
            <div>
              <StyledLabel
                size="14px"
                weight="500"
                className="m-0 text-muted"
              >
                {values?.campaignAdvert?.jobCategory?.title || '-'}
              </StyledLabel>
            </div>
          </div>
          <div className="col-sm-6 col-md-3">
            <StyledLabel
              size="14px"
              color={theme.headingColor}
              className="mt-4 mb-1"
            >
              Level
            </StyledLabel>
            <div>
              <StyledLabel
                size="14px"
                weight="500"
                className="m-0 text-muted"
              >
                {capitalizeText(values?.campaignAdvert?.position) || '-'}
              </StyledLabel>
            </div>
          </div>
        </div>
        <div className="row ">
          <div className="col-sm-6 col-md-3">
            <StyledLabel
              size="14px"
              color={theme.headingColor}
              className="mt-4 mb-1"
            >
              Employment Type
            </StyledLabel>
            <div>
              <StyledLabel
                size="14px"
                weight="500"
                className="m-0 text-muted"
              >
                {capitalizeText(values?.campaignAdvert?.employmentType) || '-'}
              </StyledLabel>
            </div>
          </div>
          <div className="col-sm-6 col-md-3">
            <StyledLabel
              size="14px"
              color={theme.headingColor}
              className="mt-4 mb-1"
            >
              Job Type
            </StyledLabel>
            <div>
              <StyledLabel
                size="14px"
                weight="500"
                className="m-0 text-muted"
              >
                {capitalizeText(values?.campaignAdvert?.jobType) || '-'}
              </StyledLabel>
            </div>
          </div>
          <div className="col-sm-6 col-md-3">
            <StyledLabel
              size="14px"
              color={theme.headingColor}
              className="mt-4 mb-1"
            >
              Job Status
            </StyledLabel>
            <div>
              <StyledLabel
                size="14px"
                weight="500"
                className="m-0 text-muted"
              >
                {capitalizeText(getStatusName(values?.campaignGeneralInfo?.job?.statusId))}
              </StyledLabel>
            </div>
          </div>
          <div className="col-sm-6 col-md-3">
            <StyledLabel
              size="14px"
              color={theme.headingColor}
              className="mt-4 mb-1"
            >
              Start Date
            </StyledLabel>

            <div>
              <StyledLabel
                size="14px"
                weight="500"
                className="m-0 text-muted"
              >
                {formatDate(values?.campaignAdvert?.startsAt)}
              </StyledLabel>
            </div>
          </div>
        </div>
        <div className="row ">
          <div className="col-sm-6 col-md-3">
            <StyledLabel
              size="14px"
              color={theme.headingColor}
              className="mt-4 mb-1"
            >
              End Date
            </StyledLabel>
            <div>
              <StyledLabel
                size="14px"
                weight="500"
                className="m-0 text-muted"
              >
                {formatDate(values?.campaignAdvert?.expiresAt) || '-'}
              </StyledLabel>
            </div>
          </div>
          <div className="col-sm-6 col-md-3">
            <StyledLabel
              size="14px"
              color={theme.headingColor}
              className="mt-4 mb-1"
            >
              Remote
            </StyledLabel>
            <div>
              <StyledLabel
                size="14px"
                weight="500"
                className="m-0 text-muted"
              >
                {capitalizeText(values?.campaignAdvert?.remoteStatus) || '-'}
              </StyledLabel>
            </div>
          </div>
          <div className="col-sm-6 col-md-3">
            <StyledLabel
              size="14px"
              color={theme.headingColor}
              className="mt-4 mb-1"
            >
              Job Keywords
            </StyledLabel>
            <div>
              <StyledLabel
                size="14px"
                weight="500"
                className="m-0 text-muted"
              >
                {values?.campaignAdvert?.keywords || '-'}
              </StyledLabel>
            </div>
          </div>

        </div>
      </div>
      {reedKey &&
        <Portlet className='my-3'>
          <ReedEditForm values={values} />
        </Portlet>
      }

      {showMonsterEditFormKey &&
        <Portlet className='my-3'>
          <MonsterEditForm values={values} />
        </Portlet>
      }

      {cvlibraryKey && (
        <Portlet className='my-3'>
          <CVLibraryEditForm values={values} />
        </Portlet>
      )}
      {totalJobKey &&
        <Portlet className='my-3'>
          <TotalJobsEditForm values={values} />
        </Portlet>
      }
 {nhsJobKey &&
        <Portlet className='my-3'>
          <NhsJobsEditForm values={values} setFieldValue={setFieldValue}/>
        </Portlet>
      }


      {/* 
            <Portlet className='my-3'>
              <GumTreeEditForm />
            </Portlet> */}
      <div className='my-4'>
        <div className="px-5">
          <StyledWrap className={`d-flex align-items-center justify-content-start gap-3`}>
            <StyledIconBoxColored className={'rounded'} color={theme.LavenderColor} icon={<SalaryDetailIcon size={24} />} />
            <Wrap className='d-flex flex-column'>
              <StyledSpan size='16px' className='text fw-semibold'>Salary Details</StyledSpan>
            </Wrap>
          </StyledWrap>
          <div className="row">
            <div className="col-sm-6 col-md-3">
              <StyledLabel
                size="14px"
                color={theme.headingColor}
                className="mt-4 mb-1"
              >
                Salary min
              </StyledLabel>
              <div>
                <StyledLabel
                  size="14px"
                  weight="500"
                  className="m-0 text-muted"
                >
                  {formatSalary(values?.campaignAdvert?.salaryFrom) || '-'}
                </StyledLabel>
              </div>
            </div>
            <div className="col-sm-6 col-md-3">
              <StyledLabel
                size="14px"
                color={theme.headingColor}
                className="mt-4 mb-1"
              >
                Salary Max
              </StyledLabel>
              <div>
                <StyledLabel
                  size="14px"
                  weight="500"
                  className="m-0 text-muted"
                >
                  {formatSalary(values?.campaignAdvert?.salaryTo) || '-'}
                </StyledLabel>
              </div>
            </div>
            <div className="col-sm-6 col-md-3">
              <StyledLabel
                size="14px"
                color={theme.headingColor}
                className="mt-4 mb-1"
              >
                Show Salary
              </StyledLabel>
              <div>
                <StyledLabel
                  size="14px"
                  weight="500"
                  className="m-0 text-muted"
                >
                  {values?.campaignAdvert?.isCompetitive ? 'No' : 'Yes' || '-'}
                </StyledLabel>
              </div>
            </div>
            <div className="col-sm-6 col-md-3">
              <StyledLabel
                size="14px"
                color={theme.headingColor}
                className="mt-4 mb-1"
              >
                Currency
              </StyledLabel>
              <div>
                <StyledLabel
                  size="14px"
                  weight="500"
                  className="m-0 text-muted"
                >
                  {values?.campaignAdvert?.salaryCurrency || '-'}
                </StyledLabel>
              </div>
            </div>
          </div>
          <div className="row ">
            <div className="col-sm-6 col-md-3">
              <StyledLabel
                size="14px"
                color={theme.headingColor}
                className="mt-4 mb-1"
              >
                Pay Period
              </StyledLabel>
              <div>
                <StyledLabel
                  size="14px"
                  weight="500"
                  className="m-0 text-muted"
                >
                  {capitalizeText(values?.campaignAdvert?.salaryPer) || '-'}
                </StyledLabel>
              </div>
            </div>
          </div>
        </div>
      </div>
      <StyledLine >
        <hr />
      </StyledLine>

      <div className='my-4'>
        <div className="px-5">
          <StyledWrap className={`d-flex align-items-center justify-content-start gap-3`}>
            <StyledIconBoxColored className={'rounded'} color={theme.NCSColor} icon={<LocationUnfillIcon size={24} />} />
            <Wrap className='d-flex flex-column'>
              <StyledSpan size='16px' className='text fw-semibold'>Address and location</StyledSpan>
            </Wrap>
          </StyledWrap>
          <div className="row">
            <div className="col-12">
              <StyledLabel
                size="14px"
                color={theme.headingColor}
                className="mt-4 mb-1"
              >
                Location
              </StyledLabel>
              <div>
                <StyledLabel
                  size="14px"
                  weight="500"
                  className="m-0 text-muted"
                >
                  {`${values?.campaignGeneralInfo?.jobHiringPlan?.address ? values?.campaignGeneralInfo?.jobHiringPlan?.address + ', ' : ''}`}
                  {`${values?.campaignGeneralInfo?.jobHiringPlan?.town ? values?.campaignGeneralInfo?.jobHiringPlan?.town + ', ' : ''}`}
                  {`${values?.campaignGeneralInfo?.jobHiringPlan?.country ? values?.campaignGeneralInfo?.jobHiringPlan?.country + ', ' : ''}`}
                  {`${values?.campaignAdvert?.postcode ? values?.campaignAdvert?.postcode : ''}`}

                </StyledLabel>
              </div>
            </div>
          </div>
        </div>
      </div>
      <StyledLine >
        <hr />
      </StyledLine>
      <div className='my-4'>
        <div className="px-5">
          <StyledWrap className={`d-flex align-items-center justify-content-start gap-3`}>
            <StyledIconBoxColored className={'rounded'} color={theme.WhiteLinen} icon={<RadioMicrophoneIcon />} />
            <Wrap className='d-flex flex-column'>
              <StyledSpan size='16px' className='text fw-semibold'>Job Description</StyledSpan>
              <StyledLabel size='12px' weight='500' className='text' color={theme.borderColor}>A brief and persuasive job description to attract potential candidates.</StyledLabel>
            </Wrap>
          </StyledWrap>
           {values?.campaignAdvert?.jobDescription
            &&
            <div className="row">
              <div className="col-12">
                <StyledLabel
                  size="14px"
                  color={theme.headingColor}
                  className="mt-4 mb-1"
                >
                  Job Description
                </StyledLabel>
                <div>
                  <StyledLabel
                    size="14px"
                    weight="500"
                    className="m-0 text-muted"
                  >
                    {renderHtml(values?.campaignAdvert?.jobDescription)}
                  </StyledLabel>
                </div>
              </div>
            </div>
          }
          {values?.campaignAdvert?.jobRole &&
            <div className="row">
              <div className="col-12">
                <StyledLabel
                  size="14px"
                  color={theme.headingColor}
                  className="mt-4 mb-1"
                >
                  About the role
                </StyledLabel>
                <div>
                  <StyledLabel
                    size="14px"
                    weight="500"
                    className="m-0 text-muted"
                  >
                    {renderHtml(values?.campaignAdvert?.jobRole)}
                  </StyledLabel>
                </div>
              </div>
            </div>
          }
          {values?.campaignAdvert?.benefits
            && <div className="row">
              <div className="col-12">
                <StyledLabel
                  size="14px"
                  color={theme.headingColor}
                  className="mt-4 mb-1"
                >
                  Company Benefits
                </StyledLabel>
                <div>
                  <StyledLabel
                    size="14px"
                    weight="500"
                    className="m-0 text-muted"
                  >
                    {renderHtml(values?.campaignAdvert?.benefits)}
                  </StyledLabel>
                </div>
              </div>
            </div>

          }
         {values?.campaignAdvert?.jobRequirement &&
            <div className="row">
              <div className="col-12">
                <StyledLabel
                  size="14px"
                  color={theme.headingColor}
                  className="mt-4 mb-1"
                >
                  Essential Skills
                </StyledLabel>
                <div>
                  <StyledLabel
                    size="14px"
                    weight="500"
                    className="m-0 text-muted"
                  >
                    {renderHtml(values?.campaignAdvert?.jobRequirement)}
                  </StyledLabel>
                </div>
              </div>
            </div>
          }
          {values?.campaignAdvert?.aboutCompany &&
            <div className="row">
              <div className="col-12">
                <StyledLabel
                  size="14px"
                  color={theme.headingColor}
                  className="mt-4 mb-1"
                >
                  About Us
                </StyledLabel>
                <div>
                  <StyledLabel
                    size="14px"
                    weight="500"
                    className="m-0 text-muted"
                  >
                    {renderHtml(values?.campaignAdvert?.aboutCompany)}
                  </StyledLabel>

                </div>
              </div>
            </div>}
        </div>
      </div>
      <StyledLine >
        <hr />
      </StyledLine>
      {(values?.showMonsterEditFormKey ||
        values?.reedKey ||
        values?.cvlibraryKey ||
        values?.totalJobKey ||
        values?.nhsJobKey) &&
      isEmpty(values?.jobBoardsSummaryDetails) ? (
        <div className="d-flex justify-content-end">
          <StyledButton
            variant="white"
            className=""
            type="submit"
            loading={postingLoading}
            disabled={postingLoading}
            onClick={() => setClickNext(true)}
          >
            {isBroadBeanProduct? 'Next' :'Publish'}
          </StyledButton>
        </div>
      ) : (
        (!isEmpty(values?.jobBoardsSummaryDetails) ||
          isBroadBeanProduct) && 
          (<div className="d-flex justify-content-end">
            <StyledButton
              variant="white"
              className=""
              onClick={() => setActiveTab(1)}
            >
             Next
            </StyledButton>
          </div>)
      )}
    </>
  );
};
const StyledLine = styled.div`
  hr {
    border: 1px solid ${({ theme }) => theme.lightGrey};
`;
const StyledWrap = styled.div`
  margin: 1px 1px 0px 0px;
`;

export default AdvertPostingDetails;
