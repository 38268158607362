import React,{useState} from 'react';
import { Row } from 'react-bootstrap';
import FormikInputField from '../../../../app/components/formikComponents/FormikInputField';
import PortletBody from '../../../../app/components/portlet/PortletBody';
import FormikAsyncSelect from '../../../../app/components/formikComponents/FormikAsyncSelect';
import FormikSelect from '../../../../app/components/formikComponents/FormikSelect';
import { useGetJobCategoriesMutation, useGetLicencesMutation } from '../../companyApiSlice';
import FormikDatePicker from '../../../../app/components/formikComponents/FormikDatePicker';

const ConfigurationForm = ({setFieldValue,values}) => {
  const [getLicences] = useGetLicencesMutation();
  const [getCategories] = useGetJobCategoriesMutation();
  const [licenceDetails, setLicenceDetails] = useState();
  // const [cost, setCost] = useState();
  // const compType = [
  //   {
  //     label: 'Standard',
  //     value: 'standard',
  //   },
  //   // {
  //   //   label: 'Partnership',
  //   //   value: 'partner',
  //   // },
  //   // {
  //   //   label: 'Agency',
  //   //   value: 'agency',
  //   // }
  // ];

  //   const jobPosting = [
  //       {
  //         label: 'Manual',
  //         value: '0',
  //       },
  //       {
  //         label: 'Auto',
  //         value: '1',
  //       }
  //     ];
      
      const compSize = [
        {
          label: '1-10',
          value: '1-10',
        },
        {
          label: '11-50',
          value: '11-50',
        },
        {
          label: '51-200',
          value: '51-200',
        },
        {
          label: '201-500',
          value: '201-500',
        },
        {
          label: '501-1000',
          value: '501-1000',
        },
        {
          label: '1001-5000',
          value: '1001-5000',
        },
        {
          label: '5001-10000',
          value: '5001-10000',
        },
        {
          label: '10000+',
          value: '10000+',
        }
      ];
  return (
    <>
      <PortletBody className="p-3 w-100">
             <div className="p-1 p-lg-2">
                <h5>Initial Occy Configuration</h5>
                        <div className="p-2">  
      <Row className='mt-2'>
      <div className="col-12 col-md-6">
              {/* <div className="mb-3">
                <label htmlFor="companyType" className="form-label">
                  Company Type
                </label>
                <FormikSelect
                  name="companyType"
                  id="companyType"
                  placeholder="Select Company Type"
                  options={compType}
                />
              </div> */}
                <div className="mb-3">
                <label htmlFor="hubspotId" className="form-label">
                Hubspot Id
                </label>
                <FormikInputField
                        name={`hubspotId`}
                        id={`hubspotId`}
                        placeholder="Hubspot Id"
                      />
              </div>
            </div>

      <div className="col-12 col-md-6">
              <div className="mb-3">
                <label htmlFor="companyLicense" className="form-label">
                 Company Licence
                </label>
                <FormikAsyncSelect
                apiCall={getLicences}
                  name="companyLicense"
                  id="companyLicense"
                  placeholder="Select Company Licence"
                  onFocus={() => setFieldValue("paymentPlan", undefined)}
                  onChange={(value) => { setLicenceDetails(value?.value); setFieldValue("companyLicense", { label: value?.label, value: value?.value }); }}
                />
              </div>
            </div>
            </Row>
            <Row>
            <div className="col-12 col-md-6">
                    <div className="mb-3">
                      <label htmlFor="paymentPlan" className="form-label">
                        Licence Payment Plan
                      </label>
                      <FormikSelect
                        name="paymentPlan"
                        id="paymentPlan"
                        placeholder="Select Payment Plan"
                        options={values?.companyLicense ? values?.companyLicense?.value?.licencePaymentPlans?.map(plan => ({ label: plan?.duration + ' days', value: plan })) : licenceDetails?.licencePaymentPlans?.map(plan => ({ label: plan?.duration, value: plan }))}
                        onChange={(value) => { 
                          // setCost(value?.value?.fee);
                           setFieldValue("paymentPlan", value?.value || value?.label); }}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="mb-3">
                    <label htmlFor="startDate" className="form-label">
                        Licence Start Date
                      </label>
                      <FormikDatePicker minDate={new Date()} name="startDate" />
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="mb-3">
                    <label htmlFor="endDate" className="form-label">
                        Licence End Date
                      </label>
                      <FormikDatePicker minDate={new Date()} name="endDate" />
                    </div>
                  </div>
          <div className="col-12 col-md-6">
              <div className="mb-3">
                <label htmlFor="industry" className="form-label">
                  Industry
                </label>
                <FormikAsyncSelect
                                                apiCall={getCategories}
                                                name={`industry`}
                                                id={`industry`}
                                                placeholder="Select Industry"
                                              />
                      
              </div>
            </div>

        <div className="col-12 col-md-6">
              <div className="mb-3">
                <label htmlFor="companySize" className="form-label">
                  Company Size
                </label>
                <FormikSelect
                  name="companySize"
                  id="companySize"
                  placeholder="Select Company Size"
                  options={compSize}
                />
              </div>
            </div>
            <div className="col-12 col-md-6">
                    <div className="mb-3">
                    <label htmlFor="contractSigned" className="form-label">
                        Contract Sign Date
                      </label>
                      <FormikDatePicker  name="contractSigned" />
                    </div>
                  </div>
              </Row>
            {/* <Row className='mt-2'>
<div className="col-12 col-md-12">
              <div className="mb-3">
                <label htmlFor="hubspotId" className="form-label">
                Hubspot Id
                </label>
                <FormikInputField
                        name={`hubspotId`}
                        id={`hubspotId`}
                        placeholder="Hubspot Id"
                      />
              </div>
            </div>
            </Row> */}

            {/* <Row className='mt-2'>
<div className="col-12 col-md-6">
              <div className="mb-3">
                <label htmlFor="jobPostingFree" className="form-label">
                Job Posting Settings <StyledToolTip
      title="Job Posting Settings"
      className="mt-2"
      placement="top"
    ><InfoIcon /></StyledToolTip>
                </label>
                <FormikSelect
                  name="jobPostingFree"
                  id="jobPostingFree"
                  placeholder="Job Posting"
                  options={jobPosting}
                />
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="mb-3">
                <label htmlFor="jobPostingPremium" className="form-label">
                Job Posting (Premium/Paid) <StyledToolTip
      title="Job Posting (Premium/Paid"
      className="mt-2"
      placement="top"
    ><InfoIcon/> </StyledToolTip>
                </label>
                <FormikSelect
                  name="jobPostingPremium"
                  id="jobPostingPremium"
                  placeholder="Job Posting (Premium/Paid)"
                  options={jobPosting}
                />
              </div>
            </div>
            </Row> */}
              </div>
              </div>
              </PortletBody>
    </>
  );
};

export default ConfigurationForm;
