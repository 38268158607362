import React from 'react';
import styled from 'styled-components';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { Wrap } from '../../../assets/styledComponents/Global';

const OnboardSkeleton = () => {
  return (
    <>
    {Array.from(new Array(5)).map((i, ind) => (
      <StyledWallet className="d-flex  me-2 justify-content-between rounded-4 align-items-center" key={`wallet-${ind}`}>
        <Wrap className="w-100">
          <Skeleton height={200}  className="me-2 mt-2" />
        </Wrap>
      </StyledWallet>
    ))}
    </>
  );
};

const StyledWallet = styled.div`
  border-radius: 6px;
  width: 100%;
`;

export default OnboardSkeleton;
