import React from 'react';

const ClipboardCheckIcon = ({ size = 19 , ...props}) => {
  return (
    <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<path d="M10.1419 1.3335H7.00853C6.3152 1.3335 5.74854 1.8935 5.74854 2.58683V3.2135C5.74854 3.90683 6.30854 4.46683 7.00187 4.46683H10.1419C10.8352 4.46683 11.3952 3.90683 11.3952 3.2135V2.58683C11.4019 1.8935 10.8352 1.3335 10.1419 1.3335Z" fill="#767476"/>
<path d="M12.0686 3.21332C12.0686 4.27332 11.2019 5.13999 10.1419 5.13999H7.00855C5.94855 5.13999 5.08189 4.27332 5.08189 3.21332C5.08189 2.83999 4.68189 2.60665 4.34855 2.77999C3.40855 3.27999 2.76855 4.27332 2.76855 5.41332V11.6867C2.76855 13.3267 4.10855 14.6667 5.74855 14.6667H11.4019C13.0419 14.6667 14.3819 13.3267 14.3819 11.6867V5.41332C14.3819 4.27332 13.7419 3.27999 12.8019 2.77999C12.4686 2.60665 12.0686 2.83999 12.0686 3.21332ZM10.8019 8.48665L8.13522 11.1533C8.03522 11.2533 7.90855 11.3 7.78189 11.3C7.65522 11.3 7.52855 11.2533 7.42855 11.1533L6.42855 10.1533C6.23522 9.95999 6.23522 9.63999 6.42855 9.44665C6.62189 9.25332 6.94189 9.25332 7.13522 9.44665L7.78189 10.0933L10.0952 7.77999C10.2886 7.58665 10.6086 7.58665 10.8019 7.77999C10.9952 7.97332 10.9952 8.29332 10.8019 8.48665Z" fill="#767476"/>
</svg>

  );
};

export default ClipboardCheckIcon;
