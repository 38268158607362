import React from 'react';
import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import PaginationPerPage from '../../../app/components/pagination/PaginationPerPage';
import Portlet from '../../../app/components/portlet/Portlet';
import PortletBody from '../../../app/components/portlet/PortletBody';
import StyledButton from '../../../app/components/styledComponents/StyledButton';
import StyledIconInput from '../../../app/components/styledComponents/StyledIconInput';
import EditIcon from '../../../app/components/svgIcons/EditIcon';
import FilterIcon from '../../../app/components/svgIcons/FilterIcon';
import SearchIcon from '../../../app/components/svgIcons/SearchIcon';
import SortIcon from '../../../app/components/svgIcons/SortIcon';
import TrashIcon from '../../../app/components/svgIcons/TrashIcon';
import { capitalizeFirstLetter } from '../../../utils/helper';
import { useEffect } from 'react';
import TableSkeleton from '../../../assets/skeletons/tableSkeleton';
import { toast } from 'react-toastify';
import CompanyProductsListingTable from './CompanyProductsLsitingTable';
import { useAddCompanyProductMutation, useGetCompanyProductsQuery, useDeleteCompanyProductMutation } from '../companyApiSlice';
import AddCompanyProductModal from './AddCompanyProductModal';

const CompanyProductsListingContainer = () => {
  const [addCompProduct, {isLoading:addLoading}] = useAddCompanyProductMutation()
  const [deleteCompanyProduct] = useDeleteCompanyProductMutation();
  const [sortFilter, setSortFilter] = useState(false);
  const [isOpen, setIsopen] = useState(false);
  // const [filters, setFilters] = useState('');
  const [timer, setSearchTimer] = useState(null);
  // const [editData, setEditData] = useState(null);
  const [addProductModal, setAddProductModal] = useState(false);
  const [editProductModal, setEditProductModal] = useState(false);
  const [productType,setProductType]=useState();
  const [productData, setProductData] = useState();
  // const [search, setSearch] = useState('');
  const { compId } = useParams();
  const initialFilters = {
    perPage: 5,
    page: 1,
    sortedColumn: { path: 'title', order: 'DESC' },
    query: '',
  };
  const [listing, setListing] = useState({ ...initialFilters });

  // const handleSubmit = async (values) => {
  //   setFilters({ ...values });
  //   setIsopen(false);
  // };

  let ToggleFilterbar = (item) => {
    setIsopen(item === 'filter' ? !isOpen : false);
    setSortFilter(item === 'sort' ? !sortFilter : false);
  };

  // const handleSortBy = (item) => {
  //   const prevFilters = { ...filters };
  //   prevFilters.sort = item.value;
  //   prevFilters.sortDir = item.dir;
  //   setFilters({ ...prevFilters });
  // };
const addCompanyNewProduct = (val) =>{
  let data = {data:{...val,allowance:0},compId:compId};
  data.data.productId = data?.data?.productId?.value;
  addCompProduct(data,{
    skip: !compId,
  }).unwrap().then((res)=>{
    toast.success(res?.message || "Company product added successfully");
   setEditProductModal(false);
   setAddProductModal(false);
   setProductType();
   setProductData();
    refetch();
  })
}
  // const sortFilters = [
  //   {
  //     id: 1,
  //     label: 'Added on (Latest First)',
  //     value: 'applied-latest-to-oldest',
  //     dir: 'DESC',
  //   },
  //   {
  //     id: 2,
  //     label: 'Applied on (Oldest First)',
  //     value: 'applied-oldest-to-latest',
  //     dir: 'ASC',
  //   },
  //   {
  //     id: 3,
  //     label: 'Ratings (Highest First)',
  //     value: 'userRating',
  //     dir: 'DESC',
  //   },
  //   {
  //     id: 4,
  //     label: 'Lastest First (Lowest First)',
  //     value: 'last_activity',
  //     dir: 'ASC',
  //   },
  //   {
  //     id: 5,
  //     label: 'Location (Nearest First)',
  //     value: 'nearest',
  //     dir: 'ASC',
  //   },
  // ];

  useEffect(() => {
    const concernedElement = document.querySelector('.click-text');
    document.addEventListener('mousedown', (event) => {
      if (!concernedElement?.contains(event?.target)) {
        setSortFilter(false);
      }
    });
  }, [sortFilter]);

  const { data: productsRes, refetch, isFetching, error } = useGetCompanyProductsQuery({
    params: {
      perPage: listing.perPage,
      page: listing.page,
      query: listing.query && listing.query,
      sort: listing.sortedColumn.path,
      sortDir: listing.sortedColumn.order,
      // ...filters,
    },
    compId: compId
  });

  const handleSearch = (evt) => {
    clearTimeout(timer);
    const query = evt.target.value;
    // setSearch(query);
    const newTimer = setTimeout(() => {
      setListing({ ...listing, page: 1, query: query });
    }, 2000);
    setSearchTimer(newTimer);
  };

  const handleSort = (sort) => {
    setListing((prevState) => ({
      ...prevState,
      sortedColumn: { ...sort },
      page: 1,
    }));
  };

  const handlePerPageChange = (perPage) => {
    setListing((prevState) => ({
      ...prevState,
      perPage: perPage.target.innerText,
      page: 1,
    }));
  };

  const handlePageChange = ({ selected }) => {
    setListing((prevState) => ({
      ...prevState,
      page: selected + 1,
    }));
  };

const deleteCompProduct = (data) =>{
  let val={productId:data?.productId, compId:compId}
  deleteCompanyProduct(val,{
    skip: !data?.id,
  }).unwrap().then((res)=>{
    toast.success(res?.message || 'Company product deleted successfully')
    refetch();
  })

}
  // const onCheck = (item) => {
    // if (item.length === candidates.length) {
    //   setAllCandSelect(true);
    // }
    // let newArr = [];
    // if (item.length > 0) {
    //   setAllCandSelect(true);
    //   tempEmail.forEach((tmp) => {
    //     tmp.checked = !tmp.checked;
    //   });
    // } else {
    //   let newItemIndex = candRes?.data.findIndex((t) => t.id === item.id);
    //   tempEmail[newItemIndex].checked = !tempEmail[newItemIndex].checked;
    // }
    // tempEmail.map((item) => item.checked && newArr.push(item.id));
    // setCandidates(tempEmail);
    // setSelectedCands(newArr);
    // if (every(candidates, ['checked', true])) {
    //   setAllCandSelect(true);
    // }
  // };

  const column = [
    {
      id: 1,
      key: 'title',
      path: 'title',
      label: (
        <>
          {/* <div className="d-flex"> */}
          {/* <StyledCheckbox
              disabled={true}
              size={16}
            //   value={allCandSelect}
            //   onChange={() => onCheck(candRes)}
            /> */}

          <span className="mx-2">Product Title</span>
          {/* </div> */}
        </>
      ),
      isChecked: true,
      alignBody: 'text-left align-middle',
      alignHeader: 'text-left align-middle cursor-pointer p-3',
      content: (data) => (
        <>
          <div className="d-flex p-2">
            {/* <StyledCheckbox
              size={15}
            // disabled={true}
            //   value={candidateData?.checked}
            //   onChange={() => onCheck(candidateData)}
            /> */}
            <Link
              className="mx-2 text-decoration-none"
              to={``}
            >
              <h5 className='ms-0'>{data?.title || '-'}</h5>
              <span className="small">{data?.productId || '-'}</span>
            </Link>
          </div>
        </>
      ),
    },
    {
      id: 2,
      key: 'productType',
      label: 'Product Type',
      isChecked: true,
      alignBody: 'text-center align-middle',
      alignHeader: 'text-center align-middle cursor-pointer p-3',
      content: (data) => (
        <>
          {data?.productType || '-'}
        </>
      ),
    },
    {
      id: 3,
      key: 'accessKey',
      isChecked: true,
      path: 'access_key',
      label: 'Access Key',
      alignBody: 'text-center align-middle',
      alignHeader: 'text-center align-middle cursor-pointer p-3',
      content: (data) => (
        <>
          <div className="d-flex p-2 justify-content-center">
            {data?.accessKey || '-'}
          </div>
        </>
      ),
    },
    {
      id: 4,
      key: 'quantity',
      isChecked: true,
      label: 'Quantity',
      alignBody: 'text-center align-middle',
      alignHeader: 'text-center align-middle cursor-pointer p-3',
      content: (data) => (
        <>
          <div className="d-flex p-2 justify-content-center">
            {data?.quantity || '-'}
          </div>
        </>
      ),
    },
    {
      id: 5,
      key: 'allowance',
      label: 'Allowance',
      alignBody: 'text-center align-middle',
      alignHeader: 'text-center align-middle cursor-pointer p-3',
      content: (data) => (
        <>
          <div className="d-flex p-2 justify-content-center">
            {data?.allowance || 0}
          </div>
        </>
      ),
    },
    {
      id: 6,
      key: 'costPerUnit',
      isChecked: true,
      label: 'Cost Per Unit',
      alignBody: 'text-center align-middle',
      alignHeader: 'text-center align-middle cursor-pointer p-3',
      content: (data) => (
        <>
          <div className="d-flex p-2 justify-content-center">
            {data?.creditPrice || '-'}
          </div>
        </>
      ),
    },
    {
      id: 8,
      key: 'visibility',
      isChecked: true,
      label: 'Visibility',
      alignBody: 'text-center align-middle',
      alignHeader: 'text-center align-middle cursor-pointer p-3',
      content: (data) => (
        <div className="d-flex p-2 justify-content-center">
          {capitalizeFirstLetter(data?.visibility) || '-'}
        </div>
      ),
    },
    {
      id: 9,
      key: 'actions',
      isChecked: true,
      label: 'Actions',
      alignBody: 'text-center align-middle',
      alignHeader: 'text-center align-middle cursor-pointer p-3',
      content: (data) => (
          <div className="d-flex align-items-center justify-content-center">
            <Link
                variant="text"
                className={`${data?.product?.accessKey === "SMS" ? 'feature-pending' : ''} text-decoration-none`}
                to={''}
                onClick={() => {
                  setEditProductModal(true);
                  setProductType({
                    accessKey: data?.accessKey,
                    productType: data?.productType,
                  });
                  setProductData(data)
                }}
            >
            <EditIcon />
          </Link>

          &nbsp;&nbsp;&nbsp;
          <div className="text-primary cursor-pointer feature-peding">
          <Link
            variant="text"
            className="text-decoration-none "
            to={''}
            onClick={()=>deleteCompProduct(data)}
          >
            <TrashIcon />
          </Link>

          </div>
        </div>
      ),
    },
  ];



  return (
    <div>
      <Portlet>
        <div className="portlet__body-bg">
          <PortletBody>
            <>
              <div className="flex-center mb-3">
                <div className="flex-grow-1">
                  <StyledIconInput
                    icon={<SearchIcon />}
                    className="mw-50"
                    $maxWidth="55%"
                    // value={search} // setting a value manually here triggers react warnings
                    onChange={handleSearch}
                    loading={isFetching}
                    placeholder="Search"
                  />
                </div>
                <div className="d-flex gap-2">
                <StyledButton
                    className=""
                    onClick={() => {
                      setAddProductModal(true);
                    }}
                  >
                    Add Product
                  </StyledButton>
                  <StyledButton
                    className="px-2 py-1 feature-pending"
                    variant="icon"
                    onClick={() => ToggleFilterbar('filter')}
                    icon={<FilterIcon />}
                  >
                    Filter
                  </StyledButton>
                  <StyledButton
                    className="px-2 py-1 d-flex align-items-center feature-pending"
                    variant="icon"
                    icon={<SortIcon size="20" className="me-0" />}
                    id="sort"
                    onClick={() => ToggleFilterbar('sort')}
                  >
                    Sort
                  </StyledButton>
                </div>
              </div>

              {(error || productsRes?.companyProducts?.length === 0 || !productsRes?.companyProducts) ? <div className='d-flex justify-content-center align-items-center'>No Products Found ...</div> :
                <>
                  {productsRes?.companyProducts?.length > 0 ? (
                    <CompanyProductsListingTable
                      companyProducts={productsRes?.companyProducts || []}
                      sortColumn={listing.sortedColumn}
                      onSort={handleSort}
                      filterTable={column}
                    />) : (
                    <TableSkeleton cols={[
                      'Produt Title',
                      'Product Type',
                      'Access Key',
                      'Quantity',
                      'Cost Per Unit',
                      'Expiry',
                      'Actions',
                    ]}
                      rows={3} />
                  )}
                  <PaginationPerPage
                    perPage={productsRes?.perPage || 0}
                    pageNo={productsRes?.page || 1}
                    onPerPageChange={handlePerPageChange}
                    totalCount={productsRes?.total || 0}
                    onPageChange={handlePageChange}
                  />
                </>
              }
            </>
          </PortletBody>
        </div>
        {/* <div className="m-0 position-relative d-flex rounded flex-column click-text">
                {sortFilter && (
                  <StyledFilterBar
                    variant={'sort'}
                    className="bg-white p-3 align-items-center justify-content-center"
                  >
                    <div className="d-flex justify-content-between align-items-center mb-3">
                      <h5 className="mb-0">Sort By</h5>
                    </div>
                    {sortFilters.map((item, index) => {
                      return (
                        <div
                          className="d-flex align-items-center py-2 px-2 "
                          key={`sort-${item.value}-index`}
                        >
                          <StyledRadioButton
                            value={filters.sort === item.value}
                            label={item.label}
                            className="fw-bold"
                            onChange={() => handleSortBy(item)}
                          />
                        </div>
                      );
                    })}
                  </StyledFilterBar>
                )}
              </div>

              <div className="portlet__body-bg position-relative bg-transparent click-filters">
                {isOpen && (
                  <StyledFilterBar variant="filter" className="my-1">
                    <div className="d-flex justify-content-end">
                      <div
                        className="cursor-pointer mt-3 me-3"
                        onClick={ToggleFilterbar}
                      >
                        <CrossIcon />
                      </div>
                    </div>

                    <div className="sd-header">
                      <h4 className="mb-0">Filters</h4>
                      {!isEqual(filters, initialFilters) && (
                        <h6
                          className="mb-0 cursor-pointer"
                          onClick={() => {
                            setFilters('');
                            setIsopen(false);
                          }}
                        >
                          Clear filters
                        </h6>
                      )}
                    </div>
                    <div className="sd-body">
                      {/* <Formik
                        initialValues={{
                          ...filters,
                        }}
                        validationSchema={Yup.object().shape({
                          appDate: Yup.string(),
                        })}
                        onSubmit={handleSubmit}
                        innerRef={ref}
                      >
                        {({ handleSubmit, values, submitForm, errors }) => (
                          <Form onSubmit={handleSubmit}>
                            Filter Form
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </StyledFilterBar>
                )}
              </div> */}
                <AddCompanyProductModal
        refetch={refetch}
        show={!editProductModal ? addProductModal : editProductModal}
        onHide={() => {
          setAddProductModal(false);
          setEditProductModal(false);
          setProductType();
          setProductData();
        }}
        title={!editProductModal ? 'Add Product' : 'Edit Product'}
        AddCompPrd={(val)=>addCompanyNewProduct(val)}
        productData={productData}
        addLoading={addLoading}
        editProductModal={editProductModal}
        setProductType={setProductType}
        productType={productType}
      />
      </Portlet>
    </div>
  );
};

export default CompanyProductsListingContainer;
