import styled from 'styled-components';
import React from 'react';

const StyledIconInput = ({
  width,
  icon,
  placeholder = 'Search',
  inputClass,
  value,
  icon1,
  loading,
  ...props
}) => {
  return (
    <div className="flex-grow-1">
      <InputStyled {...props}>
        {icon && icon}
        <input
          type="search"
          placeholder={placeholder}
          className={`${inputClass} p-2`}
          value={value}
        />
        <span
          className={`spinner spinner-border spinner-border-sm me-2 ${!loading ? 'd-none' : 'd-inline-block'
            }`}
          style={{
            transition: 'all 0.3s linear',
          }}
        />
        {icon1}
      </InputStyled>
    </div>
  );
};

const InputStyled = styled.div`
  padding: 0 14px;
  max-width: ${({ $maxWidth }) => ($maxWidth ? $maxWidth : '50em')};
  font-size: 12px;
  width: 100%;
  border-radius: 8px;
  float: ${({$floatRight}) => ($floatRight ? $floatRight : ' ')};
  border: 1.5px solid ${({ theme }) => theme.inputBorder};
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.inputColor} !important;
  background-color: ${({ theme, $background }) =>
    $background ? $background : theme.bodyBg};
  &:focus-within {
    color: ${({ theme }) => theme.inputColor};
    border: 1.5px solid ${({ theme }) => theme.primaryLight2};
    background-color: ${({ theme, $background }) =>
    $background ? $background : theme.bodyBg};
  }
  input {
    border: 0;
    width: 100%;
    margin-left: ${({ $marginLeft }) => ($marginLeft ? $marginLeft : '10px')};
    font-weight: 600;
    background-color: ${({ theme, $background }) =>
    $background ? $background : theme.bodyBg};
    &:valid {
      background-image: none;
    }
    &:focus {
      box-shadow: none;
      outline: none;
      border: 0;
      background-color: ${({ theme, $background }) =>
    $background ? $background : theme.bodyBg};
    }
    &::placeholder {
      color: ${({ theme }) => theme.inputPlaceHolder};
      opacity: 1;
    }
  }
`;

export default StyledIconInput;
