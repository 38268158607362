import React from 'react'

const TotalApplicationIcon = ({ size = 40 }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M35.8333 20.3838H30.0333C28.4 20.3838 26.95 21.2838 26.2166 22.7505L24.8166 25.5171C24.4833 26.1838 23.8166 26.6005 23.0833 26.6005H17.6166C17.1 26.6005 16.3666 26.4838 15.8833 25.5171L14.4833 22.7671C13.75 21.3171 12.2833 20.4005 10.6666 20.4005H4.83332C4.18332 20.4005 3.66665 20.9171 3.66665 21.5671V27.0005C3.66665 33.0505 7.29998 36.6671 13.3666 36.6671H27.3333C33.05 36.6671 36.5666 33.5338 37 27.9671V21.5505C37 20.9171 36.4833 20.3838 35.8333 20.3838Z" fill="#F7CEB5" />
            <path d="M27.3166 3.33301H13.35C7.28332 3.33301 3.66665 6.94967 3.66665 13.0163V18.083C4.03332 17.9497 4.43332 17.883 4.83332 17.883H10.6666C13.25 17.883 15.5666 19.3163 16.7166 21.633L17.9666 24.083H22.7333L23.9833 21.6163C25.1333 19.3163 27.45 17.883 30.0333 17.883H35.8333C36.2333 17.883 36.6333 17.9497 37 18.083V13.0163C37 6.94967 33.3833 3.33301 27.3166 3.33301ZM17.75 9.01634H22.9166C23.55 9.01634 24.0833 9.53301 24.0833 10.1663C24.0833 10.8163 23.55 11.333 22.9166 11.333H17.75C17.1166 11.333 16.5833 10.8163 16.5833 10.1663C16.5833 9.53301 17.1166 9.01634 17.75 9.01634ZM24.2166 15.983H16.45C15.8166 15.983 15.3 15.4663 15.3 14.833C15.3 14.183 15.8166 13.6663 16.45 13.6663H24.2166C24.85 13.6663 25.3666 14.183 25.3666 14.833C25.3666 15.4663 24.85 15.983 24.2166 15.983Z" fill="#F7CEB5" />
        </svg>

    )
}

export default TotalApplicationIcon