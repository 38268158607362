import React, { Fragment, useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import DropdownItem from 'react-bootstrap/esm/DropdownItem';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import CustomDropdown from '../../app/components/dropdown/CustomDropDown';
import PortletBody from '../../app/components/portlet/PortletBody';
import StyledColoredTag from '../../app/components/styledComponents/StyledColoredTag';
import CheckStatusIcon from '../../app/components/svgIcons/CheckStatusIcon';
import ManualAdvertiseIcon from '../../app/components/svgIcons/ManualAdvertiseIcon';
import MarkIcon from '../../app/components/svgIcons/MarkIcon';
import OptionFillIcon from '../../app/components/svgIcons/OptionFillIcon';
import SlashCircleCIon from '../../app/components/svgIcons/SlashCircleIcon';
import { getStatusColor, getStatusFriendly } from '../../utils/helper';
import { STATUSES } from '../../utils/Statuses';

import theme from '../../utils/themes';
import {
  useCheckBroadbeanStatusMutation,
} from './CampainApiSlice';
import WalletIcon from '../../app/components/svgIcons/WalletIcon';
import moment from 'moment';
import { isArray, isUndefined } from 'lodash';
import PostingIcon from '../../app/components/svgIcons/PostingIcon';
import BroadBeanResponseModal from '../Job/listing/BroadBeanResponseModal';
import {
  useCancelAdvertiseMutation,
  useManualAdvertiseMutation,
  useMarkAdvertisedMutation,
} from '../Job/jobApiSlice';
import StyledButton from '../../app/components/styledComponents/StyledButton';
import { CLICKADVERTPRODUCTICONS } from './CampaignsUtils';
import DotIcon from '../../app/components/svgIcons/DotIcon';
import { useNavigate } from 'react-router-dom';
import { Wrap } from '../../assets/styledComponents/Global';
import StyledToolTip from '../../app/components/styledComponents/StyledToolTip';
import EyeIcon from '../../app/components/svgIcons/EyeIcon';


const CampaignPostingDetails = ({
  show,
  onHide,
  ids,
  campaign,
  data,campaginRefetch,companySetting,
}) => {
  const [loadingStates, setLoadingStates] = useState({});
  const navigate = useNavigate();
  const [manualPostingAdvertise] = useManualAdvertiseMutation();
  const [markPostingAdvertised] = useMarkAdvertisedMutation();
  const [cancelPostingAdvertise] = useCancelAdvertiseMutation();
  const [checkPostingBroadbeanStatus] = useCheckBroadbeanStatusMutation();
  const [advertResponse, setAdvertResponse] = useState();
  // const [switchSetting, setSwitchSetting] = useState(false);

  const onAdvertiseJobBoard = async (postingId, status) => {
    navigate(`/campaigns/${postingId?.campaignId}/posting/${postingId?.id}`, {
      state: { status, postingId },
    });
  };
  const onManualAdvertise = async (jobId) => {
    const { data } = await manualPostingAdvertise(jobId);

    if (data?.broadbeanUrl) {
      window.open(data?.broadbeanUrl || '', '_blank');
    }
    else if(data?.statusId) toast.success(data?.message || 'Job manual advertised successfully');
    campaginRefetch();
  };
  const onMarkAdvertise = async (jobId) => {
    try {
      setLoadingStates(prevLoadingStates => ({
        ...prevLoadingStates,
        [jobId]: true
      }));
    const { data } = await markPostingAdvertised(jobId);
    if (data?.id) {
      toast.success(data?.message || 'Job mark as advertised successfully');
      campaginRefetch();
    }} catch (error) {
      // Handle error
    } finally {
      setLoadingStates(prevLoadingStates => ({
        ...prevLoadingStates,
        [jobId]: false
      }));
    }
  };

  const onCancelAdvertise = async (jobId) => {
    const { data } = await cancelPostingAdvertise(jobId);
    if (data?.statusId === STATUSES.CANCELED) {
      toast.success(data?.message || 'Job advertisement canceled successfully');
      campaginRefetch();
    }
  };

  const onCheckBroadbeanStatus = async (id) => {
    const { data, error } = await checkPostingBroadbeanStatus(id);
    if (error?.data?.message) {
      return;
    }

    const rsp = data?.AdCourierAPIResponse;
    if (isUndefined(rsp?.Failed)) {
      toast.error(`BroadBean Msg: ${rsp?.Failed?.Message || 'Not Found!'}`);
    } else if (isUndefined(rsp?.AdvertCheckResponse?.Failed)) {
      toast.error(
        `BroadBean Msg: ${rsp?.AdvertCheckResponse?.Failed?.Message || 'Not Found!'
        }`,
      );
    } else {
      rsp.AdvertCheckResponse = isArray(rsp?.AdvertCheckResponse)
        ? rsp?.AdvertCheckResponse[0]
        : rsp?.AdvertCheckResponse;
      if (!isArray(rsp?.AdvertCheckResponse?.ChannelList?.Channel))
        rsp.AdvertCheckResponse.ChannelList.Channel = [
          rsp?.AdvertCheckResponse?.ChannelList?.Channel,
        ];
      setAdvertResponse(rsp?.AdvertCheckResponse);
      campaginRefetch();
    }
  };

  // const getTotalPrice = (items, accessKey) => (`${items?.reduce(
  //   (total, item) =>
  //     item?.jobPostings?.product?.productType === accessKey ? item?.cost ?? 0 + total : total,
  //   0
  // )} ${accessKey === 'ADVERTISING' ? 'Credits' : 'Fiat'}`)

  const iconColors = ['orange', 'green', 'pink'];
  const hideDropDownItems = (statusId) =>
    statusId === STATUSES.POSTED || statusId === STATUSES.CANCELED;
  const hideDropdownOnJobBoard = (statusId) => statusId === STATUSES.POSTED || statusId === STATUSES.CANCELED;
  const advertProducts = useMemo(
    () =>
      data
        ?.filter(
          (item) => item?.jobPostings?.product?.productType === 'ADVERTISING'
        )
        ?.map((item) => ({
          ...item?.jobPostings,
          companySetting: companySetting||"true",
        })),
    [companySetting,data]
  );

  const clickAdvertProducts = useMemo(
    () =>
      data?.filter(
        (item) => item?.jobPostings?.product?.productType === 'CLICK_ADVERTISING',
      )?.map(item => item?.jobPostings),
    [data],
  );

  const FiatValueFromTransaction = useMemo(() => {
    const total = data?.reduce(
      (acc, item) =>
        acc +
        item?.jobPostings?.jobPostingTransactions?.reduce(
          (acc, obj) => acc + parseFloat(obj?.transaction?.fiatVal || 0),
          0
        ),
      0
    );
  
    return total?.toLocaleString(); // Format the final total
  }, [data]);
  
  return (
    <Modal show={show} centered onHide={onHide} size={'lg'}>
      <Modal.Header
        closeButton
        className="d-flex border-bottom-0 pb-0 align-items-center pb-md-3"
      >
        <Modal.Title
          as="h3"
          className="flex-grow-0 px-0 cursor-pointer flex-center mb-0 "
        >
          {moment(campaign?.startDate)?.format('Do MMM')} -{' '}
          {moment(campaign?.endDate)?.format('Do MMM YYYY')}
        </Modal.Title>
        <DotIcon size={9} className="text-gray mx-2" />
        <StyledColoredTag
          className="text-start m-0"
          variant={getStatusColor(campaign?.statusId)}
        >
          {getStatusFriendly(campaign?.statusId)}
        </StyledColoredTag>
      </Modal.Header>
      
      <Modal.Body className="d-flex  flex-column bg-body rounded">
        <PortletBody className={'p-0 w-100 mb-3 p-3'}>
          <div className="p-2 p-lg-2 rounded-3 bg-blue bg-opacity-50">
            <div className="p-2 d-flex justify-content-between">
              <div>
                <StyledLabel
                  weight="600"
                  size="16px"
                  color={theme.headingColor}
                  className="mb-2"
                >
                  Posting Cost
                </StyledLabel>
                <div className="d-flex ">
                  <h3 className="mb-0 text-darkGray">
                  £ {FiatValueFromTransaction || 0}
                  </h3>
                </div>
                {/* <div className="d-flex ">
                  <h3 className="mb-0 text-darkGray">
                    {isArray(data) &&  getTotalPrice(data, 'CLICK_ADVERTISING')}
                  </h3>
                </div> */}
              </div>
              <WalletIcon />
            </div>
          </div>
          {isArray(advertProducts) && advertProducts?.length > 0 && (
            <div className="py-2 p-3 border rounded-3 mt-3">
              <StyledLabel
                weight="600"
                size="16px"
                color={theme.headingColor}
                className="mb-2"
              >
                Channels
              </StyledLabel>
              {advertProducts?.map((posting, i) => (
                <Fragment key={`postings-details-${i}`}>
                  <div className="p-2 mb-2" >
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="d-flex align-items-center w-100">
                        <div className="d-flex">
                          <PostingIcon
                            className="me-3"
                            color={iconColors[i % 3]}
                          />
                        </div>
                        <div className="d-flex w-100 justify-content-between align-items-center">
                          <div className="d-flex flex-column">
                            <StyledLabel
                              weight="600"
                              size="14px"
                              color={theme.textColor}
                            >
                              {posting?.product?.title}
                            </StyledLabel>
                            <StyledLabel
                              size="12px"
                              weight="400"
                              color={theme.inputColor}
                            >
                              {posting?.product?.broadBeanAccount?.id
                                ? ` Broadbean ID: ${posting?.product?.broadBeanAccount?.id} `
                                : ''}
                            </StyledLabel>
                          </div>

                          <Wrap className='d-flex'>
                            <StyledColoredTag
                            className="text-start"
                            variant={getStatusColor(posting?.statusId)}
                          >
                            {getStatusFriendly(posting?.statusId)}
                          </StyledColoredTag>
                          {posting?.statusId === STATUSES.POSTED && 
                          <StyledToolTip
                            title="View details"
                            childrenClass={'ms-0 cursor-pointer'}
                          >
                            <span
                              onClick={() => {
                                onAdvertiseJobBoard(posting, "manualAdvert")
                              }}
                            >
                              <EyeIcon size={22} />
                            </span>
                          </StyledToolTip>}
                          </Wrap>
                          
                        </div>
                      </div>
                        {posting?.companySetting === "true" ? (
                          <div className="d-flex">
                             { (hideDropdownOnJobBoard(posting?.statusId) || loadingStates[posting?.id] ) ? (
                              !loadingStates[posting?.id]  ? (<></>) : (
                                <div class="spinner-border spinner-border-sm" role="status">
  <span class="sr-only"></span>
</div>
                               )
                            ) : (
                              <CustomDropdown
                                align="end"
                                toggleClassName={'bg-transparent px-0'}
                                menuClassName={'mt-3'}
                                dropdownToggle={
                                  <div className={`account-menu ${'bg-transparent ps-0 ms-2'} `}>
                                    <OptionFillIcon rotate={'left'} />
                                  </div>
                                }
                                dropdownMenu={
                                  <>
                                   <DropdownItem
                                          className="py-2"
                                          onClick={() =>
                                            onMarkAdvertise(posting?.id, "markAdvert")
                                          }
                                        >
                                          <span className="dark-text">
                                            <MarkIcon />
                                          </span>
                                          <span className="ms-3 text-secondary">
                                            Mark as advertised
                                          </span>
                                        </DropdownItem>
                                    {posting?.id !== STATUSES.PARTIALLY_LIVE && (
                                      <DropdownItem
                                        className="py-2"
                                        onClick={() => posting?.product?.jobboardProducts?.length === 0 ? onManualAdvertise(posting?.id): onAdvertiseJobBoard(posting, "manualAdvert")}
                                      >
                                        <span className="dark-text">
                                          <ManualAdvertiseIcon />
                                        </span>
                                        <span className="ms-3 text-secondary">
                                          Manual Advertise
                                        </span>
                                      </DropdownItem>
                                    )}
                                    {posting?.id === STATUSES.PARTIALLY_LIVE && (
                                      <DropdownItem
                                        className="py-2"
                                        onClick={() => onAdvertiseJobBoard(posting, "statusCheck")}
                                      >
                                        <span className="dark-text">
                                          <CheckStatusIcon />
                                        </span>
                                        <span className="ms-3 text-secondary">
                                          Check Status
                                        </span>
                                      </DropdownItem>
                                    )}
                                    {posting?.id !== STATUSES.PARTIALLY_LIVE && (
                                      <DropdownItem
                                        className="py-2"
                                        onClick={() =>
                                          onCancelAdvertise(posting?.id, "cancelAdvert")
                                        }
                                      >
                                        <span className="dark-text">
                                          <SlashCircleCIon />
                                        </span>
                                        <span className="ms-3 text-secondary">
                                          Cancel Request
                                        </span>
                                      </DropdownItem>
                                    )}
                                  </>
                                }
                              />
                            )}
                          </div>
                        ) : (
                          <div className="d-flex">
                              { (hideDropdownOnJobBoard(posting?.statusId) || loadingStates[posting?.id] ) ? (
                              !loadingStates[posting?.id]  ? (<></>) : (
                                <div class="spinner-border spinner-border-sm" role="status">
  <span class="sr-only"></span>
</div>
                               )
                            ) : (
                              <CustomDropdown
                                align="end"
                                toggleClassName={'bg-transparent px-0'}
                                menuClassName={'mt-3'}
                                dropdownToggle={
                                  <div className={`account-menu ${'bg-transparent ps-0 ms-2'} `}>
                                    <OptionFillIcon rotate={'left'} />
                                  </div>
                                }
                                dropdownMenu={
                                  <>
                                    {!hideDropDownItems(posting?.statusId) && (
                                      <>
                                        <DropdownItem
                                          className="py-2"
                                          onClick={() =>
                                            onMarkAdvertise(posting?.id, "markAdvert")
                                          }
                                        >
                                          <span className="dark-text">
                                            <MarkIcon />
                                          </span>
                                          <span className="ms-3 text-secondary">
                                            Mark as advertised
                                          </span>
                                        </DropdownItem>

                                        <DropdownItem
                                          className="py-2"
                                          onClick={() =>
                                            onManualAdvertise(posting?.id)
                                          }
                                        >
                                          <span className="dark-text">
                                            <ManualAdvertiseIcon />
                                          </span>
                                          <span className="ms-3 text-secondary">
                                            Manual Advertise
                                          </span>
                                        </DropdownItem>

                                        <DropdownItem
                                          className="py-2"
                                          onClick={() =>
                                            onCancelAdvertise(posting?.id, "cancelAdvert")
                                          }
                                        >
                                          <span className="dark-text">
                                            <SlashCircleCIon />
                                          </span>
                                          <span className="ms-3 text-secondary">
                                            Cancel Request
                                          </span>
                                        </DropdownItem>
                                      </>
                                    )}
                                    {posting?.broadbeanAccountId && (
                                      <DropdownItem
                                        className="py-2"
                                        onClick={() =>
                                          onCheckBroadbeanStatus(posting?.id)
                                        }
                                      >
                                        <span className="dark-text">
                                          <CheckStatusIcon />
                                        </span>
                                        <span className="ms-3 text-secondary">
                                          Check Status on Broadbean
                                        </span>
                                      </DropdownItem>
                                    )}
                                  </>
                                }
                              />
                            )}
                          </div>
                        )}

                    </div>
                  </div>
                  {advertProducts?.length !== i + 1 && <hr />}
                </Fragment>
              ))}
            </div>
          )}

          {isArray(clickAdvertProducts) && clickAdvertProducts?.length > 0 && (
            <div className="py-2 p-3 border rounded-3 mt-3">
              <StyledLabel
                weight="600"
                size="16px"
                color={theme.headingColor}
                className="mb-2"
              >
                Pay Per Click
              </StyledLabel>
              {clickAdvertProducts?.map((posting, i) => (
                companySetting === true ?
                  <>
                    <div className="p-2 mb-2">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center w-100">
                          <div className="d-flex">
                            {CLICKADVERTPRODUCTICONS[posting?.product?.accessKey]}
                          </div>
                          <div className="d-flex w-100 justify-content-between align-items-center">
                            <div className="d-flex flex-column">
                              <StyledLabel
                                weight="600"
                                size="14px"
                                color={theme.textColor}
                              >
                                {posting?.product?.title}
                              </StyledLabel>
                              <StyledLabel
                                size="12px"
                                weight="400"
                                color={theme.inputColor}
                              >
                                {posting?.product?.broadBeanAccount?.id
                                  ? ` Broadbean ID: ${posting?.product?.broadBeanAccount?.id} `
                                  : ''}
                              </StyledLabel>
                            </div>
                            <StyledColoredTag
                              className="text-start"
                              variant={getStatusColor(posting?.statusId)}
                            >
                              {getStatusFriendly(posting?.statusId)}
                            </StyledColoredTag>
                          </div>
                        </div>
                        <div className="d-flex">
                          {hideDropDownItems(posting.statusId) ? (
                            <> </>
                          ) : (
                            <CustomDropdown
                              align="end"
                              toggleClassName={'bg-transparent px-0'}
                              menuClassName={'mt-3'}
                              dropdownToggle={
                                <div
                                  className={`account-menu ${'bg-transparent ps-0 ms-2'} `}
                                >
                                  <OptionFillIcon rotate={'left'} />
                                </div>
                              }
                              dropdownMenu={
                                <>
                                  {posting?.id !== STATUSES.PARTIALLY_LIVE &&
                                    <DropdownItem
                                      className="py-2"
                                      onClick={() => posting?.product?.jobboardProducts?.length === 0 ? onManualAdvertise(posting?.id): onAdvertiseJobBoard(posting, "manualAdvert")}
                                    >
                                      <span className="dark-text">
                                        <ManualAdvertiseIcon />
                                      </span>
                                      <span className="ms-3 text-secondary">
                                        Manual Advertise
                                      </span>
                                    </DropdownItem>
                                  }
                                  <DropdownItem
                                    className="py-2"
                                    onClick={() =>
                                      onCancelAdvertise(posting?.id)
                                    }
                                  >
                                    <span className="dark-text">
                                      <SlashCircleCIon />
                                    </span>
                                    <span className="ms-3 text-secondary">
                                      Cancel Request
                                    </span>
                                  </DropdownItem>
                                  {posting?.id === STATUSES.PARTIALLY_LIVE &&
                                    <DropdownItem
                                      className="py-2"
                                      onClick={() => onAdvertiseJobBoard(posting, "statusCheck")}
                                    >
                                      <span className="dark-text">
                                        <CheckStatusIcon />
                                      </span>
                                      <span className="ms-3 text-secondary">
                                        Check Status
                                      </span>
                                    </DropdownItem>
                                  }
                                </>}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    {clickAdvertProducts?.length !== i + 1 && <hr />}
                  </>
                  :

                  <>
                    <div className="p-2 mb-2">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center w-100">
                          <div className="d-flex">
                            {CLICKADVERTPRODUCTICONS[posting?.product?.accessKey]}
                          </div>
                          <div className="d-flex w-100 justify-content-between align-items-center">
                            <div className="d-flex flex-column">
                              <StyledLabel
                                weight="600"
                                size="14px"
                                color={theme.textColor}
                              >
                                {posting?.product?.title}
                              </StyledLabel>
                              <StyledLabel
                                size="12px"
                                weight="400"
                                color={theme.inputColor}
                              >
                                {posting?.product?.broadBeanAccount?.id
                                  ? ` Broadbean ID: ${posting?.product?.broadBeanAccount?.id} `
                                  : ''}
                              </StyledLabel>
                            </div>
                            <StyledColoredTag
                              className="text-start"
                              variant={getStatusColor(posting?.statusId)}
                            >
                              {getStatusFriendly(posting?.statusId)}
                            </StyledColoredTag>
                          </div>
                        </div>
                        <div className="d-flex">
                          {!posting?.broadbeanAccountId &&
                            hideDropDownItems(posting.statusId) ? (
                            <></>
                          ) : (
                            <CustomDropdown
                              align="end"
                              toggleClassName={'bg-transparent px-0'}
                              menuClassName={'mt-3'}
                              dropdownToggle={
                                <div
                                  className={`account-menu ${'bg-transparent ps-0 ms-2'} `}
                                >
                                  <OptionFillIcon rotate={'left'} />
                                </div>
                              }
                              dropdownMenu={
                                <>
                                  {!hideDropDownItems(posting?.statusId) ? (
                                    <>
                                      <DropdownItem
                                        className="py-2"
                                        onClick={() =>
                                          onMarkAdvertise(posting?.id)
                                        }
                                      >
                                        <span className="dark-text">
                                          <MarkIcon />
                                        </span>
                                        <span className="ms-3 text-secondary">
                                          Approve Advert
                                        </span>
                                      </DropdownItem>

                                      {/* <DropdownItem
                                    className="py-2"
                                    onClick={() =>
                                      onManualAdvertise(posting?.id)
                                    }
                                  >
                                    <span className="dark-text">
                                      <ManualAdvertiseIcon />
                                    </span>
                                    <span className="ms-3 text-secondary">
                                      Manual Advertise
                                    </span>
                                  </DropdownItem> */}

                                      <DropdownItem
                                        className="py-2"
                                        onClick={() =>
                                          onCancelAdvertise(posting?.id)
                                        }
                                      >
                                        <span className="dark-text">
                                          <SlashCircleCIon />
                                        </span>
                                        <span className="ms-3 text-secondary">
                                          Cancel Request
                                        </span>
                                      </DropdownItem>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                  {posting?.broadbeanAccountId && (
                                    <DropdownItem
                                      className="py-2"
                                      onClick={() =>
                                        onCheckBroadbeanStatus(posting?.id)
                                      }
                                    >
                                      <span className="dark-text">
                                        <CheckStatusIcon />
                                      </span>
                                      <span className="ms-3 text-secondary">
                                        Check Status on Broadbean
                                      </span>
                                    </DropdownItem>
                                  )}
                                </>
                              }
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    {clickAdvertProducts?.length !== i + 1 && <hr />}
                  </>

              ))}
            </div>
          )}
        </PortletBody>
        <BroadBeanResponseModal
          modalContent={advertResponse}
          onHide={() => setAdvertResponse(null)}
        />
      </Modal.Body>
      <Modal.Footer className="bg-body border-0 pt-0">
        <StyledButton variant={'white'} onClick={onHide}>
          Back
        </StyledButton>
      </Modal.Footer>
    </Modal>
  );
};
export const StyledLabel = styled.label`
  font-size: ${({ size }) => size ?? '14px'};
  font-weight: ${({ weight }) => weight ?? '600'};
  color: ${({ color }) => color ?? 'black'};
`;

export default CampaignPostingDetails;
