import React from 'react';
import { ErrorMessage, FastField } from 'formik';
import Proptypes from 'prop-types';
import { formErrorMessage } from './FormErrorMessage';
import AsyncSelect from '../select/AsyncSelect';

const FormikAsyncSelect = ({ name, ...props }) => (
  <FastField name={name}>
    {({ field: { value }, meta, form: { setFieldValue, touched, errors } }) => (
      <>
        <AsyncSelect
          value={value}
          onSelect={(values) => {
            setFieldValue(name, values);
          }}
          // isValid={touched[name] && !errors[name]}
          isInvalid={ meta.touched && meta.error}
          {...props}
        />
        <ErrorMessage name={name} render={formErrorMessage} />
      </>
    )}
  </FastField>
);
FormikAsyncSelect.propTypes = {
  name: Proptypes.string.isRequired,
  type: Proptypes.string,
  placeholder: Proptypes.string,
};

export default React.memo(FormikAsyncSelect);
