import { forEach, isEmpty, set } from 'lodash';
import { apiSlice } from '../../app/services/apiSlice';
import {
  convertToDaysOrWeeks,
  formateDataByMonth,
  transformDataSorted,
} from './assets/analyticsUtil';
import { STATUSES } from '../../utils/Statuses';
import { convertToShortForm } from '../../utils/helper';

const REPORTS_URL = 'backoffice/reports/';

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    overviewSummery: builder.query({
      query: (params) => ({
        url: `${REPORTS_URL}overview-summary`,
        params,
      }),
      transformResponse: (responseData) => {
        if (!responseData) return {};

        const {
          jobsPosted = 0,
          applicants = 0,
          filledPositions = 0,
          totalPositions = 0,
          avgTimeToHire = 0,
          campignCost = 0,
          hiredAppCount = 0,
          jobsCount = 0,
        } = responseData;

        return {
          jobsPosted: String(jobsPosted),
          applicants: String(applicants),
          jobsCount: String(jobsCount),
          timeToHire: avgTimeToHire
            ? `${Number(Math.ceil(avgTimeToHire))} ${
                Number(Math.ceil(avgTimeToHire)) > 1 ? 'days' : 'day'
              }`
            : '-',
          costToHire: hiredAppCount
            ? `£ ${(campignCost / hiredAppCount).toFixed(2)}`
            : '-',
          applicantPerJob: jobsCount ? Math.round(applicants / jobsCount) : '-',
          fillRate: totalPositions
            ? `${((filledPositions / totalPositions) * 100).toFixed(2)}%`
            : '-',
          costPerApplicant: applicants
            ? `£ ${(campignCost / applicants).toFixed(2)}`
            : '-',
          positionOpen: `${totalPositions - filledPositions}`,
        };
      },
    }),
    candidatepool: builder.query({
      query: (params) => ({
        url: `${REPORTS_URL}key-performance-metrics/candidate-pool`,
        params,
      }),
      transformResponse: (responseData) => {
        return responseData && responseData[0]
          ? Number(responseData[0]?.candPoll)
          : 0;
      },
    }),
    overviewPipeline: builder.query({
      query: (params) => ({
        url: `${REPORTS_URL}overview-pipeline`,
        params,
      }),
      transformResponse: (responseData) => {
        if (!responseData) return {};

        const { filledPositions = 0, totalPositions = 0 } = responseData;
        forEach(responseData?.pipelineGraphData, (current, i) => {
          if (i === 0) return;
          const prev = responseData?.pipelineGraphData[i - 1];
          if (prev) {
            const percentageIncrease = (current.count / prev.count) * 100;
            set(current, 'percentageIncrease', percentageIncrease);
          } else {
            set(current, 'percentageIncrease', 0);
          }
        });

        return {
          ...responseData,

          fillRate: totalPositions
            ? `${((filledPositions / totalPositions) * 100).toFixed(2)}%`
            : '-',
        };
      },
    }),
    deiFormStatus: builder.query({
      query: (params) => ({
        url: `reports/dei/section/form-status`,
        params,
      }),
      transformResponse: (responseData) =>
        responseData
          ? {
              ...(responseData?.data || {}),
            }
          : {},
    }),
    overviewFilters: builder.query({
      query: (params) => ({
        url: `${REPORTS_URL}overview-filters-data`,
        params,
      }),
      transformResponse: (responseData) => {
        responseData['campaignList'] = responseData?.campaignList?.map(
          (dt) => ({
            label: dt.campaignName,
            value: dt.campaignId,
          }),
        );
        responseData['channelList'] = responseData?.channelList?.map((dt) => ({
          label: dt.channelName,
          value: dt.channelId,
        }));
        responseData['jobCategoryList'] = responseData?.jobCategoryList?.map(
          (dt) => ({
            label: dt.jobCategoryName,
            value: dt.jobCategoryId,
          }),
        );
        responseData['jobTypeList'] = responseData?.jobTypeList?.map((dt) => ({
          label: dt.jobType,
          value: dt.jobType,
        }));
        return responseData || {};
      },
    }),

    candidatePoolDiversity: builder.query({
      query: (params) => ({
        url: `reports/dei/section/candidate/pool-diversity`,
        params,
      }),
      transformResponse: (responseData) => [...(responseData?.data || [])],
    }),

    deiBreakDownOffers: builder.query({
      query: (params) => ({
        url: `reports/dei/section/offer-base/group`,
        params,
      }),
      transformResponse: (responseData) => [...(responseData?.data || [])],
    }),

    deiPipeline: builder.query({
      query: (params) => ({
        url: `reports/dei/section/pipeline-diversity`,
        params,
      }),
      transformResponse: (responseData) => [...(responseData?.data || [])],
    }),

    deiOfferAcceptanceRate: builder.query({
      query: (params) => ({
        url: `reports/dei/section/offers-status`,
        params,
      }),
      transformResponse: (responseData) => responseData?.data || [],
    }),
    formQuestionDropdown: builder.query({
      query: (params) => ({
        url: `forms/dei/form-questions`,
        params,
      }),
      transformResponse: (responseData) => {
        responseData['options'] = responseData?.map((dt) => ({
          label: dt.section_label,
          value: dt.id,
        }));
        return responseData || {};
      },
    }),
    jobsGroupCount: builder.query({
      query: (params) => ({
        url: `${REPORTS_URL}jobs/section/group-count`,
        params,
      }),
      transformResponse: (responseData) => {
        if (!responseData) return {};

        const { filledPositions = 0, totalPositions = 0 } = responseData;

        return {
          ...responseData,
          fillRate: totalPositions
            ? `${((filledPositions / totalPositions) * 100).toFixed(2)}%`
            : '-',
        };
      },
    }),

    candidatesAnalysis: builder.query({
      query: (params) => ({
        url: `${REPORTS_URL}candidates/candidate-analysis`,
        params,
      }),
    }),
    topCustomerAnalysis: builder.query({
      query: (params) => ({
        url: `${REPORTS_URL}thirdparty/top-customer-graph`,
        params,
      }),
    }),
    integrationUsageAnalysis: builder.query({
      query: (params) => ({
        url: `${REPORTS_URL}thirdparty/integration-usage-graph`,
        params,
      }),
      transformResponse: (responseData, meta, arg) => {
        let transformedArray = [];
        if(responseData?.length > 0){
transformedArray = [
  {'Signable Requests':responseData[0]?.signableReqCount,key:'Signable Requests',credits:responseData[0]?.signableCredits,count:responseData[0]?.signableReqCount || 0},
  {'uCheck Requests':responseData[0]?.uCheckReqCount,key:'uCheck Requests',credits:responseData[0]?.uCheckCredits,count:responseData[0]?.uCheckReqCount || 0},
  {'RefNow Requests':responseData[0]?.refNowReqCount,key:'RefNow Requests',credits:responseData[0]?.refNowCredits,count:responseData[0]?.refNowReqCount || 0},
  {'MessageBird Requests':responseData[0]?.smsReqCount,key:'MessageBird Requests',credits:responseData[0]?.smsCredits,count:responseData[0]?.smsReqCount || 0},
  {'Shine Requests':responseData[0]?.shineReqCount,key:'Shine Requests',credits:responseData[0]?.shineCredits,count:responseData[0]?.shineReqCount || 0}
]
        } 
  
        return transformedArray;
      },
    }),
    analyticsTopSpenders: builder.query({
      query: (params) => ({
        url: `${REPORTS_URL}top-spenders`,
        params,
      }),
    }),
    analyticsCampaignAnalysis: builder.query({
      query: (params) => ({
        url: `${REPORTS_URL}campaigns/campaign-analysis`,
        params,
      }),
    }),
    thirdPartyMetrics: builder.query({
      query: (params) => ({
        url: `${REPORTS_URL}thirdparty/top-metrics/integrations`,
        params,
      }),
      transformResponse: (responseData) => {
        if (!responseData) return {};

        return {
          ...responseData,
          partiallyIntegrated: responseData?.integratedCustomers - responseData?.fullyIntegratedCustomers,
          avgInt: Number(responseData?.avgIntegration) > 0 ? Number(responseData?.avgIntegration).toFixed(1) : 0,
          fullIntPercentage: Number(responseData?.fullyIntegratedCustomers / responseData?.totalActiveCustomers) * 100,
          partialIntPercentage: Number((responseData?.integratedCustomers - responseData?.fullyIntegratedCustomers) / responseData?.totalActiveCustomers) * 100,
          messageBirdIntegratedPercentage:  Number(responseData?.messageBirdIntegrated / responseData?.totalActiveCustomers) * 100,
          signableIntegratedPcentage: Number(responseData?.signableIntegrated / responseData?.totalActiveCustomers) * 100,
          uCheckIntegratedPercentage: Number(responseData?.uCheckIntegrated / responseData?.totalActiveCustomers) * 100,
          refNowIntegratedPercentage: Number(responseData?.refNowIntegrated / responseData?.totalActiveCustomers) * 100,
          shineIntegratedPercentage: Number(responseData?.shineIntegrated / responseData?.totalActiveCustomers) * 100,

          automated: (responseData?.autoRequestCount/responseData?.totalRequestCount) * 100
        };
      },
    }),
    analyticsCustomerBaseGrowth: builder.query({
      query: (params) => ({
        url: `${REPORTS_URL}customer-growth`,
        params,
      }),
      transformResponse: (responseData, meta, arg) => {
        const response = {};
        response.originalData = responseData;
        response.lineData = transformDataSorted(
          responseData?.customerBaseGrowth,
          arg?.groupBy,
        );
        response.lineData = formateDataByMonth(
          response?.lineData,
          'active_companies',
          arg?.groupBy,
          arg,
        );
        response.barData = transformDataSorted(
          responseData?.newAcquisitionData,
          arg?.groupBy,
        );
        response.barData = formateDataByMonth(
          response?.barData,
          'newAcquisitionCount',
          arg?.groupBy,
          arg,
        );
        response.barData2 = transformDataSorted(
          responseData?.churnData,
          arg?.groupBy,
        );
        response.barData2 = formateDataByMonth(
          response?.barData2,
          'churnCount',
          arg?.groupBy,
          arg,
        );
        return response;
      },
    }),
    analyticsCreditsConsump: builder.query({
      query: (params) => ({
        url: `${REPORTS_URL}credit-consumption`,
        params,
      }),
      transformResponse: (responseData, meta, arg) => {
        const responce = {};
        let line = responseData.filter(
          (item) => item.transactionType === 'Debit',
        );
        let bar = responseData.filter(
          (item) => item.transactionType === 'Credit',
        );
        responce.originalData = responseData;
        responce.lineData = transformDataSorted(line, arg?.groupBy);
        responce.lineData = formateDataByMonth(
          responce?.lineData,
          'amount',
          arg?.groupBy,
          arg,
        );
        responce.barData = transformDataSorted(bar, arg?.groupBy);
        responce.barData = formateDataByMonth(
          responce?.barData,
          'amount',
          arg?.groupBy,
          arg,
        );
        return responce;
      },
    }),
    analyticsKpiMetrics: builder.query({
      query: (params) => ({
        url: `${REPORTS_URL}key-performance-metrics`,
        params,
      }),
      transformResponse: (responseData) => {
        if (isEmpty(responseData)) return {};
        const {
          applicantQuality = 0,
          applicationRecieved = 0,
          campaignRun = 0,
          hiredApplicants = 0,
          hiringPlanApproved = 0,
          // interviewsScheduled = 0,
          offerApplicants = 0,
          positionCreated = 0,
          screenApplicants = 0,
          selectApplicants = 0,
          timeToOffer = 0,
          cost = 0,
        } = responseData[0];

        return {
          positionCreated: `${positionCreated || '0'}`,
          applicationRecieved: String(applicationRecieved || '0'),
          hiredApplicants: hiredApplicants,
          fillRate:
            Number(hiredApplicants) !== 0 && Number(positionCreated) !== 0
              ? `${Math.round(
                  (Number(hiredApplicants) / Number(positionCreated)) * 100,
                ).toFixed(1)}%`
              : '-',
          costPerHire:
            Number(cost) !== 0 && Number(hiredApplicants) !== 0
              ? `£${Math.round(
                  Number(cost) / Number(hiredApplicants).toFixed(1),
                )}`
              : '-',
          hiringPlanApproved: `${hiringPlanApproved}`,
          timeToOffer: timeToOffer
            ? `${Number(timeToOffer).toFixed(1)} days`
            : 0,
          totalCampaignRun: Number(campaignRun),
          campaignCost: `£${Number(cost) !== 0 ? Number(cost).toFixed(1) : 0}`,
          costPerApplicant:
            Number(cost) !== 0 && Number(applicationRecieved) !== 0
              ? `£${Math.round(
                  Number(cost) / Number(applicationRecieved),
                ).toFixed(1)}`
              : '-',
          applicantQuality:
            Number(applicantQuality) !== 0 && Number(applicationRecieved) !== 0
              ? `${Math.ceil(
                  (Number(applicantQuality) / Number(applicationRecieved)) *
                    100,
                ).toFixed(1)}%`
              : '-',
          hireRatio:
            Number(hiredApplicants) !== 0 && Number(offerApplicants) !== 0
              ? `${Math.round(
                  (Number(hiredApplicants) / Number(offerApplicants)) * 100,
                ).toFixed(1)}%`
              : '-',
          candidatePool: '0',
          screened: `${screenApplicants || '0'}`,
          selected: `${selectApplicants || '0'}`,
          offered: offerApplicants,
        };
      },
    }),
    onboardingKpiMetrics: builder.query({
      query: (params) => ({
        url: `${REPORTS_URL}onboarding/top-metrics`,
        params,
      }),
      transformResponse: (responseData) => {
        if (isEmpty(responseData)) return {};
        const {
          activeUsers = 0,
          customers = 0,
          timeToHandOff = 0,
          userActivationRate = 0,
         
        } = responseData[0];
        return {
          activeUsers: Number(activeUsers) || 0,
          customers: Number(customers) || 0,
          timeToHandOff: convertToDaysOrWeeks(Number(timeToHandOff)) || 0,
          userActivationRate: Number(userActivationRate).toFixed(1) || 0,
        };
      },
    }),
    adminOverview: builder.query({
      query: (params) => ({
        url: `${REPORTS_URL}admin-overview`,
        params,
      }),
      transformResponse: (responseData) => {
        if (isEmpty(responseData)) return {};
        const {
          balanceCredit = 0,
          activeCustomers = 0,
          inactiveCustomers = 0,
          newCustomer = 0,
          supscriptionExpiry = 0,
          lowCreditsWarning = 0,
        } = responseData;

        return {
          lowCreditsWarning: `${convertToShortForm(lowCreditsWarning)}`,
          balanceCredit: convertToShortForm(balanceCredit),
          activeCustomers: `${convertToShortForm(activeCustomers)}`,
          inactiveCustomers: `${convertToShortForm(inactiveCustomers)}`,
          newCustomer: `${convertToShortForm(newCustomer)}`,
          supscriptionExpiry: `${convertToShortForm(supscriptionExpiry)}`,
        };
      },
    }),

    onboardingAvgTimeGraph: builder.query({
      query: (params) => ({
        url: `${REPORTS_URL}onboarding/avg-stagetime-graph`,
        params,
      }),
      transformResponse: (responseData) => {
        if (isEmpty(responseData)) return 'NoData';

        const {
          applicationRecieved = 0,
          screenApplicants = 0,
          selectApplicants = 0,
          offerApplicants = 0,
          hiredApplicants = 0,
        } = responseData[0];
        return {
          ...responseData[0],
          screenPercentage:
            Number(screenApplicants) !== 0 && Number(applicationRecieved) !== 0
              ? (Number(screenApplicants) / Number(applicationRecieved)) * 100
              : 0,
          selectPercentage:
            Number(screenApplicants) !== 0 && Number(selectApplicants) !== 0
              ? (Number(selectApplicants) / Number(screenApplicants)) * 100
              : 0,
          offerPercentage:
            Number(offerApplicants) !== 0 && Number(selectApplicants) !== 0
              ? (Number(offerApplicants) / Number(selectApplicants)) * 100
              : 0,
          hirePercentage:
            Number(hiredApplicants) !== 0 && Number(offerApplicants) !== 0
              ? (Number(hiredApplicants) / Number(offerApplicants)) * 100
              : 0,
        };
      },
    }),

    candidatesByLocation: builder.query({
      query: (params) => ({
        url: `${REPORTS_URL}candidates/location`,
        params,
      }),
    }),

    candidatesSummery: builder.query({
      query: (params) => ({
        url: `${REPORTS_URL}candidates/section/summary`,
        params,
      }),
      transformResponse: (responseData) => {
        const data = responseData?.data || {};
        const candCount = String(data?.candCount ?? '');
        const shortlisted = String(data?.shortlisted ?? '');
        const appPerCandidate = data?.candCount
          ? Math.ceil(Number(data?.appCount ?? 0) / Number(data?.candCount))
          : '-';
        const hireRatio = data?.offerSent
          ? `${(
              (Number(data?.offerComplete ?? 0) / Number(data?.offerSent)) *
              100
            )?.toFixed(2)}%`
          : '-';
        return { ...data, candCount, shortlisted, appPerCandidate, hireRatio };
      },
    }),
    automationTrend: builder.query({
      query: (params) => ({
        url: `${REPORTS_URL}thirdparty/automation-trend-graph`,
        params,
      }),
      transformResponse: (responseData, meta, arg) => {
        const responce = {};
        responce.originalData = responseData;
        responce.lineData = transformDataSorted(
          responseData,
          arg?.groupBy,
        );
        responce.lineData = formateDataByMonth(
          responce?.lineData,
          'automatedCredits',
          arg?.groupBy,
          arg,
        );
        responce.barData = transformDataSorted(
          responseData,
          arg?.groupBy,
        );
        responce.barData = formateDataByMonth(
          responce?.barData,
          'automatedPercentage',
          arg?.groupBy,
          arg,
        );
        return responce;
      },
    }),
    featureTrend: builder.query({
      query: (params) => ({
        url: `${REPORTS_URL}feature/automation-trend-graph`,
        params,
      }),
      transformResponse: (responseData, meta, arg) => {
       
        const responce = {};
        let responceDta=responseData?.map(item=>
          ({...item,"automatedPercentage":Number(item?.automatedPercentage).toFixed(1) || 0}))
         
        responce.originalData = responceDta;
        responce.barData = transformDataSorted(
          responceDta,
          arg?.groupBy,
        );
        responce.barData = formateDataByMonth(
          responce?.barData,
          'automatedPercentage',
          arg?.groupBy,
          arg,
        );
        responce.totalRequests = transformDataSorted(
          responceDta,
          arg?.groupBy,
        );
        responce.totalRequests=formateDataByMonth(
          responce?.totalRequests,
          'totalRequestCount',
          arg?.groupBy,
          arg,
        );
        responce.year = transformDataSorted(
          responceDta,
          arg?.groupBy,
        );
        responce.year=formateDataByMonth(
          responce?.year,
          'year',
          arg?.groupBy,
          arg,
        );
        responce.automaticReqs = transformDataSorted(
          responceDta,
          arg?.groupBy,
        );
        responce.automaticReqs=formateDataByMonth(
          responce?.automaticReqs,
          'autoRequestCount',
          arg?.groupBy,
          arg,
        );
        return responce;
      },
    }),
    campaignPerformance: builder.query({
      query: (params) => ({
        url: `${REPORTS_URL}campaigns/campaign-performance`,
        params,
      }),
      transformResponse: (responseData, meta, arg) => {
        const responce = {};
        responce.originalData = responseData;
        responce.lineData = transformDataSorted(
          responseData?.applicationReceive,
          arg?.groupBy,
        );
        responce.lineData = formateDataByMonth(
          responce?.lineData,
          'appReceived',
          arg?.groupBy,
          arg,
        );
        responce.barData = transformDataSorted(
          responseData?.campaignCost,
          arg?.groupBy,
        );
        responce.barData = formateDataByMonth(
          responce?.barData,
          'cost',
          arg?.groupBy,
          arg,
        );
        return responce;
      },
    }),
    onboardingTrendGraph: builder.query({
      query: (params) => ({
        url: `${REPORTS_URL}onboarding/trend-graph`,
        params,
      }),
      transformResponse: (responseData, meta, arg) => {
        const responce = {};
        responce.data = transformDataSorted(responseData, arg?.groupBy);
        responce.lineData = formateDataByMonth(
          responce?.data,
          'timeToHandOff',
          arg?.groupBy,
          arg,
        );
        responce.barData = formateDataByMonth(
          responce?.data,
          'obCompleted',
          arg?.groupBy,
          arg,
        );
        return responce;
      },
    }),

    integrationBreakdown: builder.query({
      query: (params) => ({
        url: `${REPORTS_URL}thirdparty/integration-breakdown-graph`,
        params,
      }),
      transformResponse: (responseData, meta, arg) => {
        return [{title:'Signable (E-Signs)',
        customers:responseData[0]?.customers,
      requests:responseData[0]?.signableReqCount,
      integrated:responseData[0]?.signableIntegrated,
    share:((responseData[0]?.signableReqCount/responseData[0]?.totalRequestCount) * 100).toFixed(1),
    automations:(Number(responseData[0]?.autoSignableReqCount) / Number(responseData[0]?.signableReqCount)*100).toFixed(1) || 0,
    creditsGrowth:responseData[0]?.signableCredits ,
    growth: (responseData[0]?.signableCredits /  responseData[0]?.totalcredits) * 100
  },
  {title:'uCheck (Background Checks)',
  customers:responseData[0]?.customers,
  integrated:responseData[0]?.uCheckIntegrated,
  requests:responseData[0]?.uCheckReqCount,
share:((responseData[0]?.uCheckReqCount/responseData[0]?.totalRequestCount) * 100).toFixed(1),
automations:(Number(responseData[0]?.autoUCheckReqCount) / Number(responseData[0]?.uCheckReqCount)*100).toFixed(1) || 0,
creditsGrowth:responseData[0]?.uCheckCredits,
growth: (responseData[0]?.signableCredits /  responseData[0]?.totalcredits) * 100
},

{title:'RefNow (References)', customers:responseData[0]?.customers,
integrated:responseData[0]?.refNowIntegrated,
requests:responseData[0]?.refNowReqCount,
share:((responseData[0]?.refNowReqCount/responseData[0]?.totalRequestCount) * 100).toFixed(1),
automations:(Number(responseData[0]?.autoRefNowReqCount) / Number(responseData[0]?.refNowReqCount)*100).toFixed(1) || 0,
creditsGrowth:responseData[0]?.refNowCredits,
growth: (responseData[0]?.signableCredits /  responseData[0]?.totalcredits) * 100
},

{title:'MessageBird (SMS)',
customers:responseData[0]?.customers,
integrated:responseData[0]?.messageBirdIntegrated,
requests:responseData[0]?.smsReqCount,
share:((responseData[0]?.smsReqCount/responseData[0]?.totalRequestCount) * 100).toFixed(1),
automations:(Number(responseData[0]?.autoSmsReqCount) / Number(responseData[0]?.smsReqCount)*100).toFixed(1) || 0,
creditsGrowth:responseData[0]?.smsCredits,
growth: (responseData[0]?.signableCredits /  responseData[0]?.totalcredits) * 100
},

{title:'Shine (Video Profiling)', customers:responseData[0]?.customers,
 integrated:responseData[0]?.shineIntegrated,
        requests:responseData[0]?.shineReqCount,
      share:((responseData[0]?.shineReqCount/responseData[0]?.totalRequestCount) * 100).toFixed(1),
      automations:(Number(responseData[0]?.autoShineReqCount) / Number(responseData[0]?.shineReqCount)*100).toFixed(1) || 0,
      creditsGrowth:responseData[0]?.shineCredits
    }];
      }
    }),
    reasonsForRejection: builder.query({
      query: (params) => ({
        url: `${REPORTS_URL}candidates/rejection-reason`,
        params,
      }),
    }),
    onboardingStatusGraph: builder.query({
      query: (params) => ({
        url: `${REPORTS_URL}onboarding/status-graph`,
        params,
      }),
      transformResponse: (responseData, meta, arg) => {
        let transformedArray = [];
        if(responseData?.length > 0){
          transformedArray = [{'Completed' : responseData[0]?.obCompleted},
        {'Ongoing' : responseData[0]?.obInCompleted}
      ];}
        return transformedArray;
      },
    }),
    onboardingCustomerStatusGraph: builder.query({
      query: (params) => ({
        url: `${REPORTS_URL}onboarding/customer-status`,
        params,
      }),
    }),
    candidatesChannelEffectiveness: builder.query({
      query: (params) => ({
        url: `${REPORTS_URL}campaigns/candidate-analysis`,
        params,
      }),
      transformResponse: (responseData) => {
        return responseData || [];
      },
    }),
    getAsyncChildCompaniesListing: builder.mutation({
      query: (data) => ({
        url: `company/all-companies`,
        method: 'POST',
        body: {
          ...data,
        },
      }),
    }),
    deiReportDownload: builder.query({
      query: (params) => ({
        url: `${REPORTS_URL}dei/section/download`,
        params,
      }),
    }),

    getFilterCompanyJobs: builder.mutation({
      query: (params) => ({
        url: `${REPORTS_URL}get-jobs-title`,
        params: { ...params, sort: 'title', sortDir: 'ASC' },
      }),
      transformResponse: (responseData) => {
        responseData.options = responseData?.rows?.map((job) => ({
          ...job,
          value: job.id,
          label: job.title,
        }));
        return responseData || {};
      },
    }),

    getCompanyJobCategories: builder.mutation({
      query: (params) => ({
        url: `${REPORTS_URL}company-job-categories`,
        params: { ...params, sort: 'title', sortDir: 'ASC' },
      }),
      transformResponse: (responseData) => {
        responseData.options = responseData?.rows?.map((cat) => ({
          ...cat,
          value: cat.id,
          label: cat.title,
        }));
        return responseData || {};
      },
    }),

    getCompanyDepartment: builder.mutation({
      query: (params) => ({
        url: `${REPORTS_URL}company-depts`,
        params: { ...params, sort: 'title', sortDir: 'ASC' },
      }),
      transformResponse: (responseData) => {
        responseData.options = responseData?.rows?.map((cat) => ({
          ...cat,
          value: cat.id,
          label: cat.title,
        }));
        return responseData || {};
      },
    }),

    getCompanyHiringManager: builder.mutation({
      query: (params) => ({
        url: `${REPORTS_URL}company-accounts`,
        params: { ...params, roleAccessKey: 'HIRING_MANAGER' },
      }),
      transformResponse: (responseData) => {
        responseData.options = responseData?.accounts?.map((team) => ({
          ...team,
          value: team.id,
          label: team?.firstName + ' ' + team?.lastName,
        }));
        return responseData || {};
      },
    }),

    getCompanyApprover: builder.mutation({
      query: (params) => ({
        url: `${REPORTS_URL}company-accounts`,
        params: {
          ...params,
          roleAccessKey: 'APPROVER',
          filters: STATUSES.ACTIVE,
        },
      }),
      transformResponse: (responseData) => {
        responseData.options = responseData?.accounts?.map((team) => ({
          ...team,
          value: team.id,
          label: team?.firstName + ' ' + team?.lastName,
        }));
        return responseData || {};
      },
    }),

    getJobCampaign: builder.mutation({
      query: (params) => ({
        url: `${REPORTS_URL}company-campaigns`,
        params,
      }),
      transformResponse: (responseData) => {
        responseData.options = responseData?.data?.map((camp) => ({
          ...camp,
          value: camp.campaign.id,
          label: camp.campaign.campaignName,
        }));

        return responseData || {};
      },
    }),

    getJobNameForCampaign: builder.mutation({
      query: (params) => ({
        url: `${REPORTS_URL}company-campaigns`,
        params,
      }),
      transformResponse: (responseData) => {
        responseData.options = responseData?.data?.map((camp) => ({
          ...camp,
          value: camp.campaign.id,
          label: camp.campaign.jobTitle,
        }));

        return responseData || {};
      },
    }),

    getCampaignChannel: builder.mutation({
      query: (params) => ({
        url: `${REPORTS_URL}company-products`,
        params,
      }),
      transformResponse: (responseData) => {
        responseData.options = responseData?.advertising?.map((channel) => ({
          ...channel,
          value: channel.id,
          label: channel.title,
        }));
        return responseData || {};
      },
    }),

    getJobRejectionReason: builder.mutation({
      query: (params) => ({
        url: `${REPORTS_URL}company-decline-reasons`,
        params,
      }),
      transformResponse: (responseData) => {
        responseData.options = responseData?.declineReasons?.map((reason) => ({
          ...reason,
          value: reason?.id,
          title: reason?.text,
        }));
        return responseData || {};
      },
    }),
    popularFeatures: builder.query({
      query: (params) => ({
        url: `${REPORTS_URL}feature/popular-automation-graph`,
        params,
      }),
      transformResponse: (responseData, meta, arg) => {
        return [
          {
            title: 'Email',
            customers: Number(responseData?.customers),
            integrated:Number(responseData?.email),
            requests: Number(responseData?.emailReqCount),
            share: (
              (Number(responseData?.emailReqCount) /
                Number(responseData?.totalRequestCount)) *
              100
            ).toFixed(1),
            automations:(Number(responseData?.autoEmailReqCount) / Number(responseData?.emailReqCount)*100).toFixed(1) || 0,
            growth:
              (Number(responseData?.emailReqCount) /
                Number(responseData?.totalRequestCount)) *
              100,
          },
          {
            title: 'SMS',
            customers: Number(responseData?.customers),
            integrated:Number(responseData?.sms),
            requests: Number(responseData?.smsReqCount),
            share: (
              (Number(responseData?.smsReqCount) /
               Number(responseData?.totalRequestCount)) *
              100
            ).toFixed(1),
            automations:(Number(responseData?.autoSmsReqCount) / Number(responseData?.smsReqCount)*100).toFixed(1) || 0,
            growth:
              (Number(responseData?.smsReqCount) /
              Number(responseData?.totalRequestCount)) *
              100,
          },

          {
            title: 'Form',
            customers: Number(responseData?.customers),
            integrated:Number(responseData?.form),
            requests: Number(responseData?.formReqCount),
            share: ((Number(responseData?.formReqCount) / Number(responseData?.totalRequestCount)) * 100).toFixed(1),
            automations: (Number(responseData?.autoFormReqCount) / Number(responseData?.formReqCount)*100).toFixed(1) || 0,
            creditsGrowth: responseData?.refNowCredits,
            growth:
              (responseData?.formReqCount /
                responseData?.totalcredits) *
              100,
          },

          {
            title: 'Document',
            customers: Number(responseData?.customers),
            integrated:Number(responseData?.document),
            requests: Number(responseData?.documentReqCount),
            share: (
              (Number(responseData?.documentReqCount) /
                Number(responseData?.totalRequestCount)) *
              100
            ).toFixed(1),
            automations:(Number(responseData?.autoDocumentReqCount) / Number(responseData?.documentReqCount)*100).toFixed(1) || 0,
            creditsGrowth: responseData?.smsCredits,
            growth:
              (responseData?.signableCredits /
                responseData?.totalcredits) *
              100,
          },

          {
            title: 'Interview Invite',
            customers: Number(responseData?.customers),
            integrated:Number(responseData?.interview),
            requests: Number(responseData?.interviewReqCount),
            share: (
              (Number(responseData?.interviewReqCount) /
                Number(responseData?.totalRequestCount)) *
              100
            ).toFixed(1),
            automations:(Number(responseData?.autoInterviewReqCount) / Number(responseData?.interviewReqCount)*100).toFixed(1) || 0,
            creditsGrowth: responseData?.shineCredits,
          },
        ];
      }
    }),
    customerFeatures: builder.query({
      query: (params) => ({
        url: `${REPORTS_URL}feature/popular-features-graph`,
        params,
      }),
      transformResponse: (responseData) => {
        return {
          dei: Number(responseData?.dei) || 0,
          calendar: Number(responseData?.calendar)||0,
          approver: Number(responseData?.approver)||0,
          childCompany:Number(responseData?.childCompany)||0,
          careerSite: Number(responseData?.careerSite)||0,
          customers: Number(responseData?.customers) ||0,
        };
      },
    }),
    featureAdoptionMetrics: builder.query({
      query: (params) => ({
        url: `${REPORTS_URL}feature/top-metrics/adoption`,
        params,
      }),
      transformResponse: (responseData) => {
        if (isEmpty(responseData)) return {};
        const {
          customers = 0,
          partialAdoption = 0,
          completeAdoption = 0,
          avgIntegration = 0,
          calendar = 0,
          // interviewsScheduled = 0,
          approver = 0,
          dei = 0,
          childCompany = 0,
          careerSite = 0,
          totalRequestCount = 0,
          autoRequestCount = 0,

        } = responseData;

        return {
          dei: Number(dei) || 0,
          partialAdoption: Number(partialAdoption || 0),
          avgIntegration: Number(avgIntegration).toFixed(1) || 0,
          autoRequestCount: (Number(autoRequestCount)/Number(totalRequestCount) * 100) ||0,
          calendar: Number(calendar)||0,
          approver: Number(approver)||0,
          childCompany:Number(childCompany)||0,
          careerSite: Number(careerSite)||0,
          totalRequestCount: totalRequestCount
            ? Number(totalRequestCount)
            : 0,
          completeAdoption: Number(completeAdoption),
          customers: Number(customers) ||0,
        };
      },
    }),

    keyOccyMetrics: builder.query({
      query: (params) => ({
        url: `${REPORTS_URL}keymetrics/customers-and-feature`,
        params,
      }),
      transformResponse: (responseData) => {
        if (!responseData) return {};

        const {
          activeCustomers = 0,
          activeUsers = 0,
          approver = 0,
          autoEvent = 0,
          calendar = 0,
          careerSite = 0,
          childCompany = 0,
          copywriting=0,
          dei=0,
          skillAndAssessment=0,
        } = responseData[0];

        return {
          activeCustomers: Math.round(Number(activeCustomers)),
          activeUsers:  Math.round(Number(activeUsers)),
          teamSize: (Number(activeUsers)/Number(activeCustomers)).toFixed(1) || 0,
          approver: Math.round((Number(approver)/Number(activeCustomers))*100) || 0,
          autoEvent:  Math.round((Number(autoEvent)/Number(activeCustomers))*100) || 0,
          calendar:  Math.round((Number(calendar)/Number(activeCustomers))*100) || 0,
          careerSite:  Math.round((Number(careerSite)/Number(activeCustomers))*100) || 0,
          childCompany:  Math.round((Number(childCompany)/Number(activeCustomers))*100) || 0,
          skillAndAssessment:  Math.round((Number(skillAndAssessment)/Number(activeCustomers))*100) || 0,
          copywriting:  Math.round((Number(copywriting)/Number(activeCustomers))*100) || 0,
          dei:  Math.round((Number(dei)/Number(activeCustomers))*100) || 0,
        };
      },
    }),
  }),
});

export const {
  useIntegrationUsageAnalysisQuery,
  useAdminOverviewQuery,
  useGetAsyncChildCompaniesListingMutation,
  useAnalyticsCampaignAnalysisQuery,
  useTopCustomerAnalysisQuery,
  useAnalyticsTopSpendersQuery,
  useThirdPartyMetricsQuery,
  useAnalyticsCreditsConsumpQuery,
  useAnalyticsCustomerBaseGrowthQuery,
  useOverviewSummeryQuery,
  useCandidatepoolQuery,
  useAnalyticsKpiMetricsQuery,
  useOnboardingKpiMetricsQuery,
  useOnboardingAvgTimeGraphQuery,
  useOverviewPipelineQuery,
  useFormQuestionDropdownQuery,
  useDeiFormStatusQuery,
  useJobsGroupCountQuery,
  useCandidatePoolDiversityQuery,
  useOverviewFiltersQuery,
  useCandidatesByLocationQuery,
  useCandidatesSummeryQuery,
  useAutomationTrendQuery,
  useCandidatesChannelEffectivenessQuery,
  useDeiOfferAcceptanceRateQuery,
  useDeiBreakDownOffersQuery,
  useDeiPipelineQuery,
  useDeiReportDownloadQuery,
  useCampaignPerformanceQuery,
  useOnboardingTrendGraphQuery,
  useOnboardingStatusGraphQuery,
  useOnboardingCustomerStatusGraphQuery,
  useIntegrationBreakdownQuery,
  useCandidatesAnalysisQuery,
  useReasonsForRejectionQuery,
  useGetFilterCompanyJobsMutation,
  useGetCompanyJobCategoriesMutation,
  useGetCompanyDepartmentMutation,
  useGetCompanyHiringManagerMutation,
  useGetCompanyApproverMutation,
  useGetCampaignChannelMutation,
  useGetJobNameForCampaignMutation,
  useGetJobCampaignMutation,
  useGetJobRejectionReasonMutation,
  useFeatureTrendQuery,
  usePopularFeaturesQuery,
  useFeatureAdoptionMetricsQuery,
  useKeyOccyMetricsQuery,
  useCustomerFeaturesQuery
} = extendedApiSlice;
