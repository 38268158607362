import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { Modal, Row } from 'react-bootstrap';
import PortletBody from '../../../app/components/portlet/PortletBody';
import StyledButton from '../../../app/components/styledComponents/StyledButton';
import * as Yup from 'yup';
import FormikInputField from '../../../app/components/formikComponents/FormikInputField';
import FormikAsyncSelect from '../../../app/components/formikComponents/FormikAsyncSelect';
import { useGetSettingsMutation, useAddCompanySettingMutation } from '../../company/companyApiSlice';
import { toast } from 'react-toastify';

const AddCompanySettingsModal = ({ show, compId, onHide, title, editSettingData, editSettings, refetch }) => {
  const [addCompanySetting, { isLoading: loading }] = useAddCompanySettingMutation()
  const addSettingsSchema = Yup.object().shape({
    accessKey: editSettings ? Yup.string().required('required') : Yup.object().required('required'),
    newValue: Yup.string().required('Required').nullable()
  });
  const [selectedValue, setSelectedvalue] = useState({});
  const handleSubmit = (values) => {
    let sendData = {
      id: editSettings ? editSettingData?.id : selectedValue?.value?.id,
      compId,
      body: { value: values.newValue }
    }
    addCompanySetting(sendData).unwrap().then(data => { toast.success(data.message); refetch(); onHide() })
  };
  const handleChange = (val) => {
    setSelectedvalue(val);
  }
  const [getAllSettings] = useGetSettingsMutation();

  return (
    <Modal show={show} centered onHide={onHide} size={'lg'}>
      <Formik
        initialValues={{
          accessKey: editSettings ? editSettingData?.accessKey : undefined,
          newValue: editSettings ? editSettingData?.companySettingValue : undefined,
        }}
        validationSchema={addSettingsSchema}
        onSubmit={handleSubmit}
      >
        {({ handleSubmit, setFieldValue }) => (
          <Form onSubmit={handleSubmit}>
            <Modal.Header
              // title={title}
              closeButton
              className="border-bottom-0 pb-0 align-items-baseline"
            >
              <Modal.Title
                as="h4"
                className="flex-grow-1 p-2 cursor-pointer flex-center"
              >
                {show && title}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="d-flex  flex-column bg-body rounded">
              <PortletBody className="p-0 w-100">
                <PortletBody className={'p-4 w-100'}>
                  <Row>
                    <div className="col-12 col-md-12">
                      <div className="mb-3 ">

                      </div>
                    </div>

                    <div className="col-12 col-md-12">
                      <div className="mb-3">
                        <label htmlFor="accessKey" className="form-label">
                          Access key
                        </label>
                        {editSettings &&
                          <FormikInputField
                            name="accessKey"
                            id="accessKey"
                            placeholder="Access Key"
                            readOnly={true}
                          />
                        }
                        {!editSettings && <FormikAsyncSelect
                          apiCall={getAllSettings}
                          name={`accessKey`}
                          id={`accessKey`}
                          placeholder="Enter access key"
                          onChange={(val) => { handleChange(val); setFieldValue("accessKey", val) }}
                        />}
                      </div>
                    </div>

                    <div className="col-12 col-md-6">
                      <div className="mb-3">
                        <label htmlFor="default Value" className="form-label">
                          Default value
                        </label>{' '}
                        <label htmlFor="default Value" >
                          {editSettings ? editSettingData?.defaultValue : selectedValue?.value?.defaultValue || '--'}
                        </label>

                      </div>
                    </div>

                    <div className="col-12 col-md-6">
                      <div className="mb-3">
                        <label htmlFor="description" className="form-label">
                          Description
                        </label>
                        <label htmlFor="description" >
                          {editSettings ? editSettingData?.description : selectedValue?.value?.description || '--'}
                        </label>

                      </div>
                    </div>


                    <div className="col-12 col-md-12">
                      <div className="mb-3">
                        <label htmlFor="newValue" className="form-label">
                          New value
                        </label>
                        <FormikInputField
                          name="newValue"
                          id="newValue"
                          placeholder="Enter value"

                        />
                      </div>
                    </div>
                  </Row>
                </PortletBody>
              </PortletBody>
              <div className="d-flex justify-content-end mt-3">
                <div className="d-flex gap-3">
                  <StyledButton
                    variant={'text'}
                    className="mb-2 border-0 fw-bold"
                    onClick={onHide}
                  >
                    Cancel
                  </StyledButton>
                  <StyledButton className="mb-2" type="submit" loading={loading} disabled={loading}>
                    Save
                  </StyledButton>
                </div>
              </div>
            </Modal.Body>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default AddCompanySettingsModal;
