import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import FormikInputField from '../../../../app/components/formikComponents/FormikInputField';
import FormikSelect from '../../../../app/components/formikComponents/FormikSelect';
import PortletBody from '../../../../app/components/portlet/PortletBody';
import StyledButton from '../../../../app/components/styledComponents/StyledButton';
import styled from 'styled-components';
import TrashIcon from '../../../../app/components/svgIcons/TrashIcon';
import RefreshIcon from '../../../../app/components/svgIcons/RefreshIcon';
import { Wrap } from '../../../../assets/styledComponents/Global';
import FormikTextEditor from '../../../../app/components/formikComponents/FormikTextEditor';
import FormikAsyncSelect from '../../../../app/components/formikComponents/FormikAsyncSelect';
import { useGetCountiesMutation } from '../../companyApiSlice';
import EditIcon from '../../../../app/components/svgIcons/EditIcon';
import { countryCodes } from '../../../Products/assets/utills';
import { renderHtml } from '../../../../utils/helper';
import OccyLogoDefault from '../../../../app/components/svgIcons/OccyLogoDefault.png';
import StyledColoredTag from '../../../../app/components/styledComponents/StyledColoredTag';
const CompanyInfoEditForm = ({
  isEdit,
  handleSubmit,
  handleFileSelect,
  fileref,
  removeProfileImage,
  CompanyAvatar,
  compyInfo,
  countryType,
  refetch,
  loading,
  editable,
  setEditable,
}) => {
  const [getCounties] = useGetCountiesMutation();
  const [editDetails, setEditDetails] = useState('');
  const [hover, setHover] = useState(false);
  const closeForms = () => {
    setEditDetails('');
  };
  return (
    <>
      <PortletBody className="w-100 pt-2">
        <Row className="py-4">
          <Col md={4}>
            <StyledDiv
              className="position-relative "
              onMouseEnter={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
            >
              <StyledPofileImg width="100%" height="380px" className="rounded">


                  <img
                    src={
                      compyInfo?.compLOGOSignedUrl ?  compyInfo?.compLOGOSignedUrl : OccyLogoDefault
                    }
                    alt="img"
                  /> :

              </StyledPofileImg>
              {hover && (
                <StyledImageBar className="bg-secondary rounded-bottom d-flex  justify-content-center py-3">
                  <input
                    ref={fileref}
                    type="file"
                    id="attachments"
                    name="attachments"
                    accept="image/png, image/jpeg"
                    hidden
                    onChange={(evt) => handleFileSelect(evt)}
                  />
                  <label
                    className="text-white fw-bold cursor-pointer px-2"
                    onClick={() => fileref.current.click()}
                  >
                    <RefreshIcon />
                    <span className="px-2"> Replace </span>
                  </label>

                  <label
                    className="text-white fw-bold cursor-pointer px-2"
                    onClick={removeProfileImage}
                  >
                    <TrashIcon />
                    <span className="px-2"> Remove </span>
                  </label>
                </StyledImageBar>
              )}
            </StyledDiv>
          </Col>
          <Col md={8} className="ps-5">
            <Row className="py-0">
              <div className="col-12 col-md-12">
                <div className="d-flex justify-content-between pl-3">
                  <h4>Basic Information</h4>
                  {editable ? (
                    <StyledButton
                      variant={''}
                      className="me-1"
                      icon={<EditIcon size={20} />}
                      onClick={() => {
                        setEditable(!editable);
                        refetch();
                      }}
                    />
                  ) : (
                    <div className="d-flex align-items-start">
                      <StyledButton
                        variant={'white'}
                        className="me-2 fw-bold h-75"
                        onClick={() => {
                          handleSubmit();
                        }}
                        loading={loading}
                        disabled={loading}
                      >
                        Save
                      </StyledButton>

                      <StyledButton
                        variant={'white'}
                        className="fw-bold h-75"
                        onClick={() => setEditable(!editable)}
                      >
                        Cancel
                      </StyledButton>
                    </div>
                  )}
                </div>
              </div>
            </Row>
            <Row className={`${editable ? 'py-2' : 'py-0'}`}>
              <div className="col-12 col-md-6">
                <div className="mb-2">
                  <label htmlFor="name" className="form-label">
                    Company Name
                  </label>
                  {!editable ? (
                    <FormikInputField
                      name={`name`}
                      id={`name`}
                      placeholder="Company Name"
                    />
                  ) : (
                    <span>{compyInfo?.name}</span>
                  )}
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="mb-2">
                  <label htmlFor="companyEmail" className="form-label">
                    Company Email
                  </label>
                  {!editable ? (
                    <FormikInputField
                      disabled={true}
                      name={`companyEmail`}
                      id={`companyEmail`}
                      placeholder="Company Email"
                      type="email"
                      readOnly={true}
                    />
                  ) : (
                    <span>{compyInfo?.companyEmail}</span>
                  )}
                </div>
              </div>
            </Row>

            <Row className={`${editable ? 'py-2' : 'py-0'}`}>
              <div className="col-12 col-md-6">
                <div className="mb-2">
                  <label htmlFor="telephone" className="form-label">
                    Phone Number
                  </label>
                  {!editable ? (
                    <div className="row">
                      <div className="col-3 pe-1">
                        <div className="mb-3">
                          <FormikSelect
                            className="me-0 pe-0"
                            name={`telephoneCode`}
                            id={`telephoneCode`}
                            placeholder="+44"
                            options={countryCodes}
                          />
                        </div>
                      </div>
                      <div className="col-9 ps-0">
                        <div className="mb-3">
                          <FormikInputField
                            name={`telephone`}
                            id={`telephone`}
                            placeholder="Phone Number"
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <span>{compyInfo?.telephone}</span>
                  )}
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="mb-2">
                  <label htmlFor="companyType" className="form-label">
                    Company Type
                  </label>
                  {/* {!editable ? (
                    <FormikSelect
                      name="companyType"
                      id="companyType"
                      placeholder="Select Country Type"
                      options={countryType}
                    />
                  ) : ( */}
                  <span>{compyInfo?.companyType || 'Standard'}</span>
                  {/* )} */}
                </div>
              </div>
            </Row>

            <Row className={`${editable ? 'py-2' : 'py-0'}`}>
              <div className="col-12 col-md-6">
                <div className="mb-2">
                  <label htmlFor="address" className="form-label">
                    Address
                  </label>
                  {!editable ? (
                    <FormikInputField
                      name={`address`}
                      id={`address`}
                      placeholder="Address"
                    />
                  ) : (
                    <span>{compyInfo?.address}</span>
                  )}
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="mb-2">
                  <label htmlFor="buildingNo" className="form-label">
                    Building Number
                  </label>
                  {!editable ? (
                    <FormikInputField
                      name={`buildingNo`}
                      id={`buildingNo`}
                      placeholder="Building Number"
                    />
                  ) : (
                    <span>{compyInfo?.buildingNo}</span>
                  )}
                </div>
              </div>
            </Row>

            <Row className={`${editable ? 'py-2' : 'py-0'}`}>
              <div className="col-12 col-md-6">
                <div className="mb-2">
                  <label htmlFor="postcode" className="form-label">
                    Post Code
                  </label>
                  {!editable ? (
                    <FormikInputField
                      name={`postcode`}
                      id={`postcode`}
                      placeholder="PostCode"
                    />
                  ) : (
                    <span>
                      {' '}
                      <a
                        href={`https://maps.google.com/maps?q=${compyInfo?.postcode}`}
                        target="_blank"
                        rel="noreferrer"
                        className="no-underline"
                      >
                        {compyInfo?.postcode || '-'}
                      </a>
                    </span>
                  )}
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div>
                  <label htmlFor="county" className="form-label">
                    County
                  </label>
                  {!editable ? (
                    <FormikAsyncSelect
                      apiCall={getCounties}
                      searchAble={true}
                      name="county"
                      id="county"
                      placeholder="Select County"
                    />
                  ) : (
                    <span>{compyInfo?.county}</span>
                  )}
                </div>
              </div>
            </Row>
            <Row className={`${editable ? 'py-2' : 'py-0'}`}>
            <div className="col-12 col-md-6">
                <div className="mb-2">
                  <label htmlFor="name" className="form-label">
                  Indeed Tag
                  </label>
                 <div className={`${compyInfo?.opts?.slug ? 'row' : ''}`}>
                {compyInfo?.opts?.slug ? 
                 <StyledColoredTag variant={'blue'}> {compyInfo?.opts?.slug}</StyledColoredTag>
                 : '-'}    
                      </div>
                </div>
              </div>
            </Row>
          </Col>
        </Row>
        <Row></Row>
      </PortletBody>
      <PortletBody className="w-100 mt-4">
        <Wrap className="">
          <Row className="">
            <div className="col-12 col-md-12">
              <div className="d-flex justify-content-between">
                <h5>About Company</h5>
                {editDetails !== 'aboutCompany' ? (
                  <StyledButton
                    variant={''}
                    className="me-1"
                    icon={<EditIcon size={20} />}
                    onClick={() => setEditDetails('aboutCompany')}
                  />
                ) : (
                  <div className="d-flex">
                    <StyledButton
                      variant={'white'}
                      className="me-1  fw-bold h-75"
                      onClick={() => {
                        handleSubmit();
                        closeForms();
                      }}
                      loading={loading && editDetails === 'aboutCompany'}
                      disabled={loading && editDetails === 'aboutCompany'}
                    >
                      Save
                    </StyledButton>
                    <StyledButton
                      variant={'white'}
                      className="fw-bold h-75"
                      onClick={() => closeForms()}
                    >
                      Cancel
                    </StyledButton>
                  </div>
                )}
              </div>
            </div>
          </Row>
          {editDetails === 'aboutCompany' ? (
            <FormikTextEditor
              className="mt-3"
              name="aboutCompany"
              borderRadiusEditor={'0 0 8px 8px'}
              id="aboutCompany"
              placeholder="About company..."
            />
          ) : (
            <span>
              {renderHtml(compyInfo?.companyInformation?.aboutCompany)}
            </span>
          )}
        </Wrap>
      </PortletBody>

      <PortletBody className="w-100 mt-4">
        <Wrap className="">
          <Row className="">
            <div className="col-12 col-md-12">
              <div className="d-flex justify-content-between">
                <h5>Company Benefits</h5>
                {editDetails !== 'benefits' ? (
                  <StyledButton
                    variant={''}
                    className="me-1"
                    icon={<EditIcon size={20} />}
                    onClick={() => setEditDetails('benefits')}
                  />
                ) : (
                  <div className="d-flex">
                    <StyledButton
                      variant={'white'}
                      className="me-1  fw-bold h-75"
                      onClick={() => {
                        handleSubmit();
                        closeForms();
                      }}
                      loading={loading && editDetails === 'benefits'}
                      disabled={loading && editDetails === 'benefits'}
                    >
                      Save
                    </StyledButton>
                    <StyledButton
                      variant={'white'}
                      className="fw-bold h-75"
                      onClick={() => closeForms()}
                    >
                      Cancel
                    </StyledButton>
                  </div>
                )}
              </div>
            </div>
          </Row>
          {editDetails === 'benefits' ? (
            <FormikTextEditor
              className="mt-3"
              name="benefits"
              borderRadiusEditor={'0 0 8px 8px'}
              id="benefits"
              placeholder="Company benefits..."
            />
          ) : (
            <span>{renderHtml(compyInfo?.companyInformation?.benefits)}</span>
          )}
        </Wrap>
      </PortletBody>

      <PortletBody className="w-100 mt-4">
        <Wrap className="">
          <Row className="">
            <div className="col-12 col-md-12">
              <div className="d-flex justify-content-between">
                <h5>DE&I</h5>
                {editDetails !== 'dei' ? (
                  <StyledButton
                    variant={''}
                    className="me-1"
                    icon={<EditIcon size={20} />}
                    onClick={() => setEditDetails('dei')}
                  />
                ) : (
                  <div className="d-flex">
                    <StyledButton
                      variant={'white'}
                      className="me-1  fw-bold h-75"
                      onClick={() => {
                        handleSubmit();
                        closeForms();
                      }}
                      loading={loading && editDetails === 'dei'}
                      disabled={loading && editDetails === 'dei'}
                    >
                      Save
                    </StyledButton>
                    <StyledButton
                      variant={'white'}
                      className="fw-bold h-75"
                      onClick={() => closeForms()}
                    >
                      Cancel
                    </StyledButton>
                  </div>
                )}
              </div>
            </div>
          </Row>
          {editDetails === 'dei' ? (
            <FormikTextEditor
              className="mt-3"
              name="dei"
              borderRadiusEditor={'0 0 8px 8px'}
              id="dei"
              placeholder="DE&I..."
            />
          ) : (
            <span>{renderHtml(compyInfo?.companyInformation?.dei)}</span>
          )}
        </Wrap>
      </PortletBody>
    </>
  );
};
const StyledImageBar = styled.div`
  position: absolute;
  bottom: 0%;
  width: 100%;
`;
const StyledDiv = styled.div`
  height: 380px;
`;

const StyledPofileImg = styled.div`
overflow: hidden;
width: ${(props) => props?.width || '30px'};
margin: 0;
height: ${(props) => props?.height || '30px'};
display: flex;
align-items: center;
justify-content: center;

img {
  max-width: 100%;
  max-height: 100%;
}

`
export default CompanyInfoEditForm;
