import styled from 'styled-components';

export const StyledRadio = styled.span`
  height: ${({ size }) => (size ? size + 'px' : '20px')};
  width: ${({ size }) => (size ? size + 'px' : '20px')};
  border-radius: 50%;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ value, theme }) => (value ? theme.primary : theme.inputBorder)};
  transition: background-color 0.3s ease;
  border: 2px solid
    ${({ value, theme }) => (value ? theme.primary : theme.inputBorder)};
  cursor: pointer;

  .dot {
    width: ${({ dotSize }) => (dotSize ? dotSize + 'px' : '9px')};
    height: ${({ dotSize }) => (dotSize ? dotSize + 'px' : '9px')};
    border-radius: 50%;
    background-color: ${({ theme }) => theme.primary};
  }
`;
