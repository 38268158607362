import React from 'react';
import Table from '../../../app/components/table/Table';


const CopyWritingListingTable = ({ jobs, sortColumn, onSort, tableRef, filterTable }) => {
  return (
    <Table
      data={jobs}
      columns={filterTable}
      sortColumn={sortColumn}
      onSort={onSort}
      tableRef={tableRef}
    />
  );
};


export default CopyWritingListingTable;
