import React from 'react';
import Proptypes from 'prop-types';
import { StyledRadio } from './StyledRadio';

const StyledRadioButton = ({
  value,
  onChange,
  label,
  disabled,
  hasNoLabel,
  className,
  size,
  dotSize
}) => {
  const onChangeRadio = (event) => {
    if (onChange) {
      onChange(event);
    }
  };
  return (
    <>
      <label
        htmlFor={label}
        className={`${className} d-flex align-items-center mb-0 me-2`}
        style={{ cursor: 'pointer' }}
      >
        <div>
          <StyledRadio dotSize={dotSize} value={value} size={size}>
            {value && <span className="dot" />}
          </StyledRadio>
        </div>
        {label && !hasNoLabel && (
          <span className={`ms-2 text-break`}>{label}</span>
        )}
      </label>
      <input
        id={label}
        type="radio"
        style={{ display: 'none' }}
        checked={value}
        disabled={disabled}
        onChange={onChangeRadio}
      />
    </>
  );
};

StyledRadioButton.propTypes = {
  value: Proptypes.bool,
  onChange: Proptypes.func,
  label: Proptypes.string,
};

export default StyledRadioButton;
