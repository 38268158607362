import { apiSlice } from '../../app/services/apiSlice';

const COMPANY_URL = 'backoffice/companies';
const JOB_URL = '/backoffice/jobs/';

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllJobs: builder.query({
      query: (data) => ({
        url: `${COMPANY_URL}/${data?.id}/jobs`,
        params: data.params,
      }),
    }),
    getAllJob: builder.query({
      query: (data) => ({
        url: `${JOB_URL}list`,
        params: data,
      }),
    }),
    getJobInfo: builder.query({
      query: (data) => ({
        url: `${COMPANY_URL}/${data?.compId}/jobs/${data.jobId}`,
      }),
    }),

    manualAdvertise: builder.mutation({
      query: (id) => ({
        url: `${JOB_URL}/${id}/advertise/manual`,
      }),
    }),

    markAdvertised: builder.mutation({
      query: (id) => ({
        url: `${JOB_URL}/${id}/mark-advertised`,
      }),
    }),

    cancelAdvertise: builder.mutation({
      query: (id) => ({
        url: `${JOB_URL}/${id}/cancel`,
      }),
    }),
    changeStatus: builder.mutation({
      query: (data) => ({
        url: `${JOB_URL}${data.jobId}/change-copywrite-status`,
        method: 'PUT',
        body: {
          ...data,
        },
      }),
    }),
    deleteJob: builder.mutation({
      query: (params) => ({
        url: `${COMPANY_URL}/${params.companyId}/jobs/${params.jobId}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useGetAllJobsQuery,
  useGetAllJobQuery,
  useGetJobInfoQuery,
  useManualAdvertiseMutation,
  useMarkAdvertisedMutation,
  useCancelAdvertiseMutation,
  useChangeStatusMutation,
  useDeleteJobMutation
} = extendedApiSlice;
