import React from 'react';
import FormikSelect from '../../../../app/components/formikComponents/FormikSelect';
import { employmentTypes, jobPositions } from './jobCreationUtil';
import { copywriteStatus } from '../../../Products/assets/utills';
import FormikAsyncSelectMulti from '../../../../app/components/formikComponents/FormikAsyncSelectMulti';
const JobFilterForm = ({
  setFieldValue,
  submitForm,
  loading,
  onhandleChange,
  values,
  setFilters,
}) => {
  let companyIdPayload = {
    companyIds: values?.companyIds?.map((item) => item?.value),
  };

  return (
    <>
      <div className="col-12">
        <div className="mb-4">
          <label htmlFor="jobIds" className="form-label">
            Job Title
          </label>
          <FormikAsyncSelectMulti
            name="jobIds"
            id="jobIds"
            type="jobs"
            params={{...companyIdPayload}}
            isMulti
            placeholder="Select Jobs"
            onSelect={(values) => onhandleChange('jobIds', values)}
          />
        </div>
      </div>
      <div className="col-12">
        <div className="mb-4">
          <label htmlFor="categoryIds" className="form-label">
            Job Post Category
          </label>
          <FormikAsyncSelectMulti
            name="categoryIds"
            id="categoryIds"
            //apiCall={() => getCompanyJobCategories(companyIdPayload)}
            params={{...companyIdPayload}}
            type="jobCategories"
            placeholder="Choose Category"
            onSelect={(values) => onhandleChange('categoryIds', values)}
          />
        </div>
      </div>

      <div className="col-12">
        <div className="mb-4">
          <label htmlFor="departmentIds" className="form-label">
            Department
          </label>
          <FormikAsyncSelectMulti
            name="departmentIds"
            id="departmentIds"
           // apiCall={() => getCompanyDepartment(companyIdPayload)}
           params={{...companyIdPayload}}
            type="departments"
            isMulti
            placeholder="Select departments"
            onSelect={(values) => onhandleChange('departmentIds', values)}
          />
        </div>
      </div>

      <div className="col-12">
        <div className="mb-4">
          <label htmlFor="level" className="form-label">
            Job Level
          </label>
          <FormikSelect
            name="jobLevel"
            id="jobLevel"
            placeholder="Select Level"
            options={jobPositions}
            // isMulti={true}
            onChange={(value) =>
              onhandleChange('jobLevel', value?.value ?? value?.label)
            }
          />
        </div>
      </div>

      <div className="col-12">
        <div className="mb-4">
          <label htmlFor="employmentType" className="form-label">
            Employment Type
          </label>
          <FormikSelect
            name="employmentType"
            id="employmentType"
            placeholder="Select Employment Type"
            options={employmentTypes}
            onChange={(value) =>
              onhandleChange('employmentType', value?.value ?? value?.label)
            }
          />
        </div>
      </div>

      <div className="col-12">
        <div className="mb-4">
          <label htmlFor="copywriteStatus" className="form-label">
            Copywrite Status
          </label>
          <FormikSelect
            name="copywriteStatus"
            id="copywriteStatus"
            placeholder="Select  Copywrite Status"
            options={copywriteStatus}
            onChange={(value) =>
              onhandleChange('copywriteStatus', value?.value ?? value?.label)
            }
          />
        </div>
      </div>
    </>
  );
};

export default JobFilterForm;
