import { createSlice } from '@reduxjs/toolkit';
import { occyLs } from '../../utils/localStorage';
import { apiSlice } from '../../app/services/apiSlice';

const lsToken = occyLs.getObject('token');
const lsAccount = occyLs.getObject('account');
const ADMIN_URL = 'backoffice/';

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getOutlookUrl: builder.mutation({
      query: () => ({ 
        url: `${ADMIN_URL}auth/signin`,
      })
    }),
    loginWithOutlook: builder.query({
      query: (params) => ({ 
        url: `${ADMIN_URL}auth/callback`,
        params 
      })
    }),
  }),
});

const slice = createSlice({
  name: 'auth',
  initialState: { token: lsToken, account: lsAccount },
  reducers: {
    setCredentials: (
      state,
      {
        payload: {
          token: {
            accessToken,
            sessionId
          },
          account
          
        },
      },
    ) => {
      state.token = accessToken;
       state.account = account;
      occyLs.setObject('token', accessToken);
      occyLs.setObject('sessionId', sessionId);
       occyLs.setObject('account',account);
    },
    removeCredentials: (state, action) => {
      state.token = undefined;
       state.account = undefined;
      occyLs.removeItem('token');
      occyLs.removeItem('sessionId');
      occyLs.removeItem('account');
    },
    sideMenuState: (state, { payload }) => {
      occyLs.setObject('APP_SIDEBAR', payload);
    },
    removeSideMenuState: (state, { payload }) => {
      occyLs.removeItem('APP_SIDEBAR');
    }
  },
  
});

export const { setCredentials, removeCredentials, updateToken, updateProfileState, sideMenuState , removeSideMenuState } = slice.actions;

export default slice.reducer;

export const selectCurrentUser = (state) => {
  return state.auth.account;
}

export const getSideMenuState = (state) => {
  return state.auth.sideMenu;
};

export const { useClaimGrantQuery, useGetOutlookUrlMutation,
  useLoginWithOutlookQuery } = extendedApiSlice;
