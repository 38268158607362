/* eslint-disable no-mixed-operators */
import React, { useEffect } from 'react';
import useAuth from '../../hooks/useAuth';
import StyledButton from '../../app/components/styledComponents/StyledButton';
import './assets/styles/auth.scss';
import AuthMicrosoftIcon from './svgIcons/AuthMicrosoftIcon';
import { useGetOutlookUrlMutation } from './authSlice';
import { toast } from 'react-toastify';
import { Spinner } from 'react-bootstrap';
import OccyLogoBlue from '../../app/components/svgIcons/OccyLogoBlue.png';

export const Login = () => {
  const { user } = useAuth();
  const [data, { isLoading }] = useGetOutlookUrlMutation();
  useEffect(() => {
    if (user) {
      window.location.href = window.location.href + 'companies'
    }
  });
  const getUrlForOutlookLogin = () => {
    data()
      .unwrap()
      .then((res) => {
        window.open(res.message, "_blank", "location=1,status=1,scrollbars=1,width=700,height=700");
        window.onunload = function (rsp) {
          if (!rsp || (rsp && rsp?.error || rsp?.data?.message)) {
            toast.error(rsp?.error || rsp?.data?.message || 'Failed to Login');
          } else if (rsp && rsp?.jwtToken?.token) {
            window.location.reload(false)
            setInterval(() => {
              if (user) {
                window.location.href = window.location.href + 'companies'
              }
            }, 1000)

          }
        };
      })

  }
  return (

    <div className='row vh-100'>
      <div className='d-flex justify-content-center align-items-center '>
        <div className='bg-white border justify-content-center rounded p-5 w-25'>
          <div className='d-flex justify-content-center flex-column align-items-center'>
            {/* <OccyLogoWhite size={130} color={theme.primary}/> */}
             <img
        src={OccyLogoBlue}
        alt="img"
        height={38}
        width={150}
      />
            <h1 className='mt-4 text-primary'>Occy Admin</h1>
          </div>
          <div className='d-flex justify-content-center align-items-center mt-4'>
            <StyledButton
              onClick={() => getUrlForOutlookLogin()}
            >
              <AuthMicrosoftIcon /><span className='px-2'> Sign In with Microsoft {isLoading && <Spinner animation="border" variant="light" className='ms-2' size="sm" />}</span>
            </StyledButton>
          </div>

        </div>


      </div>
    </div>
  );
};
