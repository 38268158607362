import React from 'react';
import FormikSelect from '../../../../app/components/formikComponents/FormikSelect';
import FormikAsyncSelectMulti from '../../../../app/components/formikComponents/FormikAsyncSelectMulti';

const JobFilterForm = ({
  setFieldValue,
  submitForm,
  loading,
  onhandleChange,
  values,
  setFilters,
}) => {
  let companyIdPayload = {
    companyIds: values?.companyIds?.map((item) => item?.value),
  };
  const compSize = [
    {
      label: '1-10',
      value: '1-10',
    },
    {
      label: '11-50',
      value: '11-50',
    },
    {
      label: '51-200',
      value: '51-200',
    },
    {
      label: '201-500',
      value: '201-500',
    },
    {
      label: '501-1000',
      value: '501-1000',
    },
    {
      label: '1001-5000',
      value: '1001-5000',
    },
    {
      label: '5001-10000',
      value: '5001-10000',
    },
    {
      label: '10000+',
      value: '10000+',
    }
  ];
  return (
    <>
      {companyIdPayload?.companyIds?.length > 0 && <><div className="col-12">
        <div className="mb-4">
          <label htmlFor="companySize" className="form-label">
            Company Size
          </label>
          <FormikSelect
                  name="companySize"
                  id="companySize"
                  placeholder="Select Company Size"
                  options={compSize}
                  onChange={(values) => onhandleChange('companySize', typeof values?.value === 'undefined'
            ? values?.label
            : values.value,)}
                />
        </div>
      </div><div className="col-12">
          <div className="mb-4">
            <label htmlFor="industry" className="form-label">
              Industry
            </label>
            <FormikAsyncSelectMulti
              name="industryIds"
              id="industryIds"
              //apiCall={() => getCompanyJobCategories(companyIdPayload)}
              params={{ ...companyIdPayload }}
              type="jobCategories"
              placeholder="Choose Category"
              onSelect={(values) => onhandleChange('industryIds', values)} />
          </div>
        </div></>}
 {/*
      <div className="col-12">
        <div className="mb-4">
          <label htmlFor="departmentIds" className="form-label">
            Department
          </label>
          <FormikAsyncSelectMulti
            name="departmentIds"
            id="departmentIds"
           // apiCall={() => getCompanyDepartment(companyIdPayload)}
           params={{...companyIdPayload}}
            type="departments"
            isMulti
            placeholder="Select departments"
            onSelect={(values) => onhandleChange('departmentIds', values)}
          />
        </div>
      </div>

      <div className="col-12">
        <div className="mb-4">
          <label htmlFor="level" className="form-label">
            Job Level
          </label>
          <FormikSelect
            name="jobLevel"
            id="jobLevel"
            placeholder="Select Level"
            options={jobPositions}
            // isMulti={true}
            onChange={(value) =>
              onhandleChange('jobLevel', value?.value ?? value?.label)
            }
          />
        </div>
      </div>

      <div className="col-12">
        <div className="mb-4">
          <label htmlFor="employmentType" className="form-label">
            Employment Type
          </label>
          <FormikSelect
            name="employmentType"
            id="employmentType"
            placeholder="Select Employment Type"
            options={employmentTypes}
            onChange={(value) =>
              onhandleChange('employmentType', value?.value ?? value?.label)
            }
          />
        </div>
      </div>

      <div className="col-12">
        <div className="mb-4">
          <label htmlFor="copywriteStatus" className="form-label">
            Copywrite Status
          </label>
          <FormikSelect
            name="copywriteStatus"
            id="copywriteStatus"
            placeholder="Select  Copywrite Status"
            options={copywriteStatus}
            onChange={(value) =>
              onhandleChange('copywriteStatus', value?.value ?? value?.label)
            }
          />
        </div>
      </div> */}
    </>
  );
};

export default JobFilterForm;
