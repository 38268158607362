export const routesWithMeta = [
  {
    path: '/',
    meta: {
      title: 'Dashboard',
      description: 'Occy Admin...!',
    },
  },
  // {
  //   path: '/dashboard',
  //   meta: {
  //     title: 'Dashboard',
  //     description:
  //       'Dashboard live, Find job openings, get hired, and stay ahead of the competition with our easy-to-use job search dashboard.',
  //     // img: 'https://i.imgur.com/V7irMl8.png'
  //   },
  // },
  {
    path: '/companies',
    meta: {
      title: 'Companies',
      description:
        'Looking for a company to work for? Check out our top picks for 2019. These companies offer great jobs, products, and user settings. ',
    },
  },
  // {
  //   path: '/job-posts',
  //   meta: {
  //     title: 'Job Posts',
  //     description:
  //       'List of Jobs Posting, Advertising jobs, Find the best job postings, advertising, products, and channels to help you succeed in your career. Our resources provide the information and tools you need to make the most of your career journey.',
  //   },
  // },
  {
    path: '/products',
    meta: {
      title: 'Products',
      description:
        'List of Products, A JobBoard and Channels platform, with a range of products to help you find the right job and make the most of your career. Connecting you with employers, recruiters and employers to find the perfect job for you and help you build your career.',
    },
  },
  {
    path: '/licences',
    meta: {
      title: 'Licences',
      description:
        'List of Licences, Our company licences provide payment options for any duration you need, with user accounts and access for all. Get the best solution for your business today!',
    },
  },
  {
    path: '/settings',
    meta: {
      title: 'Settings',
      description:
        'List of Settings, This page provides an overview of the settings, features, access, users, and accounts associated with a company. Get information on how to manage users and accounts, access features, and adjust settings to suit your business needs.',
    },
  },
];
