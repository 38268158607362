import React, { useEffect, useState } from "react";
import CopyWritingEditDetails from "./CopyWritingEditDetails";
import { Form, Formik } from "formik";
import { basicCopywriteValidation } from "../assets/utils";
import * as Yup from 'yup';
import { useNavigate, useParams } from "react-router-dom";
import { useGetCopywriteEditDetailsQuery, useUpdateCopywriteRequestMutation, useUpdateCopywriteRevisionRequestMutation } from "../assets/copyWriteApiSlice";
import { toast } from "react-toastify";

const CopyWritingDetailsContainer = () => {
    const { jobId } = useParams();
    const navigate = useNavigate();
    const { data: copywriteDetails, refetch } =
        useGetCopywriteEditDetailsQuery(jobId, { skip: !jobId });
    const [updateLoading, setUpdateLoading] = useState(false);
    const [reviewLoading, setReviewLoading] = useState(false);

    const [copywriteUpdate] = useUpdateCopywriteRequestMutation();
    const [copywriteReview] = useUpdateCopywriteRevisionRequestMutation();

    const handleSubmit = (values) => {
        values['jobId'] = jobId;
        values['isDraft'] = values.buttonType === "Draft";
        if (!values.isDraft) {
            delete values.isDraft;
        }
        if (copywriteDetails?.copyWriteReqDetails[copywriteDetails?.copyWriteReqDetails?.length - 1]?.job?.copywriteStatus === 'IN_PROGRESS') {
            values['copyWriteId'] = copywriteDetails?.copyWriteReqDetails[copywriteDetails?.copyWriteReqDetails?.length - 1]?.id;
        }
        if (values.buttonType === "Draft") {
            delete values.buttonType;
            setUpdateLoading(true);
            copywriteReview(values)
                .unwrap()
                .then((res) => {
                    toast.success(res?.message);
                    refetch();
                    navigate(`/copywriting`);
                })
                .finally(() => {
                    setUpdateLoading(false);
                });
        } else {
            delete values.buttonType;
            setReviewLoading(true);
            copywriteUpdate(values)
                .unwrap()
                .then((res) => {
                    toast.success(res?.message);
                    refetch();
                    navigate(`/copywriting`);
                })
                .finally(() => {
                    setReviewLoading(false);
                });
        }
    };
    useEffect(() => {
        refetch();
    }, []);
    return (
        copywriteDetails?.copyWriteReqDetails &&
        <>
            <Formik
                initialValues={{
                    elevatorPitch: copywriteDetails?.copyWriteReqDetails?.[copywriteDetails?.copyWriteReqDetails?.length - 1]?.elevatorPitch || '',
                    jobDescription: copywriteDetails?.copyWriteReqDetails?.[copywriteDetails?.copyWriteReqDetails?.length - 1]?.jobDescription || '',
                    jobRequirement: copywriteDetails?.copyWriteReqDetails?.[copywriteDetails?.copyWriteReqDetails?.length - 1]?.jobRequirement || '',
                    suggestions: copywriteDetails?.copyWriteReqDetails?.[copywriteDetails?.copyWriteReqDetails?.length - 1]?.suggestions || '',
                    buttonType: '',
                    totalCharacters: '',
                }}
                validationSchema={Yup.object().shape({
                    ...basicCopywriteValidation
                })}
                onSubmit={handleSubmit}
            >
                {({ handleSubmit, values, setValues }) => (
                    <Form onSubmit={handleSubmit} >
                        <CopyWritingEditDetails
                            copywriteDetails={copywriteDetails}
                            updateLoading={updateLoading}
                            reviewLoading={reviewLoading}
                            values={values}
                            setValues={setValues}
                        />

                    </Form>
                )}
            </Formik>
        </>


    );

}

export default CopyWritingDetailsContainer;