import React from 'react';
import styled from 'styled-components';

const TabsSidebar = ({ sidebar, activeTab, setActiveTab, handleSubmit, values ,jobBoardsSummaryDetails, isBroadBeanProduct, isLoading }) => {
  const changeTab = (tabIndex, number, to) => {
    if(!jobBoardsSummaryDetails){
     return
    }else{
      if ( Array.isArray(jobBoardsSummaryDetails) && jobBoardsSummaryDetails?.length === 0) {
        if((values?.showMonsterEditFormKey || values?.reedKey || values?.cvlibraryKey || values?.totalJobKey))
        {
           handleSubmit(values);
        }
        else if (isBroadBeanProduct) setActiveTab(tabIndex);
      } else {
        setActiveTab(tabIndex);
      }
    }
  };

  return (
    <StyledSideBar className={' bg-white mt-1'}>
      <div className="__sidebar p-2 p-lg-4">
        {sidebar.map(
          ({ number, title, icon, classBar, linkDisabled, ...item }, i) => (
            <li
              key={i}
              className="rounded-1 overflow-hidden mb-1 cursor-pointer "
            >
              <span
                onClick={() => !isLoading && changeTab(i, number, item.to)}
                className={`nav-link single-item flex-center ps-2 p-3 ${classBar} ${
                  i === activeTab ? 'active' : ''
                }
                } `}
                id={title.toLowerCase().replace(/\s/g, '-')}
                {...item}
              >
                {icon}
                <h6 className="text-nowrap mb-0 fw-bold">{title}</h6>
              </span>
            </li>
          ),
        )}
      </div>
    </StyledSideBar>
  );
};
const StyledSideBar = styled.div`
  min-width: 220px;
  position: relative;

  .__sidebar {
    position: sticky;
    top: 12vh;
    height: 83vh;
  }

  li {
    list-style-type: none;
  }

  .single-item {
    //border-radius: 5px;
    &.not-allowed {
      cursor: not-allowed;
    }

    &.active {
      background-color: ${({ theme }) => theme.primaryLight};
      border-left: 3px solid ${({ theme }) => theme.primary};
    }
  }
`;
export default TabsSidebar;
