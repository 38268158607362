import React from 'react';

const CommentDesignIcon = ({ size = 16, ...props }) => (
  <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M5.66536 12.6666H5.33203C2.66536 12.6666 1.33203 12 1.33203 8.66665V5.33331C1.33203 2.66665 2.66536 1.33331 5.33203 1.33331H10.6654C13.332 1.33331 14.6654 2.66665 14.6654 5.33331V8.66665C14.6654 11.3333 13.332 12.6666 10.6654 12.6666H10.332C10.1254 12.6666 9.92536 12.7666 9.7987 12.9333L8.7987 14.2666C8.3587 14.8533 7.6387 14.8533 7.1987 14.2666L6.1987 12.9333C6.09203 12.7866 5.84536 12.6666 5.66536 12.6666Z" stroke="#001F5C" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M4.66797 5.33331H11.3346" stroke="#001F5C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M4.66797 8.66669H8.66797" stroke="#001F5C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>

);

export default CommentDesignIcon;
