import React from 'react';

const SelfGuidedIcon = ({ size = 18, color = "#000000", ...props }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.63208" width="24" height="24" rx="12" fill="#F5F5F5"/>
    <path d="M15.0763 6.16666H10.188C8.06466 6.16666 6.79883 7.43249 6.79883 9.55582V14.4442C6.79883 16.5675 8.06466 17.8333 10.188 17.8333H15.0763C17.1997 17.8333 18.4655 16.5675 18.4655 14.4442V9.55582C18.4655 7.43249 17.1997 6.16666 15.0763 6.16666ZM11.448 13.6917L10.1355 15.0042C10.048 15.0917 9.93716 15.1325 9.82633 15.1325C9.71549 15.1325 9.59883 15.0917 9.51716 15.0042L9.07966 14.5667C8.90466 14.3975 8.90466 14.1175 9.07966 13.9483C9.24883 13.7792 9.52299 13.7792 9.69799 13.9483L9.82633 14.0767L10.8297 13.0733C10.9988 12.9042 11.273 12.9042 11.448 13.0733C11.6172 13.2425 11.6172 13.5225 11.448 13.6917ZM11.448 9.60832L10.1355 10.9208C10.048 11.0083 9.93716 11.0492 9.82633 11.0492C9.71549 11.0492 9.59883 11.0083 9.51716 10.9208L9.07966 10.4833C8.90466 10.3142 8.90466 10.0342 9.07966 9.86499C9.24883 9.69582 9.52299 9.69582 9.69799 9.86499L9.82633 9.99332L10.8297 8.98999C10.9988 8.82082 11.273 8.82082 11.448 8.98999C11.6172 9.15916 11.6172 9.43916 11.448 9.60832ZM15.8755 14.695H12.813C12.5738 14.695 12.3755 14.4967 12.3755 14.2575C12.3755 14.0183 12.5738 13.82 12.813 13.82H15.8755C16.1205 13.82 16.313 14.0183 16.313 14.2575C16.313 14.4967 16.1205 14.695 15.8755 14.695ZM15.8755 10.6117H12.813C12.5738 10.6117 12.3755 10.4133 12.3755 10.1742C12.3755 9.93499 12.5738 9.73666 12.813 9.73666H15.8755C16.1205 9.73666 16.313 9.93499 16.313 10.1742C16.313 10.4133 16.1205 10.6117 15.8755 10.6117Z" fill="#767476"/>
    </svg>
    
    

  );
};

export default SelfGuidedIcon;
