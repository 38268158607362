import React, { useEffect, useRef, useState } from 'react';
import Portlet from '../../../app/components/portlet/Portlet';
import PortletHeader from '../../../app/components/portlet/PortletHeader';
import PortletBody from '../../../app/components/portlet/PortletBody';
import StyledIconInput from '../../../app/components/styledComponents/StyledIconInput';
import SearchIcon from '../../../app/components/svgIcons/SearchIcon';
import FilterIcon from '../../../app/components/svgIcons/FilterIcon';
import StyledFilterTabs from '../../../app/components/styledComponents/StyledFilterTabs';
import theme from '../../../utils/themes';
import styled from 'styled-components';
import { StyledLabel } from '../../../assets/styledComponents/Global';
import TickCircleIcon from '../../../app/components/svgIcons/TickCircleIcon';
import ComapnyIcon from '../../../app/components/svgIcons/CompanyIcon';
import StyledButton from '../../../app/components/styledComponents/StyledButton';
import OnBoardingDetailsModal from '../OnBoardingDetailsModal';
import CrossIcon from '../../../app/components/svgIcons/CrossIcon';
import FormikSelect from '../../../app/components/formikComponents/FormikSelect';
import { Form, Formik } from 'formik';
import FormikDatePicker from '../../../app/components/formikComponents/FormikDatePicker';
import DateIcon from '../../../app/components/svgIcons/DateIcon';
import DesignIcon from '../../../app/components/svgIcons/DesignIcon';
import { RenderHTMLString } from '../../../utils/helper';
import { useGetOnBoardingListingQuery, useUpdateOnboardingHandOffMutation } from '../../company/companyApiSlice';
import moment from 'moment';
import OnboardSkeleton from './OnboardSkeleton';
import { toast } from 'react-toastify';

const OnBoardingListingContainer = () => {
  const [handoff] = useUpdateOnboardingHandOffMutation();
  const [viewDetails, setViewDetails] = useState(false);
  const initialFilters = {
    perPage: 500,
    page: 1,
    query: '',
  };
  const [listing, setListing] = useState({ ...initialFilters });

  const { data: settingsRes, refetch, isFetching } = useGetOnBoardingListingQuery({
    params: {
      perPage: listing.perPage,
      page: listing.page,
      query: listing.query && listing.query,
      // ...filters,
    },
  });

  const [timer, setSearchTimer] = useState(null);
  const handleSearch = (evt) => {
    clearTimeout(timer);
    const query = evt.target.value;
    const newTimer = setTimeout(() => {
      setListing({ ...listing, page: 1, query: query });
    }, 2000);
    setSearchTimer(newTimer);
  };
  const [isOpen, setIsopen] = useState(false);
  let ToggleFilterbar = (item) => {
    setIsopen(item === 'filter' ? !isOpen : false);
  };
  const filterRef = useRef(null);
  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setIsopen(false);
        }
      }

      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }

  useOutsideAlerter(
    (isOpen && filterRef)
  );

  const handleSubmit = (values) => {
  }

  // const [appliedValues, setApplyedValues] = useState({});


  const arr = [
    { id: 1, icon: 'ContractSigned', date: '4 May 2023', title: 'Contract Signed', completed: true, flag: 'contractSigned' },
    { id: 2, icon: 'CompanyAdded', date: '4 May 2023', title: 'Company Added', completed: true, flag: 'companyAdded' },
    { id: 3, icon: 'AdminSignUp', date: '4 May 2023', title: 'Admin Sign-up', completed: true, flag: 'adminAccountSignUpAt' },
    { id: 4, icon: 'SelfGuidedStarted', date: '4 May 2023', title: 'Self Guided Setup Started', completed: false, flag: 'selfGuidedSetupStarted' },
    { id: 5, icon: 'UsersAdded', date: '4 May 2023', title: 'Users Added', completed: false, flag: 'firstUserAdded' },
    { id: 6, icon: 'SelfGuidedComplete', date: '4 May 2023', title: 'Self Guided Setup Complete', completed: false, flag: 'selfGuidedSetupCompleted' },
    { id: 7, icon: 'FirstJobCreated', date: '4 May 2023', title: 'First Job Created', flag: 'firstJobCreatedAt', completed: false },
    { id: 8, icon: 'FirstJobPublished', date: '4 May 2023', title: 'First Job Published', completed: false, flag: 'firstJobPublished' },
    { id: 9, icon: 'FirstApplicant', date: '4 May 2023', title: 'First Applicant', completed: false, flag: 'firstAppCreatedAt' },
    { id: 10, icon: 'FirstHire', date: '4 May 2023', title: 'First Hire', completed: false, flag: 'firstHire' }
  ]

  const [companyId, setCompanyId] = useState();

  const getName = (name, flag) => {
    return name[flag];
  }

  const getIcon = (comp) => {
    const foundObject = settingsRes?.onBoardingDetails?.length > 0 && settingsRes?.onBoardingDetails?.find((item) => item.companyId === comp?.companyId);
    if (foundObject) {
      // Create a new object without the specified key
      const modifiedObject = { ...foundObject };
      delete modifiedObject['industry'];
      delete modifiedObject['licenceStartDate'];
      const allKeysHaveLengthGreaterThanZero = Object.values(modifiedObject).every(
        (value) => typeof value === "string" && value.length > 0
      );

      if (allKeysHaveLengthGreaterThanZero) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }

  const getTime = (dateString) => {
    if (dateString?.length) {
      const currentDate = new Date();
      const inputDate = new Date(dateString);
      const timeDifference = currentDate - inputDate;
      const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
      const monthsDifference = Math.floor(daysDifference / 30);
      const yearsDifference = Math.floor(monthsDifference / 12);

      if (yearsDifference >= 1) {
        return `${yearsDifference} year${yearsDifference > 1 ? 's' : ''} ago`;
      } else if (monthsDifference >= 1) {
        return `${monthsDifference} month${monthsDifference > 1 ? 's' : ''} ago`;
      } else if (daysDifference >= 1) {
        return `${daysDifference} day${daysDifference > 1 ? 's' : ''} ago`;
      } else {
        return "today";
      }
    }
    else {
      return "Pending";
    }
  }
  // const handleScroll = (event) => {
  //   const { scrollTop, scrollHeight, clientHeight } = event.target;
  //   const isAtBottom = scrollTop + clientHeight === scrollHeight;

  //   if (isAtBottom) setListing({ ...listing, page: listing.page + 1 });
  //   // if (!isAtBottom) setListing({ ...listing, page: listing.page - 1 });
  // };

  const handOffToCS = (compId) => {
    handoff({ id: compId }).unwrap().then((res) => {
      toast.success(res?.message || 'Hands-Off to CS Successfully');
      refetch();
    })
  }
  return (
    <div className='onboard'>
      <Portlet>
        <PortletHeader
          title={<StyledLabel weight={'600'} size={'24px'}>Onboarding</StyledLabel>}
          subTitle={''}
          titleDetails={
            <div className='mb-3'>Track the status of companies in the onboarding process
            </div>
          }
          actions={<></>}
        />
        <div className={`bg-body p-2`}>
          <PortletBody className="mx-2 border">
            <>
              <div className="flex-center mb-3">
                <div className="flex-grow-1 pb-1">
                  <StyledIconInput
                    icon={<SearchIcon />}
                    className="mw-50"
                    $maxWidth="60%"
                    $marginLeft="3px"
                    onChange={handleSearch}
                    // loading={isFetching}
                    placeholder="Search Clients"
                  />
                </div>
                <div className="d-flex">
                  <div className="flex-center me-3">
                    <StyledFilterTabs padding="4px">
                      <div className="btn-group d-none" role="group">
                        <button
                          className="btn"
                          onClick={() => ToggleFilterbar('filter')}
                        >
                          <FilterIcon size={14} />
                          <small className="ms-1">Filter</small>
                        </button>
                      </div>
                    </StyledFilterTabs>
                  </div>
                </div>
                <div ref={filterRef} className="bg-transparent mt-2">
                  {isOpen && (
                    <StyledFilterBar width="400px" variant="filter">
                      <div className="d-flex justify-content-end">
                        <div
                          className="cursor-pointer mt-3 me-3"
                          onClick={() => setIsopen(false)}
                        >
                          <CrossIcon />
                        </div>
                      </div>
                      <div className="sd-header">
                        <h4 className="mb-0">Filters</h4>
                        {/* {!isEqual(companyFilters, initialFilters) && (
                          <h6
                            className="mb-0 cursor-pointer"
                            onClick={() => {
                              setApplyedValues({});
                              setCompanyFilters((prevFilters) => ({
                                sort: prevFilters?.sort ?? 'createdAt',
                                sortDir: prevFilters?.sortDir ?? 'DESC',
                              }));
                              setFilters(false);
                            }}
                          >
                            Clear All
                          </h6>
                        )} */}
                      </div>

                      <div className="sd-body">
                        <Formik
                          onSubmit={handleSubmit}
                          initialValues={{
                            // ...appliedValues,
                          }}
                        >
                          {({ handleSubmit, values, submitForm, errors }) => (
                            <Form onSubmit={handleSubmit}>
                              <div className="col-12">
                                <div className="mb-4">
                                  <label
                                    htmlFor="startDate"
                                    className="form-label"
                                  >
                                    Licence Start
                                  </label>
                                  <FormikDatePicker name="startDate" />
                                </div>
                              </div>
                              <div className="col-12">
                                <div className="mb-4">
                                  <label
                                    htmlFor="status"
                                    className="form-label"
                                  >
                                    Status
                                  </label>
                                  <FormikSelect
                                    name="status"
                                    id="status"
                                    options={[
                                      {
                                        label: 'Deleted',
                                        value: 1,
                                      },
                                      { label: 'Live', value: 2 },
                                      {
                                        label: 'Pending',
                                        value: 3,
                                      },
                                      {
                                        label: 'Suspended',
                                        value: 4,
                                      },
                                    ]}
                                    placeholder="Choose Status"
                                  />
                                </div>
                              </div>
                              <StyledBtn>
                                <StyledButton
                                  type="button"
                                  onClick={handleSubmit}
                                  className="ms-auto w-100"
                                // loading={loading}
                                // disabled={loading}
                                >
                                  Apply Filters
                                </StyledButton>
                              </StyledBtn>
                            </Form>
                          )}
                        </Formik>
                      </div>

                    </StyledFilterBar>
                  )}
                </div>

              </div>
              <StyledBox className='overflow h-auto' >
                {isFetching && <OnboardSkeleton />}
                {!isFetching && settingsRes?.onBoardingDetails?.map((item, index) => (
                  <div className="border border-muted rounded-3 mt-3 pb-3" key={`child-compaies-${index}`}>
                    <div className="d-flex justify-content-between align-items-center p-2 p-lg-3 template-item bg-white rounded mt-1">
                      <StyledMain className="text-start d-flex">
                        <div
                          className={`d-flex align-items-start rounded me-2 fw-bold fs-3 text-white mt-1`}
                        >
                          <ComapnyIcon size={35} />
                        </div>
                        <div className="col-12 fw-bold">
                          <div className="d-flex align-items-start">
                            <StyledLabel className="mb-1" weight='600' size='16px' color="#0A0509">{item?.companyName}</StyledLabel>

                          </div>

                          <Styledflex className="col-12">
                            <>
                              <StyledLabel
                                weight="500"
                                size="12px"
                                family="Quicksand,sans-serif"
                                className="m-0 text-muted cursor-pointer"
                              >
                                <DateIcon size={14} />
                                <span className='ms-1'>{moment(item?.companyAdded).format("Do MMM YYYY")}</span>
                              </StyledLabel>
                            </>
                            <StyledDot className="dot mx-2" dotSize={5} />

                            <>
                              <StyledLabel
                                weight="500"
                                size="12px"
                                family="Quicksand,sans-serif"
                                className="m-0 text-muted cursor-pointer"
                              >
                                <DesignIcon size={14} />
                                <span className='ms-1'>{item?.industry}</span>
                              </StyledLabel>
                            </>
                            <StyledDot className="dot mx-2" dotSize={5} />
                            <StyledLabel
                              weight="500"
                              family="Quicksand,sans-serif"
                              size="12px"
                              className="m-0 text-muted"
                            >

                              {getTime(item?.contractSigned)}

                            </StyledLabel>
                          </Styledflex>
                        </div>
                      </StyledMain>
                      <div className="d-flex align-items-center">
                        {getIcon(item) && <StyledButton
                          variant={'white'}
                          className="me-1 fw-bold py-0"
                          onClick={() => handOffToCS(item?.companyId)}
                        >
                          Handoff to CS
                        </StyledButton>}
                        <StyledButton
                          variant={'white'}
                          className="me-1 fw-600 h-50 border-0 bg-transparent text-primary"
                          onClick={() => {
                            setViewDetails(true)
                            setCompanyId(item)
                          }}
                        >
                          View Details
                        </StyledButton>
                      </div>
                    </div>
                    <StyledProgress className='d-flex justify-content-center'>
                      <table className="progressbar ">
                        <tr className="counter">
                          {arr?.map((it, index) => (
                            <td className={`${getName(item, arr[index + 1]?.flag)?.length ? 'active' : ''}`} key={`counter-td-${index}`}>
                              <div>{getName(item, it?.flag)?.length ? <TickCircleIcon size={14} color={theme.paleGreen} /> : RenderHTMLString(it?.icon, 14)}</div>
                              {arr?.length - 1 !== index && <span ></span>}
                            </td>
                          ))}
                        </tr>
                        <tr className="descriptions">
                          {arr?.map((ite, tdIndex) => (
                            <td className={`active ${ite?.icon === 'SelfGuidedStarted' || ite?.icon === 'SelfGuidedComplete' ? 'td-text' : ''}`} key={`description-td-${tdIndex}`}>
                              <div className={`d-flex justify-content-center align-content-center flex-column ${ite?.icon === 'SelfGuidedStarted' || ite?.icon === 'SelfGuidedComplete' ? 'td-text' : ''}`}><td className={`${ite?.completed ? 'active' : ''}`}><StyledLabel weight="600" size="8px" color="#585558">{ite?.title}</StyledLabel></td>
                                <td className="active"><StyledLabel weight="500" size="7px" color="#585558">{getName(item, ite?.flag)?.length > 0 ? moment(getName(item, ite?.flag)).format("Do MMM YYYY") : 'Pending'}</StyledLabel></td>
                              </div>
                            </td>
                          ))}
                        </tr>
                      </table>
                    </StyledProgress>
                  </div>
                ))}
              </StyledBox>
            </>
          </PortletBody>
        </div>
      </Portlet>
      <OnBoardingDetailsModal
        show={viewDetails}
        data={arr}
        all={settingsRes}
        companyId={companyId}
        onHide={() => {
          setViewDetails(false);
        }}
        title={'Onboarding Details'}
      >

      </OnBoardingDetailsModal>
    </div>
  );
};
const StyledMain = styled.div`
  height:60px;
`;
const StyledBox = styled.div`
 max-height:1000px;
overflow-y:scroll;
overflow-x:hidden;
`;
const StyledFilterBar = styled.div`
z-index: 9999;
  width: ${(props) => props?.width ?? '229px'};
  // height: ${(props) => (props?.variant ? '91vh !important' : 'none')};
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
  background-color: #fff;
  position: ${(props) => (props?.variant ? 'fixed' : 'absolute')};
  margin-top: ${(props) => (!props?.variant ? '35px' : 'none')};
  top: ${(props) => (props.variant ? '90px' : 'none')};
  right: 0;
  bottom: ${(props) => (props?.variant ? '0' : 'none')};s
  border-radius: ${(props) => (props?.variant ? '8px 0 0 0' : '14px')};
  transition: 0.5s;
  padding-bottom: 28px;

  .sd-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    color: ${({ theme }) => theme.textColor};
  }

  .sd-body {
    padding: 20px;
    height: 100%;
    width: 100%;
    position: relative;
    overflow: auto;
  }

  .sd-body ul {
    display: inline-block;
    width: 100%;
    margin-bottom: 0;
    padding: 0;
  }

  .sd-body ul li {
    list-style: none;
    margin-bottom: 8px;
  }
`;
const StyledBtn = styled.div`
  margin-bottom: 2rem !important;
`;

const StyledDot = styled.div`
display: inline-block;
  width: ${({ dotSize }) => (dotSize ? dotSize + 'px' : '9px')};
  height: ${({ dotSize }) => (dotSize ? dotSize + 'px' : '9px')};
  border-radius: 50%;
  background-color: ${({ theme }) => theme.lightGrey};
`;
const StyledProgress = styled.div`
margin-top: -18px;
`;

const Styledflex = styled.p`
margin-top:-5px;
`;
export default OnBoardingListingContainer;
