import React from 'react';

const InfoIcon = ({ size = 16, color ='#767476', className }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM8 7.2C8.44183 7.2 8.8 7.55817 8.8 8V12.0007C8.8 12.4425 8.44183 12.8007 8 12.8007C7.55817 12.8007 7.2 12.4425 7.2 12.0007V8C7.2 7.55817 7.55817 7.2 8 7.2ZM8 5.6C8.44183 5.6 8.8 5.24183 8.8 4.8C8.8 4.35817 8.44183 4 8 4C7.55817 4 7.2 4.35817 7.2 4.8C7.2 5.24183 7.55817 5.6 8 5.6Z"
        fill={color}
      />
    </svg>
  );
};

export default InfoIcon;
