import React from 'react';

const CandidatesFillIcon = ({ size = 24, ...props }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M12.0739 5.17967C12.0272 5.17301 11.9806 5.17301 11.9339 5.17967C10.9006 5.14634 10.0806 4.29967 10.0806 3.25967C10.0806 2.19967 10.9406 1.33301 12.0072 1.33301C13.0672 1.33301 13.9339 2.19301 13.9339 3.25967C13.9272 4.29967 13.1072 5.14634 12.0739 5.17967Z" fill="#767476"/>
    <path d="M14.2472 9.80027C13.5005 10.3003 12.4539 10.4869 11.4872 10.3603C11.7405 9.8136 11.8739 9.20694 11.8805 8.56694C11.8805 7.90027 11.7339 7.26694 11.4539 6.7136C12.4405 6.58027 13.4872 6.76694 14.2405 7.26694C15.2939 7.96027 15.2939 9.10027 14.2472 9.80027Z" fill="#767476"/>
    <path d="M4.68048 5.17967C4.72715 5.17301 4.77382 5.17301 4.82048 5.17967C5.85382 5.14634 6.67382 4.29967 6.67382 3.25967C6.67382 2.19301 5.81382 1.33301 4.74715 1.33301C3.68715 1.33301 2.82715 2.19301 2.82715 3.25967C2.82715 4.29967 3.64715 5.14634 4.68048 5.17967Z" fill="#767476"/>
    <path d="M4.75395 8.56706C4.75395 9.21373 4.89395 9.82706 5.14729 10.3804C4.20729 10.4804 3.22729 10.2804 2.50729 9.80706C1.45395 9.10706 1.45395 7.96706 2.50729 7.26706C3.22062 6.78706 4.22729 6.59373 5.17395 6.70039C4.90062 7.26039 4.75395 7.89373 4.75395 8.56706Z" fill="#767476"/>
    <path d="M8.46716 10.58C8.41382 10.5733 8.35382 10.5733 8.29382 10.58C7.06716 10.54 6.08716 9.53333 6.08716 8.29333C6.09382 7.02667 7.11382 6 8.38716 6C9.65382 6 10.6805 7.02667 10.6805 8.29333C10.6738 9.53333 9.70049 10.54 8.46716 10.58Z" fill="#767476"/>
    <path d="M6.30065 11.9598C5.29399 12.6331 5.29399 13.7398 6.30065 14.4064C7.44732 15.1731 9.32732 15.1731 10.474 14.4064C11.4807 13.7331 11.4807 12.6264 10.474 11.9598C9.33399 11.1931 7.45399 11.1931 6.30065 11.9598Z" fill="#767476"/>
    </svg>
    
  );
};

export default CandidatesFillIcon;
