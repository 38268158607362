import { Form, Formik, useField } from 'formik';
import React from 'react';
import { Modal, Row } from 'react-bootstrap';
import PortletBody from '../../../app/components/portlet/PortletBody';
import StyledButton from '../../../app/components/styledComponents/StyledButton';
import * as Yup from 'yup';
import FormikInputField from '../../../app/components/formikComponents/FormikInputField';
import { useAddSettingMutation, useUpdateSettingMutation } from '../../company/companyApiSlice';
import { toast } from 'react-toastify';
import styled from 'styled-components';

const AddSettingsModal = ({ show, compId, onHide, title, editSettingData, editSettings, refetch }) => {
    const [addSetting, { isLoading: loading }] = useAddSettingMutation()
    const [updateSetting, { isLoading: editloading }] = useUpdateSettingMutation()

    const handleSubmit = ({ accessKey, defaultValue, description }) => {
        let sendData = {
            accessKey,
            defaultValue,
            description,
            id: editSettingData?.id ?? undefined
        }
        editSettings ? updateSetting(sendData).unwrap().then(data => { toast.success(data.message); refetch(); onHide() }) : addSetting(sendData).unwrap().then(data => { toast.success(data.message); refetch(); onHide() })
    };

    const addSettingsSchema = Yup.object().shape({
        accessKey: Yup.string().required('Required'),
        defaultValue: Yup.string().required('Required'),
    });

    const FormikTextArea = ({ label, ...props }) => {
        const [field, meta ] = useField(props);
        return (
            <>
                <StyledDropDownContainer {...field} {...props} 
                 isInvalid={
                    meta.touched && meta.error ? true : false
                  }
                />
                {meta.touched && meta.error ? (
                    <div className="error">{meta.error}</div>
                ) : null}
            </>
        );
    }

    return (
        <Modal show={show} centered onHide={onHide} size={'lg'}>
            <Formik
                initialValues={{
                    accessKey: editSettings ? editSettingData?.accessKey || undefined : undefined,
                    defaultValue: editSettings ? editSettingData?.defaultValue || undefined : undefined,
                    description: editSettings ? editSettingData?.description || undefined : undefined
                }}
                validationSchema={addSettingsSchema}
                onSubmit={handleSubmit}
            >
                {({ handleSubmit, setFieldValue }) => (
                    <Form onSubmit={handleSubmit}>
                        <Modal.Header
                            // title={title}
                            closeButton
                            className="border-bottom-0 pb-0 align-items-baseline"
                        >
                            <Modal.Title
                                as="h4"
                                className="flex-grow-1 p-2 cursor-pointer flex-center"
                            >
                                {show && title}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="d-flex  flex-column bg-body rounded">
                            <PortletBody className="p-0 w-100">
                                <PortletBody className={'p-4 w-100'}>
                                    <Row>
                                        <div className="col-12 col-md-12">
                                            <div className="mb-3 ">

                                            </div>
                                        </div>

                                        <div className="col-12 col-md-12">
                                            <div className="mb-3">
                                                <label htmlFor="accessKey" className="form-label">
                                                    Access Key
                                                </label>
                                                <FormikInputField
                                                    name={`accessKey`}
                                                    id={`accessKey`}
                                                    placeholder="Access Key"
                                                />
                                            </div>
                                        </div>

                                        <div className="col-12 col-md-12">
                                            <div className="mb-3">
                                                <label htmlFor="accessKey" className="form-label">
                                                    Default Value
                                                </label>
                                                <FormikInputField
                                                    name={`defaultValue`}
                                                    id={`defaultValue`}
                                                    placeholder="Default Value"
                                                />
                                            </div>
                                        </div>

                                        <div className="col-12 col-md-12">
                                            <div className="mb-3">
                                                <label htmlFor="description" className="form-label">
                                                    Description
                                                </label>
                                                <FormikTextArea
                                                    label="description"
                                                    id="description"
                                                    name="description"
                                                    rows="6"
                                                    placeholder="Enter Message."
                                                    className="rounded"
                                                />
                                            </div>
                                        </div>


                                    </Row>
                                </PortletBody>
                            </PortletBody>
                            <div className="d-flex justify-content-end mt-3">
                                <div className="d-flex gap-3">
                                    <StyledButton
                                        variant={'text'}
                                        className="mb-2 border-0 fw-bold"
                                        onClick={onHide}
                                    >
                                        Cancel
                                    </StyledButton>
                                    <StyledButton className="mb-2" type="submit" loading={loading || editloading} disabled={loading || editloading}>
                                        Save
                                    </StyledButton>
                                </div>
                            </div>
                        </Modal.Body>
                    </Form>
                )}
            </Formik>
        </Modal>
    );
};

const StyledDropDownContainer = styled.textarea`
  width: 99%;
  outline: 0;
  border-color: ${({ theme, isInvalid }) => isInvalid ? theme.danger : theme.inputBorder} !important;
  color: ${({ theme }) => theme.headingColor} !important;
  &::placeholder {
    color: ${({ theme }) => theme.inputPlaceHolder};
    font-size: ${({ $phFontSize }) => $phFontSize ?? '14px'};
    font-weight: ${({ $phFontWeight }) => $phFontWeight ?? '14px'};
    opacity: 1;
  }
  padding: 1em;
  &:hover {
    background-color: #fafafa;
  }
`;

export default AddSettingsModal;
