import React from 'react';
import { Row } from 'react-bootstrap';
import FormikInputField from '../../../../app/components/formikComponents/FormikInputField';
import FormikSelect from '../../../../app/components/formikComponents/FormikSelect';
import PortletBody from '../../../../app/components/portlet/PortletBody';
import { countryCodes } from '../../../Products/assets/utills';

const AdminInfoForm = () => {
  return (
    <>
      <PortletBody className="p-3 w-100">
        <div className="p-1 p-lg-2">
          <h5>Add Company Admin</h5>
          <div className="p-2">
            <Row className="mt-2">
              <div className="col-12 col-md-6">
                <div className="mb-3">
                  <label htmlFor="firstName" className="form-label">
                    First Name
                  </label>
                  <FormikInputField
                    name={`firstName`}
                    id={`firstName`}
                    placeholder="First Name"
                  />
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="mb-3">
                  <label htmlFor="lastName" className="form-label">
                    Last Name
                  </label>
                  <FormikInputField
                    name={`lastName`}
                    id={`lastName`}
                    placeholder="Last Name"
                  />
                </div>
              </div>
            </Row>

            <Row className="mt-2">
              <div className="col-12 col-md-12">
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">
                    Email Address
                  </label>
                  <FormikInputField
                    name={`email`}
                    id={`email`}
                    placeholder="Email Address"
                    type="email"
                  />
                </div>
              </div>
            </Row>

            <Row className="mt-2">
              <div className="col-12 col-md-12">
                <label
                  htmlFor={`phoneCountryCode`}
                  className="form-label"
                >
                  Phone Number
                </label>
              </div>
              <div className="col-2 pe-1">
                <div className="mb-3">
                  <FormikSelect
                    className="me-0 pe-0"
                    name={`phoneCountryCode`}
                    id={`phoneCountryCode`}
                    placeholder="+44"
                    options={countryCodes}
                  />
                </div>
              </div>
              <div className="col-10 ps-0">
                <div className="mb-3">
                  <FormikInputField
                    name={`phoneNumber`}
                    id={`phoneNumber`}
                    placeholder="Enter second number"
                  />
                </div>
              </div>
            </Row>
          </div>
        </div>
      </PortletBody>
    </>
  );
};

export default AdminInfoForm;
