import { useEffect } from 'react';
import { setCredentials, sideMenuState, useLoginWithOutlookQuery } from './authSlice'
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

const OutlookComponent = () => {
  const query = new URLSearchParams(window.location.search);
  const nav = useNavigate();
  const dispatch = useDispatch()
  const { data: token, error } = useLoginWithOutlookQuery({ code: query.get('code') },
    {
      skip: !query.get('code'),
    },);
  useEffect(() => {
    if (token) {
      window.onunload = function (e) { window.opener.onunload(token) }
      window.close();
      dispatch(setCredentials({ "token": { "accessToken": token?.jwtToken?.token, 'sessionId': token?.jwtToken?.admin?.id }, "account": token?.jwtToken?.admin }));
      dispatch(sideMenuState(false));
    }
  }, [token]);

  useEffect(() => {
    if (error) {
      window.onunload = function (e) { window.opener.onunload(error) }
      window.close();
      nav(`/`);
    }
  }, [error]);

  if (!query.get('code')) {
    return nav('/');
  }

  return 'Loading'

};

export default OutlookComponent;