import { Form, Formik } from 'formik';
import React from 'react';
import { Container, Row } from 'react-bootstrap';
import { useOutletContext } from 'react-router-dom';
import FormikInputField from '../../../../app/components/formikComponents/FormikInputField';
import FormikSelect from '../../../../app/components/formikComponents/FormikSelect';
import FormikTextEditor from '../../../../app/components/formikComponents/FormikTextEditor';
import PortletBody from '../../../../app/components/portlet/PortletBody';
import StyledButton from '../../../../app/components/styledComponents/StyledButton';
import { StyledIconBg } from '../../../../app/components/styledComponents/StyledIconBg';
import InformationIcon from '../../../../app/components/svgIcons/InformationIcon';
import { StyledLabel } from '../../../../assets/styledComponents/Global';
import {
  PRODDUCT_POSTING_TYPES,
  PRODUCT_TYPES,
  basicInformationSchema,
  basicInformationValues,
  groupType,
  visiblities,
} from '../../assets/utills';
import StyledSwitch from '../../../../app/components/styledComponents/StyledSwitch';

const BasicInformation = () => {
  const {
    innerRef,
    handleSubmit,
    selectedType,
    basicInformation,
    initialData,
  } = useOutletContext();

  const handleSwitch = (postingType, setFieldValue) => {
    setFieldValue(
      'postingType',
      postingType === PRODDUCT_POSTING_TYPES.AUTO ? 'manual' : 'auto',
    );
  };
  return (
    <>
      <Formik
        innerRef={innerRef}
        initialValues={{
          ...basicInformationValues,
          ...initialData,
          ...basicInformation,
        }}
        enableReinitialize
        validationSchema={basicInformationSchema}
        onSubmit={handleSubmit}
      >
        {({ handleSubmit, values, setFieldValue }) => (
          <Form
            onSubmit={handleSubmit}
            onChange={selectedType(values.productType)}
          >
            <PortletBody className="mt-4 ms-2">
              <Container fluid className="my-2">
                <Row>
                  <div className="d-flex align-items-center gap-3 mb-4">
                    <StyledIconBg>
                      <InformationIcon size={16} />
                    </StyledIconBg>
                    <StyledLabel size="20px" weight="600">
                      Basic Information
                    </StyledLabel>
                  </div>
                  <div className="col-6">
                    <div className="mb-3">
                      <label htmlFor="title" className="form-label">
                        Title
                      </label>
                      <FormikInputField
                        name="title"
                        id="title"
                        placeholder="Enter title"
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="mb-3">
                      <label htmlFor="creditPrice" className="form-label">
                        Price (in credits)
                      </label>
                      <FormikInputField
                        type="number"
                        name="creditPrice"
                        id="creditPrice"
                        placeholder="Enter price"
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="mb-3">
                      <label htmlFor="quantity" className="form-label">
                        Quantity
                      </label>
                      <FormikInputField
                        type="number"
                        name="quantity"
                        id="quantity"
                        placeholder="Enter quantity"
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="mb-3">
                      <label htmlFor="visibility" className="form-label">
                        Visibility
                      </label>
                      <FormikSelect
                        name="visibility"
                        id="visibility"
                        placeholder="Enter visibility"
                        options={visiblities}
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="mb-3">
                      <label htmlFor="productType" className="form-label">
                        Product Type
                      </label>
                      <FormikSelect
                        name="productType"
                        id="productType"
                        placeholder="Enter type"
                        options={groupType}
                      />
                    </div>
                  </div>
                  {values?.productType === PRODUCT_TYPES.ADVERTISING ? (
                    <div className="col-6 d-flex align-align-items-center">
                      <div
                        className={`d-flex justify-content-start align-items-center h-auto`}
                      >
                        <StyledLabel
                          className="pe-2"
                          color={'#0A0509'}
                          weight={700}
                          size="14px"
                        >
                          Posting Type
                        </StyledLabel>
                        <StyledSwitch
                          isToggle={
                            values?.postingType === 'auto' ? true : false
                          }
                          onToggle={() => {
                            handleSwitch(values?.postingType, setFieldValue);
                          }}
                          width={'17px'}
                          height={'17px'}
                          lblheight={'16px'}
                          lblWidth={'29px'}
                          left={'0.3px'}
                          bottom={'0.3px'}
                          top={'0px'}
                        />
                        <StyledLabel
                          className="ps-2"
                          color={'#0A0509'}
                          weight={600}
                          size="14px"
                        >
                          {values?.postingType ===
                          PRODDUCT_POSTING_TYPES?.MANUAL
                            ? '(Manual)'
                            : '(Auto)'}
                        </StyledLabel>
                      </div>
                    </div>
                  ) : null}
                  <div className="col-12">
                    <label htmlFor="description" className="form-label mb-0">
                      Description
                    </label>

                    <div className="mt-1">
                      <FormikTextEditor
                        className="mt-3"
                        name="description"
                        borderRadiusEditor={'0 0 8px 8px'}
                        id="description"
                        placeholder="Enter description"
                      />
                    </div>
                  </div>
                </Row>
              </Container>
            </PortletBody>
            <StyledButton className="border-end ms-auto mt-4" type="submit">
              Save
            </StyledButton>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default BasicInformation;
