import React from 'react';
import { Modal, Row } from 'react-bootstrap';
import PortletBody from '../../../app/components/portlet/PortletBody';
import StyledButton from '../../../app/components/styledComponents/StyledButton';
import StyledCopy from '../../../app/components/styledComponents/StyledCopy';
import StyledIconInput from '../../../app/components/styledComponents/StyledIconInput';

const CallModal = ({ show, onHide }) => {
  return (
    <Modal
      show={show}
      centered
      onHide={onHide}
      size={'lg'}
      className="bg-black bg-opacity-75"
    >
      <Modal.Header
        title="Call Us"
        closeButton
        className="border-bottom-0 pb-0 align-items-baseline me-3 "
      >
        <div>
          <Modal.Title
            as="h4"
            className="flex-grow-1 px-3 pt-3 cursor-pointer flex-center fs-3 mb"
          >
            Call Us
          </Modal.Title>
        </div>
      </Modal.Header>

      <Modal.Body className="d-flex  flex-column bg-body rounded ">
      <PortletBody className="p-0 w-100">
                <PortletBody className={'p-3 w-100'}>
                  <Row>
                    <div className="col-12 col-md-12">
                      <div className="mb-1">
                        <StyledIconInput
                        $maxWidth='100'
                        inputClass={'fw-bold py-1 ms-0'}
                          value={'+44 0257393820'}
                          icon1={<StyledCopy text='+44 0257393820'/>}
                          disabled
                        />
                      </div>
                    </div>
                    </Row>
                    </PortletBody>
                    </PortletBody>
                    <div className="d-flex justify-content-end mt-3">
                <div className="d-flex gap-3">
                  <StyledButton
                    className="mb-2 border-0 fw-bold"
                    onClick={onHide}
                  >
                    Close
                  </StyledButton>
                  
                </div>
              </div>
      </Modal.Body>
        
    </Modal>
  );
};

export default CallModal;
