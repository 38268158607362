import React, { useCallback, useEffect, useState } from 'react';
import { AsyncPaginate } from 'react-select-async-paginate';
import themes from '../../../utils/themes';
import StyledSelectCss from './StyledSelectCss';

const AsyncSelect = ({
  placeholder = 'Select a form from Library',
  className,
  onSelect,
  value,
  apiCall,
  searchAble,
  ...props
}) => {
  const [selectedValue, setSelectedValue] = useState(value);

  useEffect(() => {
    if (props.isMulti && value) {
      setSelectedValue([...value]);
    } else if (!props?.isMulti && value) {
      setSelectedValue({
        ...value,
        label: value?.title || value?.label,
        value: value?.id || value?.value,
      });
    }
  }, [value]);

  async function loadOptions(search, loadedOptions, { page }) {
    let param = {
      perPage: 5,
      page,
      query: search,
      companyId: props.compId,
      ...props.params
    };
    try {
      const { data } = await apiCall(param);
      const totalData = data?.total ? data?.total : data?.totalCount;
      return {
        options: data?.options || [],
        hasMore: loadedOptions.length + data?.options?.length < totalData,
        additional: {
          page: page + 1,
        },
      };
    } catch (e) {
      return {
        options: [],
        hasMore: false,
      };
    }
  }

  const handleChange = useCallback((val) => {
    if (onSelect) {
      onSelect(val);
    } else {
      setSelectedValue(val);
    }
  }, []);

  // console.log({props})
  return (
    <StyledSelectCss>
      <AsyncPaginate
        value={selectedValue}
        onChange={handleChange}
        placeholder={placeholder}
        loadOptions={loadOptions}
        cacheUniqs={[props.params?.jobId]}
        isSearchable={searchAble}
        theme={(theme) => ({
          ...theme,
          borderRadius: 10,
          colors: {
            ...theme.colors,
            primary25: themes.lightHover,
            primary50: themes.primaryLight,
          },
        })}
        style={{ width: 100 }}
        className={`async-select ${props.isValid ? 'is-valid' : ''} ${props.isLightPink ? 'is-light-pink' : ''
          } ${props.isInvalid ? 'is-invalid' : ''} ${props.disabled ? 'disabled' : ''
          } ${className ?? ''}`}
        additional={{
          page: 1,
        }}
        {...props}
      />
    </StyledSelectCss>
  );
};

export default AsyncSelect;
