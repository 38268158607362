import React from 'react';
import PortletHeaderTabs from './PortletHeaderTabs';

const PortletHeader = ({
  title = '',
  headerClassName = '',
  containerClassName = '',
  actions,
  subTitle,
  borderBottom,
  tabs,
  titleDetails,
  filters,
  onChangeTabs,
  breadCrumb,
  counts,
  count,
}) => (
  <div
    className={`portlet__header-container rounded  ${!tabs ? 'isHeadingOnly' : ''
      } ${containerClassName}`}
  >
    {counts && <div className="mb-1">{counts}</div>}
    <div className="flex-center flex-wrap ">
      {title && (
        <h2 className="flex-grow-1 mt-0">
          {title}
          {subTitle && <span className="fw-light fs-4 ps-1">{subTitle}</span>}
          {count && <span>{' ' + count}</span>}
        </h2>
      )}
      {breadCrumb && <div className="flex-grow-1 ">{breadCrumb}</div>}
      {actions && <div>{actions}</div>}
    </div>
    {titleDetails && <div>{titleDetails}</div>}

    <div
      className={
        breadCrumb ? 'd-flex justify-content-center ' : 'flex-center flex-wrap'
      }
    >
      <div className={breadCrumb ? '' : 'flex-grow-1'}>
        {tabs && (
          <div
            className={`portlet__header pb-0 ${headerClassName} ${borderBottom ? 'portlet__header-border' : ''
              }`}
          >
            <div className={breadCrumb ? '' : 'flex-grow-1'}>
              {tabs && (
                <PortletHeaderTabs tabs={tabs} onChangeTabs={onChangeTabs} />
              )}
            </div>
          </div>
        )}
      </div>
      <div className="display-flex">{filters && <div>{filters}</div>}</div>
    </div>
  </div>
);

export default PortletHeader;
