import { Formik } from 'formik';
import React from 'react';
import { Form, Modal } from 'react-bootstrap';
import * as Yup from 'yup';
import FormikInputField from '../../../../app/components/formikComponents/FormikInputField';
import FormikSelect from '../../../../app/components/formikComponents/FormikSelect';
import PortletBody from '../../../../app/components/portlet/PortletBody';
import StyledButton from '../../../../app/components/styledComponents/StyledButton';
// import { countryCodes } from '../../../candidates/creation/components/CandidateLIstingUtils';
import { findPhoneNumbersInText } from 'libphonenumber-js'
// import { useUpdateChildCompanyMutation, useAddChildCompanyMutation } from '../childCompaniesApiSlice';
import { toast } from 'react-toastify';
import { countryCodes } from '../../../Products/assets/utills';
import { useChildCompaniesUpdateMutation } from '../../companyApiSlice';
import { domainSelect } from '../../../../utils/helper';
import StyledToolTip from '../../../../app/components/styledComponents/StyledToolTip';

const CompanyEditModal = ({
    show,
    onHide,
    companyDetails,
    companyEdit,
    refetch
}) => {
    const [updateChild, { isLoading: addChildCompanyLoading }] = useChildCompaniesUpdateMutation();
    const removeCountryCode = (telephone, countryCodes) => {
        for (let i = 0; i < countryCodes.length; i++) {
            const countryCode = countryCodes[i].value;
            if (telephone?.startsWith(countryCode)) {
                return telephone.slice(countryCode.length);
            }
        }
        return telephone;
    }

    const extractCountryCodeFromTelephone = (telephone, countryCodes) => {
        let countryCode = null;
        for (let i = 0; i < countryCodes.length; i++) {
            if (telephone?.startsWith(countryCodes[i].value)) {
                countryCode = countryCodes[i].value;
                break;
            }
        }
        return countryCode;
    }
    // const extractSubdomain = (obj, address) => {
    //     const regex = new RegExp(`^(\\w+)\\.${obj}$`);
    //     const match = address?.match(regex);
    //     return match ? match[1] : null;
    // }
    const handleSubmit = async (values) => {
        let data = {
            telephone: values?.mobileCountryCode + values?.telephone,
            companyName: values?.companyName,
           // careerSiteUrl: values?.careerSiteUrl ? values?.careerSiteUrl + '.' + domainSelect() : undefined,
            id: companyDetails?.id,
            statusId: companyEdit ? companyDetails?.statusId : 2
        }
        updateChild(data)
            .unwrap()
            .then((res) => {
                toast.success(res?.message || 'Company Updated Successfully');
                refetch();
                onHide();
            });
    };

    const addCompanySchema = Yup.object().shape({
        companyName: Yup.string().required('Required'),
        telephone: Yup.string().when('mobileCountryCode', (mobileCountryCode, schema, val) => val?.parent?.telephone && schema
            .test('is-valid-phone', 'Invalid Number',
                (value) => findPhoneNumbersInText(`${mobileCountryCode}${value}`)?.length > 0 && !value?.includes('+'))).required('Required'),
        mobileCountryCode: Yup.string().required('Required').nullable(),
    });
    return (
        <Modal show={show} centered onHide={onHide} size={'lg'}>
            <Formik
                initialValues={{
                    companyName: companyDetails?.name,
                    telephone: removeCountryCode(companyDetails?.telephone, countryCodes),
                    careerSiteUrl: companyDetails?.cpCompanySetting?.domain || '',
                    mobileCountryCode: extractCountryCodeFromTelephone(companyDetails?.telephone, countryCodes)
                }}
                validationSchema={addCompanySchema}
                onSubmit={handleSubmit}
            >
                {({ handleSubmit }) => (
                    <Form onSubmit={handleSubmit}>
                        <Modal.Header
                            closeButton
                            className="border-bottom-0 pb-0 align-items-baseline"
                        >
                            <Modal.Title
                                as="h4"
                                className="flex-grow-1 px-0 cursor-pointer flex-center pt-md-1 pb-md-3"
                            >
                                {'Edit Company'}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="d-flex  flex-column bg-body rounded">
                            <PortletBody className="p-2 w-100">
                                <PortletBody className={'p-2 w-100'}>
                                    <div>
                                        <h6>Company name</h6>
                                        <FormikInputField
                                            id="companyName"
                                            name="companyName"
                                            placeholder="Enter company name"
                                        />
                                    </div>
                                    <div className="mt-4">
                                        <h6>Contact</h6>
                                        <div className='row'>
                                            <div className="col-3 pe-1">
                                                <div className="mb-3">
                                                    <FormikSelect
                                                        className="me-0 pe-0"
                                                        name={`mobileCountryCode`}
                                                        id={`mobileCountryCode`}
                                                        placeholder="+0"
                                                        options={countryCodes}
                                                    />

                                                </div>
                                            </div>
                                            <div className="col-9 ps-0">
                                                <div className="mb-3">
                                                    <FormikInputField
                                                        type="tel"
                                                        name={`telephone`}
                                                        id={`telephone`}
                                                        placeholder="Enter telephone number"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <h6 className='d-flex pb-2'>Career Site URL
                                            <StyledToolTip
                                                title="'Choose a name for your career website. This URL can then be used to list jobs and for candidate applications'"
                                                childrenClass={'ms-0 cursor-pointer'}
                                            ></StyledToolTip>

                                        </h6>
                                        <div className='d-flex align-items-end'><FormikInputField
                                            id="careerSiteUrl"
                                            name="careerSiteUrl"
                                            placeholder="Sub domain name"
                                            disabled={true}
                                        />
                                            <span className='px-3 fs-5'>.{domainSelect()} </span></div>
                                    </div>
                                    <div className='py-3 fw-700'>
                                        <h5>By creating a new company, you agree to our
                                            <a href='/policies' target="_blank" className='text-decoration-none'> Terms & Conditions</a> and acknowledge our  <a href='/policies' target="_blank" className='text-decoration-none'>Privacy Policy</a>.
                                        </h5>
                                    </div>
                                </PortletBody>
                            </PortletBody>
                            <div className="d-flex justify-content-end mt-3">
                                <div className="d-flex gap-3">
                                    <StyledButton
                                        variant={'white'}
                                        className="mb-2 border-0 fw-bold"
                                        onClick={onHide}
                                    >
                                        Cancel
                                    </StyledButton>
                                    <StyledButton
                                        className="mb-2"
                                        type="submit"
                                        disabled={addChildCompanyLoading}
                                        loading={addChildCompanyLoading}
                                    >
                                        {'Update'}
                                    </StyledButton>
                                </div>
                            </div>
                        </Modal.Body>
                    </Form>
                )}
            </Formik>
        </Modal>
    );
};


export default CompanyEditModal;
