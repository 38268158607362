import React from 'react';

const FirstJobIcon = ({ size = 18, color = "#000000", ...props }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.13208" width="24" height="24" rx="12" fill="#F5F5F5"/>
    <path d="M17.4347 9.07167C16.9389 8.52333 16.1105 8.24917 14.9089 8.24917H14.7689V8.22583C14.7689 7.24583 14.7689 6.0325 12.5755 6.0325H11.6888C9.49552 6.0325 9.49552 7.25167 9.49552 8.22583V8.255H9.35552C8.14802 8.255 7.32552 8.52917 6.82968 9.0775C6.25218 9.71917 6.26968 10.5825 6.32802 11.1717L6.33385 11.2125L6.36964 11.5883C6.3839 11.7381 6.46465 11.8736 6.59093 11.9554C6.72852 12.0445 6.90696 12.1577 7.02218 12.2217C7.10385 12.2742 7.19135 12.3208 7.27885 12.3675C8.27635 12.9158 9.37302 13.2833 10.4872 13.4642C10.5397 14.0125 10.7789 14.6542 12.0564 14.6542C13.3339 14.6542 13.5847 14.0183 13.6255 13.4525C14.8155 13.26 15.9647 12.8458 17.003 12.2392C17.038 12.2217 17.0614 12.2042 17.0905 12.1867C17.2981 12.0693 17.5128 11.9275 17.7127 11.7856C17.8226 11.7076 17.8923 11.5855 17.9072 11.4517L17.9364 11.1775C17.9422 11.1425 17.9422 11.1133 17.948 11.0725C17.9947 10.4833 17.983 9.67833 17.4347 9.07167ZM12.768 13.0675C12.768 13.6858 12.768 13.7792 12.0505 13.7792C11.333 13.7792 11.333 13.6683 11.333 13.0733V12.3383H12.768V13.0675ZM10.3297 8.24917V8.22583C10.3297 7.23417 10.3297 6.86667 11.6888 6.86667H12.5755C13.9347 6.86667 13.9347 7.24 13.9347 8.22583V8.255H10.3297V8.24917Z" fill="#767476"/>
    <path d="M16.9701 13.1706C17.3252 13.0057 17.7331 13.2866 17.6977 13.6766L17.5221 15.6108C17.3996 16.7775 16.9212 17.9675 14.3546 17.9675H9.90957C7.3429 17.9675 6.86457 16.7775 6.74207 15.6167L6.57636 13.7938C6.54132 13.4084 6.93957 13.1278 7.29363 13.2842C7.94429 13.5715 8.85815 13.9543 9.50051 14.138C9.66382 14.1847 9.79569 14.3035 9.87644 14.4529C10.2502 15.1447 10.998 15.5117 12.0562 15.5117C13.1042 15.5117 13.8603 15.1304 14.2359 14.4356C14.3167 14.2861 14.4488 14.1674 14.6121 14.1202C15.2986 13.922 16.2819 13.4901 16.9701 13.1706Z" fill="#767476"/>
    </svg>
    
    

  );
};

export default FirstJobIcon;
