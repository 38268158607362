import React from 'react';

const SubsidiariesIcon = ({ size = 16, ...props }) => (
  <svg width={size} height={size} viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M6.66671 13.3431C6.66671 14.0795 7.26366 14.6764 8.00004 14.6764C8.73642 14.6764 9.33337 14.0795 9.33337 13.3431C9.33337 12.6067 8.73642 12.0098 8.00004 12.0098C7.26366 12.0098 6.66671 12.6067 6.66671 13.3431Z" stroke="#767476" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M12 13.3431C12 14.0795 12.5969 14.6764 13.3333 14.6764C14.0697 14.6764 14.6666 14.0795 14.6666 13.3431C14.6666 12.6067 14.0697 12.0098 13.3333 12.0098C12.5969 12.0098 12 12.6067 12 13.3431Z" stroke="#767476" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M1.33333 13.3431C1.33333 14.0795 1.93029 14.6764 2.66667 14.6764C3.40305 14.6764 4 14.0795 4 13.3431C4 12.6067 3.40305 12.0098 2.66667 12.0098C1.93029 12.0098 1.33333 12.6067 1.33333 13.3431Z" stroke="#767476" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M6.66671 2.67611C6.66671 3.41249 7.26366 4.00944 8.00004 4.00944C8.73642 4.00944 9.33337 3.41249 9.33337 2.67611C9.33337 1.93973 8.73642 1.34277 8.00004 1.34277C7.26366 1.34277 6.66671 1.93973 6.66671 2.67611Z" stroke="#767476" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8 4.00977L8 12.0098" stroke="#767476" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M13.3334 12.0094L13.3334 9.34277C13.3334 8.00944 12.6667 7.34277 11.3334 7.34277L4.66671 7.34277C3.33337 7.34277 2.66671 8.00944 2.66671 9.34277L2.66671 12.0094" stroke="#767476" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>

);

export default SubsidiariesIcon;
