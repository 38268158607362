import React from 'react';
import styled from 'styled-components';
// import ArrowIcon from '../../../app/components/svgIcons/ArrowIcon';

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import theme from '../../utils/themes';
import { Wrap } from '../styledComponents/Global';

const CardSkeleton = () => {
  return (
    <>
      <StyledWallet className="d-flex p-4 me-2 justify-content-between rounded-4 align-items-center">
        <Wrap className="">
          <Skeleton height={20} width={85} className="me-2 mt-2" />

          <Wrap className="d-flex gap-2 mb-3 mt-2">
            <Wrap className="">
              <Skeleton height={32} width={58} className="me-2 mt-2" />
            </Wrap>

            <Wrap className="d-flex">
              <Skeleton height={32} width={58} className="me-2 mt-2" />
              <Skeleton height={32} width={80} className="me-2 mt-2" />

            </Wrap>
          </Wrap>

          <Wrap className="d-flex ">
            <Skeleton height={30} width={81} className="me-2 mt-2" />
            <Skeleton height={30} width={81} className="me-2 mt-2" />
          </Wrap>
        </Wrap>
        <Skeleton height={54} width={57} className="me-2 mt-2" />
      </StyledWallet>

      {/* <StyledWallet className="d-flex px-4 pt-3 pb-3 me-2 justify-content-between rounded-3">
                <Wrap className="">
                    <Wrap className="pb-2">
                        <h5 className="text-primary"><Skeleton height={15} width={100} className="me-2 mt-2" /></h5>
                        <h1 className="text-primary fs-1"><Skeleton height={20} width={60} className="me-2 mt-2" /></h1>
                    </Wrap>
                    <Wrap className="d-flex justify-content-between">
                        <Skeleton height={25} width={100} className="me-2 mt-2" />
                        <Skeleton height={25} width={100} className="me-2 mt-2" />
                    </Wrap>
                </Wrap>
                <Skeleton height={80} width={160} className="me-2 mt-3 rounded" />
            </StyledWallet> */}
      {Array.from(new Array(3)).map((i, ind) => (
        <StyledCard className="bg-white px-4 pt-3 pb-2 mx-2" key={`sekeltincards-${ind}`}>
          <Skeleton height={30} width={50} className="me-2 mt-2" />
          <Wrap className="pt-4">
            <p className="fs-5">
              <Skeleton height={15} width={150} className="me-2 mt-2" />
            </p>
            <Wrap className="d-flex w-100 justify-content-between">
              <h4>
                {' '}
                <Skeleton height={15} width={40} className="me-2 mt-2" />
              </h4>
              {ind !== 0 && (
                <Skeleton height={15} width={40} className="me-2 mt-2" />
              )}
            </Wrap>
          </Wrap>
        </StyledCard>
      ))}
    </>
  );
};

const StyledCard = styled.div`
  border-radius: 12px;
  width: 50%;
`;
const StyledWallet = styled.div`
  background: linear-gradient(
    102.05deg,
    ${theme.occyCredit1} 2.51%,
    ${theme.occyCredit2} 93.7%
  );
  border-radius: 6px;
  width: 55%;
`;

export default CardSkeleton;
