import React from 'react';
import { Row } from 'react-bootstrap';
import FormikInputField from '../../../app/components/formikComponents/FormikInputField';
import PortletBody from '../../../app/components/portlet/PortletBody';
import { StyledSpan, Wrap } from '../../../assets/styledComponents/Global';
import styled from 'styled-components';
import FormikAsyncSelect from '../../../app/components/formikComponents/FormikAsyncSelect';
import ReedImgIcon from '../../../app/components/svgIcons/ReedImgIcon';
import { coverLetter, parentSector, productTypes, quickapply } from '../CampaignsUtils';


const ReedEditForm = ({ values }) => {

  return (
    <>
      <PortletBody className="w-100">
        <div className="p-4">
          <StyledWrap className={`d-flex align-items-center justify-content-start gap-3`}>
            <ReedImgIcon size={24} />
            <Wrap className='d-flex flex-column'>
              <StyledSpan size='16px' className='text fw-semibold'>Reed</StyledSpan>
            </Wrap>
          </StyledWrap>
          <div className="py-2">
            <Row className="mt-2">
              <div className="col-12 col-md-6">
                <div className="mb-3">
                  <label htmlFor="product" className="form-label">
                    Product
                  </label>
                  <FormikAsyncSelect
                    name="product"
                    id="product"
                    searchAble={false}
                    options={productTypes}
                    placeholder="Select product type"
                    isDisabled={values?.jobBoardSummaryKeys?.reedObject}
                  />
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="mb-3">
                  <label htmlFor="expiryInDays" className="form-label">
                    Expiry
                  </label>
                  <FormikInputField
                    name="expiryInDays"
                    id="expiryInDays"
                    type="number"
                    min="1"
                    max="42"
                    placeholder="Select expiry in days"
                    disabled={values?.jobBoardSummaryKeys?.reedObject}
                  />
                </div>
              </div>
            </Row>
            <Row className="mt-2">
              <div className="col-12 col-md-6">
                <div className="mb-3">
                  <label htmlFor="sendApplicationDigest" className="form-label">
                    Digest Application
                  </label>
                  <FormikAsyncSelect
                    name="sendApplicationDigest"
                    id="sendApplicationDigest"
                    searchAble={false}
                    options={quickapply}
                    placeholder="Send application in digest format"
                    isDisabled={values?.jobBoardSummaryKeys?.reedObject}
                  />
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="mb-3">
                  <label htmlFor="parentSector" className="form-label">
                    Parent Sector
                  </label>
                  <FormikAsyncSelect
                    name="parentSector"
                    id="parentSector"
                    searchAble={false}
                    options={parentSector}
                    placeholder="Select parent sector"
                    isDisabled={values?.jobBoardSummaryKeys?.reedObject}
                  />
                </div>
              </div>
            </Row>

            <Row className="mt-2">
              <div className="col-12 col-md-6">
                <div className="mb-3">
                  <label htmlFor="coverLetterPreference" className="form-label">
                    Cover Letter
                  </label>
                  <FormikAsyncSelect
                    name="coverLetterPreference"
                    id="coverLetterPreference"
                    options={coverLetter}
                    searchAble={false}
                    placeholder="Select cover letter"
                    isDisabled={values?.jobBoardSummaryKeys?.reedObject}
                  />
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="mb-3">
                  <label htmlFor="isPublic" className="form-label">
                    Public Sector Job
                  </label>
                  <FormikAsyncSelect
                    name="isPublic"
                    id="isPublic"
                    searchAble={false}
                    options={quickapply}
                    placeholder="Public sector job"
                    isDisabled={values?.jobBoardSummaryKeys?.reedObject}
                  />
                </div>
              </div>
            </Row>
            <Row className="mt-2">
              <div className="col-12 col-md-6">
                <div className="mb-3">
                  <label htmlFor="salaryOTE" className="form-label">
                    Salary + OTE
                  </label>
                  <FormikAsyncSelect
                    name="salaryOTE"
                    id="salaryOTE"
                    searchAble={false}
                    options={quickapply}
                    placeholder="Salary OTE"
                    isDisabled={values?.jobBoardSummaryKeys?.reedObject}
                  />
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="mb-3">
                  <label htmlFor="isGraduate" className="form-label">
                    Graduate Job
                  </label>
                  <FormikAsyncSelect
                    name="isGraduate"
                    id="isGraduate"
                    searchAble={false}
                    options={quickapply}
                    placeholder="Select graduate job"
                    isDisabled={values?.jobBoardSummaryKeys?.reedObject}
                  />
                </div>
              </div>
            </Row>
            <Row className="mt-2">
              <div className="col-12 col-md-6">
                <div className="mb-3">
                  <label htmlFor="eligibleUkOnly" className="form-label">
                    UK Eligibility
                  </label>
                  <FormikAsyncSelect
                    name="eligibleUkOnly"
                    id="eligibleUkOnly"
                    searchAble={false}
                    options={quickapply}
                    placeholder="UK eligibility"
                    isDisabled={values?.jobBoardSummaryKeys?.reedObject}
                  />
                </div>
              </div>
            </Row>
          </div>
        </div>
      </PortletBody>
    </>
  );
};

const StyledWrap = styled.div`
margin : 1px 1px 0px 0px;
`;
export default ReedEditForm;
