import React, { useEffect, useState } from 'react';
import Portlet from '../../../app/components/portlet/Portlet';
import PortletBody from '../../../app/components/portlet/PortletBody';
import CustomDropdown from '../../../app/components/dropdown/CustomDropDown';
import DropdownItem from 'react-bootstrap/esm/DropdownItem';
import StyledButton from '../../../app/components/styledComponents/StyledButton';
import EditIcon from '../../../app/components/svgIcons/EditIcon';
import OptionFillIcon from '../../../app/components/svgIcons/OptionFillIcon';
import { Link, useParams } from 'react-router-dom';
import EyeIcon from '../../../app/components/svgIcons/EyeIcon';
import PaginationPerPage from '../../../app/components/pagination/PaginationPerPage';
import TableSkeleton from '../../../assets/skeletons/tableSkeleton';
import JobListingTable from './JobListingTable';
import StyledIconInput from '../../../app/components/styledComponents/StyledIconInput';
import SearchIcon from '../../../app/components/svgIcons/SearchIcon';
import FilterIcon from '../../../app/components/svgIcons/FilterIcon';
import SortIcon from '../../../app/components/svgIcons/SortIcon';
import StyledRadioButton from '../../../app/components/radio/StyledRadioButton';
import { StyledFilterBar } from '../../../app/components/styledComponents/StyledFilterBar';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { isEqual } from 'lodash';
import CrossIcon from '../../../app/components/svgIcons/CrossIcon';
import { useGetAllJobsQuery } from '../jobApiSlice';
import moment from 'moment';
import StyledColoredTag from '../../../app/components/styledComponents/StyledColoredTag';
import OnlineIcon from '../../../app/components/svgIcons/OnlineIcon';
import { getStatusFriendly } from '../../../utils/helper';
import { JOB_COPY_WRITING } from '../../../utils/Statuses';
import TrashIcon from '../../../app/components/svgIcons/TrashIcon';
import ChangeStatusIcon from '../../../app/components/svgIcons/ChangeStatusIcon';
import ChangeStatusModal from '../../JobListing/listing/ChangeStatusModal';
import { useDeleteJobMutation } from '../../JobListing/jobsListingApiSlice';
import { toast } from 'react-toastify';
import DeleteJobModal from '../../JobListing/listing/DeleteJobModal';

const CompanyJobLisitng = () => {
  const initialFilters = {
    jobs: [],
    perPage: 5,
    page: 1,
    sortedColumn: { path: 'title', order: 'ASC' },
    query: '',
  };
  const { compId } = useParams();
  const [timer, setSearchTimer] = useState(null);
  const [listing, setListing] = useState({ ...initialFilters });

  const [sortFilter, setSortFilter] = useState(false);
  const [isOpen, setIsopen] = useState(false);
  const [filters, setFilters] = useState('');

  const sortFilters = [];

  const handleSearch = (evt) => {
    clearTimeout(timer);
    const query = evt.target.value;
    // setSearch(query);
    const newTimer = setTimeout(() => {
      setListing({ ...listing, page: 1, query: query });
    }, 2000);
    setSearchTimer(newTimer);
  };
  const handleSort = (sort) => {
    const sortBlacklist = ['hired', 'statusId', 'account.firstName'];
    if (!sortBlacklist.includes(sort.path)) {
      setListing((prevState) => ({
        ...prevState,
        sortedColumn: { ...sort },
        page: 1,
      }));
    }
  };
  const handlePerPageChange = (perPage) => {
    setListing((prevState) => ({
      ...prevState,
      perPage: perPage.target.innerText,
      page: 1,
    }));
  };
  const handlePageChange = ({ selected }) => {
    setListing((prevState) => ({
      ...prevState,
      page: selected + 1,
    }));
  };
  const statusColor = [
    { statusId: 4, color: 'danger' },
    { statusId: 3, color: 'orange' },
    { statusId: 52, color: 'orange' },
    { statusId: 2, color: 'success' },
    { statusId: 16, color: 'success' },
    { statusId: 1, color: 'info' },
    { statusId: 6, color: 'info' },
    { statusId: 21, color: 'info' },
  ];

  const getTagColor = (statusId) => {
    const colour = statusColor?.find((i) => i.statusId === statusId)?.color;
    return colour;
  };
  const {
    data: jobRes,
    refetch,
    isFetching,
    error,
  } = useGetAllJobsQuery({
    params: {
      perPage: listing.perPage,
      page: listing.page,
      query: listing.query && listing.query,
      sort: listing.sortedColumn.path,
      sortDir: listing.sortedColumn.order,
      ...filters,
    },
    id: compId,
  });


  const [copywritingStatus, setCopywritingStatus] = useState({
    changeStatus: false,
    deleteJob: false,
    status: '',
    jobId: '',
    companyId: ''
  });



  // const [newColumn, setNewColumn] = useState([]);
  const column = [
    // {
    //   id: 1,
    //   path: 'id',
    //   label: 'Job Id',
    //   isChecked: true,
    //   alignBody: 'ps-3 text-left align-middle px-3',
    //   alignHeader: 'ps-3 text-left align-middle cursor-pointer p-3',
    //   content: (job) => (
    //     <Link
    //       // to={`/ jobs / ${ job.id }/applications`}
    //       className="text-decoration-none"
    //     >
    //        <h5 className="m-0">{job?.title || '-'}</h5>,
    //       <span className="small">{job?.id || '-'}</span>
    //     </Link>
    //   ),
    // },
    {
      id: 2,
      isChecked: true,
      path: 'title',
      label: 'Job Title',
      content: (job) => (
        <>
          <h5 className="m-0 cut-text">{job?.title || '-'}</h5>
          <span className="small cut-text">{job?.id || '-'}</span>
        </>
      ),
      alignBody: 'text-left align-middle ps-4 py-3',
      alignHeader: 'text-left align-middle cursor-pointer ps-4 py-3',
    },
    {
      id: 3,
      isChecked: true,
      path: 'expiresAt',
      label: 'Expiry Date',
      content: (job) => (
        <span className="m-0">
          {' '}
          {job?.expiresAt ? moment(job?.expiresAt).format('Do MMM YYYY') : '-'}
        </span>
      ),
      alignBody: 'text-left align-middle ps-4 py-3',
      alignHeader: 'text-left align-middle cursor-pointer ps-4 py-3',
    },
    {
      id: 4,
      isChecked: true,
      path: 'statusId',
      label: 'Status',
      alignBody: 'text-left align-middle ps-4 py-3',
      alignHeader: 'text-left align-middle cursor-pointer ps-4 py-3',
      content: (job) => (
        <>
          <div className="d-flex">
            <StyledColoredTag
              className="rounded-pill mx-0"
              variant={getTagColor(job?.statusId)}
            >
              <OnlineIcon color={getTagColor(job?.statusId)} className="me-1" />{' '}
              {getStatusFriendly(job?.statusId) || '-'}
            </StyledColoredTag>
          </div>
        </>
      ),
    },
    {
      id: 5,
      isChecked: true,
      label: 'Copywriting',
      content: (job) => (
        <>
          <div className="d-flex">
            {Object.values(JOB_COPY_WRITING).includes(job?.copywriteStatus) ? (
              <StyledColoredTag
                className="rounded-pill mx-0"
                variant={getTagColor(job?.copywriteStatus)}
              >
                <OnlineIcon
                  color={getTagColor(job?.copywriteStatus)}
                  className="me-1"
                />
                {getStatusFriendly(job?.copywriteStatus, JOB_COPY_WRITING)}
              </StyledColoredTag>
            ) : (
              <>-</>
            )}
          </div>
        </>
      ),
      alignBody: 'text-left align-middle ps-4 py-3',
      alignHeader:
        'text-left align-middle cursor-pointer ps-4 py-3 text-nowrap',
    },
    {
      id: 6,
      isChecked: true,
      label: 'Actions',
      alignBody: 'text-center align-middle ps-4 py-3',
      alignHeader: 'text-center align-middle cursor-pointer ps-4 py-3',
      content: (job) => (
        <>
          {' '}
          <div className="d-flex justify-content-center align-items-center">
            <a
              href={`${process.env.REACT_APP_URL}/p/job/${job?.id}`}
              variant="text"
              rel="noopener noreferrer"
              target="_blank"
              className="text-decoration-none px-2"
            >
              <EyeIcon />
            </a>
            <Link
              variant="text"
              className="text-decoration-none feature-pending px-2"
              to={`${job.id}`}
            >
              <EditIcon />
            </Link>
            {/* <CustomDropdown
              toggleClassName={'bg-transparent feature-pending'}
              menuClassName={'adminJobs'}
              dropdownToggle={
                <div className={`account-menu ${'bg-transparent'} `}>
                  <OptionFillIcon rotate={'left'} />
                </div>
              }
              dropdownMenu={
                <DropdownItem>
                  <div className="d-flex flex-column">
                    <Link
                      // to={`/jobs/${jobId}/stages`}
                      to={''}
                      className="text-decoration-none"
                    >
                      <span className="dark-text">
                        <MarkIcon />
                      </span>
                      <span className="ms-3 text-secondary">
                        Mark as advertised
                      </span>
                    </Link>
                    <Link
                      // to={`/jobs/${jobId}/stages`}
                      to={''}
                      className="text-decoration-none  pt-2"
                    >
                      <span className="dark-text">
                        <SlashCircleCIon />
                      </span>
                      <span className="ms-3 text-secondary">
                        Cancel Request
                      </span>
                    </Link>
                  </div>
                </DropdownItem>
              }
            /> */}
            <div className="d-flex">

              <CustomDropdown
                toggleClassName={'bg-transparent px-0'}
                menuClassName={'mt-1 z-index-1'}
                dropdownToggle={
                  <div
                    className={`account-menu ${'bg-transparent ps-0 ms-2'} `}
                  >
                    <OptionFillIcon rotate={'left'} />
                  </div>
                }
                dropdownMenu={
                  <>
                    <DropdownItem
                      className="py-2"
                      onClick={() => {
                        setCopywritingStatus({
                          ...copywritingStatus,
                          changeStatus: true,
                          status: job?.copywriteStatus,
                          jobId: job?.id,
                          companyId: job?.companyId
                        })
                      }}
                    >
                      <span className="dark-text">
                        <ChangeStatusIcon />
                      </span>
                      <span className="ms-3 text-secondary">
                        Change Status
                      </span>
                    </DropdownItem>

                    <DropdownItem
                      className="py-2"
                      onClick={() => {
                        setCopywritingStatus({
                          ...copywritingStatus,
                          deleteJob: true,
                          jobId: job?.id,
                          companyId: job?.companyId
                        })
                      }}
                    >
                      <span className="dark-text">
                        <TrashIcon />
                      </span>
                      <span className="ms-3 text-secondary">
                        Delete Job
                      </span>
                    </DropdownItem>
                  </>
                }
              />

            </div>
          </div>
        </>
      ),
    },
  ];

  const handleSubmit = async (values) => {
    setFilters({ ...values });
    setIsopen(false);
  };
  const [removeJob, { isLoading: loading }] = useDeleteJobMutation();

  const deleteJob = () => {
    let data = {
      jobId: copywritingStatus.jobId,
      companyId: copywritingStatus.companyId
    }
    removeJob(data)
      .unwrap()
      .then((res) => {
        toast.success(res?.message || 'Job Deleted Successfully');
        refetch();
        setCopywritingStatus({ deleteJob: false })
      });
  }

  let ToggleFilterbar = (item) => {
    setIsopen(item === 'filter' ? !isOpen : false);
    setSortFilter(item === 'sort' ? !sortFilter : false);
  };

  const handleSortBy = (item) => {
    const prevFilters = { ...filters };
    prevFilters.sort = item.value;
    prevFilters.sortDir = item.dir;
    setFilters({ ...prevFilters });
  };

  const handleSelectedColumn = () => {
    const newArr = [];
    column.map((item) => item.isChecked && newArr.push(item));
    // setNewColumn(newArr);
    // setSelectedColumns(false);
  };

  useEffect(() => {
    handleSelectedColumn();
  }, []);

  return (
    <div>
      <Portlet>
        <PortletBody>
          <>
            <div className="flex-center mb-3">
              <div className="flex-grow-1">
                <StyledIconInput
                  icon={<SearchIcon />}
                  className="mw-50"
                  $maxWidth="60%"
                  $marginLeft="3px"
                  onChange={handleSearch}
                  loading={isFetching}
                  placeholder="Search"
                />
              </div>
              <div className="d-flex gap-2">
                <StyledButton
                  className="px-2 py-1 feature-pending"
                  variant="icon"
                  onClick={() => ToggleFilterbar('filter')}
                  icon={<FilterIcon />}
                >
                  Filter
                </StyledButton>
                <StyledButton
                  className="px-2 py-1 d-flex align-items-center feature-pending"
                  variant="icon"
                  icon={<SortIcon size="20" className="me-0" />}
                  id="sort"
                  onClick={() => ToggleFilterbar('sort')}
                >
                  Sort
                </StyledButton>
              </div>
            </div>

            {error || jobRes?.jobs?.length === 0 ? (
              <div className="d-flex justify-content-center align-items-center">
                No Jobs Found ...
              </div>
            ) : (
              <>
                {jobRes?.jobs.length > 0 ? (
                  <JobListingTable
                    jobs={jobRes?.jobs}
                    sortColumn={listing.sortedColumn}
                    onSort={handleSort}
                    filterTable={column}
                  />
                ) : (
                  <TableSkeleton
                    cols={['Title', 'Expires At', 'Status Id', 'Actions']}
                    rows={3}
                  />
                )}
                <PaginationPerPage
                  perPage={jobRes?.perPage || 0}
                  pageNo={jobRes?.page || 1}
                  onPerPageChange={handlePerPageChange}
                  totalCount={jobRes?.total || 0}
                  onPageChange={handlePageChange}
                />
              </>
            )}
          </>
        </PortletBody>
        <div className="m-0 position-relative d-flex rounded flex-column click-text">
          {sortFilter && (
            <StyledFilterBar
              variant={'sort'}
              className="bg-white p-3 align-items-center justify-content-center"
            >
              <div className="d-flex justify-content-between align-items-center mb-3">
                <h5 className="mb-0">Sort By</h5>
              </div>
              {sortFilters.map((item, index) => {
                return (
                  <div
                    className="d-flex align-items-center py-2 px-2 "
                    key={`sort-${item.value}-index`}
                  >
                    <StyledRadioButton
                      value={filters.sort === item.value}
                      label={item.label}
                      className="fw-bold"
                      onChange={() => handleSortBy(item)}
                    />
                  </div>
                );
              })}
            </StyledFilterBar>
          )}
        </div>

        <div className="portlet__body-bg position-relative bg-transparent click-filters">
          {isOpen && (
            <StyledFilterBar variant="filter" className="my-1">
              <div className="d-flex justify-content-end">
                <div
                  className="cursor-pointer mt-3 me-3"
                  onClick={ToggleFilterbar}
                >
                  <CrossIcon />
                </div>
              </div>

              <div className="sd-header">
                <h4 className="mb-0">Filters</h4>
                {!isEqual(filters, initialFilters) && (
                  <h6
                    className="mb-0 cursor-pointer"
                    onClick={() => {
                      setFilters('');
                      setIsopen(false);
                    }}
                  >
                    Clear filters
                  </h6>
                )}
              </div>
              <div className="sd-body">
                <Formik
                  initialValues={{
                    ...filters,
                  }}
                  validationSchema={Yup.object().shape({
                    appDate: Yup.string(),
                  })}
                  onSubmit={handleSubmit}
                >
                  {({ handleSubmit, values, submitForm, errors }) => (
                    <Form onSubmit={handleSubmit}>filters</Form>
                  )}
                </Formik>
              </div>
            </StyledFilterBar>
          )}
        </div>
      </Portlet>
      {copywritingStatus?.changeStatus &&
        <ChangeStatusModal
          copywritingStatus={copywritingStatus}
          refetch={refetch}
          onHide={() => setCopywritingStatus({ changeStatus: false })}
        />}

      {copywritingStatus?.deleteJob &&
        <DeleteJobModal
          loading={loading}
          show={copywritingStatus?.deleteJob}
          deleteJob={deleteJob}
          onHide={() => setCopywritingStatus({ deleteJob: false })}
        />}

    </div>
  );
};

export default CompanyJobLisitng;
