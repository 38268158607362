import React, { useState, useRef, useEffect } from 'react';
import { StyledLabel } from '../../../assets/styledComponents/Global';
import theme from '../../../utils/themes';
import StyledButton from './StyledButton';
import ToggleArrowIcon from '../svgIcons/ToggleArrowIcon';

const StyledTextContainer = ({ color, scrollable, toggle, toggleScroll, children, header, headerIcon, className }) => {
    const [isOpen, setIsOpen] = useState(true);
    // const [hasScrollbar, setHasScrollbar] = useState(false);
    const containerRef = useRef(null);

    const toggleOpen = () => {
        setIsOpen(!isOpen);
    };

    const checkScrollbar = () => {
        // const container = containerRef.current;
        // if (container) {
        //     setHasScrollbar(container.scrollHeight > container.clientHeight);
        // }
    };

    useEffect(() => {
        checkScrollbar();
        window.addEventListener('resize', checkScrollbar);
        return () => {
            window.removeEventListener('resize', checkScrollbar);
        };
    }, []);

    const boxStyle = {
        backgroundColor: color || '#FAFAFA',
        maxHeight: isOpen ? '258px' : '59px',
        minHeight: !toggle ? '24vh' : 'auto',
        overflow: scrollable ? (!toggle && !toggleScroll ? scrollable : 'hidden') : 'hidden',
        transition: 'max-height 0.5s ease',
        wordWrap: 'break-word',
        position: 'relative',
    };

    const labelStyle = {
        maxHeight: toggleScroll ? '200px' : 'auto',
        overflow: toggleScroll ? 'auto' : 'visible',
    };

    const classes = ['box', className].filter(Boolean).join(' ');

    return (
        <>
            <div className={classes} style={boxStyle} ref={containerRef}>
                <div className={`header d-flex justify-content-between ${header ? 'mb-3' : ''}`}>
                    <span className='d-flex'>
                        {headerIcon && <span className='me-2'>{headerIcon}</span>}
                        {header && <StyledLabel className='' weight={700} color={theme.textColor}>{header}</StyledLabel>}
                    </span>
                    {toggle && (
                        <StyledButton
                            icon={<ToggleArrowIcon rotate={`${isOpen ? 'right' : 'left'}`} />}
                            variant="text"
                            onClick={toggleOpen}
                            className="p-0"
                        />
                    )}
                </div>
                <div style={labelStyle}>
                    <StyledLabel color={theme.textColor} weight={500} lineHight="24px">
                        {children}
                    </StyledLabel>
                </div>
            </div>
        </>
    );
};

export default StyledTextContainer;
