import React from 'react';

const UndoIcon = ({ size = 15, ...props }) => (
    <svg fill="#000000" height={size} width={size} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsLink="http://www.w3.org/1999/xlink" viewBox="0 0 512 512" xmlSpace="preserve"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
    <g id="SVGRepo_tracerCarrier" stroke-linecap="round" strokeLinejoin="round"></g>
    <g id="SVGRepo_iconCarrier"> <g> <g> <g> 
        <path d="M511.919,254.169c0.034-0.557,0.054-1.117,0.045-1.684c-0.267-16.54-2.36-32.991-6.211-48.952 c-2.894-11.954-14.937-19.233-26.847-16.406c-11.954,2.872-19.3,14.893-16.429,26.847c3.094,12.755,4.764,25.956,4.964,39.201 c0.007,0.505,0.038,1.004,0.078,1.5c-0.026,0.438-0.042,0.878-0.042,1.323c0,98.364-79.723,178.087-178.087,178.087 c-95.187,0-172.913-74.656-177.836-168.615l28.53,28.53c8.693,8.693,22.788,8.693,31.482,0c8.693-8.693,8.693-22.788,0-31.482 l-66.783-66.783c-8.693-8.693-22.788-8.693-31.482,0L6.52,262.518c-8.693,8.693-8.693,22.788,0,31.482 c8.693,8.693,22.788,8.693,31.482,0l28.978-28.978c4.736,118.767,102.482,213.585,222.411,213.585 C412.344,478.606,512,378.951,512,255.998C512,255.381,511.968,254.773,511.919,254.169z"></path>
         <path d="M399.48,116.004c10.396,8.17,19.857,17.497,28.138,27.693c4.385,5.409,10.819,8.237,17.297,8.237 c4.92,0,9.862-1.625,14.002-4.986c9.55-7.724,11.019-21.749,3.25-31.299c-10.351-12.778-22.194-24.42-35.172-34.638 c-9.683-7.613-23.663-5.944-31.254,3.74C388.149,94.411,389.797,108.39,399.48,116.004z"></path> <path d="M289.4,77.915c13.245,0,26.468,1.447,39.246,4.341c1.67,0.356,3.295,0.534,4.92,0.534 c10.173,0,19.367-7.012,21.704-17.363c2.694-11.999-4.831-23.908-16.829-26.624c-16.006-3.584-32.501-5.409-49.041-5.409 c-12.31,0-22.261,9.973-22.261,22.261S277.09,77.915,289.4,77.915z"></path>
          </g> </g> </g> </g></svg>
);

export default UndoIcon;
