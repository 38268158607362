import React from 'react';

const SortingIcon = ({ size = 14, rotate = 'down' }) => {
  const getRotateDeg = (val) => {
    switch (val) {
      case 'left':
        return '90';
      case 'up':
        return '180';
      case 'right':
        return '270';
      default:
        return '0';
    }
  };
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 17 16"
      fill="none"
      style={{ transform: `rotate(${getRotateDeg(rotate)}deg)` }}
    >
      <path
        d="M12.1467 5.45334H7.99336H4.25336C3.61336 5.45334 3.29336 6.22667 3.74669 6.68001L7.20003 10.1333C7.75336 10.6867 8.65336 10.6867 9.20669 10.1333L10.52 8.82L12.66 6.68001C13.1067 6.22667 12.7867 5.45334 12.1467 5.45334Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default SortingIcon;
