import { apiSlice } from '../../app/services/apiSlice';

const PREFIX = '/backoffice';

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllCompanyCampaigns: builder.query({
      query: (data) => ({
        url: `${PREFIX}/companies/${data?.id}/campaigns`,
        params: data?.params,
      }),
    }),
    getAllCampaigns: builder.query({
      query: (data) => ({
        url: `${PREFIX}/jobs/campaigns`,
        params: data?.params,
      }),
    }),

    getPostListing: builder.query({
      query: (data) => ({
        url: `${PREFIX}/jobs/campaigns/${data?.id}/job-postings`,
        params: data?.params,
      }),
    }),
    getPostingDetails: builder.query({
      query: (params) => ({
          url: `${PREFIX}/jobs/job-posting/${params?.postingId}`,
          params: params?.params,
      }),
  }),
  getJobBoardSummary: builder.query({
    query: (params) => ({
        url: `${PREFIX}/jobs/job-board-posting-summary/${params?.refId}`,
        // params: params,
    }),
}),
    getCampaignDetails: builder.query({
      query: (data) => ({
        url: `${PREFIX}/jobs/campaigns/${data?.id}`,
        params: data?.params,
      }),
    }),
    manualChannelAdvertise: builder.mutation({
      query: (data) => ({
        url: `${PREFIX}/jobs/postings/${data?.id}/advertise/manual`,
      }),
    }),
    markChannelAdvertise: builder.mutation({
      query: (data) => ({
        url: `${PREFIX}/jobs/postings/${data?.id}/mark-advertised`,
      }),
    }),

    cancelRequestPosting: builder.mutation({
      query: (data) => ({
        url: `${PREFIX}/jobs/postings/${data?.id}/cancel`,
      }),
    }),

    checkBroadbeanStatus: builder.mutation({
      query: (id) => ({
        url: `${PREFIX}/jobs/postings/${id}/broadbean-status`,
      }),
    }),

    getCompanyJobs: builder.mutation({
      query: (params) => ({
        url: `${PREFIX}/jobs`,
        params:{...params,sort:'title',sortDir:'ASC'},
      }),
      transformResponse: (responseData) => {
        responseData.options = responseData?.data?.map((option) => ({
          label: option.title,
          value: option.id,
        }));
        return responseData || {};
      },
    }),

    getCampaignOccurrence: builder.query({
      query: (data) => ({
        url: `${PREFIX}/jobs/campaigns/${data?.id}/occurrence`,
      }),
    }),

    getCampaignPostingDetails: builder.query({
      query: (params) => ({
        url: `${PREFIX}/jobs/campaigns/job-postings/per-occurrence`,
        params,
      }),
    }),
    advertPosting: builder.mutation({
      query: (data) => ({
        url: `${PREFIX}/jobs/job-board-posting`,
        method: 'POST',
        body: {
          ...data.payload,
        },
      }),
    }),
    advertRePosting: builder.mutation({
      query: (data) => ({
        url: `${PREFIX}/jobs/job-board-re-posting`,
        method: 'PUT',
        body: {
          ...data.payload,
        },
      }),
    }),
    closeCampaign: builder.mutation({
      query: (data) => ({
        url: `${PREFIX}/jobs/campaigns/${data?.id}/closed`,
        method: 'POST',
      }),
    }),
  }),
});

export const {
  useGetAllCampaignsQuery,
  useGetPostListingQuery,
  useGetCampaignDetailsQuery,
  useGetAllCompanyCampaignsQuery,
  useGetPostingDetailsQuery,
  useManualChannelAdvertiseMutation,
  useAdvertPostingMutation,
  useAdvertRePostingMutation,
  useGetJobBoardSummaryQuery,
  useMarkChannelAdvertiseMutation,
  useCancelRequestPostingMutation,
  useCheckBroadbeanStatusMutation,
  useGetCompanyJobsMutation,
  useGetCampaignOccurrenceQuery,
  useGetCampaignPostingDetailsQuery,
  useCloseCampaignMutation,
} = extendedApiSlice;
