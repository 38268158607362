export const CURRENCY = {
    GBP:'£',
    USD:'$',
    EUR:'€',
    AED:'Dh',
    AUD:'$',
    INR:'₹',
    MYR:'MYR',
    ZAR:'ZAR',

}