import React from 'react';

const PenNibStarIcon = ({ size = 20, rotate = 'down', color = "#292D32", ...props }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M10.7493 22.5H13.2693C14.2293 22.5 14.8493 21.82 14.6693 20.99L14.2594 19.1801H9.75935L9.34935 20.99C9.16935 21.77 9.84935 22.5 10.7493 22.5Z" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M14.2605 19.1701L15.9905 17.63C16.9605 16.77 17.0005 16.17 16.2305 15.2L13.1805 11.33C12.5405 10.52 11.4905 10.52 10.8505 11.33L7.80054 15.2C7.03054 16.17 7.03054 16.8 8.04054 17.63L9.77054 19.1701" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12.0098 11.12V13.65" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M11.1501 5.19002L10.3701 4.41C9.90008 3.94 9.90008 3.18004 10.3701 2.71004L11.1501 1.93001C11.6201 1.46001 12.3801 1.46001 12.8501 1.93001L13.6301 2.71004C14.1001 3.18004 14.1001 3.94 13.6301 4.41L12.8501 5.19002C12.3801 5.66002 11.6201 5.66002 11.1501 5.19002Z" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M19.45 9.81H20.55C21.21 9.81 21.75 10.35 21.75 11.01V12.11C21.75 12.77 21.21 13.3101 20.55 13.3101H19.45C18.79 13.3101 18.25 12.77 18.25 12.11V11.01C18.25 10.35 18.79 9.81 19.45 9.81Z" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M4.55 9.81H3.45C2.79 9.81 2.25 10.35 2.25 11.01V12.11C2.25 12.77 2.79 13.3101 3.45 13.3101H4.55C5.21 13.3101 5.75 12.77 5.75 12.11V11.01C5.75 10.35 5.21 9.81 4.55 9.81Z" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M18.5402 10.1L13.2402 4.80005" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5.45898 10.1L10.759 4.80005" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
};

export default PenNibStarIcon;
