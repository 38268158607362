import React from 'react';

const NewDownloadIcon = ({ size = 16}, props) => (
  <svg width={size} height={size} viewBox="0 0 16 16" {...props} fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M14 10V12.6667C14 13.0203 13.8595 13.3594 13.6095 13.6095C13.3594 13.8595 13.0203 14 12.6667 14H3.33333C2.97971 14 2.64057 13.8595 2.39052 13.6095C2.14048 13.3594 2 13.0203 2 12.6667V10" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M4.66699 6.66663L8.00033 9.99996L11.3337 6.66663" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M8 10V2" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
  
);

export default NewDownloadIcon;
