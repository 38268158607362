import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import CustomDropdown from '../../../../app/components/dropdown/CustomDropDown';
import StyledButton from '../../../../app/components/styledComponents/StyledButton';
import StyledColoredTag from '../../../../app/components/styledComponents/StyledColoredTag';
import KeyIcon from '../../../../app/components/svgIcons/KeyIcon';
import OptionFillIcon from '../../../../app/components/svgIcons/OptionFillIcon';
import PriceIcon from '../../../../app/components/svgIcons/PriceIcon';
import ShopBagIcon from '../../../../app/components/svgIcons/ShopBagIcon';
import StagesIcons from '../../../../app/components/svgIcons/StagesIcons';
import { StyledLabel, Wrap } from '../../../../assets/styledComponents/Global';

const BundleTile = ({ bundles, prodId, setAddNewBundle, setBundleIndex, deletebundle, activeTab, viewOnly }) => {

  return (
    <Wrap className={activeTab ? "col-4 bg-white rounded-4" : "col-6"} key={'bundleTile' + bundles?.id}>
      <Wrap className="border rounded-4 p-4 mt-3">
        <Wrap className="d-flex justify-content-between align-items-center">
          {activeTab ? null : <Wrap className="d-flex gap-1">
            <Wrap className="d-flex rounded border gap-1 align-items-center px-2 py-1">
              <StagesIcons size={12} />
              <StyledLabel size="12px" className="text-lightBlack">
                1
              </StyledLabel>
            </Wrap>

            <StyledColoredTag
              bgColorVariant="green"
              $padding="5px 10px"
              variant="green"
              $radius="8px"
            >
              {`${bundles?.bundlePrice} Credit${bundles?.bundlePrice > 1 ? 's' : ''}`}

            </StyledColoredTag>
          </Wrap>}
          {activeTab ? null : <CustomDropdown
            dropdownToggle={<OptionFillIcon rotate="left" />}
            toggleClassName="bg-body p-0 bg-transparent"
            dropdownMenu={
              <>
                <StyledDropDownContainer className="rounded p-0">
                  <StyledButton
                    variant="icon"
                    className="border-0 text-black w-100 justify-content-start"
                    onClick={() => { setAddNewBundle(true); setBundleIndex(bundles) }}
                  >
                    Edit
                  </StyledButton>
                </StyledDropDownContainer>

                <StyledDropDownContainer className="rounded p-0">
                  <StyledButton
                    variant="icon"
                    className="border-0 text-black w-100 justify-content-start"
                    onClick={() => deletebundle(bundles?.id)}
                  >
                    Delete
                  </StyledButton>
                </StyledDropDownContainer>
              </>
            }
          />}
        </Wrap>
        <Wrap className={`d-flex flex-column ${activeTab ? '' : 'mt-3'}`}>
          <Wrap className='d-flex justify-content-between'>
            <StyledLabel size="16px" weight="700">
              {bundles?.title || ''}
            </StyledLabel>
            {activeTab && <CustomDropdown
              dropdownToggle={<OptionFillIcon rotate="left" />}
              toggleClassName="bg-body p-0 bg-transparent"
              dropdownMenu={
                <>
                  <StyledDropDownContainer className={viewOnly ? "feature-pending rounded p-0" : "rounded p-0"}>
                    <StyledButton
                      variant="icon"
                      className="border-0 text-black w-100 justify-content-start"
                      onClick={() => { setAddNewBundle(true); setBundleIndex(bundles) }}
                    >
                      Edit
                    </StyledButton>
                  </StyledDropDownContainer>
                  <StyledDropDownContainer className={viewOnly ? "rounded p-0" : "feature-pending rounded p-0"}>
                    <StyledButton
                      variant="icon"
                      className="border-0 text-black w-100 justify-content-start"
                    // onClick={() => { setAddNewBundle(true); setBundleIndex(bundles) }}
                    >
                      <Link
                        variant="text"
                        className="text-decoration-none px-2"
                        to={`/products/${prodId}/bundles`}
                      >
                        Edit
                      </Link>

                    </StyledButton>
                  </StyledDropDownContainer>
                  <StyledDropDownContainer className={viewOnly ? "feature-pending rounded p-0" : "rounded p-0"}>
                    <StyledButton
                      variant="icon"
                      className="border-0 text-black w-100 justify-content-start"
                      onClick={() => deletebundle(bundles?.id)}
                    >
                      Delete
                    </StyledButton>
                  </StyledDropDownContainer>
                </>
              }
            />}
          </Wrap>
          <StyledLabel
            size="12px"
            weight="500"
            className="text-lightBlack mt-1"
          >
           {bundles?.description || ''}
          </StyledLabel>
        </Wrap>

        <Wrap className="d-flex justify-content-between mt-3 align-items-center text-lightBlack">
          <Wrap className="d-flex gap-1">
            <KeyIcon />
            <StyledLabel size="12px" className="text-lightBlack">
              Access key
            </StyledLabel>
          </Wrap>
          <StyledLabel size="14px" className="text-primary">
          {bundles?.accessKey || '-'}
          </StyledLabel>
        </Wrap>
        <Wrap className={`d-flex justify-content-between ${activeTab ? '' : 'border-bottom'} pb-3 mt-2 text-lightBlack`}>
          <Wrap className="d-flex gap-1">
            <ShopBagIcon />
            <StyledLabel size="12px" className="text-lightBlack">
              Quantity:
            </StyledLabel>
          </Wrap>
          <StyledLabel size="14px" className="text-primary">
            {bundles?.quantity || '0'}
          </StyledLabel>
        </Wrap>

        {activeTab && <Wrap className="d-flex justify-content-between border-bottom pb-3 text-lightBlack">
          <Wrap className="d-flex gap-1 align-items-center">
            <PriceIcon />
            <StyledLabel size="12px" className="text-lightBlack">
              Price:
            </StyledLabel>
          </Wrap>
          <StyledColoredTag
            $marginX="0px"
            $padding="5px 10px"
            variant="orange"
            $radius="8px"
          >
            {bundles?.bundlePrice || '0'} {bundles?.bundlePrice > 1 ? 'Credits' : 'Credit'}
           </StyledColoredTag>
        </Wrap>}

        <Wrap className="d-flex flex-column pt-3">
          <StyledLabel size="12px" className="text-lightBlack">
            Channels
          </StyledLabel>
          <StyledLabel size="14px" className="text-darkGray">
          {bundles?.Channels || '-'}
          </StyledLabel>
        </Wrap>
      </Wrap>
    </Wrap>
  );
};

const StyledDropDownContainer = styled.div`
  width: 226px;
  padding: 15px 24px;

  &:hover {
    background-color: #fafafa;
  }
`;

export default BundleTile;
