import React from 'react';
import { ErrorMessage, Field } from 'formik';
import Proptypes from 'prop-types';
import { formErrorMessage } from './FormErrorMessage';
import AsyncSelectMulti from '../select/AsyncSelectMulti';

const FormikAsyncSelectMulti = ({ name, disabled, ...props }) => (
  <Field name={name}>
    {({ field: { value }, form: { setFieldValue, touched, errors } }) => (
      <>
        <AsyncSelectMulti
          value={value}
          onSelect={(values) => {
            setFieldValue(name, values);
          }}
          isDisabled={disabled}
          isInvalid={touched[name] && errors[name]}
          {...props}
        />
        <ErrorMessage name={name} render={formErrorMessage} />
      </>
    )}
  </Field>
);

FormikAsyncSelectMulti.propTypes = {
  name: Proptypes.string.isRequired,
  type: Proptypes.string.isRequired,
  placeholder: Proptypes.string,
  disabled: Proptypes.bool,
};

export default React.memo(FormikAsyncSelectMulti);
