import React from 'react';
import Table from '../../../app/components/table/Table';

const LicenseListingTable = ({
  license,
  sortColumn,
  onSort,
  tableRef,
  filterTable,
}) => {
  return (
    <Table
      data={license}
      columns={filterTable}
      sortColumn={sortColumn}
      onSort={onSort}
      tableRef={tableRef}
    />
  );
};

export default LicenseListingTable;
