import { isArray, isEmpty, isNumber, isString, startCase } from 'lodash';
import { useLocation } from 'react-router-dom';
import { CURRENCY } from './currency';
import { STATUSES } from './Statuses';
import CompanyAddedIcon from '../features/Onboarding/listing/OnBoardingIcons/CompanyAddIcon';
import SelfGuideSatrtIcon from '../features/Onboarding/listing/OnBoardingIcons/SelfGuideStartIcon';
import AdminSignIcon from '../features/Onboarding/listing/OnBoardingIcons/AdminSignIcon';
import UsersAddedcon from '../features/Onboarding/listing/OnBoardingIcons/UsersAddedIcon';
import SelfGuidedIcon from '../features/Onboarding/listing/OnBoardingIcons/SelfGuidedIcon';
import FirstJobIcon from '../features/Onboarding/listing/OnBoardingIcons/FirstJobIcon';
import FirstJobPubIcon from '../features/Onboarding/listing/OnBoardingIcons/FirstJobPubIcon';
import FirstAppIcon from '../features/Onboarding/listing/OnBoardingIcons/FirstAppIcon';
import FirstHireBackIcon from '../features/Onboarding/listing/OnBoardingIcons/FirstHireIcon';
import ContractSigIcon from '../features/Onboarding/listing/OnBoardingIcons/ContractSigIcon';
import moment from 'moment';
import { useEffect, useState } from 'react';
export const isValidEmail = (value) =>
  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value);

export const getCurrencySign = (name = 'GBP', obj = CURRENCY) => obj[name];
export const getAdvertRedirectUrl = (value, id) =>
  value
    ? `/p/job/${value
        ?.trim()
        ?.replace(/[^\w\s]/gi, '')
        ?.replace(/ /g, '-')}-jp-${id}`
    : '';

export const handleErrorImg = async (e) => {
  e.target.onerror = null;
  e.target.src =
    'https://smartrecroot-dev.s3.eu-west-1.amazonaws.com/companies/2/images/e53008b7-8717-4755-bd61-d1f7695bdf57.blob?AWSAccessKeyId=AKIAJSW6IXMOAKKBKX5Q&Expires=1666379557&Signature=XPO1kKsx8HVtuv6MUo9igO6%2FUfE%3D';
};
export const phoneRegExp =
  //  /^\+(?:[0-9]●?){6,14}[0-9]$/;
  // /\\+?\(?([1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  /\+?\(?([0-9]{3})\)?[-.]?\(?([0-9]{3})\)?[-.]?\(?([0-9]{4})\)?/;

export const DurationFriendly = (no, flag) => {
  if (flag === true) {
    return ` ${no} days`;
  } else {
    switch (no) {
      case 365:
        return ' /Annum';
      case 30:
        return ' /Monthly';
      case 31:
        return ' /Monthly';
      case 120:
        return ' /Quaterly';
      case 7:
        return ' /Weekly';
      case no > 30:
        return ` ${no} days`;
      default:
        return ` days`;
    }
  }
};

export const uuidv4 = () =>
  ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16),
  );

export const getActiveTabId = (tabs) => tabs?.find((t) => t?.active)?.id;
export const handleChangeTab = (tabs, clicked) =>
  tabs?.map((p) => {
    return p.id === clicked
      ? {
          ...p,
          active: true,
        }
      : {
          ...p,
          active: false,
        };
  });
export const copyText = (text = 'Text Copied') =>
  navigator.clipboard.writeText(text);

export const getStatusFriendly = (statusId = 1, obj = STATUSES) =>
  capitalizeFirstLetter(
    startCase(Object.keys(obj).find((key) => obj[key] === statusId)),
  );
export const capitalizeFirstLetter = (string) =>
  string?.length > 0
    ? string?.charAt(0)?.toUpperCase() + string?.slice(1)?.toLowerCase()
    : '';

export const getPreviewURL = (url) => {
      const msDocs = ['doc', 'docx', 'ppt', 'pptx'];
      const pdfDocs = ['pdf', 'PDF'];
      const reg = url?.match(/\.([^\\./?]+)($|\?)/);
      const isMsg = reg && reg?.length >= 2 && msDocs?.indexOf(reg[1]) !== -1;
      const isPdf = pdfDocs?.indexOf(isArray(reg) && reg[1]) !== -1;
      return isMsg
        ? `https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(
          url,
        )}&wdStartOn=1&wdEmbedCode=0&wdPrint=1`
        : isPdf ? url : `https://docs.google.com/viewer?url=${encodeURIComponent(
          url,
        )}&embedded=true&hgd=1`;
    };

export const getFileNameByFileDescriptor = (name) => {
  const myRe = new RegExp('(?=\\w+\\.\\w{3,4}$).+', 'g');
  return myRe.exec(name);
};
export const domainSelect = () => {
  const hostname = window.location.hostname?.split(':')[0];
  const domain = hostname?.split('.').slice(-3).join('.');
  return domain;
};
export const getAppEnvironment = () => {
  const env = process.env.REACT_APP_ENVIRONMENT;

  if (env === 'production') {
    return {
      IsDevelopment: false,
      IsProduction: true,
    };
  } else {
    return {
      IsDevelopment: true,
      IsProduction: false,
    };
  }
};

export const useQuery = () => new URLSearchParams(useLocation().search);

//Forms and dynamic fields control types
export const ControlTypes = {
  SECTION: 'section',
  HEADING: 'heading',
  SUB_HEADING: 'sub_heading',
  TEXT_INPUT: 'text',
  NUMBER_INPUT: 'number',
  PARAGRAPH: 'description',
  TEXTAREA: 'textarea',
  RADIO: 'radio',
  CHECKBOX: 'checkbox',
  DROPDOWN: 'select',
  EMAIL: 'email',
  DATE: 'date',
  FILE_UPLOAD: 'file',
  EDUCATION: 'education',
  EXPERIENCE: 'experience',
  COUNTRY: 'country',
  REFEREE: 'referee',
};

export const statusesColors = [
  {
    statusId: STATUSES.PENDING,
    color: 'darkOrange',
  },
  {
    statusId: STATUSES.LIVE,
    color: 'green',
  },
  {
    statusId: STATUSES.SCHEDULED,
    color: 'primary',
  },
  {
    statusId: STATUSES.FAILED,
    color: 'defaultImage',
  },
  {
    statusId: STATUSES.CLOSED,
    color: 'danger',
  },
  {
    statusId: STATUSES.DRAFT,
    color: 'textColor',
  },
  {
    statusId: STATUSES.PARTIALLY_LIVE,
    color: 'tagBlueColor',
  },

  {
    statusId: STATUSES.POSTED,
    color: 'DarkGreen',
  },

  {
    statusId: STATUSES.BEING_POSTED,
    color: 'tagBlueColor',
  },

  {
    statusId: STATUSES.REVIEW,
    color: 'primary',
  },

  {
    statusId: STATUSES.BEING_POSTED,
    color: 'tagBlueColor',
  },

  {
    statusId: STATUSES.CANCELED,
    color: 'defaultImage',
  },
  {
    statusId: STATUSES.FAILED,
    color: 'danger',
  },
];

export const getStatusColor = (id) =>
  statusesColors?.find((c) => c?.statusId === id)?.color || '#000';

export const stripHtmlTags = (html) =>
  isString(html) ? html?.replace(/<[^>]*>/g, '') : html;

export const renderHtml = (text) => {
  return (
    <div className="rendered-html" dangerouslySetInnerHTML={{ __html: text }} />
  );
};

export const RenderHTMLString = (htmlString, size) => {
  const componentMap = {
    ContractSigned: ContractSigIcon,
    CompanyAdded: CompanyAddedIcon,
    AdminSignUp: AdminSignIcon,
    SelfGuidedStarted: SelfGuideSatrtIcon,
    UsersAdded: UsersAddedcon,
    SelfGuidedComplete: SelfGuidedIcon,
    FirstJobCreated: FirstJobIcon,
    FirstJobPublished: FirstJobPubIcon,
    FirstApplicant: FirstAppIcon,
    FirstHire: FirstHireBackIcon,
  };

  const DynamicComponent = componentMap[htmlString];

  return <>{htmlString && <DynamicComponent size={size || 12} />}</>;
};
export const convertToShortForm = (number) => {
  if (number >= 1000) {
    return (number / 1000).toFixed(1) + 'k';
  } else {
    return number.toString();
  }
};

export const getGraphLabels = (maxDate, minDate, groupedBy) => {
  let endDate = moment(maxDate);
  let startDate = moment(minDate);
  let labels = [];
  switch (groupedBy) {
    case 'daily':
      let currentsDate = startDate.clone();
      while (currentsDate.isSameOrBefore(endDate)) {
        labels.push({
          name: currentsDate.format('YYYY/MM/DD'),
          value: currentsDate.format('YYYY-MM-DD'),
        });
        currentsDate.add(1, 'day');
      }
      break;
    case 'weekly':
      let currentWeekStart = startDate.clone().startOf('week');
      let currentWeekEnd = startDate.clone().endOf('week');
      while (currentWeekEnd.isSameOrBefore(endDate)) {
        labels.push({
          name: `Week ${currentWeekStart.week()}`,
          value: currentWeekStart.week(),
        });
        currentWeekStart.add(1, 'week');
        currentWeekEnd.add(1, 'week');
      }
      break;
    default:
      let currentDate = startDate.clone();
      do {
        labels.push({
          name: currentDate.format('MMM'),
          value: currentDate.format('M'),
          year: currentDate.format('Y'),
        });
        currentDate.add(1, 'month').date(1);
      } while (currentDate.isSameOrBefore(endDate));
      break;
  }
  return labels;
};
export const transformObjectToArray = (obj) => {
  return Object.keys(obj).map(key => ({ [key]: obj[key] }));
};

export function useWindowSize() {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 1500);

  function debounce(func, delay) {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func(...args);
      }, delay);
    };
  }
  useEffect(() => {
    const handleResize = debounce(() =>
      setIsSmallScreen(window.innerWidth < 1500),
    );
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return isSmallScreen;
}


export const formatter = new Intl.NumberFormat('en-US', {
  notation: 'compact',
  compactDisplay: 'short',
  // maximumSignificantDigits: 2,
});

export const formatCount = (inputString) => {
  const numberMatch =
    inputString?.length > 0 ? inputString?.match(/(\d+(\.\d+)?)/) : inputString;
  if (numberMatch?.length > 0) {
    const extractedNumber = parseFloat(numberMatch[0]);
    const formattedNumber = extractedNumber.toLocaleString();
    const formattedString = inputString.replace(
      numberMatch[0],
      formattedNumber,
    );

    return formattedString; // Output: "1,234,567.89"
  } else {
    return inputString; // No number found, just use the original string
  }
};


export const formateNumber = (number) => number ? Number.isInteger(number) ? number.toString() : !isNaN(number) && parseFloat(number)?.toFixed(2) : "";




export const checkValue = (value) =>  value ? isNumber(value) && !isNaN(value) && isEmpty(value)  ? Number.isInteger(value) ? Number(value) : value.toFixed(2) :0 : 0;


export const valueToLocaleString= (value) => value ? Number(value).toLocaleString('en-US') : 0;


