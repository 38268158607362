import React from 'react';

const ShopBagIcon = ({ size = 16 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.6663 5.83337C10.393 5.83337 10.1663 5.60671 10.1663 5.33337V3.00004C10.1663 2.28004 9.71967 1.83337 8.99967 1.83337H6.99967C6.27967 1.83337 5.83301 2.28004 5.83301 3.00004V5.33337C5.83301 5.60671 5.60634 5.83337 5.33301 5.83337C5.05967 5.83337 4.83301 5.60671 4.83301 5.33337V3.00004C4.83301 1.72671 5.72634 0.833374 6.99967 0.833374H8.99967C10.273 0.833374 11.1663 1.72671 11.1663 3.00004V5.33337C11.1663 5.60671 10.9397 5.83337 10.6663 5.83337Z"
        fill="currentColor"
        fillOpacity="0.7"
      />
      <path
        d="M5.3329 11.8534C5.05957 11.8534 4.8329 11.6267 4.8329 11.3534C4.8329 11.0734 5.05957 10.8534 5.3329 10.8534H13.1729C13.3729 10.8534 13.5262 10.68 13.5062 10.48L13.0529 6.68671C12.8929 5.39337 12.6662 4.33337 10.3996 4.33337H5.59957C3.3329 4.33337 3.10624 5.39337 2.9529 6.68671L2.3529 11.6867C2.15957 13.3267 2.66624 14.6667 5.00624 14.6667H10.9929C13.0996 14.6667 13.7196 13.58 13.6862 12.1667C13.6796 11.9867 13.5329 11.8534 13.3529 11.8534H5.3329Z"
        fill="currentColor"
        fillOpacity="0.7"
      />
    </svg>
  );
};

export default ShopBagIcon;
