import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import StyledButton from '../../app/components/styledComponents/StyledButton';
import styled from 'styled-components';
import QuestionTile from './listing/QuestionTile';
import { useGetOnBoardingDetailsQuery ,useGetSetupOnBoardingDetailsQuery} from '../company/companyApiSlice';

const OnBoardingDetailsModal = ({ show,data, editData, onHide, title, id, refetch,companyId,all }) => {
  const { data: detailsRes, isFetching } = useGetOnBoardingDetailsQuery({
id:companyId?.companyId
  },{skip:!companyId?.companyId});
  const { data: setupguide,isFetching:setupFetch } = useGetSetupOnBoardingDetailsQuery({
    compId:companyId?.companyId
      },{skip:!companyId?.companyId});
   
     const [total,setTotal] = useState(0)

  return (
    <Modal show={show} centered onHide={onHide} size={'lg'}>
      
            <Modal.Header
              closeButton
              className="border-bottom-0 pb-0 align-items-baseline"
            >
              <Modal.Title
                as="h4"
                className="flex-grow-1 p-2 cursor-pointer flex-center"
              >
                <div className='d-flex flex-column'>
                   <h3> {title}</h3>
                    <Styleddiv>{total || 0}/10 Completed</Styleddiv>
                </div>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="d-flex  flex-column bg-body rounded">
            
                
              
                   <QuestionTile Information={data} details={detailsRes} companyId={companyId} setupguide={setupguide} total={total} setTotal={setTotal} setupFetch={setupFetch} isFetching={isFetching}/>
                  
                 
             
             
              <div className="d-flex justify-content-end mt-3">
                <div className="d-flex gap-3">
                  <StyledButton
                    variant={'primary'}
                    className="mb-2 border-0 fw-bold"
                    onClick={onHide}
                  >
                    Close
                  </StyledButton>
                </div>
              </div>
            </Modal.Body>
      
    </Modal>
  );
};
const Styleddiv = styled.div`
color: ${({ theme }) => theme.inputColor};
font-size: 14px;
// font-family: QuickSand;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 142.857% */
letter-spacing: 0.28px;
`;
export default OnBoardingDetailsModal;
