import { Form, Formik } from 'formik';
import React from 'react';
import { useState } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import StyledButton from '../../../../app/components/styledComponents/StyledButton';
import ArrowIcon from '../../../../app/components/svgIcons/ArrowIcon';
import { Wrap } from '../../../../assets/styledComponents/Global';
// import { bundleFormSchema, BundleFormValues } from '../../assets/utills';
// import BundleForm from './BundleForm';
import BundlesPortlet from './BundlesPortlet';

const BundleContainer = () => {
  const { handleSubmit, initialData } = useOutletContext();
  const [addNewBundle, setAddNewBundle] = useState(false);
  const [bundleIndex, setBundleIndex] = useState();
  const [addExistingbundle, setAddExistingBundle] = useState(false);

  const navigate = useNavigate();
  const { productId } = useParams();

  return (
    <Formik
      initialValues={{ ...bundleIndex }}
      enableReinitialize
      // validationSchema={bundleFormSchema}
      onSubmit={(values) => handleSubmit(values)}
    >
      {({ handleSubmit, resetForm }) => (
        <Form onSubmit={handleSubmit}>
          {/* {initialData?.productPackages?.length === 0 ? (
            <BundleForm />
          ) : ( */}
          <BundlesPortlet
            bundles={initialData?.productPackages}
            handleSubmit={handleSubmit}
            addNewBundle={addNewBundle}
            setAddNewBundle={setAddNewBundle}
            addExistingbundle={addExistingbundle}
            setAddExistingBundle={setAddExistingBundle}
            resetForm={resetForm}
            setBundleIndex={setBundleIndex}
            // deletebundle={deletebundle}
            bundleIndex={bundleIndex}
          />

          <Wrap className="d-flex justify-content-between px-1">
            <StyledButton
              className=" mt-4"
              variant={'white'}
              icon={<ArrowIcon rotate="left" />}
              onClick={() =>
                navigate(
                  !productId
                    ? '/products/creation'
                    : `/products/${productId}/creation`,
                )
              }
            >
              Back
            </StyledButton>
            <StyledButton className="mt-4" onClick={() => handleSubmit()}>
              Save
            </StyledButton>
          </Wrap>
          {/* )} */}
        </Form>
      )}
    </Formik>
  );
};

export default BundleContainer;
