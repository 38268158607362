import React, { useState } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import ProtectedRoute from './app/ProtectedRoute';
import { Login } from './features/auth/Login';
import ComponentsList from './features/componentsLibrary/ComponentsList';
import CompanyRoutes from './features/company/CompanyRoutes';
import StyledToastContainer from './app/components/styledComponents/StyledToastContainer';
import { Logout } from './features/auth/Logout';
// import DashboardRoutes from './features/dashboard/dashboardRoutes';
import LicensesRoutes from './features/Licenses/LicensesRoutes';
import ProductRoutes from './features/Products/ProductRoutes';
import SettingRoutes from './features/Setting/SettingRoutes';
import UsersRoutes from './features/Users/UsersRoutes';
import OutlookComponent from './features/auth/OutlookComponent';
import Helmet from 'react-helmet';
import { routesWithMeta } from './utils/meta';
import { Container } from 'react-bootstrap';
import CampaignRoutes from './features/Campaigns/CampaignRoutes';
// import JobListingRoutes from './features/JobListing/jobsListingRoutes';
import { capitalize, find } from 'lodash';
import CopyWritingRoutes from './features/Copywriting/components/CopyWritingRoutes';
import OnboardingRoutes from './features/Onboarding/listing/OnboardingRoutes';
import AnalyticsRoutes from './features/analytics/AnalyticsRoutes';
import { useWindowSize } from './utils/helper';
import ProductAdoptionRoutes from './features/ProductAdoption/ProductAdoptionRoutes';

function App() {
  const [pageTitle, setPageTitle] = useState('');
  const [pageDescription, setPageDescription] = useState('');
  const location = useLocation();
  React.useEffect(() => {
    const activeRoute = find(routesWithMeta, { path: location?.pathname });
    if (activeRoute) {
      setPageTitle(capitalize(activeRoute?.meta?.title) + ' - occy ADMIN');
      setPageDescription(activeRoute?.meta?.description);
    } else {
      setPageTitle(
        capitalize(location?.pathname.split('/').pop()) + ' - occy ADMIN',
      );
      setPageDescription('');
    }
  }, [location]);
  const isSmallScreen = useWindowSize();
  return (
    <Container fluid className={`${isSmallScreen && 'p-0'} bg-primary`}>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
      </Helmet>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route
          path="backoffice/admin/callback"
          element={<OutlookComponent />}
        />
        <Route path="/logout" element={<Logout />} />
        <Route path="/components" element={<ComponentsList />} />
        <Route element={<ProtectedRoute />}>
          {/* <Route path="/dashboard/*" element={<DashboardRoutes />} /> */}
          {/*<Route path="/job-posts/*" element={<JobRoutes />} />*/}
          {/* <Route path="/job-listing/*" element={<JobListingRoutes />} /> */}
          <Route path="/copywriting/*" element={<CopyWritingRoutes />} />
          <Route path="/companies/*" element={<CompanyRoutes />} />
          <Route path="/products/*" element={<ProductRoutes />} />
          <Route path="/licences/*" element={<LicensesRoutes />} />
          <Route path="/analytics/*" element={<AnalyticsRoutes />} />
          <Route path="/product/adoption/*" element={<ProductAdoptionRoutes />} />
          <Route path="/onboarding/*" element={<OnboardingRoutes />} />
          <Route path="/campaigns/*" element={<CampaignRoutes />} />
          <Route path="/users/*" element={<UsersRoutes />} />
          <Route path="/settings/*" element={<SettingRoutes />} />
        </Route>
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
      <StyledToastContainer
        position="top-center"
        autoClose={1500}
        hideProgressBar
        newestOnTop={false}
        closeOnClick={true}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </Container>
  );
}

export default App;
