import React from 'react';

const PublishIcon = ({ size = 18 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 20 20"
  >
    <path
      d="M10.5557 9.44441L15.1112 4.88885"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M15.5558 7.11106V4.4444H12.8892"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M9.44482 4.4444H8.33371C5.55594 4.4444 4.44482 5.55551 4.44482 8.33329V11.6666C4.44482 14.4444 5.55594 15.5555 8.33371 15.5555H11.667C14.4448 15.5555 15.5559 14.4444 15.5559 11.6666V10.5555"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </svg>
);

export default PublishIcon;
