import React from 'react';
import { useState } from 'react';
import styled from 'styled-components';

const LicenseSideBar = ({ onTabChange }) => {
  const [tab, setTab] = useState(1);
  const getTab = (number) => {
    setTab(number);
  };
  const sidebar = [
    {
      title: 'Current Licence',
      number: 1,
      to: 'current',
    },
    {
      title: 'New Licence',
      number: 2,
      to: 'new',
    },
  ];

  return (
    <StyledSideBar className={'bg-white mt-2 '}>
      <div className="__sidebar p-2 p-lg-4">
        {sidebar.map(({ number, title }, i) => (
          <li key={i} className="rounded-1 overflow-hidden mb-1 cursor-pointer">
            <span
              onClick={() => {
                onTabChange(number);
                getTab(number);
              }}
              className={`nav-link single-item flex-center ps-2 p-3
              ${tab === number ? 'active' : ''}
              `}
              id={title.toLowerCase().replace(/\s/g, '-')}
            >
              <h6 className="text-nowrap mb-0 fw-bold">{title}</h6>
            </span>
          </li>
        ))}
      </div>
    </StyledSideBar>
  );
};
const StyledSideBar = styled.div`
  min-width: 220px;
  position: relative;
  .__sidebar {
    position: sticky;
    top: 12vh;
    height: 83vh;
  }

  li {
    list-style-type: none;
  }

  .single-item {
    //border-radius: 5px;
    &.not-allowed {
      cursor: not-allowed;
    }

    &.active {
      background-color: ${({ theme }) => theme.primaryLight};
      border-left: 3px solid ${({ theme }) => theme.primary};
    }
  }
`;
export default LicenseSideBar;
