import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
const StyledToolTip = ({
  children,
  title,
  placement,
  className,
  childrenClass,
  condition,
}) => {
  return (
    <OverlayTrigger
      overlay={<Tooltip className={`${className}` ?? ''}>{title}</Tooltip>}
      placement={placement}
    >
      <span
        className={`d-flex align-items-center cursor-pointer ${
          childrenClass ?? 'ms-2'
        } `}
      >
        {children}
      </span>
    </OverlayTrigger>
  );
};

export default StyledToolTip;
