import { apiSlice } from '../../app/services/apiSlice';

const COMPANY_URL = 'backoffice/companies';
const JOB_URL = '/backoffice/jobs/postings';

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllJobs: builder.query({
      query: (data) => ({
        url: `${COMPANY_URL}/${data?.id}/jobs`,
        params: data.params,
      }),
    }),
    getAllJobPosts: builder.query({
      query: (data) => ({
        url: `${JOB_URL}`,
        params: data.params,
      }),
    }),
    getJobInfo: builder.query({
      query: (data) => ({
        url: `${COMPANY_URL}/${data?.compId}/jobs/${data.jobId}`,
      }),
    }),

    manualAdvertise: builder.mutation({
      query: (id) => ({
        url: `${JOB_URL}/${id}/advertise/manual`,
      }),
    }),
    markJobBoardAdvertise: builder.mutation({
      query: (data) => ({
        url: `${JOB_URL}/mark-advertised`,
        method:'PUT',
        body: data
      }),
    }),
    markAdvertised: builder.mutation({
      query: (id) => ({
        url: `${JOB_URL}/${id}/mark-advertised`,
      }),
    }),

    cancelAdvertise: builder.mutation({
      query: (id) => ({
        url: `${JOB_URL}/${id}/cancel`,
      }),
    }),
  }),
});

export const {
  useGetAllJobsQuery,
  useGetAllJobPostsQuery,
  useGetJobInfoQuery,
  useManualAdvertiseMutation,
  useMarkAdvertisedMutation,
  useCancelAdvertiseMutation,
  useMarkJobBoardAdvertiseMutation,
} = extendedApiSlice;
