import React from 'react';
import styled from 'styled-components';
import FormikAsyncSelect from '../../../app/components/formikComponents/FormikAsyncSelect';
import StyledButton from '../../../app/components/styledComponents/StyledButton';
import { useGetAllCompaniesListMutation } from '../../company/companyApiSlice';
import { copywriteStatus, copywriteType } from '../assets/utils';
import FormikSelect from '../../../app/components/formikComponents/FormikSelect';

const CopyWritingFilterForm = ({ submitForm, loading, values }) => {
    const [getAllCompaniesList] = useGetAllCompaniesListMutation()

    return (
        <>
            <div className="col-12">
                <div className="mb-4">
                    <label htmlFor="companyId" className="form-label">
                        Company
                    </label>
                    <FormikAsyncSelect
                        name="companyId"
                        id="companyId"
                        apiCall={getAllCompaniesList}
                        placeholder="Select Company"
                        getCompanyJobs
                    />
                </div>
            </div>
            <div className="col-12">
                <div className="mb-4">
                    <label htmlFor="type" className="form-label">
                        Type
                    </label>
                    <FormikSelect
                        name="type"
                        id="type"
                        placeholder="Select Type"
                        options={copywriteType}
                    />
                </div>
            </div>
            <div className="col-12">
                <div className="mb-4">
                    <label htmlFor="staus" className="form-label">
                        Status
                    </label>
                    <FormikSelect
                        name="status"
                        id="status"
                        placeholder="Select Status"
                        options={copywriteStatus}
                    />
                </div>
            </div>
            <StyledBtn>
                <StyledButton
                    type="button"
                    onClick={submitForm}
                    className="ms-auto w-100"
                    loading={loading}
                    disabled={loading}
                >
                    Apply Filters
                </StyledButton>
            </StyledBtn>
        </>
    );
};

const StyledBtn = styled.div`
margin-bottom: 4rem !important;
`

export default CopyWritingFilterForm;
