import moment from 'moment';
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import DropdownItem from 'react-bootstrap/esm/DropdownItem';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import CustomDropdown from '../../app/components/dropdown/CustomDropDown';
import PortletBody from '../../app/components/portlet/PortletBody';
import PortletHeader from '../../app/components/portlet/PortletHeader';
import StyledColoredTag from '../../app/components/styledComponents/StyledColoredTag';
import StyledFilterTabs from '../../app/components/styledComponents/StyledFilterTabs';
import StyledIconInput from '../../app/components/styledComponents/StyledIconInput';
import AvatarIcon from '../../app/components/svgIcons/AvatarIcon';
import CalenderFillIcon from '../../app/components/svgIcons/CalenderFillIcon';
import CheckStatusIcon from '../../app/components/svgIcons/CheckStatusIcon';
import JobsIcon from '../../app/components/svgIcons/JobsIcon';
import ManualAdvertiseIcon from '../../app/components/svgIcons/ManualAdvertiseIcon';
import MarkIcon from '../../app/components/svgIcons/MarkIcon';
import OptionFillIcon from '../../app/components/svgIcons/OptionFillIcon';
import RecieptIcon from '../../app/components/svgIcons/RecieptIcon';
import SearchIcon from '../../app/components/svgIcons/SearchIcon';
import SlashCircleCIon from '../../app/components/svgIcons/SlashCircleIcon';
import SortIcon from '../../app/components/svgIcons/SortIcon';
import {
  getStatusFriendly,
  handleChangeTab,
} from '../../utils/helper';
import { STATUSES } from '../../utils/Statuses';

import theme from '../../utils/themes';
import {
  useGetCampaignDetailsQuery,
  useManualChannelAdvertiseMutation,
  useMarkChannelAdvertiseMutation,
} from './CampainApiSlice';

const CampaignDetails = ({
  credits,
  title,
  show,
  onHide,
  loading,
  globalCampaign,
  compId,
  id,
}) => {

  const [manualChannelAdvertise] = useManualChannelAdvertiseMutation();
  const [markChannelAdvertised] = useMarkChannelAdvertiseMutation();


  let apiCall = useGetCampaignDetailsQuery;

  const { data, refetch } = apiCall({
    // params: {
    //   perPage: listing.perPage,
    //   page: listing.page,
    //   query: listing.query && listing.query,
    //   sort: listing.sortedColumn.path || filters.sort,
    //   ...filters,
    // },
    id: id,
  });

  const [tabs, setTabs] = useState([
    {
      id: 1,
      title: `Channels (0)`,
      active: true,
    },
    {
      id: 2,
      title: `Pay Per Clicks (0})`,
      active: false,
    },
  ]);

  const onManualAdvertise = async (campaignId, productId) => {
    const { data } = await manualChannelAdvertise({
      campaignId: campaignId,
      productId: productId,
    });
    if (data?.statusId === STATUSES.POSTED) {
      toast.success(data?.message || 'Job manual advertised successfully');
      refetch();
    } else if (data?.broadbeanUrl) {
      window.open(data?.broadbeanUrl || '', '_blank');
      refetch();
    }
  };

  const onMarkAdvertise = async (campaignId, productId) => {
    const { data } = await markChannelAdvertised({
      campaignId: campaignId,
      productId: productId,
    });
    if (data?.id) {
      toast.success(data?.message || 'Job mark as advertised successfully');
      refetch();
    }
  };

  const onChangeTabs = (tab) => {
    setTabs(handleChangeTab(tabs, tab));
  };

  return (
    <Modal show={show} centered onHide={onHide} size={'lg'}>
      <Modal.Header
        closeButton
        className="d-flex border-bottom-0 pb-0 align-items-baseline"
      >
        <Modal.Title
          as="h3"
          className="flex-grow-0 px-0 cursor-pointer flex-center pt-md-1 pb-md-3"
        >
          {title}
        </Modal.Title>
        <StyledColoredTag className="text-start">
          {getStatusFriendly(data?.data?.statusId)}
        </StyledColoredTag>
      </Modal.Header>
      <Modal.Body className="d-flex  flex-column bg-body rounded">
        <PortletBody className={'p-0 w-100 mb-3'}>
          <div className="p-1 p-lg-2">
            <div className="p-2">
              <StyledLabel
                weight="600"
                size="16px"
                color={theme.headingColor}
                className="mb-3"
              >
                Details
              </StyledLabel>
              <div>
                <div className="d-flex align-items-center mb-2">
                  <StyledColoredTag className="rounded-circle p-1 ms-0">
                    <JobsIcon size="10" className="text-muted" />
                  </StyledColoredTag>{' '}
                  <StyledLabel weight="500" size="14" color={theme.textColor}>
                    {data?.data?.jobTitle}
                  </StyledLabel>
                </div>
                <div className="d-flex align-items-center mb-2">
                  <StyledColoredTag
                    className="rounded-circle p-1 ms-0"
                    variant="orange"
                  >
                    <CalenderFillIcon size="10" className="text-muted" />
                  </StyledColoredTag>{' '}
                  <StyledLabel weight="500" size="14px" color={theme.textColor}>
                    {moment(data?.data?.endDate).format('DD MMM YYYY')}
                  </StyledLabel>
                </div>
                <div className="d-flex align-items-center">
                  <StyledColoredTag className="rounded-circle p-1 ms-0">
                    <RecieptIcon size="10" />
                  </StyledColoredTag>{' '}
                  <StyledLabel weight="500" size="14px" color={theme.textColor}>
                    1200 OCCY CREDIT- 200 GBP
                  </StyledLabel>
                </div>
              </div>
            </div>
          </div>
        </PortletBody>
        <PortletBody className="p-0 w-100">
          <div className="p-1 p-lg-2">
            <div className="p-2">
              <PortletHeader
                containerClassName="p-0 mt-0 mb-2"
                headerClassName="py-0 ps-0 m-0"
                tabs={tabs}
                onChangeTabs={onChangeTabs}
              />
              <div className="d-flex justify-content-between align-items-center mt-4">
                <StyledIconInput
                  $maxWidth="50%"
                  icon={<SearchIcon />}
                  placeholder="Search by Channel or Broadbean ID"
                />
                <StyledFilterTabs>
                  <div className="btn-group border-muted" role="group">
                    <button className="btn p-1">
                      <SortIcon size={14} className="ms-1 me-2" />
                      <StyledLabel
                        weight="600"
                        size="12px"
                        color={theme.textColor}
                        className="me-1"
                      >
                        Sort
                      </StyledLabel>
                    </button>
                  </div>
                </StyledFilterTabs>
              </div>

              <StyledLabel
                weight="600"
                size="16px"
                color={theme.headingColor}
                className="mt-3 mb-2"
              >
                Channels
              </StyledLabel>
              {data?.data?.campaignProduct?.map((product) => (
                <div className="border border-1 border-muted rounded p-2 mb-3">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                      <div className="d-flex">
                        <AvatarIcon className="me-2" />
                      </div>
                      <div className="d-flex flex-column">
                        <StyledLabel
                          weight="600"
                          size="14px"
                          color={theme.textColor}
                        >
                          {product?.title}
                        </StyledLabel>
                        <StyledLabel
                          weight="500"
                          size="12px"
                          color={theme.textColor}
                        >
                          BroadBean ID: {product?.broadBeanAccount?.id}
                        </StyledLabel>
                      </div>
                    </div>
                    <div className="d-flex">
                      <CustomDropdown
                        align="end"
                        toggleClassName={'bg-transparent px-0'}
                        menuClassName={'mt-3'}
                        dropdownToggle={
                          <div
                            className={`account-menu ${'bg-transparent ps-0 ms-2'} `}
                          >
                            <OptionFillIcon rotate={'left'} />
                          </div>
                        }
                        dropdownMenu={
                          <>
                            <DropdownItem
                              className="py-2"
                              onClick={() =>
                                onMarkAdvertise(
                                  product?.CampaignProduct?.jobCampaignId,
                                  product?.CampaignProduct?.productId,
                                )
                              }
                            >
                              <span className="dark-text">
                                <MarkIcon />
                              </span>
                              <span className="ms-3 text-secondary">
                                Mark as advertised
                              </span>
                            </DropdownItem>

                            <DropdownItem
                              className="py-2"
                              onClick={() =>
                                onManualAdvertise(
                                  product?.CampaignProduct?.jobCampaignId,
                                  product?.CampaignProduct?.productId,
                                )
                              }
                            >
                              <span className="dark-text">
                                <ManualAdvertiseIcon />
                              </span>
                              <span className="ms-3 text-secondary">
                                Manual Advertise
                              </span>
                            </DropdownItem>

                            <DropdownItem
                              className="py-2"
                              // onClick={() => onCancelAdvertise(job?.id)}
                            >
                              <span className="dark-text">
                                <SlashCircleCIon />
                              </span>
                              <span className="ms-3 text-secondary">
                                Cancel Request
                              </span>
                            </DropdownItem>

                            <DropdownItem
                              className="py-2"
                              // onClick={() => onCheckBroadbeanStatus(job?.id)}
                            >
                              <span className="dark-text">
                                <CheckStatusIcon />
                              </span>
                              <span className="ms-3 text-secondary">
                                Check Status on Broadbean
                              </span>
                            </DropdownItem>
                          </>
                        }
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </PortletBody>
      </Modal.Body>
    </Modal>
  );
};
export const StyledLabel = styled.label`
  font-size: ${({ size }) => size ?? '14px'};
  font-weight: ${({ weight }) => weight ?? '600'};
  color: ${({ color }) => color ?? 'black'};
`;

export default CampaignDetails;
