import * as Yup from 'yup';
import moment from 'moment';

export const jobFilterValidationSchema = {
  title: Yup.string().required('Required'),
  jobType: Yup.string().required('Required'),
  jobCategory: Yup.object().required('Required'),
  jobRole: Yup.string().required('Required'),
  position: Yup.string().required('Required'),
  location: Yup.object().required('Required'),
  startsAt: Yup.date()
    .default(() => new Date())
    .required('Required'),
  expiresAt: Yup.date('Required').when('startsAt', (startsAt, schema) =>
    moment(startsAt).isValid()
      ? startsAt &&
        schema.min(startsAt, 'Closing date must be later then start date')
      : schema,
  ),
  vacancies: Yup.number().positive().integer('Positions must be whole number'),
  remoteStatus: Yup.string(),
};

export const basicInformationValidationSchema = {
  title: Yup.string()
    .max(63, 'Must be less than or equal to 63 characters')
    .required('Required'),
  jobType: Yup.string(),
  jobCategory: Yup.object().required('Required'),
  jobRole: Yup.string(),
  position: Yup.string().required('Required'),
  location: Yup.object().required('Required'),
  startsAt: Yup.date()
    .default(() => new Date())
    .required('Required'),
  expiresAt: Yup.date('Required').when('startsAt', (startsAt, schema) =>
    moment(startsAt).isValid()
      ? startsAt &&
        schema.min(startsAt, 'Closing date must be later then start date')
      : schema,
  ),
  vacancies: Yup.number()
    .positive()
    .integer('Positions must be whole number')
    .required('Required'),
  remoteStatus: Yup.string(),
};

export const basicInformationInitialState = {
  title: '',
  jobType: undefined,
  jobCategory: '',
  jobRole: undefined,
  position: undefined,
  location: '',
  startsAt: new Date(),
  expiresAt: new Date(),
  vacancies: undefined,
  remoteStatus: undefined,
};

export const otherDetailsValidationSchema = {
  // department: Yup.string().required('Required'),
  // position: Yup.string().required('Required'),
  // keywords: Yup.array().min(1, 'Required'),
  salaryFrom: Yup.number().positive().integer('Salary must be whole number'),
  // salaryPer: Yup.string().required('Required'),
  salaryTo: Yup.number()
    .positive()
    .integer('Salary must be whole number')
    .moreThan(Yup.ref('salaryFrom')),
  salaryCurrency: Yup.string().required('Required'),
  isCompetitive: Yup.boolean(),
};

export const otherDetailsInitialState = {
  // department: '',
  // position: '',
  keywords: [],
  salaryFrom: undefined,
  salaryTo: undefined,
  salaryCurrency: 'GBP',
  salaryPer: undefined,
  isCompetitive: false,
};

export const additionalFieldsValidationSchema = {
  aboutCompany: Yup.string(),
  elevatorPitch: Yup.string(),
  jobDescription: Yup.string().required('Required'),
  jobRequirement: Yup.string().required('Required'),
  benefits: Yup.string(),
  additionalNotes: Yup.string(),
};

export const additionalFieldsInitialState = {
  aboutCompany: undefined,
  elevatorPitch: undefined,
  jobDescription: '',
  jobRequirement: '',
  benefits: undefined,
  additionalNotes: undefined,
};

export const jobTypes = [
  {
    label: 'Permanent',
    value: 'permanent',
  },
  {
    label: 'Contract',
    value: 'contract',
  },
  {
    label: 'Temporary',
    value: 'temporary',
  },
  {
    label: 'Voluntary',
    value: 'voluntary',
  },
  {
    label: 'Casual',
    value: 'casual',
  },
];

export const departmentsValues = [
  {
    label: 'd1',
    value: 'd1',
  },
  {
    label: 'd2',
    value: 'd2',
  },
  {
    label: 'd3',
    value: 'd3',
  },
];

export const positionTypes = [
  {
    label: 'Full Time',
    value: 'full_time',
  },
  {
    label: 'Part Time',
    value: 'part_time',
  },
];

export const salaryTypes = [
  {
    label: 'GBP',
    value: 'GBP',
  },
  {
    label: 'USD',
    value: 'USD',
  },
  {
    label: 'EUR',
    value: 'EUR',
  },
  {
    label: 'AED',
    value: 'AED',
  },
  {
    label: 'AUD',
    value: 'AUD',
  },
  {
    label: 'INR',
    value: 'INR',
  },
  {
    label: 'MYR',
    value: 'MYR',
  },
  {
    label: 'ZAR',
    value: 'ZAR',
  },
];

export const jobPositions = [
  {
    label: 'Position 1',
    value: 'position1',
  },
  {
    label: 'Position 2',
    value: 'position2',
  },
  {
    label: 'Position 6',
    value: 'position6',
  },
  {
    label: 'Position 3',
    value: 'position3',
  },
  {
    label: 'Position 4',
    value: 'position4',
  },
  {
    label: 'Position 5',
    value: 'position5',
  },
];
export const payPerValues = [
  {
    label: 'Annum',
    value: 'Annum',
  },
  {
    label: 'Month',
    value: 'month',
  },
  {
    label: 'Week',
    value: 'week',
  },
  {
    label: 'Day',
    value: 'day',
  },
  {
    label: 'Hour',
    value: 'hour',
  },
  {
    label: 'Pro Rata',
    value: 'pro_rata',
  },
];

export const remoteValues = [
  {
    label: 'Yes',
    value: 1,
  },
  {
    label: 'No',
    value: 2,
  },
];

export const ACCOUNT_ROLE = {
  HIRING_MANAGER: 'hiring manager',
  RECRUITER: 'recruiter',
  APPROVER: 'approver',
  INTERVIEWER: 'interviewer',
};
export const getAddressStringByLocation = (loc, fields = []) =>
  fields
    .map((f) => loc[f])
    .filter((a) => a != null)
    .join(', ')
    .replace(/, *$/, '');

export const currencyType = [
  {
    label: 'GBP',
    value: 'GBP',
  },
  {
    label: 'USD',
    value: 'USD',
  },
  {
    label: 'EUR',
    value: 'EUR',
  },
  {
    label: 'AED',
    value: 'AED',
  },
  {
    label: 'AUD',
    value: 'AUD',
  },
  {
    label: 'INR',
    value: 'INR',
  },
  {
    label: 'MYR',
    value: 'MYR',
  },
  {
    label: 'ZAR',
    value: 'ZAR',
  },
];

export const AddMoreInitialState = {
  occyCredits: '',
  totalCost: '',
  currency: 'GBP',
  invoiceId: '',
  comment: '',
};

export const CurrencyInitialState = {
  currencyQuantity: '',
  selectCurrencyType: 'GBP',
  currencyInvoice: '',
  currencyComment: '',
};

export const addMoreValidationSchema = Yup.object().shape({
  occyCredits: Yup.number()
    .positive('Quantity must be more than 0')
    .integer()
    .required('Required'),
  totalCost: Yup.number()
    .positive('Cost must be more than 0')
    .integer()
    .required('Required'),
  invoiceId: Yup.string().required('Required'),
});

export const CurrencyValidationSchema = Yup.object().shape({
  currencyQuantity: Yup.number()
    .positive('Quantity must be more than 0')
    .integer()
    .required('Required'),
  currencyInvoice: Yup.string().required('Required'),
});

export const sort = [
  {
    id: 1,
    label: 'None',
    value: 'createdAt',
    dir: 'DESC',
    match: 'checked1',
  },
  {
    id: 2,
    label: 'Created (Oldest to Newest)',
    value: 'createdAt',
    dir: 'ASC',
    match: 'checked2',
  },
  {
    id: 3,
    label: 'Created (Newest to Oldest)',
    value: 'createdAt',
    dir: 'DESC',
    match: 'checked3',
  },
  {
    id: 4,
    label: 'Name (A to Z)',
    value: 'title',
    dir: 'ASC',
    match: 'checked4',
  },
  {
    id: 5,
    label: 'Name (Z to A)',
    value: 'title',
    dir: 'DESC',
    match: 'checked5',
  },
];
