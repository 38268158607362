import React from 'react';
import { Row } from 'react-bootstrap';
import PortletBody from '../../../app/components/portlet/PortletBody';
import { StyledSpan, Wrap } from '../../../assets/styledComponents/Global';
import styled from 'styled-components';
import FormikAsyncSelect from '../../../app/components/formikComponents/FormikAsyncSelect';
import { industryType, inventoryType, jobLevel, jobType, monsterDuration } from '../CampaignsUtils';
import MonsterIcon from '../../../app/components/svgIcons/MonsterIcon';


const MonsterEditForm = ({values}) => {
  return (
    <>
      <PortletBody className="w-100">
        <div className="p-4">
        <StyledWrap className={`d-flex align-items-center justify-content-start gap-3`}>
          <MonsterIcon size={24} />
          <Wrap className='d-flex flex-column'>
            <StyledSpan size='16px' className='text fw-semibold'>Monster</StyledSpan>
          </Wrap>
        </StyledWrap>
          <div className="p-2">
            <Row className="mt-2">
              <div className="col-12 col-md-6">
                <div className="mb-3">
                  <label htmlFor="inventoryType" className="form-label">
                  Inventory
                  </label>
                  <FormikAsyncSelect
                        name="inventoryType"
                        id="inventoryType"
                        options={inventoryType}
                        searchAble={false}
                        placeholder="Select inventory"
                        isDisabled={values?.jobBoardSummaryKeys?.monsterObject}
                    />
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="mb-3">
                  <label htmlFor="jobLevel" className="form-label">
                  Job Level
                  </label>
                  <FormikAsyncSelect
                        name="jobLevel"
                        searchAble={false}
                        id="jobLevel"
                        options={jobLevel}
                        placeholder="Select job level"
                        isDisabled={values?.jobBoardSummaryKeys?.monsterObject}
                    />
                </div>
              </div>
            </Row>

            <Row className="mt-2">
              <div className="col-12 col-md-6">
                <div className="mb-3">
                  <label htmlFor="jobType" className="form-label">
                  Job Type
                  </label>
                  <FormikAsyncSelect
                        name="jobType"
                        searchAble={false}
                        id="jobType"
                        options={jobType}
                        placeholder="Select job type"
                        isDisabled={values?.jobBoardSummaryKeys?.monsterObject}
                    />
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="mb-3">
                  <label htmlFor="desiredDuration" className="form-label">
                    Duration
                  </label>
                  <FormikAsyncSelect
                        name="desiredDuration"
                        searchAble={false}
                        id="desiredDuration"
                        options={monsterDuration}
                        placeholder="Select duration in days"
                        isDisabled={values?.jobBoardSummaryKeys?.monsterObject}
                    />
                </div>
              </div>
            </Row>
           
            <Row className="mt-2">
              <div className="col-12 col-md-6">
                <div className="mb-3">
                  <label htmlFor="industryName" className="form-label">
                 Industry
                  </label>
                  <FormikAsyncSelect
                        name="industryName"
                        id="industryName"
                        searchAble={false}
                        options={industryType}
                        placeholder="Select industry name"
                        isDisabled={values?.jobBoardSummaryKeys?.monsterObject}
                    />
                </div>
              </div>

            </Row>
          </div>
        </div>
      </PortletBody>
    </>
  );
};

const StyledWrap = styled.div`
margin : 1px 1px 0px 0px;
`;
export default MonsterEditForm;
