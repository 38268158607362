import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const StyledSwitch = ({ color = '#6ab04c', isToggle, onToggle,width,height,lblheight,lblWidth,left,bottom,top }) => {
  const [toggle, setToggle] = useState(isToggle);
  useEffect(()=>{
    setToggle(isToggle)
  },[isToggle])
  return (
    <Switch  {...{ color,width,height,lblheight,lblWidth,left,bottom,top }}>
      <Input
        {...{ color,width,height,lblheight,left,bottom,top }}
        type="checkbox"
        // defaultChecked={toggle}
        checked={toggle}
        onChange={onToggle}
      />
      <Slider
        {...{ toggle, color,width,height,lblheight,lblWidth,left,bottom,top }}
        onClick={() => setToggle(!toggle)}
        className="position-absolute top-0 start-0 end-0 bottom-0 cursor-pointer "
      />
    </Switch>
  );
};

export default StyledSwitch;

const Slider = styled.span`
  background-color: ${({ toggle, color }) =>
    toggle ? color : 'rgba(120, 120, 128, 0.16)'};
  border-radius: 15px;
  transition: 0.4s;

  &:before {
    content: '';

    position: absolute;
    left: ${({left}) =>
     left ? left : '2px'};
    bottom: ${({ bottom}) =>
    bottom ? bottom : '2px'};
    top:${({ top}) =>
    top ? top : ''};

    width: ${({ width}) =>
    width ? width : '16px'};;
    height: ${({ height}) =>
    height ? height : '16px'};;
    border-radius: 100%;

    background-color: white;
    border: 1px solid #d3d3d3;
    transition: 0.4s;
  }
`;

const Input = styled.input`
  &:checked + ${Slider}:before {
    transform: translateX(13px);
  }
`;

const Switch = styled.label`
  position: relative;
  width: ${({ lblWidth}) => lblWidth ? lblWidth : '33px'};;
  height: ${({ lblheight}) => lblheight ? lblheight : '20px'};
  background-color: ${({ toggle, color }) => (toggle ? color : 'white')};
  border-radius: 15px;
  transition: 0.4s;

  & ${Input} {
    opacity: 0;
    width: 0;
    height: 0;
  }
`;
