import styled from 'styled-components';

export const Wrap = styled.div``;
export const Image = styled.img``;
export const H1 = styled.h1``;
export const H2 = styled.h2``;
export const H3 = styled.h3``;
export const H4 = styled.h4``;
export const H5 = styled.h5``;
export const H6 = styled.h6``;

export const P = styled.p``;
export const B = styled.b``;
export const I = styled.i``;
export const Span = styled.span``;

export const HR = styled.hr`
border-top: 1px solid $shadow-color-dark
`;

export const StyledLabel = styled.label`
font-size:${({ size }) => size ?? '14px'};
font-weight:${({ weight }) => weight ?? '600'};
color:${({ color }) => color ?? 'black'};
line-height: ${({ lineHight }) => lineHight ?? ''};
font-family:${({ family }) => family ?? ''};
`;
export const StyledSpan = styled.span`
  font-size: ${({ size }) => size ?? '14px'};
  font-weight: ${({ weight }) => weight ?? '600'};
  color: ${({ color }) => color ?? 'black'};
`;
export const ScrollWrap = styled.div`
height:${({ height }) => height ?? '14px'};
width:${({ width }) => width ?? '100%'};
margin-top:12px;
overflow:auto;
`;