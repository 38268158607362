import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { getAdvertRedirectUrl } from '../../utils/helper';
import LazyScreenLoader from '../../app/components/loader/LazyScreenLoader';
const PublicAdvertModal = ({ show, onHide, data }) => {
    const [loading, setLoading] = useState(true);
    const handleLoad = () => {
        setLoading(false);
      };
    
      const handleBeforeLoad = () => {
        setLoading(true);
      };
    
    return (
      <Modal show={show} centered onHide={onHide} size={'xl'}>
        <Modal.Header
          closeButton
          className="d-flex border-bottom-0 pb-0 align-items-baseline ps-4"
        >
          <Modal.Title
            as="h3"
            className=" flex-grow-0 px-0 cursor-pointer flex-center pt-md-1 pb-md-3 "
          >
            {data?.jobTitle}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex  flex-column bg-body rounded p-0 mt-0">
          <div className="position-relative">
            {loading && (
              <div
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  zIndex: 9999,
                }}
              >
                {/* Add your loader content here */}
                <LazyScreenLoader/>
              </div>
            )}
            <iframe
              title="cv"
              src={`${process.env.REACT_APP_URL}${getAdvertRedirectUrl(
                data?.jobTitle,
                data?.jobPostings[0]?.id,
              )}`}
              width="100%"
              height="850px"
              onLoad={handleLoad}
              onBeforeLoad={handleBeforeLoad}
            ></iframe>
          </div>
        </Modal.Body>
      </Modal>
    );
};

export default PublicAdvertModal;
