/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react';
import Portlet from '../../../app/components/portlet/Portlet';
import PortletHeader from '../../../app/components/portlet/PortletHeader';
import styled from 'styled-components';
import { STATUSES } from '../../../utils/Statuses';
import CustomDropdown from '../../../app/components/dropdown/CustomDropDown';
import DropDownIcon from '../../../app/components/svgIcons/DropDownIcon';
import theme from '../../../utils/themes';
import DropdownItem from 'react-bootstrap/esm/DropdownItem';
import CandidatesIcon from '../../../app/components/svgIcons/CandidatesIcon';
import ProductsIcon from '../../../app/components/svgIcons/ProductsIcon';
import WalletIcon from '../../../app/components/svgIcons/WalletIcon.svg';
import CompanyAvatar from '../../../app/components/svgIcons/CompanyAvatar.svg';
import { Wrap } from '../../../assets/styledComponents/Global';
import StyledButton from '../../../app/components/styledComponents/StyledButton';
import briefCaseIcon from '../../../app/components/svgIcons/briefCaseIcon';
import TotalApplicationIcon from '../../../app/components/svgIcons/TotalApplicationIcon';
import CompanyStatusCard from './CompanyStatusCard';
import DeleteIcon from '../../../app/components/svgIcons/DeleteIcon';
import License from '../../Licenses/License';
import UserListingContainer from '../../Users/listing/UserListingContainer';
import SettingsListingContainer from '../../Setting/listing/SettingsListingContainer';
import EditIcon from '../../../app/components/svgIcons/EditIcon';
import OptionFillIcon from '../../../app/components/svgIcons/OptionFillIcon';
import SlashCircleCIon from '../../../app/components/svgIcons/SlashCircleIcon';
import MarkIcon from '../../../app/components/svgIcons/MarkIcon';
import { Link, useParams } from 'react-router-dom';
import EyeIcon from '../../../app/components/svgIcons/EyeIcon';
import CompanyEditForm from './companyEditForm';
import LicensesIcon from '../../../app/components/svgIcons/LicensesIcon';
import SuspendIcon from '../../../app/components/svgIcons/SuspendIcon';
import DeleteCompanyModal from '../creation/components/DeleteCompanyModal';
import CompanyTableModal from '../creation/components/CompanyTableModal';
import TotalCandidate from '../../../app/components/svgIcons/TotalCandidate';
import { toast } from 'react-toastify';
import {
  usePreSignedProfileUrlMutation,
  useGetCompanyStatsQuery,
  useUpdateCompanyGeneralInfoMutation,
  useGetCompanyInfoQuery,
  useRemoveCompanyMutation,
  useSuspendCompanyMutation,
  useChangeCompanyAdminMutation,
  useGetCreditDetailsQuery,
} from '../companyApiSlice';
import PurchaseCreditModal from '../creation/components/PurchaseCreditModal';
import ChangeCompanyAdminModal from '../creation/components/ChangeCompanyAdminModal';
import CompanyProductsListingContainer from '../CompanyProdutcts/CompanyProductsLsitingContainer';
import CompanyJobLisitng from '../../Job/listing/companyJobLisitng';
import CardSkeleton from '../../../assets/skeletons/cardSkeleton';
import CompanyProfileSkeleton from '../../../assets/skeletons/companyProfileSkeleton';
import { useNavigate } from 'react-router-dom';
import CampaignsListingContainer from '../../Campaigns/listing/CampaignsListingContainer';
import { Helmet } from 'react-helmet';
import _ from 'lodash';
import { StyledLabel } from '../../Campaigns/CampaignDetails';
import { Row } from 'react-bootstrap';
import { formateNumber, getCurrencySign } from '../../../utils/helper';
import SubsidiariesIcon from '../../../app/components/svgIcons/subsidiariesIcon';
import SubsidiariesModal from '../creation/components/SubsidiariesModal';
import StyledColoredTag from '../../../app/components/styledComponents/StyledColoredTag';
import CompanyEditModal from '../creation/components/CompanyEditModal';
import SubsiIconFill from '../../../app/components/svgIcons/SubsiIconFill';

const CompanyListingContainer = () => {
  const initialFilters = {
    jobs: [],
    perPage: 5,
    page: 1,
    sortedColumn: { path: 'title', order: 'ASC' },
    query: '',
  };
  const navigate = useNavigate();
  const [isNew, setIsNew] = useState('');
  const [activeTab, setActiveTab] = useState(1);
  const [selectedColumns, setSelectedColumns] = useState(false);
  const [companyDetails, setCompanyDetails] = useState();
  const { compId } = useParams();
  const fileref = useRef(null);
  const { data: companyStats, isLoading: statsLoading } =
    useGetCompanyStatsQuery(compId);
  const [getPreSignedUrl] = usePreSignedProfileUrlMutation();
  const [updateCompGnrlInfo, { isLoading: loading }] =
    useUpdateCompanyGeneralInfoMutation();
  const {
    data: compyInfo,
    refetch,
    isLoading: profileLoading,
  } = useGetCompanyInfoQuery(compId);
  const { data: creditDetails, refetch: creditRefetch } =
    useGetCreditDetailsQuery(compId);
  const [addCreditModal, setAddCreditModal] = useState(false);
  const [subsidiariesModal, setSubsidiariesModal] = useState(false);
  const [showPurchaseHistoryModal, setPurchaseHistoryModal] = useState(false);
  const [companyEdit, setCompanyEdit] = useState(false);
  const [companyId, setCompanyId] = useState('');
  const [removeCompany] = useRemoveCompanyMutation();
  const [suspndCompany] = useSuspendCompanyMutation();
  const [editable, setEditable] = useState(true);
  const [deleteModal, setDeleteModal] = useState(false);
  const [changeCompanyAdmin] = useChangeCompanyAdminMutation();

  const deleteCompany = () => {
    removeCompany(compId)
      .unwrap()
      .then((res) => {
        toast.success(res?.message || 'Company Deleted Successfully');
        updateEditOptions(5);
        navigate(-1);
      });
  };
  const [newColumn, setNewColumn] = useState([]);
  const [column, setColumn] = useState([
    {
      id: 1,
      path: 'jobId',
      label: 'Job Id',
      isChecked: true,
      alignBody: 'text-left align-middle px-3',
      alignHeader: 'text-left align-middle cursor-pointer p-3',
      content: (job) => (
        <Link
          // to={`/ jobs / ${ job.id }/applications`}
          to={''}
          className="text-decoration-none"
        >
          <h5 className="m-0">{job.id || '-'}</h5>
        </Link>
      ),
    },
    {
      id: 2,
      isChecked: true,
      path: 'title',
      label: 'Job Title',
      content: (job) => <span className="m-0">{job.title || '-'}</span>,
      alignBody: 'text-left align-middle',
      alignHeader: 'text-left align-middle cursor-pointer p-3',
    },
    {
      id: 3,
      isChecked: true,
      path: 'company',
      label: 'Company',
      content: (job) => <span className="m-0"> {job.company || '-'}</span>,
      alignBody: 'text-left align-middle',
      alignHeader: 'text-left align-middle cursor-pointer p-3',
    },
    {
      id: 4,
      isChecked: true,
      path: 'expiry',
      label: 'Expiry Date',
      content: (job) => <span className="m-0"> {job.expiry || '-'}</span>,
      alignBody: 'text-left align-middle',
      alignHeader: 'text-left align-middle cursor-pointer p-3',
    },
    {
      id: 5,
      isChecked: true,
      path: 'postedBy',
      label: 'Posted By',
      content: (job) => <span className="m-0"> {job.posted || '-'}</span>,
      alignBody: 'text-left align-middle',
      alignHeader: 'text-left align-middle cursor-pointer p-3',
    },
    {
      id: 6,
      isChecked: true,
      path: 'ticket',
      label: 'Ticket',
      alignBody: 'text-left align-middle',
      alignHeader: 'text-left align-middle cursor-pointer p-3',
      content: (job) => (
        <>
          {' '}
          <StyledBadge>{job.ticket || '-'}</StyledBadge>
        </>
      ),
    },
    {
      id: 7,
      isChecked: true,
      label: 'Actions',
      alignBody: 'text-center align-middle',
      alignHeader: 'text-center align-middle cursor-pointer p-3',
      content: (job) => (
        <>
          {' '}
          <div className="d-flex justify-content-center align-items-center">
            <Link
              variant="text"
              className="text-decoration-none  px-2"
              to={`${job.id}`}
            >
              <EditIcon />
            </Link>
            <Link
              variant="text"
              className="text-decoration-none feature-pending px-2"
              to={''}
            //   to={`${job.id}/job-information`}
            >
              <EyeIcon />
            </Link>
            <CustomDropdown
              toggleClassName={'bg-transparent'}
              menuClassName={'adminJobs'}
              dropdownToggle={
                <div className={`account-menu ${'bg-transparent'} `}>
                  <OptionFillIcon rotate={'left'} />
                </div>
              }
              dropdownMenu={
                <DropdownItem>
                  <div className="d-flex flex-column">
                    <Link
                      // to={`/jobs/${jobId}/stages`}
                      to={''}
                      className="text-decoration-none"
                    >
                      <span className="dark-text">
                        <MarkIcon />
                      </span>
                      <span className="ms-3 text-secondary">
                        Mark as advertised
                      </span>
                    </Link>
                    <Link
                      // to={`/jobs/${jobId}/stages`}
                      to={''}
                      className="text-decoration-none  pt-2"
                    >
                      <span className="dark-text">
                        <SlashCircleCIon />
                      </span>
                      <span className="ms-3 text-secondary">
                        Cancel Request
                      </span>
                    </Link>
                  </div>
                </DropdownItem>
              }
            />
            {/*<Link
                  variant="text"
                  className="text-decoration-none feature-pending px-2"
                //   to={`${job.id}/job-information`}
                >
                    <DeleteIcon />
                  </Link> */}
          </div>
        </>
      ),
    },
  ]);

  const handleSelectedColumn = () => {
    const newArr = [];
    column.map((item) => item.isChecked && newArr.push(item));
    setNewColumn(newArr);
    setSelectedColumns(false);
  };

  useEffect(() => {
    handleSelectedColumn();
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const suspendCompany = (id) => {
    suspndCompany({
      id: compId,
      statusId: id === 3 ? STATUSES.LIVE : STATUSES.SUSPENDED,
    })
      .unwrap()
      .then((res) => {
        toast.success(res?.message || 'Company suspended successfully');
        refetch();
      });
  };
  const deleteCompModal = () => {
    setDeleteModal(true);
  };
  const switchLicence = () => {
    window.location.href = window.location.origin + '/licences/switch-licence';
  };
  const addNew = [
    { name: 'Users', isActive: true, Icon: CandidatesIcon },
    { name: 'Products', isActive: false, Icon: ProductsIcon },
  ];
  const editCompanyOptions = [
    {
      id: 0,
      Icon: SuspendIcon,
      desc: 'Suspend',
      isActive: false,
      action: suspendCompany,
    },
    {
      id: 1,
      Icon: LicensesIcon,
      desc: 'Switch Licence',
      isActive: false,
      action: switchLicence,
    },
    // { id: 2, Icon: AdminIcon, desc: 'Change Admin', isActive: false, hide: true },
    {
      id: 3,
      Icon: DeleteIcon,
      desc: 'Delete Company',
      isActive: false,
      action: deleteCompModal,
    },
  ];
  const [editOptions, setEditOptions] = useState(editCompanyOptions);
  const cardData = [
    {
      Icon: briefCaseIcon,
      desc: 'Total Live Jobs',
      no: `${companyStats?.liveJobs?.totalLiveJobs || 0}`,
      growth: '',
    },
    {
      Icon: TotalCandidate,
      desc: 'Total Candidates',
      no: `${companyStats?.totalCandidates?.totalCandidates || 0}`,
      growth: {
        arrow: `${companyStats?.totalCandidates?.lastMonthCandidates >
          companyStats?.totalCandidates?.secondLastMonthCandidates
          ? 'up'
          : 'down'
          }`,
        percent: `${(
          (companyStats?.totalCandidates?.lastMonthCandidates / 100) *
          companyStats?.totalCandidates?.secondLastMonthCandidates
        ).toFixed(2)}`,
      },
    },
    {
      Icon: TotalApplicationIcon,
      desc: 'Applications last Month',
      no: `${companyStats?.totalApplications?.lastMonthApplications || 0}`,
      growth: {
        arrow: `${companyStats?.totalApplications?.lastMonthApplications >
          companyStats?.totalApplications?.secondLastMonthApplications
          ? 'up'
          : 'down'
          }`,
        percent: `${(
          (companyStats?.totalApplications?.lastMonthApplications / 100) *
          companyStats?.totalApplications?.secondLastMonthApplications
        ).toFixed(2)}`,
      },
    },
  ];
  const [tabs, setTabs] = useState([
    {
      id: 1,
      title: 'General',
      active: true,
      status: STATUSES.NULL,
    },
    {
      id: 2,
      title: 'Licence',
      active: false,
      status: STATUSES.DRAFT,
    },
    {
      id: 3,
      title: 'Users',
      active: false,
      status: STATUSES.CLOSED,
    },
    {
      id: 4,
      title: 'Jobs',
      active: false,
      status: STATUSES.ARCHIVED,
    },
    {
      id: 5,
      title: 'Campaigns',
      active: false,
      status: STATUSES.ARCHIVED,
    },
    {
      id: 6,
      title: 'Products',
      active: false,
      status: STATUSES.ARCHIVED,
    },
    // {
    //   id: 7,
    //   title: 'JAG Fields',
    //   active: false,
    //   status: STATUSES.ARCHIVED,
    // },
    {
      id: 8,
      title: 'Settings',
      active: false,
      status: STATUSES.ARCHIVED,
    },
  ]);

  const changeAdmin = (val) => {
    const obj = { adminId: val, compId: compId };
    changeCompanyAdmin(obj)
      .unwrap()
      .then((res) => {
        toast.success(res?.message || 'Company admin changed successfully');
      });
  };

  const updateEditOptions = (ind) => {
    setEditOptions((current) =>
      current.map((obj) => {
        if (obj.id === ind) {
          return { ...obj, isActive: true };
        }
        return { ...obj, isActive: false };
      }),
    );
  };

  const onChangeTabs = (tab) => {
    setActiveTab(tab);
    setTabs((prevState) =>
      prevState.map((p) => {
        return p.id === tab
          ? {
            ...p,
            active: true,
          }
          : {
            ...p,
            active: false,
          };
      }),
    );
  };
  const uploadImage = (values) => {
    const dataObj = {
      values,
      compId,
    };
    updateCompGnrlInfo(dataObj)
      .unwrap()
      .then((user) => {
        toast.success(user.message);
        refetch();
      });
  };
  const handleFileSelect = (evt) => {
    try {
      const acceptedFiles = [...evt.target.files];
      if (!acceptedFiles) return;
      acceptedFiles.map(async (file) => {
        const { data: responseData } = await getPreSignedUrl({
          fileName: file.name,
          fileType: file.type,
          compId: compId,
        });
        if (!responseData?.url) {
          toast.error(
            'Could not process the upload. Please make sure the file is valid and the internet connection is stable',
          );
        }
        await fetch(`${responseData?.url}`, {
          method: responseData?.method,
          headers: {
            Accept: 'application/json',
            'Content-Type': file.type,
          },
          body: file,
        });
        uploadImage({
          image: responseData.key,
        });
      });
    } catch (e) {
      console.error(e);
      toast.error('Something went wrong while trying to upload your files..');
    }
  };
  const removeProfileImage = () => {
    uploadImage({
      image: null,
    });
  };
  const updateCompanyGenerelInfo = (val) => {
    let data = { ...val };
    if (data.county) {
      data.county = data?.county?.label;
    }
    if (data?.telephone?.length === 0) {
      delete data['telephone'];
    }
    if (data?.description?.length === 0) {
      delete data['description'];
    }
    updateCompGnrlInfo({ values: data, compId: compId })
      .unwrap()
      .then((res) => {
        toast.success(res?.message || 'Update Successfully');
        setEditable(true);
        refetch();
      });
  };

  useEffect(() => {
    if (!addCreditModal) creditRefetch();
  }, [addCreditModal]);

  const result =
    creditDetails?.creditDetails?.length &&
    creditDetails?.creditDetails?.reduce((acc, item) => {
      return {
        ...acc,
        [item?.credit_type]: {
          amount: item?.amount,
          currency_type: item.currency_type,
        },
      };
    }, {});

  return (
    <div>
      <Helmet>
        <title>{_.capitalize(compyInfo?.name) + ' - occy ADMIN'}</title>
      </Helmet>
      <Portlet>
        <PortletHeader
          tabs={tabs}
          title={<div className='d-flex'>
            <span className='pe-3'>{compyInfo?.name}</span>
            {compyInfo?.isParent ?
              <SubsiIconFill className="cursor-pointer" size={32}
                onClick={() => {
                  setSubsidiariesModal(true);
                  setCompanyId(compyInfo)
                }} /> :
              <StyledColoredTag variant={'blue'}>
                {compyInfo?.superCompany?.companyName}
              </StyledColoredTag>
              // <span></span>
            }

          </div>
          }
          onChangeTabs={onChangeTabs}
          // subTitle={`(${jobRes?.totalCount || '0'})`}
          actions={
            <>
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex bg-primary feature-pending align-items-center border me-4 border-1 border-primary rounded px-3 py-2 text-white fw-bold gap-2">
                  <span
                    className="cursor-pointer"
                    onClick={() => setIsNew(true)}
                  >
                    Add New
                  </span>
                  <StyledVerticalLine color={theme.white} />
                  <CustomDropdown
                    dropdownToggle={<DropDownIcon size="14" />}
                    menuClassName={'mt-2'}
                    toggleClassName="p-0 mx-0 bg-transparent cursor-pointer text-white"
                    dropdownMenu={
                      <span>
                        {addNew.map(({ name, Icon, isActive }, index) => (
                          <DropdownItem
                            onClick={() => setIsNew(isActive)}
                            key={`cand-stat-${name}-${index}`}
                          >
                            <Icon size={18} />
                            <span className="ms-2 me-5 text-secondary">
                              {name}
                            </span>
                          </DropdownItem>
                        ))}
                      </span>
                    }
                  />
                </div>
                <div className="ms-2">
                  <CustomDropdown
                    dropdownToggle={
                      <div className="border rounded border-1 p-2">
                        <OptionFillIcon
                          size="20"
                          rotate="left"
                          className="cursor-pointer"
                        />
                      </div>
                    }
                    toggleClassName="bg-transparent p-0"
                    dropdownMenu={
                      <>
                        {editOptions.map(
                          ({ Icon, desc, action, id, hide }, ind) => (
                            <DropdownItem
                              onClick={() => {
                                updateEditOptions(ind);
                                action(compyInfo?.statusId);
                              }}
                              key={ind}
                            >
                              <div
                                className={`d-flex align-items-center gap-2`}
                              >
                                {' '}
                                <Icon size={18} />{' '}
                                {compyInfo?.statusId === 3 && id === 0
                                  ? 'Unblock '
                                  : desc}
                              </div>
                            </DropdownItem>
                          ),
                        )}
                      </>
                    }
                  />
                </div>
              </div>
            </>
          }
        />
        {activeTab === 4 && (
          <div className="portlet__body-bg">
            <CompanyJobLisitng />
          </div>
        )}
        {activeTab === 5 && (
          <div className="portlet__body-bg">
            <CampaignsListingContainer />
          </div>
        )}
        {activeTab === 6 && <CompanyProductsListingContainer />}
        {activeTab === 1 && (
          <div className="portlet__body-bg">
            <Wrap className="d-flex mb-3">
              {statsLoading ? (
                <>
                  <CardSkeleton />
                </>
              ) : (
                <>
                  <StyledWallet className="p-4 justify-content-center d-flex flex-column">
                    <StyledLabel className="text-primary">
                      WALLET BALANCE
                    </StyledLabel>

                    <Wrap className="d-flex justify-content-between align-items-center py-2">
                      <Wrap className="d-flex align-items-center gap-2">
                        <Wrap>
                          <StyledLabel className="text-primary" size="28px">
                            {getCurrencySign(result?.FIAT?.currency_type)}
                          </StyledLabel>
                          <StyledLabel className="text-primary" size="28px">
                            {result?.FIAT?.amount
                              ? formateNumber(result?.FIAT?.amount)
                              : '0.00'}
                          </StyledLabel>
                        </Wrap>
                        <StyledVerticalLine
                          size="3"
                          color={theme.primary}
                          height="25"
                          className="rounded-3"
                        />
                        <Wrap>
                          <StyledLabel className="text-primary" size="28px">
                            {result?.OCCY_CREDITS?.amount
                              ? formateNumber(result?.OCCY_CREDITS?.amount)
                              : '0.00'}{' '}
                            Credits
                          </StyledLabel>
                        </Wrap>
                      </Wrap>
                    </Wrap>

                    <Wrap className="d-flex ">
                      <StyledButton
                        variant={'white'}
                        className="me-1 border-0 fw-bold shadow fs-6 py-2 px-3"
                        onClick={() => setAddCreditModal(true)}
                      >
                        Add more
                      </StyledButton>
                      <StyledButton
                        variant={'white'}
                        className="ms-1 border-0 fw-bold shadow fs-6 py-2 px-3"
                        onClick={() => setPurchaseHistoryModal(true)}
                      >
                        Transaction History
                      </StyledButton>
                    </Wrap>
                  </StyledWallet>
                  {cardData.map((item, ind) => (
                    <CompanyStatusCard {...item} companyStats={companyStats} key={`CompanyStatusCard-${ind}`}/>
                  ))}
                </>
              )}
            </Wrap>

            {profileLoading ? (
              <CompanyProfileSkeleton />
            ) : compyInfo ? (
              <CompanyEditForm
                updateCompanyGenerelInfo={(val) =>
                  updateCompanyGenerelInfo(val)
                }
                handleFileSelect={handleFileSelect}
                fileref={fileref}
                removeProfileImage={removeProfileImage}
                CompanyAvatar={CompanyAvatar}
                compyInfo={compyInfo}
                refetch={refetch}
                loading={loading}
                editable={editable}
                setEditable={setEditable}
              />
            ) : (
              <>
                <div className="d-flex justify-content-center align-items-center py-5">
                  {' '}
                  Failed to load Data
                </div>
              </>
            )}
          </div>
        )}
        {activeTab === 2 && (
          <div className="bg-body">
            <License />
          </div>
        )}
        {activeTab === 3 && <UserListingContainer />}
        {activeTab === 8 && <SettingsListingContainer companySettings />}
      </Portlet>
      <>
        <PurchaseCreditModal
          show={addCreditModal}
          onHide={() => {
            setAddCreditModal(false);
          }}
          creditRefetch={creditRefetch}
        />
        <DeleteCompanyModal
          show={deleteModal}
          deleteCompany={deleteCompany}
          onHide={() => {
            setDeleteModal(false);
          }}
        />
        <ChangeCompanyAdminModal
          show={editOptions[2]?.isActive}
          changeAdmin={(val) => changeAdmin(val)}
          onHide={() => {
            updateEditOptions(4);
          }}
        />
      </>
      <CompanyTableModal
        show={showPurchaseHistoryModal}
        onHide={() => {
          setPurchaseHistoryModal(false);
        }}
      />
      <SubsidiariesModal
        show={subsidiariesModal}
        onHide={() => {
          setSubsidiariesModal(false);
        }}
        // company={compyInfo}
        companyId={companyId}
        setSubsidiariesModal={setSubsidiariesModal}
        setCompanyDetails={setCompanyDetails}
        setCompanyEdit={setCompanyEdit}
      />
      <CompanyEditModal
        show={companyEdit}
        onHide={() => {
          setCompanyEdit(false);
        }}
        companyDetails={companyDetails}
        refetch={refetch}
      />
    </div>
  );
};

const StyledVerticalLine = styled.span`
  border-right: ${({ size }) => (size ? `${size}px` : '1px')} solid
    ${({ color }) => color};
  margin-left: 5px;
  height: ${({ height }) => (height ? `${height}px` : '14px')};
`;

const StyledWallet = styled.div`
  background-image: url(${WalletIcon}),
    linear-gradient(
      102.05deg,
      ${theme.occyCredit1} 2.51%,
      ${theme.occyCredit2} 93.7%
    );

  background-repeat: no-repeat;
  background-position: center right;
  border-radius: 6px;
  width: 70%;
`;
const StyledBadge = styled.div`
  background-color: ${({ theme }) => theme.tagLightBlue} !important;
  display: flex;
  color: ${({ theme }) => theme.blue} !important;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  border: 1.5px;
  font-weight: 500;
  gap: 7px;
  border-radius: 10px;
  padding: 4px 12px;
  width: fit-content;
`;

export default CompanyListingContainer;
