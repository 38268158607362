import * as Yup from 'yup';
import styled from 'styled-components';
import { countries } from '../../../../utils/Countries';
// import LineIcon from '../../../../app/components/svgIcons/LineIcon';
// import GreenTickIcon from '../../../../app/components/svgIcons/GreenTickIcon';
// import SpeakerSoundIcon from '../../../../app/components/svgIcons/SpeakerSoundIcon';

export const basicInformationValidationSchema = {
  employmentType: Yup.string().required('Required'),
  jobCategory: Yup.object().required('Required'),
  position:Yup.string().required('Required'),
};

export const jobAdvertSchema = {
  jobRequirement: Yup.string().required('Required'),
  elevatorPitch: Yup.string(),
  jobDescription: Yup.string().required('Required'),
  additionalNotes: Yup.string().nullable(true),
};

export const basicInformationInitialState = {
  title: '',
  employmentType: undefined,
  jobCategory: '',
  position :undefined,
};

export const formMessageTypes = {
  REMINDER: 'FORM_REMINDER',
  SUCCESS: 'FORM_SUCCESS',
};

export const audienceTypes = {
  HIRING_TEAM: 'HIRING_TEAM',
  CANDIDATE: 'CANDIDATE',
};

export const otherDetailsValidationSchema = {
  // isCompetitive: Yup.boolean(),
};

export const otherDetailsInitialState = {
  keywords: [],
  salaryFrom: undefined,
  salaryTo: undefined,
  salaryCurrency: 'GBP',
};

export const additionalFieldsValidationSchema = {
  aboutCompany: Yup.string().nullable(true),
  elevatorPitch: Yup.string().nullable(true),
  benefits: Yup.string().nullable(true),
  additionalNotes: Yup.string().nullable(true),
};

export const additionalFieldsInitialState = {
  aboutCompany: '',
  elevatorPitch: '',
  jobDescription: '',
  jobRequirement: '',
  benefits: '',
  additionalNotes: '',
  isCompanyBenefits:false,
  isDei:false,
  isAboutCompany:false,

};

export const StyledDot = styled.div`
  width: 14px;
  height: 14px;
  background: ${({ bgColor }) => bgColor};
  border-radius: 4px;
`;


export const positionStatus = [
  {
    label: 'Closed',
    value: 21,
  },
  {
    label: 'Live',
    value: 2,
  },
  {
    label: 'Filled',
    value: 56,
  },
];
// export const CopywriteStatus = [
//   {
//     id: 1,
//     label: 'A request for copywriting services has been made. Our team will get back to you in 24 hours.',
//     icon: <GreenTickIcon size={18} />,
//     line: <LineIcon height={60} />,
//   },
//   {
//     id: 2,
//     label: 'Once copywriting is completed, the job will be sent for publishing',
//     icon: <SpeakerSoundIcon size={22} />,

//     active: true,
//   },
// ];
export const jobTypes = [

  {
    label: 'Contract',
    value: 'CONTRACT',
  },
  {
    label: 'Permanent',
    value: 'PERMANENT',
  },
  {
    label: 'Temporary',
    value: 'TEMPORARY',
  },
];

export const employmentTypes = [
  {
    label: 'Full-time',
    value: 'FULL_TIME',
  },
  {
    label: 'Part-time',
    value: 'PART_TIME',
  },
];

export const salaryTypes = [
  {
    label: 'GBP',
    value: 'GBP',
  },
  {
    label: 'USD',
    value: 'USD',
  },
  {
    label: 'EUR',
    value: 'EUR',
  },
  {
    label: 'AED',
    value: 'AED',
  },
  {
    label: 'AUD',
    value: 'AUD',
  },
  {
    label: 'INR',
    value: 'INR',
  },
  {
    label: 'MYR',
    value: 'MYR',
  },
  {
    label: 'ZAR',
    value: 'ZAR',
  },
];

export const jobPositions = [
  {
    label: 'Entry Level',
    value: 'ENTRY_LEVEL',
  },
  {
    label: 'Mid Level',
    value: 'MID_LEVEL',
  },
  {
    label: 'Mid-Senior Level',
    value: 'MID_SENIOR_LEVEL',
  },
  {
    label: 'Other',
    value: 'OTHER',
  },
  {
    label: 'Senior Level',
    value: 'SENIOR_LEVEL',
  },

];
export const payPerValues = [
  {
    label: 'Annum',
    value: 'Annum',
  },
  {
    label: 'Day',
    value: 'day',
  },
  {
    label: 'Hour',
    value: 'hour',
  },
  {
    label: 'Month',
    value: 'month',
  },
  {
    label: 'Pro Rata',
    value: 'pro_rata',
  },
  {
    label: 'Week',
    value: 'week',
  },
];

export const remoteValues = [
  {
    label: 'Fully remote',
    value: 'REMOTE',
  },
  {
    label: 'Hybrid',
    value: 'HYBRID',
  },
  {
    label: 'On site',
    value: 'ON_SITE',
  },
];

export const ACCOUNT_ROLE = {
  HIRING_MANAGER: 'hiring manager',
  RECRUITER: 'recruiter',
  APPROVER: 'approver',
  INTERVIEWER: 'interviewer',
};
export const getAddressStringByLocation = (loc, fields = []) => {
  return fields
    .map((f) => loc[f])
    .filter((a) => !!a)
    .join(', ')
    .replace(/, *$/, '');
};

export const getCountryShortCodeByName = (countryShortCode) => {
  return countryShortCode && (countries.find(loc => loc.name === countryShortCode)?.code ?? '');
};

export const getCountryNameByCode = (countryShortCode) => {
  return countryShortCode && (countries.find(loc => loc.code === countryShortCode)?.name ?? '');
};
export const functionTypes = [
  {
    label: 'Accountancy',
    value: 'Accountancy',
  },
  {
    label: 'Admin, Secretarial & PA',
    value: 'Admin, Secretarial & PA',
  },
  {
    label: 'Animal Care & Welfare',
    value: 'Animal Care & Welfare',
  },
  {
    label: 'Arts & Heritage',
    value: 'Arts & Heritage',
  },
  {
    label: 'Charity',
    value: 'Charity',
  },
  {
    label: 'Computing & IT',
    value: 'Computing & IT',
  },
  {
    label: 'Construction & Skilled Trades',
    value: 'Construction & Skilled Trades',
  },
  {
    label: 'Customer Service & Call Centre',
    value: 'Customer Service & Call Centre',
  },
  {
    label: 'Driving & Automotive',
    value: 'Driving & Automotive',
  },
  {
    label: 'Education',
    value: 'Education',
  },
  {
    label: 'Engineering',
    value: 'Engineering',
  },
  {
    label: 'Financial Services',
    value: 'Financial Services',
  },
  {
    label: 'Health & Beauty',
    value: 'Health & Beauty',
  },
  {
    label: 'Healthcare & Medical',
    value: 'Healthcare & Medical',
  },
  {
    label: 'Hospitality & Catering',
    value: 'Hospitality & Catering',
  },
  {
    label: 'HR',
    value: 'HR',
  },
  {
    label: 'Legal',
    value: 'Legal',
  },
  {
    label: 'Leisure & Tourism',
    value: 'Leisure & Tourism',
  },
  {
    label: 'Logistics, Distribution & Supply Chain',
    value: 'Logistics, Distribution & Supply Chain',
  },
  {
    label: 'Manufacturing',
    value: 'Manufacturing',
  },
  {
    label: 'Marketing, Advertising, & PR',
    value: 'Marketing, Advertising, & PR',
  },
  {
    label: 'Media, Digital, & Creative',
    value: 'Media, Digital, & Creative',
  },
  {
    label: 'Others',
    value: 'Others',
  },
  {
    label: 'Production/Operations',
    value: 'Production/Operations',
  },

  {
    label: 'Purchasing & Procurement',
    value: 'Purchasing & Procurement',
  },
  {
    label: 'Retail & FMCG',
    value: 'Retail & FMCG',
  },
  {
    label: 'Sales',
    value: 'Sales',
  },
  {
    label: 'Scientific',
    value: 'Scientific',
  },
  {
    label: 'Safety & Security',
    value: 'Safety & Security',
  },
  {
    label: 'Social Care',
    value: 'Social Care',
  },
  {
    label: 'Strategy & Consultancy',
    value: 'Strategy & Consultancy',
  },
  {
    label: 'Training',
    value: 'Training',
  },

];

export const roles = [
  {
    key: 'Accountancy',
    value: [
      {
        label: 'Accountant',
        value: 'Accountant',
      },
      {
        label: 'Auditor',
        value: 'Auditor',
      },
      {
        label: 'Analyst',
        value: 'Analyst',
      },
      {
        label: 'Bookkeeper',
        value: 'Bookkeeper',
      },
      {
        label: 'Finance Manager',
        value: 'Finance Manager',
      },
      {
        label: 'Financial Accountant',
        value: 'Financial Accountant',
      },
      {
        label: 'Financial Controller',
        value: 'Financial Controller',
      },
      {
        label: 'Forensic & Fraud',
        value: 'Forensic & Fraud',
      },
      {
        label: 'Graduate/Internship',
        value: 'Graduate/Internship',
      },
      {
        label: 'Insolvency Legal Cashier',
        value: 'Insolvency Legal Cashier',
      },
      {
        label: 'Management Accountant',
        value: 'Management Accountant',
      },
      {
        label: 'Other Accountancy',
        value: 'Other Accountancy',
      },
      {
        label: 'Payroll',
        value: 'Payroll',
      },
      {
        label: 'Project Accountant',
        value: 'Project Accountant',
      },
      {
        label: 'Purchase Ledger Clerk',
        value: 'Purchase Ledger Clerk',
      },
      {
        label: 'Risk & Compliance',
        value: 'Risk & Compliance',
      },
      {
        label: 'Sales Ledger Clerk',
        value: 'Sales Ledger Clerk',
      },
      {
        label: 'Tax Accountant',
        value: 'Tax Accountant',
      },
      {
        label: 'Treasury',
        value: 'Treasury',
      },
      {
        label: ' VAT Specialist',
        value: ' VAT Specialist',
      },
    ],
  },
  {
    key: 'Admin, Secretarial & PA',
    value: [
      {
        label: 'Administrator',
        value: 'Administrator',
      },
      {
        label: 'Bilingual/Multilingual',
        value: 'Bilingual/Multilingual',
      },
      {
        label: 'Data Entry',
        value: 'Data Entry',
      },
      {
        label: 'Electoral Services',
        value: 'Electoral Services',
      },
      {
        label: 'Executive Assistant',
        value: 'Executive Assistant',
      },
      {
        label: 'Executive PA',
        value: 'Executive PA',
      },
      {
        label: 'HR Administrator',
        value: 'HR Administrator',
      },
      {
        label: 'Legal Secretary',
        value: 'Legal Secretary',
      },
      {
        label: 'Medical Secretary',
        value: 'Medical Secretary',
      },
      {
        label: 'Office Assistant',
        value: 'Office Assistant',
      },
      {
        label: 'Office Manager',
        value: 'Office Manager',
      },
      {
        label: 'Other',
        value: 'Other',
      },
      {
        label: 'PA',
        value: 'PA',
      },
      {
        label: 'Post Room Operative',
        value: 'Post Room Operative',
      },
      {
        label: 'Project Support',
        value: 'Project Support',
      },
      {
        label: 'Receptionist',
        value: 'Receptionist',
      },
      {
        label: 'Sales Administrator',
        value: 'Sales Administrator',
      },
      {
        label: 'Secretary',
        value: 'Secretary',
      },
      {
        label: 'Team Secretary',
        value: 'Team Secretary',
      },
      {
        label: 'Typist',
        value: 'Typist',
      },
    ],
  },
  {
    key: 'Animal Care & Welfare',
    value: [
      {
        label: 'Animal Shelter',
        value: 'Animal Shelter',
      },
      {
        label: 'Grooming',
        value: 'Grooming',
      },
      {
        label: 'Other',
        value: 'Other',
      },
      {
        label: 'Vet',
        value: 'Vet',
      },
      {
        label: 'Veterinary Assistant',
        value: 'Veterinary Assistant',
      },

    ],
  },
  {
    key: 'Arts & Heritage',
    value: [
      {
        label: 'Museums & Galleries',
        value: 'Museums & Galleries',
      },
      {
        label: 'Musicians',
        value: 'Musicians',
      },
      {
        label: 'Other',
        value: 'Other',
      },
      {
        label: 'Performing Arts',
        value: 'Performing Arts',
      },
      {
        label: 'Stage Technician',
        value: 'Stage Technician',
      },
      {
        label: 'Ticketing',
        value: 'Ticketing',
      },
      {
        label: 'Venues',
        value: 'Venues',
      },
    ],
  },
  {
    key: 'Charity',
    value: [
      {
        label: 'Advocate',
        value: 'Advocate',
      },
      {
        label: 'Charity Shop Assistant',
        value: 'Charity Shop Assistant',
      },
      {
        label: 'Charity Shop Manager',
        value: 'Charity Shop Manager',
      },
      {
        label: 'Community Support',
        value: 'Community Support',
      },
      {
        label: 'Corporate Partnerships',
        value: 'Corporate Partnerships',
      },
      {
        label: 'Events',
        value: 'Events',
      },
      {
        label: 'Finance',
        value: 'Finance',
      },
      {
        label: 'Fundraising',
        value: 'Fundraising',
      },
      {
        label: 'Other',
        value: 'Other',
      },
      {
        label: 'Project Manager',
        value: 'Project Manager',
      },
      {
        label: 'Senior Manager',
        value: 'Senior Manager',
      },
    ],
  },
  {
    key: 'Computing & IT',
    value: [
      {
        label: '1st Line Support/Helpdesk',
        value: '1st Line Support/Helpdesk',
      },
      {
        label: '2nd Line Support',
        value: '2nd Line Support',
      },
      {
        label: '3rd Line Support',
        value: '3rd Line Support',
      },
      {
        label: 'Analyst Prog/Dev',
        value: 'Analyst Prog/Dev',
      },
      {
        label: 'Business Analyst',
        value: 'Business Analyst',
      },
      {
        label: 'CRM',
        value: 'CRM',
      },
      {
        label: 'Cabling Engineer',
        value: 'Cabling Engineer',
      },
      {
        label: 'Consultant',
        value: 'Consultant',
      },
      {
        label: 'Database Developer',
        value: 'Database Developer',
      },
      {
        label: 'Datacoms Engineer',
        value: 'Datacoms Engineer',
      },
      {
        label: 'Desktop Support',
        value: 'Desktop Support',
      },
      {
        label: 'ERP',
        value: 'ERP',
      },
      {
        label: 'Field Service Engineer',
        value: 'Field Service Engineer',
      },
      {
        label: 'GIS',
        value: 'GIS',
      },
      {
        label: 'Hardware Engineer',
        value: 'Hardware Engineer',
      },
      {
        label: 'Installation/Rollout Engineer',
        value: 'Installation/Rollout Engineer',
      },
      {
        label: 'Mobile Engineer',
        value: 'Mobile Engineer',
      },

      {
        label: 'Network Administrator',
        value: 'Network Administrator',
      },
      {
        label: 'Network Analyst',
        value: 'Network Analyst',
      },

      {
        label: 'Network Manager',
        value: 'Network Manager',
      },
      {
        label: 'Network Security',
        value: 'Network Security',
      },
      {
        label: 'Operations Analyst',
        value: 'Operations Analyst',
      },

      {
        label: 'Other',
        value: 'Other',
      },
      {
        label: 'Programme Manager',
        value: 'Programme Manager',
      },
      {
        label: 'Systems Administrator',
        value: 'Systems Administrator',
      },
      {
        label: 'Systems Tester',
        value: 'Systems Tester',
      },
      {
        label: 'Systems Administrator',
        value: 'Systems Administrator',
      },
      {
        label: 'Systems Support Analyst',
        value: 'Systems Support Analyst',
      },
      {
        label: 'Systems Manager',
        value: 'Systems Manager',
      },
      {
        label: 'Trainer',
        value: 'Trainer',
      },
      {
        label: 'Technical Architect',
        value: 'Technical Architect',
      },
      {
        label: 'Technical Author',
        value: 'Technical Author',
      },
      {
        label: 'Telecoms Consultant',
        value: 'Telecoms Consultant',
      },
      {
        label: 'Telecoms Engineer',
        value: 'Telecoms Engineer',
      },

      {
        label: 'Test Manager',
        value: 'Test Manager',
      },
      {
        label: 'Test Analyst',
        value: 'Test Analyst',
      },
      {
        label: 'UAT, Web Designerther',
        value: 'UAT, Web Designer',
      },

      {
        label: 'Web Developer',
        value: 'Web Developer',
      },
    ],
  },
  {
    key: 'Construction & Skilled Trades',
    value: [
      {
        label: 'Architect',
        value: 'Architect',
      },
      {
        label: 'CAD/Drafting',
        value: 'CAD/Drafting',
      },
      {
        label: 'Carpenter',
        value: 'Carpenter',
      },
      {
        label: 'Concrete and Masonry',
        value: 'Concrete and Masonry',
      },
      {
        label: 'Contracts Manager',
        value: 'Contracts Manager',
      },
      {
        label: 'Electrician',
        value: 'Electrician',
      },
      {
        label: 'Estimator',
        value: 'Estimator',
      },
      {
        label: 'Facilities Manager',
        value: 'Facilities Manager',
      },
      {
        label: 'Flooring, Foreman',
        value: 'Flooring, Foreman',
      },

      {
        label: 'HVAC Designer/Installer',
        value: 'HVAC Designer/Installer',
      },
      {
        label: 'Heavy Equipment Operator',
        value: 'Heavy Equipment Operator',
      },
      {
        label: 'Ironwork/Metal Fabricator',
        value: 'Ironwork/Metal Fabricator',
      },
      {
        label: 'Landscaping',
        value: 'Landscaping',
      },
      {
        label: 'Operations Manager',
        value: 'Operations Manager',
      },
      {
        label: 'Other',
        value: 'Other',
      },
      {
        label: 'Plasterer',
        value: 'Plasterer',
      },
      {
        label: 'Plumber',
        value: 'Plumber',
      },
      {
        label: 'Painter/Decorator',
        value: 'Painter/Decorator',
      },
      {
        label: 'Pipe Fitter',
        value: 'Pipe Fitter',
      },
      {
        label: 'Roofer',
        value: 'Roofer',
      },

      {
        label: 'Site Superintendent',
        value: 'Site Superintendent',
      },
      {
        label: 'Site Manager',
        value: 'Site Manager',
      },
      {
        label: 'Surveyor',
        value: 'Surveyor',
      },
      {
        label: 'Tiler',
        value: 'Tiler',
      },

    ],
  },
  {
    key: 'Customer Service & Call Centre',
    value: [
      {
        label: 'Account Manager',
        value: 'Account Manager',
      },
      {
        label: 'Bank Teller',
        value: 'Bank Teller',
      },
      {
        label: 'Call Centre',
        value: 'Call Centre',
      },
      {
        label: 'Contact Centre Manager',
        value: 'Contact Centre Manager',
      },
      {
        label: 'Customer Service Advisor',
        value: 'Customer Service Advisor',
      },
      {
        label: 'Customer Service Assistant',
        value: 'Customer Service Assistant',
      },
      {
        label: 'Customer Service Manager',
        value: 'Customer Service Manager',
      },
      {
        label: 'Helpdesk',
        value: 'Helpdesk',
      },
      {
        label: 'Other',
        value: 'Other',
      },
      {
        label: 'Switchboard',
        value: 'Switchboard',
      },
      {
        label: 'Team Leader',
        value: 'Team Leader',
      },
    ],
  },
  {
    key: 'Driving & Automotive',
    value: [
      {
        label: 'Aftersales',
        value: 'Aftersales',
      },
      {
        label: 'Bus Driver',
        value: 'Bus Driver',
      },
      {
        label: 'Body Repair & Fit',
        value: 'Body Repair & Fit',
      },
      {
        label: 'Driver',
        value: 'Driver',
      },
      {
        label: 'Driving Instructor',
        value: 'Driving Instructor',
      },
      {
        label: 'Driving Examiner',
        value: 'Driving Examiner',
      },
      {
        label: 'Delivery Drive',
        value: 'Delivery Drive',
      },
      {
        label: 'Finance & Leasing',
        value: 'Finance & Leasing',
      },
      {
        label: 'Fleet Operations',
        value: 'Fleet Operations',
      },
      {
        label: 'Machine Driver',
        value: 'Machine Driver',
      },
      {
        label: 'Other',
        value: 'Other',
      },
      {
        label: 'Parts Advisor',
        value: 'Parts Advisor',
      },
      {
        label: 'Quality & Testing',
        value: 'Quality & Testing',
      },
      {
        label: 'Service Receptionist',
        value: 'Service Receptionist',
      },
      {
        label: 'Taxi Driver',
        value: 'Taxi Driver',
      },
      {
        label: 'Train Driver',
        value: 'Train Driver',
      },
      {
        label: 'Valeting',
        value: 'Valeting',
      },
      {
        label: 'Vehicle Technician',
        value: 'Vehicle Technician',
      },
    ],
  },
  {
    key: 'Education',
    value: [
      {
        label: 'Adult Education',
        value: 'Adult Education',
      },
      {
        label: 'Advanced Skills Teacher',
        value: 'Advanced Skills Teacher',
      },
      {
        label: 'Assessor/Verifier',
        value: 'Assessor/Verifier',
      },
      {
        label: 'ESOL/TEFL',
        value: 'ESOL/TEFL',
      },
      {
        label: 'Education Administrator',
        value: 'Education Administrator',
      },
      {
        label: 'Educational Psychology',
        value: 'Educational Psychology',
      },
      {
        label: 'Further Education',
        value: 'Further Education',
      },
      {
        label: 'Head Teacher/Deputy Head Teacher',
        value: 'Head Teacher/Deputy Head Teacher',
      },
      {
        label: 'Higher Education',
        value: 'Higher Education',
      },
      {
        label: 'Invigilator',
        value: 'Invigilator',
      },
      {
        label: 'Learning Support Assistant',
        value: 'Learning Support Assistant',
      },
      {
        label: 'Lecturer',
        value: 'Lecturer',
      },
      {
        label: 'Nursery Teacher',
        value: 'Nursery Teacher',
      },
      {
        label: 'Other',
        value: 'Other',
      },
      {
        label: 'Special Needs',
        value: 'Special Needs',
      },
      {
        label: 'Supply Teacher',
        value: 'Supply Teacher',
      },
      {
        label: 'Teaching Assistant',
        value: 'Teaching Assistant',
      },
    ],
  },
  {
    key: 'Engineering',
    value: [
      {
        label: 'Aeronautic/Avionic Engineer',
        value: 'Aeronautic/Avionic Engineer',
      },
      {
        label: 'Bio-Engineer',
        value: 'Bio-Engineer',
      },
      {
        label: 'CAD/Drafting',
        value: 'CAD/Drafting',
      },
      {
        label: 'Civil Engineer',
        value: 'Civil Engineer',
      },
      {
        label: 'Chemical Engineer',
        value: 'Chemical Engineer',
      },
      {
        label: 'Electrical Engineer',
        value: 'Electrical Engineer',
      },
      {
        label: 'Energy/Nuclear Engineer',
        value: 'Energy/Nuclear Engineer',
      },
      {
        label: 'Engineer, Structural Engineer',
        value: 'Engineer, Structural Engineer',
      },
      {
        label: 'Environmental Engineer',
        value: 'Environmental Engineer',
      },
      {
        label: 'Industrial/Manufacturing Engineer',
        value: 'Industrial/Manufacturing Engineer',
      },
      {
        label: 'Maintenance Engineer',
        value: 'Maintenance Engineer',
      },
      {
        label: 'Mechanical Engineer',
        value: 'Mechanical Engineer',
      },
      {
        label: 'Other',
        value: 'Other',
      },
      {
        label: 'PDI',
        value: 'PDI',
      },

      {
        label: 'Systems/Process Engineer',
        value: 'Systems/Process Engineer',
      },
      {
        label: 'Thermal Engineer',
        value: 'Thermal Engineer',
      },


    ],
  },
  {
    key: 'Financial Services',
    value: [
      {
        label: 'Actuarial',
        value: 'Actuarial',
      },
      {
        label: 'Collections',
        value: 'Collections',
      },
      {
        label: 'Compliance',
        value: 'Compliance',
      },
      {
        label: 'Consultancy',
        value: 'Consultancy',
      },
      {
        label: 'Employee Benefits',
        value: 'Employee Benefits',
      },
      {
        label: 'Financial Advisor',
        value: 'Financial Advisor',
      },
      {
        label: 'General Management',
        value: 'General Management',
      },
      {
        label: 'Group Risk Advisor',
        value: 'Group Risk Advisor',
      },
      {
        label: 'Hedge Funds',
        value: 'Hedge Funds',
      },
      {
        label: 'Insolvency Advisor',
        value: 'Insolvency Advisor',
      },
      {
        label: 'Investments',
        value: 'Investments',
      },
      {
        label: 'Loan Advisor',
        value: 'Loan Advisor',
      },
      {
        label: 'Loan Underwriter',
        value: 'Loan Underwriter',
      },
      {
        label: 'Mortgage Administrator',
        value: 'Mortgage Administrator',
      },
      {
        label: 'Mortgage Advisor',
        value: 'Mortgage Advisor',
      },
      {
        label: 'Mortgage Underwriter',
        value: 'Mortgage Underwriter',
      },
      {
        label: 'Other',
        value: 'Other',
      },
      {
        label: 'Paraplanner',
        value: 'Paraplanner',
      },
      {
        label: 'Pensions',
        value: 'Pensions',
      },
      {
        label: 'Stockbroker',
        value: 'Stockbroker',
      },
    ],
  },
  {
    key: 'Health & Beauty',
    value: [
      {
        label: 'Hairdresser',
        value: 'Hairdresser',
      },
      {
        label: 'Beauty Therapist',
        value: 'Beauty Therapist',
      },
      {
        label: 'Massage Therapist',
        value: 'Massage Therapist',
      },
      {
        label: 'Nail Technician',
        value: 'Nail Technician',
      },
      {
        label: 'Other',
        value: 'Other',
      },
    ],
  },

  {
    key: 'Healthcare & Medical',
    value: [
      {
        label: 'Dental Hygienist',
        value: 'Dental Hygienist',
      },
      {
        label: 'EMT/Paramedic',
        value: 'EMT/Paramedic',
      },
      {
        label: 'Lab Technician',
        value: 'Lab Technician',
      },
      {
        label: 'Medical/Dental Assistant',
        value: 'Medical/Dental Assistant',
      },
      {
        label: 'Medical Practitioner',
        value: 'Medical Practitioner',
      },
      {
        label: 'Medical Therapy/Rehab Services',
        value: 'Medical Therapy/Rehab Services',
      },

      {
        label: 'Mental Health',
        value: 'Mental Health',
      },
      {
        label: 'Midwifery',
        value: 'Midwifery',
      },
      {
        label: 'Nursing',
        value: 'Nursing',
      },
      {
        label: 'Nutrition & Diet',
        value: 'Nutrition & Diet',
      },
      {
        label: 'Optical',
        value: 'Optical',
      },
      {
        label: 'Other',
        value: 'Other',
      },
      {
        label: 'Pharmacy',
        value: 'Pharmacy',
      },
      {
        label: 'Pathologist',
        value: 'Pathologist',
      },
      {
        label: 'Public Health Administration',
        value: 'Public Health Administration',
      },
      {
        label: 'Sports Medical Trainer ',
        value: 'Sports Medical Trainer ',
      },
    ],
  },
  {
    key: 'Hospitality & Catering',
    value: [
      {
        label: 'Assistant Manager',
        value: 'Assistant Manager',
      },
      {
        label: 'Bar Manager',
        value: 'Bar Manager',
      },
      {
        label: 'Barista',
        value: 'Barista',
      },
      {
        label: 'Caretaker',
        value: 'Caretaker',
      },
      {
        label: 'Chef Manager',
        value: 'Chef Manager',
      },
      {
        label: 'Chef de Partie',
        value: 'Chef de Partie',
      },
      {
        label: 'Chef de Rang',
        value: 'Chef de Rang',
      },
      {
        label: 'Commis-Chef',
        value: 'Commis-Chef',
      },
      {
        label: 'Concierge',
        value: 'Concierge',
      },
      {
        label: 'Night Manager',
        value: 'Night Manager',
      },
      {
        label: 'Other',
        value: 'Other',
      },
      {
        label: 'Porter',
        value: 'Porter',
      },
      {
        label: 'Receptionist',
        value: 'Receptionist',
      },
      {
        label: 'Restaurant Deputy Manager',
        value: 'Restaurant Deputy Manager',
      },
      {
        label: 'Restaurant Manager',
        value: 'Restaurant Manager',
      },
      {
        label: 'Sous Chef',
        value: 'Sous Chef',
      },
      {
        label: 'Waiting & Bar Staff',
        value: 'Waiting & Bar Staff',
      },
    ],
  },
  {
    key: 'HR',
    value: [
      {
        label: 'Change Management',
        value: 'Change Management',
      },
      {
        label: 'Compensation & Benefits',
        value: 'Compensation & Benefits',
      },
      {
        label: 'Compliance & Quality',
        value: 'Compliance & Quality',
      },
      {
        label: 'Employee Relations',
        value: 'Employee Relations',
      },
      {
        label: 'HR Administrator',
        value: 'HR Administrator',
      },
      {
        label: 'HR Advisor',
        value: 'HR Advisor',
      },
      {
        label: 'HR Analyst',
        value: 'HR Analyst',
      },
      {
        label: 'HR Assistant',
        value: 'HR Assistant',
      },
      {
        label: 'HR Business Partner',
        value: 'HR Business Partner',
      },
      {
        label: 'HR Consultant',
        value: 'HR Consultant',
      },
      {
        label: 'HR Director',
        value: 'HR Director',
      },
      {
        label: 'HR Manager',
        value: 'HR Manager',
      },
      {
        label: 'HR Officer',
        value: 'HR Officer',
      },
      {
        label: 'Head of HR',
        value: 'Head of HR',
      },
      {
        label: 'Health & Safety Officer',
        value: 'Health & Safety Officer',
      },
      {
        label: 'Learning and Development Officer',
        value: 'Learning and Development Officer',
      },
      {
        label: 'Organisational Development',
        value: 'Organisational Development',
      },
      {
        label: 'Other',
        value: 'Other',
      },
      {
        label: 'Recruitment & Resourcing',
        value: 'Recruitment & Resourcing',
      },
    ],
  },
  {
    key: 'Legal',
    value: [
      {
        label: 'Barrister Clerk',
        value: 'Barrister Clerk',
      },
      {
        label: 'Barrister',
        value: 'Barrister',
      },
      {
        label: 'Billing Co-ordinator',
        value: 'Billing Co-ordinator',
      },
      {
        label: 'Company Secretary',
        value: 'Company Secretary',
      },
      {
        label: 'Conveyancing',
        value: 'Conveyancing',
      },
      {
        label: 'General Legal Support',
        value: 'General Legal Support',
      },
      {
        label: 'Graduate Training & Internship Schemes',
        value: 'Graduate Training & Internship Schemes',
      },
      {
        label: 'In House',
        value: 'In House',
      },
      {
        label: 'Legal Cashier',
        value: 'Legal Cashier',
      },
      {
        label: 'Legal Executive',
        value: 'Legal Executive',
      },
      {
        label: 'Legal Secretary',
        value: 'Legal Secretary',
      },
      {
        label: 'Other',
        value: 'Other',
      },
      {
        label: 'Paralegal',
        value: 'Paralegal',
      },
      {
        label: 'Partner',
        value: 'Partner',
      },
      {
        label: 'Partnership Secretary',
        value: 'Partnership Secretary',
      },
      {
        label: 'Patent/Trademark',
        value: 'Patent/Trademark',
      },
      {
        label: 'Practice Manager',
        value: 'Practice Manager',
      },
      {
        label: 'Private Practice',
        value: 'Private Practice',
      },
      {
        label: 'Probate',
        value: 'Probate',
      },
      {
        label: 'Solicitor/Lawyer',
        value: 'Solicitor/Lawyer',
      },
    ],
  },
  {
    key: 'Leisure & Tourism',
    value: [
      {
        label: 'Accommodation Manager',
        value: 'Accommodation Manager',
      },
      {
        label: 'Airline',
        value: 'Airline',
      },
      {
        label: 'Beauty & Spa',
        value: 'Beauty & Spa',
      },
      {
        label: 'Business Travel',
        value: 'Business Travel',
      },
      {
        label: 'Children’s Activity',
        value: 'Children’s Activity',
      },
      {
        label: 'Cruise',
        value: 'Cruise',
      },
      {
        label: 'Health & Fitness',
        value: 'Health & Fitness',
      },
      {
        label: 'Museums & Galleries',
        value: 'Museums & Galleries',
      },
      {
        label: 'Other',
        value: 'Other',
      },
      {
        label: 'Personal Trainer',
        value: 'Personal Trainer',
      },
      {
        label: 'Reservations',
        value: 'Reservations',
      },
      {
        label: 'Sports Development',
        value: 'Sports Development',
      },
      {
        label: 'Sports Manager',
        value: 'Sports Manager',
      },
      {
        label: 'Sports Coach',
        value: 'Sports Coach',
      },
      {
        label: 'Travel Agent',
        value: 'Travel Agent',
      },
      {
        label: 'Travel Consultant',
        value: 'Travel Consultant',
      },
    ],
  },
  {
    key: 'Logistics, Distribution & Supply Chain',
    value: [
      {
        label: 'Airline',
        value: 'Airline',
      },
      {
        label: 'Courier',
        value: 'Courier',
      },
      {
        label: 'Depot Manager',
        value: 'Depot Manager',
      },
      {
        label: 'Driving',
        value: 'Driving',
      },
      {
        label: 'Export Clerk',
        value: 'Export Clerk',
      },
      {
        label: 'Import Clerk',
        value: 'Import Clerk',
      },
      {
        label: 'Import Co-Ordinator',
        value: 'Import Co-Ordinator',
      },
      {
        label: 'Loader/Shifter',
        value: 'Loader/Shifter',
      },
      {
        label: 'Logistics',
        value: 'Logistics',
      },
      {
        label: 'Other',
        value: 'Other',
      },
      {
        label: 'Picker/Packer',
        value: 'Picker/Packer',
      },
      {
        label: 'Postal Worker',
        value: 'Postal Worker',
      },
      {
        label: 'Rail',
        value: 'Rail',
      },
      {
        label: 'Shipping',
        value: 'Shipping',
      },
      {
        label: 'Stock Control',
        value: 'Stock Control',
      },
      {
        label: 'Supply Chain',
        value: 'Supply Chain',
      },
      {
        label: 'Team Leader',
        value: 'Team Leader',
      },
      {
        label: 'Transport Planner',
        value: 'Transport Planner',
      },
      {
        label: 'Warehouse Manager',
        value: 'Warehouse Manager',
      },
      {
        label: 'Warehouse Operative',
        value: 'Warehouse Operative',
      },
      {
        label: 'Warehouse Supervisor',
        value: 'Warehouse Supervisor',
      },
    ],
  },
  {
    key: 'Manufacturing',
    value: [
      {
        label: 'Automation',
        value: 'Automation',
      },
      {
        label: 'Assembler',
        value: 'Assembler',
      },
      {
        label: 'Computer Numerical Control (CNC)',
        value: 'Computer Numerical Control (CNC)',
      },
      {
        label: 'Factory/Floor Manager',
        value: 'Factory/Floor Manager',
      },
      {
        label: 'Forecast Analyst',
        value: 'Forecast Analyst',
      },
      {
        label: 'Maintenance',
        value: 'Maintenance',
      },
      {
        label: 'Other',
        value: 'Other',
      },
      {
        label: 'Production Manager',
        value: 'Production Manager',
      },
      {
        label: 'Production Planning Manager',
        value: 'Production Planning Manager',
      },
      {
        label: 'Quality Control',
        value: 'Quality Control',
      },
      {
        label: 'Semi-skilled Operator',
        value: 'Semi-skilled Operator',
      },
      {
        label: 'Skilled Operator',
        value: 'Skilled Operator',
      },
      {
        label: 'Supervisor',
        value: 'Supervisor',
      },
      {
        label: 'Textiles',
        value: 'Textiles',
      },
      {
        label: 'Waste management',
        value: 'Waste management',
      },
      {
        label: 'Workshop Manager',
        value: 'Workshop Manager',
      },
    ],
  },
  {
    key: 'Marketing, Advertising, & PR',
    value: [
      {
        label: 'Advertising/Sponsorship',
        value: 'Advertising/Sponsorship',
      },
      {
        label: 'Brand Management',
        value: 'Brand Management',
      },
      {
        label: 'Campaign Manager',
        value: 'Campaign Manager',
      },
      {
        label: 'Digital Marketing',
        value: 'Digital Marketing',
      },
      {
        label: 'Direct Marketing Executive',
        value: 'Direct Marketing Executive',
      },
      {
        label: 'Direct Marketing Manager',
        value: 'Direct Marketing Manager',
      },
      {
        label: 'Events Assistant',
        value: 'Events Assistant',
      },
      {
        label: 'Events Manager',
        value: 'Events Manager',
      },
      {
        label: 'Internal Communications',
        value: 'Internal Communications',
      },
      {
        label: 'Market Research Analyst',
        value: 'Market Research Analyst',
      },
      {
        label: 'Market Research Executive',
        value: 'Market Research Executive',
      },
      {
        label: 'Market Research Manager',
        value: 'Market Research Manager',
      },
      {
        label: 'Marketing Assistant',
        value: 'Marketing Assistant',
      },
      {
        label: 'Marketing Director',
        value: 'Marketing Director',
      },
      {
        label: 'Marketing Executive',
        value: 'Marketing Executive',
      },
      {
        label: 'Marketing Manager',
        value: 'Marketing Manager',
      },
      {
        label: 'Online Marketing Executive',
        value: 'Online Marketing Executive',
      },
      {
        label: 'Online Marketing Manager',
        value: 'Online Marketing Manager',
      },
      {
        label: 'Other',
        value: 'Other',
      },
      {
        label: 'PR Executive',
        value: 'PR Executive',
      },
      {
        label: 'PR Manager',
        value: 'PR Manager',
      },
      {
        label: 'Product Management',
        value: 'Product Management',
      },
      {
        label: 'Telemarketing',
        value: 'Telemarketing',
      },
    ],
  },
  {
    key: 'Media, Digital, & Creative',
    value: [
      {
        label: 'Account Director',
        value: 'Account Director',
      },
      {
        label: 'Account Executive',
        value: 'Account Executive',
      },
      {
        label: 'Account Manager',
        value: 'Account Manager',
      },
      {
        label: 'Animation',
        value: 'Animation',
      },
      {
        label: 'Artist',
        value: 'Artist',
      },
      {
        label: 'Arts & Entertainment',
        value: 'Arts & Entertainment',
      },
      {
        label: 'CAD Design',
        value: 'CAD Design',
      },
      {
        label: 'Copywriter',
        value: 'Copywriter',
      },
      {
        label: 'Creative Director',
        value: 'Creative Director',
      },
      {
        label: 'Designer',
        value: 'Designer',
      },
      {
        label: 'Editorial',
        value: 'Editorial',
      },
      {
        label: 'Events Designer',
        value: 'Events Designer',
      },
      {
        label: 'Fashion Designer',
        value: 'Fashion Designer',
      },
      {
        label: 'Games',
        value: 'Games',
      },
      {
        label: 'Graphic Designer',
        value: 'Graphic Designer',
      },
      {
        label: 'Interior Designer',
        value: 'Interior Designer',
      },
      {
        label: 'Journalism',
        value: 'Journalism',
      },
      {
        label: 'Media Sales',
        value: 'Media Sales',
      },
      {
        label: 'Music Industry',
        value: 'Music Industry',
      },
      {
        label: 'New Media',
        value: 'New Media',
      },
      {
        label: 'Other',
        value: 'Other',
      },
      {
        label: 'Photographer',
        value: 'Photographer',
      },
      {
        label: 'Product Design',
        value: 'Product Design',
      },
      {
        label: 'Project Manager',
        value: 'Project Manager',
      },
      {
        label: 'Publishing',
        value: 'Publishing',
      },
      {
        label: 'Radio',
        value: 'Radio',
      },
      {
        label: 'Researcher',
        value: 'Researcher',
      },
      {
        label: 'Search – SEO & PPC',
        value: 'Search – SEO & PPC',
      },
      {
        label: 'Television',
        value: 'Television',
      },
      {
        label: 'Traffic Manager',
        value: 'Traffic Manager',
      },
      {
        label: 'Web Design',
        value: 'Web Design',
      },
    ],
  },
  {
    key: 'Production/Operations',
    value: [
      {
        label: 'Agricultural & Farming',
        value: 'Agricultural & Farming',
      },
      {
        label: 'Assembly Line',
        value: 'Assembly Line',
      },
      {
        label: 'Cleaner',
        value: 'Cleaner',
      },
      {
        label: 'Gardener',
        value: 'Gardener',
      },
      {
        label: 'Hazardous Materials Handling',
        value: 'Hazardous Materials Handling',
      },
      {
        label: 'Machining – CNC',
        value: 'Machining – CNC',
      },
      {
        label: 'Metal Fabrication & Welding',
        value: 'Metal Fabrication & Welding',
      },
      {
        label: 'Moldmaking/Casting',
        value: 'Moldmaking/Casting',
      },
      {
        label: 'Operations/Plant Management',
        value: 'Operations/Plant Management',
      },
      {
        label: 'Productions/Operations Planning',
        value: 'Productions/Operations Planning',
      },
      {
        label: 'Telecommunications Technician',
        value: 'Telecommunications Technician',
      },
      {
        label: 'Waste Pickup/Removal',
        value: 'Waste Pickup/Removal',
      },
    ],
  },
  {
    key: 'Training',
    value: [
      {
        label: 'Corporate Development & Training',
        value: 'Corporate Development & Training',
      },
      {
        label: 'Design Author',
        value: 'Design Author',
      },
      {
        label: 'Director',
        value: 'Director',
      },
      {
        label: 'Disability Inclusion',
        value: 'Disability Inclusion',
      },
      {
        label: 'Driving Instructor',
        value: 'Driving Instructor',
      },
      {
        label: 'Finance Trainer',
        value: 'Finance Trainer',
      },
      {
        label: 'Freelance Trainer',
        value: 'Freelance Trainer',
      },
      {
        label: 'Learning & Development',
        value: 'Learning & Development',
      },
      {
        label: 'Other',
        value: 'Other',
      },
      {
        label: 'Product Trainer',
        value: 'Product Trainer',
      },
      {
        label: 'Sales Trainer',
        value: 'Sales Trainer',
      },
      {
        label: 'Training Assistant',
        value: 'Training Assistant',
      },
      {
        label: 'Training Manager',
        value: 'Training Manager',
      },
    ],
  },

  {
    key: 'Purchasing & Procurement',
    value: [
      {
        label: 'Assistant Buyer',
        value: 'Assistant Buyer',
      },
      {
        label: 'Buyer',
        value: 'Buyer',
      },
      {
        label: 'Contract Manager',
        value: 'Contract Manager',
      },
      {
        label: 'Head of Procurement',
        value: 'Head of Procurement',
      },
      {
        label: 'Other',
        value: 'Other',
      },
      {
        label: 'Procurement',
        value: 'Procurement',
      },
      {
        label: 'Procurement Manager',
        value: 'Procurement Manager',
      },
      {
        label: 'Purchasing Manager',
        value: 'Purchasing Manager',
      },
      {
        label: 'Purchasing Support',
        value: 'Purchasing Support',
      },
      {
        label: 'Senior Buyer',
        value: 'Senior Buyer',
      },
      {
        label: 'Stock Control',
        value: 'Stock Control',
      },
    ],
  },
  {
    key: 'Retail & FMCG',
    value: [
      {
        label: 'Area Manager',
        value: 'Area Manager',
      },
      {
        label: 'Floor Manager',
        value: 'Floor Manager',
      },
      {
        label: 'Merchandiser',
        value: 'Merchandiser',
      },
      {
        label: 'National Accounts',
        value: 'National Accounts',
      },
      {
        label: 'Operations, Other',
        value: 'Operations, Other',
      },
      {
        label: 'Other',
        value: 'Other',
      },
      {
        label: 'Sales Assistant',
        value: 'Sales Assistant',
      },
      {
        label: 'Store Manager',
        value: 'Store Manager',
      },
      {
        label: 'Store Staff',
        value: 'Store Staff',
      },
      {
        label: 'Team Leader',
        value: 'Team Leader',
      },
    ],
  },
  {
    key: 'Sales',
    value: [
      {
        label: 'Account Manager',
        value: 'Account Manager',
      },
      {
        label: 'Business Development Manager',
        value: 'Business Development Manager',
      },
      {
        label: 'Direct Sales',
        value: 'Direct Sales',
      },
      {
        label: 'Field Sales',
        value: 'Field Sales',
      },
      {
        label: 'Mortgage Advisor',
        value: 'Mortgage Advisor',
      },
      {
        label: 'Other Sales',
        value: 'Other Sales',
      },
      {
        label: 'Sales Director',
        value: 'Sales Director',
      },
      {
        label: 'Sales Executive',
        value: 'Sales Executive',
      },
      {
        label: 'Sales Support',
        value: 'Sales Support',
      },
      {
        label: 'Sales Manager',
        value: 'Sales Manager',
      },
      {
        label: 'Telesales',
        value: 'Telesales',
      },
      {
        label: 'Travel Agent/Ticket Sales',
        value: 'Travel Agent/Ticket Sales',
      },

    ],
  },
  {
    key: 'Scientific',
    value: [
      {
        label: 'Analyst',
        value: 'Analyst',
      },
      {
        label: 'Analytical Chemistry',
        value: 'Analytical Chemistry',
      },
      {
        label: 'Bioanalysis',
        value: 'Bioanalysis',
      },
      {
        label: 'Bioinformatics',
        value: 'Bioinformatics',
      },
      {
        label: 'Biotechnology',
        value: 'Biotechnology',
      },
      {
        label: 'Chemical Engineering',
        value: 'Chemical Engineering',
      },
      {
        label: 'Chemistry',
        value: 'Chemistry',
      },
      {
        label: 'Clinical Data Management',
        value: 'Clinical Data Management',
      },
      {
        label: 'Clinical Research/Trials',
        value: 'Clinical Research/Trials',
      },
      {
        label: 'Earth Science',
        value: 'Earth Science',
      },
      {
        label: 'Ecology',
        value: 'Ecology',
      },
      {
        label: 'Environmental Science',
        value: 'Environmental Science',
      },
      {
        label: 'Food Science',
        value: 'Food Science',
      },
      {
        label: 'Forensic Science',
        value: 'Forensic Science',
      },
      {
        label: 'Formulation',
        value: 'Formulation',
      },
      {
        label: 'Geoscience',
        value: 'Geoscience',
      },
      {
        label: 'Inorganic Chemistry',
        value: 'Inorganic Chemistry',
      },
      {
        label: 'Laboratory Technician',
        value: 'Laboratory Technician',
      },
      {
        label: 'Life Science',
        value: 'Life Science',
      },
      {
        label: 'Materials Science',
        value: 'Materials Science',
      },
      {
        label: 'Medical Devices',
        value: 'Medical Devices',
      },
      {
        label: 'Medical Information/Writing',
        value: 'Medical Information/Writing',
      },
      {
        label: 'Microbiology',
        value: 'Microbiology',
      },
      {
        label: 'Molecular Biology',
        value: 'Molecular Biology',
      },
      {
        label: 'Organic Chemistry',
        value: 'Organic Chemistry',
      },
      {
        label: 'Other',
        value: 'Other',
      },
      {
        label: 'Patent Specialists',
        value: 'Patent Specialists',
      },
      {
        label: 'Personal Care',
        value: 'Personal Care',
      },
      {
        label: 'Physics',
        value: 'Physics',
      },
      {
        label: 'Polymer Chemistry',
        value: 'Polymer Chemistry',
      },
      {
        label: 'Process Chemistry',
        value: 'Process Chemistry',
      },
      {
        label: 'Quality Assurance',
        value: 'Quality Assurance',
      },
      {
        label: 'Quality Control',
        value: 'Quality Control',
      },
      {
        label: 'Regulatory Affairs',
        value: 'Regulatory Affairs',
      },
      {
        label: 'Researcher',
        value: 'Researcher',
      },
      {
        label: 'SAS Programming',
        value: 'SAS Programming',
      },
      {
        label: 'Statistics',
        value: 'Statistics',
      },
      {
        label: 'Validation',
        value: 'Validation ',
      },
    ],
  },
  {
    key: 'Safety & Security',
    value: [
      {
        label: 'CCTV Operator',
        value: 'CCTV Operator',
      },
      {
        label: 'Counter Terrorist Cleared',
        value: 'Counter Terrorist Cleared',
      },
      {
        label: 'Environmental',
        value: 'Environmental',
      },
      {
        label: 'Event Safety Steward',
        value: 'Event Safety Steward',
      },
      {
        label: 'Fire Safety',
        value: 'Fire Safety',
      },
      {
        label: 'Health & Safety Officer',
        value: 'Health & Safety Officer',
      },
      {
        label: 'Health & Safety',
        value: 'Health & Safety',
      },
      {
        label: 'Law Enforcement',
        value: 'Law Enforcement',
      },
      {
        label: 'Military, Other',
        value: 'Military, Other',
      },
      {
        label: 'Parking Attendant',
        value: 'Parking Attendant',
      },
      {
        label: 'Probation/Prison Service',
        value: 'Probation/Prison Service',
      },
      {
        label: 'Security Consultant',
        value: 'Security Consultant',
      },
      {
        label: 'Security Contracts Manager',
        value: 'Security Contracts Manager',
      },
      {
        label: 'Security Guard',
        value: 'Security Guard',
      },
      {
        label: 'Security Officer',
        value: 'Security Officer',
      },
      {
        label: 'Traffic Warden',
        value: 'Traffic Warden',
      },
    ],
  },
  {
    key: 'Social Care',
    value: [
      {
        label: 'Advice Worker',
        value: 'Advice Worker',
      },
      {
        label: 'Approved Social Worker',
        value: 'Approved Social Worker',
      },
      {
        label: 'Benefits Assessor',
        value: 'Benefits Assessor',
      },
      {
        label: 'Care Assistant',
        value: 'Care Assistant',
      },
      {
        label: 'Care Manager',
        value: 'Care Manager',
      },
      {
        label: 'Care Worker',
        value: 'Care Worker',
      },
      {
        label: 'Case Worker',
        value: 'Case Worker',
      },
      {
        label: 'Childcare/Nannyt',
        value: 'Childcare/Nanny',
      },
      {
        label: 'Elderly Care',
        value: 'Elderly Care',
      },
      {
        label: 'Family Support',
        value: 'Family Support',
      },
      {
        label: 'Home Carer',
        value: 'Home Carer',
      },
      {
        label: 'Homelessness',
        value: 'Homelessness',
      },
      {
        label: 'Housing',
        value: 'Housing',
      },
      {
        label: 'Learning Mentors',
        value: 'Learning Mentors',
      },
      {
        label: 'Nursery Assistan',
        value: 'Nursery Assistan',
      },
      {
        label: 'Other',
        value: 'Other',
      },
      {
        label: 'Project Worker',
        value: 'Project Worker',
      },
      {
        label: 'Registered Manager',
        value: 'Registered Manager',
      },
      {
        label: 'Residential Child Care',
        value: 'Residential Child Care',
      },
      {
        label: 'Residential Support',
        value: 'Residential Support',
      },
      {
        label: 'Social Care Assessment',
        value: 'Social Care Assessment',
      },
      {
        label: 'Social Worker',
        value: 'Social Worker',
      },

      {
        label: 'Support Worker',
        value: 'Support Worker',
      },
      {
        label: 'Youth Worker',
        value: 'Youth Worker',
      },
    ],
  },
  {
    key: 'Strategy & Consultancy',
    value: [
      {
        label: 'Bids & Tenders',
        value: 'Bids & Tenders',
      },
      {
        label: 'Business Advisor',
        value: 'Business Advisor',
      },
      {
        label: 'Business Analyst',
        value: 'Business Analyst',
      },
      {
        label: 'Change Management',
        value: 'Change Management',
      },
      {
        label: 'Economist',
        value: 'Economist',
      },
      {
        label: 'Management Consultant',
        value: 'Management Consultant',
      },
      {
        label: 'Operations Manager',
        value: 'Operations Manager',
      },
      {
        label: 'Other',
        value: 'Other',
      },
      {
        label: 'Outsourcing/Offshoring',
        value: 'Outsourcing/Offshoring',
      },
      {
        label: 'Policy',
        value: 'Policy',
      },
      {
        label: 'Programme Management',
        value: 'Programme Management',
      },
      {
        label: 'Statistician',
        value: 'Statistician',
      },
      {
        label: 'Strategy Consultant',
        value: 'Strategy Consultant',
      },
      {
        label: 'Technical Project Manager',
        value: 'Technical Project Manager',
      },
    ],
  },
];


export const weekDaysOption = [
  { label: 'Week', value: 'week' },
  { label: 'Month', value: 'month' }
];