import React from 'react';
import { Row } from 'react-bootstrap';
import PortletBody from '../../../app/components/portlet/PortletBody';
import { StyledSpan, Wrap } from '../../../assets/styledComponents/Global';
import styled from 'styled-components';
import FormikAsyncSelect from '../../../app/components/formikComponents/FormikAsyncSelect';
import TotalJobIcon from '../../../app/components/svgIcons/TotalJobIcon';
import { quickapply, salaryRates } from '../CampaignsUtils';
import FormikInputField from '../../../app/components/formikComponents/FormikInputField';


const TotalJobsEditForm = ({values}) => {
  return (
    <>
      <PortletBody className="w-100">
        <div className="p-4">
        <StyledWrap className={`d-flex align-items-center justify-content-start gap-3`}>
          <TotalJobIcon size={24} />
          <Wrap className='d-flex flex-column'>
            <StyledSpan size='16px' className='text fw-semibold'>Total Jobs</StyledSpan>
          </Wrap>
        </StyledWrap>
          <div className="p-2">
            <Row className="mt-2">
 <div className="col-12 col-md-6">
                <div className="mb-3">
                  <label htmlFor="liveDays" className="form-label">
                    Expiry (In Days)
                  </label>
                  <FormikInputField
                    name="liveDays"
                    id="liveDays"
                    type="number"
                    min="1"
                    placeholder="Write live days"
                    disabled={values?.jobBoardSummaryKeys?.totalObject}
                  />
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="mb-3">
                  <label htmlFor="rateUnit" className="form-label">
                  Salary Rate
                  </label>
                  <FormikAsyncSelect
                        name="rateUnit"
                        id="rateUnit"
                        searchAble={false}
                        options={salaryRates}
                        placeholder="Select rate per unit"
                        isDisabled={values?.jobBoardSummaryKeys?.totalObject}
                    />
                </div>
              </div>
            </Row>

            <Row className="mt-2">
              <div className="col-12 col-md-6">
                <div className="mb-3">
                  <label htmlFor="euOnly" className="form-label">
                  EU Indicator
                  </label>
                  <FormikAsyncSelect
                        name="euOnly"
                        id="euOnly"
                        options={quickapply}
                        searchAble={false}
                        placeholder="Select eu view"
                        isDisabled={values?.jobBoardSummaryKeys?.totalObject}
                    />
                </div>
              </div>
            </Row>
          </div>
        </div>
      </PortletBody>
    </>
  );
};

const StyledWrap = styled.div`
margin : 1px 1px 0px 0px;
`;
export default TotalJobsEditForm;
