import { forEach, isEmpty, set } from 'lodash';
import { apiSlice } from '../../app/services/apiSlice';
import {
  formateDataByMonth,
  transformDataSorted,
  getDataValueAgainstLabels,
  getUniqueSortedLabelsByYear
} from './assets/analyticsUtil';
import { STATUSES } from '../../utils/Statuses';
import { convertToShortForm } from '../../utils/helper';

const REPORTS_URL = 'backoffice/reports/';

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    overviewSummery: builder.query({
      query: (params) => ({
        url: `${REPORTS_URL}overview-summary`,
        params,
      }),
      transformResponse: (responseData) => {
        if (!responseData) return {};

        const {
          jobsPosted = 0,
          applicants = 0,
          filledPositions = 0,
          totalPositions = 0,
          avgTimeToHire = 0,
          campignCost = 0,
          hiredAppCount = 0,
          jobsCount = 0,
        } = responseData;

        return {
          jobsPosted: String(jobsPosted),
          applicants: String(applicants),
          jobsCount: String(jobsCount),
          timeToHire: avgTimeToHire
            ? `${Number(Math.ceil(avgTimeToHire))} ${
                Number(Math.ceil(avgTimeToHire)) > 1 ? 'days' : 'day'
              }`
            : '-',
          costToHire: hiredAppCount
            ? `£ ${(campignCost / hiredAppCount).toFixed(2)}`
            : '-',
          applicantPerJob: jobsCount ? Math.round(applicants / jobsCount) : '-',
          fillRate: totalPositions
            ? `${((filledPositions / totalPositions) * 100).toFixed(2)}%`
            : '-',
          costPerApplicant: applicants
            ? `£ ${(campignCost / applicants).toFixed(2)}`
            : '-',
          positionOpen: `${totalPositions - filledPositions}`,
        };
      },
    }),
    candidatepool: builder.query({
      query: (params) => ({
        url: `${REPORTS_URL}key-performance-metrics/candidate-pool`,
        params,
      }),
      transformResponse: (responseData) => {
        return responseData && responseData[0]
          ? Number(responseData[0]?.candPoll)
          : 0;
      },
    }),
    overviewPipeline: builder.query({
      query: (params) => ({
        url: `${REPORTS_URL}overview-pipeline`,
        params,
      }),
      transformResponse: (responseData) => {
        if (!responseData) return {};

        const { filledPositions = 0, totalPositions = 0 } = responseData;
        forEach(responseData?.pipelineGraphData, (current, i) => {
          if (i === 0) return;
          const prev = responseData?.pipelineGraphData[i - 1];
          if (prev) {
            const percentageIncrease = (current.count / prev.count) * 100;
            set(current, 'percentageIncrease', percentageIncrease);
          } else {
            set(current, 'percentageIncrease', 0);
          }
        });

        return {
          ...responseData,

          fillRate: totalPositions
            ? `${((filledPositions / totalPositions) * 100).toFixed(2)}%`
            : '-',
        };
      },
    }),
    deiFormStatus: builder.query({
      query: (params) => ({
        url: `reports/dei/section/form-status`,
        params,
      }),
      transformResponse: (responseData) =>
        responseData
          ? {
              ...(responseData?.data || {}),
            }
          : {},
    }),
    overviewFilters: builder.query({
      query: (params) => ({
        url: `${REPORTS_URL}overview-filters-data`,
        params,
      }),
      transformResponse: (responseData) => {
        responseData['campaignList'] = responseData?.campaignList?.map(
          (dt) => ({
            label: dt.campaignName,
            value: dt.campaignId,
          }),
        );
        responseData['channelList'] = responseData?.channelList?.map((dt) => ({
          label: dt.channelName,
          value: dt.channelId,
        }));
        responseData['jobCategoryList'] = responseData?.jobCategoryList?.map(
          (dt) => ({
            label: dt.jobCategoryName,
            value: dt.jobCategoryId,
          }),
        );
        responseData['jobTypeList'] = responseData?.jobTypeList?.map((dt) => ({
          label: dt.jobType,
          value: dt.jobType,
        }));
        return responseData || {};
      },
    }),

    candidatePoolDiversity: builder.query({
      query: (params) => ({
        url: `reports/dei/section/candidate/pool-diversity`,
        params,
      }),
      transformResponse: (responseData) => [...(responseData?.data || [])],
    }),

    deiBreakDownOffers: builder.query({
      query: (params) => ({
        url: `reports/dei/section/offer-base/group`,
        params,
      }),
      transformResponse: (responseData) => [...(responseData?.data || [])],
    }),

    deiPipeline: builder.query({
      query: (params) => ({
        url: `reports/dei/section/pipeline-diversity`,
        params,
      }),
      transformResponse: (responseData) => [...(responseData?.data || [])],
    }),

    deiOfferAcceptanceRate: builder.query({
      query: (params) => ({
        url: `reports/dei/section/offers-status`,
        params,
      }),
      transformResponse: (responseData) => responseData?.data || [],
    }),
    formQuestionDropdown: builder.query({
      query: (params) => ({
        url: `forms/dei/form-questions`,
        params,
      }),
      transformResponse: (responseData) => {
        responseData['options'] = responseData?.map((dt) => ({
          label: dt.section_label,
          value: dt.id,
        }));
        return responseData || {};
      },
    }),
    jobsGroupCount: builder.query({
      query: (params) => ({
        url: `${REPORTS_URL}jobs/section/group-count`,
        params,
      }),
      transformResponse: (responseData) => {
        if (!responseData) return {};

        const { filledPositions = 0, totalPositions = 0 } = responseData;

        return {
          ...responseData,
          fillRate: totalPositions
            ? `${((filledPositions / totalPositions) * 100).toFixed(2)}%`
            : '-',
        };
      },
    }),

    candidatesAnalysis: builder.query({
      query: (params) => ({
        url: `${REPORTS_URL}candidates/candidate-analysis`,
        params,
      }),
    }),
    analyticsJobAnalysis: builder.query({
      query: (params) => ({
        url: `${REPORTS_URL}jobs/job-analysis`,
        params,
      }),
    }),
    analyticsTopSpenders: builder.query({
      query: (params) => ({
        url: `${REPORTS_URL}top-spenders`,
        params,
      }),
    }),
    analyticsCampaignAnalysis: builder.query({
      query: (params) => ({
        url: `${REPORTS_URL}campaigns/campaign-analysis`,
        params,
      }),
    }),
    analyticsCopyWritten: builder.query({
      query: (params) => ({
        url: `${REPORTS_URL}jobs/copywritten`,
        params,
      }),
    }),
    analyticsCustomerBaseGrowth: builder.query({
      query: (params) => ({
        url: `${REPORTS_URL}customer-growth`,
        params,
      }),
      transformResponse: (responseData, meta, arg) => {
        const response = {};
        response.originalData = responseData;
        response.lineData = transformDataSorted(
          responseData?.customerBaseGrowth,
          arg?.groupBy,
        );
        response.lineData = formateDataByMonth(
          response?.lineData,
          'active_companies',
          arg?.groupBy,
          arg,
        );
        response.barData = transformDataSorted(
          responseData?.newAcquisitionData,
          arg?.groupBy,
        );


        response.barData = formateDataByMonth(
          response?.barData,
          'newAcquisitionCount',
          arg?.groupBy,
          arg,
        );
        response.barData2 = transformDataSorted(
          responseData?.churnData,
          arg?.groupBy,
        );

        response.barData2 = formateDataByMonth(
          response?.barData2,
          'churnCount',
          arg?.groupBy,
          arg,
        );

        return response;
      },
    }),
    analyticsCreditsConsump: builder.query({
      query: (params) => ({
        url: `${REPORTS_URL}credit-consumption`,
        params,
      }),
      transformResponse: (responseData, meta, arg) => {
        const responce = {};
        let line = responseData.filter(
          (item) => item.transactionType === 'Debit',
        );
        let bar = responseData.filter(
          (item) => item.transactionType === 'Credit',
        );
        responce.originalData = responseData;
        responce.lineData = transformDataSorted(line, arg?.groupBy);
        responce.lineData = formateDataByMonth(
          responce?.lineData,
          'amount',
          arg?.groupBy,
          arg,
        );
        responce.barData = transformDataSorted(bar, arg?.groupBy);
        responce.barData = formateDataByMonth(
          responce?.barData,
          'amount',
          arg?.groupBy,
          arg,
        );
        return responce;
      },
    }),
    analyticsKpiMetrics: builder.query({
      query: (params) => ({
        url: `${REPORTS_URL}key-performance-metrics`,
        params,
      }),
      transformResponse: (responseData) => {
        if (isEmpty(responseData)) return {};
        const {
          applicantQuality = 0,
          applicationRecieved = 0,
          campaignRun = 0,
          hiredApplicants = 0,
          hiringPlanApproved = 0,
          // interviewsScheduled = 0,
          offerApplicants = 0,
          positionCreated = 0,
          screenApplicants = 0,
          selectApplicants = 0,
          timeToOffer = 0,
          cost = 0,
        } = responseData[0];

        return {
          positionCreated: `${positionCreated || '0'}`,
          applicationRecieved: String(applicationRecieved || '0'),
          hiredApplicants: hiredApplicants,
          fillRate:
            Number(hiredApplicants) !== 0 && Number(positionCreated) !== 0
              ? `${Math.round(
                  (Number(hiredApplicants) / Number(positionCreated)) * 100,
                ).toFixed(1)}%`
              : '-',
          costPerHire:
            Number(cost) !== 0 && Number(hiredApplicants) !== 0
              ? `£${Math.round(
                  Number(cost) / Number(hiredApplicants).toFixed(1),
                )}`
              : '-',
          hiringPlanApproved: `${hiringPlanApproved}`,
          timeToOffer: timeToOffer
            ? `${Number(timeToOffer).toFixed(1)} days`
            : 0,
          totalCampaignRun: Number(campaignRun),
          campaignCost: `£${Number(cost) !== 0 ? Number(cost).toFixed(1) : 0}`,
          costPerApplicant:
            Number(cost) !== 0 && Number(applicationRecieved) !== 0
              ? `£${Math.round(
                  Number(cost) / Number(applicationRecieved),
                ).toFixed(1)}`
              : '-',
          applicantQuality:
            Number(applicantQuality) !== 0 && Number(applicationRecieved) !== 0
              ? `${Math.ceil(
                  (Number(applicantQuality) / Number(applicationRecieved)) *
                    100,
                ).toFixed(1)}%`
              : '-',
          hireRatio:
            Number(hiredApplicants) !== 0 && Number(offerApplicants) !== 0
              ? `${Math.round(
                  (Number(hiredApplicants) / Number(offerApplicants)) * 100,
                ).toFixed(1)}%`
              : '-',
          candidatePool: '0',
          screened: `${screenApplicants || '0'}`,
          selected: `${selectApplicants || '0'}`,
          offered: offerApplicants,
        };
      },
    }),

    adminOverview: builder.query({
      query: (params) => ({
        url: `${REPORTS_URL}admin-overview`,
        params,
      }),
      transformResponse: (responseData) => {
        if (isEmpty(responseData)) return {};
        const {
          balanceCredit = 0,
          activeCustomers = 0,
          inactiveCustomers = 0,
          newCustomer = 0,
          supscriptionExpiry = 0,
          lowCreditsWarning = 0,
        } = responseData;

        return {
          lowCreditsWarning: `${convertToShortForm(lowCreditsWarning)}`,
          balanceCredit: convertToShortForm(balanceCredit),
          activeCustomers: `${convertToShortForm(activeCustomers)}`,
          inactiveCustomers: `${convertToShortForm(inactiveCustomers)}`,
          newCustomer: `${convertToShortForm(newCustomer)}`,
          supscriptionExpiry: `${convertToShortForm(supscriptionExpiry)}`,
        };
      },
    }),

    overviewPipelineProgression: builder.query({
      query: (params) => ({
        url: `${REPORTS_URL}overview/hp-progression`,
        params,
      }),
      transformResponse: (responseData) => {
        if (isEmpty(responseData)) return 'NoData';

        const {
          applicationRecieved = 0,
          screenApplicants = 0,
          selectApplicants = 0,
          offerApplicants = 0,
          hiredApplicants = 0,
        } = responseData[0];
        return {
          ...responseData[0],
          screenPercentage:
            Number(screenApplicants) !== 0 && Number(applicationRecieved) !== 0
              ? (Number(screenApplicants) / Number(applicationRecieved)) * 100
              : 0,
          selectPercentage:
            Number(screenApplicants) !== 0 && Number(selectApplicants) !== 0
              ? (Number(selectApplicants) / Number(screenApplicants)) * 100
              : 0,
          offerPercentage:
            Number(offerApplicants) !== 0 && Number(selectApplicants) !== 0
              ? (Number(offerApplicants) / Number(selectApplicants)) * 100
              : 0,
          hirePercentage:
            Number(hiredApplicants) !== 0 && Number(offerApplicants) !== 0
              ? (Number(hiredApplicants) / Number(offerApplicants)) * 100
              : 0,
        };
      },
    }),

    candidatesByLocation: builder.query({
      query: (params) => ({
        url: `${REPORTS_URL}candidates/location`,
        params,
      }),
    }),

    candidatesSummery: builder.query({
      query: (params) => ({
        url: `${REPORTS_URL}candidates/section/summary`,
        params,
      }),
      transformResponse: (responseData) => {
        const data = responseData?.data || {};
        const candCount = String(data?.candCount ?? '');
        const shortlisted = String(data?.shortlisted ?? '');
        const appPerCandidate = data?.candCount
          ? Math.ceil(Number(data?.appCount ?? 0) / Number(data?.candCount))
          : '-';
        const hireRatio = data?.offerSent
          ? `${(
              (Number(data?.offerComplete ?? 0) / Number(data?.offerSent)) *
              100
            )?.toFixed(2)}%`
          : '-';
        return { ...data, candCount, shortlisted, appPerCandidate, hireRatio };
      },
    }),
    overviewHiringTrend: builder.query({
      query: (params) => ({
        url: `${REPORTS_URL}jobs/hiring-trend`,
        params,
      }),
      transformResponse: (responseData, meta, arg) => {
        const responce = {};
        responce.originalData = responseData;
        responce.labels = getUniqueSortedLabelsByYear([...responseData?.candidateHired,...responseData?.positionCreated],  arg?.groupBy);
        responce.lineData = getDataValueAgainstLabels(responce.labels,responseData?.candidateHired, arg?.groupBy, 'hired')
        responce.barData = getDataValueAgainstLabels(responce.labels,responseData?.positionCreated, arg?.groupBy, 'positionCreated')
        return responce;
      },
    }),
    campaignPerformance: builder.query({
      query: (params) => ({
        url: `${REPORTS_URL}campaigns/campaign-performance`,
        params,
      }),
      transformResponse: (responseData, meta, arg) => {
        const responce = {};
        responce.originalData = responseData;
        responce.labels = getUniqueSortedLabelsByYear([...responseData?.applicationReceive,...responseData?.campaignCost],  arg?.groupBy);
        responce.lineData = getDataValueAgainstLabels(responce.labels,responseData?.applicationReceive, arg?.groupBy, 'appReceived') 
        responce.barData = getDataValueAgainstLabels(responce.labels,responseData?.campaignCost, arg?.groupBy, 'cost')
        return responce;
      },
    }),
    overviewCampaignPerformance: builder.query({
      query: (params) => ({
        url: `${REPORTS_URL}overview/campaign-performance`,
        params,
      }),
      transformResponse: (responseData, meta, arg) => {
        const responce = {};
        responce.data = transformDataSorted(responseData, arg?.groupBy);
        responce.lineData1 = formateDataByMonth(
          responce?.data,
          'costPerApplicant',
          arg?.groupBy,
          arg,
        );
        responce.lineData2 = formateDataByMonth(
          responce?.data,
          'appliedToQualifiedRatio',
          arg?.groupBy,
          arg,
        );
        return responce;
      },
    }),

    getinterviewScheduled: builder.query({
      query: (params) => ({
        url: `${REPORTS_URL}jobs/interviews-scheduled`,
        params,
      }),
    }),
    reasonsForRejection: builder.query({
      query: (params) => ({
        url: `${REPORTS_URL}candidates/rejection-reason`,
        params,
      }),
    }),
    hiringEfficieny: builder.query({
      query: (params) => ({
        url: `${REPORTS_URL}overview/hiring-efficiency`,
        params,
      }),
      transformResponse: (responseData, meta, arg) => {
        const responce = {};
        responce.data = transformDataSorted(responseData, arg?.groupBy);
        responce.lineData1 = formateDataByMonth(
          responce?.data,
          'costPerHire',
          arg?.groupBy,
          arg,
        );
        responce.lineData2 = formateDataByMonth(
          responce?.data,
          'fillRate',
          arg?.groupBy,
          arg,
        );
        return responce;
      },
    }),
    candidatesChannelEffectiveness: builder.query({
      query: (params) => ({
        url: `${REPORTS_URL}campaigns/candidate-analysis`,
        params,
      }),
      transformResponse: (responseData) => {
        return responseData || [];
      },
    }),
    getAsyncChildCompaniesListing: builder.mutation({
      query: (data) => ({
        url: `company/all-companies`,
        method: 'POST',
        body: {
          ...data,
        },
      }),
    }),
    deiReportDownload: builder.query({
      query: (params) => ({
        url: `${REPORTS_URL}dei/section/download`,
        params,
      }),
    }),

    getFilterCompanyJobs: builder.mutation({
      query: (params) => ({
        url: `${REPORTS_URL}get-jobs-title`,
        params: { ...params, sort: 'title', sortDir: 'ASC' },
      }),
      transformResponse: (responseData) => {
        responseData.options = responseData?.rows?.map((job) => ({
          ...job,
          value: job.id,
          label: job.title,
        }));
        return responseData || {};
      },
    }),

    getCompanyJobCategories: builder.mutation({
      query: (params) => ({
        url: `${REPORTS_URL}company-job-categories`,
        params: { ...params, sort: 'title', sortDir: 'ASC' },
      }),
      transformResponse: (responseData) => {
        responseData.options = responseData?.rows?.map((cat) => ({
          ...cat,
          value: cat.id,
          label: cat.title,
        }));
        return responseData || {};
      },
    }),

    getCompanyDepartment: builder.mutation({
      query: (params) => ({
        url: `${REPORTS_URL}company-depts`,
        params: { ...params, sort: 'title', sortDir: 'ASC' },
      }),
      transformResponse: (responseData) => {
        responseData.options = responseData?.rows?.map((cat) => ({
          ...cat,
          value: cat.id,
          label: cat.title,
        }));
        return responseData || {};
      },
    }),

    getCompanyHiringManager: builder.mutation({
      query: (params) => ({
        url: `${REPORTS_URL}company-accounts`,
        params: { ...params, roleAccessKey: 'HIRING_MANAGER' },
      }),
      transformResponse: (responseData) => {
        responseData.options = responseData?.accounts?.map((team) => ({
          ...team,
          value: team.id,
          label: team?.firstName + ' ' + team?.lastName,
        }));
        return responseData || {};
      },
    }),

    getCompanyApprover: builder.mutation({
      query: (params) => ({
        url: `${REPORTS_URL}company-accounts`,
        params: {
          ...params,
          roleAccessKey: 'APPROVER',
          filters: STATUSES.ACTIVE,
        },
      }),
      transformResponse: (responseData) => {
        responseData.options = responseData?.accounts?.map((team) => ({
          ...team,
          value: team.id,
          label: team?.firstName + ' ' + team?.lastName,
        }));
        return responseData || {};
      },
    }),

    getJobCampaign: builder.mutation({
      query: (params) => ({
        url: `${REPORTS_URL}company-campaigns`,
        params,
      }),
      transformResponse: (responseData) => {
        responseData.options = responseData?.data?.map((camp) => ({
          ...camp,
          value: camp.campaign.id,
          label: camp.campaign.campaignName,
        }));

        return responseData || {};
      },
    }),

    getJobNameForCampaign: builder.mutation({
      query: (params) => ({
        url: `${REPORTS_URL}company-campaigns`,
        params,
      }),
      transformResponse: (responseData) => {
        responseData.options = responseData?.data?.map((camp) => ({
          ...camp,
          value: camp.campaign.id,
          label: camp.campaign.jobTitle,
        }));

        return responseData || {};
      },
    }),

    getCampaignChannel: builder.mutation({
      query: (params) => ({
        url: `${REPORTS_URL}company-products`,
        params,
      }),
      transformResponse: (responseData) => {
        responseData.options = responseData?.advertising?.map((channel) => ({
          ...channel,
          value: channel.id,
          label: channel.title,
        }));
        return responseData || {};
      },
    }),

    getJobRejectionReason: builder.mutation({
      query: (params) => ({
        url: `${REPORTS_URL}company-decline-reasons`,
        params,
      }),
      transformResponse: (responseData) => {
        responseData.options = responseData?.declineReasons?.map((reason) => ({
          ...reason,
          value: reason?.id,
          title: reason?.text,
        }));
        return responseData || {};
      },
    }),
  }),
});

export const {
  useAdminOverviewQuery,
  useGetAsyncChildCompaniesListingMutation,
  useAnalyticsCampaignAnalysisQuery,
  useAnalyticsJobAnalysisQuery,
  useAnalyticsTopSpendersQuery,
  useAnalyticsCopyWrittenQuery,
  useAnalyticsCreditsConsumpQuery,
  useAnalyticsCustomerBaseGrowthQuery,
  useOverviewSummeryQuery,
  useCandidatepoolQuery,
  useAnalyticsKpiMetricsQuery,
  useOverviewPipelineProgressionQuery,
  useOverviewPipelineQuery,
  useFormQuestionDropdownQuery,
  useDeiFormStatusQuery,
  useJobsGroupCountQuery,
  useCandidatePoolDiversityQuery,
  useOverviewFiltersQuery,
  useCandidatesByLocationQuery,
  useCandidatesSummeryQuery,
  useOverviewHiringTrendQuery,
  useCandidatesChannelEffectivenessQuery,
  useDeiOfferAcceptanceRateQuery,
  useDeiBreakDownOffersQuery,
  useDeiPipelineQuery,
  useDeiReportDownloadQuery,
  useCampaignPerformanceQuery,
  useOverviewCampaignPerformanceQuery,
  useHiringEfficienyQuery,
  useGetinterviewScheduledQuery,
  useCandidatesAnalysisQuery,
  useReasonsForRejectionQuery,
  useGetFilterCompanyJobsMutation,
  useGetCompanyJobCategoriesMutation,
  useGetCompanyDepartmentMutation,
  useGetCompanyHiringManagerMutation,
  useGetCompanyApproverMutation,
  useGetCampaignChannelMutation,
  useGetJobNameForCampaignMutation,
  useGetJobCampaignMutation,
  useGetJobRejectionReasonMutation,
} = extendedApiSlice;
