import { apiSlice } from '../../app/services/apiSlice';

const LICENSE_URL = '/backoffice/licences';
const PRODUCT_URL = '/backoffice/products';

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // addNewUser: builder.mutation({
    //   query: (data) => ({
    //     url: `${ACCOUNT_URL}`,
    //     method: 'POST',
    //     body: {
    //       ...data,
    //     },
    //   }),
    // }),
    // updateUser: builder.mutation({
    //   query: (data) => ({
    //     url: `${ACCOUNT_URL}/${data.id}`,
    //     method: 'PUT',
    //     body: {
    //       ...data,
    //     },
    //   }),
    // }),
    // emailTemplateCreation: builder.mutation({
    //   query: (data) => ({
    //     url: `templates`,
    //     method: 'POST',
    //     body: {
    //       ...data,
    //     },
    //   }),
    // }),
    addNewLicense: builder.mutation({
      query: (data) => ({
        url: `${LICENSE_URL}`,
        method: 'POST',
        body: {
          ...data,
        },
      }),
    }),
    getAllLicenses: builder.query({
      query: (params) => ({
        url: `${LICENSE_URL}`,
        params,
      }),
    }),

    getProductListing: builder.mutation({
      query: (params) => ({
        url: `${PRODUCT_URL}`,
        params: {...params,sort:'title',sortDir:'ASC'},
      }),
      transformResponse: (responseData) => {
        responseData.options = responseData?.products?.map((option) => ({
          label: option?.title,
          value: option?.id,
        }));
        return responseData || {};
      },
    }),

    getAllLicenceTypes: builder.mutation({
      query: (params) => ({
        url: `${LICENSE_URL}/types`,
        params: {...params,sort:'title',sortDir:'ASC'},
      }),
      transformResponse: (responseData) => {
        responseData.options = responseData?.LicenceTypes?.map((option) => ({
          label: option?.title,
          value: option?.id,
        }));
        return responseData || {};
      },
    }),

    deleteLicense: builder.mutation({
      query: (id) => ({
        url: `${LICENSE_URL}/${id}`,
        method: 'DELETE',
      }),
    }),

    getSingleLicenseById: builder.query({
      query: (id) => ({
        url: `${LICENSE_URL}/${id}`,
      }),
    }),

    updateLicenseInfo: builder.mutation({
      query: (data) => ({
        url: `${LICENSE_URL}/${data?.id}`,
        method: 'PUT',
        body: {
          ...data,
        },
      }),
    }),
  }),
});

export const {
  useAddNewLicenseMutation,
  useGetAllLicensesQuery,
  useGetProductListingMutation,
  useDeleteLicenseMutation,
  useGetSingleLicenseByIdQuery,
  useUpdateLicenseInfoMutation,
  useGetAllLicenceTypesMutation,
} = extendedApiSlice;
