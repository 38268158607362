import React from 'react';
import SearchIcon from '../../components/svgIcons/SearchIcon';
import StyledIconInput from '../../components/styledComponents/StyledIconInput';
import StyledProfileDropDown from '../../components/styledComponents/StyledProfileDropDown';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import HeaderSearchICon from '../../components/svgIcons/HeaderSearchIcon';
import useAuth from '../../../hooks/useAuth';
import LogoutIcon from '../../components/svgIcons/LogoutIcon';
import { removeCredentials, removeSideMenuState } from '../../../features/auth/authSlice';
import { useDispatch } from 'react-redux';

const Header = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const dispatch = useDispatch();
  const userMenuItems = [
    {
      name: 'Logout',
      icon: <LogoutIcon />,
      action: () => {
        dispatch(removeCredentials());
        dispatch(removeSideMenuState());
        navigate('/');
      },
    },
  ];
  // const companyMenuItems = [
  //   {
  //     name: 'C1',
  //     icon: <>C1</>,
  //     action: () => {
  //       occyLs.clear();
  //       navigate('/');
  //     },
  //   },
  // ];

  return (
    <StyledHeader className="bg-body">
      <div className="d-flex p-2 p-md-4 bg-white">
        <StyledIconInput
          icon={<SearchIcon />}
          width="50em"
          searchIcon={<HeaderSearchICon />}
        />
        <div className="d-flex">
          {/*<StyledProfileDropDown*/}
          {/*  profile={{*/}
          {/*    firstName: 'Parallel',*/}
          {/*  }}*/}
          {/*  menuItems={companyMenuItems}*/}
          {/*  toggleClassName={'bg-transparent'}*/}
          {/*/>*/}
          <StyledProfileDropDown
            menuItems={userMenuItems}
            profile={user}
            toggleClassName={'bg-transparent'}
          />
        </div>
      </div>
    </StyledHeader>
  );
};
const StyledHeader = styled.div`
  position: sticky;
  top: 0;
  z-index: 99;
  margin-bottom: 10px;

  .section-container {
    position: sticky;
    top: 0;
  }
`;
export default Header;
