
import OccyNewLogoIcon from "../../../app/components/svgIcons/OccyNewLogoIcon";
import * as Yup from 'yup';

export const basicCopywriteValidation = {
    elevatorPitch: Yup.string()
        .test(
            'characterLimit',
            'Character limit exceeded max character limit',
            function (value) {
                const { jobDescription, jobRequirement } = this.parent;
                const totalCharacters = (value || '')?.length + (jobDescription?.length || 0) + (jobRequirement?.length || 0);
                return totalCharacters <= 5000 || totalCharacters === 0;
            }
        ),
    jobDescription: Yup.string()
        .test(
            'characterLimit',
            'Character limit exceeded max character limit',
            function (value) {
                const { elevatorPitch, jobRequirement } = this.parent;
                const totalCharacters = (elevatorPitch?.length || 0) + (value || '')?.length + (jobRequirement?.length || 0);
                return totalCharacters <= 5000 || totalCharacters === 0;
            }
        ),
    jobRequirement: Yup.string()
        .test(
            'characterLimit',
            'Character limit exceeded max character limit',
            function (value) {
                const { elevatorPitch, jobDescription } = this.parent;
                const totalCharacters = (elevatorPitch?.length || 0) + (jobDescription?.length || 0) + (value || '')?.length;
                return totalCharacters <= 5000 || totalCharacters === 0;
            }
        ),
};

export const copyWriterVersionTab = [
    {
        id: 1,
        title: `Original`,
        active: true,
    },
    {
        id: 2,
        title: 'Copy Version 1',
        icon: <OccyNewLogoIcon color="#EC008C" />,
        active: false,
    },
    {
        id: 3,
        title: 'Copy Version 2',
        icon: <OccyNewLogoIcon />,
        active: false,
    }
];

export const copywriteType = [
    {
        label: 'Job Template',
        value: 'JOB_TEMPLATE',
    },
    {
        label: 'Job',
        value: 'JOB',
    }

];
export const copywriteStatus = [
    {
        label: 'Completed',
        value: 'COMPLETED',
    },
    {
        label: 'In review',
        value: 'IN_REVIEW',
    },
    {
        label: 'Revision Requested',
        value: 'REVISION_REQUESTED',
    },
    {
        label: 'In progress',
        value: 'IN_PROGRESS',
    },
    {
        label: 'Requested',
        value: 'REQUESTED',
    },
];

export const CopywriteSort = [
    {
        id: 1,
        label: 'None',
        value: 'requestedOn',
        dir: 'DESC',
        match: "checked1"
    },
    {
        id: 2,
        label: 'Title (A to Z)',
        value: 'title',
        dir: 'ASC',
        match: "checked2"
    },
    {
        id: 3,
        label: 'Title (Z to A)',
        value: 'title',
        dir: 'DESC',
        match: "checked3"
    },
    {
        id: 4,
        label: 'Company (A to Z)',
        value: 'company',
        dir: 'ASC',
        match: "checked4"
    },
    {
        id: 5,
        label: 'Company (Z to A)',
        value: 'company',
        dir: 'DESC',
        match: "checked5"
    },
    {
        id: 6,
        label: 'Requested on (Oldest to Newest)',
        value: 'requestedOn',
        dir: 'ASC',
        match: "checked6"
    },
    {
        id: 7,
        label: 'Requested (Newest to Oldest)',
        value: 'requestedOn',
        dir: 'DESC',
        match: "checked7"
    },
];