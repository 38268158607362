import React from 'react'

const RefreshIcon = ({ size = 16 }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.6666 7.99967C14.6666 11.6797 11.68 14.6663 7.99998 14.6663C4.31998 14.6663 2.07331 10.9597 2.07331 10.9597M2.07331 10.9597H5.08665M2.07331 10.9597V14.293M1.33331 7.99967C1.33331 4.31967 4.29331 1.33301 7.99998 1.33301C12.4466 1.33301 14.6666 5.03967 14.6666 5.03967M14.6666 5.03967V1.70634M14.6666 5.03967H11.7066" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    )
}

export default RefreshIcon