import React from 'react';

const ComapnyIcon = ({ size = 36, color = "#000000", ...props }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="36" height="36" rx="18" fill="#E6E6F9"/>
<path d="M12.1665 14.6667C10.7832 14.6667 9.6665 15.7833 9.6665 17.1667V23.8333C9.6665 25.2167 10.7832 26.3333 12.1665 26.3333H13.4165C14.3332 26.3333 15.0832 25.5833 15.0832 24.6667V16.3333C15.0832 15.4167 14.3332 14.6667 13.4165 14.6667H12.1665ZM12.9998 22.1667C12.9998 22.5083 12.7165 22.7917 12.3748 22.7917C12.0332 22.7917 11.7498 22.5083 11.7498 22.1667V18.8333C11.7498 18.4917 12.0332 18.2083 12.3748 18.2083C12.7165 18.2083 12.9998 18.4917 12.9998 18.8333V22.1667Z" fill="#35356F"/>
<path d="M23.8335 9.66666H18.8335C17.4502 9.66666 16.3335 10.7833 16.3335 12.1667V23.8333C16.3335 25.2167 17.4502 26.3333 18.8335 26.3333H19.2502C19.4835 26.3333 19.6668 26.15 19.6668 25.9167V23C19.6668 22.5417 20.0418 22.1667 20.5002 22.1667H22.1668C22.6252 22.1667 23.0002 22.5417 23.0002 23V25.9167C23.0002 26.15 23.1835 26.3333 23.4168 26.3333H23.8335C25.2168 26.3333 26.3335 25.2167 26.3335 23.8333V12.1667C26.3335 10.7833 25.2168 9.66666 23.8335 9.66666ZM20.2918 18.8333C20.2918 19.175 20.0085 19.4583 19.6668 19.4583C19.3252 19.4583 19.0418 19.175 19.0418 18.8333V14.6667C19.0418 14.325 19.3252 14.0417 19.6668 14.0417C20.0085 14.0417 20.2918 14.325 20.2918 14.6667V18.8333ZM23.6252 18.8333C23.6252 19.175 23.3418 19.4583 23.0002 19.4583C22.6585 19.4583 22.3752 19.175 22.3752 18.8333V14.6667C22.3752 14.325 22.6585 14.0417 23.0002 14.0417C23.3418 14.0417 23.6252 14.325 23.6252 14.6667V18.8333Z" fill="#35356F"/>
</svg>


  );
};

export default ComapnyIcon;
