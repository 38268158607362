import React from 'react';
import { Row } from 'react-bootstrap';
import FormikInputField from '../../../../app/components/formikComponents/FormikInputField';
import PortletBody from '../../../../app/components/portlet/PortletBody';
import FormikAsyncSelect from '../../../../app/components/formikComponents/FormikAsyncSelect';
import { useGetCountiesMutation } from '../../companyApiSlice';
import FormikSelect from '../../../../app/components/formikComponents/FormikSelect';
import { countryCodes } from '../../../Products/assets/utills';

const GeneralInfoForm = () => {
  const [getCounties] = useGetCountiesMutation();
  return (
    <>
      <PortletBody className="p-3 w-100">
        <div className="p-1 p-lg-2">
          <h5>General Information</h5>
          <div className="p-2">
            <Row className="mt-2">
              <div className="col-12 col-md-12">
                <div className="mb-3">
                  <label htmlFor="companyName" className="form-label">
                    Company Name
                  </label>
                  <FormikInputField
                    name={`companyName`}
                    id={`companyName`}
                    placeholder="Company Name"
                  />
                </div>
              </div>
            </Row>

            <Row className="mt-2">
              <div className="col-12 col-md-12">
                <label
                  htmlFor={`telephoneCode`}
                  className="form-label"
                >
                  Phone Number
                </label>
              </div>
              <div className="col-2 pe-1">
                <div className="mb-3">
                  <FormikSelect
                    className="me-0 pe-0"
                    name={`telephoneCode`}
                    id={`telephoneCode`}
                    placeholder="+44"
                    options={countryCodes}
                  />
                </div>
              </div>
              <div className="col-10 ps-0">
                <div className="mb-3">
                  <FormikInputField
                    name={`telephone`}
                    id={`telephone`}
                    placeholder="Phone Number"
                  />
                </div>
              </div>
            </Row>

            <Row className="mt-2">
              <div className="col-12 col-md-6">
                <div className="mb-3">
                  <label htmlFor="address" className="form-label">
                    Address
                  </label>
                  <FormikInputField
                    name={`address`}
                    id={`address`}
                    placeholder="Address"
                  />
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="mb-3">
                  <label htmlFor="buildingNo" className="form-label">
                    Building Number
                  </label>
                  <FormikInputField
                    name={`buildingNo`}
                    id={`buildingNo`}
                    placeholder="Building Number"
                  />
                </div>
              </div>
            </Row>
            <Row className="mt-2">
              <div className="col-12 col-md-6">
                <div className="mb-3">
                  <label htmlFor="postcode" className="form-label">
                    Post Code
                  </label>
                  <FormikInputField
                    name={`postcode`}
                    id={`postcode`}
                    placeholder="PostCode"
                  />
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="mb-3">
                  <label htmlFor="county" className="form-label">
                    County
                  </label>
                  <FormikAsyncSelect
                    apiCall={getCounties}
                    searchAble={true}
                    name={`county`}
                    id={`county`}
                    placeholder="Select County"
                  />
                </div>
              </div>
            </Row>
          </div>
        </div>
      </PortletBody>
    </>
  );
};

export default GeneralInfoForm;
