import React from 'react';
import Table from '../../../app/components/table/Table';

const SettingsListingTable = ({
  settings,
  sortColumn,
  onSort,
  tableRef,
  filterTable,
}) => {
  return (
    <Table
      data={settings}
      columns={filterTable}
      sortColumn={sortColumn}
      onSort={onSort}
      tableRef={tableRef}
    />
  );
};

export default SettingsListingTable;
