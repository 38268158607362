import React, { useEffect, useState } from 'react';
import PortletHeader from '../../../app/components/portlet/PortletHeader';
import StyledButton from '../../../app/components/styledComponents/StyledButton';
import Portlet from '../../../app/components/portlet/Portlet';
import TemplateListingTable from './ProductListingTable';
import PortletBody from '../../../app/components/portlet/PortletBody';
import StyledIconInput from '../../../app/components/styledComponents/StyledIconInput';
import SearchIcon from '../../../app/components/svgIcons/SearchIcon';
import SortIcon from '../../../app/components/svgIcons/SortIcon';
import PauseIcon from '../../../app/components/svgIcons/PauseIcon';
import PaginationPerPage from '../../../app/components/pagination/PaginationPerPage';
import { StyledFilterBar } from '../../../app/components/styledComponents/StyledFilterBar';
import StyledRadioButton from '../../../app/components/radio/StyledRadioButton';
import EditIcon from '../../../app/components/svgIcons/EditIcon';
import { Link } from 'react-router-dom';
import TrashIcon from '../../../app/components/svgIcons/TrashIcon';
import StyledCheckbox from '../../../app/components/checkbox/StyledCheckbox';
import CrossIcon from '../../../app/components/svgIcons/CrossIcon';
import StyledFilterTabs from '../../../app/components/styledComponents/StyledFilterTabs';
import { departments, sortData } from '../assets/utills';
import AddIcon from '../../../app/components/svgIcons/AddIcon';
import NewDownloadIcon from '../../../app/components/svgIcons/NewDownloadIcon';
import FilterIcon from '../../../app/components/svgIcons/FilterIcon';
import { isEqual } from 'lodash';
import { Form, Formik } from 'formik';
import FormikSelect from '../../../app/components/formikComponents/FormikSelect';
import { useGetAllProductsQuery, useRemoveProductMutation } from '../productApiSlice';
import { CSVLink } from 'react-csv';
import { toast } from 'react-toastify';
import { capitalizeFirstLetter } from '../../../utils/helper';

const ProductListingContainer = () => {
  const initialFilters = {
    jobs: [],
    perPage: 5,
    page: 1,
    sortedColumn: { path: 'createdAt', order: 'DESC' },
    query: '',
    msgType: 'EMAIL',
  };
  const [sortFilter, setSortFilter] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState(false);
  const [isOpen, setIsopen] = useState(false);
  const [listing, setListing] = useState({ ...initialFilters });
  const [newColumn, setNewColumn] = useState([]);
  const [deleteProduct] = useRemoveProductMutation();
  const { data: productResponse, refetch, isFetching } = useGetAllProductsQuery({
    perPage: listing.perPage,
    page: listing.page,
    query: listing.query && listing.query,
    sort: listing.sortedColumn.path,
    sortDir: listing.sortedColumn.order,
  },
    {
      refetchOnMountOrArgChange: true,
    },);

  const handleSelectedColumn = () => {
    const newArr = [];
    column.map((item) => item.isChecked && newArr.push(item));
    setNewColumn(newArr);
    setSelectedColumns(false);
  };

  useEffect(() => {
    handleSelectedColumn();
    refetch();
  }, []);

  const [column, setColumn] = useState([
    {
      id: 1,
      key: 'title',
      path: 'title',
      label: 'Title',
      alignBody: 'text-left align-middle ps-4 py-3',
      alignHeader: 'text-left align-middle cursor-pointer ps-4 py-3',
      isChecked: true,
      content: (product) => (
        <Link
          className="text-decoration-none"
          to={`${product?.id}`}
        >
          <h5 className="m-0 cut-text"> {product?.title || '-'}</h5>
          <span className='small cut-text'>
            {product.id || '-'}

          </span>
        </Link>

      ),
    },
    {
      id: 2,
      key: 'productType',
      label: 'Type',
      path: 'productType',
      alignBody: 'text-center align-middle ps-4 py-3',
      alignHeader: 'text-center align-middle cursor-pointer ps-4 py-3',
      isChecked: true,
      content: (product) => (
        <>
          {product?.productType || '-'}
        </>
      ),
    },
    {
      id: 3,
      key: 'accessKey',
      path: 'accessKey',
      label: 'Access Key',
      alignBody: 'text-center align-middle ps-4 py-3',
      alignHeader: 'text-center align-middle cursor-pointer ps-4 py-3',
      isChecked: true,
      content: (product) => (
        <>
          {product?.accessKey || '-'}
        </>
      ),
    },
    {
      id: 4,
      path: 'creditPrice',
      label: 'Cost Per Unit',
      alignBody: 'text-center align-middle ps-4 py-3',
      alignHeader: 'text-center align-middle cursor-pointer ps-4 py-3',
      isChecked: true,
    },
    {
      id: 5,
      key: 'visibility',
      path: 'visibility',
      label: 'Visibility',
      alignBody: 'text-center align-middle ps-4 py-3',
      alignHeader: 'text-center align-middle cursor-pointer ps-4 py-3',
      isChecked: true,
      content: (product) => (
        <>
          {capitalizeFirstLetter(product?.visibility) || '-'}
        </>
      ),
    },
    {
      id: 7,
      label: 'Actions',
      isChecked: true,
      alignBody: 'text-center align-middle ps-4 py-3 text-nowrap',
      alignHeader: 'text-center align-middle cursor-pointer ps-4 py-3',
      content: (products) => (
        <div className="text-center align-middle cursor-pointer">
          <Link
            variant="text"
            className="text-decoration-none px-2"
            to={`${products?.id}/creation`}
          >
            <EditIcon />
          </Link>
          <Link
            variant="text"
            className="text-decoration-none"
            onClick={() => deleteProductsList(products?.id)}
            to={''}
          >
            <TrashIcon />
          </Link>
        </div>
      ),
    },
  ]);
  const [timer, setSearchTimer] = useState(null);
  const handleSearch = (evt) => {
    clearTimeout(timer);
    const query = evt.target.value;
    // setSearch(query);
    const newTimer = setTimeout(() => {
      setListing({ ...listing, page: 1, query: query });
    }, 2000);
    setSearchTimer(newTimer);
  };
  const handleCHeck = (item) => {
    const tempColumn = [...column];
    const newItemIndex = column.findIndex((t) => t.id === item.id);
    tempColumn[newItemIndex].isChecked = !tempColumn[newItemIndex].isChecked;
    setColumn(tempColumn);
  };
  const handleSort = (sort) => {
    setListing((prevState) => ({
      ...prevState,
      sortedColumn: { ...sort },
    }));
  };
  const handlePerPageChange = (perPage) => {
    setListing((prevState) => ({
      ...prevState,
      perPage: perPage.target.innerText,
      page: 1,
    }));
  };
  const handlePageChange = ({ selected }) => {
    setListing((prevState) => ({
      ...prevState,
      page: selected + 1,
    }));
  };
  const handleSortBy = (item) => {
    const prevFilters = { ...listing };
    prevFilters.sortedColumn = item;
    setListing(prevFilters);
  };

  const deleteProductsList = (id) => {
    deleteProduct(id)
      .unwrap()
      .then((resp) => {
        toast.success(resp.message || 'Product deleted successfully');
        refetch();
      });
  };

  const ToggleFilterbar = () => {
    isOpen === true ? setIsopen(false) : setIsopen(true);
    setSortFilter(false);
  };

  const handleSubmit = (values) => {
    // console.log(values);
    // const postCode = values.sortData.postcode;
    // const department = values.department;
    // setListing({ ...listing, postCode: postCode, department: department });
    // setIsopen(false);
  };
  const getCsvData = () => {
    return productResponse?.products.map((jr) => ({
      id: jr.id,
      title: jr.title,
      type: jr.productType,
      accessKey: jr.accessKey,
      costPerUnit: jr.creditPrice,
      visibility: jr?.visibility || '-'
    }));
  };

  return (
    <Portlet className="m-4">
      <PortletHeader
        containerClassName="px-4 pt-3"
        title="Products"
        actions={
          <StyledButton
            as={Link}
            className="btn btn-primary"
            icon={<AddIcon />}
            to={'/products/creation'}
          >
            Add New
          </StyledButton>
        }
      />
      <div className="portlet__body-bg px-0">
        <PortletBody>
          <div className="d-flex justify-content-between align-items-center mb-4 mt-2">
            <StyledIconInput
              icon={<SearchIcon />}
              className="mw-50"
              $maxWidth="60%"
              $marginLeft="3px"
              onChange={handleSearch}
              loading={isFetching}
              placeholder="Search Product Name"
            />
            <StyledButton
              className="me-3 py-2"
              variant="iconBackground feature-pending"
              icon={<FilterIcon />}
              onClick={ToggleFilterbar}
            >
              Filter
            </StyledButton>
            <StyledFilterTabs>
              <div className="btn-group" role="group">
                <button
                  className="btn"
                  id="sort"
                  onClick={() => {
                    setSortFilter(!sortFilter);
                    setSelectedColumns(false);
                  }}
                >
                  <SortIcon size={15} />
                </button>
                <button
                  className="btn"
                  onClick={() => {
                    setSelectedColumns(!selectedColumns);
                    setSortFilter(false);
                  }}
                >
                  <PauseIcon size={15} />
                </button>
                <button
                  className="btn"
                // onClick={() => {
                //   setSelectedColumns(!selectedColumns);
                //   setSortFilter(false);
                // }}
                >
                  {productResponse?.products && (
                    <CSVLink
                      className='d-flex align-items-center'
                      data={getCsvData()}
                      filename={'Products.csv'}
                    >
                      <NewDownloadIcon size={15} />
                    </CSVLink>
                  )}
                </button>
              </div>
            </StyledFilterTabs>
          </div>
          <div className="m-0 position-relative d-flex rounded flex-column click-text">
            {sortFilter ? (
              <StyledFilterBar
                variant={'sort'}
                className="bg-white p-3 align-items-center justify-content-center shadow"
              >
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <h5 className="mb-0">Sort By</h5>
                </div>
                {sortData.map((item, index) => {
                  return (
                    <div
                      className="d-flex align-items-center py-2 px-2 "
                      key={`sort-${item.value}-index`}
                    >
                      <StyledRadioButton
                        value={
                          listing.sortedColumn.path === item.sortvalue.path &&
                          listing.sortedColumn.order === item.sortvalue.order
                        }
                        label={item.label}
                        className="fw-bold"
                        onChange={() => handleSortBy(item.sortvalue)}
                      />
                    </div>
                  );
                })}
              </StyledFilterBar>
            ) : selectedColumns ? (
              <StyledFilterBar className="bg-white p-3 align-items-center justify-content-center  rounded w-10 shadow">
                <div
                  className="d-flex justify-content-between align-items-center mb-3 gap-5"
                  onClick={() => setSelectedColumns(false)}
                >
                  <h5 className="mb-0">Customise Columns</h5>
                  <div className="cursor-pointer">
                    <CrossIcon />
                  </div>
                </div>
                {column?.map((item, index) => {
                  return item.path ? (
                    <div
                      className="d-flex align-items-center py-2 px-2"
                      key={`cl-${index}`}
                    >
                      <StyledCheckbox
                        size={'20'}
                        value={item.isChecked}
                        onChange={() => handleCHeck(item)}
                      />
                      <h6 className="ms-3 mb-0">{item.label}</h6>
                    </div>
                  ) : null;
                })}
                <div className="my-3 w-100 d-flex">
                  <StyledButton
                    onClick={handleSelectedColumn}
                    className="w-100"
                  >
                    Apply
                  </StyledButton>
                </div>
              </StyledFilterBar>
            ) : (
              <>
                <div className="position-absolute">
                  {isOpen && (
                    <StyledFilterBar
                      variant="filter"
                      className="my-1 position-fixed bottom-0"
                      style={{ top: '20vh' }}
                    >
                      <div className="sd-header">
                        <div className="d-flex justify-content-between align-items-center w-100">
                          <h4 className="mb-0">Filters</h4>

                          <div onClick={ToggleFilterbar}>
                            <CrossIcon />
                          </div>
                        </div>
                        {!isEqual(listing, initialFilters) && (
                          <h6
                            className="mb-0 cursor-pointer"
                            onClick={() => {
                              setListing({ ...initialFilters });
                              // setSearch('');
                              setIsopen(false);
                            }}
                          >
                            Clear All
                          </h6>
                        )}
                      </div>
                      <div className="sd-body">
                        <Formik
                          initialValues={{
                            ...listing,
                          }}
                          onSubmit={handleSubmit}
                        >
                          {({ handleSubmit, submitForm }) => (
                            <Form onSubmit={handleSubmit}>
                              <div className="col-12">
                                <div className="mb-3">
                                  <label
                                    htmlFor="jobTemplate"
                                    className="form-label"
                                  >
                                    Department
                                  </label>
                                  <FormikSelect
                                    name="department"
                                    id="department"
                                    placeholder="Select Job Type"
                                    options={departments}
                                  />
                                </div>
                              </div>
                              <StyledButton
                                variant="white"
                                type="button"
                                onClick={submitForm}
                                className="w-100"
                              >
                                Apply Filters
                              </StyledButton>
                            </Form>
                          )}
                        </Formik>
                      </div>
                    </StyledFilterBar>
                  )}
                </div>
              </>
            )}
          </div>
          <TemplateListingTable
            templates={productResponse?.products ? productResponse?.products : []}
            sortColumn={listing.sortedColumn}
            onSort={handleSort}
            filterTable={newColumn}
          />
          <PaginationPerPage
            perPage={productResponse?.perPage ?? 0}
            pageNo={productResponse?.page ?? 0}
            onPerPageChange={handlePerPageChange}
            totalCount={productResponse?.totalCount}
            onPageChange={handlePageChange}
          />
        </PortletBody>
      </div>
    </Portlet>
  );
};


export default ProductListingContainer;