import { Formik, Form } from 'formik';
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import FormikDropZone from '../../../app/components/formikComponents/FormikDropZone';
import PortletBody from '../../../app/components/portlet/PortletBody';
import StyledButton from '../../../app/components/styledComponents/StyledButton';
import * as Yup from 'yup';
import { usePreSignedAgreementUrlMutation, useUpdateCompanyLicenceMutation } from '../../company/companyApiSlice';
import { toast } from 'react-toastify';

const UploadLicenseModal = ({ show, onHide, compId, licenceId, refetch, endDate }) => {
  const [isLoading, setLoading] = useState(false)
  const [preSignedAgreementUrl] = usePreSignedAgreementUrlMutation();
  const [updateLicenceInfo, { isLoading: loadingUpload }] = useUpdateCompanyLicenceMutation();

  const uploadAgreementSchema = Yup.object().shape({
    agreement: Yup.mixed().required('Required'),
  });
  const handleUploadFile = async (e, setFieldValue, name) => {
    setLoading(true)
    const file = e[0];
    if (!file) return;
    const { data: responseData } = await preSignedAgreementUrl({
      compId,
      body: {
        fileName: file.name,
        fileType: file.type,
      }
    });
    if (!responseData?.url) {
      return;
    }
    await fetch(`${responseData?.url}`, {
      method: responseData?.method,
      headers: {
        Accept: 'application/json',
        'Content-Type': file.type,
      },
      body: file,
    });
    setFieldValue(name, responseData.key);
    setLoading(false)
  };

  const handleSubmit = async (values) => {
    const { data } = await updateLicenceInfo({
      param: { agreement: values?.agreement, endDate },
      compId,
      licenceId
    }, {
      skip: !values?.agreement
    })
    if (data) {
      onHide()
      refetch()
      toast.success(data?.message)
    }
  };
  return (
    <Modal show={show} centered onHide={onHide} size={'lg'}>
      <Formik
        initialValues={{
          agreement: '',
        }}
        validationSchema={uploadAgreementSchema}
        onSubmit={handleSubmit}
      >
        {({ handleSubmit, setFieldValue }) => (
          <Form onSubmit={handleSubmit}>
            <Modal.Header
              closeButton
              className="border-bottom-0 pb-0 align-items-baseline"
            >
              <Modal.Title
                as="h4"
                className="flex-grow-1 p-2 cursor-pointer flex-center"
              >
                Upload Licence Agreement
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="d-flex  flex-column bg-body rounded">
              <PortletBody className="p-0 w-100">
                <PortletBody className={'p-0 w-100'}>
                  <div className="p-1 p-lg-2">
                    <div className="p-2">
                      <FormikDropZone name="agreement" id="agreement"
                        onDrop={(val) =>
                          handleUploadFile(
                            val,
                            setFieldValue,
                            'agreement',
                          )
                        }
                      />
                    </div>
                  </div>
                </PortletBody>
              </PortletBody>
              <div className="d-flex justify-content-end mt-3">
                <div className="d-flex gap-3">
                  <StyledButton
                    variant={'white'}
                    className="mb-2 border-0 fw-bold"
                    onClick={onHide}
                  >
                    Cancel
                  </StyledButton>
                  <StyledButton
                    disabled={isLoading}
                    loading={loadingUpload}
                    className="mb-2" type="submit">
                    Upload
                  </StyledButton>
                </div>
              </div>
            </Modal.Body>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default UploadLicenseModal;
