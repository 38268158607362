import React from 'react';

const ResumeIcon = ({ className }) => {
  return (
    <svg width="45" height="41" viewBox="0 0 45 41" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip_path="url(#clip0_1484_48929)">
    <path d="M23.045 0.0893532L21.5807 0.472675L3.54124 5.19528L2.07701 5.57861C1.3663 5.76553 0.757645 6.23102 0.384584 6.87295C0.0115237 7.51488 -0.0954769 8.28082 0.0870573 9.00274L6.43624 33.997C6.62037 34.7185 7.07891 35.3364 7.71124 35.7151C8.34357 36.0938 9.09807 36.2025 9.8092 36.0172L9.81298 36.0162L30.7733 30.5289L30.7771 30.5279C31.4878 30.341 32.0965 29.8755 32.4696 29.2336C32.8426 28.5916 32.9496 27.8257 32.7671 27.1038L26.4179 2.10952C26.2338 1.38802 25.7752 0.770125 25.1429 0.391401C24.5106 0.0126773 23.7561 -0.0959492 23.045 0.0893532Z" fill="#F2F2F2"/>
    <path d="M23.2439 0.872989L21.5097 1.32701L4.01022 5.90824L2.27597 6.36225C1.76986 6.49536 1.33642 6.82685 1.07075 7.28398C0.805082 7.74111 0.728883 8.28656 0.85887 8.80065L7.20806 33.7949C7.33918 34.3087 7.66571 34.7487 8.11601 35.0184C8.56631 35.2881 9.10361 35.3655 9.61002 35.2335L9.6138 35.2325L30.5742 29.7453L30.578 29.7443C31.0841 29.6111 31.5175 29.2797 31.7832 28.8225C32.0488 28.3654 32.125 27.82 31.9951 27.3059L25.6459 2.3116C25.5147 1.7978 25.1882 1.35779 24.7379 1.08809C24.2876 0.818395 23.7503 0.741037 23.2439 0.872989Z" fill="white"/>
    <path d="M21.9375 8.96676L11.3341 11.7426C11.2157 11.7736 11.09 11.7556 10.9846 11.6925C10.8793 11.6294 10.803 11.5265 10.7724 11.4062C10.7419 11.286 10.7596 11.1584 10.8218 11.0514C10.8839 10.9445 10.9854 10.867 11.1038 10.836L21.7071 8.06014C21.8255 8.02926 21.9512 8.04736 22.0564 8.11046C22.1616 8.17356 22.2379 8.2765 22.2684 8.39665C22.299 8.51681 22.2812 8.64435 22.2192 8.75125C22.1571 8.85815 22.0558 8.93567 21.9375 8.96676Z" fill="#F2F2F2"/>
    <path d="M24.1661 10.0145L11.7208 13.2726C11.6024 13.3036 11.4767 13.2855 11.3714 13.2224C11.266 13.1594 11.1897 13.0564 11.1591 12.9361C11.1286 12.8159 11.1464 12.6883 11.2085 12.5814C11.2706 12.4744 11.3721 12.3969 11.4905 12.3659L23.9358 9.10785C24.0543 9.07685 24.18 9.09487 24.2853 9.15796C24.3906 9.22105 24.467 9.32403 24.4975 9.44426C24.5281 9.56448 24.5103 9.6921 24.4482 9.79904C24.386 9.90597 24.2846 9.98347 24.1661 10.0145Z" fill="#F2F2F2"/>
    <path d="M23.6757 15.8231L13.0724 18.5989C13.0138 18.6143 12.9527 18.6178 12.8927 18.6092C12.8328 18.6006 12.7751 18.5801 12.7229 18.5488C12.6708 18.5176 12.6252 18.4762 12.5888 18.4271C12.5523 18.378 12.5258 18.3221 12.5107 18.2625C12.4956 18.203 12.4922 18.141 12.5006 18.0802C12.5091 18.0193 12.5293 17.9607 12.5601 17.9078C12.5908 17.8548 12.6316 17.8085 12.68 17.7716C12.7284 17.7346 12.7834 17.7077 12.8421 17.6923L23.4454 14.9164C23.5041 14.9011 23.5651 14.8976 23.6251 14.9062C23.685 14.9148 23.7427 14.9353 23.7949 14.9666C23.8471 14.9978 23.8926 15.0392 23.9291 15.0883C23.9655 15.1374 23.992 15.1933 24.0071 15.2529C24.0222 15.3124 24.0257 15.3744 24.0172 15.4352C24.0087 15.4961 23.9885 15.5547 23.9578 15.6076C23.927 15.6606 23.8862 15.7069 23.8378 15.7438C23.7895 15.7808 23.7344 15.8077 23.6757 15.8231Z" fill="#F2F2F2"/>
    <path d="M25.9083 16.8709L13.463 20.129C13.4044 20.1444 13.3433 20.1478 13.2834 20.1392C13.2234 20.1306 13.1657 20.1101 13.1135 20.0789C13.0614 20.0477 13.0158 20.0063 12.9794 19.9572C12.943 19.908 12.9164 19.8521 12.9013 19.7926C12.8862 19.7331 12.8828 19.6711 12.8913 19.6102C12.8997 19.5493 12.9199 19.4908 12.9507 19.4378C12.9815 19.3849 13.0222 19.3386 13.0706 19.3016C13.119 19.2647 13.1741 19.2377 13.2327 19.2224L25.678 15.9643C25.7964 15.9333 25.9221 15.9513 26.0275 16.0144C26.1328 16.0775 26.2092 16.1805 26.2397 16.3007C26.2702 16.4209 26.2525 16.5485 26.1903 16.6555C26.1282 16.7624 26.0267 16.8399 25.9083 16.8709Z" fill="#F2F2F2"/>
    <path d="M25.4176 22.6795L14.8143 25.4554C14.6959 25.4863 14.5702 25.4682 14.465 25.4051C14.3597 25.342 14.2834 25.2391 14.2529 25.1189C14.2224 24.9987 14.2401 24.8712 14.3022 24.7643C14.3643 24.6573 14.4656 24.5798 14.584 24.5488L25.1873 21.7729C25.3057 21.7419 25.4314 21.7599 25.5368 21.823C25.6421 21.8861 25.7185 21.9891 25.749 22.1093C25.7795 22.2295 25.7618 22.3571 25.6996 22.4641C25.6375 22.571 25.536 22.6485 25.4176 22.6795Z" fill="#F2F2F2"/>
    <path d="M27.6509 23.7271L15.2056 26.9852C15.1469 27.0006 15.0859 27.0041 15.0259 26.9956C14.9659 26.987 14.9081 26.9665 14.8559 26.9353C14.8037 26.9041 14.7581 26.8627 14.7216 26.8136C14.6852 26.7644 14.6586 26.7085 14.6435 26.6489C14.6284 26.5893 14.625 26.5273 14.6335 26.4664C14.6419 26.4055 14.6622 26.3469 14.693 26.2939C14.7238 26.241 14.7646 26.1947 14.813 26.1577C14.8615 26.1208 14.9166 26.0939 14.9753 26.0786L27.4206 22.8205C27.539 22.7895 27.6647 22.8075 27.7701 22.8706C27.8754 22.9337 27.9517 23.0367 27.9823 23.1569C28.0128 23.2771 27.9951 23.4047 27.9329 23.5117C27.8708 23.6186 27.7693 23.6961 27.6509 23.7271Z" fill="#F2F2F2"/>
    <path d="M9.52288 14.589L5.77046 15.5713C5.71368 15.5861 5.65343 15.5775 5.60294 15.5472C5.55244 15.517 5.51583 15.4677 5.50113 15.41L4.63977 12.0192C4.62519 11.9615 4.63373 11.9004 4.66352 11.8491C4.69331 11.7979 4.74191 11.7607 4.79866 11.7458L8.55108 10.7634C8.60787 10.7486 8.66811 10.7573 8.71861 10.7875C8.7691 10.8178 8.80571 10.8671 8.82041 10.9247L9.68178 14.3156C9.69636 14.3732 9.68781 14.4344 9.65802 14.4856C9.62824 14.5369 9.57963 14.5741 9.52288 14.589Z" fill="#E6E6E6"/>
    <path d="M11.2651 21.4452L7.51265 22.4275C7.45586 22.4423 7.39562 22.4337 7.34512 22.4034C7.29463 22.3732 7.25802 22.3238 7.24332 22.2662L6.38195 18.8754C6.36738 18.8177 6.37592 18.7566 6.40571 18.7053C6.4355 18.6541 6.4841 18.6169 6.54085 18.602L10.2933 17.6196C10.3501 17.6048 10.4103 17.6135 10.4608 17.6437C10.5113 17.674 10.5479 17.7233 10.5626 17.7809L11.424 21.1718C11.4385 21.2294 11.43 21.2906 11.4002 21.3418C11.3704 21.3931 11.3218 21.4303 11.2651 21.4452Z" fill="#E6E6E6"/>
    <path d="M13.0073 28.3016L9.25484 29.284C9.19805 29.2988 9.1378 29.2901 9.08731 29.2599C9.03682 29.2296 9.00021 29.1803 8.9855 29.1227L8.12414 25.7318C8.10956 25.6742 8.11811 25.613 8.1479 25.5618C8.17768 25.5105 8.22629 25.4733 8.28304 25.4584L12.0355 24.4761C12.0922 24.4613 12.1525 24.4699 12.203 24.5002C12.2535 24.5304 12.2901 24.5798 12.3048 24.6374L13.1662 28.0282C13.1807 28.0859 13.1722 28.147 13.1424 28.1983C13.1126 28.2495 13.064 28.2867 13.0073 28.3016Z" fill="#E6E6E6"/>
    <path d="M37.6692 6.42505H16.0153C15.2811 6.42589 14.5772 6.72234 14.0581 7.24936C13.5389 7.77638 13.2469 8.49094 13.2461 9.23626V35.0482C13.2469 35.7936 13.5389 36.5081 14.0581 37.0351C14.5772 37.5621 15.2811 37.8586 16.0153 37.8594H37.6692C38.4034 37.8586 39.1072 37.5621 39.6264 37.0351C40.1455 36.5081 40.4376 35.7936 40.4384 35.0482V9.23626C40.4376 8.49094 40.1455 7.77638 39.6264 7.24936C39.1072 6.72234 38.4034 6.42589 37.6692 6.42505Z" fill="#E6E6E6"/>
    <path d="M37.6689 7.23438H16.015C15.4921 7.23497 14.9909 7.44608 14.6212 7.82138C14.2515 8.19669 14.0436 8.70554 14.043 9.2363V35.0483C14.0436 35.579 14.2515 36.0879 14.6212 36.4632C14.9909 36.8385 15.4921 37.0496 16.015 37.0502H37.6689C38.1917 37.0496 38.693 36.8385 39.0627 36.4632C39.4324 36.0879 39.6403 35.579 39.6409 35.0483V9.2363C39.6403 8.70554 39.4324 8.19669 39.0627 7.82139C38.693 7.44609 38.1917 7.23498 37.6689 7.23438Z" fill="white"/>
    <path d="M39.9457 41.0001C42.7375 41.0001 45.0007 38.7025 45.0007 35.8683C45.0007 33.0341 42.7375 30.7366 39.9457 30.7366C37.1538 30.7366 34.8906 33.0341 34.8906 35.8683C34.8906 38.7025 37.1538 41.0001 39.9457 41.0001Z" fill="#4A154B"/>
    <path d="M42.3618 35.1989H40.6035V33.414C40.6035 33.2365 40.5341 33.0662 40.4104 32.9407C40.2868 32.8152 40.119 32.7446 39.9442 32.7446C39.7693 32.7446 39.6016 32.8152 39.4779 32.9407C39.3543 33.0662 39.2848 33.2365 39.2848 33.414V35.1989H37.5265C37.3517 35.1989 37.184 35.2695 37.0603 35.395C36.9367 35.5205 36.8672 35.6908 36.8672 35.8683C36.8672 36.0458 36.9367 36.2161 37.0603 36.3416C37.184 36.4671 37.3517 36.5377 37.5265 36.5377H39.2848V38.3226C39.2848 38.5001 39.3543 38.6704 39.4779 38.7959C39.6016 38.9214 39.7693 38.992 39.9442 38.992C40.119 38.992 40.2868 38.9214 40.4104 38.7959C40.5341 38.6704 40.6035 38.5001 40.6035 38.3226V36.5377H42.3618C42.5367 36.5377 42.7044 36.4671 42.828 36.3416C42.9517 36.2161 43.0212 36.0458 43.0212 35.8683C43.0212 35.6908 42.9517 35.5205 42.828 35.395C42.7044 35.2695 42.5367 35.1989 42.3618 35.1989Z" fill="white"/>
    <path d="M34.4119 21.418H23.4617C23.4011 21.418 23.341 21.406 23.285 21.3825C23.229 21.359 23.1781 21.3245 23.1352 21.281C23.0924 21.2376 23.0584 21.1859 23.0352 21.1291C23.0119 21.0723 23 21.0114 23 20.9498C23 20.8883 23.0119 20.8274 23.0352 20.7706C23.0584 20.7137 23.0924 20.6621 23.1352 20.6186C23.1781 20.5751 23.229 20.5407 23.285 20.5172C23.341 20.4937 23.4011 20.4816 23.4617 20.4817H34.4119C34.5341 20.4818 34.6513 20.5312 34.7376 20.619C34.824 20.7068 34.8725 20.8258 34.8725 20.9498C34.8725 21.0739 34.824 21.1929 34.7376 21.2807C34.6513 21.3685 34.5341 21.4178 34.4119 21.418Z" fill="#E6E6E6"/>
    <path d="M36.3141 22.998H23.4617C23.4011 22.9981 23.341 22.9861 23.285 22.9626C23.229 22.9391 23.1781 22.9046 23.1352 22.8611C23.0924 22.8176 23.0584 22.766 23.0352 22.7092C23.0119 22.6523 23 22.5914 23 22.5299C23 22.4684 23.0119 22.4075 23.0352 22.3506C23.0584 22.2938 23.0924 22.2422 23.1352 22.1987C23.1781 22.1552 23.229 22.1207 23.285 22.0973C23.341 22.0738 23.4011 22.0617 23.4617 22.0618H36.3141C36.4364 22.0618 36.5537 22.1111 36.6402 22.1989C36.7267 22.2867 36.7753 22.4057 36.7753 22.5299C36.7753 22.6541 36.7267 22.7731 36.6402 22.8609C36.5537 22.9487 36.4364 22.998 36.3141 22.998Z" fill="#E6E6E6"/>
    <path d="M34.4119 28.4988H23.4617C23.4011 28.4989 23.341 28.4868 23.285 28.4633C23.229 28.4398 23.1781 28.4053 23.1352 28.3619C23.0924 28.3184 23.0584 28.2667 23.0352 28.2099C23.0119 28.1531 23 28.0922 23 28.0306C23 27.9691 23.0119 27.9082 23.0352 27.8514C23.0584 27.7945 23.0924 27.7429 23.1352 27.6994C23.1781 27.656 23.229 27.6215 23.285 27.598C23.341 27.5745 23.4011 27.5624 23.4617 27.5625H34.4119C34.5342 27.5625 34.6515 27.6118 34.738 27.6996C34.8245 27.7874 34.873 27.9065 34.873 28.0306C34.873 28.1548 34.8245 28.2739 34.738 28.3617C34.6515 28.4495 34.5342 28.4988 34.4119 28.4988Z" fill="#E6E6E6"/>
    <path d="M36.3141 30.0786H23.4617C23.4011 30.0787 23.341 30.0666 23.285 30.0431C23.229 30.0196 23.1781 29.9852 23.1352 29.9417C23.0924 29.8982 23.0584 29.8466 23.0352 29.7897C23.0119 29.7329 23 29.672 23 29.6105C23 29.549 23.0119 29.488 23.0352 29.4312C23.0584 29.3744 23.0924 29.3227 23.1352 29.2793C23.1781 29.2358 23.229 29.2013 23.285 29.1778C23.341 29.1543 23.4011 29.1423 23.4617 29.1423H36.3141C36.3747 29.1423 36.4347 29.1543 36.4908 29.1778C36.5468 29.2013 36.5977 29.2358 36.6405 29.2793C36.6834 29.3227 36.7174 29.3744 36.7406 29.4312C36.7638 29.488 36.7758 29.549 36.7758 29.6105C36.7758 29.672 36.7638 29.7329 36.7406 29.7897C36.7174 29.8466 36.6834 29.8982 36.6405 29.9417C36.5977 29.9852 36.5468 30.0196 36.4908 30.0431C36.4347 30.0666 36.3747 30.0787 36.3141 30.0786Z" fill="#E6E6E6"/>
    <path d="M21.0065 23.7151H17.1313C17.0727 23.7151 17.0164 23.6914 16.975 23.6493C16.9335 23.6072 16.9102 23.5502 16.9102 23.4907V19.9889C16.9102 19.9294 16.9335 19.8723 16.975 19.8302C17.0164 19.7881 17.0727 19.7645 17.1313 19.7644H21.0065C21.0651 19.7645 21.1213 19.7881 21.1627 19.8302C21.2042 19.8723 21.2275 19.9294 21.2276 19.9889V23.4907C21.2275 23.5502 21.2042 23.6072 21.1627 23.6493C21.1213 23.6914 21.0651 23.7151 21.0065 23.7151Z" fill="#E6E6E6"/>
    <path d="M21.0065 30.796H17.1313C17.0727 30.7959 17.0164 30.7722 16.975 30.7301C16.9335 30.6881 16.9102 30.631 16.9102 30.5715V27.0697C16.9102 27.0102 16.9335 26.9531 16.975 26.911C17.0164 26.869 17.0727 26.8453 17.1313 26.8452H21.0065C21.0651 26.8453 21.1213 26.869 21.1627 26.911C21.2042 26.9531 21.2275 27.0102 21.2276 27.0697V30.5715C21.2275 30.631 21.2042 30.6881 21.1627 30.7301C21.1213 30.7722 21.0651 30.7959 21.0065 30.796Z" fill="#E6E6E6"/>
    <path d="M34.4249 13.5359H26.3518C26.2295 13.5359 26.1122 13.4866 26.0257 13.3988C25.9392 13.311 25.8906 13.1919 25.8906 13.0677C25.8906 12.9436 25.9392 12.8245 26.0257 12.7367C26.1122 12.6489 26.2295 12.5996 26.3518 12.5996H34.4249C34.5472 12.5996 34.6645 12.6489 34.751 12.7367C34.8375 12.8245 34.8861 12.9436 34.8861 13.0677C34.8861 13.1919 34.8375 13.311 34.751 13.3988C34.6645 13.4866 34.5472 13.5359 34.4249 13.5359Z" fill="#CCCCCC"/>
    <path d="M36.3272 15.116H26.3518C26.2912 15.116 26.2312 15.1039 26.1753 15.0803C26.1193 15.0568 26.0685 15.0223 26.0257 14.9789C25.9829 14.9354 25.9489 14.8838 25.9257 14.827C25.9026 14.7702 25.8906 14.7093 25.8906 14.6478C25.8906 14.5864 25.9026 14.5255 25.9257 14.4687C25.9489 14.4119 25.9829 14.3603 26.0257 14.3168C26.0685 14.2733 26.1193 14.2388 26.1753 14.2153C26.2312 14.1918 26.2912 14.1797 26.3518 14.1797H36.3272C36.4495 14.1797 36.5668 14.229 36.6532 14.3168C36.7397 14.4046 36.7883 14.5237 36.7883 14.6478C36.7883 14.772 36.7397 14.8911 36.6532 14.9789C36.5668 15.0666 36.4495 15.116 36.3272 15.116Z" fill="#CCCCCC"/>
    <path d="M24.5623 17.0305H17.1196C17.0609 17.0305 17.0047 17.0068 16.9633 16.9647C16.9218 16.9226 16.8985 16.8656 16.8984 16.8061V10.9093C16.8985 10.8498 16.9218 10.7927 16.9633 10.7506C17.0047 10.7085 17.0609 10.6849 17.1196 10.6848H24.5623C24.6209 10.6849 24.6771 10.7085 24.7186 10.7506C24.7601 10.7927 24.7834 10.8498 24.7834 10.9093V16.8061C24.7834 16.8656 24.7601 16.9226 24.7186 16.9647C24.6771 17.0068 24.6209 17.0305 24.5623 17.0305Z" fill="#4A154B"/>
    </g>
    <defs>
    <clipPath id="clip0_1484_48929">
    <rect width="45" height="41" fill="white"/>
    </clipPath>
    </defs>
    </svg>
    
    
  );
};

export default ResumeIcon;
