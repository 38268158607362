import React from 'react';
import { Modal } from 'react-bootstrap';
import FormikSelect from '../../../../app/components/formikComponents/FormikSelect';
import PortletBody from '../../../../app/components/portlet/PortletBody';
import StyledButton from '../../../../app/components/styledComponents/StyledButton';
import { Wrap } from '../../../../assets/styledComponents/Global';
import { jobTypes } from '../../assets/utills';

const ExistingBundleModal = ({ show, onHide }) => {
  return (
    <>
      <Modal show={show} centered onHide={onHide} size={'lg'}>
        <Modal.Header closeButton>
          <Modal.Title
            as="h2"
            className="flex-grow-1 p-2 cursor-pointer flex-center"
          >
            Add Existing Bundle
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex  flex-column bg-body rounded px-4">
          <PortletBody>
            <div className="col-12">
              <div className="mb-3">
                <label htmlFor="jobType" className="form-label">
                Bundle
                </label>
                <FormikSelect
                  name="jobType"
                  id="jobType"
                  placeholder="Select Job Type"
                  options={jobTypes}
                />
              </div>
            </div>
          </PortletBody>
          <Wrap className="d-flex ms-auto">
            <StyledButton
              className=" mt-4"
              type="submit"
              variant={'text'}
              onClick={onHide}
            >
              Cancel
            </StyledButton>
            <StyledButton className="mt-4" type="submit">
              Add
            </StyledButton>
          </Wrap>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ExistingBundleModal;
