import React from 'react';
import FormikAsyncSelectMulti from '../../../../app/components/formikComponents/FormikAsyncSelectMulti';
import FormikDatePicker from '../../../../app/components/formikComponents/FormikDatePicker';
import FormikSelectMulti from '../../../../app/components/formikComponents/FormikSelectMulti';
import FormikSelect from '../../../../app/components/formikComponents/FormikSelect';
import styled from 'styled-components';
import theme from '../../../../utils/themes';
import FormikInputField from '../../../../app/components/formikComponents/FormikInputField';
import { budgetedValues, perPerPeriod } from '../../components/HiringUtils';
import { jobTypes, remoteValues, salaryTypes } from './jobCreationUtil';
const HiringPlanFilterForm = ({ submitForm, loading,setFieldValue, onhandleChange ,values}) => {


  let companyIdPayload = {
    companyIds: values?.companyIds?.map((item) => item?.value),
  };

  return (
    <>
      <div className="col-12">
        <div className="mb-4">
          <label htmlFor="jobType" className="form-label">
            Job Type
          </label>
          <FormikSelectMulti
            name="jobType"
            id="jobType"
            isMulti={true}
            placeholder="Select Job Type"
            options={jobTypes}
            onChange={(value) => onhandleChange('jobType', value)}
          />
        </div>
      </div>

      <div className="col-12">
        <div className="mb-4">
          <label htmlFor="remoteStatus" className="form-label">
            Remote
          </label>
          <FormikSelect
            name="remoteStatus"
            id="remoteStatus"
            placeholder="Select Remote Status"
            options={remoteValues}
            onChange={(values) => onhandleChange('remoteStatus', typeof values?.value === 'undefined'
            ? values?.label
            : values.value,)}
          />
        </div>
      </div>

      <div className="col-12">
        <div className="mb-4">
          <label htmlFor="startDate" className="form-label">
            Job Start Date
          </label>
          <FormikDatePicker name="startDate" placeholder="Select Start Date"  onChange={(date) => onhandleChange('startDate', date)}/>
        </div>
      </div>

      <div className="col-12">
        <div className="mb-4">
          <label htmlFor="budgeted" className="form-label">
            Budgeted
          </label>
          <FormikSelect
            name="budgeted"
            id="budgeted"
            placeholder="Select Budget Status"
            options={budgetedValues}
            onChange={(values) => onhandleChange('budgeted', typeof values?.value === 'undefined'
            ? values?.label
            : values.value,)}
          />
        </div>
      </div>

      <div className="col-12">
        <div className="mb-4">
          <label htmlFor="isCompetitive" className="form-label">
            Is Competitive
          </label>
          <FormikSelect
            name="isCompetitive"
            id="isCompetitive"
            placeholder="Select"
            options={budgetedValues}
            onChange={(values) => onhandleChange('isCompetitive', typeof values?.value === 'undefined'
            ? values?.label
            : values.value,)}
          />
        </div>
      </div>

     

      <div className="col-12">
        <div className="mb-3">
          <label htmlFor="salaryPeriod" className="form-label">
            Pay Period
          </label>
          <FormikSelect
            name="salaryPeriod"
            id="salaryPeriod"
            placeholder="Select Pay Period"
            options={perPerPeriod}
            onChange={(values) => onhandleChange('salaryPeriod', typeof values?.value === 'undefined'
            ? values?.label
            : values.value,)}
          />
        </div>
      </div>

      <div className="col-12">
        <div className="mb-3">
          <label htmlFor="salaryCurrency" className="form-label">
          Pay Currency
          </label>
          <FormikSelect
            name="salaryCurrency"
            id="salaryCurrency"
            placeholder="Select Currency type"
            menuPlacement={'bottom'}
            options={salaryTypes}
            onChange={(values) => onhandleChange('salaryCurrency', typeof values?.value === 'undefined'
            ? values?.label
            : values.value,)}
          />
        </div>
      </div>

      {/* <div className="col-12">
        <div className="mb-4">
          <label htmlFor="salary" className="form-label">
            Salary
          </label>
          <StyledCustomTabDropdown
            type='salary'
            name="salary"
            id="salary"
            placeholder="Select Salary"
            onChange={(name,val)=>setFieldValue('salary',val)}
          />
        </div>
      </div> */}

      <div className="col-12">
        <div className="mb-4">
          <label htmlFor="hiringManager" className="form-label">
            Hiring Manager
          </label>
          <FormikAsyncSelectMulti
            name="hiringManagerIds"
            id="hiringManagerIds"
            isMulti={true}
            // apiCall={()=>getCompanyHiringManager(companyIdPayload)}
            params={{...companyIdPayload}}
            type="hiringManager"
            placeholder="Select Hiring Managers"
            CustomOptionLayout={(option) => option?.label}
            onSelect={(values)=>onhandleChange('hiringManagerIds', values)}
          />
        </div>
      </div>

      <div className="col-12">
        <div className="mb-4">
          <label htmlFor="recruiters" className="form-label">
            Approver
          </label>
          <StyledAsyncSelect
            isMulti={true}
            name="approverIds"
            id="approverIds"
            // apiCall={()=>getComapanyApprovers(companyIdPayload)}
            params={{...companyIdPayload}}
            type="approvers"
            placeholder="Select Approvers"
            CustomOptionLayout={(option) => option?.label}
            onSelect={(values)=>onhandleChange('approverIds', values)}
          />
        </div>
      </div>
      <div className="col-12">
        <div className="mb-4">
          <label htmlFor="address" className="form-label">
            Job Location
          </label>
          <FormikInputField
            name="address"
            id="address"
            placeholder="Enter Address"
            onChange={(e) =>{
             onhandleChange('address', e?.target?.value)
            }}
          />
        </div>
      </div>

      <div className="col-12">
        <div className="mb-4">
          <label htmlFor="postcode" className="form-label">
            Job Postcode
          </label>
          <FormikInputField
            name="postcode"
            id="postcode"
            placeholder="Enter Post Code"
            onChange={(e) => onhandleChange('postcode', e?.target?.value)}
          />
        </div>
      </div>

      <div className="col-12">
        <div className="mb-4">
          <label htmlFor="town" className="form-label">
            Job Town
          </label>
          <FormikInputField
            name="town"
            id="town"
            placeholder="Enter Job Town"
            onChange={(e) => onhandleChange('town', e?.target?.value)}
          />
        </div>
      </div>

      <div className="col-12">
        <div className="mb-4">
          <label htmlFor="country" className="form-label">
            Job Country
          </label>
          <FormikInputField
            name="country"
            id="country"
            placeholder="Enter Job Country"
            onChange={(e) => onhandleChange('country', e?.target?.value)}
          />
        </div>
      </div>

      {/* <div className="col-12">
        <div className="mb-4">
          <label htmlFor="remoteStatus" className="form-label">
            Remote Status
          </label>
          <FormikSelect
            name="remoteStatus"
            id="remoteStatus"
            options={remoteValues}
            placeholder="Choose Type"
          />
        </div>
      </div> */}
    </>
  );
};
const StyledAsyncSelect = styled(FormikAsyncSelectMulti)`
  [class$='-control'] {
    height: 100% !important;
  }
  [class$='-multiValue'] {
    background-color: ${theme?.tagLightYellow} !important;
    border-radius: 30px !important;
    .roletitle {
      display: none;
    }
  }
`;
export default HiringPlanFilterForm;
