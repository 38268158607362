import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom';
import OnBoardingListingContainer from './OnBoardingListing';

const OnboardingRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<OnBoardingListingContainer globalCampaign />} />
            <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
    )
}

export default OnboardingRoutes