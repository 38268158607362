import styled from 'styled-components';
import theme from '../../../utils/themes';

export const StyledFilterBar = styled.div`
  ${({ variant, _children }) => {
    const basicProperties = `
  position: absolute;
  background-color: #fff;
  right: 0%;
  top:0;
  z-index: 100;
  transition: 0.5s;
  .sd-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    color: ${({ theme }) => theme.textColor};
  }
  .sd-body {
    padding: 15px;
    max-height: 100%
    overflow-x: hidden;
  }
  .sd-body ul {
    display: inline-block;
    width: 100%;
    margin-bottom: 0;
    padding: 0;
  }
  .sd-body ul li {
    list-style: none;
    margin-bottom: 8px;
    &:hover{
      background-color:${theme.primaryLight};
      border-radius:4px;
    }
    &.onActive{
      background-color:${theme.primaryLight};
    }
 
  }
`;
    switch (variant) {
      case 'sort':
        return `
        ${basicProperties}
    width: 250px;
    box-shadow: 0px 8px 8px -4px rgba(74, 21, 75, 0.03), 0px 20px 24px -6px rgba(74, 21, 75, 0.08);
    border-radius:14px;
    `;
      case 'quick':
        return `
      ${basicProperties}
  width: 250px;
  box-shadow: 0px 8px 8px -4px rgba(74, 21, 75, 0.03), 0px 20px 24px -6px rgba(74, 21, 75, 0.08);
  border-radius:14px;
  .sd-body {
    overflow-y: scroll;
    padding: 0px;
    max-height: 500px;
    overflow-x: hidden;
  }
  `;
      case 'filter':
        return `
        ${basicProperties}
    width: 300px;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.09);
    `;
      default:
        return `
        ${basicProperties}`;
    }
  }}
`;
