import React from 'react';

const AdminSignIcon = ({ size = 18, color = "#000000", ...props }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="24" height="24" rx="12" fill="#F5F5F5"/>
    <path d="M12 6.16666C10.4717 6.16666 9.22916 7.40916 9.22916 8.93749C9.22916 10.4367 10.4017 11.65 11.93 11.7025C11.9767 11.6967 12.0233 11.6967 12.0583 11.7025C12.07 11.7025 12.0758 11.7025 12.0875 11.7025C12.0933 11.7025 12.0933 11.7025 12.0992 11.7025C13.5925 11.65 14.765 10.4367 14.7708 8.93749C14.7708 7.40916 13.5283 6.16666 12 6.16666Z" fill="#767476"/>
    <path d="M14.9633 13.2542C13.3358 12.1692 10.6817 12.1692 9.04251 13.2542C8.30167 13.75 7.89334 14.4208 7.89334 15.1383C7.89334 15.8558 8.30167 16.5208 9.03667 17.0108C9.85334 17.5592 10.9267 17.8333 12 17.8333C13.0733 17.8333 14.1467 17.5592 14.9633 17.0108C15.6983 16.515 16.1067 15.85 16.1067 15.1267C16.1008 14.4092 15.6983 13.7442 14.9633 13.2542Z" fill="#767476"/>
    </svg>

  );
};

export default AdminSignIcon;
