import { Form, Formik } from 'formik';
import React from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import FormikInputField from '../../../../app/components/formikComponents/FormikInputField';
import FormikSelect from '../../../../app/components/formikComponents/FormikSelect';
import PortletBody from '../../../../app/components/portlet/PortletBody';
import StyledButton from '../../../../app/components/styledComponents/StyledButton';
import StyledToolTip from '../../../../app/components/styledComponents/StyledToolTip';
import InfoIcon from '../../../../app/components/svgIcons/InfoIcon';
import WarningIcon from '../../../../app/components/svgIcons/WarningIcon';
import { Wrap } from '../../../../assets/styledComponents/Global';
import theme from '../../../../utils/themes';
import { StyledLabel } from '../../../Campaigns/CampaignDetails';
import {
  CurrencyInitialState,
  currencyType,
  CurrencyValidationSchema,
} from '../assets/companyCreationUtill';

const OccyCurrency = ({
  creditRefetch,
  compId,
  addCredit,
  isLoading,
  onHide,
}) => {
  const handleSubmit = (values) => {
    const occyCurrency = {
      totalCost: values?.currencyQuantity,
      currency: values?.selectCurrencyType,
      invoiceId: values?.currencyInvoice,
      comment: values?.currencyComment,
      companyId: compId,
      type: 'FIAT',
    };
    addCredit(occyCurrency).then(
      (resp) => {toast.success(resp?.data?.message); onHide(); creditRefetch()},
    );
  };
  return (
    <Formik
      initialValues={CurrencyInitialState}
      validationSchema={CurrencyValidationSchema}
      //   validate={validate}
      onSubmit={handleSubmit}
    >
      {({ handleSubmit, setFieldValue, errors, touched }) => (
        <Form onSubmit={handleSubmit}>
          <PortletBody className="mt-3">
            <Wrap className="d-flex gap-2 align-items-center">
              <StyledLabel>Currency</StyledLabel>
              <StyledToolTip
                title={
                  'Here you can add balance in fiat currency. It is later used for SMS and other non-advertising related products'
                }
                className="mt-2"
                placement="top"
              >
                <InfoIcon />
              </StyledToolTip>
            </Wrap>
            <Wrap className="mt-3">
              <StyledLabel className="mb-1">Quantity</StyledLabel>
              <div
                className={`d-flex border rounded ${
                  errors?.currencyQuantity &&
                  touched?.currencyQuantity &&
                  ' border-danger'
                }`}
              >
                <Wrap className="col">
                  <FormikInputField
                    className="border-0"
                    name="currencyQuantity"
                    id="currencyQuantity"
                    type="Number"
                    placeholder="Enter quantity"
                    isShowError={false}
                  />
                </Wrap>
                <Wrap className="border-end my-2" />
                <Wrap>
                  <StyledCustomSelect
                    name="selectCurrencyType"
                    id="selectCurrencyType"
                    options={currencyType}
                  />
                </Wrap>
              </div>
              {errors?.currencyQuantity && touched?.currencyQuantity && (
                <div className="text-danger d-flex align-items-center">
                  <WarningIcon />
                  {errors?.currencyQuantity}
                </div>
              )}
            </Wrap>
            <Wrap className="mt-3">
              <StyledLabel className="mb-1">
                Invoice / Transaction ID
              </StyledLabel>
              <FormikInputField
                id="currencyInvoice"
                name="currencyInvoice"
                placeholder="e.g OC5637J7829"
              />
            </Wrap>
            <Wrap className="mt-3">
              <Wrap className="d-flex gap-1">
                <StyledLabel className="mb-1">Add a comment</StyledLabel>
                <StyledLabel className="mb-1" color={theme.inputColor}>
                  (optional)
                </StyledLabel>
              </Wrap>
              <FormikInputField
                id="currencyComment"
                name="currencyComment"
                placeholder="Add a short comment for future reference"
              />
            </Wrap>
          </PortletBody>
          <div className="d-flex justify-content-end mt-3">
            <StyledButton
              className="mb-2 border-0 fw-bold"
              onClick={onHide}
              variant="text"
            >
              Cancel
            </StyledButton>
            <StyledButton
              className="mb-2 border-0 fw-bold"
              type="submit"
              disabled={isLoading}
              loading={isLoading}
            >
              Add
            </StyledButton>
          </div>
        </Form>
      )}
    </Formik>
  );
};

const StyledCustomSelect = styled(FormikSelect)`
  [class$='-control'] {
    border: none;
  }
`;

export default OccyCurrency;
