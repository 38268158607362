import { configureStore, isRejectedWithValue } from '@reduxjs/toolkit';
import authReducer from '../features/auth/authSlice';
import companyCreationReducer from '../features/company/creation/companyCreationSlice';
import stagesReducer from '../features/company/StagesDataSlice';
import { apiSlice } from './services/apiSlice';
import { toast } from 'react-toastify';

// let refreshStatus = 1; // 0 = failed, 1 = all okay, 2 = in progress
/**
 * this function monitors the accessToken expiry and
 * fetches a new accessToken based on refreshToken - only if user is active
 * @returns {function(*): function(*)}
 */

const rtkQueryErrorLogger = (store) => (next) => (action) => {
  // if 401 then redirect to /
  if (action?.payload?.status === 401) {
    window.location.href = '/logout';
    return;
  }
   //if 504 status Check
   if (action?.payload?.status === 504 || action?.payload?.status === 'FETCH_ERROR') { 
    return next(action);
  }

  if (
    action?.meta?.baseQueryMeta?.request?.url?.includes('licences/new') &&
    action?.payload?.status === 404
  ) {
    return next(action);
  }
  // Log a warning and show a toast!
  if (isRejectedWithValue(action)) {
    console.warn('We got a rejected action!');

    toast.error(
      action?.payload?.data?.message ||
      action?.payload?.error ||
      'Something went wrong!',
    );
  }
  return next(action);
};
const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    auth: authReducer,
    createJob: companyCreationReducer,
    stages: stagesReducer,
  },
  middleware: (getDefaultMiddleware) => {
    return [
      rtkQueryErrorLogger,
      ...getDefaultMiddleware({
        serializableCheck: false,
      }).concat(apiSlice.middleware),
    ];
  },
  devTools: process.env.NODE_ENV !== 'production',
});
export default store;
