import React from 'react';
import styled from 'styled-components';
import { useField } from 'formik';

const FormikStyledTextArea = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <>
      <StyledTextArea
        {...field}
        {...props}
        isInvalid={meta.touched && meta.error ? true : false}
      />
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </>
  );
};

const StyledTextArea = styled.textarea`
  width: 99%;
  outline: 0;
  border-color: ${({ theme, isInvalid }) =>
    isInvalid ? theme.danger : theme.inputBorder} !important;
  color: ${({ theme }) => theme.headingColor} !important;
  background-color: ${({ isReadOnly }) =>
    isReadOnly ? '#f5f5f5' : 'transparent'};
  opacity: ${({ isReadOnly }) => (isReadOnly ? 0.6 : 1)};
  pointer-events: ${({ isReadOnly }) => (isReadOnly ? 'none' : 'auto')};
  &::placeholder {
    color: ${({ theme }) => theme.inputPlaceHolder};
    font-size: ${({ $phFontSize }) => $phFontSize ?? '14px'};
    font-weight: ${({ $phFontWeight }) => $phFontWeight ?? '14px'};
    opacity: 1;
  }
  padding: 1em;
  &:hover {
    background-color: ${({ isReadOnly }) => (isReadOnly ? '#f5f5f5' : '#fafafa')};
  }
`;

export default FormikStyledTextArea;
