import React from 'react';

const UsersAddedcon = ({ size = 18, color = "#000000", ...props }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="24" height="24" rx="12" fill="#F5F5F5"/>
    <path d="M10.25 6.16669C8.72166 6.16669 7.47916 7.40919 7.47916 8.93752C7.47916 10.4367 8.65166 11.65 10.18 11.7025C10.2267 11.6967 10.2733 11.6967 10.3083 11.7025C10.32 11.7025 10.3258 11.7025 10.3375 11.7025C10.3433 11.7025 10.3433 11.7025 10.3492 11.7025C11.8425 11.65 13.015 10.4367 13.0208 8.93752C13.0208 7.40919 11.7783 6.16669 10.25 6.16669Z" fill="#767476"/>
    <path d="M13.2133 13.2542C11.5858 12.1692 8.93167 12.1692 7.29251 13.2542C6.55167 13.75 6.14334 14.4208 6.14334 15.1383C6.14334 15.8558 6.55167 16.5208 7.28667 17.0108C8.10334 17.5592 9.17667 17.8333 10.25 17.8333C11.3233 17.8333 12.3967 17.5592 13.2133 17.0108C13.9483 16.515 14.3567 15.85 14.3567 15.1267C14.3508 14.4092 13.9483 13.7442 13.2133 13.2542Z" fill="#767476"/>
    <path d="M16.6608 9.28168C16.7542 10.4134 15.9492 11.405 14.835 11.5392C14.8292 11.5392 14.8292 11.5392 14.8233 11.5392H14.8058C14.7708 11.5392 14.7358 11.5392 14.7067 11.5509C14.1408 11.58 13.6217 11.3992 13.2308 11.0667C13.8317 10.53 14.1758 9.72502 14.1058 8.85002C14.065 8.37752 13.9017 7.94585 13.6567 7.57835C13.8783 7.46752 14.135 7.39752 14.3975 7.37418C15.5408 7.27502 16.5617 8.12668 16.6608 9.28168Z" fill="#767476"/>
    <path d="M17.8275 14.6775C17.7808 15.2433 17.4192 15.7333 16.8125 16.0658C16.2292 16.3867 15.4942 16.5383 14.765 16.5208C15.185 16.1417 15.43 15.6692 15.4767 15.1675C15.535 14.4442 15.1908 13.75 14.5025 13.1958C14.1117 12.8867 13.6567 12.6417 13.1608 12.4608C14.45 12.0875 16.0717 12.3383 17.0692 13.1433C17.6058 13.575 17.88 14.1175 17.8275 14.6775Z" fill="#767476"/>
    </svg>
    
    
    

  );
};

export default UsersAddedcon;
