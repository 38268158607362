import React from 'react';
import { Row } from 'react-bootstrap';
import Portlet from '../../../app/components/portlet/Portlet';
import PortletBody from '../../../app/components/portlet/PortletBody';
import PortletHeader from '../../../app/components/portlet/PortletHeader';
import StyledButton from '../../../app/components/styledComponents/StyledButton';
import EditIcon from '../../../app/components/svgIcons/EditIcon';
import SaveIcon from '../../../app/components/svgIcons/SaveIcon';
// import { useParams } from 'react-router-dom';
import FormikTextEditor from '../../../app/components/formikComponents/FormikTextEditor';
import { Formik, Form } from 'formik';
// import { useGetJobInfoQuery } from '../../Job/jobApiSlice';

const JobEditContainer = () => {
  // const { compId, jobId } = useParams();
  // const { data: jobInfo } = useGetJobInfoQuery({ compId, jobId });
  const BasicInformation = [
    'Job Type',
    'Job Company',
    'Job Role',
    'Employment Type',
    'Application Start Date',
    'Application Close Date',
    'No of Positions',
    'Remote',
  ];

  const OtherInformation = [
    'Department',
    'Position Type',
    'Job Keywords',
    'Pay',
  ];

  const handleSubmit = (values) => {
    // console.log(values)
  };

  return (
    <div>
      <Portlet>
        <PortletHeader
          tabs={''}
          title={
            <>
              <div className="flex-center pb-3">
                Visual Designer
                <div className="px-3">
                  <EditIcon size={24} />
                </div>
              </div>
            </>
          }
          titleDetails={'Edit the necessary job details for your applicants'}
          //   subTitle={`(${jobRes?.total || '0'})`}
          actions={
            <>
              <div className="flex-center pb-3">
                <StyledButton
                  className="btn btn-primary"
                  icon={<SaveIcon size={20} />}
                >
                  Save Changes
                </StyledButton>
              </div>
            </>
          }
        />
        <Formik initialValues={{}} enableReinitialize onSubmit={handleSubmit}>
          {({ handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              <Row className="justify-content-center bg-body">
                <div className="col-12 col-md-10">
                  <div className="portlet__body-bg px-5">
                    <PortletBody className="p-0 w-100">
                      <PortletBody className={'p-0 w-100'}>
                        <div className="p-2 p-lg-5">
                          <Row className="pb-3">
                            <h5>Basic Information</h5>
                          </Row>
                          <Row>
                            {BasicInformation?.map((info) => {
                              return (
                                <div className="col-12 col-md-3  py-3">
                                  <div className="d-flex flex-column pb-3">
                                    <h6>{info}</h6>
                                    <div>text</div>
                                  </div>
                                </div>
                              );
                            })}
                          </Row>
                        </div>
                      </PortletBody>
                    </PortletBody>
                  </div>

                  <div className="portlet__body-bg px-5 pt-4">
                    <PortletBody className="p-0 w-100">
                      <PortletBody className={'p-0 w-100'}>
                        <div className="p-2 p-lg-5">
                          <Row className="pb-3">
                            <h5>Other Details</h5>
                          </Row>
                          <Row>
                            {OtherInformation?.map((info) => {
                              return (
                                <div className="col-12 col-md-3  py-3">
                                  <div className="d-flex flex-column pb-3">
                                    <h6>{info}</h6>
                                    <div>text</div>
                                  </div>
                                </div>
                              );
                            })}
                          </Row>
                        </div>
                      </PortletBody>
                    </PortletBody>
                  </div>

                  <div className="portlet__body-bg px-5 pt-4">
                    <PortletBody className="p-0 w-100">
                      <PortletBody className={'p-0 w-100'}>
                        <div className="p-5">
                          <Row className="pb-3">
                            <h5>Job Location</h5>
                          </Row>
                          <Row>
                            <div className="bg-body rounded">
                              <div className="d-flex flex-column p-3">
                                <h6>Location Address</h6>
                                <div>
                                  Gunnersbury House, 1 Chapel Hill, London,
                                  United Kingdom, A11 B12.
                                </div>
                              </div>
                            </div>
                          </Row>
                        </div>
                      </PortletBody>
                    </PortletBody>
                  </div>

                  <div className="portlet__body-bg px-5 pt-4">
                    <PortletBody className="p-0 w-100">
                      <PortletBody className={'p-0 w-100'}>
                        <div className="p-5">
                          <Row className="pb-3">
                            <h5>About Company</h5>
                          </Row>
                          <Row>
                            <FormikTextEditor
                              className="mt-3"
                              name="aboutCompany"
                              borderRadiusEditor={'0 0 8px 8px'}
                              id="aboutCompany"
                              placeholder="About company..."
                            />
                          </Row>
                        </div>
                      </PortletBody>
                    </PortletBody>
                  </div>

                  <div className="portlet__body-bg px-5 pt-4">
                    <PortletBody className="p-0 w-100">
                      <PortletBody className={'p-0 w-100'}>
                        <div className="p-5">
                          <Row className="pb-3">
                            <h5>Elevator Pitch</h5>
                          </Row>
                          <Row>
                            <FormikTextEditor
                              className="mt-3"
                              name="elevatorPitch"
                              id="elevatorPitch"
                              borderRadiusEditor={'0 0 8px 8px'}
                              placeholder="Elevator Pitch..."
                            />
                          </Row>
                        </div>
                      </PortletBody>
                    </PortletBody>
                  </div>

                  <div className="portlet__body-bg px-5 pt-4">
                    <PortletBody className="p-0 w-100">
                      <PortletBody className={'p-0 w-100'}>
                        <div className="p-5">
                          <Row className="pb-3">
                            <h5>Job Description</h5>
                          </Row>
                          <Row>
                            <FormikTextEditor
                              className="mt-3"
                              name="jobDescription"
                              id="jobDescription"
                              borderRadiusEditor={'0 0 8px 8px'}
                              placeholder="Job Description..."
                            />
                          </Row>
                        </div>
                      </PortletBody>
                    </PortletBody>
                  </div>

                  <div className="portlet__body-bg px-5 pt-4">
                    <PortletBody className="p-0 w-100">
                      <PortletBody className={'p-0 w-100'}>
                        <div className="p-5">
                          <Row className="pb-3">
                            <h5>Job Requirements</h5>
                          </Row>
                          <Row>
                            <FormikTextEditor
                              className="mt-3"
                              name="jobRequirement"
                              id="jobRequirement"
                              borderRadiusEditor={'0 0 8px 8px'}
                              placeholder="Job Requirements..."
                            />
                          </Row>
                        </div>
                      </PortletBody>
                    </PortletBody>
                  </div>

                  <div className="portlet__body-bg px-5 pt-4">
                    <PortletBody className="p-0 w-100">
                      <PortletBody className={'p-0 w-100'}>
                        <div className="p-5">
                          <Row className="pb-3">
                            <h5>Company Benefits</h5>
                          </Row>
                          <Row>
                            <FormikTextEditor
                              className="mt-3"
                              name="benefits"
                              borderRadiusEditor={'0 0 8px 8px'}
                              id="benefits"
                              placeholder="Company Benefits..."
                            />
                          </Row>
                        </div>
                      </PortletBody>
                    </PortletBody>
                  </div>

                  <div className="portlet__body-bg px-5 pt-4">
                    <PortletBody className="p-0 w-100">
                      <PortletBody className={'p-0 w-100'}>
                        <div className="p-5">
                          <Row className="pb-3">
                            <h5>Additional Notes</h5>
                          </Row>
                          <Row>
                            <FormikTextEditor
                              className="mt-3"
                              name="additionalNotes"
                              borderRadiusEditor={'0 0 8px 8px'}
                              id="additionalNotes"
                              placeholder="Additional Notes..."
                            />
                          </Row>
                        </div>
                      </PortletBody>
                    </PortletBody>
                  </div>
                  <div className="portlet__body-bg bg-body px-5 pt-4">
                    <PortletBody className="p-0 w-100 bg-body">
                      <PortletBody className={'p-0 w-100 bg-body'}>
                        <div className=" d-flex justify-content-end">
                          <StyledButton className="mb-2" type="submit">
                            <SaveIcon size={22} /> Save Changes
                          </StyledButton>
                        </div>
                      </PortletBody>
                    </PortletBody>
                  </div>
                </div>
              </Row>
            </Form>
          )}
        </Formik>
      </Portlet>
    </div>
  );
};

export default JobEditContainer;
