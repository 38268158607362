import React from 'react';

const FirstAppIcon = ({ size = 18, color = "#000000", ...props }) => {
  return (
<svg width={size} height={size} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.349121" width="24" height="24" rx="12" fill="#F5F5F5"/>
<path d="M15.575 9.53249C15.5341 9.52666 15.4933 9.52666 15.4525 9.53249C14.5483 9.50332 13.8308 8.76249 13.8308 7.85249C13.8308 6.92499 14.5833 6.16666 15.5166 6.16666C16.4441 6.16666 17.2025 6.91916 17.2025 7.85249C17.1966 8.76249 16.4791 9.50332 15.575 9.53249Z" fill="#767476"/>
<path d="M17.4766 13.575C16.8233 14.0125 15.9075 14.1758 15.0616 14.065C15.2833 13.5867 15.4 13.0558 15.4058 12.4958C15.4058 11.9125 15.2775 11.3583 15.0325 10.8742C15.8958 10.7575 16.8116 10.9208 17.4708 11.3583C18.3925 11.965 18.3925 12.9625 17.4766 13.575Z" fill="#767476"/>
<path d="M9.1058 9.53249C9.14663 9.52666 9.18746 9.52666 9.2283 9.53249C10.1325 9.50332 10.85 8.76249 10.85 7.85249C10.85 6.91916 10.0975 6.16666 9.16413 6.16666C8.23663 6.16666 7.48413 6.91916 7.48413 7.85249C7.48413 8.76249 8.20163 9.50332 9.1058 9.53249Z" fill="#767476"/>
<path d="M9.17002 12.4958C9.17002 13.0617 9.29252 13.5983 9.51419 14.0825C8.69169 14.17 7.83419 13.995 7.20419 13.5808C6.28252 12.9683 6.28252 11.9708 7.20419 11.3583C7.82836 10.9383 8.70919 10.7692 9.53752 10.8625C9.29836 11.3525 9.17002 11.9067 9.17002 12.4958Z" fill="#767476"/>
<path d="M12.4192 14.2575C12.3725 14.2517 12.32 14.2517 12.2675 14.2575C11.1942 14.2225 10.3367 13.3417 10.3367 12.2567C10.3425 11.1483 11.235 10.25 12.3492 10.25C13.4575 10.25 14.3558 11.1483 14.3558 12.2567C14.35 13.3417 13.4983 14.2225 12.4192 14.2575Z" fill="#767476"/>
<path d="M10.5232 15.465C9.64234 16.0542 9.64234 17.0225 10.5232 17.6058C11.5265 18.2767 13.1715 18.2767 14.1748 17.6058C15.0557 17.0167 15.0557 16.0483 14.1748 15.465C13.1773 14.7942 11.5323 14.7942 10.5232 15.465Z" fill="#767476"/>
</svg>

    

  );
};

export default FirstAppIcon;
