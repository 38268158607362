import React from 'react';

const CrossCircleIcon = ({ size = 18, ...props }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.00004 14.6761C11.6667 14.6761 14.6667 11.6761 14.6667 8.00944C14.6667 4.34277 11.6667 1.34277 8.00004 1.34277C4.33337 1.34277 1.33337 4.34277 1.33337 8.00944C1.33337 11.6761 4.33337 14.6761 8.00004 14.6761Z" stroke="#767476" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6.11328 9.89638L9.88661 6.12305" stroke="#767476" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M9.88661 9.89638L6.11328 6.12305" stroke="#767476" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>

  );
};

export default CrossCircleIcon;
