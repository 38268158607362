import React from 'react';

const StyledIconBoxColored = ({ color = ' rgba(194, 211, 235, 0.25)', icon, size = '40px', className }) => {
    const boxStyle = {
        backgroundColor: color,
        width: size,
        height: size,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    };

    const boxClassName = `square-box ${className}`;

    return (
        <div style={boxStyle} className={boxClassName}>
            {icon}
        </div>
    );
};

export default StyledIconBoxColored;