import React from 'react';

const SettingsIcon = ({ size = 18 }) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 15.895C13.6569 15.895 15 14.5519 15 12.895C15 11.2382 13.6569 9.89502 12 9.89502C10.3431 9.89502 9 11.2382 9 12.895C9 14.5519 10.3431 15.895 12 15.895Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 13.775V12.015C2 10.975 2.85 10.115 3.9 10.115C5.71 10.115 6.45 8.83502 5.54 7.26502C5.02 6.36502 5.33 5.19502 6.24 4.67502L7.97 3.68502C8.76 3.21502 9.78 3.49502 10.25 4.28502L10.36 4.47502C11.26 6.04502 12.74 6.04502 13.65 4.47502L13.76 4.28502C14.23 3.49502 15.25 3.21502 16.04 3.68502L17.77 4.67502C18.68 5.19502 18.99 6.36502 18.47 7.26502C17.56 8.83502 18.3 10.115 20.11 10.115C21.15 10.115 22.01 10.965 22.01 12.015V13.775C22.01 14.815 21.16 15.675 20.11 15.675C18.3 15.675 17.56 16.955 18.47 18.525C18.99 19.435 18.68 20.595 17.77 21.115L16.04 22.105C15.25 22.575 14.23 22.295 13.76 21.505L13.65 21.315C12.75 19.745 11.27 19.745 10.36 21.315L10.25 21.505C9.78 22.295 8.76 22.575 7.97 22.105L6.24 21.115C5.33 20.595 5.02 19.425 5.54 18.525C6.45 16.955 5.71 15.675 3.9 15.675C2.85 15.675 2 14.815 2 13.775Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SettingsIcon;
