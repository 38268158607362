import styled from 'styled-components';
import themes from '../../../utils/themes';

export const StyledCheck = styled.span`
  height: ${(props) => (props?.size ? `${props?.size}px` : '20px')};
  width: ${(props) => (props?.size ? `${props?.size}px` : '20px')};
  border-radius: ${(props) => (props?.size ? `${props?.size / 3}px` : '6px')};
  margin-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${themes.white};
  transition: background-color 0.3s ease;
  background-color: ${({ theme, value }) =>
    value ? theme.primary : themes.white};
  border: 2px solid
    ${({ theme, value }) => (value ? theme.primary : themes.inputBorder)};
  cursor: pointer;

  svg {
    fill: currentColor;
  }
`;
