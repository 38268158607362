import styled from 'styled-components';

const StyledFilterTabs = styled.div`
  button {
    padding: ${(props) => props.padding ?? '8px'};
    background-color: white;
    border: 1.5px solid ${({ theme }) => theme.inputBorder};
    display: flex;
    align-items: center;
    &:active,
    &:focus,
    &.active {
      color: ${({ theme }) => theme.primary};
      border: 1.5px solid ${({ theme }) => theme.primaryLight2};
      background-color: ${({ theme }) => theme.primaryLight};
    }
    &:hover {
      border: 1.5px solid ${({ theme }) => theme.primaryLight2};
    }
  }
`;

export default StyledFilterTabs;
