import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import UserListingContainer from './listing/UserListingContainer';
const UsersRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<UserListingContainer />} />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};

export default UsersRoutes;
