import React, { useEffect } from 'react';
import PortletBody from '../../../app/components/portlet/PortletBody';
import StyledButton from '../../../app/components/styledComponents/StyledButton';
import StyledColoredTag from '../../../app/components/styledComponents/StyledColoredTag';
import PublishIcon from '../../../app/components/svgIcons/PublishIcon';
import { Col, Row } from 'react-bootstrap';
import DatePickerComponent from '../../../app/components/datePicker/DatePickerComponent';
import { useState } from 'react';
import EditIcon from '../../../app/components/svgIcons/EditIcon';
import CustomDropdown from '../../../app/components/dropdown/CustomDropDown';
import DropdownItem from 'react-bootstrap/esm/DropdownItem';
import UploadLicenseModal from './UploadLicenseModal';
import moment from 'moment';
import StyledInput from '../../../app/components/styledComponents/StyledInput';
import { Link, useParams } from 'react-router-dom';
import WarningIcon from '../../../app/components/svgIcons/WarningIcon';
import DropDownIcon from '../../../app/components/svgIcons/DropDownIcon';
import styled from 'styled-components';
import theme from '../../../utils/themes';
import CallModal from './CallModal';
import { useGetCompanyLicenceDetailsQuery, useUpdateCompanyLicenceMutation, useGetNewCompanyLicenceDetailsQuery } from '../../company/companyApiSlice';
import { toast } from 'react-toastify';
import { DurationFriendly, getFileNameByFileDescriptor, getPreviewURL } from '../../../utils/helper';

const LicenseDetails = ({ activeTab, setSidebar }) => {
  const { compId } = useParams();
  const [date, setDate] = useState(new Date());
  const [editDate, setEditDate] = useState(false);
  const [agreementModal, setAgreementModal] = useState(false);
  const [notes, setNotes] = useState('');
  const [editNotes, setEditNotes] = useState(false);
  const [callModal, setCallModal] = useState(false)
  const { data: newLicenceInfo, refetch: refetchNewLicence, error } = useGetNewCompanyLicenceDetailsQuery(compId, { refetchOnMountOrArgChange: true, });
  const { data: activelicenceInfo, refetch } = useGetCompanyLicenceDetailsQuery(compId);
  const [updateLicenceInfo] = useUpdateCompanyLicenceMutation();

  
  const date1 = new Date();
  const licenceInfo = activeTab === 2 ? newLicenceInfo : activelicenceInfo;
  // const contactType = [
  //   { name: 'Call us', icon: <CallIcon />, isActive: true },
  //   { name: 'Email us', icon: <MailIconFilled />, isActive: false },
  // ];

  const onContact = (item) => {
    item === 'Call us' && setCallModal(!callModal)
  }

  useEffect(() => {
    if (!error && newLicenceInfo) {
      setSidebar(true)
    }
  }, [newLicenceInfo, error])


  useEffect(() => {
    if (!newLicenceInfo) {
      refetchNewLicence()
    }
  }, [])

  useEffect(() => {
    if (licenceInfo || editNotes) {
      setNotes(licenceInfo?.notes)
    }
  }, [licenceInfo, editNotes])

  const updateLicenceDetails = () => {
    const data = {
      param: { startDate: date, endDate: licenceInfo?.endDate ? licenceInfo?.endDate: moment(licenceInfo?.startDate).add(licenceInfo?.licence?.duration - 1, 'days', )},
      compId: compId,
      licenceId: licenceInfo?.id
    }
    apiCall(data);
    newLicenceInfo && refetchNewLicence();
  }
  const updateLicenceNotes = () => {
    const data = {
      param: { notes: notes, endDate: licenceInfo?.endDate ? licenceInfo?.endDate: moment(licenceInfo?.startDate).add(licenceInfo?.licence?.duration - 1, 'days', ) },
      compId: compId,
      licenceId: licenceInfo?.id
    }
    apiCall(data);
  }
  const apiCall = (data) => {
    updateLicenceInfo(data, {
      skip: !licenceInfo?.id,
    }).unwrap().then((res) => {
      refetch()
      toast.success(res?.message || 'Licence Info Updated Successfully')
    }).catch((err) => {
      console.error(err?.data?.message);
    })
  }

  return (
    <>
      <PortletBody className="mb-4">
        {licenceInfo?.expiry?.getTime() <= date1.getTime() ? (
          <Row>
            <StyledExpiry className="d-flex justify-content-between align-items-center p-3 rounded">
              <Col lg={7} className="d-flex align-items-center ">
                <WarningIcon size="30" className="me-2 ms-0 rounded text-danger" />
                <div>
                  <h5 className="m-0 text-danger"> Your license has expired</h5>
                  <label className="mt-0 text-muted fs-6">
                    Please contact occy for renewal
                  </label>
                </div>
              </Col>
              <Col lg={5} className="d-flex justify-content-end">
                <div className="d-flex cursor-pointer align-items-center border border-1 border-primary rounded px-3 py-1 text-primary fw-bold gap-2">
                  <span onClick={''}>Contact Us</span>
                  <StyledVerticalLine />
                  <CustomDropdown
                    dropdownToggle={<DropDownIcon size="12" />}
                    menuClassName={'mt-2'}
                    toggleClassName="p-0 mx-0 bg-transparent"
                    dropdownMenu={
                      <span>
                        {licenceInfo?.map((item, index) => (
                          <DropdownItem key={`cont-${item?.licence?.name}-${index}`} onClick={() => onContact(item?.licence?.name)}>
                            <span className="ms-3 me-5 text-secondary">
                              {item?.icon} {item?.licence?.name}
                            </span>
                          </DropdownItem>
                        ))}
                      </span>
                    }
                  />
                </div>
              </Col>
            </StyledExpiry>
          </Row>
        ) : (
          <Row className="d-flex justify-content-between align-items-center">
            <Col lg={8} className="d-flex ">
              <StyledColoredTag className="ms-0">
                <PublishIcon size="26" />
              </StyledColoredTag>
              <div>
                <h4 className="m-0"> {licenceInfo?.title}</h4>
                <label className="mt-0">{licenceInfo?.subTitle}</label>
              </div>
            </Col>
            <Col lg={4} className="d-flex justify-content-end">
              <StyledButton
                as={Link}
                className="border border-primary fw-bold p-2 rounded text-primary text-decoration-none"
                to={`/companies/licence/${compId}/switch-licence`}
              >
                Switch Licence
              </StyledButton>
            </Col>
          </Row>
        )}
        {
          !licenceInfo ? <div className='d-flex justify-content-center align-items-center'>No Active Licence ...</div> :
            <>
              <h6 className="mt-4 fw-bold">Basic Details</h6>
              <Row className="my-1">
                <Col lg={4}>
                  <span className="fw-bold">Expiry Date</span>
                </Col>
                <Col lg={4}>
                  <span> {licenceInfo?.endDate ? moment(licenceInfo?.endDate).format('MMMM Do YYYY') : moment(licenceInfo?.startDate).add(licenceInfo?.licence?.duration - 1, 'days', ).format('MMMM Do YYYY')}</span>{' '}
                </Col>
                <Col lg={4}></Col>
              </Row>
              <hr />
              <Row className="my-1">
                <Col lg={4}>
                  <span className="fw-bold">Name</span>
                </Col>
                <Col lg={4}>
                  <span>{licenceInfo?.licence?.name}</span>{' '}
                </Col>
                <Col lg={4}></Col>
              </Row>
              <hr />

              <Row className="mt-3 mb-1">
                <Col lg={4}>
                  <span className="fw-bold">Type</span>
                </Col>
                <Col lg={4}>
                  <span>{licenceInfo?.licence?.licenceType?.title}</span>{' '}
                </Col>
                <Col lg={4}></Col>
              </Row>
              <hr />
              <Row className="mt-3 mb-1">
                <Col lg={4}>
                  <span className="fw-bold">Duration</span>
                </Col>
                <Col lg={4}>
                  <span>{licenceInfo?.licence?.duration ? DurationFriendly(licenceInfo?.licence?.duration, true) : ''}</span>{' '}
                </Col>
                <Col lg={4}></Col>
              </Row>
              <hr />
              <Row className="mt-3 mb-1">
                <Col lg={4}>
                  <span className="fw-bold">Basic Accounts</span>
                </Col>
                <Col lg={4}>
                  <span>{licenceInfo?.licence?.maxBasicAccounts}</span>{' '}
                </Col>
                <Col lg={4}></Col>
              </Row>
              <hr />
              <Row className="mt-3 mb-1">
                <Col lg={4}>
                  <span className="fw-bold">Standard Accounts</span>
                </Col>
                <Col lg={4}>
                  <span>{licenceInfo?.licence.maxAccounts}</span>{' '}
                </Col>
                <Col lg={4}></Col>
              </Row>
              <hr />
              <Row className="mt-3 mb-1">
                <Col lg={4}>
                  <span className="fw-bold">Fee</span>
                </Col>
                <Col lg={4}>
                  <span>£{licenceInfo?.licencePaymentPlan?.fee + (licenceInfo?.licencePaymentPlan?.duration ? DurationFriendly(licenceInfo?.licencePaymentPlan?.duration) : '')}</span>{' '}
                </Col>
                <Col lg={4}></Col>
              </Row>
              <hr />

              <Row className="mt-3 mb-1 d-flex align-items-center">
                <Col lg={4}>
                  <span className="fw-bold">Start Date</span>
                </Col>
                <Col lg={4} className="pe-4">
                  {!editDate ? (
                    <span>{licenceInfo?.startDate ? moment(licenceInfo?.startDate).format('MMMM Do YYYY') : '-'} </span>
                  ) : (
                    <DatePickerComponent
                      minDate={new Date()}
                      onChange={(date) => {
                        setDate(date);
                      }}
                      date={date}
                    />
                  )}
                </Col>
                <Col lg={4}>
                  <div className={`d-flex justify-content-end`}>
                    {!editDate ? (
                      <EditIcon
                        className="text-primary me-2 cursor-pointer"
                        onClick={() => setEditDate(true)}
                      />
                    ) : (
                      <>
                        <StyledButton
                          variant="white"
                          className="py-1"
                          onClick={() => {
                            setEditDate(false)
                            updateLicenceDetails()
                          }}
                        >
                          Save
                        </StyledButton>
                        <StyledButton
                          variant="text"
                          className="fw-bold"
                          onClick={() => setEditDate(false)}
                        >
                          Cancel
                        </StyledButton>
                      </>
                    )}
                  </div>
                </Col>
              </Row>
              <hr />
              <Row className="mt-3 mb-1">
                <Col lg={4}>
                  <span className="fw-bold">Agreement</span>
                </Col>
                <Col lg={4}>
                  <span className="text-primary fw-bold">

                    <a
                      className="cursor-pointer text-muted d-flex align-items-center text-decoration-none"
                      href={getPreviewURL(licenceInfo?.compLicenceAgreementSignedUrl)}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {getFileNameByFileDescriptor(licenceInfo?.agreement) ?? licenceInfo?.agreement}</a>
                  </span>{' '}
                </Col>
                <Col lg={4} className="d-flex justify-content-end">
                  <EditIcon
                    className="text-primary me-2 cursor-pointer"
                    onClick={() => setAgreementModal(!agreementModal)}
                  />
                </Col>
              </Row>
              <hr />
              <Row className="mt-3 mb-1">
                <Col lg={4}>
                  <span className="fw-bold">Licence Note</span>
                </Col>
                <Col lg={4} className="px-3">
                  {!editNotes ? (
                    <span>{notes ? notes : licenceInfo?.notes}</span>
                  ) : (
                    <StyledInput
                      value={notes}
                      onChange={(e) => setNotes(e.target.value)}
                      type="textarea"
                      rows="4"
                      className="rounded"
                      placeholder="Enter Notes"
                    />
                  )}
                </Col>
                <Col lg={4} className="d-flex justify-content-end">
                  {!editNotes ? (
                    <EditIcon
                      className="text-primary me-2 cursor-pointer"
                      onClick={() => setEditNotes(true)}
                    />
                  ) : (
                    <>
                      <StyledButton
                        variant="white"
                        className="py-1"
                        disabled={!notes?.length}
                        onClick={() => {
                          setEditNotes(false)
                          updateLicenceNotes();
                        }}
                      >
                        Save
                      </StyledButton>
                      <StyledButton
                        variant="text"
                        className="fw-bold"
                        onClick={() => setEditNotes(false)}
                      >
                        Cancel
                      </StyledButton>
                    </>
                  )}
                </Col>
              </Row>
              {licenceInfo?.licence?.licenseProducts?.length > 0 && <div className='mb-1'>
                <h6 className="mt-4 mb-3 fw-bold">Product Allowances</h6>
                {
                  licenceInfo?.licence?.licenseProducts?.map((item, index) => (
                    <>
                      {index !== 0 && <hr />}
                      <Row key={index} className="mt-2 ">
                        <Col lg={4}>
                          <span className="fw-bold">{item?.product?.title}</span>
                        </Col>
                        <Col lg={4}>
                          <span>{item?.quantity}/{item?.duration} days</span>{' '}
                        </Col>
                        <Col lg={4}></Col>
                      </Row>
                    </>
                  ))
                }
              </div>
              }
            </>
        }
      </PortletBody>
      <UploadLicenseModal
        show={agreementModal}
        compId={compId}
        refetch={refetch}
        licenceId={licenceInfo?.id}
        endDate={licenceInfo?.endDate ? licenceInfo?.endDate: moment(licenceInfo?.startDate).add(licenceInfo?.licence?.duration - 1, 'days', )}
        onHide={() => setAgreementModal(false)}
      />
      <CallModal
        show={callModal}
        onHide={() => setCallModal(false)} />
    </>
  );
};

const StyledVerticalLine = styled.span`
  border-right: 1px solid ${theme.primary};
  margin-left: 5px;
  height: 10px;
`;

const StyledExpiry = styled.div`
  background-color: #FFEBEE;
`;

export default LicenseDetails;
