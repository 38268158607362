import React from 'react';
import Table from '../../../app/components/table/Table';

const UsersListingTable = ({
  users,
  sortColumn,
  onSort,
  tableRef,
  filterTable,
}) => {
  return (
    <Table
      data={users}
      columns={filterTable}
      sortColumn={sortColumn}
      onSort={onSort}
      tableRef={tableRef}
    />
  );
};

export default UsersListingTable;
