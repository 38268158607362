import React, { useState } from 'react';
import { Calendar } from 'react-date-range';
import { Dropdown } from 'react-bootstrap';
import PropTypes from 'prop-types';
import moment from 'moment';
import './datePicker.scss';
import themes from '../../../utils/themes';
import styled from 'styled-components';
import CalenderIcon from '../svgIcons/CalenderIcon';

const DatePickerComponent = ({
  date,
  onChange,
  toggleClassName = 'form-control date-picker__toggle d-flex justify-content-between align-items-center input',
  isValid,
  isInvalid,
  ...props
}) => {
  const [state, setState] = useState(
    moment(new Date(date)).isValid() ? new Date(date) : new Date(),
  );
  const [show, isShow] = useState(false);
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className={`${toggleClassName} ${isValid ? 'is-valid' : ''} ${
        isInvalid ? 'is-invalid' : ''
      }`}
    >
      <>
        {children}
        <CalenderIcon />
      </>
    </div>
  ));
  const onToggleHandler = (isOpen, e, metadata) => {
    if (!e?.originalEvent?.srcElement?.closest('.rdrCalendarWrapper')) {
      isShow(isOpen);
    }
  };
  const CustomMenu = React.forwardRef(
    ({ style, className, 'aria-labelledby': labeledBy }, ref) => {
      return (
        <div
          ref={ref}
          style={style}
          className={`hello ${className}`}
          aria-labelledby={labeledBy}
        >
          <Calendar
            date={state}
            onChange={(item) => {
              setState(item);
              onChange(item);
              isShow(false);
            }}
            {...props}
            color={themes.primary}
            rangeColors={[themes.primary]}
          />
          <div className=" d-flex  align-items-center justify-content-end mr-2 mb-2 d-none">
            <Dropdown.Item>Cancel</Dropdown.Item>
            <Dropdown.Item>Ok</Dropdown.Item>
          </div>
        </div>
      );
    },
  );

  return (
    <DatePickerStyled
      autoClose={true}
      show={show}
      onToggle={(isOpen, e, metadata) => onToggleHandler(isOpen, e, metadata)}
    >
      <Dropdown.Toggle
        id="single-date-picker"
        as={CustomToggle}
        onClick={() => isShow(!show)}
      >
        {props.children || (
          <span className="date-view m-0">
            {moment(new Date(date)).isValid() ? (
              moment(new Date(date)).format('Do MMMM YY')
            ) : (
              <span className="date-placeholder">Select date</span>
            )}
          </span>
        )}
      </Dropdown.Toggle>
      <Dropdown.Menu as={CustomMenu} className="p-0 border-0 shadow-lg" />
    </DatePickerStyled>
  );
};

DatePickerComponent.propTypes = {
  date: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  toggleClassName: PropTypes.string,
  children: PropTypes.any,
  isValid: PropTypes.any,
  isInvalid: PropTypes.any,
};

const DatePickerStyled = styled(Dropdown)`
  .date-picker__toggle {
    padding: 8px 14px;
    cursor: pointer;
    font-size: 14px;
    border-radius: 8px;
    border: 1.5px solid ${({ theme }) => theme.inputBorder};
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.headingColor};

    background: ${({ theme }) => theme.white};
    border-color: ${({ theme }) => theme.inputBorder};

    &:after {
      content: none;
    }

    &:focus {
      box-shadow: none !important;
      border-color: ${({ theme }) => theme.primaryLight2} !important;
    }

    &:hover,
    &:active,
    &:focus {
      background-color: ${({ theme }) => theme.white} !important;
      color: ${({ theme }) => theme.headingColor} !important;
    }

    .date-view {
      margin: 0 10px;
      cursor: pointer;
    }

    &.is-invalid {
      border-color: ${({ theme }) => theme.danger} !important;
    }

    //
    // &.is-valid {
    //   border-color: ${({ theme }) => theme.success} !important;
    // }

    .date-placeholder {
      color: ${({ theme }) => theme.inputPlaceHolder} !important;
    }
  }
`;
export default DatePickerComponent;
