import React from 'react';

// import Skeleton, { Table } from '@nejcm/react-skeleton';



import Skeleton from 'react-loading-skeleton';

import 'react-loading-skeleton/dist/skeleton.css';

// import { useLocation } from 'react-router-dom';

// import styled from 'styled-components';

import PortletBody from '../../app/components/portlet/PortletBody';



const AnalyticSkeleton = ({ cols, show, customeColWidth=200 }) => {

  return (
    <>
      <PortletBody>
      {Array(2).fill(null).map((col, ind) => ( <div key={ind} className="flex-center mb-4 d-flex justify-content-between">

        {Array(10).fill(null).map((col, ind) => (

    <div key={ind} className="flex-grow-1 m-2">

      <Skeleton height={80} width={'100%'} className="me-2 mt-2" />

    </div>

        ))}

        </div>))}



        {Array(2).fill(null).map((col, ind) =>(<div key={ind} className="flex-center mb-4 d-flex justify-content-between">

        {Array(2).fill(null).map((col, ind) =>(<div key={ind} className="w-50 flex-grow-1 m-2">

      <Skeleton height={100} width={'100%'} className="me-2 mt-2" />

    </div>  ))}

   

    </div>))}

      </PortletBody>

    </>

  );

};


export default AnalyticSkeleton;

