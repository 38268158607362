import React from 'react';

const ToggleArrowIcon = ({ size = 18, rotate = 'up', ...props }) => {
  const getRotateDeg = (val) => {
    switch (val) {
      case 'down':
        return '90';
      case 'left':
        return '180';
      case 'up':
        return '270';
      default:
        return '0';
    }
  };
  return (
    <svg width={size} height={size} viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg"
      style={{
        transform: `rotate(${getRotateDeg(rotate)}deg)`,
        transition: 'transform 0.3s ease'
      }}
      {...props}>
      <path d="M14.5984 7.54169L9.1651 2.10835C8.52344 1.46669 7.47344 1.46669 6.83177 2.10835L1.39844 7.54169" stroke="#0A0509" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    </svg>

  );
};

export default ToggleArrowIcon;
