import React from 'react';
import FormikAsyncSelectMulti from '../../../../app/components/formikComponents/FormikAsyncSelectMulti';
import FormikDatePicker from '../../../../app/components/formikComponents/FormikDatePicker';  
import FormikInputField from '../../../../app/components/formikComponents/FormikInputField';
import FormikSelect from '../../../../app/components/formikComponents/FormikSelect';
import { compaignStatus } from '../../assets/analyticsUtil';
const CampaignsFilterForm = ({ setFieldValue, submitForm, loading, onhandleChange ,values}) => {

 
  let companyIdPayload = {
    companyIds: values?.companyIds?.map((item) => item?.value),
  };

  return (
    <>
      <div className="col-12">
        <div className="mb-4">
          <label htmlFor="campaignName" className="form-label">
            Campaign Name
          </label>
          <FormikAsyncSelectMulti
            name="campaignName"
            id="campaignName"
            // apiCall={()=>getJobCampaign(companyIdPayload)}
            params={{...companyIdPayload}}
            type="campaigns"
            isMulti={true}
            placeholder="Select campaign"
            onSelect={(values) => onhandleChange('campaignName', values)}
          />
        </div>
      </div>

      <div className="col-12">
        <div className="mb-4">
          <label htmlFor="campaignJobName" className="form-label">
            Job Name for Campaign
          </label>
          <FormikAsyncSelectMulti
            name="campaignJobName"
            id="campaignJobName"
            // apiCall={()=>getJobNameForCampaign(companyIdPayload)}
            params={{...companyIdPayload}}
            type="jobNameForcampaigns"
            isMulti={true}
            placeholder="Select campaign Job Name"
            onSelect={(values) => onhandleChange('campaignJobName', values)}
          />
        </div>
      </div>

      <div className="col-12">
        <div className="mb-4">
          <label htmlFor="campaignReferenceId" className="form-label">
            Campaign Reference ID
          </label>
          <FormikInputField
            name="campaignReferenceId"
            id="campaignReferenceId"
            placeholder="Enter Campaign Reference ID"
             onChange={(e) =>{
             onhandleChange('campaignReferenceId', e?.target?.value)
            }}
          />
        </div>
      </div>

      <div className="col-12">
        <div className="mb-4">
          <label htmlFor="campaignStartDate" className="form-label">
            Campaign Start Date
          </label>
          <FormikDatePicker
            name="campaignStartDate"
            id='campaignStartDate'
            placeholder="Select Start Date"
            onChange={(date) => onhandleChange('campaignStartDate', date)}
          />
        </div>
      </div>

      <div className="col-12">
        <div className="mb-4">
          <label htmlFor="campaignEndDate" className="form-label">
            Campaign End Date
          </label>
          <FormikDatePicker
            name="campaignEndDate"
            id='campaignEndDate'
            placeholder="Select Campaign End Date"
            onChange={(date) => onhandleChange('campaignEndDate', date)}
          />
        </div>
      </div>
      <div className="col-12">
        <div className="mb-4">
          <label htmlFor="campaignDuration" className="form-label">
          Campaign Duration
          </label>
          <FormikSelect
            name="campaignDuration"
            id='campaignDuration'
            menuPlacement='bottom'
            placeholder="Select Campaign Duration"
            options={[
              { label: '15 days', value: 15 },
              { label: '30 days', value: 30 },
              { label: '60 days', value: 60 },
            ]}
            onChange={(value) => onhandleChange('campaignDuration', value?.value??value?.label)}
          />
        </div>
      </div>
      <div className="col-12">
        <div className="mb-4">
          <label htmlFor="campaignRepeatAfter" className="form-label">
            Campaign Repeats After
          </label>
          <FormikSelect
            name="campaignRepeatAfter"
            id='campaignRepeatAfter'
            menuPlacement={'top'}
            placeholder="Select Campaign Repeats After"
            options={[
              { label: '15 days', value: 15 },
              { label: '30 days', value: 30 },
              { label: '60 days', value: 60 },
              { label: 'One time only', value: 1 },
            ]}
            onChange={(value) => onhandleChange('campaignRepeatAfter', value?.value??value?.label)}
          />
        </div>
      </div>
      <div className="col-12">
        <div className="mb-4">
          <label htmlFor="channelIds" className="form-label">
          Campaign Status
          </label>
          <FormikSelect
            name="campaignStatus"
            id='campaignStatus'
            menuPlacement={'top'}
            placeholder="Select Campaign Status"
            options={compaignStatus}
            onChange={(values) => onhandleChange('campaignStatus', typeof values?.value === 'undefined'
            ? values?.label
            : values.value,)}
          />
        </div>
      </div>

      <div className="col-12">
        <div className="mb-4">
          <label htmlFor="channelIds" className="form-label">
          Channels
          </label>
          <FormikAsyncSelectMulti
            name="channelIds"
            id="channelIds"
            // apiCall={()=>getCampaignChannel(companyIdPayload)}
            params={{...companyIdPayload}}
            type='channels'
            isMulti={true}
            menuPlacement={'top'}
            placeholder="Select Channels"
            onChange={(value) => onhandleChange('channelIds', value)}
          />
        </div>
      </div>
  
    </>
  );
};

export default CampaignsFilterForm;
