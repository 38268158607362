import React from 'react';

const DateIcon = ({ size = 18, color = "#000000", ...props }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.33333 0.166656H3.66667C1.625 0.166656 0.75 1.33332 0.75 3.08332V8.91666C0.75 10.6667 1.625 11.8333 3.66667 11.8333H8.33333C10.375 11.8333 11.25 10.6667 11.25 8.91666V3.08332C11.25 1.33332 10.375 0.166656 8.33333 0.166656ZM3.66667 6.14582H6C6.23917 6.14582 6.4375 6.34416 6.4375 6.58332C6.4375 6.82249 6.23917 7.02082 6 7.02082H3.66667C3.4275 7.02082 3.22917 6.82249 3.22917 6.58332C3.22917 6.34416 3.4275 6.14582 3.66667 6.14582ZM8.33333 9.35416H3.66667C3.4275 9.35416 3.22917 9.15582 3.22917 8.91666C3.22917 8.67749 3.4275 8.47916 3.66667 8.47916H8.33333C8.5725 8.47916 8.77083 8.67749 8.77083 8.91666C8.77083 9.15582 8.5725 9.35416 8.33333 9.35416ZM9.79167 4.39582H8.625C7.73833 4.39582 7.02083 3.67832 7.02083 2.79166V1.62499C7.02083 1.38582 7.21917 1.18749 7.45833 1.18749C7.6975 1.18749 7.89583 1.38582 7.89583 1.62499V2.79166C7.89583 3.19416 8.2225 3.52082 8.625 3.52082H9.79167C10.0308 3.52082 10.2292 3.71916 10.2292 3.95832C10.2292 4.19749 10.0308 4.39582 9.79167 4.39582Z" fill="#767476"/>
    </svg>
    

  );
};

export default DateIcon;
