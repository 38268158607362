import React from 'react';

const WarningIcon = ({ size = 14, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      d="M8.66699 9.33331H7.33366V5.99998H8.66699M8.66699 12H7.33366V10.6666H8.66699M0.666992 14H15.3337L8.00033 1.33331L0.666992 14Z"
      fill="currentColor"
    />
  </svg>
);

export default WarningIcon;
