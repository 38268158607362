import { useEffect, useRef, useState } from 'react';

const CustomCircularProgressbar = ({ value, size = 20, strokeWidth = 3, trailWidth = 3, trailColor = '#988D96', strokeColor = '#4A154B', textColor = '#6b7280', className = '', ...props }) => {
  const [offset, setOffset] = useState(0);
  const circleRef = useRef(null);

  useEffect(() => {
    const circumference = 2 * Math.PI * (10 - (strokeWidth / 2));
    const progressPercentage = value / 100;
    const progressOffset = circumference * (1 - progressPercentage);

    setOffset(progressOffset);
    circleRef.current.style = 'transition: stroke-dashoffset 850ms ease-in-out;';
  }, [setOffset, value, strokeWidth]);

  return (
    <svg
      className={`CircularProgressbar ${className}`}
      viewBox="0 0 20 20"
      width={size}
      height={size}
      {...props}
    >
      <circle
        className="CircularProgressbar-trail"
        cx="10"
        cy="10"
        r={10 - trailWidth / 2}
        strokeWidth={trailWidth}
        stroke={trailColor}
        fill="none"
      />
      <circle
        className="CircularProgressbar-path"
        cx="10"
        cy="10"
        r={10 - strokeWidth / 2}
        strokeWidth={strokeWidth}
        stroke={strokeColor}
        strokeDasharray={` ${2 * Math.PI * (10 - strokeWidth / 2)}`}
        strokeDashoffset={offset}
        strokeLinecap="round"
        fill="none"
        ref={circleRef}
      />
      <text
        x="10"
        y="10"
        className="CircularProgressbar-text"
        fill={textColor}
        dominantBaseline="middle"
        textAnchor="middle"
      >
        {/* {value}% */}
      </text>
    </svg>
  );
};

export default CustomCircularProgressbar;
