import React from 'react';
import FormikAsyncSelect from '../../../../app/components/formikComponents/FormikAsyncSelect';
import { useGetAllCompaniesListMutation } from '../../../company/companyApiSlice';

const CompaniesFilterForm = ({ submitForm, loading,onhandleChange }) => {
  const [getAllChildCompanies]=useGetAllCompaniesListMutation()
  return (
    <>
      <div className="col-12">
        <div className="mb-4">
          <label htmlFor="companyIds" className="form-label">
            Select Company
          </label>
          <FormikAsyncSelect
            name="companyIds"
            id="companyIds"
            isMulti
            apiCall={getAllChildCompanies}
            type="childCompanies"
            placeholder="Choose..."
            onSelect={(values)=>onhandleChange('companyIds', values)}
          />
        </div>
      </div>
    </>
  );
};

export default CompaniesFilterForm;
