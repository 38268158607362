import SortingIcon from '../svgIcons/SortingIcon';

const TableHeader = ({ sortColumn, columns, onSort }) => {
  const raiseSort = (path) => {
    if (!path) return;
    const sort = { ...sortColumn };
    if (sort.path === path)
      sort.order = sortColumn.order === 'ASC' ? 'DESC' : 'ASC';
    else {
      sort.path = path;
      sort.order = 'ASC';
    }
    onSort(sort);
  };

  const renderSortIcon = (column) => {
    if (column.path !== sortColumn.path) return null;
    if (column.path === 'hired') return null;
    if (sortColumn.order === 'ASC') return <SortingIcon />;
    return <SortingIcon rotate="up" />;
  };

  return (
    <thead className="bg-body">
      <tr>
        {columns.map((column, trIndex) => (
          <th
            className={`${column.alignHeader} text-nowrap`}
            key={`${column.id || column.key}-${trIndex}`}
            onClick={() => raiseSort(column.path)}
          >
            {column.label} {renderSortIcon(column)}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default TableHeader;
