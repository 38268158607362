import React from 'react';

const OptionFillIcon = ({ rotate = 'down', size = 16, ...props }) => {
  const getRotateDeg = (val) => {
    switch (val) {
      case 'left':
        return '90';
      case 'up':
        return '180';
      case 'right':
        return '270';
      default:
        return '0';
    }
  };
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ transform: `rotate(${getRotateDeg(rotate)}deg)` }}
      {...props}
    >
      <path
        d="M12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14Z"
        fill="currentColor"
      />
      <path
        d="M4 14C5.10457 14 6 13.1046 6 12C6 10.8954 5.10457 10 4 10C2.89543 10 2 10.8954 2 12C2 13.1046 2.89543 14 4 14Z"
        fill="currentColor"
      />
      <path
        d="M20 14C21.1046 14 22 13.1046 22 12C22 10.8954 21.1046 10 20 10C18.8954 10 18 10.8954 18 12C18 13.1046 18.8954 14 20 14Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default OptionFillIcon;
