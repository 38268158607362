import {isUndefined} from "lodash";

const packageFile = require('../../package.json');

const APP_IDENTIFIER = `${packageFile.name.toUpperCase()}_`;
export const occyLs = {
  removeItem: (itemKey) => localStorage.removeItem(APP_IDENTIFIER + itemKey),
  clear: () => localStorage.clear(),
  length: localStorage.length,
  key: localStorage.key,
  getItem: (itemKey) => {
    const val = localStorage.getItem(APP_IDENTIFIER + itemKey);
    if (val === 'undefined') return undefined;
    return val;
  },
  setItem: (itemKey, itemValue) => {
    if (!isUndefined(itemValue))
      localStorage.setItem(APP_IDENTIFIER + itemKey, itemValue)
  },
  setObject: (itemKey, value) => {
    if (!isUndefined(value))
      occyLs.setItem(itemKey, JSON.stringify(value))
  },
  getObject: (itemKey) => {
    const val = occyLs.getItem(itemKey);
    if(isUndefined(val)) return undefined;
    return JSON.parse(val);
  },
};
