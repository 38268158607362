import React, { useCallback, useEffect, useState } from 'react';
import { AsyncPaginate } from 'react-select-async-paginate';
import themes from '../../../utils/themes';
import styled from 'styled-components';
import theme from '../../../utils/themes';
import {useGetCampaignChannelMutation, useGetJobRejectionReasonMutation,useGetJobCampaignMutation, useGetJobNameForCampaignMutation , useGetFilterCompanyJobsMutation,useGetCompanyHiringManagerMutation,useGetCompanyApproverMutation, useGetCompanyJobCategoriesMutation, useGetCompanyDepartmentMutation } from '../../../features/analytics/analyticsApiSlice';
import StyledSelectCss from './StyledSelectCss';

const AsyncSelectMulti = ({
  placeholder = 'Select a form from Library',
  className,
  onSelect,
  value,
  type,
  position,
  CustomOptionLayout,
  icon,
  isEmpty,
  ...props
}) => {
  const [selectedValue, setSelectedValue] = useState(value);
  useEffect(() => {
    if (props.isMulti && value) {
      setSelectedValue([...value]);
    } else if (!props?.isMulti) {
      if (value)
        setSelectedValue({
          ...value,
          label: value?.title || value?.label,
          value: value?.id || value?.value,
        });
      else {
        if (type === 'meetingTypes') setSelectedValue({});
        else setSelectedValue();
      }
    }
  }, [value]);
  const [getCompanyFilterJobs] = useGetFilterCompanyJobsMutation();
  const [getCompanyJobCategories] = useGetCompanyJobCategoriesMutation();
  const [getCompanyDepartment] = useGetCompanyDepartmentMutation();
  const [getCompanyHiringManager] = useGetCompanyHiringManagerMutation();
  const [getComapanyApprovers] = useGetCompanyApproverMutation();
  const [getJobRejectionReason] = useGetJobRejectionReasonMutation();
  const [getJobCampaign] = useGetJobCampaignMutation();
  const [getJobNameForCampaign] = useGetJobNameForCampaignMutation();
  const [getCampaignChannel] = useGetCampaignChannelMutation();
  const sortTypes = [
    'Internalforms',
    'forms',
    'jobs',
    'OfferTempJobCreation',
    'eventTeamwithIcon',
    'roles',
    'interviewRoles',
    'users',
    'eventTeam',
    'locations',
    'rejectionReasons',
  ];
  const loadMoreTypes = [
    'candidates',
    'jobCategories',
    'departments',
    'jobs',
    'eventTeam',
    'eventTeamwithIcon',
    'rejectionReasons',
    'searchLocation',
    'campaigns',
    'jobNameForcampaigns',
    'channels',
    'froms',
    'hiringManager',
    'recruiters',
    'interviewers',
    'approvers',
    'childCompanies',
  ];
  const getApiToCall = (type) => {
    switch (type) {
      case 'jobs':
        return props?.params?.companyIds?.length > 0 ? getCompanyFilterJobs :'';
      case 'jobCategories':
        return props?.params?.companyIds?.length > 0 ? getCompanyJobCategories : '';
      case 'departments':
        return  getCompanyDepartment
        case 'hiringManager':
          return getCompanyHiringManager
        case 'approvers':
          return  getComapanyApprovers
          case 'jobNameForcampaigns':
            return getJobNameForCampaign
          case 'campaigns':
            return  getJobCampaign
            case 'channels':
              return getCampaignChannel
            case 'rejectionReasons':
              return getJobRejectionReason
      default:
        return getCompanyFilterJobs;
    }
  };

  async function loadOptions(search, loadedOptions, { page }) {
    let param = {
      perPage: 10,
      page,
      query: search,
      // sort: 'title', sortDir: 'ASC',

      ...(type === 'application' && {
        candidateId: props?.params?.candidateId,
      }),
      ...(sortTypes.includes(type) && {
        sort:
          type === 'locations'
            ? 'postcode'
            : type === 'rejectionReasons'
            ? 'text'
            : 'title',
        sortDir: 'ASC',
      }),
      ...((type === 'jobApplication' || type === 'hiringplans') && {
        jobId: props.params?.jobId,
      }),
      ...(type === 'jobPositions' && {
        ...props?.params,
        jobId: props.params?.jobId,
      }),
      ...(type === 'jobs' && {
        ...props?.params ,
      }),
      ...(type === 'jobCategories' && {
        ...props?.params,
      }),
      ...(type === 'departments' && {
        ...props?.params,
      }),
      ...(type === 'hiringManager' && {
        ...props?.params,
      }),
      ...(type === 'approvers' && {
        ...props?.params,
      }),
      
      ...(type === 'rejectionReasons' && {
        ...props?.params,
      }),
      ...(type === 'jobNameForcampaigns' && {
        ...props?.params,
      }),
      ...(type === 'campaigns' && {
        ...props?.params,
      }),
      ...(type === 'channels' && {
        ...props?.params,
      }),

    };
    if (type === 'jobStages') {
      param = { param, jobId: props?.jobId };
    }
    if (type === 'hiringLocation') {
      param = { jobId: props?.jobId };
    }
    if (type === 'tplStages') {
      param = { param, tplId: props?.jobId };
    }
    if (type === 'meetingTypes') {
      param = { accountIds: props?.accountId.map((val) => val) };
    }
    if(type === 'childCompanies'){

      param={...param, statusId:props.statusId}
    }
    try {
      let call = getApiToCall(type);
      const { data } = await call(param);
      const options = await data?.options?.map((option) => ({
        value: option.id,
        label: option.title,
        ...(type === 'Internalforms' && {
          createdAt: option.createdAt,
        }),
        ...(type === 'candidateSources' && {
          value: option.value,
          label: { title: option.title, productType: option.productType },
        }),
        ...(type === 'meetingTypes' && {
          value: option?.value,
          label: { text: option?.label?.text, icon: option?.label?.icon },
        }),
        ...(type === 'searchLocation' && {
          value: option,
        }),
        ...(type === 'gapi' && {
          latitude: option.lat,
          longitude: option.lon,
          value: option.value,
        }),
        ...((type === 'templateLocation' || type === 'locations') && {
          town: option.town,
          postcode: option.postcode,
          value: option.value,
        }),
        ...(type === 'application' && { label: option?.job?.title }),
        // ...(type === 'jobs' && { label: option?.title, value: option?.value }),

        ...(type === 'eventTeam' && {
          label: option?.firstName + ' ' + option?.lastName,
          signedUrl: option.signedUrl,
        }),
        ...((type === 'hiringManager' ||
          type === 'approvers' ||
          type === 'recruiters' ||
          type === 'interviewers') && {
          label: option?.firstName + ' ' + option?.lastName,
          signedUrl: option?.signedUrl,
          unBudgeted: option?.unBudgeted,
          budgeted: option?.budgeted,
        }),
        ...(type === 'eventTeamwithIcon' && {
          label: {
            text: option?.firstName + ' ' + option?.lastName,
            icon: option.signedUrl,
          },
          signedUrl: option.signedUrl,
          email: option?.email ?? '',
          accountId: option?.id,
        }),
        ...(type === 'applicationjobs' && {
          label: option?.label,
        }),
        ...(type === 'jobPositions' && {
          option: option,
        }),
        ...(type === 'hiringplans' && {
          startdate: option?.startDate,
          postions: option?.postions,
          ...option,
        }),
        ...(type === 'campaigns' && {
          value: option?.campaign?.id,
          label: option?.campaign?.campaignName,
        }),
        ...(type === 'jobNameForcampaigns' && {
          value: option?.campaign?.id,
          label: option?.campaign?.jobTitle,
        }),
        ...(type === 'candidates' && {
          label: `${option?.candidate?.firstName} ${option?.candidate?.lastName}`,
          value: option?.id,
        }),
        ...(type === 'hiringLocation' && {
          label: `${option?.title}`,
          value: option.value,
        }),
        ...(type === 'jobStages' && {
          label: option?.label,
          value: option.value,
        }),
        ...(type === 'OfferTempJobCreation' && {
          value: option?.value,
          label: option?.label,
          templatedata: option?.document,
        }),
        ...(type === 'interviewRoles' && {
          label: option.label,
          value: option.value,
        }),
      }));

      return {
        options: options || [],
        hasMore: loadedOptions.length + options.length < data?.total,
        ...(loadMoreTypes.includes(type) && {
          hasMore: loadedOptions.length + options.length < data?.totalCount,
        }),
        additional: {
          page: page + 1,
        },
      };
    } catch (e) {
      return {
        options: [],
        hasMore: false,
      };
    }
  }

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? themes.primaryLight
        : provided.backgroundColor,
      borderRadius: props.borderRadius ? 10 : 8,
      padding: '10px 15px',
      color: themes.textColor,
      cursor: 'pointer',
    }),
  };

  const handleChange = useCallback((val) => {
    if (onSelect) {
      onSelect(val);
    } else {
      setSelectedValue(val);
    }
  }, []);

  const optionalData = (option) =>
    CustomOptionLayout ? CustomOptionLayout(option) : option?.label;

  return (
    <CustomAsync className={icon && icon ? 'analytics' : ''}>
      {icon && icon}
      <StyledSelectCss>
        <AsyncPaginate
          menuPlacement={position}
          value={selectedValue}
          onChange={handleChange}
          styles={customStyles}
          placeholder={placeholder}
          loadOptions={loadOptions}
          getOptionLabel={(option) => optionalData(option)}
          cacheUniqs={[props.params?.jobId || props?.accountId || props?.params]}
          theme={(theme) => ({
            ...theme,
            borderRadius: 10,
            colors: {
              ...theme.colors,
              primary25: themes.lightHover,
              primary50: themes.primaryLight,
            },
          })}
          style={{ width: 100 }}
          className={`async-select ${props.isValid ? 'is-valid' : ''} ${
            props.isLightPink ? 'is-light-pink' : ''
          } ${props.isInvalid ? 'is-invalid' : ''}
          ${props.analytics ? 'analytics' : ''} ${
            props.disabled ? 'disabled' : ''
          } ${className ?? ''}`}
          additional={{
            page: 1,
          }}
          {...props}
        />
      </StyledSelectCss>
    </CustomAsync>
  );
};

const CustomAsync = styled.div`
  &.analytics {
    > div {
      width: 100%;
    }
    display: flex;
    align-items: center;
    cursor: pointer;
    background: ${theme.white};
    font-size: 14px;
    border: 1px solid ${theme.borderLight};
    border-radius: 8px;
    width: 170px;
    max-width: 170px;
    margin-top: 5px;
    height: 30px;
    z-index: 999;
    padding: 0px;
    padding-block: 0px;
    &:hover,
    &:active,
    &:focus {
      border: 1px solid ${theme.inputBorder};
      background: ${theme.primaryLight};
      color: ${theme.primary};
      box-shadow: none;
    }
  }
`;

export default AsyncSelectMulti;
