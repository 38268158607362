import React from 'react';

const PauseIcon = ({size = 20}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.00016 13.2668V2.7335C7.00016 1.7335 6.5735 1.3335 5.5135 1.3335H2.82016C1.76016 1.3335 1.3335 1.7335 1.3335 2.7335V13.2668C1.3335 14.2668 1.76016 14.6668 2.82016 14.6668H5.5135C6.5735 14.6668 7.00016 14.2668 7.00016 13.2668Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.6667 13.2668V2.7335C14.6667 1.7335 14.24 1.3335 13.18 1.3335H10.4867C9.42667 1.3335 9 1.7335 9 2.7335V13.2668C9 14.2668 9.42667 14.6668 10.4867 14.6668H13.18C14.24 14.6668 14.6667 14.2668 14.6667 13.2668Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default PauseIcon;
