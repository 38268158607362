import React from 'react';

const SalaryDetailIcon = ({ size = 16, ...props }) => (
<svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<path d="M19.3 7.91949V13.0695C19.3 16.1495 17.54 17.4695 14.9 17.4695H6.10995C5.65995 17.4695 5.22996 17.4295 4.82996 17.3395C4.57996 17.2995 4.33996 17.2295 4.11996 17.1495C2.61996 16.5895 1.70996 15.2895 1.70996 13.0695V7.91949C1.70996 4.83949 3.46995 3.51953 6.10995 3.51953H14.9C17.14 3.51953 18.75 4.46953 19.18 6.63953C19.25 7.03953 19.3 7.44949 19.3 7.91949Z" stroke="#585558" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M22.3011 10.9196V16.0696C22.3011 19.1496 20.5411 20.4696 17.9011 20.4696H9.11105C8.37105 20.4696 7.70106 20.3697 7.12106 20.1497C5.93106 19.7097 5.12105 18.7997 4.83105 17.3397C5.23105 17.4297 5.66105 17.4696 6.11105 17.4696H14.9011C17.5411 17.4696 19.3011 16.1496 19.3011 13.0696V7.91962C19.3011 7.44962 19.2611 7.02965 19.1811 6.63965C21.0811 7.03965 22.3011 8.37962 22.3011 10.9196Z" stroke="#585558" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M10.4984 13.1394C11.9564 13.1394 13.1384 11.9574 13.1384 10.4994C13.1384 9.04136 11.9564 7.85938 10.4984 7.85938C9.04038 7.85938 7.8584 9.04136 7.8584 10.4994C7.8584 11.9574 9.04038 13.1394 10.4984 13.1394Z" stroke="#585558" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M4.78003 8.2998V12.6998" stroke="#585558" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M16.2217 8.2998V12.6998" stroke="#585558" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

);

export default SalaryDetailIcon;
