import React from 'react';
import PropTypes from 'prop-types';
import ScrollContainer from 'react-indiana-drag-scroll';

const PortletHeaderTabs = ({ tabs=[], onChangeTabs }) => {
  const handleChangeTabs = (tab) => () => {
    onChangeTabs(tab);
  };
  return (
    <ScrollContainer
      className="portlet__header-tabs"
      vertical={false}
      hideScrollbars
    >
      {tabs?.map((tab, i) => (
        <div
          key={tab.id || i}
          className={`${tab.tabsDisbaled ? 'feature-pending' : ''} portlet__header-tab ${tab.active ? 'tab-active' : ''}`}
          onClick={handleChangeTabs(tab.id)}
        >
          {tab.icon && (
            <span className="mx-2">
              {React.cloneElement(tab.icon, {
                fill: tab.active ? '#A5A9AE' : '#A5A9AE',
                color: tab.active ? '#EC008C' : '',
              })}
            </span>
          )}
          {tab.title}
        </div>
      ))}
    </ScrollContainer>
  );
};

PortletHeaderTabs.propTypes = {
  tabs: PropTypes.array.isRequired,
  onChangeTabs: PropTypes.func.isRequired,
};

export default PortletHeaderTabs;
