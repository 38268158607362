import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom';
import CompanyCampaignsListingContainer from './listing/CampaignsListingContainer';
import CampaignPostingDetailPage from './listing/CampaignPostingDetailPage';


const CampaignRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<CompanyCampaignsListingContainer globalCampaign />} />
            <Route path="/:campaignId/posting/:postingId" element={<CampaignPostingDetailPage globalCampaign />} />
            <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
    )
}

export default CampaignRoutes