import React from 'react';

const OpenSidebarIcon = ({ size = 30 }) => {
  return (
    <svg
      width="30"
      height="31"
      viewBox="0 0 30 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.75 8.19751H26.25"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M10 15.4475L26.25 15.4475"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M3.75 22.6975H26.25"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M6.25 17.9475L3.95342 15.8894C3.68219 15.6464 3.68219 15.2486 3.95342 15.0056L6.25 12.9475"
        stroke="white"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default OpenSidebarIcon;
