import React from 'react';
import { Col, Row } from 'react-bootstrap';
import PortletBody from '../../../../../app/components/portlet/PortletBody';
import { StyledLabel } from '../../../../../assets/styledComponents/Global';

const JobBoardView = ({ productDetails }) => {
 
  return (
    <>
      <div className="d-flex bg-body justify-content-center align-item-center w-100">
        <PortletBody className="mb-4 w-50">
          <Row className="d-flex justify-content-between align-items-center">
            <Col lg={8} className="d-flex">
              <div>
                <h4 className="m-0">Job Boards</h4>
              </div>
            </Col>
            <Col lg={4} className="d-flex justify-content-end">
            </Col>
          </Row>
          <>
            {productDetails?.product?.jobboardProducts?.map((item, index) => {
              return (
              <div className="row my-3 mx-1 gap-2">
                <div className="d-flex align-items-center border p-2 w-auto rounded-3 gap-2">
                  <StyledLabel>{item?.jobBoard?.name}</StyledLabel>
                </div>
              </div>
            )})}
          </>
        </PortletBody>
      </div>
    </>
  );
};

export default JobBoardView;
