const breakpoints = {
  xs: '400px',
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
};

const mediaQueries = {
  xs: `@media (max-width: ${breakpoints.xs})`,
  sm: `@media (max-width: ${breakpoints.sm})`,
  md: `@media (max-width: ${breakpoints.md})`,
  lg: `@media (max-width: ${breakpoints.lg})`,
  xl: `@media (max-width: ${breakpoints.xl})`,
  gtSm: `@media (min-width: ${parseInt(breakpoints.sm) + 1}px)`,
};

const theme = {
  ...mediaQueries,
  primary: '#4A154B',
  primaryDark: '#2F0D30',
  primaryLight: '#F8F2F7',
  primaryLight2: '#CCB2C8',
  lightYellowAutomation: '#FDF4ED',
  primaryLight3: '#001F5C1f',
  water: '#F0F4FA',
  fantasy: '#FDF4ED',
  primaryDisabled: '#EDEDED',
  headingColor: '#0A0509',
  body: '#000000',
  textColor: '#585558',
  lightGray: '#FCFCFC',
  iconGray: '#A4A2A4',
  lightHover: '#FAFAFA',
  inputDisabled: '#707070',
  lightBoldBlack: '#767476',
  purple: "#F6EFFF",
  skyblue: "#EFF4FA",
  thunder: "#292D32",
  inputBorder: '#EBEAEB',
  bodyBg: '#F5F5F5',
  white: '#fff',
  WhiteLinen: '#E7EFDC',
  lightbrown: "#F0E9D9",
  finch: '#5B6251',
  berylGreen: '#D8E6C6',
  inputPlaceHolder: '#9A9FA5',
  successColorSky: '#00796B',
  inputColor: '#787377',
  defaultImage: '#8C0020',
  danger: '#B80000',
  skygreen: '#E0F2F1',
  success: '#6EAA64',
  lightGreen: '#c1e8eb33',
  tuna: '#33383F',
  orange: '#DBA745',
  blue: '#001F5C',
  gold: '#996B00',
  occyLogo: '#EC008C',
  successLight: '#ECF5EA',
  tagLightBlue: '#EDF1FC',
  tagBlueColor: '#6D93C9',
  tagLightgray: '#A4ADA3',
  tagLightYellow: '#FCF1E9',
  statusTextColor: '#343840',
  statusTagOverDue: '#FFEFEF',
  statusTagCompleted: '#E0F2E0',
  statusTagSent: '#FCF7E5',
  chartBorderColor:'#E5E5E5',
  notesColorSent: '#BFAB61',
  notesColorOverDue: '#AF6060',
  notesColorCompleted: '#6F876F',
  lightGreenNew: '#E6F4F4',
  crystal: '#B2D3D6',
  noteTags: '#C2D3EB80',
  borderColor: '#767476',
  borderLight: '#E0E0E0',
  cornSilk: '#FFF9E5',
  bgPink: '#F9F5F6',
  darkGray: '#E6E5E6',
  eventColor1: '#F4DDE5',
  eventColor2: '#DADAF3',
  eventColor3: '#F4E5DE',
  eventColor4: '#ECEEEF',
  eventColor5: '#DCEAF5',
  interviewAttend: '#E3F1FF',
  blueIcon: '#204B8C',
  formSettingCardDesc: '#6F767E',
  hintBg: '#3B393C',
  green: '#00595C',
  lightYellow: '#FFF6DA',
  chartFilled: '#A5A5E9',
  chartVacant: '#F7CEB5',
  LavenderColor: '#E0F3F5',
  NCSColor: '#E0E9F5',
  LineChartBorder: "#775078",
  ChartDashes: "#ABADB5",
  eventGreen: '#00796B',
  pyramidInterviewed: '#8D6587',
  pyramidOffered: '#521753',
  triggerIconColor: '#775078',
  chartManual: '#DC89A6',
  doughGraphtooltipTitle: '#C9C9C9',
  tootipBg: '#1B1D21',
  doughplaceholderColor: '#d2dee2',
  lineGraphBorderColor: '#8F8FF0',
  lightBgPink: "#FDFDFD",
  funnelIndex1: '#b68fb0',
  navyBlue: '#262262',
  seaGreen: '#1B9AAA',
  darkBlue: '#5E4DB2',
  funnelIndex3: '#73486d',
  funnelIndex4: '#5e2f5e',
  graphPrimary: '#943D73',
  graphOrange: '#D97109',
  dotGreen:'#2CBFB1',
  dotYellow:'#FFD27D',
  teamLabelbg: 'rgba(194, 211, 235, 0.2)',
  scheduleSelfSlotInfoColor: 'rgba(255, 246, 218, 0.3)',
  greenBarylLightColor: 'rgba(216, 230, 198, 0.4)',
  orangeProcessingColor: 'rgba(219,167,69, 0.4)',
  dangerFailColor: 'rgba(255,0,0,0.4)',
  selfGrey: 'rgba(252, 241, 233, 0.2)',
  interviewtimebg: '#FAF7FA',
  interviewsuccess: '#26A69A',
  lightBrown: '#FCEDE3',
  darkBrown: '#C67E4F',
  stageIconColor: '#76747680',
  stagesDarkColor: '#313F62',
  DotColor: '#996b004d',
  darkRed: '#8F0000',
  creditBg1: '#dbf8ff',
  creditBg2: '#dedbff ',
  neutral: '#1A1D1F',
  InterviewFormikToolbarBg: '#FAFAFB',
  camelColor: '#FFAE1A',
  evaluationBannerColor: '#FFEBEE',
  previewColor: '#FFFAEB',
  previewColorBorder: '#FFE3AD',
  sourceColor: '#0080D0',
  screenColor: '#F9D28C',
  selectColor: '#C39CBD',
  offerColor: '#BBD49A',
  hireColor: '#34A023',
  StageHr: "#EFEFEF",
  seaPink: '#EF9A9A',
  cyan: '#85C8DD',
  cyanBlue: '#B3CBED',
  palePeach: '#FFE2AC',
  blueDark: 'rgba(0, 31, 92, 1)',
  bodyColor: '#535870',
  lightBlue: '#f6f8fc',
  darkYellow: '#664700',
  cerebellumGrey: "#CDCBCD",
  verticalBarLineColor:'#58558',
  skyBlueMatchColor: '#F0F4FA',
  DarkOrange: '#FF6A55',
  occyCredit1: '#DBF8FF',
  occyCredit2: '#DEDBFF',
  darkOrange: '#664700',
  pink: '#EC008C',
  paleGreen: '#26A69A',
  lightGrey:'#D9D9D9',
  darkishGreen:'EOF2F1'
};

export default theme;
