import { apiSlice } from '../../app/services/apiSlice';

const COMPANY_URL = 'backoffice/companies';

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllUsers: builder.query({
      query: (data) => ({
        url: `${COMPANY_URL}/${data.id}/accounts`,
        params: data.params,
      }),
    }),
    getUserRoles: builder.query({
      query: () => ({
        url: `${COMPANY_URL}/accountRoles`,
      }),
      transformResponse: (responseData) => {
        responseData?.roles?.length > 0 && responseData?.roles?.sort((a,b)=> (a?.title>b?.title)*2-1)
        responseData = responseData?.roles?.map((cat) => ({
          label: cat.title,
          value: cat.id
        }));
        return responseData || {};
      },
    }),
    addNewUser: builder.mutation({
      query: (data) => ({
        url: `${COMPANY_URL}/${data.compId}/accounts`,
        method: 'POST',
        body: { ...data.body }
      }),
    }),
    editNewUser: builder.mutation({
      query: (data) => ({
        url: `${COMPANY_URL}/${data.compId}/accounts/${data?.user?.id}`,
        method: 'PUT',
        body: { ...data.body }
      }),
    }),
    deleteUser: builder.mutation({
      query: (data) => ({
        url: `${COMPANY_URL}/${data?.companyId}/accounts/${data?.id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useGetAllUsersQuery,
  useGetUserRolesQuery,
  useAddNewUserMutation,
  useEditNewUserMutation,
  useDeleteUserMutation,
} = extendedApiSlice;
