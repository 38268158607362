import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import BasicInformation from './components/Basic Information/BasicInformation';
import BundleContainer from './components/Bundles/BundleContainer';
import ChannelContainer from './components/Channels/ChannelContainer';
import ProductDetailsContainer from './components/ProductDetails/ProductDetailsContainer';
import ProductEditDetails from './components/ProductDetails/ProductEditDetails/ProductEditDetails';
import ProductListingContainer from './components/ProductListingContainer';
import ProductListingOutlet from './components/ProductListingOutlet';


const ProductRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<ProductListingContainer />} />
      <Route path="/:productId" element={<ProductEditDetails />} />
      <Route element={<ProductListingOutlet />}>
        <Route path="/creation" element={<BasicInformation />} />
        <Route path="/:productId/creation" element={<BasicInformation />} />
        <Route path="/:productId/bundles" element={<BundleContainer />} />
        <Route path="/bundles" element={<BundleContainer />} />
        <Route path="/:productId/channels" element={<ChannelContainer />} />
        <Route path="/channels" element={<ChannelContainer />} />
      </Route>
      <Route path="/productDetails" element={<ProductDetailsContainer />} />

      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};

export default ProductRoutes;
