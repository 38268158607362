import React from 'react';

const KeyIcon = ({ size = 14, ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.544 2.46165C9.81738 0.740812 7.01738 0.740812 5.30238 2.46165C4.09488 3.65748 3.73322 5.37831 4.19988 6.89498L1.45822 9.63665C1.26572 9.83498 1.13155 10.2258 1.17238 10.5058L1.34738 11.7775C1.41155 12.1975 1.80238 12.5941 2.22238 12.6525L3.49405 12.8275C3.77405 12.8683 4.16488 12.74 4.36322 12.5358L4.84155 12.0575C4.95822 11.9466 4.95822 11.76 4.84155 11.6433L3.70988 10.5116C3.54072 10.3425 3.54072 10.0625 3.70988 9.89331C3.87905 9.72415 4.15905 9.72415 4.32822 9.89331L5.46571 11.0308C5.57655 11.1416 5.76321 11.1416 5.87405 11.0308L7.11071 9.79998C8.62155 10.2725 10.3424 9.90498 11.544 8.70915C13.2649 6.98831 13.2649 4.18248 11.544 2.46165ZM8.45821 6.99998C7.65321 6.99998 6.99988 6.34665 6.99988 5.54165C6.99988 4.73665 7.65321 4.08331 8.45821 4.08331C9.26322 4.08331 9.91655 4.73665 9.91655 5.54165C9.91655 6.34665 9.26322 6.99998 8.45821 6.99998Z"
        fill="currentColor"
        fillOpacity="0.7"
      />
    </svg>
  );
};

export default KeyIcon;
