import React from 'react';
import { Container, Row } from 'react-bootstrap';
import { useOutletContext } from 'react-router-dom';
import styled from 'styled-components';
import CustomDropdown from '../../../../app/components/dropdown/CustomDropDown';
import PortletBody from '../../../../app/components/portlet/PortletBody';
import StyledButton from '../../../../app/components/styledComponents/StyledButton';
import { StyledIconBg } from '../../../../app/components/styledComponents/StyledIconBg';
import DropDownIcon from '../../../../app/components/svgIcons/DropDownIcon';
import StagesIcons from '../../../../app/components/svgIcons/StagesIcons';
import { StyledLabel, Wrap } from '../../../../assets/styledComponents/Global';
import theme from '../../../../utils/themes';
import BundleTile from './BundleTile';
import ExistingBundleModal from './ExistingBundleModal';
import NewBundleModal from './NewBundleModal';
import { STATUSES } from '../../../../utils/Statuses';

const BundlesPortlet = ({
  bundles,
  // handleSubmit,
  addNewBundle,
  setAddNewBundle,
  addExistingbundle,
  setAddExistingBundle,
  resetForm,
  setBundleIndex,
  // deletebundle,
  bundleIndex
}) => {
  const { handleSubmit, recordsLoading, updateLoading } = useOutletContext();
  const handleBundle = (val) => {
    if (bundleIndex) {
      const bundlesResp = [...bundles];
      const bundleFilter = bundlesResp.filter((x) => { return x.id !== val.id })
      const filterBundle = [...bundleFilter];
      filterBundle.push(val);
      handleSubmit(filterBundle);
    } else {
      const updateVal = [...bundles];
      updateVal.push(val);
      handleSubmit(updateVal);
    }
  }
  const deletebundle = (id) => {
    const bundlesData = [...bundles];
    const bundleFilterDeleted = bundlesData?.map((x) => x?.id === id? ({...x,statusId:STATUSES.DELETE}):x)
    handleSubmit(bundleFilterDeleted);
  };

  return (
    <>
      <PortletBody className="mt-4 py-4" >
        <Wrap className="d-flex justify-content-between align-items-center px-3">
          <Wrap className="d-flex align-items-center gap-3 mb-4">
            <StyledIconBg>
              <StagesIcons size={16} />
            </StyledIconBg>
            <StyledLabel size="20px" weight="600">
              Bundles
            </StyledLabel>
          </Wrap>

          <div className="d-flex align-items-center justify-content-between border border-1 border-primary rounded-3">
            <StyledLabel
              weight="700"
              className='text-primary px-3 py-2 cursor-pointer'
              onClick={() => {
                setAddNewBundle(true);
                resetForm();
              }}
            >
              Add Bundle
            </StyledLabel>
            <StyledVerticalLine />
            <CustomDropdown
              dropdownToggle={<Wrap className='px-3 py-2'>
                <DropDownIcon size="12" />
              </Wrap>}
              menuClassName={'mt-2'}
              toggleClassName="p-0 mx-0 bg-transparent"
              dropdownMenu={
                <>
                  <StyledDropDownContainer className="rounded p-0">
                    <StyledButton
                      onClick={() => {
                        setAddNewBundle(true);
                        resetForm();
                      }}
                      variant="icon"
                      className="border-0 text-black w-100 justify-content-start"
                    >
                      Create new Bundle
                    </StyledButton>
                  </StyledDropDownContainer>

                  <StyledDropDownContainer className="rounded p-0 feature-pending">
                    <StyledButton
                      variant="icon"
                      className="border-0 text-black w-100 justify-content-start"
                      onClick={() => setAddExistingBundle(true)}
                    >
                      Choose from existing
                    </StyledButton>
                  </StyledDropDownContainer>
                </>
              }
            />
          </div>
        </Wrap>
        <Container fluid>
          <Row>
            {bundles?.map((bundle) => (
              <BundleTile key={'bundleTiles' + bundle.id} bundles={bundle} setAddNewBundle={setAddNewBundle} setBundleIndex={setBundleIndex} deletebundle={deletebundle} />
            ))}
          </Row>
        </Container>
      </PortletBody>
      <NewBundleModal
        show={addNewBundle}
        bundleIndex={bundleIndex}
        onHide={() => setAddNewBundle(false)}
        handleSubmit={handleBundle}
        setAddNewBundle={setAddNewBundle}
        loading={recordsLoading || updateLoading}
      />
      <ExistingBundleModal
        show={addExistingbundle}
        onHide={() => setAddExistingBundle(false)}
      />
    </>
  );
};

const StyledDropDownContainer = styled.div`
  width: 226px;
  padding: 15px 24px;

  &:hover {
    background-color: #fafafa;
  }
`;

const StyledVerticalLine = styled.span`
  border-right: 1px solid ${theme.primary};
  height: 10px;
`;

export default BundlesPortlet;
