import React from 'react';
import { ErrorMessage, FastField } from 'formik';
import Proptypes from 'prop-types';
import StyledInput from '../styledComponents/StyledInput';
import { formErrorMessage } from './FormErrorMessage';
import { StyledLabel } from '../../../assets/styledComponents/Global';

const FormikInputField = ({
  name,
  maxChar,
  options,
  label = '',
  isShowError = true,
  ...props
}) => (
  <FastField name={name}>
    {({ field, meta, form: { touched, errors, submitCount, ...rest } }) => (
      <>
        <div
          className={
            label
              ? `d-flex align-items-center justify-content-between border rounded pe-3 ${
                  meta.touched && meta.error && 'border-danger'
                } `
              : ' h-auto'
          }
        >
          <StyledInput
            {...field}
            className={label && 'border-0'}
            // isValid={submitCount && touched[field.name] && !errors[field.name]}
            isInvalid={
              submitCount && isShowError && !label && meta.touched && meta.error
            }
            id={props.id}
            {...props}
          />
          {label ? <StyledLabel weight="500px">{label}</StyledLabel> : null}
        </div>
        {props.type !== 'number' && maxChar && field && field.value && (
          <code className="text-muted float-right">
            {field.value.length}/{maxChar}
          </code>
        )}
        {submitCount > 0 && isShowError && (
          <ErrorMessage name={field.name} render={formErrorMessage} />
        )}
      </>
    )}
  </FastField>
);

FormikInputField.propTypes = {
  name: Proptypes.string.isRequired,
  placeholder: Proptypes.string,
  type: Proptypes.string,
  as: Proptypes.string,
  rows: Proptypes.string,
};
export default React.memo(FormikInputField);
