import React from 'react';

const SlashCircleCIon = ({ size = 19, ...props }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.66672 17C13.8089 17 17.1667 13.6421 17.1667 9.5C17.1667 5.35786 13.8089 2 9.66672 2C5.52458 2 2.16672 5.35786 2.16672 9.5C2.16672 13.6421 5.52458 17 9.66672 17Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.36423 4.19727L14.9692 14.8023"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SlashCircleCIon;
