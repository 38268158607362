//*Dropzone.js*//

import React from 'react';
import { useDropzone } from 'react-dropzone';
import ResumeIcon from '../svgIcons/ResumeIcon';
import styled from 'styled-components';
import theme from '../../../utils/themes';
import PdfIcon from '../svgIcons/PdfIcon';
import UploadIcon from '../svgIcons/UploadIcon';

function Dropzone({ onDrop, accept, label, content, maxFiles = 1, isInValid }) {
  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    accept,
    onDrop,
    maxFiles,
  });
  const files = acceptedFiles.map((file, i) => file?.path);
  return (
    <div {...getRootProps({ className: 'dropzone' })}>
      <input className="input-zone" {...getInputProps()} />
      <div className={`text-center ${isInValid ? 'border border-danger' : ''}`}>
        <StyledResumeContainer
          className={` ${files.length > 0 && 'justify-content-between'} mt-2`}
        >
          {files.length > 0 ? (
          <>
            <div className="my-3 mx-4 d-flex justify-content-between">
              <div className="d-flex align-items-center gap-2">
                <PdfIcon size="24" />
                <label className=" fw-bold text-muted">{files}</label>
              </div>
             
            </div>
             <div className="d-flex align-items-center justify-content-center me-4 text-primary fw-bold">
             <UploadIcon /> Upload new File
           </div>
           </>
          ) : (
            <div className="d-flex flex-column align-items-center mt-3">
              <>
                <ResumeIcon />
                <label className="mt-2">{label}</label>
                <label> or</label>
                {content}
              </>
            </div>
          )}
        </StyledResumeContainer>
      </div>
      {/* <aside className="mb-3">
        <ul>{files}</ul>
      </aside> */}
    </div>
  );
}

const StyledResumeContainer = styled.div`
  border: 2px dashed ${theme.inputDisabled};
  background-color: ${theme.bodyBg};
  display: flex;
  justify-content: center;
  gap: 8px;
  height: auto;
  border-radius: 16px;
  cursor: pointer;

  label {
    font-weight: 600;
  }
`;

export default Dropzone;
