import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';
import ChannelIcon from '../../../app/components/svgIcons/Channelcon';
import InformationIcon from '../../../app/components/svgIcons/InformationIcon';
import StagesIcons from '../../../app/components/svgIcons/StagesIcons';


const ProductListingSidebar = ({ tabHide, onTabChange }) => {
  const { productId } = useParams();
  const location = useLocation();
  const sidebar = [
    {
      title: 'Basic Information',
      number: 1,
      icon: <InformationIcon className={'me-2'} size={18} />,
      to: productId ? `${productId}/creation` : `creation`,
      classBar: ''
    },
    {
      title: 'Bundles',
      number: 2,
      icon: <StagesIcons className={'me-2'} size={18} />,
      to: productId ? `${productId}/bundles` : 'bundles',
      classBar: (tabHide === 'ADVERTISING' ) ? 'd-none' : '', //|| tabHide === 'AGENCY'
    },
    {
      title: 'Channels',
      number: 3,
      icon: <ChannelIcon className={'me-2'} size={18} />,
      to: productId ? `${productId}/channels` : `channels`,
      classBar: (tabHide === 'OTHER' ) ? 'd-none' : '',
    },

  ];
  const getActiveTab = () => {
    if (location.pathname.includes('creation')) {
      return 1;
    } else if (location.pathname.includes('bundles')) {
      return 2;
    } else if (location.pathname.includes('channels')) {
      return 3;
    } else return 0;
  };

  return (

    <StyledSideBar className={' bg-white mt-1'}>
      <div className="__sidebar p-2 p-lg-4">
        {sidebar.map(({ number, title, icon, classBar, linkDisabled, ...item }, i) => (
          <li key={i} className="rounded-1 overflow-hidden mb-1 cursor-pointer ">
            <span
              onClick={() => onTabChange(number, getActiveTab(), item.to)}
              className={`nav-link single-item flex-center ps-2 p-3 ${classBar}  ${getActiveTab() === number ? 'active' : ''
                } `
              }
              id={title.toLowerCase().replace(/\s/g, '-')}
              {...item}
            >
              {icon}
              <h6 className="text-nowrap mb-0 fw-bold">{title}</h6>
            </span>
          </li>
        ))}
      </div>
    </StyledSideBar>

  );
};
const StyledSideBar = styled.div`
  min-width: 220px;
  position: relative;

  .__sidebar {
    position: sticky;
    top: 12vh;
    height: 83vh;
  }

  li {
    list-style-type: none;
  }

  .single-item {
    //border-radius: 5px;
    &.not-allowed {
      cursor: not-allowed;
    }

    &.active {
      background-color: ${({ theme }) => theme.primaryLight};
      border-left: 3px solid ${({ theme }) => theme.primary};
    }
  }
`;
export default ProductListingSidebar;
