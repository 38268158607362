import styled from 'styled-components';
import { ToastContainer } from 'react-toastify';
import theme from '../../../utils/themes';
const StyledToastContainer = styled(ToastContainer)`
  min-width: 35%;

  .Toastify__toast-theme--colored {
    color: ${theme.textColor} !important;
    font-family: 'Quick Sand';
    font-weight: 600;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    padding: 5px 30px;
  }

  .Toastify__toast--success {
    background: #ecf5ea !important;
    border: 1px solid ${theme.success} !important;

    .Toastify__progress-bar {
      opacity: 0;
    }

    .Toastify__toast-icon {
      color: ${theme.success} !important;
    }
  }

  .Toastify__toast--error {
    background: #f1dbdbff !important;
    border: 1px solid ${theme.danger} !important;

    .Toastify__progress-bar {
      opacity: 0;
    }

    .Toastify__toast-icon {
      color: ${theme.danger} !important;
    }
  }

  .Toastify__toast--info {
    background: ${theme.primaryLight} !important;
    border: 1px solid ${theme.primaryLight} !important;

    .Toastify__progress-bar {
      background: ${theme.primaryLight2} !important;
    }
  }

  .Toastify__close-button {
    display: none !important;
  }
`;

export default StyledToastContainer;
