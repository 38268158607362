import { Form, Formik } from 'formik';
import React from 'react';
import { Modal, Row } from 'react-bootstrap';
import FormikAsyncSelect from '../../../../app/components/formikComponents/FormikAsyncSelect';
import PortletBody from '../../../../app/components/portlet/PortletBody';
import StyledButton from '../../../../app/components/styledComponents/StyledButton';

const ChangeCompanyAdminModal = ({ show, onHide,changeAdmin }) => {

  const handleSubmit = (values) => {
    changeAdmin(values);
  };

  return (
    <Modal show={show} centered onHide={onHide} size={'lg'}>
      <Formik
        initialValues={{
            accessKey:'',
            newValue:'',
        }}
        onSubmit={handleSubmit}
      >
        {({ handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <Modal.Header
              title="Change Admin"
              closeButton
              className="border-bottom-0 pb-0 align-items-baseline"
            >
              <Modal.Title
                as="h4"
                className="flex-grow-1 p-2 cursor-pointer flex-center"
              >
              Change Admin
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="d-flex  flex-column bg-body rounded">
              <PortletBody className="p-0 w-100">
                <PortletBody className={'p-4 w-100'}>
                  <Row>
                    <div className="col-12 col-md-12">
                      <div className="mb-3 ">
                     
                      </div>
                    </div>
                  
                    <div className="col-12 col-md-12">
                      <div className="mb-3">
                        <label htmlFor="admin" className="form-label">
                          Select Admin
                        </label>
                        <FormikAsyncSelect
                          name={`admin`}
                          id={`admin`}
                          placeholder="Users"
                        />
                      </div>
                    </div>
                 
                  </Row>
                </PortletBody>
              </PortletBody>
              <div className="d-flex justify-content-end mt-3">
                <div className="d-flex gap-3">
                  <StyledButton
                    variant={'text'}
                    className="mb-2 border-0 fw-bold"
                    onClick={onHide}
                  >
                    Cancel
                  </StyledButton>
                  <StyledButton className="mb-2" type="submit">
                    Save
                  </StyledButton>
                </div>
              </div>
            </Modal.Body>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default ChangeCompanyAdminModal;
