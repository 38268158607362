import React from 'react'

const TotalCandidate = ({ size = 40 }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M29.8832 12.9497C29.7666 12.933 29.6499 12.933 29.5332 12.9497C26.9499 12.8663 24.8999 10.7497 24.8999 8.14967C24.8999 5.49967 27.0499 3.33301 29.7166 3.33301C32.3666 3.33301 34.5332 5.48301 34.5332 8.14967C34.5166 10.7497 32.4666 12.8663 29.8832 12.9497Z" fill="#EC9B69" />
            <path d="M35.3166 24.4997C33.4499 25.7497 30.8333 26.2164 28.4166 25.8997C29.0499 24.533 29.3833 23.0164 29.3999 21.4164C29.3999 19.7497 29.0333 18.1664 28.3333 16.783C30.7999 16.4497 33.4166 16.9164 35.2999 18.1664C37.9333 19.8997 37.9333 22.7497 35.3166 24.4997Z" fill="#EC9B69" />
            <path d="M11.3999 12.9497C11.5166 12.933 11.6333 12.933 11.7499 12.9497C14.3333 12.8663 16.3833 10.7497 16.3833 8.14967C16.3833 5.48301 14.2333 3.33301 11.5666 3.33301C8.9166 3.33301 6.7666 5.48301 6.7666 8.14967C6.7666 10.7497 8.8166 12.8663 11.3999 12.9497Z" fill="#EC9B69" />
            <path d="M11.5832 21.4167C11.5832 23.0333 11.9332 24.5667 12.5666 25.95C10.2166 26.2 7.76658 25.7 5.96658 24.5167C3.33324 22.7667 3.33324 19.9167 5.96658 18.1667C7.74991 16.9667 10.2666 16.4833 12.6332 16.75C11.9499 18.15 11.5832 19.7333 11.5832 21.4167Z" fill="#EC9B69" />
            <path d="M20.8666 26.45C20.7333 26.4333 20.5833 26.4333 20.4333 26.45C17.3666 26.35 14.9166 23.8333 14.9166 20.7333C14.9333 17.5667 17.4833 15 20.6666 15C23.8333 15 26.4 17.5667 26.4 20.7333C26.3833 23.8333 23.95 26.35 20.8666 26.45Z" fill="#EC9B69" />
            <path d="M15.45 29.9004C12.9333 31.5837 12.9333 34.3504 15.45 36.0171C18.3167 37.9337 23.0167 37.9337 25.8833 36.0171C28.4 34.3337 28.4 31.5671 25.8833 29.9004C23.0333 27.9837 18.3333 27.9837 15.45 29.9004Z" fill="#EC9B69" />
        </svg>

    )
}

export default TotalCandidate