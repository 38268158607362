import React from 'react';
import theme from '../../../utils/themes';
import { StyledLabel, Wrap } from '../../../assets/styledComponents/Global';
import TickCircleIcon from '../../../app/components/svgIcons/TickCircleIcon';
import { RenderHTMLString } from '../../../utils/helper';
import StyledTag from '../../../app/components/styledComponents/StyledTag';
import moment from 'moment';
import { useEffect } from 'react';
import styled from 'styled-components';

const QuestionTile = ({ Information ,details, companyId,setupguide,total, setTotal,setupFetch,isFetching}) => {
  // const [showCurrenInfo, setShowCurrenInfo] = useState('');

  // const handleVisible = (item) => {
  //   if(item?.id === 5 || item?.id === 4){
  //   setShowCurrenInfo(showCurrenInfo === item?.id ? '' : item?.id);}
  // };

  const users =[{title:'Total Users',flag:'totalUsers',user:true},
  {title:'Admin',flag:'totalAdmins',user:true},
  {title:'Recruiter',flag:'totalRecruiter',user:true},
  {title:'Hiring Manager',flag:'totalHiringManager',user:true},
  {title:'Interviewer',flag:'totalInterviewer',user:true}
]


const setup =[{title:'Company Setup',flag:'company_setup',user:false},
{title:'Job Settings',flag:'job_settings',user:false},
{title:'Communications',flag:'communications',user:false},
{title:'Your Account',flag:'personal_account',user:false}
]
const checkDone = (obj) => {
  // Find objects with the specified category
const objectsWithCategory = setupguide?.allWizardDetails?.filter((wizard) => wizard?.category === obj?.flag );
// Check if any of the found objects have a companyGuidedOnBoarding with length > 0
const isAnyObjectWithCompanyGuidedOnBoarding = objectsWithCategory?.length > 0 && objectsWithCategory.every(steps => obj?.flag === 'personal_account' ?  steps?.accountGuidedOnBoarding?.length > 0 : steps?.companyGuidedOnBoarding?.length > 0);
return isAnyObjectWithCompanyGuidedOnBoarding
}
const getName = (flag) =>{
  return companyId[flag];
}

useEffect(()=>{
  let inc = 0;
  Information?.length > 0 &&  Information?.map((inf)=>{
    if(companyId[inf?.flag]?.length > 0){
      inc++;
    }
    return inf;
  })
setTotal(inc);
},[Information])
  return Information?.map((item, ind) => (
    <div className='bg-white rounded my-1'>
      <Wrap
        key={ind}
        className="d-flex justify-content-between  align-items-center cursor-pointer bg-white px-3 py-3 my-1 rounded"
        // onClick={() => handleVisible(item)}
      >
        <div className="cursor-pointer d-flex justify-content-center align-content-center">
        <div className='pe-2'>{getName(item?.flag)?.length ?  <TickCircleIcon color={theme.paleGreen} size={22}/> : RenderHTMLString(item?.icon, 22)}</div>
          <StyledLabel size="16px" weight={'600'} className=' text-black'>{item?.id === 4 ? 'Self-Guided Setup Started' : item?.id === 6 ? 'Self-Guided Setup Complete' : item?.title}</StyledLabel>
        </div>
        <StyledLabel family="Quicksand,sans-serif" size="12px" color="#767476"  weight="500">{getName(item?.flag)?.length ? moment(getName(item?.flag)).format("Do MMM YYYY") + `, ${ moment(getName(item?.flag))?.format('HH:mm')}` : 'Pending'}</StyledLabel>
      </Wrap>
      {item?.id === 4 || item?.id === 5 ? (
        <div className='px-3 mb-3'>
          <hr className='mt-1'/>
<div className='d-flex justify-content-between align-content-center px-4 bg-white' >
{(item?.id === 5 ? users : setup)?.map((item, ind) => (
          <div className='d-flex justify-content-center align-items-center flex-column' >
        
           <><StyledLabel key={ind}>{item?.title}</StyledLabel>
           {item?.user  && isFetching  && <div>...</div>}
           {!item?.user  && setupFetch && <div>...</div>}
           {item?.user && !isFetching  && <Styleddiv  className='mt-1'>{details?.users[0][item?.flag]}</Styleddiv>}
           {!item?.user && !setupFetch && <div  className='mt-1'>
            { checkDone(item) ? <StyledTag textColor={theme?.green} variant='onboard'>Completed</StyledTag> : <StyledTag textColor={theme?.darkOrange} variant='onboard-pending' pad="2px 6px 2px 6px">Pending</StyledTag>}</div>}
           </>
         </div>
          ))}
                       </div>
  
        </div>
      ) : null}
  
    </div>
  ));
};

const Styleddiv = styled.div`
font-size:14px;
font-weight:400;
font-family: 'Quick Sand';
color:black;
`;


export default QuestionTile;
