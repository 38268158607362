import { capitalize} from 'lodash';
import React, { useRef } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Portlet from '../../../app/components/portlet/Portlet';
import PortletHeader from '../../../app/components/portlet/PortletHeader';
import { StyledLabel } from '../../../assets/styledComponents/Global';
import { PRODUCT_TYPES, basicInformationValues } from '../assets/utills';
import {
  useAddNewProductMutation,
  useGetSingleProductByIdMutation,
  useUpdateProductInfoMutation,
} from '../productApiSlice';
import ProductListingSidebar from './ProductListingSidebar';

const ProductListingOutlet = () => {
  const navigate = useNavigate();
  const formRef = useRef();
  const { productId } = useParams();
  const [editValues, setEditValues] = useState();
  const [hideTab, setHideTab] = useState();
  const location = useLocation();
  const [crateNewProduct] = useAddNewProductMutation();
  const [getSinglePorduct,{isLoading:recordsLoading}] = useGetSingleProductByIdMutation();
  const [updateProductInfo, {isLoading:updateLoading}] = useUpdateProductInfoMutation();
  const [productResponse, setProductResponse] = useState();
  const [basicInformation, setBasicInformation] = useState();
  const [channelInformation, setChannelInformation] = useState();
  const [newProductResponse, setNewProductResponse] = useState();

  const filterResponse = () => {
    const jobboardProducts = editValues?.product?.jobboardProducts?.map(
      (item) => ({
        jobBoard: {
          label: item?.jobBoard?.name,
          value: item?.jobBoard?.id,
        },
        duration: item?.duration,
      }),
    );
    const filteredData = { ...editValues?.product, jobboardProducts };
    setHideTab(editValues?.product?.productType?.toUpperCase());
    setBasicInformation({ ...editValues?.product });
    setChannelInformation({ ...editValues?.product, jobboardProducts });
    setProductResponse(filteredData);
  };
  const selectedType = (e) => {
    setHideTab(e);
  };


  const getProductById=(productId)=>
  getSinglePorduct(productId).then(({ data: resp }) => {
    setEditValues(resp);
  });
  useEffect(() => {
    if (productId)getProductById(productId)
    
  }, [productId]);
  useEffect(() => filterResponse(), [editValues]);

  const initialData = productId ? productResponse : basicInformationValues;
  const handleSubmitDraft = (link) => {
    if (!productId) {
      formRef?.current?.handleSubmit();
    } else {
      if (link) {
        navigate(`${link}`);
        if (!productId && hideTab !== 'ADVERTISING') {
          formRef?.current?.handleSubmit();
        }
      } else navigate(`/products`);
    }
  };
  const handleSwitchTab = async (newTab, activeTab, link) => {
    if (newTab > activeTab && location.pathname.includes('/creation')) {
      return handleSubmitDraft(link);
    } else if (link) {
      navigate(`${link}`);
    } else navigate(`/products`);
  };
  const handleSubmit = (payload) => {
    const values = {...payload}
    if (location.pathname.includes('/creation')) {
      setBasicInformation(values);
      if (
        values.productType === 'ADVERTISING' ||
        values.productType === 'OTHER'
      ) {
        if (productId) {
          setChannelInformation(initialData);
        }
        if(values.productType!==PRODUCT_TYPES.ADVERTISING) values['postingType']=null;
        else if(!values?.postingType)  values['postingType']='manual'
        const api = productId ? updateProductInfo : crateNewProduct;
        api(values)
          .unwrap()
          .then((res) => {
            const resProductId = productId || res?.postProduct?.id
            getProductById(resProductId);
            setNewProductResponse(res?.postProduct);
            toast.success(
              productId
                ? 'Product updated Successfully'
                : 'Product created Successfully',
            );

            if (values.productType === 'ADVERTISING') {
              
              navigate(resProductId ? `${resProductId}/channels` : 'channels');
            } else {
              navigate(resProductId ? `${resProductId}/bundles` : 'bundles');
            }
          });
      } else {
        const api = productId ? updateProductInfo : crateNewProduct;
        api(values)
          .unwrap()
          .then((res) => {
            toast.success(
              productId
                ? 'Product updated Successfully'
                : 'Product created Successfully',
            );
            navigate(`/products`);
          });
      }
    } else if (location.pathname.includes('/bundles')) {
        const bundleDetailsArray = [];
        const bundleArray = !Array.isArray(payload) ? bundleDetailsArray : payload ;
        const merged = { ...basicInformation, bundleDetails: bundleArray };
        delete merged?.postingType;
        const api = updateProductInfo;
        api(merged)
          .unwrap()
          .then((res) => {
            toast.success(res?.data?.message);
            navigate(`/products`);
          });
    } else {
      let jobboardProducts=values.jobboardProducts || []
      jobboardProducts = jobboardProducts.map((item) => ({
        duration: item.duration,
        jobboardId: item?.jobBoard.value,
        statusId:item?.statusId
      }));
      const merged = productId
        ? { ...basicInformation, ...values, jobboardProducts }
        : { ...values, ...newProductResponse, jobboardProducts };
      const api = updateProductInfo;
      api(merged)
        .unwrap()
        .then((res) => {
          toast.success(
            productId
              ? 'Product updated Successfully'
              : 'Product created Successfully',
          );
          navigate(`/products`);
        });
    }
  };

  return (
    <Portlet>
      <Helmet>
        <title>{capitalize(initialData?.title) + ' - occy ADMIN'}</title>
      </Helmet>
      <PortletHeader
        title={
          <div className="d-flex align-items-center gap-2">
            <StyledLabel size="32px" weight="600">
              {productId ? 'Edit Product' : 'Add New Product'}
            </StyledLabel>
          </div>
        }
      />
      <div className="d-flex bg-body">
        <ProductListingSidebar
          tabHide={hideTab}
          onTabChange={handleSwitchTab}
        />
        <div className="portlet__body-bg w-100 pt-1">
          <Outlet
            context={{
              innerRef: formRef,
              handleSubmit: handleSubmit,
              selectedType: selectedType,
              basicInformation: basicInformation,
              channelInformation: channelInformation,
              initialData: initialData,
              recordsLoading:recordsLoading,
              updateLoading:updateLoading
              // product: { ...values },
            }}
          />
        </div>
      </div>
    </Portlet>
  );
};

export default ProductListingOutlet;
