import React from 'react';
import { Row } from 'react-bootstrap';
import PortletBody from '../../../app/components/portlet/PortletBody';
import { StyledSpan, Wrap } from '../../../assets/styledComponents/Global';
import styled from 'styled-components';
import FormikAsyncSelect from '../../../app/components/formikComponents/FormikAsyncSelect';
import CVLibraryIcon from '../../../app/components/svgIcons/CVLibraryIcon';
import { industries } from '../CampaignsUtils';


const CVLibraryEditForm = ({
  values
}) => {
  return (
    <>
      <PortletBody className="w-100">
        <div className="p-4">
          <StyledWrap className={`d-flex align-items-center justify-content-start gap-3`}>
            <CVLibraryIcon size={24} />
            <Wrap className='d-flex flex-column'>
              <StyledSpan size='16px' className='text fw-semibold'>CV Library</StyledSpan>
            </Wrap>
          </StyledWrap>
          <div className="p-2">
            <Row className="mt-2">
              <div className="col-12 col-md-6">
                <div className="mb-3">
                  <label htmlFor="additionalIndustry" className="form-label">
                    Additional Industry
                  </label>
                  <FormikAsyncSelect
                    name="additionalIndustry"
                    id="additionalIndustry"
                    type=""
                    searchAble={false}
                    options={industries}
                    placeholder="Select additionalIndustry"
                    isDisabled={values?.jobBoardSummaryKeys?.cvLibraryObject}
                  />
                </div>
              </div>

              {/* <div className="col-12 col-md-6">
                <div className="mb-3">
                  <label htmlFor="quickapply" className="form-label">
                    Quick Apply
                  </label>
                  <FormikAsyncSelect
                    name="quickapply"
                    id="quickapply"
                    type=""
                    searchAble={false}
                    options={quickapply}
                    placeholder="Select quickapply"
                    isDisabled={values?.jobBoardSummaryKeys?.cvLibraryObject}
                  />
                </div>
              </div> */}
            </Row>


          </div>
        </div>
      </PortletBody>
    </>
  );
};

const StyledWrap = styled.div`
margin : 1px 1px 0px 0px;
`;
export default CVLibraryEditForm;
