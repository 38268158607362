import { StyledLabel } from '../../../assets/styledComponents/Global';
const getAddressStringByLocation = (loc, fields = []) => {
    return fields
      .map((f) => loc[f])
      .filter((a) => a != null)
      .join(', ')
      .replace(/, *$/, '');
  };
export const getJobLocation = (data) => (
  <StyledLabel weight="500" size="13px" className="m-0 text-muted">
    <a
      href={`https://maps.google.com/maps?q=${data?.postcode}`}
      target="_blank"
      rel="noreferrer"
      className="no-underline "
    >
      {getAddressStringByLocation(data, [
        'postcode',
        'address',
        'county',
      ])}
    </a>
  </StyledLabel>
);
