import React from 'react';
import { Card, Col, Modal, Row } from 'react-bootstrap';
import PortletBody from '../../../app/components/portlet/PortletBody';
import styled from 'styled-components';

const BroadBeanResponseModal = ({ onHide, modalContent }) => {
  return (
    <>
      <Modal show={modalContent} centered onHide={onHide} size={'lg'}>
        <Modal.Header closeButton>
          <Modal.Title
            as="h2"
            className="flex-grow-1 p-2 cursor-pointer flex-center"
          >
            Broadbean Response
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex  flex-column bg-body rounded px-4">
          <PortletBody>
            <StyledContainer>
              <Card className="my-2">
                <Card.Header className="fw-bold">
                  Advert Contact Details
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col md={3}>
                      <strong>Name</strong>
                    </Col>
                    <Col md={9}>{modalContent?.Contact?.ContactName || ''}</Col>
                  </Row>

                  <Row>
                    <Col md={3}>
                      <strong>Email</strong>
                    </Col>
                    <Col md={9}>
                      {modalContent?.Contact?.ContactEmail || ''}
                    </Col>
                  </Row>

                  <Row>
                    <Col md={3}>
                      <strong>Telephone</strong>
                    </Col>
                    <Col md={9}>
                      {modalContent?.Contact?.ContactTelephone || ''}
                    </Col>
                  </Row>
                </Card.Body>
              </Card>

              <Card className="my-2">
                <Card.Header className="fw-bold">Channels</Card.Header>
                <Card.Body>
                  {modalContent?.ChannelList?.Channel?.map((ch) => (
                    <>
                      <Row>
                        <Col md={3}>
                          <strong>ID</strong>
                        </Col>
                        <Col md={9}>{ch?.ChannelId || ''}</Col>
                      </Row>

                      <Row>
                        <Col md={3}>
                          <strong>Name</strong>
                        </Col>
                        <Col md={9}>{ch?.ChannelName || ''}</Col>
                      </Row>

                      <Row>
                        <Col md={3}>
                          <strong>Status</strong>
                        </Col>
                        <Col md={9}>{ch?.ChannelStatus?._ || ''}</Col>
                      </Row>

                      <Row>
                        <Col md={3}>
                          <strong>Posted Time</strong>
                        </Col>
                        <Col md={9}>
                          {ch?.ChannelStatus?.$?.PostedTime || ''}
                        </Col>
                      </Row>

                      <Row>
                        <Col md={3}>
                          <strong>Removal Time</strong>
                        </Col>
                        <Col md={9}>
                          {ch?.ChannelStatus?.$?.RemovalTime || ''}
                        </Col>
                      </Row>

                      <Row>
                        <Col md={3}>
                          <strong>Responses</strong>
                        </Col>
                        <Col md={9}>
                          {ch?.ChannelStatus?.$?.Responses || ''}
                        </Col>
                      </Row>

                      <Row>
                        <Col md={3}>
                          <strong>Advert URL</strong>
                        </Col>
                        <Col md={9}>
                          {ch?.ChannelStatus?.$?.AdvertURL || ''}
                        </Col>
                      </Row>
                      <hr />
                    </>
                  ))}
                </Card.Body>
              </Card>

              <Card className="my-2">
                <Card.Header className="fw-bold">Advert</Card.Header>
                <Card.Body>
                  <Row>
                    <Col md={3}>
                      <strong>ID</strong>
                    </Col>
                    <Col md={9}>{modalContent?.Advert?.Id || ''}</Col>
                  </Row>

                  <Row>
                    <Col md={3}>
                      <strong>Create Time</strong>
                    </Col>
                    <Col md={9}>{modalContent?.Advert?.CreateTime || ''}</Col>
                  </Row>

                  <Row>
                    <Col md={3}>
                      <strong>User Name</strong>
                    </Col>
                    <Col md={9}>{modalContent?.Advert?.UserName || ''}</Col>
                  </Row>

                  <Row>
                    <Col md={3}>
                      <strong>Job Title</strong>
                    </Col>
                    <Col md={9}>{modalContent?.Advert?.JobTitle || ''}</Col>
                  </Row>

                  <Row>
                    <Col md={3}>
                      <strong>Job Reference</strong>
                    </Col>
                    <Col md={9}>{modalContent?.Advert?.JobReference || ''}</Col>
                  </Row>

                  <Row>
                    <Col md={3}>
                      <strong>Job Type</strong>
                    </Col>
                    <Col md={9}>{modalContent?.Advert?.JobType || ''}</Col>
                  </Row>

                  <Row>
                    <Col md={3}>
                      <strong>Industry</strong>
                    </Col>
                    <Col md={9}>{modalContent?.Advert?.Industry || ''}</Col>
                  </Row>

                  <Row>
                    <Col md={3}>
                      <strong>Salary</strong>
                    </Col>
                    <Col md={9}>
                      {modalContent?.Advert?.SalaryCurrency || ''}{' '}
                      {modalContent?.Advert?.SalarFrom || ''} -{' '}
                      {modalContent?.Advert?.SalaryTo || ''} per{' '}
                      {modalContent?.Advert?.SalaryPer || ''}
                    </Col>
                  </Row>
                  <Row>
                    <Col md={3}>
                      <strong>Salary Benefits</strong>
                    </Col>
                    <Col md={9}>
                      {modalContent?.Advert?.SalaryBenefits || ''}
                    </Col>
                  </Row>

                  <Row>
                    <Col md={3}>
                      <strong>Skills</strong>
                    </Col>
                    <Col md={9}>{modalContent?.Advert?.Skills || ''}</Col>
                  </Row>
                </Card.Body>
              </Card>
            </StyledContainer>
          </PortletBody>
        </Modal.Body>
      </Modal>
    </>
  );
};

const StyledContainer = styled.div`
  max-height: 60vh;
  overflow-y: auto;
  padding-inline: 5px;
`;
export default BroadBeanResponseModal;
