import { apiSlice } from "../../../app/services/apiSlice";

const COPYWRITE_URL = '/backoffice/copy-write';

export const extendedApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getAllCopywriteRequest: builder.query({
            query: (data) => ({
                url: `${COPYWRITE_URL}`,
                params: data,
            }),
        }),
        getCopywriteEditDetails: builder.query({
            query: (id) => ({
                url: `${COPYWRITE_URL}/${id}`,
            }),
        }),

        updateCopywriteRequest: builder.mutation({
            query: (data) => ({
                url: `${COPYWRITE_URL}`,
                method: 'POST',
                body: {
                    ...data
                },
            }),
        }),

        updateCopywriteRevisionRequest: builder.mutation({
            query: (data) => ({
                url: `${COPYWRITE_URL}`,
                method: 'POST',
                body: {
                    ...data
                },
            }),
        }),
    }),

});

export const {
    useGetAllCopywriteRequestQuery,
    useGetCopywriteEditDetailsQuery,
    useUpdateCopywriteRequestMutation,
    useUpdateCopywriteRevisionRequestMutation

} = extendedApiSlice;
