import React from 'react';
import PropTypes from 'prop-types';

const CheckIcon = ({ size = '20px' }) => (
  <svg
    enableBackground="new 0 0 515.556 515.556"
    height={size}
    viewBox="0 0 515.556 515.556"
    width={size}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="m0 274.226 176.549 176.886 339.007-338.672-48.67-47.997-290.337 290-128.553-128.552z" />
  </svg>
);

CheckIcon.propTypes = {
  size: PropTypes.string,
};

export default CheckIcon;
