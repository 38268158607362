import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import AppOutlet from './AppOutlet';

function ProtectedRoute() {
  const { user } = useAuth();
  if (user) {
    return (
      <AppOutlet>
        <Outlet />
      </AppOutlet>
    );
  }

  return <Navigate to="/login" />;
}

export default ProtectedRoute;
