import React from 'react';

const SelfGuideSatrtIcon = ({ size = 18, color = "#000000", ...props }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="24" height="24" rx="12" fill="#F5F5F5"/>
    <path d="M14.4442 6.16669H9.55582C7.43249 6.16669 6.16666 7.43252 6.16666 9.55585V14.4442C6.16666 16.5675 7.43249 17.8334 9.55582 17.8334H14.4442C16.5675 17.8334 17.8333 16.5675 17.8333 14.4442V9.55585C17.8333 7.43252 16.5675 6.16669 14.4442 6.16669ZM10.8158 13.6917L9.50332 15.0042C9.41582 15.0917 9.30499 15.1325 9.19416 15.1325C9.08332 15.1325 8.96666 15.0917 8.88499 15.0042L8.44749 14.5667C8.27249 14.3975 8.27249 14.1175 8.44749 13.9484C8.61666 13.7792 8.89082 13.7792 9.06582 13.9484L9.19416 14.0767L10.1975 13.0734C10.3667 12.9042 10.6408 12.9042 10.8158 13.0734C10.985 13.2425 10.985 13.5225 10.8158 13.6917ZM10.8158 9.60835L9.50332 10.9209C9.41582 11.0084 9.30499 11.0492 9.19416 11.0492C9.08332 11.0492 8.96666 11.0084 8.88499 10.9209L8.44749 10.4834C8.27249 10.3142 8.27249 10.0342 8.44749 9.86502C8.61666 9.69585 8.89082 9.69585 9.06582 9.86502L9.19416 9.99335L10.1975 8.99002C10.3667 8.82085 10.6408 8.82085 10.8158 8.99002C10.985 9.15919 10.985 9.43919 10.8158 9.60835ZM15.2433 14.695H12.1808C11.9417 14.695 11.7433 14.4967 11.7433 14.2575C11.7433 14.0184 11.9417 13.82 12.1808 13.82H15.2433C15.4883 13.82 15.6808 14.0184 15.6808 14.2575C15.6808 14.4967 15.4883 14.695 15.2433 14.695ZM15.2433 10.6117H12.1808C11.9417 10.6117 11.7433 10.4134 11.7433 10.1742C11.7433 9.93502 11.9417 9.73669 12.1808 9.73669H15.2433C15.4883 9.73669 15.6808 9.93502 15.6808 10.1742C15.6808 10.4134 15.4883 10.6117 15.2433 10.6117Z" fill="#767476"/>
    </svg>
    
    
    

  );
};

export default SelfGuideSatrtIcon;
