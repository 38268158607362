import { Formik } from 'formik';
import React from 'react';
import { Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import FormikInputField from '../../../../app/components/formikComponents/FormikInputField';
import FormikSelect from '../../../../app/components/formikComponents/FormikSelect';
import PortletBody from '../../../../app/components/portlet/PortletBody';
import StyledButton from '../../../../app/components/styledComponents/StyledButton';
import WarningIcon from '../../../../app/components/svgIcons/WarningIcon';
import { Wrap } from '../../../../assets/styledComponents/Global';
import theme from '../../../../utils/themes';
import { StyledLabel } from '../../../Campaigns/CampaignDetails';
import {
  AddMoreInitialState,
  addMoreValidationSchema,
  currencyType,
} from '../assets/companyCreationUtill';

const OccyCredits = ({ creditRefetch, compId, addCredit, isLoading, onHide }) => {
  const handleSubmit = (values) => {
    const occyCredit = {
      occyCredits: values?.occyCredits,
      totalCost: values?.totalCost,
      currency: values?.currency,
      invoiceId: values?.invoiceId,
      comment: values?.comment,
      companyId: compId,
      type: 'OCCY_CREDITS',
    };

    addCredit(occyCredit).then(
      (resp) => {toast.success(resp?.data?.message); onHide(); creditRefetch()},
    );
  };
  return (
    <Formik
      initialValues={AddMoreInitialState}
      validationSchema={addMoreValidationSchema}
      //   validate={validate}
      onSubmit={handleSubmit}
    >
      {({ handleSubmit, setFieldValue, errors, touched }) => (
        <Form onSubmit={handleSubmit}>
          <PortletBody>
            <StyledLabel>Occy Credits</StyledLabel>
            <Wrap className="mt-3">
              <StyledLabel className="mb-1">Quantity</StyledLabel>
              <FormikInputField
                id="occyCredits"
                name="occyCredits"
                type="number"
                label="Credits"
                placeholder="Enter quantity"
              />
            </Wrap>
            <Wrap className="mt-3">
              <StyledLabel className="mb-1">Total cost</StyledLabel>

              <div
                className={`d-flex border rounded ${errors?.totalCost && touched?.totalCost && ' border-danger'
                  }`}
              >
                <Wrap className="col">
                  <FormikInputField
                    className="border-0"
                    name="totalCost"
                    type="number"
                    id="totalCost"
                    placeholder="Enter cost"
                    isShowError={false}
                  />
                </Wrap>
                <Wrap className="border-end my-2" />
                <Wrap >
                  <StyledCustomSelect
                    name="currency"
                    id="currency"
                    options={currencyType}
                  />
                </Wrap>
              </div>
              {errors?.totalCost && touched?.totalCost && (
                <div className="text-danger d-flex align-items-center">
                  <WarningIcon />
                  {errors?.totalCost}
                </div>
              )}
            </Wrap>
            <Wrap className="mt-3">
              <StyledLabel className="mb-1">
                Invoice / Transaction ID
              </StyledLabel>
              <FormikInputField
                id="invoiceId"
                name="invoiceId"
                placeholder="e.g OC5637J7829"
              />
            </Wrap>
            <Wrap className="mt-3">
              <Wrap className='d-flex gap-1'>
                <StyledLabel className="mb-1">
                  Add a comment
                </StyledLabel>
                <StyledLabel className="mb-1" color={theme.inputColor}>
                  (optional - visible to client)
                </StyledLabel>
              </Wrap>
              <FormikInputField
                id="comment"
                name="comment"
                placeholder="Add a short comment for future reference"
              />
            </Wrap>
          </PortletBody>
          <div className="d-flex justify-content-end mt-3">
            <StyledButton
              className="mb-2 border-0 fw-bold"
              onClick={onHide}
              variant="text"
            >
              Cancel
            </StyledButton>
            <StyledButton
              className="mb-2 border-0 fw-bold"
              type="submit"
              disabled={isLoading}
              loading={isLoading}
            >
              Add
            </StyledButton>
          </div>
        </Form>
      )}
    </Formik>
  );
};

const StyledCustomSelect = styled(FormikSelect)`
  [class$='-control'] {
    border: none;
  }
`;


export default OccyCredits;
