import React from 'react';

const PriceIcon = ({ size = 12, ...props }) => (
<svg width={size} height={size} viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.333 0.333496H3.66634C1.66634 0.333496 0.333008 1.3335 0.333008 3.66683V8.3335C0.333008 10.6668 1.66634 11.6668 3.66634 11.6668H10.333C12.333 11.6668 13.6663 10.6668 13.6663 8.3335V3.66683C13.6663 1.3335 12.333 0.333496 10.333 0.333496ZM3.16634 7.66683C3.16634 7.94016 2.93967 8.16683 2.66634 8.16683C2.39301 8.16683 2.16634 7.94016 2.16634 7.66683V4.3335C2.16634 4.06016 2.39301 3.8335 2.66634 3.8335C2.93967 3.8335 3.16634 4.06016 3.16634 4.3335V7.66683ZM6.99967 8.00016C5.89301 8.00016 4.99967 7.10683 4.99967 6.00016C4.99967 4.8935 5.89301 4.00016 6.99967 4.00016C8.10634 4.00016 8.99967 4.8935 8.99967 6.00016C8.99967 7.10683 8.10634 8.00016 6.99967 8.00016ZM11.833 7.66683C11.833 7.94016 11.6063 8.16683 11.333 8.16683C11.0597 8.16683 10.833 7.94016 10.833 7.66683V4.3335C10.833 4.06016 11.0597 3.8335 11.333 3.8335C11.6063 3.8335 11.833 4.06016 11.833 4.3335V7.66683Z" fill="currentColor"/>
</svg>

);

export default PriceIcon;
