import React from 'react';

const TickCircleIcon = ({ size = 18, color = "#000000", ...props }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M8.00651 1.33313C4.33318 1.33313 1.33984 4.32646 1.33984 7.9998C1.33984 11.6731 4.33318 14.6665 8.00651 14.6665C11.6798 14.6665 14.6732 11.6731 14.6732 7.9998C14.6732 4.32646 11.6798 1.33313 8.00651 1.33313ZM11.1932 6.46646L7.41318 10.2465C7.31984 10.3398 7.19318 10.3931 7.05984 10.3931C6.92651 10.3931 6.79984 10.3398 6.70651 10.2465L4.81984 8.3598C4.62651 8.16646 4.62651 7.84646 4.81984 7.65313C5.01318 7.4598 5.33318 7.4598 5.52651 7.65313L7.05984 9.18646L10.4865 5.7598C10.6798 5.56646 10.9998 5.56646 11.1932 5.7598C11.3865 5.95313 11.3865 6.26646 11.1932 6.46646Z" fill={color} />
    </svg>

  );
};

export default TickCircleIcon;
