import React from 'react';

const MarkIcon = ({ size = 19, ...props }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.58594 8.01006L7.1926 9.6234L10.4126 6.39673" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M7.16594 1.64339C7.62594 1.25006 8.37927 1.25006 8.84594 1.64339L9.89927 2.55006C10.0993 2.72339 10.4726 2.86339 10.7393 2.86339H11.8726C12.5793 2.86339 13.1593 3.44339 13.1593 4.15006V5.28339C13.1593 5.54339 13.2993 5.92339 13.4726 6.12339L14.3793 7.17672C14.7726 7.63672 14.7726 8.39006 14.3793 8.85672L13.4726 9.91006C13.2993 10.1101 13.1593 10.4834 13.1593 10.7501V11.8834C13.1593 12.5901 12.5793 13.1701 11.8726 13.1701H10.7393C10.4793 13.1701 10.0993 13.3101 9.89927 13.4834L8.84594 14.3901C8.38594 14.7834 7.6326 14.7834 7.16594 14.3901L6.1126 13.4834C5.9126 13.3101 5.53927 13.1701 5.2726 13.1701H4.11927C3.4126 13.1701 2.8326 12.5901 2.8326 11.8834V10.7434C2.8326 10.4834 2.6926 10.1101 2.52594 9.91006L1.62594 8.85006C1.23927 8.39006 1.23927 7.64339 1.62594 7.18339L2.52594 6.12339C2.6926 5.92339 2.8326 5.55006 2.8326 5.29006V4.14339C2.8326 3.43672 3.4126 2.85672 4.11927 2.85672H5.2726C5.5326 2.85672 5.9126 2.71672 6.1126 2.54339L7.16594 1.64339Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
    
    
  );
};

export default MarkIcon;
