import React from 'react';
import Select from 'react-select';
import themes from '../../../utils/themes';
import './_select.scss';

const StyledSelect = ({
  isMulti,
  options = [],
  value = '',
  onChange,
  disabled = false,
  className = '',
  ...props
}) => {
  let newValue = null;
  if (value) {
    newValue = {
      value,
      label: options.find((o) => o.value === value)?.label || value,
    };
  }
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? themes.primaryLight
        : provided.backgroundColor,
      borderRadius: props.borderRadius ? 10 : 8,
      padding: '10px 15px',
      color: themes.textColor,
      cursor: 'pointer',
    }),
  };
  return (
    <Select
      isMulti={isMulti}
      isDisabled={disabled}
      options={options.map((option) => option)}
      styles={customStyles}
      theme={(theme) => ({
        ...theme,
        borderRadius: props.borderRadius || 10,

        colors: {
          ...theme.colors,
          primary25: themes.lightHover,
          primary50: themes.primaryLight,
        },
      })}
      value={newValue}
      onChange={onChange}
      className={`async-select ${className} ${props.isValid ? 'is-valid' : ''} ${
        props.isLightPink ? 'is-light-pink' : ''
      }${props.pay ? ' is-light-pink pay' : ''}
      ${props.application ? 'application' : ''}
       ${props.isInvalid ? 'is-invalid' : ''} ${disabled ? 'disabled' : ''}`}
      {...props}
    />
  );
};

export default StyledSelect;
