import React from 'react';

const DesignIcon = ({ size = 18, color = "#000000", ...props }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.18317 2.42082C6.18317 2.53166 6.08984 2.62499 5.979 2.62499H5.31984C4.05984 2.62499 3.03317 3.65166 3.03317 4.91166V10.2958C3.03317 10.4067 2.93984 10.5 2.829 10.5H2.42067C1.7265 10.5 1.1665 9.93999 1.1665 9.24582V2.42082C1.1665 1.72666 1.7265 1.16666 2.42067 1.16666H4.929C5.62317 1.16666 6.18317 1.72666 6.18317 2.42082Z" fill="#767476"/>
<path d="M12.8336 2.42082V9.24582C12.8336 9.93999 12.2736 10.5 11.5794 10.5H11.2119C11.1011 10.5 11.0077 10.4067 11.0077 10.2958V4.91166C11.0077 3.65166 9.98106 2.62499 8.72106 2.62499H8.02106C7.91023 2.62499 7.81689 2.53166 7.81689 2.42082C7.81689 1.72666 8.37689 1.16666 9.07106 1.16666H11.5794C12.2736 1.16666 12.8336 1.72666 12.8336 2.42082Z" fill="#767476"/>
<path d="M8.7207 3.5H5.31987C4.5382 3.5 3.9082 4.13 3.9082 4.91167V11.4217C3.9082 12.2033 4.5382 12.8333 5.31987 12.8333H6.2707C6.43404 12.8333 6.56237 12.705 6.56237 12.5417V11.0833C6.56237 10.8442 6.7607 10.6458 6.99987 10.6458C7.23904 10.6458 7.43737 10.8442 7.43737 11.0833V12.5417C7.43737 12.705 7.5657 12.8333 7.72904 12.8333H8.72654C9.50237 12.8333 10.1324 12.2033 10.1324 11.4275V4.91167C10.1324 4.13 9.50237 3.5 8.7207 3.5ZM8.16654 8.60417H5.8332C5.59404 8.60417 5.3957 8.40583 5.3957 8.16667C5.3957 7.9275 5.59404 7.72917 5.8332 7.72917H8.16654C8.4057 7.72917 8.60404 7.9275 8.60404 8.16667C8.60404 8.40583 8.4057 8.60417 8.16654 8.60417ZM8.16654 6.85417H5.8332C5.59404 6.85417 5.3957 6.65583 5.3957 6.41667C5.3957 6.1775 5.59404 5.97917 5.8332 5.97917H8.16654C8.4057 5.97917 8.60404 6.1775 8.60404 6.41667C8.60404 6.65583 8.4057 6.85417 8.16654 6.85417Z" fill="#767476"/>
</svg>


  );
};

export default DesignIcon;
