import React from 'react';
import FormikAsyncSelectMulti from '../../../../app/components/formikComponents/FormikAsyncSelectMulti';
import FormikSelect from '../../../../app/components/formikComponents/FormikSelect';
import {
  // InterviewStatus,
  candidateStageGroups,
  ratings,
} from '../../assets/analyticsUtil';
import FormikInputField from '../../../../app/components/formikComponents/FormikInputField';
import { budgetedValues } from '../../components/HiringUtils';

const ApplicationsFilterForm = ({ submitForm, loading, onhandleChange,values }) => {
  
  let companyIdPayload = {
    companyIds: values?.companyIds?.map((item) => item?.value),
  };

  return (
    <>
      <div className="col-12">
        <div className="mb-4">
          <label htmlFor="rating" className="form-label">
            Rating
          </label>

          <FormikSelect
            name="rating"
            id="rating"
            placeholder="Select Rating"
            options={ratings}
            onChange={(values) =>
              onhandleChange(
                'rating',
                typeof values?.value === 'undefined'
                  ? values?.label
                  : values.value,
              )
            }
          />
        </div>
      </div>

      <div className="col-12">
        <div className="mb-4">
          <label htmlFor="candPostcode" className="form-label">
            Candidate Postcode
          </label>
          <FormikInputField
            name="candPostcode"
            id="candPostcode"
            placeholder="Enter Candidate Post Code"
            onChange={(e) => {
              onhandleChange('candPostcode', e?.target?.value);
            }}
          />
        </div>
      </div>

      <div className="col-12">
        <div className="mb-4">
          <label htmlFor="candTown" className="form-label">
            Candidate Town
          </label>
          <FormikInputField
            name="candTown"
            id="candTown"
            placeholder="Enter Candidate Town"
            onChange={(e) => {
              onhandleChange('candTown', e?.target?.value);
            }}
          />
        </div>
      </div>
      <div className="col-12">
        <div className="mb-4">
          <label htmlFor="interviewScheduled" className="form-label">
            Interview Scheduled
          </label>
          <FormikSelect
            name="interviewScheduled"
            id="interviewScheduled"
            placeholder="Select Interview Scheduled"
            options={budgetedValues}
            onChange={(values) =>
              onhandleChange(
                'interviewScheduled',
                typeof values?.value === 'undefined'
                  ? values?.label
                  : values.value,
              )
            }
          />
        </div>
      </div>
      {/* backend wait for Interview Status */}
      {/* <div className="col-12">
        <div className="mb-4">
          <label htmlFor="interviewStatus" className="form-label">
            Interview Status
          </label>
          <FormikSelect
            name="interviewStatus"
            menuPlacement={'top'}
            placeholder="Select Interview Status"
            options={InterviewStatus}
          />
        </div>
      </div> */}

      <div className="col-12">
        <div className="mb-4">
          <label htmlFor="candStageGroup" className="form-label">
            Candidate Stage
          </label>
          <FormikSelect
            name="candStageGroup"
            id="candStageGroup"
            menuPlacement={'top'}
            placeholder="Select"
            options={candidateStageGroups}
            onChange={(values) =>
              onhandleChange(
                'candStageGroup',
                typeof values?.value === 'undefined'
                  ? values?.label
                  : values.value,
              )
            }
          />
        </div>
      </div>

      <div className="col-12">
        <div className="mb-4">
          <label htmlFor="rejected" className="form-label">
            Rejected
          </label>
          <FormikSelect
            name="rejected"
            id="rejected"
            placeholder="Select"
            options={budgetedValues}
            menuPlacement={'top'}
            onChange={(values) =>
              onhandleChange(
                'rejected',
                typeof values?.value === 'undefined'
                  ? values?.label
                  : values.value,
              )
            }
          />
        </div>
      </div>

      <div className="col-12">
        <div className="mb-4">
          <label htmlFor="rejectionReason" className="form-label">
            Reason for Rejection
          </label>
          <FormikAsyncSelectMulti
            id="rejectionReason"
            name="rejectionReason"
            // apiCall={()=>getJobRejectionReason(companyIdPayload)}
            params={{...companyIdPayload}}
            type="rejectionReasons"
            menuPlacement={'top'}
            placeholder="Select reason"
            onSelect={(values) => onhandleChange('rejectionReason', values)}
          />
        </div>
      </div>
    </>
  );
};

export default ApplicationsFilterForm;
