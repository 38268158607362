import { FieldArray } from 'formik';
import React, { useEffect } from 'react';
import { Container, Row } from 'react-bootstrap';
import FormikSelect from '../../../../app/components/formikComponents/FormikSelect';
import PortletBody from '../../../../app/components/portlet/PortletBody';
import StyledButton from '../../../../app/components/styledComponents/StyledButton';
import { StyledIconBg } from '../../../../app/components/styledComponents/StyledIconBg';
import ArrowIcon from '../../../../app/components/svgIcons/ArrowIcon';
import Channelcon from '../../../../app/components/svgIcons/Channelcon';
import { StyledLabel, Wrap } from '../../../../assets/styledComponents/Global';
import { duration } from '../../assets/utills';
import { useGetJobBoardsMutation } from '../../productApiSlice';
import FormikAsyncSelect from '../../../../app/components/formikComponents/FormikAsyncSelect';
import { useNavigate, useParams } from 'react-router-dom';
import TrashIcon from '../../../../app/components/svgIcons/TrashIcon';
import AddIcon from '../../../../app/components/svgIcons/AddIcon';
import { STATUSES } from '../../../../utils/Statuses';

const ChannelForm = ({ values,recordsLoading, updateLoading }) => {
  const [getJobBoards] = useGetJobBoardsMutation();
  const navigate = useNavigate();
  const { productId } = useParams();
  useEffect(() => {
    delete values?.title;
    delete values.creditPrice;
    delete values.quantity;
    delete values.visibility;
    delete values.productType;
    delete values.description;
  }, [values]);

  return (
    <>
      <PortletBody className="mt-4 ms-2">
        <Container fluid className="my-2">
          <Row>
            <div className="d-flex align-items-center gap-3 mb-4">
              <StyledIconBg>
                <Channelcon size={16} />
              </StyledIconBg>
              <StyledLabel size="20px" weight="600">
                Channels
              </StyledLabel>
            </div>
          </Row>
        </Container>
        <div className=" border-1 mx-3" />

        <div className="p-2 mt-2 mx-1">
          <FieldArray
            name="jobboardProducts"
            render={(arrayHelpers) => (
              <div>
                {Array.isArray(values?.jobboardProducts)
                  ? values?.jobboardProducts?.map((item, index) => item?.statusId!==STATUSES.DELETE && (
                      <div className="row mb-3" key={'jobboardProducts' + index}>
                        <div className="col-5">
                            <label
                              htmlFor={`jobboardProducts.${index}.jobBoard`}
                              className="form-label"
                            >
                              Job Board
                            </label>
                            <FormikAsyncSelect
                              apiCall={getJobBoards}
                              name={`jobboardProducts.${index}.jobBoard`}
                              id={`jobboardProducts.${index}.jobBoard`}
                              type="jobBoards"
                              placeholder="Select Board"
                            />
                        </div>

                        <div className="col-5">
                            <label
                              htmlFor={`jobboardProducts.${index}.duration`}
                              className="form-label"
                            >
                              Expiry
                            </label>
                            <FormikSelect
                              name={`jobboardProducts.${index}.duration`}
                              placeholder="Duration in days on job board"
                              options={duration}
                            />
                        </div>
                        <div className="col-2 mt-4 pt-1">
                        
                        <StyledButton
                          // className="mb-3"
                          variant="iconBackground"
                          height={40}
                          width={40}
                          icon={<TrashIcon />}
                          onClick={() => {
                            const newItem = {
                              ...item,
                              statusId: STATUSES.DELETE,
                          };
                          arrayHelpers.replace(index, newItem);
                          }}
                        />
                   
                          </div>
                      </div>
                    ))
                  : null}
                {values?.jobboardProducts?.length?
                
                <h6
                  className="text-primary fw-bold"
                  role="button"
                  onClick={() =>
                    arrayHelpers.insert(values?.jobboardProducts?.length + 1, {
                      jobBoard: '',
                      duration: '',
                    })
                  }
                >
                  + Add another
                </h6>:
                  <StyledButton
                  className="w-100"
                  // variant="iconBackground"
                  icon={<AddIcon/>}
                  onClick={() =>
                    arrayHelpers.insert(
                      values?.jobboardProducts?.length + 1,
                      {
                        jobBoard: '',
                        duration: '',
                      },
                    )
                  }
                >Add Job Board</StyledButton>
                }

              </div>
            )}
          />
        </div>
      </PortletBody>
      <Wrap className="d-flex justify-content-between px-1">
        <StyledButton
          className=" mt-4"
          variant={'white'}
          icon={<ArrowIcon rotate="left" />}
          disabled={recordsLoading || updateLoading}
          onClick={() =>
            navigate(
              !productId
                ? '/products/creation'
                : `/products/${productId}/creation`,
            )
          }
        >
          Back
        </StyledButton>
        <StyledButton className="mt-4" type="submit" disabled={recordsLoading || updateLoading} loading={recordsLoading || updateLoading}>
          Save
        </StyledButton>
      </Wrap>
    </>
  );
};

export default ChannelForm;
