import { orderBy } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import FormikAsyncSelect from '../../app/components/formikComponents/FormikAsyncSelect';
import FormikDatePicker from '../../app/components/formikComponents/FormikDatePicker';
import FormikSelect from '../../app/components/formikComponents/FormikSelect';
import StyledButton from '../../app/components/styledComponents/StyledButton';
import { useGetAllCompaniesListMutation } from '../company/companyApiSlice';
import { campaignStatus } from './CampaignsUtils';
import { useGetCompanyJobsMutation } from './CampainApiSlice';


const CampaignFilterForm = ({ submitForm, loading, values, compId, globalCampaign }) => {
    const [getCompanyJobs] = useGetCompanyJobsMutation()
    const [getAllCompaniesList] = useGetAllCompaniesListMutation()

    return (
        <>
            {
                globalCampaign &&
                <div className="col-12">
                    <div className="mb-4">
                        <label htmlFor="companyId" className="form-label">
                            Company
                        </label>
                        <FormikAsyncSelect
                            name="companyId"
                            id="companyId"
                            apiCall={getAllCompaniesList}
                            placeholder="Choose Company"getCompanyJobs
                        />
                    </div>
                </div>
            }
            {
                (!globalCampaign || values.companyId) &&
                <div className="col-12">
                    <div className="mb-4">
                        <label htmlFor="jobId" className="form-label">
                            Job
                        </label>
                        <FormikAsyncSelect
                            name="jobId"
                            id="jobId"
                            apiCall={getCompanyJobs}
                            compId={compId ?? values.companyId.value}
                            placeholder="Choose Job"
                        />
                    </div>
                </div>
            }

            <div className="col-12">
                <div className="mb-4">
                    <label htmlFor="startDate" className="form-label">
                        Start Date
                    </label>

                    <FormikDatePicker name="startDate" />
                </div>
            </div>

            <div className="col-12">
                <div className="mb-4">
                    <label htmlFor="endDate" className="form-label">
                        End Date
                    </label>

                    <FormikDatePicker name="endDate" />
                </div>
            </div>
            <div className="col-12">
                <div className="mb-4">
                    <label htmlFor="statusId" className="form-label">
                        Status
                    </label>
                    <FormikSelect
                        name="statusId"
                        id="statusId"
                        options={orderBy(campaignStatus, ['label'],['asc'])}
                        placeholder="Choose Status"
                    />
                </div>
            </div>
            <StyledBtn>
                <StyledButton
                    type="button"
                    onClick={submitForm}
                    className="ms-auto w-100"
                    loading={loading}
                    disabled={loading}
                >
                    Apply Filters
                </StyledButton>
            </StyledBtn>
        </>
    );
};

const StyledBtn = styled.div`
margin-bottom: 4rem !important;
`

export default CampaignFilterForm;
