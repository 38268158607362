import React from 'react';
import { ErrorMessage, FastField } from 'formik';
import { formErrorMessage } from './FormErrorMessage';
import StyledButton from '../styledComponents/StyledButton';
import Dropzone from '../dropzone/Dropzone';

const FormikDropZone = ({ name, maxChar, onDrop, setFieldName, ...props }) => {
  return (
    <FastField name={name}>
      {({
        field,
        form: { touched, setFieldValue, submitCount, errors, ...props },
      }) => (
        <>
          <Dropzone
            {...props}
            name={name}
            isInvalid={submitCount && touched[field.name] && errors[field.name]}
            onDrop={onDrop}
            label={'Drag and Drop a Resume here'}
            content={
              <StyledButton variant="text">Browse Your Computer</StyledButton>
            }
          />
          <ErrorMessage name={field.name} render={formErrorMessage} />
        </>
      )}
    </FastField>
  );
};

export default React.memo(FormikDropZone);




