import React from 'react';
import { ErrorMessage, FastField } from 'formik';
import Proptypes from 'prop-types';
import StyledTextEditor from '../textEditor/StyledTextEditor';
import { formErrorMessage } from './FormErrorMessage';

const FormikTextEditor = ({ name, totalCount, ...props }) => (
  <FastField name={name}>
    {({ field, form: { setFieldValue, touched, errors } }) => (
      <>
        <StyledTextEditor
          {...field}
          onChange={(values) => {
            if (values === '<p><br></p>') {
              values = '';
            }
            setFieldValue(name, values);
          }}
          name={name}
          totalCount={totalCount}
          onPaste={(values) => {
            // if (values === '<p><br></p>') {
            //   values = '';
            // }
            // setFieldValue(name, values);
          }}
          isValid={touched[field.name] && !errors[field.name]}
          isInvalid={touched[field.name] && errors[field.name]}
          {...props}
        />
        <ErrorMessage name={field.name} render={formErrorMessage} />
      </>
    )}
  </FastField>
);

FormikTextEditor.propTypes = {
  name: Proptypes.string.isRequired,
  placeholder: Proptypes.string,
  type: Proptypes.string,
  as: Proptypes.string,
  rows: Proptypes.string,
};
export default React.memo(FormikTextEditor);
