import styled from 'styled-components';

const StyledColoredTag = styled.div`
  border-radius: ${(props) => props.$radius ?? '5px'};
  padding: ${(props) => props.$padding ?? '4px 7px'};
  font-weight: 600;
  font-size: ${(props) => props.$fontSize ?? '12px'};
  margin: 0 ${(props) => props.$marginX ?? '10px'};
  display: flex;
  align-items: center;
  width: ${(props) => props.$width ?? 'auto'};
  height: ${(props) => props.$height ?? 'auto'};
  ${(props) => {
    return `
      color: ${props.color || props.theme[props.variant] || props.theme.primary};
      background-color: ${props.backgroundColor || props.theme[props.variant] + '1f' || props.theme.primary + '1f'};
    `;
  }}
`;

export default StyledColoredTag;
