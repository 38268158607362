import { FieldArray, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Portlet from '../../../app/components/portlet/Portlet';
import PortletBody from '../../../app/components/portlet/PortletBody';
import PortletHeader from '../../../app/components/portlet/PortletHeader';
import * as Yup from 'yup';
import FormikInputField from '../../../app/components/formikComponents/FormikInputField';
import FormikSelect from '../../../app/components/formikComponents/FormikSelect';
import StyledButton from '../../../app/components/styledComponents/StyledButton';
import {
  paymentDuration,
  productAllowanceDuration,
} from '../../Products/assets/utills';
import {
  useAddNewLicenseMutation,
  useGetAllLicenceTypesMutation,
  useGetProductListingMutation,
  useGetSingleLicenseByIdQuery,
  useUpdateLicenseInfoMutation,
} from '../licenseApiSlice';
import { toast } from 'react-toastify';
import FormikAsyncSelect from '../../../app/components/formikComponents/FormikAsyncSelect';
import { useNavigate, useParams } from 'react-router-dom';
import _, { differenceWith, fromPairs, isEqual, toPairs } from 'lodash';
import { Helmet } from 'react-helmet';

const AddLicense = () => {
  const { id } = useParams();
  const [getProducts] = useGetProductListingMutation();
  const [getLicenceType] = useGetAllLicenceTypesMutation();
  const navigate = useNavigate();

  const [addLicense, { isLoading: createLoading }] = useAddNewLicenseMutation();
  const { data: license, refetch } = useGetSingleLicenseByIdQuery(id, {
    skip: !id,
  });

  const [reponseLicense, setResponseLicence] = useState();
  const [updateLicense, { isLoading: updateLoading }] =
    useUpdateLicenseInfoMutation();

  const initValues = {
    name: '',
    maxAccounts: '',
    maxBasicAccounts: '',
    duration: '',
    licenceType: '',
    licencePaymentPlans: [{ duration: '', fee: '' }],
    // licenseFeatures: [],
    licenceProducts: [{ productId: '', quantity: '', duration: '' }],
  };

  // const [features, setFeatures] = useState([
  //   {
  //     id: uuidv4(),
  //     name: 'video Profiling',
  //     value: 'enabled',
  //   },
  // ]);

  // const [count, setCount] = useState(0);
  // const onAddFeatures = (values, setValues) => {
  //   setCount(count + 1);
  //   let newFeature = {
  //     id: uuidv4(),
  //     name: 'feature' + count,
  //     value: 'enabled',
  //   };

  //   let UpdateFeature = [...features];
  //   UpdateFeature.push(newFeature);
  //   setFeatures(UpdateFeature);

  //   setValues({ ...values, licenseFeatures: features });
  // };

  // const dellFeature = (id) => {
  //   const filteredFeatures = features.filter((item) => item.id !== id);
  //   setFeatures(filteredFeatures);
  // };

  const handleSubmit = (values) => {
    if (id) {
      const changes = differenceWith(
        toPairs(values),
        toPairs(reponseLicense),
        isEqual,
      );
      const licenseData = fromPairs(changes);
      licenseData['id'] = id;
      licenseData['licenceProducts'] =
        licenseData?.licenceProducts &&
        values?.licenceProducts
          ?.filter(
            (item, index) =>
              !isEqual(item, reponseLicense?.licenceProducts[index]),
          )
          .map((item) => ({ ...item, productId: item?.productId.value }));

      licenseData['licencePaymentPlans'] =
        licenseData?.licencePaymentPlans &&
        values?.licencePaymentPlans?.filter(
          (item, index) =>
            !isEqual(item, reponseLicense?.licencePaymentPlans[index]),
        );
      licenseData['licenceType'] =
        licenseData?.licenceType && values?.licenceType?.value;
      if (
        values?.licencePaymentPlans[0]?.duration ===
        values?.licencePaymentPlans[1]?.duration
      ) {
        toast.error(
          'Two payment plans for a licence cannot have same duration',
        );
      } else {
        updateLicense(licenseData).then(
          (data) => {
            toast.success('Licence updated successfully'); navigate('/licences');
          },
        );
      }
    } else {
      if (
        values?.licencePaymentPlans[0]?.duration ===
        values?.licencePaymentPlans[1]?.duration
      ) {
        toast.error(
          'Two payment plans for a licence cannot have same duration',
        );
      } else {
        values.licenceProducts = values?.licenceProducts?.map((item) => ({
          ...item,
          productId: item?.productId?.value,
        }));
        values.licenceType = values?.licenceType?.value;
        addLicense(values).then(
          (data) => {
            toast.success('Licence added successfully'); navigate('/licences');
          },
        );
      }
    }
  };

  const filterResponse = () => {
    const licenceProducts = license?.license?.licenceProducts?.map((item) => ({
      productId: {
        label: item?.product?.title,
        value: item?.product?.id,
      },
      quantity: item?.quantity,
      duration: item?.duration,
      id: item?.id,
    }));

    const licencePaymentPlans = license?.license?.licencePaymentPlans?.map(
      (item) => ({
        duration: item?.duration,
        fee: item?.fee,
        id: item?.id,
      }),
    );

    const licenceType = {
      label: license?.license?.licenceType?.title,
      value: license?.license?.licenceType?.id,
    };

    const filteredData = {
      ...license?.license,
      licencePaymentPlans: licencePaymentPlans,
      licenceProducts: licenceProducts,
      licenceType: licenceType,
    };

    setResponseLicence(filteredData);
  };

  const addLicenseSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    maxAccounts: Yup.number()
      .positive('Standard accounts must be positive number')
      .integer('Standard accounts must be whole number')
      .required('Required'),
    maxBasicAccounts: Yup.number()
      .positive('Guest accounts must be positive number')
      .integer('Guest accounts must be whole number')
      .required('Required'),
    duration: Yup.number()
      .positive('Licence duration must be positive number')
      .integer('Licence duration must be whole number')
      .required('Required'),
    licenceType: Yup.object().required('Required'),
    licencePaymentPlans: Yup.array().of(
      Yup.object().shape({
        duration: Yup.string().required('Required'),
        fee: Yup.number()
          .positive('Fee must be positive number')
          .required('Required'),
      }),
    ),

    // licenceProducts: Yup.array().of(
    //   Yup.object().shape({
    //     productId: Yup.object().required('Required'),
    //     quantity: Yup.string().required('Required'),
    //     duration: Yup.string().required('Required'),
    //   }),
    // ),
  });

  useEffect(() => {
    filterResponse();
  }, [license?.license]);

  useEffect(() => {
    if (id) refetch();
  }, [id]);

  let initials = id ? reponseLicense : initValues;

  return (
    <div>
      <Helmet>
        <title>{_.capitalize(reponseLicense?.name) + ' - occy ADMIN'}</title>
      </Helmet>
      <Portlet className="mx-3 my-4 ">
        <PortletHeader
          title={id ? 'Update Licence' : 'New Licence'}
          titleDetails={
            <span>
              {id
                ? 'Edit the necessary details and save this Licence'
                : 'Add the necessary details and save this Licence'}
            </span>
          }
        />
      </Portlet>

      <Formik
        enableReinitialize
        initialValues={{ ...initials }}
        validationSchema={addLicenseSchema}
        onSubmit={handleSubmit}
      >
        {({ handleSubmit, values, setValues }) => (
          <Form onSubmit={handleSubmit}>
            <Row className="mb-5">
              <Col lg={10}>
                <div className="ms-3">
                  <PortletBody className="ms- mb-3">
                    <h6 className="fw-bold">Licence Details</h6>
                    <Row className="mt-4">
                      <div className="col-12 col-md-12">
                        <div className="mb-3">
                          <label htmlFor="name" className="form-label">
                            Name
                          </label>
                          <FormikInputField
                            name="name"
                            id="name"
                            placeholder="Enter Name here"
                          />
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="mb-3">
                          <label htmlFor="maxAccounts" className="form-label">
                            Standard Accounts
                          </label>
                          <FormikInputField
                            type="number"
                            name="maxAccounts"
                            id="maxAccounts"
                            placeholder="Enter Accounts"
                          />
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="mb-3">
                          <label
                            htmlFor="maxBasicAccounts"
                            className="form-label"
                          >
                            Guest Accounts
                          </label>
                          <FormikInputField
                            type="number"
                            name="maxBasicAccounts"
                            id="maxBasicAccounts"
                            placeholder="Select Accounts"
                          />
                        </div>
                      </div>

                      <div className="col-12 col-md-6">
                        <div className="mb-3">
                          <label htmlFor="duration" className="form-label">
                            Licence Duration (In Days)
                          </label>
                          <FormikInputField
                            type="number"
                            name="duration"
                            id="duration"
                            placeholder="Enter Licence Duration (in days)"
                          />
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="mb-3">
                          <label htmlFor="licenceType" className="form-label">
                            Licence Type
                          </label>
                          <FormikAsyncSelect
                            name="licenceType"
                            id="licenceType"
                            apiCall={getLicenceType}
                            placeholder="Select Licence Type"
                            type="licenceType"
                          />
                        </div>
                      </div>
                    </Row>
                  </PortletBody>

                  <PortletBody className=" mb-3">
                    <h6 className="fw-bold mb-4">Payment Plans</h6>

                    <FieldArray
                      name="licencePaymentPlans"
                      render={(arrayHelpers) => (
                        <div>
                          {Array.isArray(values?.licencePaymentPlans)
                            ? values?.licencePaymentPlans?.map(
                              (item, index) => (
                                <div
                                  className="row"
                                  key={'licencePaymentPlans' + index}
                                >
                                  <div className="col-6">
                                    <div className="mb-3">
                                      <label
                                        htmlFor={`licencePaymentPlans.${index}.duration`}
                                        className="form-label"
                                      >
                                        Duration
                                      </label>
                                      <FormikSelect
                                        name={`licencePaymentPlans.${index}.duration`}
                                        placeholder="Select duration"
                                        options={paymentDuration}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-6">
                                    <div className="mb-3">
                                      <label
                                        htmlFor={`licencePaymentPlans.${index}.fee`}
                                        className="form-label"
                                      >
                                        Fee (in pounds £)
                                      </label>
                                      <FormikInputField
                                        type="number"
                                        name={`licencePaymentPlans.${index}.fee`}
                                        placeholder="Enter licence fee"
                                      />
                                    </div>
                                  </div>
                                </div>
                              ),
                            )
                            : null}

                          {Array.isArray(values?.licencePaymentPlans) &&
                            values?.licencePaymentPlans?.length > 1 ? null : (
                            <h6
                              className="text-primary fw-bold"
                              role="button"
                              onClick={() =>
                                arrayHelpers.insert(
                                  values?.licencePaymentPlans?.length + 1,
                                  {
                                    duration: '',
                                    fee: '',
                                  },
                                )
                              }
                            >
                              + Add Payment option
                            </h6>
                          )}
                        </div>
                      )}
                    />
                  </PortletBody>
                  {/*
                  <PortletBody className="ms-4 mb-3">
                    <h6 className="fw-bold">License Features</h6>
                    <Row className="mt-4">
                      <div className="col-12 col-md-12 ">
                        <div className="rounded border table-responsive">
                          <table className="table mb-0">
                            <thead className="bg-body">
                              <tr>
                                <th>Feature</th>
                                <th>Disabled</th>
                                <th>Enabled</th>
                                <th>Only For paid</th>
                                <th></th>
                              </tr>
                            </thead>
                            {features.map((feat, ind) => (
                              <tr className={ind % 2 !== 0 && 'bg-body'}>
                                <td className="p-2 ">{feat.name}</td>
                                <td>
                                  <FormikRadio
                                    hasNoLabel
                                    name={feat.name}
                                    value={feat.value}
                                    options={[{ label: 'disable' + ind }]}
                                  />
                                </td>
                                <td>
                                  <FormikRadio
                                    hasNoLabel
                                    name={feat.name}
                                    value={feat.value}
                                    options={[{ label: 'enable' + ind }]}
                                  />
                                </td>
                                <td>
                                  <FormikRadio
                                    hasNoLabel
                                    name={feat.name}
                                    value={feat.value}
                                    options={[{ label: 'paid' + ind }]}
                                  />
                                </td>
                                <td>
                                  <div
                                    className="cursor-pointer"
                                    onClick={() => dellFeature(feat.id)}
                                  >
                                    <CrossIcon />
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </table>
                        </div>
                      </div>

                      <div
                        className="text-primary fw-bold d-flex align-items-center cursor-pointer mt-4 mb-2"
                        onClick={() => onAddFeatures(values, setValues)}
                      >
                        <AddIcon className="me-2" />
                        Add Feature
                      </div>
                    </Row>
                  </PortletBody> */}

                  {/* <PortletBody className="ms-4 mb-3">
                    <h6 className="fw-bold">Product allowances</h6>
                    <Row className="mt-4">
                      <div className="col-12 col-md-4 ">
                        <div className="mb-3">
                          <label htmlFor="product" className="form-label">
                            Product
                          </label>
                          <FormikSelect
                            name="product"
                            id="product"
                            placeholder="Select a Product Type"
                            options={[{ id: uuidv4(), label: '1', value: '1' }]}
                          />
                        </div>
                      </div>

                      <div className="col-12 col-md-4 ">
                        <div className="mb-3">
                          <label
                            htmlFor="productQuantity"
                            className="form-label"
                          >
                            Quantity
                          </label>
                          <FormikSelect
                            name="productQuantity"
                            id="productQuantity"
                            placeholder="Select quantity"
                            options={[{ id: uuidv4(), label: '1', value: '1' }]}
                          />
                        </div>
                      </div>
                      <div className="col-12 col-md-4 ">
                        <div className="mb-3">
                          <label
                            htmlFor="productDuration"
                            className="form-label"
                          >
                            Duration
                          </label>
                          <FormikSelect
                            name="productDuration"
                            id="productDuration"
                            placeholder="Select Duration"
                            options={[{ id: uuidv4(), label: '1', value: '1' }]}
                          />
                        </div>
                      </div>
                      <div className="text-primary fw-bold d-flex align-items-center cursor-pointer mt-4 mb-2">
                        <AddIcon className="me-2" />
                        Add Allowances
                      </div>
                    </Row>
                  </PortletBody> */}

                  <PortletBody className="mb-3">
                    <h6 className="fw-bold mb-4">Product Allowances</h6>

                    <FieldArray
                      name="licenceProducts"
                      render={(arrayHelpers) => (
                        <div>
                          {Array.isArray(values?.licenceProducts)
                            ? values?.licenceProducts?.map((item, index) => (
                              <div
                                className="row"
                                key={'licenceProducts' + index}
                              >
                                <div className="col-4">
                                  <div className="mb-3">
                                    <label
                                      htmlFor={`licenceProducts.${index}.productId`}
                                      className="form-label"
                                    >
                                      Product
                                    </label>
                                    <FormikAsyncSelect
                                      apiCall={getProducts}
                                      name={`licenceProducts.${index}.productId`}
                                      placeholder="Select product"
                                      type={'productId'}
                                    />
                                  </div>
                                </div>

                                <div className="col-4">
                                  <div className="mb-3">
                                    <label
                                      htmlFor={`licenceProducts.${index}.quantity`}
                                      className="form-label"
                                    >
                                      Quantity
                                    </label>
                                    <FormikInputField
                                      type="number"
                                      name={`licenceProducts.${index}.quantity`}
                                      placeholder="Enter quantity"
                                    />
                                  </div>
                                </div>
                                <div className="col-4">
                                  <div className="mb-3">
                                    <label
                                      htmlFor={`licenceProducts.${index}.duration`}
                                      className="form-label"
                                    >
                                      Duration
                                    </label>
                                    <FormikSelect
                                      name={`licenceProducts.${index}.duration`}
                                      placeholder="Select duration"
                                      options={productAllowanceDuration}
                                    />
                                  </div>
                                </div>
                              </div>
                            ))
                            : null}

                          <h6
                            className="text-primary fw-bold"
                            role="button"
                            onClick={() =>
                              arrayHelpers.insert(
                                values?.licenceProducts?.length + 1,
                                {
                                  productId: '',
                                  quantity: '',
                                  duration: '',
                                },
                              )
                            }
                          >
                            + Add product allowances
                          </h6>
                        </div>
                      )}
                    />
                  </PortletBody>

                  <div className="float-end mb-5">
                    <StyledButton
                      type="submit"
                      loading={createLoading || updateLoading}
                      disabled={createLoading || updateLoading}
                    >
                      {id ? 'Update' : 'Save'}
                    </StyledButton>
                  </div>
                </div>
              </Col>
            </Row>
          </Form>
        )
        }
      </Formik >
    </div >
  );
};

export default AddLicense;
