import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import PortletBody from '../../../../../app/components/portlet/PortletBody';
import EditIcon from '../../../../../app/components/svgIcons/EditIcon';
import { capitalizeFirstLetter, renderHtml } from '../../../../../utils/helper';
import { PRODUCT_TYPES } from '../../../assets/utills';

const ProductDetailsView = ({ productDetails }) => {
  return (
    <>
      <div className="d-flex bg-body justify-content-center align-item-center w-100">
        <PortletBody className="mb-4 w-50">
          <Row className="d-flex justify-content-between align-items-center">
            <Col lg={8} className="d-flex ">
              <div>
                <h4 className="m-0"> Basic information</h4>
              </div>
            </Col>
            <Col lg={4} className="d-flex justify-content-end">
              <Link
                variant="text"
                className="text-decoration-none px-2"
                to={`/products/${productDetails?.product.id}/creation`}
              >
                <EditIcon />
              </Link>
            </Col>
          </Row>
          <>
            <Row className="mt-4">
              <Col lg={4}>
                <span className="fw-bold">Title</span>
              </Col>
              <Col lg={4}>
                <span>{productDetails?.product.title}</span>{' '}
              </Col>
              <Col lg={4}></Col>
            </Row>
            <hr />

            <Row className="mt-3 mb-1">
              <Col lg={4}>
                <span className="fw-bold">Quantity</span>
              </Col>
              <Col lg={4}>
                <span>{productDetails?.product.quantity}</span>{' '}
              </Col>
              <Col lg={4}></Col>
            </Row>
            <hr />
            <Row className="mt-3 mb-1">
              <Col lg={4}>
                <span className="fw-bold">Access Key</span>
              </Col>
              <Col lg={4}>
                <span>{productDetails?.product.accessKey}</span>{' '}
              </Col>
              <Col lg={4}></Col>
            </Row>
            <hr />
            <Row className="mt-3 mb-1">
              <Col lg={4}>
                <span className="fw-bold">Price (in Credits)</span>
              </Col>
              <Col lg={4}>
                <span>{productDetails?.product.creditPrice}</span>{' '}
              </Col>
              <Col lg={4}></Col>
            </Row>
            <hr />
            <Row className="mt-3 mb-1">
              <Col lg={4}>
                <span className="fw-bold">Product Group</span>
              </Col>
              <Col lg={4}>
                <span>{productDetails?.product.productGroup || '-'}</span>{' '}
              </Col>
              <Col lg={4}></Col>
            </Row>
            <hr />
            <Row className="mt-3 mb-1">
              <Col lg={4}>
                <span className="fw-bold">Description</span>
              </Col>
              <Col lg={4}>
                <span>{renderHtml(productDetails?.product.description)}</span>{' '}
              </Col>
              <Col lg={4}></Col>
            </Row>
            <hr />
            {productDetails?.product.productType ===
            PRODUCT_TYPES.ADVERTISING ? (
              <>
                <Row className="mt-3 mb-1">
                  <Col lg={4}>
                    <span className="fw-bold">Posting Type</span>
                  </Col>
                  <Col lg={4}>
                    <span>
                      {capitalizeFirstLetter(
                        productDetails?.product.postingType,
                      )}
                    </span>{' '}
                  </Col>
                  <Col lg={4}></Col>
                </Row>
                <hr />
              </>
            ) : null}
          </>
        </PortletBody>
      </div>
    </>
  );
};

export default ProductDetailsView;
