import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import ProductAdoptionDashboard from './adoptionDashboard/ProductAdoptionDashboard';

const ProductAdoptionRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<ProductAdoptionDashboard />} />
            <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
    );
};

export default ProductAdoptionRoutes;
