import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import styled from 'styled-components';
import EditorToolbar, { formats, getModules } from './Toolbar';
import CustomCircularProgressbar from '../svgIcons/CustomCircularProgressbar';
import StyledColoredTag from '../styledComponents/StyledColoredTag';
import theme from '../../../utils/themes';
import { StyledLabel } from '../../../assets/styledComponents/Global';

const StyledTextEditor = ({
  title,
  value,
  name,
  onChange,
  placeholder,
  id,
  border,
  borderRadius,
  borderRadiusEditor,
  elementRef,
  isReadOnly,
  count,
  totalCount,
  maxLength,
  ...props
}) => {
  const [charCount, setCharCount] = useState(value?.length);
  const [progress, setProgress] = useState(0);
  const isCharacterLimitExceeded = charCount > count;
  const handleTextChange = (content, delta, source, editor) => {
    const currentCharCount = editor.getLength();
    setCharCount(currentCharCount);
    setProgress((currentCharCount / maxLength) * 100);
    onChange(content, delta, source, editor);
  };
  const checkCharacterCount = (event) => {
    if (totalCount >= 5000 && event.key !== 'Backspace') {
      if (name === 'elevatorPitch' ||
        name === 'jobDescription' ||
        name === 'jobRequirement') {
        event.preventDefault();
      }
    }
  };

  return (
    <StyledContainer
      {...props}
      border={border}
      borderRadius={borderRadius}
      borderRadiusEditor={borderRadiusEditor}
      isCharacterLimitExceeded={isCharacterLimitExceeded}
    >
      <h5 className="m-0 fw-bold">{title}</h5>
      <EditorToolbar id={id} />
      <ReactQuill
        ref={elementRef}
        modules={getModules(id)}
        formats={formats}
        maxLength={maxLength}
        value={value}
        readOnly={isReadOnly}
        onChange={handleTextChange}
        onKeyDown={checkCharacterCount}
        placeholder={placeholder}
      />
      {count && (
        <StyledColoredTag
          className="char-count-box"
          backgroundColor={theme.primaryLight}
          $height="45px"
        >
          <CustomCircularProgressbar value={progress} />{' '}
          <StyledLabel className="ms-2" size="14px" color={theme.primary}>
            {`${charCount}/${count} characters`}
          </StyledLabel>
        </StyledColoredTag>
      )}
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.white};
  border: 1px solid transparent;
  border-radius: 10px;
  ${({ isValid, theme, isInvalid }) => {
    if (isValid && !isInvalid) {
      return `
        overflow: hidden;
      `;
    }
    if (!isValid && isInvalid) {
      return `
        overflow: hidden;
        border-color: ${theme.danger};
      `;
    }
    return `border-color: transparent`;
  }}

  ${({ isCharacterLimitExceeded, theme }) =>
    isCharacterLimitExceeded
      ? `
        border-color: ${theme.danger};
      `
      : ''}

  .quill {
    display: flex;
    flex-direction: column;
  }

  .ql-toolbar {
    border-radius: ${({ borderRadius }) =>
    borderRadius ? borderRadius : '5px 5px 0 0'};
    background-color: ${({ theme }) => theme.bodyBg} !important;
    border: ${({ border }) => (border ? border : '0 !important')};
  }

  .ql-snow.ql-toolbar,
  button {
    &:hover {
      color: ${({ theme }) => theme.primary} !important;
    }
  }

  .char-count-box {
    position: absolute;
    bottom: -18px;
    right: 0;
    transform: translateY(-50%);
    padding: 5px;
    border: 1px solid;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    padding: 10px;
  }

  .ql-container {
    border: 0 !important;
  }

  .ql-editor {
    font-family: 'Quick Sand';
    border-radius: ${({ borderRadiusEditor }) =>
    borderRadiusEditor ? borderRadiusEditor : '0 0 5px 5px'};
    min-height: 150px;
    font-size: 14px;
    border-bottom: 2px solid
      ${({ theme, border }) => (border ? border : theme.bodyBg)};
    border-right: 2px solid
      ${({ theme, border }) => (border ? border : theme.bodyBg)};
    border-left: 2px solid
      ${({ theme, border }) => (border ? border : theme.bodyBg)};
    max-height: 300px;
    overflow-y: auto;

    border: ${({ borderApp }) => borderApp && borderApp};
  }

  .char-count-box {
    background-color: ${({ theme }) => theme.primaryLight};
  }

`;

export default StyledTextEditor;
