import React from 'react';
import StyledColoredTag from './StyledColoredTag';
import CustomCircularProgressbar from '../svgIcons/CustomCircularProgressbar';
import { useEffect } from 'react';
import theme from '../../../utils/themes';
import { StyledLabel } from '../../../assets/styledComponents/Global';
import { useState } from 'react';
import styled from 'styled-components';

const StyledCharacterCount = ({ values }) => {
  const { elevatorPitch, jobDescription, jobRequirement } = values;
  const totalCharacters = elevatorPitch.length + jobDescription.length + jobRequirement.length;
  const [progress, setProgress] = useState(0);
  useEffect(() => {
    setProgress((totalCharacters / 5000) * 100);
  }, [totalCharacters]);
  return (
    <StyledTag>
      <StyledColoredTag
        className="char-count-box"
        backgroundColor={theme.primaryLight}
        $height="45px"
      >
        <CustomCircularProgressbar value={progress} />{' '}
        <StyledLabel className="ms-2" size="14px" color={theme.primary}>
          {`${totalCharacters}/${5000} characters`}
        </StyledLabel>
      </StyledColoredTag>
    </StyledTag>

  );
};


const StyledTag = styled.div` 
.char-count-box {
  position: fixed;
  bottom: 50px;
  right: 65px;
  padding: 5px;
  border: 1px solid ${({ theme }) => theme.primary};
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  padding: 10px;
  background-color: ${({ theme }) => theme.primaryLight};
}
`


export default StyledCharacterCount;