import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Link, useParams } from 'react-router-dom';
import { isEqual } from 'lodash';
import moment from 'moment';
import { STATUSES } from '../../../utils/Statuses';
import JobListingTable from '../../Job/listing/JobListingTable';
import PaginationPerPage from '../../../app/components/pagination/PaginationPerPage';
import TableSkeleton from '../../../assets/skeletons/tableSkeleton';
import {
  useGetAllCampaignsQuery,
  useGetAllCompanyCampaignsQuery,
} from '../CampainApiSlice';
import Portlet from '../../../app/components/portlet/Portlet';
import PortletBody from '../../../app/components/portlet/PortletBody';
import StyledIconInput from '../../../app/components/styledComponents/StyledIconInput';
import SearchIcon from '../../../app/components/svgIcons/SearchIcon';
import StyledButton from '../../../app/components/styledComponents/StyledButton';
import FilterIcon from '../../../app/components/svgIcons/FilterIcon';
import SortIcon from '../../../app/components/svgIcons/SortIcon';
import StyledColoredTag from '../../../app/components/styledComponents/StyledColoredTag';
import EyeIcon from '../../../app/components/svgIcons/EyeIcon';
import CustomDropdown from '../../../app/components/dropdown/CustomDropDown';
import OptionFillIcon from '../../../app/components/svgIcons/OptionFillIcon';
import OnlineIcon from '../../../app/components/svgIcons/OnlineIcon';
import {
  capitalizeFirstLetter,
  getStatusColor,
  getStatusFriendly,
} from '../../../utils/helper';
import { Form, Formik } from 'formik';
import StyledRadioButton from '../../../app/components/radio/StyledRadioButton';
import PauseIcon from '../../../app/components/svgIcons/PauseIcon';
import StyledCheckbox from '../../../app/components/checkbox/StyledCheckbox';
import StyledFilterTabs from '../../../app/components/styledComponents/StyledFilterTabs';
import { sort } from '../CampaignsUtils';
import NewDownloadIcon from '../../../app/components/svgIcons/NewDownloadIcon';
import { CSVLink } from 'react-csv';
import StyledToolTip from '../../../app/components/styledComponents/StyledToolTip';
import CrossIcon from '../../../app/components/svgIcons/CrossIcon';
import CampaignFilterForm from '../CampaignFilterForm';
import PortletHeader from '../../../app/components/portlet/PortletHeader';
import CampaignOccurrence from '../CampaignOccurrence';
import CandidatesIcon from '../../../app/components/svgIcons/CandidatesIcon';
import PublicAdvertModal from '../PublicAdvertModal';
import { StyledLabel } from '../CampaignDetails';

const CampaignsListingContainer = ({ globalCampaign }) => {
  const { compId } = useParams();
  const [sortFilter, setSortFilter] = useState(false);
  const [appliedValues, setApplyedValues] = useState({});
  const [sorted, setSorted] = useState(null);
  const [isOpen, setIsopen] = useState(false);
  const [filters, setFilters] = useState('');
  const [selectedColumns, setSelectedColumns] = useState(false);
  const sortFilterRef = useRef(null);
  const selectColumnRef = useRef(null);
  const filterRef = useRef(null);
  const [campaignDetailsModal, setCampaignDetailsModal] = useState(false);
  const [publicCampaignModal, setPublicCampaignModal] = useState(false);
  const [compaignList, setCompaignList] = useState([]);
  const [campaignData, setCampaignData] = useState()
  const [activeCampaign, setActiveCampaign] = useState('');
  const [campaignId, setCampaignId] = useState('');
  const ref = useRef();
  let apiCall = globalCampaign
    ? useGetAllCampaignsQuery
    : useGetAllCompanyCampaignsQuery;
  const initialFilters = {
    jobs: [],
    perPage: 5,
    page: 1,
    sortedColumn: { path: 'createdAt', order: 'DESC' },
    query: '',
  };
  const [listing, setListing] = useState({ ...initialFilters });

  const {
    data: jobRes,
    refetch,
    isFetching,
    error,
  } = apiCall(
    {
      params: {
        perPage: listing.perPage,
        page: listing.page,
        query: listing.query && listing.query,
        sort: listing.sortedColumn.path || filters.sort,
        sortDir: listing.sortedColumn.order || filters.sortDir,
        ...filters,
      },
      id: compId,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const [timer, setSearchTimer] = useState(null);
  const handleSearch = (evt) => {
    clearTimeout(timer);
    const query = evt.target.value;
    const newTimer = setTimeout(() => {
      setListing({ ...listing, page: 1, query: query });
    }, 2000);
    setSearchTimer(newTimer);
  };
  const handleSort = (sort) => {
    const sortBlacklist = ['hired', 'statusId', 'account.firstName'];
    if (!sortBlacklist.includes(sort.path)) {
      setListing((prevState) => ({
        ...prevState,
        sortedColumn: { ...sort },
        page: 1,
      }));
    }
  };
  const handlePerPageChange = (perPage) => {
    setListing((prevState) => ({
      ...prevState,
      perPage: perPage.target.innerText,
      page: 1,
    }));
  };

  const handlePageChange = ({ selected }) => {
    setListing((prevState) => ({
      ...prevState,
      page: selected + 1,
    }));
  };
  useEffect(() => {
    jobRes?.data && setCompaignList(jobRes?.data);
  }, [jobRes?.data]);
  // const handleAccountCheck = (account, index, checked) => {
  //   setCompaignList((prevAccountsList) => {
  //     const accountIndex = prevAccountsList?.findIndex(
  //       (a) => a.id === account.id,
  //     );
  //     if (accountIndex === -1) {
  //       const updatedAccountsList = [
  //         ...prevAccountsList,
  //         { ...account, checked },
  //       ];
  //       return updatedAccountsList;
  //     } else if (prevAccountsList[accountIndex].checked) {
  //       const updatedAccountsList = [...prevAccountsList];
  //       updatedAccountsList[accountIndex] = {
  //         ...prevAccountsList[accountIndex],
  //         checked: false,
  //       };
  //       return updatedAccountsList;
  //     } else {
  //       const updatedAccountsList = [...prevAccountsList];
  //       updatedAccountsList[accountIndex] = {
  //         ...prevAccountsList[accountIndex],
  //         checked: true,
  //       };
  //       return updatedAccountsList;
  //     }
  //   });
  // };
  const [newColumn, setNewColumn] = useState([]);

  const [column, setColumn] = useState([
    // {
    //   id: 1,
    //   isChecked: true,
    //   path: '',
    //   label: '',
    //   alignBody: 'text-left align-middle',
    //   alignHeader: 'text-left align-middle cursor-pointer p-3',
    //   content: (account, index) => (
    //     <>
    //       <div key={1} className="d-flex align-items-center">
    //       <StyledCheckbox
    //           size={15}
    //           value={account?.checked}
    //           onChange={(e) =>
    //             handleAccountCheck(account, index, e.target.checked)
    //           }
    //         />
    //       </div>
    //     </>
    //   ),
    // },
    {
      id: 2,
      label: 'Id',
      // path: 'id',
      isChecked: true,
      alignBody: 'text-left align-middle ps-4 py-3',
      alignHeader: 'text-left align-middle cursor-pointer ps-4 py-3',
      content: (job) => (
        <Link
          className="text-decoration-none cursor-pointer"
          // to={`${job?.id}`}
        >
          <StyledLabel weight="600" size="14px"  className="m-0 cursor-pointer">
           {job?.id || '-'}
         </StyledLabel>
        </Link>
        
      ),
    },
    {
      id: 3,
      label: 'Name',
      // path: 'campaignName',
      isChecked: true,
      alignBody: 'text-left align-middle ps-4 py-3 ',
      alignHeader: 'text-left align-middle cursor-pointer ps-4 py-3',
      content: (job) => (
        <StyledLabel weight="500" size="13px" className="m-0 text-muted cursor-pointer">
        {job?.campaignName || '-'}
      </StyledLabel>
      ),
    },
    {
      id: 4,
      isChecked: true,
      label: 'Job',
      content: ({ jobTitle }) =>  <StyledLabel weight="500" size="13px" className="m-0 text-muted">
      {jobTitle || '-'}
    </StyledLabel> ,
      alignBody: 'text-left align-middle ps-4 py-3 ',
      alignHeader:
        'text-left align-middle ps-4 py-3 cursor-pointer text-nowrap',
    },
    {
      id: 3,
      isChecked: true,
      //  path: 'jobStartDate',
      label: 'Job Start',
      content: (job) => (
        <>
          <span className="m-0">{job?.jobHiringPlan?.startDate ? moment(job?.jobHiringPlan?.startDate).format('Do MMM yyyy') : '-'}</span>
        </>
      ),
      alignBody: 'text-left ps-4 py-3 align-middle',
      alignHeader:
        'text-left align-middle cursor-pointer ps-4 py-3 text-nowrap',
    },
    {
      id: 5,
      isChecked: true,
      // path: 'startDate',
      label: 'Duration',
      content: (job) => (
        <StyledLabel weight="500" size="13px" className="m-0 text-muted">
      {' '}
          {job?.startDate &&
            `${moment(job?.startDate).format('Do MMM')} -`}{' '}
          {job?.endDate ? moment(job?.endDate).format('Do MMM YYYY') : '-'}
    </StyledLabel> 
    
      ),
      alignBody: 'text-left align-middle ps-4 py-3',
      alignHeader:
        'text-left align-middle cursor-pointer ps-4 py-3 text-nowrap',
    },
    {
      id: 6,
      isChecked: true,
      label: 'Creator',
      content: (campaign) => (
        <>
          <StyledLabel weight="500" size="13px" className="m-0 text-muted">
          {`${capitalizeFirstLetter(
              campaign?.account?.firstName,
            )} ${capitalizeFirstLetter(campaign?.account?.lastName)}`}
    </StyledLabel> 
          {/* <div className="d-flex">
            {`${capitalizeFirstLetter(
              campaign?.account?.firstName,
            )} ${capitalizeFirstLetter(campaign?.account?.lastName)}`}
          </div> */}
        </>
      ),
      alignBody: 'text-left align-middle ps-4 py-3',
      alignHeader:
        'text-left align-middle cursor-pointer ps-4 py-3 text-nowrap',
    },
    {
      id: 7,
      isChecked: true,
      label: 'Company',
      content: (campaign) => (
        <>
           <StyledLabel weight="500" size="13px" className="m-0 text-muted">
           {campaign?.job?.company?.name ?? '-'}
    </StyledLabel> 
          {/* <div className="d-flex">{campaign?.job?.company?.name ?? '-'}</div> */}
        </>
      ),
      alignBody: 'text-left align-middle ps-4 py-3',
      alignHeader:
        'text-left align-middle cursor-pointer ps-4 py-3 text-nowrap',
    },
    {
      id: 8,
      isChecked: true,
      label: 'Status',
      content: (job) => (
        <>
          <div className="d-flex">
            <StyledColoredTag
              className="rounded-pill mx-0"
              variant={getStatusColor(job?.statusId)}
            >
              <OnlineIcon
                color={getStatusColor(job?.statusId)}
                className="me-1"
              />{' '}
              {getStatusFriendly(job?.statusId) || '-'}
            </StyledColoredTag>
          </div>
        </>
      ),
      alignBody: 'text-left align-middle ps-4 py-3',
      alignHeader:
        'text-left align-middle cursor-pointer ps-4 py-3 text-nowrap',
    },
    {
      id: 9,
      isChecked: true,
      label: 'Actions',
      alignBody: 'text-center align-middle text-nowrap ps-4 py-3',
      alignHeader:
        'text-center align-middle cursor-pointer ps-4 py-3 text-nowrap',
      content: (campaign) => (
        <>
          <div className={`d-flex justify-content-center align-items-center cursor-pointer ${campaign?.statusId === 101 ? 'feature-disable' : ''}`}>
            {/* <Link
                variant="text"
                className="text-decoration-none  px-2"
                to={`${job.id}`}
              >
                <EditIcon />
              </Link> */}
            <div
              // href={`${process.env.REACT_APP_URL}/p/job/${job?.job?.id}`}
              // variant="text"
              // rel="noopener noreferrer"
              // target="_blank"
              className={`text-decoration-none px-2 d-flex align-items-center ${campaign?.statusId === 101 ? 'feature-pending-new' : ''}`}
              onClick={() => {
                setCampaignDetailsModal(true);
                setCampaignId(campaign?.id);
                setActiveCampaign(campaign);
              }}
            >
              <EyeIcon />
              {/* <span className="ms-2 fw-semibold">View Details</span> */}
            </div>

            <StyledToolTip title="View Public Advert"
              className={`position-fixed ${campaign?.jobPostings?.length <= 0 && 'd-none'}`}
              childrenClass={'ms-0'}>
              <span
                className={`cursor-pointer mx-2 ${campaign?.jobPostings?.length <= 0 && 'feature-pending-new'}`}
                onClick={() => {
                  setCampaignData(campaign);
                  setPublicCampaignModal(true);
                }}
              >
                <CandidatesIcon size={15} />
              </span>
            </StyledToolTip>
            <div className="d-flex">
              <CustomDropdown
                className="feature-pending"
                toggleClassName={'bg-transparent px-0 feature-disable'}
                menuClassName={'adminJobs'}
                dropdownToggle={
                  <div
                    className={`account-menu ${'bg-transparent ps-0 ms-2'} `}
                  >
                    <OptionFillIcon rotate={'left'} />
                  </div>
                }
                dropdownMenu={<></>}
              />
            </div>
          </div>
        </>
      ),
    },
  ]);

  let ToggleFilterbar = (item) => {
    setIsopen(item === 'filter' ? !isOpen : false);
    setSortFilter(item === 'sort' ? !sortFilter : false);
    setSelectedColumns(item === 'selected' ? !selectedColumns : false);
  };

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setSortFilter(false);
          setSelectedColumns(false);
          setIsopen(false);
        }
      }

      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }

  useOutsideAlerter(
    (isOpen && filterRef) ||
    (sortFilter && sortFilterRef) ||
    (selectedColumns && selectColumnRef),
  );

  const handleSubmit = async (values) => {
    setApplyedValues({ ...values });
    setFilters((prevFilters) => ({
      ...prevFilters,
      ...values,
      jobId: values?.jobId?.value,
      companyId: values?.companyId?.value,
    }));
    setIsopen(false);
  };

  const handleCHeck = (item) => {
    const tempColumn = [...column];
    const newItemIndex = column.findIndex((t) => t.id === item.id);
    tempColumn[newItemIndex].isChecked = !tempColumn[newItemIndex].isChecked;
    setColumn(tempColumn);
  };

  const handleSelectedColumn = () => {
    const newArr = [];
    column.map((item) => item.isChecked && newArr.push(item));
    setNewColumn(newArr);
    setSelectedColumns(false);
  };

  useEffect(() => {
    handleSelectedColumn();
    refetch();
  }, []);

  const handleSortBy = (item) => {
    setSorted(item.match);
    const prevFilters = { ...filters };
    prevFilters.sort = item.value;
    prevFilters.sortDir = item.dir;
    setFilters({ ...prevFilters });
  };

  const getCsvData = () => {
    return jobRes?.data
      ? jobRes?.data?.map((job) => ({
        Name: job?.campaignName,
        Job: job?.job?.title,
        Cost: job?.cost,
        Duration: `${job?.startDate && moment(job?.startDate).format('Do MMM')
          } ${job?.startDate && '-'} ${job?.endDate && moment(job?.endDate).format('Do MMM YYYY')
          }`,
        Creator: `${capitalizeFirstLetter(
          job?.account?.firstName,
        )} ${capitalizeFirstLetter(job?.account?.lastName)}`,
        Company: job?.job?.company?.name ?? '-',
        Status: `${getStatusFriendly(job?.statusId) || '-'}`,
      }))
      : '';
  };

  const [tabs, setTabs] = useState([
    {
      id: 1,
      title: 'All',
      active: true,
      status: STATUSES.NULL,
    },
    {
      id: 2,
      title: 'Live',
      active: false,
      status: STATUSES.LIVE,
    },
    {
      id: 3,
      title: 'Partially Live',
      active: false,
      status: STATUSES.PARTIALLY_LIVE,
    },
    {
      id: 4,
      title: 'Draft',
      active: false,
      status: STATUSES.DRAFT,
    },
    {
      id: 5,
      title: 'Pending',
      active: false,
      status: STATUSES.PENDING,
    },
    {
      id: 6,
      title: 'Closed',
      active: false,
      status: STATUSES.CLOSED,
    },
    {
      id: 7,
      title: 'Scheduled',
      active: false,
      status: STATUSES.SCHEDULED,
    },
    {
      id: 8,
      title: 'Suspended',
      active: false,
      status: STATUSES.SUSPENDED,
    },
    {
      id: 9,
      title: 'Failed',
      active: false,
      status: STATUSES.FAILED,
    },
  ]);

  const onChangeTabs = (tab) => {
    setTabs((prevState) =>
      prevState.map((p) => {
        p.id === tab &&
          setFilters({
            ...filters,
            statusId: p.status === 0 ? undefined : p.status,
          });
        return p.id === tab
          ? {
            ...p,
            active: true,
          }
          : {
            ...p,
            active: false,
          };
      }),
    );
  };

  return (
    <div>
      <Portlet>
        {globalCampaign && (
          <PortletHeader
            tabs={tabs}
            title={'Campaigns'}
            onChangeTabs={onChangeTabs}
            subTitle={`(${jobRes?.totalCount || '0'})`}
            actions={<></>}
          />
        )}
        <div className={`${globalCampaign ? 'portlet__body-bg' : ''}`}>
          <PortletBody className="mx-2">
            <>
              <div className="flex-center mb-3">
                <div className="flex-grow-1 pb-1">
                  <StyledIconInput
                    icon={<SearchIcon />}
                    className="mw-50"
                    $maxWidth="60%"
                    $marginLeft="3px"
                    onChange={handleSearch}
                    loading={isFetching}
                    placeholder="Search"
                  />
                </div>

                <div className="d-flex">
                  <div className="flex-center me-3">
                    <StyledFilterTabs padding="4px">
                      <div className="btn-group " role="group">
                        <button
                          className="btn"
                          onClick={() => ToggleFilterbar('filter')}
                        >
                          <FilterIcon size={14} />
                          <small className="ms-1">Filter</small>
                        </button>
                      </div>
                    </StyledFilterTabs>
                  </div>
                  <div className="d-flex">
                    <div className="flex-center">
                      <StyledFilterTabs padding="5px">
                        <div className="btn-group " role="group">
                          <button
                            className="btn"
                            id="sort"
                            onClick={() => ToggleFilterbar('sort')}
                          >
                            <SortIcon size={15} />
                          </button>
                          <button
                            className="btn"
                            onClick={() => ToggleFilterbar('selected')}
                          >
                            <PauseIcon size={15} />
                          </button>
                          <button className="btn">
                            <CSVLink
                              data={getCsvData()}
                              filename={'companies.csv'}
                            >
                              <StyledToolTip
                                title="Download"
                                childrenClass={'ms-0'}
                              >
                                <NewDownloadIcon size={15} />{' '}
                              </StyledToolTip>
                            </CSVLink>
                          </button>
                        </div>
                      </StyledFilterTabs>
                    </div>
                  </div>
                  <div
                    ref={selectColumnRef}
                    className=" position-relative d-flex rounded flex-column"
                  >
                    {selectedColumns && (
                      <StyledFilterBar
                        width="248px"
                        className="bg-white py-3 align-items-center justify-content-center"
                      >
                        <div
                          className="d-flex justify-content-between align-items-center mb-3"
                          onClick={() => setSelectedColumns(false)}
                        >
                          <h5 className="mb-0 ps-3">Column Modifier</h5>
                          <div className="cursor-pointer pe-3">
                            <CrossIcon />
                          </div>
                        </div>
                        {column.map((item, index) => {
                          return (
                            <StyledBgEffect
                              className="d-flex align-items-center py-2 ps-4 cursor-pointer"
                              key={`cl-${index}`}
                            >
                              <StyledCheckbox
                                size="14"
                                value={item.isChecked}
                                onChange={() => handleCHeck(item)}
                              />
                              <h6 className="ms-2 mb-0">{item.label}</h6>
                            </StyledBgEffect>
                          );
                        })}
                        <div className="mx-4 mt-2">
                          <StyledButton
                            onClick={handleSelectedColumn}
                            className="w-100 p-1"
                          >
                            Apply
                          </StyledButton>
                        </div>
                      </StyledFilterBar>
                    )}
                  </div>
                  <div
                    ref={sortFilterRef}
                    className="m-0 position-relative d-flex rounded flex-column"
                  >
                    {sortFilter && (
                      <StyledFilterBar
                        width="290px"
                        className="bg-white py-3 align-items-center justify-content-center"
                      >
                        <div className="d-flex justify-content-between align-items-center mb-3">
                          <h5 className="mb-0 ps-4">Sort by</h5>
                        </div>
                        {sort?.map((item, index) => {
                          return (
                            <StyledBgEffect
                              className={`${item.value === 'nearest'
                                ? 'feature-pending'
                                : ''
                                } d-flex align-items-center py-2 ps-4 `}
                              key={`sort-${item.value}-${index}`}
                            >
                              <StyledRadioButton
                                size={15}
                                dotSize={7}
                                value={
                                  sorted === item.match ||
                                  (!sorted && index === 0 && true)
                                }
                                label={item.label}
                                className="fw-bold"
                                onChange={() => handleSortBy(item)}
                              />
                            </StyledBgEffect>
                          );
                        })}
                      </StyledFilterBar>
                    )}
                  </div>
                  <div ref={filterRef} className="bg-transparent mt-2">
                    {isOpen && (
                      <StyledFilterBar width="400px" variant="filter">
                        <div className="d-flex justify-content-end">
                          <div
                            className="cursor-pointer mt-3 me-3"
                            onClick={() => setIsopen(false)}
                          >
                            <CrossIcon />
                          </div>
                        </div>

                        <div className="sd-header">
                          <h4 className="mb-0">Filters</h4>
                          {!isEqual(filters, initialFilters) && (
                            <h6
                              className="mb-0 cursor-pointer"
                              onClick={() => {
                                setApplyedValues({});
                                setFilters((prevFilters) => ({
                                  sort: prevFilters?.sort ?? 'createdAt',
                                  sortDir: prevFilters?.sortDir ?? 'DESC',
                                }));
                                setIsopen(false);
                              }}
                            >
                              Clear All
                            </h6>
                          )}
                        </div>
                        <div className="sd-body">
                          <Formik
                            initialValues={{
                              ...appliedValues,
                            }}
                            onSubmit={handleSubmit}
                            innerRef={ref}
                          >
                            {({ handleSubmit, values, submitForm, errors }) => (
                              <Form onSubmit={handleSubmit}>
                                <CampaignFilterForm
                                  submitForm={submitForm}
                                  values={values}
                                  loading={false}
                                  compId={compId}
                                  globalCampaign={globalCampaign}
                                />
                              </Form>
                            )}
                          </Formik>
                        </div>
                      </StyledFilterBar>
                    )}
                  </div>
                </div>
              </div>

              {error || compaignList?.length === 0 || !compaignList? (
                <div className="d-flex justify-content-center align-items-center">
                  No Job Campaigns Found ...
                </div>
              ) : (
                <>
                  {compaignList.length > 0 ? (
                    <JobListingTable
                      jobs={compaignList}
                      sortColumn={listing.sortedColumn}
                      onSort={handleSort}
                      filterTable={newColumn}
                    />
                  ) : (
                    <TableSkeleton
                      cols={[
                        'Name',
                        'Job',
                        'Cost',
                        'Duration',
                        'Status',
                        'Actions',
                      ]}
                      rows={3}
                    />
                  )}
                  <PaginationPerPage
                    perPage={jobRes?.perPage || 0}
                    pageNo={jobRes?.page || 1}
                    onPerPageChange={handlePerPageChange}
                    totalCount={jobRes?.totalCount || 0}
                    onPageChange={handlePageChange}
                  />
                </>
              )}
            </>
          </PortletBody>
        </div>
      </Portlet>
      {campaignDetailsModal && (
        // <CampaignPostingModal
        //   show={campaignDetailsModal}
        //   onHide={() => setCampaignDetailsModal(false)}
        //   title={campaignName}
        //   id={campaignId}
        // />
        <CampaignOccurrence
          show={campaignDetailsModal}
          onHide={() => {
            setCampaignDetailsModal(false);
            setActiveCampaign('');
            refetch();
          }}
          campaign={activeCampaign}
          id={campaignId}
          refetch={refetch}
        />
        /* <JobNewJobCreationModal
                                                                                                                                  onHide={() => setCreateNewJobModal(false)}
                                                                                                                                  show={createNewJobModal}
                                                                                                                                />*/
      )}

      {publicCampaignModal && (
        <PublicAdvertModal
          show={publicCampaignModal}
          onHide={() => {
            setPublicCampaignModal(false);
          }}
          data={campaignData}
        />
      )}
    </div>
  );
};

const StyledFilterBar = styled.div`
          width: ${(props) => props?.width ?? '229px'};
  // height: ${(props) => (props?.variant ? '91vh !important' : 'none')};
          box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
          background-color: #fff;
          position: ${(props) => (props?.variant ? 'fixed' : 'absolute')};
          margin-top: ${(props) => (!props?.variant ? '35px' : 'none')};
          top: ${(props) => (props.variant ? '90px' : 'none')};
          right: 0;
          bottom: ${(props) => (props?.variant ? '0' : 'none')};
          z-index: 2;
          border-radius: ${(props) => (props?.variant ? '8px 0 0 0' : '14px')};
          transition: 0.5s;
          padding-bottom: 28px;

          .sd-header {
            display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 20px;
          color: ${({ theme }) => theme.textColor};
  }

          .sd-body {
            padding: 20px;
          height: 100%;
          width: 100%;
          position: relative;
          overflow: auto;
  }

          .sd-body ul {
            display: inline-block;
          width: 100%;
          margin-bottom: 0;
          padding: 0;
  }

          .sd-body ul li {
            list - style: none;
          margin-bottom: 8px;
  }
          `;

const StyledBgEffect = styled.div`
          &:hover {
            background - color: ${({ theme }) => theme.bodyBg};
  }
          `;

export default CampaignsListingContainer;
