import { Modal} from 'react-bootstrap';
import React, { useState } from 'react';
import { useAddNewCreditMutation } from '../../companyApiSlice';
import { useParams } from 'react-router-dom';
import PortletHeader from '../../../../app/components/portlet/PortletHeader';
import OccyCredits from './OccyCredits';
import OccyCurrency from './OccyCurrency';

const PurchaseCreditModal = ({ show, onHide, creditRefetch }) => {
  const { compId } = useParams();
  const [addCredit, { isLoading }] = useAddNewCreditMutation();

  const [tabs, setTabs] = useState([
    {
      id: 1,
      title: `Occy Credits`,
      active: true,
    },
    {
      id: 2,
      title: 'Currency',
      active: false,
    },
  ]);
  const activeTab = tabs.find((t) => t.active).id;

  const onChangeTabs = (tab) => {
    setTabs((prevState) =>
      prevState?.map((p) => {
        return p.id === tab
          ? {
              ...p,
              active: true,
            }
          : {
              ...p,
              active: false,
            };
      }),
    );
  };


  return (
    <Modal show={show} centered onHide={onHide} size={'lg'}>
      <Modal.Header
        closeButton
        className="border-bottom-0 pb-0 align-items-baseline"
      >
        <div>
          <Modal.Title
            as="h4"
            className="flex-grow-1 px-0 cursor-pointer flex-center fs-3 ps-2"
          >
            Add More
          </Modal.Title>

          <PortletHeader
            containerClassName="p-0"
            headerClassName="p-0 mx-2"
            tabs={tabs}
            onChangeTabs={onChangeTabs}
          />
        </div>
      </Modal.Header>
      <Modal.Body className="d-flex  flex-column bg-body rounded">
        {activeTab === 1 && <OccyCredits creditRefetch={creditRefetch} compId={compId} addCredit={addCredit} isLoading={isLoading} onHide={onHide}/>}
        {activeTab === 2 && <OccyCurrency creditRefetch={creditRefetch} compId={compId} addCredit={addCredit} isLoading={isLoading} onHide={onHide}/>}
      </Modal.Body>
    </Modal>
  );
};


export default PurchaseCreditModal;
