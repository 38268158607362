import React from 'react';

const CVLibraryIcon = ({ size = 48, ...props }) => (
  <div style={{ borderRadius: '4px', overflow: 'hidden', width: size, height: size }}>
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 48 48" version="1.1">
      <path d="M 20.081 12 C 16.329 16.314, 14.167 18, 12.386 18 C 8.732 18, 5 22.204, 5 26.320 C 5 31.135, 8.235 34.854, 13.102 35.632 C 15.278 35.980, 18.673 37.548, 20.647 39.116 C 22.716 40.761, 24.749 41.657, 25.450 41.234 C 27.965 39.714, 43 22.146, 43 20.727 C 43 18.905, 28.761 6, 26.750 6 C 25.953 6, 22.952 8.700, 20.081 12 M 8.457 21.453 C 5.441 24.468, 5.325 27.872, 8.115 31.419 C 9.833 33.603, 10.911 34.050, 13.865 33.804 C 16.295 33.601, 17.500 33.003, 17.500 32 C 17.500 31.013, 16.685 30.590, 15.117 30.762 C 12.039 31.100, 10 29.367, 10 26.413 C 10 23.952, 12.602 21.949, 14.784 22.730 C 15.490 22.982, 16.636 22.717, 17.331 22.140 C 18.297 21.339, 19.258 22.613, 21.407 27.546 C 22.953 31.096, 24.748 34, 25.395 34 C 26.848 34, 33.263 21.235, 32.462 19.938 C 31.258 17.990, 29.821 19.150, 27.811 23.695 L 25.734 28.390 23.460 23.917 C 21.246 19.559, 21.054 19.437, 16.050 19.220 C 11.756 19.033, 10.510 19.399, 8.457 21.453" stroke="none" fill="#f6f8f9" fillRule="evenodd" />
      <path d="M 0.461 1.242 C 0.186 1.959, 0.082 12.660, 0.230 25.023 L 0.500 47.500 23.375 47.769 C 39.407 47.958, 46.660 47.697, 47.625 46.897 C 48.718 45.989, 49 41.083, 49 22.944 L 49 0.132 24.980 0.036 C 6.096 -0.040, 0.854 0.218, 0.461 1.242 M 0.430 24 C 0.430 36.925, 0.573 42.212, 0.747 35.750 C 0.921 29.287, 0.921 18.712, 0.747 12.250 C 0.573 5.787, 0.430 11.075, 0.430 24 M 20.081 12 C 16.329 16.314, 14.167 18, 12.386 18 C 8.732 18, 5 22.204, 5 26.320 C 5 31.135, 8.235 34.854, 13.102 35.632 C 15.278 35.980, 18.673 37.548, 20.647 39.116 C 22.716 40.761, 24.749 41.657, 25.450 41.234 C 27.965 39.714, 43 22.146, 43 20.727 C 43 18.905, 28.761 6, 26.750 6 C 25.953 6, 22.952 8.700, 20.081 12 M 8.457 21.453 C 5.441 24.468, 5.325 27.872, 8.115 31.419 C 9.833 33.603, 10.911 34.050, 13.865 33.804 C 16.295 33.601, 17.500 33.003, 17.500 32 C 17.500 31.013, 16.685 30.590, 15.117 30.762 C 12.039 31.100, 10 29.367, 10 26.413 C 10 23.952, 12.602 21.949, 14.784 22.730 C 15.490 22.982, 16.636 22.717, 17.331 22.140 C 18.297 21.339, 19.258 22.613, 21.407 27.546 C 22.953 31.096, 24.748 34, 25.395 34 C 26.848 34, 33.263 21.235, 32.462 19.938 C 31.258 17.990, 29.821 19.150, 27.811 23.695 L 25.734 28.390 23.460 23.917 C 21.246 19.559, 21.054 19.437, 16.050 19.220 C 11.756 19.033, 10.510 19.399, 8.457 21.453" stroke="none" fill="#204c8a" fillRule="evenodd" />
    </svg>
  </div>
);

export default CVLibraryIcon;
