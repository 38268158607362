import React from 'react'

const briefCaseIcon = ({ size = 40 }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M35.15 11.6335C33.7333 10.0669 31.3833 9.28353 27.9333 9.28353H27.5333V9.21686C27.5333 6.41686 27.5333 2.9502 21.2667 2.9502H18.7333C12.4667 2.9502 12.4667 6.41686 12.4667 9.21686V9.28353H12.0667C8.61668 9.28353 6.25001 10.0669 4.85001 11.6335C3.20001 13.4835 3.25001 15.9335 3.41668 17.6169L3.43334 17.7335L3.56243 19.2395C3.57562 19.3934 3.65922 19.5326 3.78887 19.6167C4.30068 19.9484 4.85494 20.3066 5.38334 20.6002C5.61668 20.7502 5.86668 20.8835 6.11668 21.0169C8.00001 22.0502 10.0167 22.8669 12.0833 23.4335C12.1333 27.7502 15.6667 31.2502 20 31.2502C24.3667 31.2502 27.9167 27.7002 27.9167 23.3335V23.2669C30.0167 22.6335 32.0333 21.7502 33.9167 20.6502C34.0167 20.6002 34.0833 20.5502 34.1667 20.5002C34.8675 20.1193 35.5963 19.613 36.251 19.1469C36.3671 19.0643 36.4421 18.9358 36.4575 18.7941L36.5833 17.6335C36.6 17.5335 36.6 17.4502 36.6167 17.3335C36.75 15.6669 36.7167 13.3669 35.15 11.6335ZM14.85 9.21686C14.85 6.38353 14.85 5.31686 18.7333 5.31686H21.2667C25.15 5.31686 25.15 6.38353 25.15 9.21686V9.28353H14.85V9.21686ZM20 28.7502C17.25 28.7502 14.9667 26.6835 14.6333 24.0169C14.6 23.8002 14.5833 23.5669 14.5833 23.3335C14.5833 20.3502 17.0167 17.9169 20 17.9169C22.9833 17.9169 25.4167 20.3502 25.4167 23.3335C25.4167 23.5335 25.4 23.7169 25.3833 23.9002V23.9169C25.0833 26.6335 22.7833 28.7502 20 28.7502Z" fill="#C2D3EB" />
            <path d="M19.05 26.7166C18.7333 26.7166 18.4167 26.5999 18.1667 26.3499L16.5167 24.7166C16.0333 24.2333 16.0333 23.4333 16.5167 22.9499C17 22.4666 17.8 22.4666 18.2833 22.9499L19.0833 23.7499L21.7667 21.2833C22.2667 20.8166 23.0667 20.8499 23.5333 21.3499C24 21.8499 23.9667 22.6499 23.4667 23.1166L19.9167 26.3999C19.65 26.5999 19.35 26.7166 19.05 26.7166Z" fill="#C2D3EB" />
            <path d="M35.1856 22.8185C35.5491 22.6075 36.0289 22.9086 35.9906 23.3272L35.386 29.9352C35.036 33.2685 33.6693 36.6685 26.3359 36.6685H13.6359C6.30261 36.6685 4.93595 33.2685 4.60261 29.9518L4.00957 23.6019C3.97177 23.1971 4.40703 22.9194 4.76263 23.1165C4.82227 23.1496 4.87586 23.1788 4.91928 23.2018C6.21928 23.9185 7.56928 24.5518 8.95261 25.0518C9.46928 25.2352 9.83595 25.6685 9.98595 26.2018C11.2359 30.5352 15.2693 33.7518 20.0026 33.7518C24.8193 33.7518 28.8859 30.4685 30.0693 25.9685C30.2026 25.4352 30.5693 25.0018 31.086 24.8018C32.486 24.2518 33.8193 23.6018 35.0693 22.8852C35.0931 22.8719 35.1338 22.8485 35.1856 22.8185Z" fill="#C2D3EB" />
        </svg>

    )
}

export default briefCaseIcon