import React from 'react'
import styled from 'styled-components';
import ArrowIcon from '../../../app/components/svgIcons/ArrowIcon';
import { Wrap } from '../../../assets/styledComponents/Global'
import theme from '../../../utils/themes';

const CompanyStatusCard = ({ Icon, desc, no, growth , companyStats}) => {
    return (
        <StyledCard className='bg-white px-4 pt-4 pb-3 mx-2'>
            <Icon size="40" />
            <Wrap className='pt-4'>
                <p className='fs-5'>{desc}</p>
                <Wrap className='d-flex w-100 justify-content-between'>
                    <h4>{no}</h4>
                    <GrowthWrap growth={growth} className={`d-flex px-1 mb-1 align-items-center justify-centent-between rounded-2`}>
                        {growth && <><ArrowIcon rotate={growth?.arrow} /> <span className='text-'>{growth.percent}</span></>}
                    </GrowthWrap>
                </Wrap>
            </Wrap>
        </StyledCard>
    )
}

const StyledCard = styled.div`
  border-radius: 12px;
  width: 50%;
`;

const GrowthWrap = styled.div`
background-color: ${props => props.growth?.arrow && (props.growth?.arrow === 'up' ? theme.lightGreen : theme.lightOrange)}};
color: ${props => props.growth?.arrow && (props.growth?.arrow === 'up' ? theme.DarkGreen : theme.DarkOrange)}};
`;

export default CompanyStatusCard