import theme from "../../../utils/themes";
import styled from 'styled-components';

export const StyledIconBg = styled.div`
  height: 40px;
  width: 40px;
  background-color: ${theme.tagLightBlue};
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 12px;
`;
