

import { apiSlice } from '../../app/services/apiSlice';
const COMPANY_URL = 'backoffice/companies';
const PRODUCT_URL = 'backoffice/products';
const PUBLIC_URL = 'api'
export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addNewCompany: builder.mutation({
      query: (data) => ({
        url: `${COMPANY_URL}`,
        method: 'POST',
        body: {
          ...data,
        },
      }),
    }),
    revertCompanyTransaction: builder.mutation({
      query: (data) => ({
        url: `${COMPANY_URL}/revert-transaction`,
        method: 'PUT',
        body: {
          ...data,
        },
      }),
    }),
    getJobCategories: builder.mutation({
      query: (params) => ({
        url: `${COMPANY_URL}/jobCategory`,
        params,
      }),
      transformResponse: (responseData) => {
        responseData.options = responseData?.jobCatergories?.map((cat) => (
          {
            label: cat?.title,
            value: cat?.id
          }));
        return responseData || {};
      },
    }),
    getCounties: builder.mutation({
      query: (params) => ({
        url: `${PUBLIC_URL}/locations/counties`,
        params,
      }),
      transformResponse: (responseData) => {
        responseData?.length > 0 && responseData?.sort((a, b) => (a?.county > b?.county) * 2 - 1)
        responseData.options = responseData?.map((option) => (
          {
            label: option.county,
            value: option.county,
          }));
        return responseData || {};
      },
    }),
    getSettings: builder.mutation({
      query: (params) => ({
        url: `${COMPANY_URL}/settings`,
        params: { ...params, sort: 'accessKey', sortDir: 'ASC' },
      }),
      transformResponse: (responseData) => {
        responseData.options = responseData?.settings?.map((option) => (
          {
            label: option?.accessKey,
            value: option,
          }));
        return responseData || {};
      },
    }),
    getLicences: builder.mutation({
      query: (params) => ({
        url: `backoffice/licences`,
        params,
      }),
      transformResponse: (responseData) => {
        responseData.options = responseData?.licences?.map((option) => (
          {
            label: option?.name,
            value: option
          }
        ));
        return responseData || {};
      },
    }),
    getCompanyStats: builder.query({
      query: (data) => ({
        url: `${COMPANY_URL}/${data}/stats`,
      }),
    }),
    getCompanyLicenceDetails: builder.query({
      query: (data) => ({
        url: `${COMPANY_URL}/${data}/licences/active`,
      }),
    }),
    getNewCompanyLicenceDetails: builder.query({
      query: (data) => ({
        url: `${COMPANY_URL}/${data}/licences/new`,
      }),
    }),
    getCompanyInfo: builder.query({
      query: (compId) => ({
        url: `${COMPANY_URL}/${compId}`,
      }),
    }),
    getAllCompanies: builder.query({
      query: (params) => ({
        url: `${COMPANY_URL}`,
        params,
      }),
    }),
    getAllCompaniesList: builder.mutation({
      query: (params) => ({
        url: `${COMPANY_URL}`,
        params: { ...params, sort: 'name', sortDir: 'ASC' },
      }),
      transformResponse: (responseData) => {
        responseData.options = responseData?.companies?.map((cat) => (
          {
            label: cat?.name,
            value: cat?.id
          }));
        return responseData || {};
      },
    }),
    getCompanyProducts: builder.query({
      query: (data) => ({
        url: `${COMPANY_URL}/${data.compId}/products`,
        params: data.params,
      }),
    }),
    //  presigned upload
    preSignedUrl: builder.mutation({
      query: (data) => ({
        url: `${COMPANY_URL}/preSignedProfileImage`,
        method: 'POST',
        body: {
          ...data,
        },
      }),

    }),
    preSignedAgreementUrl: builder.mutation({
      query: (data) => ({
        url: `${COMPANY_URL}/${data.compId}/preSignedLicenceAgreement`,
        method: 'POST',
        body: {
          ...data?.body,
        },
      }),

    }),
    preSignedAttachmentUrl: builder.mutation({
      query: (data) => ({
        url: `/pre-create/media`,
        method: 'POST',
        body: {
          ...data,
        },
      }),
    }),
    postSignedAttachmentUrl: builder.mutation({
      query: (data) => ({
        url: `/post-create/media`,
        method: 'POST',
        body: {
          ...data,
        },
      }),
    }),
    preSignedProfileUrl: builder.mutation({
      query: (data) => ({
        url: `${COMPANY_URL}/${data.compId}/preSignedProfileImage`,
        method: 'POST',
        body: {
          fileName: data.fileName,
          fileType: data.fileType
        },
      }),
    }),
    updateCompanyProfileImage: builder.mutation({
      query: (data) => ({
        url: `company`,
        method: 'PUT',
        body: {
          ...data.compLOGOSignedUrl,
        },
      }),
    }),
    updateOnboardingHandOff: builder.mutation({
      query: (data) => ({
        url: `backoffice/on-boarding/hand-off-to-cs/${data?.id}`,
        method: 'PUT',
        body: {
          ...data.id,
        },
      }),
    }),
    updateCompanyGeneralInfo: builder.mutation({
      query: (data) => ({
        url: `${COMPANY_URL}/${data.compId}`,
        method: 'PUT',
        body: {
          ...data.values
        },
      }),
    }),
    changeCompanyAdmin: builder.mutation({
      query: (data) => ({
        url: `${COMPANY_URL}/${data.compId}/adminchange`,
        method: 'PUT',
        body: {
          ...data.adminId
        },
      }),
    }),
    updateCompanyLicence: builder.mutation({
      query: (data) => ({
        url: `${COMPANY_URL}/${data.compId}/licences/${data?.licenceId}`,
        method: 'PUT',
        body: {
          ...data.param
        },
      }),
    }),
    removeCompany: builder.mutation({
      query: (params) => ({
        url: `${COMPANY_URL}/${params}`,
        method: 'DELETE',
      }),
    }),
    removeCompanySetting: builder.mutation({
      query: (params) => ({
        url: `${COMPANY_URL}/${params?.compId}/settings/${params?.settingId}`,
        method: 'DELETE',
      }),
    }),
    suspendCompany: builder.mutation({
      query: (data) => ({
        url: `${COMPANY_URL}/${data.id}/statusChange`,
        method: 'POST',
        body: { statusId: data.statusId }
      }),
    }),
    addCompanySetting: builder.mutation({
      query: (data) => ({
        url: `${COMPANY_URL}/${data.compId}/settings/${data?.id}`,
        method: 'POST',
        body: data?.body,
      }),
    }),
    addSetting: builder.mutation({
      query: (data) => ({
        url: `${COMPANY_URL}/settings`,
        method: 'POST',
        body: data,
      }),
    }),
    updateSetting: builder.mutation({
      query: (data) => ({
        url: `${COMPANY_URL}/settings`,
        method: 'PUT',
        body: data,
      }),
    }),
    addSaveLicence: builder.mutation({
      query: (data) => ({
        url: `${COMPANY_URL}/${data.compId}/licences`,
        method: 'POST',
        body: data.body,
      }),
    }),
    getAllCompanySettings: builder.query({
      query: (data) => ({
        url: `${COMPANY_URL}/${data.id}/settings`,
        params: data.params,
      }),
    }),

    getAllSettings: builder.query({
      query: (data) => ({
        url: `${COMPANY_URL}/settings`,
        params: data.params,
      }),
    }),

    addNewCredit: builder.mutation({
      query: (data) => ({
        url: `${COMPANY_URL}/credits`,
        method: 'POST',
        body: {
          ...data,
        },
      }),
    }),

    getPurchaseHistory: builder.query({
      query: (data) => ({
        url: `${COMPANY_URL}/transactions?companyId=${data?.id}`,
        method: 'GET',
        params: data?.params
      }),
    }),
    getChildCompanies: builder.query({
      query: (data) => ({
        url: `${COMPANY_URL}/${data?.id}/child-companies`,
        method: 'GET',
        params: data?.params
      }),
    }),
    childCompaniesUpdate: builder.mutation({
      query: (data) => ({
        url: `${COMPANY_URL}/modify-subsidiary/${data?.id}`,
        method: 'PUT',
        body: data,
      }),
    }),
    getSubsidiaries: builder.query({
      query: (data) => ({
        url: `${COMPANY_URL}/subsidiaries?companyId=${data?.id}`,
        method: 'GET',
        params: data?.params
      }),
    }),


    getCreditDetails: builder.query({
      query: (id) => ({
        url: `${COMPANY_URL}/credits?companyId=${id}`,
      }),
    }),
    addCompanyProduct: builder.mutation({
      query: (data) => ({
        url: `${COMPANY_URL}/${data.compId}/products`,
        method: 'POST',
        body: data.data,
      }),
    }),
    getAllProductsList: builder.mutation({
      query: (params) => ({
        url: `${PRODUCT_URL}`,
        params: { ...params, sort: 'title', sortDir: 'ASC' },
      }),
      transformResponse: (responseData) => {
        responseData.options = responseData?.products?.map((prd) => (
          {
            ...prd,
            label: prd?.title,
            value: prd?.id
          }));
        return responseData || {};
      },
    }),
    getOnBoardingListing: builder.query({
      query: (data) => ({
        url: `backoffice/on-boarding`,
        params:data.params,
      }),
    }),
    getOnBoardingDetails: builder.query({
      query: (data) => ({
        url: `backoffice/on-boarding/user/${data?.id}`,
      }),
    }),
    getSetupOnBoardingDetails: builder.query({
      query: (data) => ({
        url: `backoffice/on-boarding/wizard-details/${data.compId}`,
      }),
    }),
    deleteCompanyProduct: builder.mutation({
      query: (params) => ({
        url: `${COMPANY_URL}/${params.compId}/products/${params.productId}`,
        method: 'DELETE',
      }),
    }),
  }),
});
export const {
  useRevertCompanyTransactionMutation,
  useUpdateOnboardingHandOffMutation,
  useGetSetupOnBoardingDetailsQuery,
  useGetOnBoardingDetailsQuery,
  useGetOnBoardingListingQuery,
  useDeleteCompanyProductMutation,
  useGetAllProductsListMutation,
  useAddCompanyProductMutation,
  useAddNewCompanyMutation,
  useGetJobCategoriesMutation,
  useGetCountiesMutation,
  useGetSettingsMutation,
  useGetLicencesMutation,
  useGetCompanyStatsQuery,
  useGetCompanyLicenceDetailsQuery,
  useGetNewCompanyLicenceDetailsQuery,
  usePreSignedAgreementUrlMutation,
  useGetCompanyInfoQuery,
  useGetAllCompaniesQuery,
  useAddCompanySettingMutation,
  useChildCompaniesUpdateMutation,
  useGetCompanyProductsQuery,
  usePreSignedUrlMutation,
  usePreSignedAttachmentUrlMutation,
  usePostSignedAttachmentUrlMutation,
  usePreSignedProfileUrlMutation,
  useUpdateCompanyProfileImageMutation,
  useUpdateCompanyGeneralInfoMutation,
  useUpdateCompanyLicenceMutation,
  useRemoveCompanyMutation,
  useRemoveCompanySettingMutation,
  useGetAllCompanySettingsQuery,
  useGetAllSettingsQuery,
  useGetChildCompaniesQuery,
  useSuspendCompanyMutation,
  useChangeCompanyAdminMutation,
  useAddSettingMutation,
  useUpdateSettingMutation,
  useGetAllCompaniesListMutation,
  useAddSaveLicenceMutation,
  useAddNewCreditMutation,
  useGetPurchaseHistoryQuery,
  useGetSubsidiariesQuery,
  useGetCreditDetailsQuery
} = extendedApiSlice;
