import React from 'react';

const SaveIcon = ({ size ='18px' }) => (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_2606_9242)">
    <path d="M7.33325 4.33325V6.83325C7.33325 7.66168 8.00482 8.33325 8.83325 8.33325H10.8333C11.6617 8.33325 12.3333 7.66168 12.3333 6.83325V4.33325H12.7119C13.2424 4.33325 13.7511 4.54397 14.1261 4.91904L15.7475 6.54036C16.1225 6.91543 16.3333 7.42414 16.3333 7.95457V14.3333C16.3333 15.4378 15.4378 16.3333 14.3333 16.3333V11.8333C14.3333 11.0048 13.6617 10.3333 12.8333 10.3333H7.83325C7.00595 10.3333 6.33325 11.002 6.33325 11.8315V16.3332C5.22874 16.3332 4.33325 15.4378 4.33325 14.3333V6.33325C4.33325 5.22868 5.22868 4.33325 6.33325 4.33325H7.33325ZM8.33325 4.33325V6.83325C8.33325 7.10939 8.55711 7.33325 8.83325 7.33325H10.8333C11.1094 7.33325 11.3333 7.10939 11.3333 6.83325V4.33325H8.33325ZM7.33325 16.3333H13.3333V11.8333C13.3333 11.5571 13.1094 11.3333 12.8333 11.3333H7.83325C7.55598 11.3333 7.33325 11.5565 7.33325 11.8315V16.3333Z" fill="white" />
    </g>
    <defs>
    <clipPath id="clip0_2606_9242">
    <rect width="13.3333" height="13.3333" fill="white" transform="translate(3.33325 3.33325)"/>
    </clipPath>
    </defs>
    </svg>
    
    
);

SaveIcon.default = {
  size: 14,
};
export default SaveIcon;
