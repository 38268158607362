import { Modal } from 'react-bootstrap';
import React from 'react';
import theme from '../../../../utils/themes';
import StyledButton from '../../../../app/components/styledComponents/StyledButton';
import { StyledLabel } from '../../../../assets/styledComponents/Global';
import PortletBody from '../../../../app/components/portlet/PortletBody';
import * as Yup from 'yup';
import { Formik,Form } from 'formik';
import StyledColoredTag from '../../../../app/components/styledComponents/StyledColoredTag';
import CandidatesIcon from '../../../../app/components/svgIcons/CandidatesIcon';
import FormikInputField from '../../../../app/components/formikComponents/FormikInputField';

const RevertCommentModal = ({ show, onHide, onSubmit, loading}) => {
    const handleSubmit = (res) => {
      onSubmit(res)
    };
    const rejectionModalSchema = Yup.object().shape({
      comment: Yup.string().required('Required'),
    });
    return (
    <>
      <Modal backdrop="static" show={show} centered onHide={onHide} size={'lg'}>
          <Formik
            enableReinitialize
            initialValues={{
              comment: '',
            }}
            validationSchema={rejectionModalSchema}
            onSubmit={handleSubmit}
          >
            {({ handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                <Modal.Header
                  closeButton
                  className="border-bottom-0 pb-2 align-items-baseline"
                >
                  <Modal.Title as="h2" className="flex-grow-1 p-2 pb-0 ms-1">
                    {'Revert Request'}
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body className="d-flex flex-column bg-body rounded px-3">
                  <PortletBody className="text-start p-md-4 mx-2 rounded-3">
                    <div className="d-flex align-items-center gap-3">
                      <StyledColoredTag
                        backgroundColor={theme.primaryLight}
                        className="mt-1 p-2 fw-800 rounded mx-0"
                      >
                        <CandidatesIcon color={theme.textColor} />
                      </StyledColoredTag>
                      <StyledLabel className="" size="16px">
                      Please provide reason to revert
                      </StyledLabel>
                    </div>

                    <div className="p-2 mt-2">
                      <div>
                        <FormikInputField
                          name="comment"
                          id="comment"
                          placeholder="Enter reason for revert"
                          as="textarea"
                          rows="6" />
                      </div>
                    </div>
                  </PortletBody>
                  <div className="d-flex justify-content-end mt-3">
                    <div className="d-flex gap-3">
                      <StyledButton
                        className="mb-1"
                        type="submit"
                        loading={loading}
                        disabled={loading}
                      >
                        Submit
                      </StyledButton>
                    </div>
                  </div>
                </Modal.Body>
              </Form>
            )}
          </Formik>
        </Modal></>
  );
};

export default RevertCommentModal;
