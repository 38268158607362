import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import Portlet from '../../../../../app/components/portlet/Portlet';
import PortletHeader from '../../../../../app/components/portlet/PortletHeader';
import { Wrap } from '../../../../../assets/styledComponents/Global';
import { PRODUCT_TYPE } from '../../../../../utils/Statuses';
import { useGetSingleProductByIdMutation } from '../../../productApiSlice';
import BundlesView from './BundlesView';
import JobBoardView from './JobBoardView';
import ProductDetailsView from './ProductDetailsView';

const ProductEditDetails = () => {
  const { productId } = useParams();
  const [productDetails, setProductDetails] = useState();
  const [getSinglePorduct] = useGetSingleProductByIdMutation();
  const [activeTab, setActiveTab] = useState(1);
  const [tabs, setTabs] = useState([
    {
      id: 1,
      title: 'Product Details',
      active: true,
      status: PRODUCT_TYPE.DETAILS,
    },
    {
      id: 2,
      title: `${productDetails?.product.productType === 'OTHER' ? '' : 'Job Boards'}`,
      active: false,
      status: PRODUCT_TYPE.JOB_BOARDS,
    },
    {
      id: 3,
      title: 'Bundles',
      active: false,
      status: PRODUCT_TYPE.BUNDLES,
    }
  ]);
  const getProductDetails = () => {
    getSinglePorduct(productId).then(({ data: resp }) => {
      setProductDetails(resp);
      if (resp?.product.productType === 'OTHER') {
        setTabs((prevState) => prevState.filter((p) => p.id !== 2));
      } else {
        setTabs((prevState) => prevState.filter((p) => p.id !== 3));
      };
    });
  }
  useEffect(() => {
    getProductDetails();
  }, [productId]);

  const onChangeTabs = (tab) => {
    setActiveTab(tab);
    setTabs((prevState) =>
      prevState.map((p) => {

        return p.id === tab
          ? {
            ...p,
            active: true,
          }
          : {
            ...p,
            active: false,
          };
      }),
    );
  };
  return (
    <><Helmet>
      <title>{_.capitalize(productDetails?.product?.title) + ' - occy ADMIN'}</title>
    </Helmet>
    <Portlet>
        <PortletHeader
          tabs={tabs}
          title='Product Details view'
        onChangeTabs={onChangeTabs}
      />

        {activeTab === 1 && (
          <div className="portlet__body-bg">
            <Wrap className="d-flex mb-3 justify-content-center ">
              <ProductDetailsView productDetails={productDetails} />
            </Wrap>
          </div>
        )}
        {activeTab === 2 && (
          <div className="portlet__body-bg">
            <Wrap className="d-flex mb-3 justify-content-center">
              <JobBoardView productDetails={productDetails} />
            </Wrap>
          </div>
        )}
        {activeTab === 3 && (
          <div className="portlet__body-bg">
            <Wrap className="d-flex mb-3 justify-content-center">
              <BundlesView productDetails={productDetails} />
            </Wrap>
          </div>
        )}
      </Portlet></>
  );
};


export default ProductEditDetails;