import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Modal, Row } from 'react-bootstrap';
import PortletBody from '../../../app/components/portlet/PortletBody';
import StyledButton from '../../../app/components/styledComponents/StyledButton';
import * as Yup from 'yup';
import FormikInputField from '../../../app/components/formikComponents/FormikInputField';
import FormikSelect from '../../../app/components/formikComponents/FormikSelect';
import { useAddNewUserMutation, useEditNewUserMutation, useGetUserRolesQuery } from '../userApiSlice';
import FormikAsyncSelect from '../../../app/components/formikComponents/FormikAsyncSelect';
import { toast } from 'react-toastify';
import { findPhoneNumbersInText } from 'libphonenumber-js';
import { countryCodes } from '../../Products/assets/utills';

const AddUserModal = ({ show, editData, onHide, title, id, refetch }) => {
  const [addNewUser, { isLoading: addLoading }] = useAddNewUserMutation()
  const [editNewUser, { isLoading: editLoading }] = useEditNewUserMutation()
  let roles = editData?.roles?.map(item => ({ value: item.id, label: item.title }))
  const [phoneNumber, setPhoneNumber] = useState([])
  const [phoneNumber2, setPhoneNumber2] = useState([])
  const { data } = useGetUserRolesQuery(id)

  const addUserSchema = Yup.object().shape({
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    email: Yup.string().email().required('Required'),
    phone: Yup.string().when('phoneCountryCode', (phoneCountryCode, schema, val) => val?.parent?.phone ? schema.test('is-valid-phone', 'Invalid Number', (value) => findPhoneNumbersInText(`${phoneCountryCode}${value}`)?.length > 0 && !value?.includes('+')) : schema).nullable(),
    phone2: Yup.string().when('phoneCountryCode2', (phoneCountryCode2, schema, val) => val?.parent?.phone2 ? schema.test('is-valid-phone', 'Invalid Number', (value) => findPhoneNumbersInText(`${phoneCountryCode2}${value}`)?.length > 0 && !value?.includes('+')) : schema).nullable(),
    //SROEmail: Yup.string().email().required('Required'),
    accountType: Yup.string().required('Required'),
    role: Yup.array().min(1, "required"),
  });

  const handleSubmit = (values) => {
    values = { ...values };
    values.phone = values?.phone && values?.phoneCountryCode + (values?.phone[0] === '0' ? values?.phone.substring(1) : values?.phone);
    values.phone2 = values?.phone2 && values?.phoneCountryCode2 + (values?.phone2[0] === '0' ? values?.phone2.substring(1) : values?.phone2);
    values.phoneCountryCode = undefined;
    values.phoneCountryCode2 = undefined;
    values.roles = values?.roles?.map(item => item.value)
    const delRoles = editData?.roles?.filter(item => !values?.roles.includes(item.id) && item.id)
    values.delRoles = delRoles?.map(item => item.id)
    if (values?.accountType === 'Guest User') {
      values.delRoles = roles?.map(item => item.value)
      values.roles = [];
    }
    values.accountType = values?.accountType === 'Guest User' ? 0 : 1;
    const sendObj = {
      body: values,
      compId: id
    }
    editData ? editNewUser({ ...sendObj, user: { ...editData } }).unwrap().then((data) => { toast.success(data?.message); onHide(); refetch() }) :
      addNewUser(sendObj).unwrap().then((data) => { toast.success(data?.message); onHide(); refetch() })
  };
  const AccountTypes = [
    // {
    //   value: 'Guest User',
    //   label: 'Guest User',
    //   // data: `${data?.basic}/${licenceData?.data?.licence?.maxBasicAccounts} left`,
    //   color: 'green',
    // },
    {
      value: 'Standard Account',
      label: 'Standard Account',
      // data: `${data?.standard}/${licenceData?.data?.licence?.maxAccounts} left`,
      color: 'orange',
    },
  ];
  const [account, setAccount] = useState('');
  const handleSelectAccount = (evt) => {
    setAccount(evt?.accountType);
  };

  useEffect(() => {
    editData?.phone && setPhoneNumber(findPhoneNumbersInText(editData?.phone))
    editData?.mobile && setPhoneNumber2(findPhoneNumbersInText(editData?.mobile))
  }, [editData])

  return (
    <Modal show={show} centered onHide={onHide} size={'lg'}>
      <Formik
        initialValues={{
          firstName: editData?.firstName ?? '',
          lastName: editData?.lastName ?? '',
          email: editData?.email ?? '',
          // SROEmail: editData?.SROEmail ?? '',
          phone: phoneNumber[0]?.number?.nationalNumber ?? editData?.phone,
          phoneCountryCode: phoneNumber[0]?.number?.countryCallingCode ? `+${phoneNumber[0]?.number?.countryCallingCode}` : "+44",
          phone2: phoneNumber2[0]?.number?.nationalNumber ?? editData?.mobile,
          phoneCountryCode2: phoneNumber2[0]?.number?.countryCallingCode ? `+${phoneNumber2[0]?.number?.countryCallingCode}` : "+44",
          accountType:
            editData?.accountType === 0
              ? 'Guest User'
              : editData?.accountType === 1
                ? 'Standard Account'
                : '',
          roles,
        }}
        validationSchema={addUserSchema}
        onSubmit={handleSubmit}
      >
        {({ handleSubmit, values }) => (
          <Form onSubmit={handleSubmit} onChange={handleSelectAccount(values)}>
            <Modal.Header
              closeButton
              className="border-bottom-0 pb-0 align-items-baseline"
            >
              <Modal.Title
                as="h4"
                className="flex-grow-1 p-2 cursor-pointer flex-center"
              >
                {show && title}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="d-flex  flex-column bg-body rounded">
              <PortletBody className="p-0 w-100">
                <PortletBody className={'p-3 w-100'}>
                  <Row>
                    <div className="col-12 col-md-6">
                      <div className="mb-3 ">
                        <label htmlFor="firstName" className="form-label">
                          First Name
                        </label>
                        <FormikInputField
                          name="firstName"
                          id="firstName"
                          placeholder="Enter First Name"
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-6 ">
                      <div className="mb-3">
                        <label htmlFor="lastName" className="form-label">
                          Last Name
                        </label>
                        <FormikInputField
                          name="lastName"
                          id="lastName"
                          placeholder="Enter Last Name"
                        />
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="mb-3 ">
                        <label htmlFor="email" className="form-label">
                          Email
                        </label>
                        <FormikInputField
                          name="email"
                          id="email"
                          type="email"
                          placeholder="Enter Email"
                        />
                      </div>
                    </div>
                    {/* <div className="col-12 col-md-6">
                      <div className="mb-3">
                        <label htmlFor="SROEmail" className="form-label">
                          SRO Email
                        </label>
                        <FormikInputField
                          name="SROEmail"
                          id="SROEmail"
                          placeholder="Enter Email"
                        />
                      </div>
                    </div> */}

                    {/* <div className="col-12 col-md-6">
                      <div className="mb-3">
                        <label htmlFor="phone" className="form-label">
                          Phone
                        </label>{' '}
                        <FormikInputField
                          name="phone"
                          id="phone"
                          placeholder="Enter Phone"
                          type="tel"
                        />
                      </div>
                    </div> */}
                    <div className="col-12 col-md-6">
                      <label
                        htmlFor={`phoneCountryCode`}
                        className="form-label"
                      >
                        Phone Number <span className="ms-1 text-muted">(optional)</span>
                      </label>
                      <div className='row'>
                        <div className="col-4 pe-1">
                          <div className="mb-3">
                            <FormikSelect
                              className="me-0 pe-0"
                              name={`phoneCountryCode`}
                              id={`phoneCountryCode`}
                              placeholder="+44"
                              options={countryCodes}
                            />
                          </div>
                        </div>
                        <div className="col-8 ps-0">
                          <div className="mb-3">
                            <FormikInputField
                              name={`phone`}
                              id={`phone`}
                              placeholder="Enter Phone"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-12 col-md-6">
                      <label
                        htmlFor={`phoneCountryCode`}
                        className="form-label"
                      >
                        Phone Number <span className="ms-1 text-muted">(optional)</span>
                      </label>
                      <div className='row'>
                        <div className="col-4 pe-1">
                          <div className="mb-3">
                            <FormikSelect
                              className="me-0 pe-0"
                              name={`phoneCountryCode2`}
                              id={`phoneCountryCode2`}
                              placeholder="+44"
                              options={countryCodes}
                            />
                          </div>
                        </div>
                        <div className="col-8 ps-0">
                          <div className="mb-3">
                            <FormikInputField
                              name={`phone2`}
                              id={`phone2`}
                              placeholder="Enter Phone 2"
                            />
                          </div>
                        </div>
                      </div>
                    </div>


                    <div className="col-12 col-md-6">
                      <div className="mb-3">
                        <label htmlFor="accountType" className="form-label">
                          Account Type
                        </label>
                        <FormikSelect
                          id="accountType"
                          name="accountType"
                          options={AccountTypes}
                          placeholder="Select Account type"
                        />
                      </div>
                    </div>

                    {account === 'Standard Account' &&
                      <div className="col-12 col-md-6">
                        <div className="mb-3">
                          <label htmlFor="role" className="form-label">
                            Role
                          </label>
                          <FormikAsyncSelect
                            isMulti={true}
                            isSearchable={false}
                            name="roles"
                            id="roles"
                            placeholder="Select Role"
                            options={data}
                            type="eventTeam"
                          />
                        </div>
                      </div>}
                  </Row>
                </PortletBody>
              </PortletBody>
              <div className="d-flex justify-content-end mt-3">
                <div className="d-flex gap-3">
                  <StyledButton
                    variant={'text'}
                    className="mb-2 border-0 fw-bold"
                    onClick={onHide}
                  >
                    Cancel
                  </StyledButton>
                  <StyledButton className="mb-2" type="submit" loading={editData ? editLoading : addLoading} disabled={editData ? editLoading : addLoading}>
                    Send Invite
                  </StyledButton>
                </div>
              </div>
            </Modal.Body>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default AddUserModal;
