import { createSlice } from '@reduxjs/toolkit';
import { occyLs } from '../../../utils/localStorage';

const lsJobData = occyLs.getObject('job_data') || {};
const lsActiveTab = occyLs.getObject('active_tab') || 0;
const lsJobTemplate = occyLs.getObject('job_Template') || 0;

const slice = createSlice({
  name: 'jobCreation',
  initialState: {
    job: { ...lsJobData },
    jobTemplate: { ...lsJobTemplate },
    activeTab: lsActiveTab,
  },
  reducers: {
    setJobData: (state, { payload: { values } }) => {
      state.job = { ...values };
      occyLs.setObject('job_data', { ...values });
    },
    resetJobData: (state) => {
      state.job = {};
      occyLs.setObject('job_data', {});
    },
  },
});

export const { setJobData, setActiveTab, resetJobData } = slice.actions;

export default slice.reducer;

export const getJobCreationState = (state) => state.createJob.job;
