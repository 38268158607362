import React from 'react';

const MonsterImgIcon = ({ size = 14, ...props }) => {
  const svgStyle = {
    shapeRendering: 'geometricPrecision',
    textRendering: 'geometricPrecision',
    imageRendering: 'optimizeQuality',
    fillRule: 'evenodd',
    clipRule: 'evenodd',
    borderRadius: '10px',
  };

  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        width={size}
        height={size}
        style={svgStyle}
        xmlnsXlink="http://www.w3.org/1999/xlink"
        {...props}
      >
        <g>
          <path
            style={{ opacity: 0.999 }}
            fill="#7c51c0"
            d="M 6.5,-0.5 C 18.1667,-0.5 29.8333,-0.5 41.5,-0.5C 45.3255,1.827 47.6589,5.16033 48.5,9.5C 48.5,18.8333 48.5,28.1667 48.5,37.5C 47.6589,41.8397 45.3255,45.173 41.5,47.5C 29.8333,47.5 18.1667,47.5 6.5,47.5C 3.57608,46.2467 1.24275,44.2467 -0.5,41.5C -0.5,29.5 -0.5,17.5 -0.5,5.5C 1.24275,2.75333 3.57608,0.753325 6.5,-0.5 Z M 12.5,13.5 C 16.1774,17.0106 20.0107,20.3439 24,23.5C 27.62,20.8832 30.9533,17.8832 34,14.5C 34.4996,21.1583 34.6663,27.825 34.5,34.5C 33.1667,34.5 31.8333,34.5 30.5,34.5C 30.8226,30.774 30.4892,27.1073 29.5,23.5C 27.5,25.1667 25.5,26.8333 23.5,28.5C 21.5,26.8333 19.5,25.1667 17.5,23.5C 16.5108,27.1073 16.1774,30.774 16.5,34.5C 15.1667,34.5 13.8333,34.5 12.5,34.5C 12.5,27.5 12.5,20.5 12.5,13.5 Z"
          />
        </g>
      </svg>
    </div>
  );
};

export default MonsterImgIcon;
