import React from 'react';
import Table from '../../../app/components/table/Table';

const ProductListingTable = ({
  templates,
  sortColumn,
  onSort,
  filterTable,
  key
}) => {
  return (
    <>
      <Table
        data={templates.map((j) => {
          const account = j ? j : undefined;
          return { ...j, account: account };
        })}
        columns={filterTable}
        sortColumn={sortColumn}
        onSort={onSort}
      />
    </>
  );
};

export default ProductListingTable;
