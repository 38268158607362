
import Portlet from '../../../app/components/portlet/Portlet';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Link, useParams } from 'react-router-dom';
import { isEqual } from 'lodash';
import moment from 'moment';
import { Form, Formik } from 'formik';
import { CSVLink } from 'react-csv';
import StyledToolTip from '../../../app/components/styledComponents/StyledToolTip';
import CrossIcon from '../../../app/components/svgIcons/CrossIcon';
import PortletHeader from '../../../app/components/portlet/PortletHeader';
import PaginationPerPage from '../../../app/components/pagination/PaginationPerPage';
import TableSkeleton from '../../../assets/skeletons/tableSkeleton';
import PortletBody from '../../../app/components/portlet/PortletBody';
import StyledIconInput from '../../../app/components/styledComponents/StyledIconInput';
import SearchIcon from '../../../app/components/svgIcons/SearchIcon';
import StyledButton from '../../../app/components/styledComponents/StyledButton';
import FilterIcon from '../../../app/components/svgIcons/FilterIcon';
import SortIcon from '../../../app/components/svgIcons/SortIcon';
import StyledRadioButton from '../../../app/components/radio/StyledRadioButton';
import PauseIcon from '../../../app/components/svgIcons/PauseIcon';
import StyledCheckbox from '../../../app/components/checkbox/StyledCheckbox';
import StyledFilterTabs from '../../../app/components/styledComponents/StyledFilterTabs';
import NewDownloadIcon from '../../../app/components/svgIcons/NewDownloadIcon';
import CopyWritingListingTable from './CopyWritingListingTable';
import { CopywriteSort } from '../assets/utils';
import EditIcon from '../../../app/components/svgIcons/EditIcon';
import { StyledLabel } from '../../../assets/styledComponents/Global';
import theme from '../../../utils/themes';
import StyledColoredTag from '../../../app/components/styledComponents/StyledColoredTag';
import CopyWritingFilterForm from './CopyWritingFilterForm';
import { useGetAllCopywriteRequestQuery } from '../assets/copyWriteApiSlice';
import EyeIcon from '../../../app/components/svgIcons/EyeIcon';

const CopyWritingContainer = () => {
  const { compId } = useParams();
  const [sortFilter, setSortFilter] = useState(false);
  const [appliedValues, setApplyedValues] = useState({});
  const [sorted, setSorted] = useState(null);
  const [isOpen, setIsopen] = useState(false);
  const [filters, setFilters] = useState('');
  const [selectedColumns, setSelectedColumns] = useState(false);
  const sortFilterRef = useRef(null);
  const selectColumnRef = useRef(null);
  const filterRef = useRef(null);
  const ref = useRef();
  const initialFilters = {
    jobs: [],
    perPage: 5,
    page: 1,
    sortedColumn: { path: 'requestedOn', order: 'DESC' },
    query: '',
  };
  const [listing, setListing] = useState({ ...initialFilters });

  const {
    data: copywriteResponse,
    refetch,
    isFetching,
    error,
  } = useGetAllCopywriteRequestQuery(
    {
      perPage: listing.perPage,
      page: listing.page,
      query: listing.query && listing.query,
      sort: listing.sortedColumn.path || filters.sort,
      sortDir: listing.sortedColumn.order || filters.sortDir,
      ...filters,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const [timer, setSearchTimer] = useState(null);
  const handleSearch = (evt) => {
    clearTimeout(timer);
    const query = evt.target.value;
    const newTimer = setTimeout(() => {
      setListing({ ...listing, page: 1, query: query });
    }, 2000);
    setSearchTimer(newTimer);
  };
  const handleSort = (sort) => {
    const sortBlacklist = ['hired', 'statusId', 'account.firstName'];
    if (!sortBlacklist.includes(sort.path)) {
      setListing((prevState) => ({
        ...prevState,
        sortedColumn: { ...sort },
        page: 1,
      }));
    }
  };
  const handlePerPageChange = (perPage) => {
    setListing((prevState) => ({
      ...prevState,
      perPage: perPage.target.innerText,
      page: 1,
    }));
  };

  const handlePageChange = ({ selected }) => {
    setListing((prevState) => ({
      ...prevState,
      page: selected + 1,
    }));
  };
  const getStatusProperties = (status) => {
    switch (status) {
      case 'REQUESTED':
        return {
          label: 'Requested',
          textColor: theme.blue,
          bgColor: theme.lightSkyBlue,
        };
      case 'IN_PROGRESS':
        return {
          label: 'In progress',
          textColor: theme.darkOrange,
          bgColor: theme.darkYellow,
        };
      case 'IN_REVIEW':
        return {
          label: 'In review',
          textColor: theme.primary,
          bgColor: theme.primaryLight,
        };
      case 'COMPLETED':
        return {
          label: 'Completed',
          textColor: theme.green,
          bgColor: theme.textGreen,
        };
      case 'REVISION_REQUESTED':
        return {
          label: 'Revision Requested',
          textColor: theme.blue,
          bgColor: theme.lightSkyBlue,
        };
      default:
        return {
          label: '-',
          textColor: '-',
          bgColor: '-',
        };
    }
  };
  const dateConversion = (date) => {
    const timestamp = moment(date);
    const formattedDate = timestamp.format('Do MMM YYYY [at] h:mm a');
    return formattedDate;
  }
  const [newColumn, setNewColumn] = useState([]);
  const [column, setColumn] = useState([
    {
      id: 1,
      label: 'Title',
      isChecked: true,
      alignBody: 'text-left align-middle ps-4 py-3',
      alignHeader: 'text-left align-middle cursor-pointer ps-4 py-3',
      content: (data) => (
        <Link className="text-decoration-none">
          <h5 className="m-0"> {data?.job?.title || '-'}</h5>
          {/* <StyledH5 className="m-0">
            {data?.job?.title?.length > 15 ? (
              <StyledToolTip title={data?.job?.title} childrenClass="ms-0">
                {data?.job?.title.substring(0, 22) || ''}
                {data?.job?.title.length > 22 && '....'}
              </StyledToolTip>
            ) : (
              <>{data?.job?.title || '-'}</>
            )}
          </StyledH5> */}
        </Link>
      ),
    },
    {
      id: 2,
      isChecked: true,
      label: 'Company',
      content: (data) => (
        <>
          <StyledLabel weight="500" size="14" color={theme.textColor}>
            {data?.company?.name || '-'}
          </StyledLabel>
        </>
      ),
      alignBody: 'text-left ps-4 py-3 align-middle',
      alignHeader:
        'text-left align-middle cursor-pointer ps-4 py-3 text-nowrap',
    },
    {
      id: 3,
      isChecked: true,
      label: 'Type',
      content: (data) => (
        <StyledLabel className='text-center' weight="500" size="14" color={theme.textColor}>
          {data?.job?.statusId === 52 ? 'Job Template ' : 'Job' || '-'}
        </StyledLabel>
      ),
      alignBody: 'text-left align-middle ps-4 py-3',
      alignHeader:
        'text-left align-middle cursor-pointer ps-4 py-3 text-nowrap',
    },
    {
      id: 4,
      isChecked: true,
      label: 'Reference Number',
      content: (data) => (
        <StyledLabel className='text-center' weight="500" size="14" color={theme.textColor}>
          {data?.job?.reference || '-'}
        </StyledLabel>
      ),
      alignBody: 'text-left align-middle ps-4 py-3',
      alignHeader:
        'text-left align-middle cursor-pointer ps-4 py-3 text-nowrap',
    },
    {
      id: 5,
      isChecked: true,
      label: 'Requested on',
      content: (data) => (
        <>
          <StyledLabel weight="500" size="14" color={theme.textColor}>
            {dateConversion(data?.createdAt)}
          </StyledLabel>
        </>
      ),
      alignBody: 'text-left align-middle ps-4 py-3',
      alignHeader:
        'text-left align-middle cursor-pointer ps-4 py-3 text-nowrap',
    },
    {
      id: 6,
      isChecked: true,
      label: 'Status',
      content: (data) => {
        const { label, textColor, bgColor } = getStatusProperties(data?.job?.copywriteStatus);
        return (
          <StyledColoredTag backgroundColor={bgColor} color={textColor} $width='max-content' $marginX='0'>
            {label}
          </StyledColoredTag>
        );
      },
      alignBody: 'text-left align-middle ps-4 py-3',
      alignHeader: 'text-left align-middle cursor-pointer ps-4 py-3 text-nowrap',
    },
    {
      id: 7,
      isChecked: true,
      label: 'Actions',
      alignBody: 'text-center align-middle text-nowrap ps-4 py-3',
      alignHeader:
        'text-center align-middle cursor-pointer ps-4 py-3 text-nowrap',
      content: (data) => (
        <>
          <div className="d-flex justify-content-center align-items-center">
            <Link
              variant="text"
              className="text-decoration-none  pe-2"
              to={`${data?.job?.id}`}
            >
              {data?.job?.copywriteStatus === 'IN_REVIEW' || data?.job?.copywriteStatus === 'COMPLETED' ?
                <StyledToolTip title="View Copywrite" className="position-fixed" childrenClass={'ms-0 '}>
                  <EyeIcon />
                </StyledToolTip>
                :
                <StyledToolTip title="Edit Copywrite" className="position-fixed" childrenClass={'ms-0 '}>
                  <EditIcon size={18} />
                </StyledToolTip>
              }
            </Link>
          </div>
        </>
      ),
    },
  ]);

  let ToggleFilterbar = (item) => {
    setIsopen(item === 'filter' ? !isOpen : false);
    setSortFilter(item === 'sort' ? !sortFilter : false);
    setSelectedColumns(item === 'selected' ? !selectedColumns : false);
  };

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setSortFilter(false);
          setSelectedColumns(false);
          setIsopen(false);
        }
      }

      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }

  useOutsideAlerter(
    (isOpen && filterRef) ||
    (sortFilter && sortFilterRef) ||
    (selectedColumns && selectColumnRef),
  );

  const handleSubmit = async (values) => {
    setApplyedValues({ ...values });
    setFilters((prevFilters) => ({
      ...prevFilters,
      ...values,
      jobId: values?.jobId?.value,
      companyId: values?.companyId?.value,
    }));
    setIsopen(false);
  };

  const handleCHeck = (item) => {
    const tempColumn = [...column];
    const newItemIndex = column.findIndex((t) => t.id === item.id);
    tempColumn[newItemIndex].isChecked = !tempColumn[newItemIndex].isChecked;
    setColumn(tempColumn);
  };

  const handleSelectedColumn = () => {
    const newArr = [];
    column.map((item) => item.isChecked && newArr.push(item));
    setNewColumn(newArr);
    setSelectedColumns(false);
  };

  useEffect(() => {
    handleSelectedColumn();
    refetch();
  }, []);

  const handleSortBy = (item) => {
    setSorted(item.match);
    const prevFilters = { ...filters };
    prevFilters.sort = item.value;
    prevFilters.sortDir = item.dir;
    setFilters({ ...prevFilters });
  };

  const getCsvData = () => {
    return copywriteResponse?.copyWriteRequests
      ? copywriteResponse?.copyWriteRequests.map((copywrite) => ({
        Job_Title: copywrite?.job?.title,
        Company: copywrite?.company?.name,
        Job_Type: copywrite?.job?.type === 52 ? 'Job Template' : 'Job' || '-',
        Requested_on: copywrite?.createdAt
          ? dateConversion(copywrite?.createdAt)
          : '-',
        Status: copywrite?.job?.copywriteStatus
      }))
      : []
  };

  return (
    <div>
      <Portlet>
        <PortletHeader
          tabs={''}
          title={'Copywriting'}
        />
        <div className="portlet__body-bg">
          <PortletBody className="mx-2">
            <>
              <div className="flex-center mb-3">
                <div className="flex-grow-1 pb-1">
                  <StyledIconInput
                    icon={<SearchIcon />}
                    className="mw-50"
                    $maxWidth="60%"
                    $marginLeft="3px"
                    onChange={handleSearch}
                    loading={isFetching}
                    placeholder="Search by Job Name, Company or Reference Number"
                  />
                </div>

                <div className="d-flex">
                  <div className="flex-center me-3">
                    <StyledFilterTabs padding="4px">
                      <div className="btn-group " role="group">
                        <button
                          className="btn"
                          onClick={() => ToggleFilterbar('filter')}
                        >
                          <FilterIcon size={14} />
                          <small className="ms-1">Filter</small>
                        </button>
                      </div>
                    </StyledFilterTabs>
                  </div>
                  <div className="d-flex">
                    <div className="flex-center">
                      <StyledFilterTabs padding="5px">
                        <div className="btn-group " role="group">
                          <button
                            className="btn"
                            id="sort"
                            onClick={() => ToggleFilterbar('sort')}
                          >
                            <SortIcon size={15} />
                          </button>
                          <button
                            className="btn"
                            onClick={() => ToggleFilterbar('selected')}
                          >
                            <PauseIcon size={15} />
                          </button>
                          <button className="btn">
                            <CSVLink
                              data={getCsvData()}
                              filename={'Copywrite Listing.csv'}
                            >
                              <StyledToolTip
                                title="Download"
                                childrenClass={'ms-0'}
                              >
                                <NewDownloadIcon size={15} />{' '}
                              </StyledToolTip>
                            </CSVLink>
                          </button>
                        </div>
                      </StyledFilterTabs>
                    </div>
                  </div>
                  <div
                    ref={selectColumnRef}
                    className=" position-relative d-flex rounded flex-column"
                  >
                    {selectedColumns && (
                      <StyledFilterBar
                        width="248px"
                        className="bg-white py-3 align-items-center justify-content-center"
                      >
                        <div
                          className="d-flex justify-content-between align-items-center mb-3"
                          onClick={() => setSelectedColumns(false)}
                        >
                          <h5 className="mb-0 ps-3">Column Modifier</h5>
                          <div className="cursor-pointer pe-3">
                            <CrossIcon />
                          </div>
                        </div>
                        {column.map((item, index) => {
                          return (
                            <StyledBgEffect
                              className="d-flex align-items-center py-2 ps-4 cursor-pointer"
                              key={`cl-${index}`}
                            >
                              <StyledCheckbox
                                size="14"
                                value={item.isChecked}
                                onChange={() => handleCHeck(item)}
                              />
                              <h6 className="ms-2 mb-0">{item.label}</h6>
                            </StyledBgEffect>
                          );
                        })}
                        <div className="mx-4 mt-2">
                          <StyledButton
                            onClick={handleSelectedColumn}
                            className="w-100 p-1"
                          >
                            Apply
                          </StyledButton>
                        </div>
                      </StyledFilterBar>
                    )}
                  </div>
                  <div
                    ref={sortFilterRef}
                    className="m-0 position-relative d-flex rounded flex-column"
                  >
                    {sortFilter && (
                      <StyledFilterBar
                        width="290px"
                        className="bg-white py-3 align-items-center justify-content-center"
                      >
                        <div className="d-flex justify-content-between align-items-center mb-3">
                          <h5 className="mb-0 ps-4">Sort by</h5>
                        </div>
                        {CopywriteSort?.map((item, index) => {
                          return (
                            <StyledBgEffect
                              className={`${item.value === 'nearest'
                                ? 'feature-pending'
                                : ''
                                } d-flex align-items-center py-2 ps-4 `}
                              key={`sort-${item.value}-${index}`}
                            >
                              <StyledRadioButton
                                size={15}
                                dotSize={7}
                                value={
                                  sorted === item.match ||
                                  (!sorted && index === 0 && true)
                                }
                                label={item.label}
                                className="fw-bold"
                                onChange={() => handleSortBy(item)}
                              />
                            </StyledBgEffect>
                          );
                        })}
                      </StyledFilterBar>
                    )}
                  </div>
                  <div ref={filterRef} className="bg-transparent mt-2">
                    {isOpen && (
                      <StyledFilterBar width="400px" variant="filter">
                        <div className="d-flex justify-content-end">
                          <div
                            className="cursor-pointer mt-3 me-3"
                            onClick={() => setIsopen(false)}
                          >
                            <CrossIcon />
                          </div>
                        </div>

                        <div className="sd-header">
                          <h4 className="mb-0">Filters</h4>
                          {!isEqual(filters, initialFilters) && (
                            <h6
                              className="mb-0 cursor-pointer"
                              onClick={() => {
                                setApplyedValues({});
                                setFilters((prevFilters) => ({
                                  sort: prevFilters?.sort ?? 'createdAt',
                                  sortDir: prevFilters?.sortDir ?? 'DESC',
                                }));
                                setIsopen(false);
                              }}
                            >
                              Clear All
                            </h6>
                          )}
                        </div>
                        <div className="sd-body">
                          <Formik
                            initialValues={{
                              ...appliedValues,
                            }}
                            onSubmit={handleSubmit}
                            innerRef={ref}
                          >
                            {({ handleSubmit, values, submitForm, errors }) => (
                              <Form onSubmit={handleSubmit}>
                                <CopyWritingFilterForm
                                  submitForm={submitForm}
                                  values={values}
                                  compId={compId}
                                  loading={false}
                                />
                              </Form>
                            )}
                          </Formik>
                        </div>
                      </StyledFilterBar>
                    )}
                  </div>
                </div>
              </div>

              {error || copywriteResponse?.copyWriteRequests?.length === 0 || !copywriteResponse?.copyWriteRequests ? (
                <> </>
              ) : (
                <>
                  {copywriteResponse?.copyWriteRequests?.length > 0 ? (
                    <CopyWritingListingTable
                      jobs={copywriteResponse?.copyWriteRequests}
                      sortColumn={listing.sortedColumn}
                      onSort={handleSort}
                      filterTable={newColumn}
                    />
                  ) : (
                    <TableSkeleton
                      cols={[
                        'Name',
                        'Job',
                        'Cost',
                        'Duration',
                        'Status',
                        'Actions',
                      ]}
                      rows={3}
                    />
                  )}
                  <PaginationPerPage
                    perPage={copywriteResponse?.perPage || 0}
                    pageNo={copywriteResponse?.page || 1}
                    onPerPageChange={handlePerPageChange}
                    totalCount={copywriteResponse?.total || 0}
                    onPageChange={handlePageChange}
                  />
                </>
              )}
            </>
          </PortletBody>
        </div>
      </Portlet>
    </div>
  );
};

const StyledFilterBar = styled.div`
  width: ${(props) => props?.width ?? '229px'};
  // height: ${(props) => (props?.variant ? '91vh !important' : 'none')};
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
  background-color: #fff;
  position: ${(props) => (props?.variant ? 'fixed' : 'absolute')};
  margin-top: ${(props) => (!props?.variant ? '35px' : 'none')};
  top: ${(props) => (props.variant ? '90px' : 'none')};
  right: 0;
  bottom: ${(props) => (props?.variant ? '0' : 'none')};
  z-index: 2;
  border-radius: ${(props) => (props?.variant ? '8px 0 0 0' : '14px')};
  transition: 0.5s;
  padding-bottom: 28px;

  .sd-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    color: ${({ theme }) => theme.textColor};
  }

  .sd-body {
    padding: 20px;
    height: 100%;
    width: 100%;
    position: relative;
    overflow: auto;
  }

  .sd-body ul {
    display: inline-block;
    width: 100%;
    margin-bottom: 0;
    padding: 0;
  }

  .sd-body ul li {
    list-style: none;
    margin-bottom: 8px;
  }
`;

const StyledBgEffect = styled.div`
  &:hover {
    background-color: ${({ theme }) => theme.bodyBg};
  }
`;
export default CopyWritingContainer;