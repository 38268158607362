import React from 'react';

const OccyNewLogoIcon = ({ height = 18, width = 16, color = "#A5A9AE", ...props }) => (
    <svg width={width} height={height} viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M6.46149 13.8642C6.87354 13.9463 7.29853 13.9929 7.73322 13.9994C10.7605 14.0414 13.3557 12.1681 14.3849 9.51406H14.5776C14.9037 9.51406 15.196 9.31935 15.3254 9.0205C15.4923 8.63368 15.667 7.98812 15.667 6.97513C15.667 5.96215 15.493 5.31658 15.3254 4.92976C15.196 4.63091 14.903 4.4362 14.5776 4.4362H14.3855C13.4676 2.06805 11.3032 0.322171 8.69769 0.00262199C8.55085 -0.0154901 8.4066 0.0614863 8.34256 0.19474C8.25135 0.38427 8.05794 0.514936 7.83348 0.514936C7.60902 0.514936 7.41561 0.38427 7.3244 0.19474C7.26036 0.0614863 7.11611 -0.0154901 6.96927 0.00262199C4.36437 0.322171 2.19997 2.06805 1.28208 4.43556H1.08931C0.763295 4.43556 0.470914 4.63026 0.341542 4.92911C0.174652 5.31593 0 5.9615 0 6.97448C0 7.98747 0.174005 8.63304 0.341542 9.01986C0.470914 9.31871 0.763942 9.51341 1.08931 9.51341H1.28725C2.14887 11.7147 4.09269 13.39 6.46084 13.8636H6.46149V13.8642Z" fill={color} />
        <path d="M7.84347 12.4652C10.704 12.4652 13.0229 10.5538 13.0229 8.19591C13.0229 5.83806 10.704 3.92664 7.84347 3.92664C4.98296 3.92664 2.66406 5.83806 2.66406 8.19591C2.66406 10.5538 4.98296 12.4652 7.84347 12.4652Z" fill="white" />
        <path d="M5.20871 7.18811C4.51721 7.18811 3.95703 7.74829 3.95703 8.43979C3.95703 9.13128 4.51721 9.69146 5.20871 9.69146C5.9002 9.69146 6.46038 9.13128 6.46038 8.43979C6.46038 7.74829 5.9002 7.18811 5.20871 7.18811ZM5.6324 8.31882C5.45387 8.31882 5.30962 8.17393 5.30962 7.99604C5.30962 7.81815 5.45451 7.67326 5.6324 7.67326C5.81029 7.67326 5.95518 7.81815 5.95518 7.99604C5.95518 8.17393 5.81029 8.31882 5.6324 8.31882Z" fill={color} />
        <path d="M10.5798 7.18811C9.88831 7.18811 9.32812 7.74829 9.32812 8.43979C9.32812 9.13128 9.88831 9.69146 10.5798 9.69146C11.2713 9.69146 11.8315 9.13128 11.8315 8.43979C11.8315 7.74829 11.2713 7.18811 10.5798 7.18811ZM11.0442 8.31882C10.8657 8.31882 10.7215 8.17393 10.7215 7.99604C10.7215 7.81815 10.8664 7.67326 11.0442 7.67326C11.2221 7.67326 11.367 7.81815 11.367 7.99604C11.367 8.17393 11.2221 8.31882 11.0442 8.31882Z" fill={color} />
    </svg>


);

export default OccyNewLogoIcon;
