import React, { useState } from 'react';
import styled from 'styled-components';
import CustomDropdown from '../../../../app/components/dropdown/CustomDropDown';
import StyledButton from '../../../../app/components/styledComponents/StyledButton';
import StyledColoredTag from '../../../../app/components/styledComponents/StyledColoredTag';
import IndeedIcon from '../../../../app/components/svgIcons/IndeedIcon';
import OptionFillIcon from '../../../../app/components/svgIcons/OptionFillIcon';
import { StyledLabel, Wrap } from '../../../../assets/styledComponents/Global';

const ChannelTile = ({
  channels,
  setAddNewChannel,
  setChannelIndex,
  deleteChannel,
  resetForm,
  activeTab,
}) => {
  const [showMore, setShowMore] = useState(false);
  const bulkJobBoard = showMore
    ? channels?.jobBoards
    : channels?.jobBoards?.slice(0, 3);

  return (
    <StyledHoverDiv
      className={activeTab ? 'col-4 bg-white rounded-4 onActive' : 'col-6'}
      key={'channelTile' + channels?.id}
    >
      <Wrap className="border rounded-4 p-4 mt-3">
        <Wrap className="d-flex justify-content-between align-items-center">
          <Wrap className="d-flex gap-1">
            <StyledLabel size="18px">
              {activeTab ? 'SRO Premium' : channels?.channelName}
            </StyledLabel>

            <StyledColoredTag
              $padding="5px 10px"
              variant="primary"
              $radius="10px"
            >
              #10
            </StyledColoredTag>
          </Wrap>
          <CustomDropdown
            dropdownToggle={<OptionFillIcon rotate="left" />}
            toggleClassName="bg-body p-0 bg-transparent"
            dropdownMenu={
              <>
                <StyledDropDownContainer className="rounded p-0">
                  <StyledButton
                    variant="icon"
                    className="border-0 text-black w-100 justify-content-start"
                    onClick={() => {
                      setAddNewChannel(true);
                      setChannelIndex(channels);
                    }}
                  >
                    Edit
                  </StyledButton>
                </StyledDropDownContainer>

                <StyledDropDownContainer className="rounded p-0">
                  <StyledButton
                    variant="icon"
                    className="border-0 text-black w-100 justify-content-start"
                    onClick={() => {
                      deleteChannel(channels?.id);
                      resetForm();
                    }}
                  >
                    Delete
                  </StyledButton>
                </StyledDropDownContainer>
              </>
            }
          />
        </Wrap>

        <StyledLabel size="12px" weight="500" className="text-lightBlack mt-2">
          Post your job advert to the leading national jb boards and their
          syndicated networks.
        </StyledLabel>

        <Wrap className="d-flex flex-column my-3">
          <StyledLabel size="12px" className="text-lightBlack">
            Access key
          </StyledLabel>
          <StyledLabel size="14px" className="text-primary mt-1">
            abc3def
          </StyledLabel>
        </Wrap>

        <Wrap className="d-flex flex-column">
          <StyledLabel size="12px" weight="700" className="text-lightBlack">
            Job boards
          </StyledLabel>
          {activeTab ? (
            <Wrap
              className={`d-flex flex-wrap flex-column
              `}
            >
              <Wrap className="d-flex my-2 align-items-center gap-2 me-5">
                <IndeedIcon />
                <StyledLabel size="12px" weight="500" className="text-darkGray">
                  Indeed
                </StyledLabel>
              </Wrap>
              <Wrap className="d-flex my-2 align-items-center gap-2 me-5">
                <IndeedIcon />
                <StyledLabel size="12px" weight="500" className="text-darkGray">
                  Indeed
                </StyledLabel>
              </Wrap>
              <Wrap className='d-flex align-items-center'>
              <Wrap className="d-flex my-2 align-items-center gap-2 me-5">
                <IndeedIcon />
                <StyledLabel size="12px" weight="500" className="text-darkGray">
                  Indeed
                </StyledLabel>
              </Wrap>

              <StyledLabel
                className={`text-primary ms-2 cursor-pointer`}
                size="12px"
                onClick={() => setShowMore(!showMore)}
              >
                {showMore ? 'show less' : `+ 4 more`}
              </StyledLabel>
                </Wrap>
            </Wrap>
          ) : (
            <Wrap
              className={`d-flex flex-wrap ${
                showMore ? 'align-items-center' : 'flex-column'
              }`}
            >
              {bulkJobBoard?.map((item, index) => (
                // <Wrap className={index === 2 ? 'align-items-center' : ''}>
                <StyledBoardContainer
                  className="d-flex align-items-center"
                  key={'bulkJobBoard' + index}
                >
                  <Wrap className="d-flex my-2 align-items-center gap-2 me-5">
                    <IndeedIcon />
                    <StyledLabel
                      size="12px"
                      weight="500"
                      className="text-darkGray"
                    >
                      {item.jobBoard}
                    </StyledLabel>
                  </Wrap>

                  {index === 2 && !showMore ? (
                    <>
                      {channels.jobBoards.length > 3 ? (
                        <StyledLabel
                          className={`text-primary ms-2 cursor-pointer`}
                          size="12px"
                          onClick={() => setShowMore(!showMore)}
                        >
                          {showMore
                            ? 'show less'
                            : `+ ${channels.jobBoards.length - 3} more`}
                        </StyledLabel>
                      ) : null}
                    </>
                  ) : null}
                </StyledBoardContainer>
              ))}
              {showMore && (
                <StyledLabel
                  className={`text-primary ms-2 cursor-pointer`}
                  size="12px"
                  onClick={() => setShowMore(!showMore)}
                >
                  {showMore
                    ? 'show less'
                    : `+ ${channels.jobBoards.length - 3} more`}
                </StyledLabel>
              )}
            </Wrap>
          )}
        </Wrap>
      </Wrap>
    </StyledHoverDiv>
  );
};

const StyledDropDownContainer = styled.div`
  width: 226px;
  padding: 15px 24px;

  &:hover {
    background-color: #fafafa;
  }
`;

const StyledBoardContainer = styled.div`
  flex-basis: 25%;
`;

const StyledHoverDiv = styled.div`
&.onActive {
  :hover {
    box-shadow: 0 0 11px rgba(33,33,33,.2); 
    transition: box-shadow .3s;
    opacity: 0.8;
  
  }
}
`

export default ChannelTile;
