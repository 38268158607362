import React from 'react';
import { Route, Routes } from 'react-router-dom';

import CopyWritingContainer from './CopyWritingContainer';
import CopyWritingDetailsContainer from './CopyWritingDetailsContainer';


const CopyWritingRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<CopyWritingContainer />} />
      <Route path="/:jobId" element={<CopyWritingDetailsContainer />} />
    </Routes>
  );
};

export default CopyWritingRoutes;
