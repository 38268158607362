import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Modal, Row } from 'react-bootstrap';
import PortletBody from '../../../app/components/portlet/PortletBody';
import StyledButton from '../../../app/components/styledComponents/StyledButton';
import * as Yup from 'yup';
import FormikInputField from '../../../app/components/formikComponents/FormikInputField';
import FormikAsyncSelect from '../../../app/components/formikComponents/FormikAsyncSelect';
import { useGetAllProductsListMutation } from '../companyApiSlice';
// import FormikDatePicker from '../../../app/components/formikComponents/FormikDatePicker';
// import InfoIcon from '../../../app/components/svgIcons/InfoIcon';
import { StyledLabel } from '../../../assets/styledComponents/Global';
import StyledSwitch from '../../../app/components/styledComponents/StyledSwitch';
import { PRODDUCT_POSTING_TYPES } from '../../Products/assets/utills';

const AddCompanyProductModal = ({
  show,
  onHide,
  title,
  AddCompPrd,
  addLoading,
  productData,
  editProductModal,
  setProductType,
  productType,
}) => {
  const [getAllProductsList] = useGetAllProductsListMutation();
  const addCompProductSchema = Yup.object().shape({
    productId: Yup.object().required('Required').nullable(),
    ...(((editProductModal && productData?.accessKey === 'SMS') ||
      productType?.accessKey === 'SMS') && {
      quantity: Yup.number()
        .positive('Quantity must be more than 0')
        .integer()
        .required('Required'),
    }),
    creditPrice: Yup.number().required('Required').min(0),
    // .integer()
    // .required('Required'),
    // expiryDate: Yup.date().required('Required'),
  });
  const handleSubmit = (values) => {
    // if (productType?.accessKey !== 'SMS') {
    //   values.quantity = 0;
    // }
    AddCompPrd(values);
  };

  useEffect(() => {
    editProductModal &&
      setAutoPosting(productData?.postingType === 'auto' ? true : false);
  }, [editProductModal]);

  const [autoPosting, setAutoPosting] = useState(
    productType?.postingType && productType?.postingType === 'auto'
      ? true
      : false,
  );
  const handleSwitch = (setFieldValue, value) => {
    if (value) {
      setFieldValue(
        'postingType',
        value?.postingType === null ? 'manual' : value?.postingType,
      );
      setAutoPosting(value?.postingType === 'auto' ? true : false);
    } else {
      if (!autoPosting) {
        setAutoPosting(!autoPosting);
        setFieldValue('postingType', 'auto');
      } else {
        setAutoPosting(!autoPosting);
        setFieldValue('postingType', 'manual');
      }
    }
  };

  return (
    <Modal show={show} centered onHide={onHide} size={'lg'}>
      <Formik
        initialValues={{
          productId: editProductModal
            ? { label: productData?.title, value: productData?.productId }
            : undefined,
          quantity: editProductModal ? productData?.quantity : 0,
          postingType: editProductModal ? productData?.postingType : 'manual',
          creditPrice: editProductModal ? productData?.creditPrice : '',
          // expiryDate: editProductModal ? productData?.expiryDate : '',
        }}
        validationSchema={addCompProductSchema}
        onSubmit={handleSubmit}
      >
        {({ handleSubmit, values, setFieldValue }) => (
          <Form onSubmit={handleSubmit}>
            <Modal.Header
              // title={title}
              closeButton
              className="border-bottom-0 pb-0 align-items-baseline"
            >
              <Modal.Title
                as="h4"
                className="flex-grow-1 p-2 cursor-pointer flex-center"
              >
                {show && title}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="d-flex  flex-column bg-body rounded">
              <PortletBody className="p-0 w-100">
                <PortletBody className={'p-3 w-100'}>
                  <Row>
                    <div className="col-12 col-md-12">
                      <div className="mb-3 ">
                        <label htmlFor="productId" className="form-label">
                          Select Product
                        </label>
                        <FormikAsyncSelect
                          name="productId"
                          id="productId"
                          apiCall={getAllProductsList}
                          isDisabled={editProductModal}
                          placeholder="Select product"
                          // onFocus={() => setFieldValue("productId", undefined)}
                          onChange={(value) => {
                            setFieldValue('productId', {
                              label: value?.title,
                              value: value?.value,
                            });
                            setFieldValue('creditPrice', value?.creditPrice);
                            setProductType(value);
                            handleSwitch(setFieldValue, value);
                          }}
                        />
                      </div>
                    </div>
                   {productType?.productType === 'ADVERTISING' && (
                      <div
                        className={`d-flex justify-content-start align-items-center mb-3`}
                      >
                        <StyledLabel
                          className="pe-2"
                          color={'#0A0509'}
                          weight={700}
                          size="14px"
                        >
                          Posting Type
                        </StyledLabel>

                        <StyledSwitch
                          isToggle={autoPosting}
                          onToggle={() => {
                            handleSwitch(setFieldValue);
                          }}
                          width={'17px'}
                          height={'17px'}
                          lblheight={'16px'}
                          lblWidth={'29px'}
                          left={'0.3px'}
                          bottom={'0.3px'}
                          top={'0px'}
                        />
                       <StyledLabel
                          className="ps-2"
                          color={'#0A0509'}
                          weight={600}
                          size="14px"
                        >
                            {values?.postingType===PRODDUCT_POSTING_TYPES?.MANUAL?'(Manual)':'(Auto)'}
                        </StyledLabel>
                      </div>
                    )}
                    {((editProductModal && productData?.accessKey === 'SMS') ||
                      productType?.accessKey === 'SMS') && (
                      <div className="col-12 col-md-6">
                        <div className="mb-3 ">
                          <label htmlFor="quantity" className="form-label">
                            Quantity
                          </label>
                          <FormikInputField
                            name="quantity"
                            id="quantity"
                            type="number"
                            min="1"
                            placeholder="Enter quantity"
                            disabled={true}
                          />
                          {/* <p  className="form-label small">
                           <InfoIcon size={12}/>   <small> This    </small>
                           </p> */}
                        </div>
                      </div>
                    )}
                    <div className={`col-12 ${((editProductModal && productData?.accessKey === 'SMS') ||
                      productType?.accessKey === 'SMS') ?'col-md-6':'col-md-12'}  `}>
                      <div className="mb-3">
                        <label htmlFor="creditPrice" className="form-label">
                          Cost Per Unit (in credits)
                        </label>
                        <FormikInputField
                          name="creditPrice"
                          id="creditPrice"
                          type="number"
                          min="0"
                          step="0.01"
                          placeholder="Cost per unit 1"
                        />
                      </div>
                    </div>
                    {/* <div className="col-12 col-md-6 ">
                      <div className="mb-3">
                        <label htmlFor="expiryDate" className="form-label">
                          Expiry Date
                        </label>
                        <FormikDatePicker
                          name="expiryDate"
                          minDate={new Date(new Date().getTime() + 86400000)}
                        />
                      </div>
                    </div> */}
                  </Row>
                </PortletBody>
              </PortletBody>
              <div className="d-flex justify-content-end mt-3">
                <div className="d-flex gap-3">
                  <StyledButton
                    variant={'text'}
                    className="mb-2 border-0 fw-bold"
                    onClick={onHide}
                  >
                    Cancel
                  </StyledButton>
                  <StyledButton
                    className="mb-2"
                    type="submit"
                    loading={addLoading}
                    disabled={addLoading}
                  >
                    {editProductModal ? 'Update' : 'Save'}
                  </StyledButton>
                </div>
              </div>
            </Modal.Body>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default AddCompanyProductModal;
