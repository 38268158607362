import React from 'react';
import './assets/styles/auth.scss';
import {useDispatch} from "react-redux";
import {removeCredentials, removeSideMenuState} from "./authSlice";

export const Logout = () => {

    const dispatch = useDispatch();
    dispatch(removeCredentials());
    dispatch(removeSideMenuState());

    window.location.href = '/';
    return (
        <i>processing...</i>
    );
};
