import React from 'react';

const CompanyAddedIcon = ({ size = 18, color = "#000000", ...props }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="24" height="24" rx="12" fill="#F5F5F5"/>
    <path d="M7.91666 9.66666C6.94832 9.66666 6.16666 10.4483 6.16666 11.4167V16.0833C6.16666 17.0517 6.94832 17.8333 7.91666 17.8333H8.79166C9.43332 17.8333 9.95832 17.3083 9.95832 16.6667V10.8333C9.95832 10.1917 9.43332 9.66666 8.79166 9.66666H7.91666ZM8.49999 14.9167C8.49999 15.1558 8.30166 15.3542 8.06249 15.3542C7.82332 15.3542 7.62499 15.1558 7.62499 14.9167V12.5833C7.62499 12.3442 7.82332 12.1458 8.06249 12.1458C8.30166 12.1458 8.49999 12.3442 8.49999 12.5833V14.9167Z" fill="#767476"/>
    <path d="M16.0833 6.16666H12.5833C11.615 6.16666 10.8333 6.94832 10.8333 7.91666V16.0833C10.8333 17.0517 11.615 17.8333 12.5833 17.8333H12.875C13.0383 17.8333 13.1667 17.705 13.1667 17.5417V15.5C13.1667 15.1792 13.4292 14.9167 13.75 14.9167H14.9167C15.2375 14.9167 15.5 15.1792 15.5 15.5V17.5417C15.5 17.705 15.6283 17.8333 15.7917 17.8333H16.0833C17.0517 17.8333 17.8333 17.0517 17.8333 16.0833V7.91666C17.8333 6.94832 17.0517 6.16666 16.0833 6.16666ZM13.6042 12.5833C13.6042 12.8225 13.4058 13.0208 13.1667 13.0208C12.9275 13.0208 12.7292 12.8225 12.7292 12.5833V9.66666C12.7292 9.42749 12.9275 9.22916 13.1667 9.22916C13.4058 9.22916 13.6042 9.42749 13.6042 9.66666V12.5833ZM15.9375 12.5833C15.9375 12.8225 15.7392 13.0208 15.5 13.0208C15.2608 13.0208 15.0625 12.8225 15.0625 12.5833V9.66666C15.0625 9.42749 15.2608 9.22916 15.5 9.22916C15.7392 9.22916 15.9375 9.42749 15.9375 9.66666V12.5833Z" fill="#767476"/>
    </svg>
    
    

  );
};

export default CompanyAddedIcon;
