import React from 'react';
import TableHeader from './TableHeader';
import TableBody from './TableBody';
import styled from 'styled-components';
const Table = ({ columns, sortColumn, onSort, data, tableRef,analytics }) => {
  return (
    <StyledDiv className={`rounded border ${analytics === 'true' ? 'table-responsive' : ''}`}>
      <table className={`table mb-0 `}>
        <TableHeader
          columns={columns}
          sortColumn={sortColumn}
          onSort={onSort}
        />
        <TableBody columns={columns} data={data} ref={tableRef} />
      </table>
    </StyledDiv >
  );
};
const StyledDiv = styled.div`
// overflow: auto;

`
export default Table;
