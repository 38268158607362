import React from 'react';

const NoChartIcon = ({ size = 40, ...props }) => {
  return (
<svg width={size} height={size}viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<path d="M26.6332 8.19987H28.3665C31.0165 8.19987 32.0832 9.19987 32.0832 11.7332V31.5332H22.9165V11.7332C22.9332 9.19987 23.9998 8.19987 26.6332 8.19987Z" fill="#767476"/>
<path d="M12.4667 16.5332H14.2C16.85 16.5332 17.9167 17.5499 17.9167 20.0665V31.5332H8.75V20.0665C8.76667 17.5499 9.83333 16.5332 12.4667 16.5332Z" fill="#767476"/>
<path d="M35.4168 30.2999H4.5835C3.90016 30.2999 3.3335 30.8499 3.3335 31.5332C3.3335 32.2165 3.90016 32.7832 4.5835 32.7832H35.4168C36.1002 32.7832 36.6668 32.2165 36.6668 31.5332C36.6668 30.8499 36.1002 30.2999 35.4168 30.2999Z" fill="#767476"/>
<rect x="3.80371" y="8.61426" width="3.31834" height="40.3557" rx="1.65917" transform="rotate(-51.97 3.80371 8.61426)" fill="#CBCBCB"/>
</svg>

  );
};

export default NoChartIcon;